import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { PrintService } from "../../services/print.service";
interface Status {
  name: string,
  code: string
}
import * as XLSX from 'xlsx'; 
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {

  userId = parseInt(sessionStorage.getItem('userId'));
  today: string;
  ExpiredDocs: any; //archiveModel[];
  ArchivedDocs: any;
  cols: any[];
  dynamicplaceholder: string;
  selectedCustomers: any;
  archiveArrayObject: any;
  statuses: Status[];
  selectedStatus: any;
  statusError: any;
  showLoading : boolean;
  headerCheckbox:boolean;
  ArchiveHideShow:boolean = true;
  @ViewChild('dt') table: Table;

  constructor(
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    public printService: PrintService,private cdr: ChangeDetectorRef) {
    this.statuses = [
      { name: 'Expired Documents', code: '1' },
      { name: 'Archived Documents', code: '2' },

    ];
  }

  public archiveArray: any[] = [];
  ngOnInit() {

    this.GetExpiredDocsData();
    var status = this.statuses.find(s => s.name = "Expired Documents");
    this.selectedStatus = status;
    console.log(this.selectedStatus)

    this.cols = [
      { field: 'code', header: 'Id', dynamicplaceholder: 'Search' },
      { field: 'revision', header: 'Revision', dynamicplaceholder: "Search" },
      { field: 'ecrn', header: 'ECRN#', dynamicplaceholder: "Search" },
      { field: 'title', header: 'Title', dynamicplaceholder: "Search" },
      // { field: 'documentType', header: 'Document Type', dynamicplaceholder: "Search" },
      { field: 'departmentName', header: 'Department', dynamicplaceholder: "Search" },
      { field: 'owner', header: 'Originator', dynamicplaceholder: "Search" },
      { field: 'status', header: 'Status', dynamicplaceholder: "Search" },
      { field: 'lastAccessedDate', header: 'Date', dynamicplaceholder: "Search" },
      // { field: 'expirationDate', header: 'Expiration Date', dynamicplaceholder: "Search" },
      // { field: 'archivedDate', header: 'Archived Date', dynamicplaceholder: "Search" },
    ];
  }
  selectDocList(type) {
    this.headerCheckbox = false;
    this.archiveArray=[];
    if (type.code == 1) {
      this.ArchiveHideShow = true;
      this.GetExpiredDocsData();
    }
    if (type.code == 2) {
      this.ArchiveHideShow = false;
      this.GetArchivedDocsData();
    }
  }
  allExpiredDataChecked(event){
    this.headerCheckbox = true;
    const checked = event.target.ariaChecked;
    this.ExpiredDocs.forEach(item => item.selected = checked);
    this.archiveArray =[];
    if(checked==null){
      return;
    }
    this.ExpiredDocs.forEach((value, key) => {
    this.archiveArray.push(value.fileGuid);
    });
  }

  GetExpiredDocsData() {
    this.headerCheckbox = false;
    this.showLoading = true;
    this.userId = 0;
    this._facadeService.GetExpiredDocsById(this.userId).subscribe(
      (data) => {
        this.ExpiredDocs = data.returnObject;
        this.ExpiredDocs.forEach(element => {
          if(element.status=='New'||element.status == 'In-Process' || element.status == 'Obsolete In-Process'){
            element.lastAccessedDate ="--";
          }
          else{
            element.lastAccessedDate = this.datePipe.transform(element.lastAccessedDate, 'MM/dd/yyyy');
          }
        });
        console.log(this.ExpiredDocs);
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  GetArchivedDocsData() {
    this.headerCheckbox = false;
    this.showLoading = true;
    this.userId = 0;
    this._facadeService.GetArchivedDocsById(this.userId).subscribe(
      (data) => {
        this.ExpiredDocs = data.returnObject;
        console.log(this.ArchivedDocs);
        this.ExpiredDocs.forEach(element => {
          if(element.status=='New'||element.status == 'In-Process' || element.status == 'Obsolete In-Process'){
            element.lastAccessedDate ="--";
          }
          else{
            element.lastAccessedDate = this.datePipe.transform(element.lastAccessedDate, 'MM/dd/yyyy');
          }
        });
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx : 100 },{ wpx :  100},{ wpx : 80 },{ wpx : 90 },{ wpx : 550 },{ wpx : 150 },{ wpx : 100 },{ wpx : 100 },{ wpx : 100 }];
  
    // Save to file
        if(this.ArchiveHideShow)
      XLSX.writeFile(wb, "Expired Documents.xlsx");
    else
    XLSX.writeFile(wb, "Archived Documents.xlsx");
  }
  // exportExcel() {
  //    /* table id is passed over here */   
  //    let element = document.getElementById('excel-table'); 
  //    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //    /* generate workbook and add the worksheet */
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //    this.delete_row(wb.Sheets.Sheet1, 1);
  //    ws['!cols'] = [{ wpx : 100 },{ wpx :  100},{ wpx : 80 },{ wpx : 90 },{ wpx : 550 },{ wpx : 150 },{ wpx : 100 },{ wpx : 100 },{ wpx : 100 }];
  //    /* save to file */
  //    if(this.ArchiveHideShow)
  //     XLSX.writeFile(wb, "Expired Documents.xlsx");
  //   else
  //   XLSX.writeFile(wb, "Archived Documents.xlsx");
  // }

  // delete_row (ws, row_index){
  //   let range = XLSX.utils.decode_range(ws["!ref"])
  //   for(var R = row_index; R < range.e.r; ++R){
  //       for(var C = range.s.c; C <= range.e.c; ++C){
  //           ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
  //       }
  //   }
  //   range.e.r--
  //   ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  // }
  // ec(r, c) {
  //   return XLSX.utils.encode_cell({r:r,c:c})
  // }

  print() {
   
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    if(this.ArchiveHideShow)
      this.printService.Print(Content,"Expired Documents",'trSearch',false);
    else
      this.printService.Print(Content,"Archived Documents",'trSearch',false);
}

  selectedId(selectedRow) {
    this.headerCheckbox = false;
    if (this.archiveArray.some((x) => x == selectedRow.fileGuid)) {
      this.archiveArray.forEach((value, key) => {
        if(value==selectedRow.fileGuid){
          this.archiveArray.splice((key), 1);
        }
      });
    } 
    else {
      this.archiveArray.push(selectedRow.fileGuid);
    }
  }

  archiveDocuments() {
    this.headerCheckbox = false;
    if(this.archiveArray!=null && this.archiveArray.length>0){
      this.showLoading = true;
      this.userId = 0;
      this._facadeService.ArchiveDocs(this.userId, this.archiveArray).subscribe(
        (data) => {
          this.showLoading = false;
          this.archiveArray=[];
          this.ngOnInit();
          this.headerCheckbox = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      alert("No Documents selected.");
    }
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
  
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'lastAccessedDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === '--' ? null : new Date(value1);
        const date2 = value2 === '--' ? null : new Date(value2);
  
        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
  
      return (event.order * result);
    });
  
    event.data.forEach(e => {
      if (e.status == 'New' || e.status == 'In-Process' || e.status == 'Obsolete In-Process') {
        e.lastAccessedDate = "--";
      } else if (event.field !== 'lastAccessedDate') {
        // Only format lastAccessedDate if it's not the field used for sorting
        e.lastAccessedDate = this.datePipe.transform(e.lastAccessedDate, 'MM/dd/yyyy');
      }
    });
    this.ExpiredDocs = [...event.data];
  }
}
