import { ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { Table } from 'primeng/table';
import { Router} from '@angular/router';
import {SelectItem} from 'primeng/api';
import { DatePipe } from '@angular/common';
import { PrintService } from "../../services/print.service";
import * as XLSX from 'xlsx'; 


@Component({
  selector: 'app-emailnotification',
  templateUrl: './emailnotification.component.html',
  styleUrls: ['./emailnotification.component.scss']
})
export class EmailnotificationComponent implements OnInit {

  loading: "loading";
  showLoading:boolean;
  cols: any[];
  projects: any[];
  selectedprojects: any[];
  @ViewChild('dt') table: Table;

  status: SelectItem[];
  selectedStatus:any[]=[];

  period: any[];
  selectedPeriod:any;
  datesdisplay:boolean = false;
  editStartDate:any;
  editEndDate:any;
  Name:string = "";
  error:string = "";
  Count:number = 0;
constructor(
  private _facadeService: FacadeService,
  private router: Router,public datepipe: DatePipe,private printService:PrintService,private cdr: ChangeDetectorRef
) {}

ngOnInit() {
  this.showLoading = true;
  
  this.cols = [
    { field: 'applicationName', header: 'Application' },
    { field: 'sentTo', header: 'Sent To' },
    { field: 'sentDate', header: 'Sent Date' },
    { field: 'description', header: 'Description' }
  ];
  sessionStorage.applicationName = "";
  sessionStorage.sentTo = "";
  sessionStorage.sentDate = "";
  sessionStorage.description = "";
  this.period = [ 
    {id: 'currentmonth', name: 'Current Month'},
    {id: 'nextmonth', name: 'Next Month'},
    {id: 'next30days', name: 'Next 30 Days'},
    {id: 'custom', name: 'Custom'}
  ];
  if(sessionStorage.getItem('reportperiod') != null && sessionStorage.getItem('reportperiod') != undefined)
  {
    if(sessionStorage.getItem('reportperiod') != "")
    {
      this.selectedPeriod =  this.period.find(m=>m.id==sessionStorage.getItem('reportperiod') );
      if(this.selectedPeriod.id == "custom")
      {
        this.datesdisplay = true;
        this.editStartDate = "";
        this.editEndDate = "";
        if(sessionStorage.getItem('reportstartdate') != null && sessionStorage.getItem('reportstartdate') != undefined)
          this.editStartDate = this.datepipe.transform(sessionStorage.getItem('reportstartdate'), 'MM/dd/yyyy');
        if(sessionStorage.getItem('reportenddate') != null && sessionStorage.getItem('reportenddate') != undefined)
          this.editEndDate = this.datepipe.transform(sessionStorage.getItem('reportenddate'), 'MM/dd/yyyy');
      }
    }
    else
    {
      sessionStorage.reportperiod = "currentmonth";
      this.selectedPeriod =  this.period.find(m=>m.id=='currentmonth');
    }
  }
  else
  {
    sessionStorage.reportperiod = "currentmonth";
    this.selectedPeriod =  this.period.find(m=>m.id=='currentmonth');
  }
  this.GetApplications();
}

GetApplications() {
  this._facadeService
  .GetApplications()
  .subscribe((data) => {
    this.status = data.returnObject;
    if(sessionStorage.getItem('reportstatus') != null && sessionStorage.getItem('reportstatus') != undefined)
    {
      if(sessionStorage.getItem('reportstatus') != "")
      {
        var projectstats = [];
        projectstats = sessionStorage.getItem('reportstatus').split(',');
        projectstats.forEach((element) => {
          this.selectedStatus.push(element);
        });
      }
    }
    else
    {
      this.status.forEach((element) => {
        this.selectedStatus.push(element);
      });
    }
    this.getprojects();
  });
}
projectstatuschange()
{
  var projectstats = [];
    this.selectedStatus.forEach((element) => {
      projectstats.push(element);
    });

  sessionStorage.reportstatus = projectstats;
}
startdate()
{
  sessionStorage.reportstartdate = this.editStartDate;
}
enddate()
{
  sessionStorage.reportenddate = this.editEndDate;
}
Dates()
{
  sessionStorage.reportperiod = this.selectedPeriod.id;
  this.error = "";
  this.datesdisplay = false;
  this.editStartDate = "";
  this.editEndDate = "";
  if(this.selectedPeriod.id == "custom")
    this.datesdisplay = true;
}
getprojects() {
  this.error = "";
  if(this.selectedStatus == null || this.selectedStatus == undefined)
    this.error = "Please select the application";
  else if(this.selectedStatus.length == 0)
    this.error = "Please select atleast one application";
  else if(this.selectedPeriod.id == "custom" && (this.editStartDate == null || this.editStartDate == undefined || this.editStartDate == ""))
    this.error = "Please enter start date";
  else if(this.selectedPeriod.id == "custom" && (this.editEndDate == null || this.editEndDate == undefined || this.editEndDate == ""))
    this.error = "Please enter end date";
  else if(this.selectedPeriod.id == "custom" && new Date(this.editEndDate) <= new Date(this.editStartDate) )
    this.error = "Start Date should be less then End Date";
  else
  {
    this.showLoading = true;
    var projectstats = [];
    this.selectedStatus.forEach((element) => {
      projectstats.push(element.applicationGuid);
    });
    var searchModel={
      'periodType':this.selectedPeriod.id,
      'startDate':this.editStartDate == null || this.editStartDate == undefined || this.editStartDate == ""? new Date() : new Date(this.editStartDate),
      'endDate':this.editEndDate == null || this.editEndDate == undefined || this.editEndDate == ""? new Date() : new Date(this.editEndDate),
      'applicationID':projectstats,

    }
    this._facadeService.GetEmailNotifications(searchModel).subscribe(
      (data) => {
          this.projects = data.returnObject;  
          this.projects.forEach(element => {
            if(element.sentDate != null && element.sentDate != undefined && element.sentDate != '01/01/1970')
            {
              if ( element.sentDate !== null &&  element.sentDate !== undefined && element.sentDate.toString() !== '0001-01-01T00:00:00' ) {
                element.sentDate = new Date(new Date(element.sentDate).toDateString() + " " + new Date(element.sentDate).toTimeString() + " UTC");
                element.sentDate = this.datepipe.transform(element.sentDate, 'MM/dd/yyyy hh:mm');
              }
             
            } 
        });
        this.CountM();
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
        this.showLoading = false;
      }
    );
  }
}
CountM()
{
  if(this.projects != undefined && this.projects != null)
  {
    var temp = this.projects;
    if(sessionStorage.applicationName != "")
      temp = temp.filter(x=> x.applicationName.toLowerCase().includes(sessionStorage.applicationName.toLowerCase()));
    if(sessionStorage.sentTo != "")
      temp = temp.filter(x=> x.sentTo.toLowerCase().includes(sessionStorage.sentTo.toLowerCase()));
    if(sessionStorage.sentDate != "")
      temp = temp.filter(x=> x.sentDate.toLowerCase().includes(sessionStorage.sentDate.toLowerCase()));
    if(sessionStorage.description != "")
      temp = temp.filter(x=> x.description.toLowerCase().includes(sessionStorage.description.toLowerCase()));
    this.Count = temp.length;
  }
  else
    this.Count = 0;
}

test(val,field,dt)
{
  if(field == "applicationName")
    sessionStorage.applicationName = val;
  else if(field == "sentTo")
    sessionStorage.sentTo = val;
  else if(field == "sentDate")
    sessionStorage.sentDate = val;
  else if(field == "description")
    sessionStorage.description = val;
  dt = dt.filter(val,field, 'contains');
  this.CountM();
}
exportExcel() {
  // Manually load all data
  this.table.first = 0;
  this.table.rows = this.table.totalRecords;

  // Wait for data to load (assuming it's loaded asynchronously)
  setTimeout(() => {
    const data = this.getDataFromTable(this.table);

    // Proceed with exporting data to Excel
    this.exportDataToExcel(data);
  }, 100); // Adjust the timeout as needed
}

// Helper function to get visible columns and data from the p-table
getDataFromTable(table: Table): { columns: any[], data: any[] } {
  const visibleColumns = table.columns.filter(column => column.field);
  const columns = visibleColumns.map(column => {
    return {
      header: typeof column.header === 'object' ? column.header['name'] : column.header,
      field: column.field
    };
  });
  // Use the 'filteredValue' property if available, otherwise fallback to 'value'
  const data = (table.filteredValue || table.value).map(item => {
    const rowData = {};
    visibleColumns.forEach(column => {
      const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
      rowData[columnHeader] = item[column.field];
    });
    return rowData;
  });

  return { columns, data };
}

// Example function to export data to Excel (you can customize this based on your needs)
exportDataToExcel(data: { columns: any[], data: any[] }) {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });

  // Add headers separately
  const headerRows = [data.columns.map(column => column.header)];
  XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    ws['!cols'] = [{ wpx : 150 },{ wpx :  150},{ wpx : 120 },{ wpx : 950 }];
  /* save to file */
  XLSX.writeFile(wb, "Email Notifications.xlsx");
}
// exportExcel() {
//   /* table id is passed over here */   
//   let element = document.getElementById('excel-table'); 
//   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//   /* generate workbook and add the worksheet */
//   const wb: XLSX.WorkBook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//   this.delete_row(wb.Sheets.Sheet1, 1);
//   ws['!cols'] = [{ wpx : 150 },{ wpx :  150},{ wpx : 120 },{ wpx : 950 }];
//   /* save to file */
//   XLSX.writeFile(wb, "Email Notifications.xlsx");
// }

delete_row (ws, row_index){
let range = XLSX.utils.decode_range(ws["!ref"])
for(var R = row_index; R < range.e.r; ++R){
    for(var C = range.s.c; C <= range.e.c; ++C){
      ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
      if(ws[this.ec(R+1, C)].v == "&nbsp;&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
        ws[this.ec(R+1, C)].v  = "";
    }
}
range.e.r--
delete (ws['I1'])
ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
return XLSX.utils.encode_cell({r:r,c:c})
}

print() {

    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
this.printService.Print(Content,'Email Notifications','trSearch',false);
}

}