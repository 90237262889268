<div class="card">
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">
        <h5 style="color:#495057; padding:15px 0 0 10px;width: 90%;">{{headerName}}</h5> <!-- T0010003 -->
        <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:18px;">
            <button *ngIf="source=='A'" pButton pRipple type="button" icon="pi pi-plus" iconPos="left"
                label="Add Product Folder" (click)="AddNewButton()" class="p-button-raised p-button-primary"
                style="float:right;width:auto;"></button>&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
    </div>
    <div class="container">
        <div class="left-container">
            <p-tree [value]="files" [filter]="true" filterPlaceholder="Search" scrollHeight="500px">
                <ng-template let-node pTemplate="urlplus">
                    <div style="cursor: pointer;">
                        {{node.label}} &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </ng-template>
                <ng-template let-node pTemplate="sub">
                    <div (click)="handleTemplateClick(node)" style="cursor: pointer;">
                        {{node.label}} &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </ng-template>
            </p-tree>
        </div>
        <div class="middle-container">
            <!-- <b> {{ productroleGroupNames }}</b> -->
            <p-tree [value]="folderfiles" [filter]="true" filterPlaceholder="Search" scrollHeight="500px">
                <ng-template let-node pTemplate="urlplus" *ngIf="source=='A'">
                    <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;" class="p-button-raised p-button-primary" pTooltip="Edit" (click)="EditFolder(node)"></button>&nbsp;&nbsp;
                    <button pButton type="button" icon="pi pi-plus" pTooltip="Create Level 1 Folder" class="p-button-raised p-button-primary" (click)="CreateSubFolder(node)"
                    style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                    <button pButton type="button" (click)="ViewRoles(node)" icon="pi pi-eye" pTooltip="View Roles" class="p-button-rasised p-button-primary"
                      style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                    <span (click)="folderhandleTemplateClick(node)" style="cursor: pointer;">
                       
                        {{node.label}}
                    </span>
                </ng-template>
                <ng-template let-node pTemplate="urlplus" *ngIf="source!='A'">
                    <span (click)="folderhandleTemplateClick(node)" style="cursor: pointer;">
                        {{node.label}} &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                </ng-template>
                <ng-template let-node pTemplate="sub" *ngIf="source!='A'">
                    <span (click)="folderhandleTemplateClick(node)" style="cursor: pointer;">
                        {{node.label}}
                    </span>
                </ng-template>
                <ng-template let-node pTemplate="sub" *ngIf="source=='A'">
                    <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;"
                    class="p-button-raised p-button-primary" pTooltip="Edit"
                    (click)="EditFolder(node)"></button>&nbsp;&nbsp;
                <button pButton type="button" icon="pi pi-plus" pTooltip="Create Level 1 Folder"
                    class="p-button-raised p-button-primary" (click)="CreateSubFolder(node)"
                    style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                    <button pButton type="button" (click)="ViewRoles(node)" icon="pi pi-eye" pTooltip="View Roles" class="p-button-rasised p-button-primary"
              style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                    <span (click)="folderhandleTemplateClick(node)" style="cursor: pointer;">
                          
                        {{node.label}}
                    </span>
                </ng-template>
                <ng-template let-node pTemplate="subfol" *ngIf="source=='A'">
                    <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;"
                    class="p-button-raised p-button-primary" pTooltip="Edit"
                    (click)="EditFolder(node)"></button>&nbsp;&nbsp;
                <button pButton type="button" icon="pi pi-plus" style="height:20px; width:20px;"
                    pTooltip="Create Level 2 Folder" class="p-button-raised p-button-primary"
                    (click)="CreateSubSubFolder(node)"></button>&nbsp;&nbsp;
                    <button pButton type="button" (click)="ViewRoles(node)" icon="pi pi-eye" pTooltip="View Roles" class="p-button-rasised p-button-primary"
              style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                    <span (click)="folderhandleTemplateClick(node)" style="cursor: pointer;">
                           
                        {{node.label}}
                       
                    </span>
                </ng-template>
                <ng-template let-node pTemplate="subfol" *ngIf="source!='A'">
                    <span (click)="folderhandleTemplateClick(node)" style="cursor: pointer;">
                        {{node.label}} 
                    </span>
                </ng-template>
                <ng-template let-node pTemplate="subfol1" *ngIf="source=='A'">
                    <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;"
                    class="p-button-raised p-button-primary" pTooltip="Edit"
                    (click)="EditFolder(node)"></button>&nbsp;&nbsp;
                    <button pButton type="button" (click)="ViewRoles(node)" icon="pi pi-eye" pTooltip="View Roles" class="p-button-rasised p-button-primary"
              style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                    <span (click)="folderhandleTemplateClick(node)" style="cursor: pointer;">
                          
                        {{node.label}} 
                    </span>
                </ng-template>
                <ng-template let-node pTemplate="subfol1" *ngIf="source!='A'">
                    <span (click)="folderhandleTemplateClick(node)" style="cursor: pointer;">
                        {{node.label}}
                    </span>
                </ng-template>
            </p-tree>
        </div>
        <div class="right-container">
            <div class="card" style="height:580px;">
                <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;display: flex; justify-content: space-between; align-items: center;height: 50px;overflow: hidden;">
                    <h5 style="color:#495057; padding:10px 15px;margin: 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">Product: {{ProductTitle}}</h5>
                </div>
                <div class="invoice invoice-header" id="invoice-content">
                    <p-table #dt [value]="docsData"
                        id="excel-table" [columns]="cols" class="table-align" [scrollable]="true" scrollHeight="500px"
                        [filterDelay]="0" dataKey="code" styleClass="p-datatable-grid">
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th style="text-align: center;font-size: medium;width:35%;">
                                    Document Id
                                </th>
                                <th style="text-align: center;font-size: medium;width:65%;">
                                    Title
                                </th>
                            </tr>
                            <tr id="trSearch">
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                        (input)="test($event.target.value,col.field,dt)"
                                        style="margin: -10px 0 -10px 0;width: 80%; height:25px;" />
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                            <tr *ngIf="docsData.title==''" [pSelectableRow]="rowData" style="background-color:#008EBD;color:white;border: 2px;vertical-align: left;">
                                <th style="text-align: center;width:35%;">
                                    <span class="p-input-icon-left" style="float:left;margin-right:5px;margin-top:5px;margin-bottom:5px;">
                                        {{docsData.folderName}}&nbsp;&nbsp;&nbsp;     
                                    </span>
                                </th>
                                <th style="text-align: left;font-size: medium;width:65%;">
                                </th>
							</tr>
                            <tr *ngIf="docsData.title!=''" [pSelectableRow]="rowData" style="vertical-align: middle;">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                    <td *ngIf="i == 0" style="text-align: left; width: 35%;">
                                        <a href="javascript:;" (click)="DMSToken(rowData)" ptooltip="Click to View">{{rowData[col.field] }} </a>
                                    </td>
                                    <td *ngIf="i == 1" style="text-align: left;width:65%;">
                                        <button pButton type="button" (click)="ViewTasks(rowData)" icon="pi pi-eye" pTooltip="View Tasks" class="p-button-rasised p-button-primary"
                                        style="height:20px; width:20px;"></button>&nbsp;
                                        <span style="text-wrap: wrap;">
                                            <a href="javascript:;" (click)="GetFile(rowData)" ptooltip="Click to Download">{{ rowData[col.field] }}</a></span>
                                    </td>
                                    <!-- <td *ngIf="i == 2" style="text-align: left;width:20%;">
                                        {{ rowData[col.field] }}
                                    </td> -->
                                </ng-container>
							</tr>
                        </ng-template>

                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="2" style="text-align: left;">No documents found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div style="
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #000000;
                opacity: 0.5;
                z-index: 5500;
              " *ngIf="showLoading">
        <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
    </div>
</div>
<p-dialog header="{{textlabelheader}}" [(visible)]="addDisplay" modal="modal" showEffect="fade"
    [style]="{ width: '50%' }">
    <hr />
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2"></div>
        <div class="p-field p-col-12 p-md-8">
            <label>Name<span style="color: red">*</span></label>
            <input [(ngModel)]="folderName" type="text" pInputText placeholder="Enter Name" />
            <small class="p-invalid">{{ nameError }}</small>
            <br /><br />
            <label>Description</label>
            <textarea [(ngModel)]="description" type="text" rows="3" [maxLength]="300" pInputTextarea
                placeholder="Enter Description"></textarea>
            <br /><br />
            <label>Active
                <p-checkbox disabled={{isdisabled}} [(ngModel)]="active" binary="active">
                </p-checkbox> </label>
            <br /><br />
        </div>
        <div class="p-field p-col-12 p-md-2"></div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-8"></div>
        <div class="p-field p-col-12 p-md-2">
            <br />
            <button pButton pRipple type="button" icon="pi pi-check" label="{{TextLabel}}"
                class="p-button-raised p-button-success p-mr-2 p-mb-2" style="float: right"
                (click)="CreateUpdateFolder()"></button>
        </div>
        <div class="p-field p-col-12 p-md-2"></div>
        <small class="p-invalid" style="float: right">{{responseMessage}}</small>
    </div>
</p-dialog>

<p-dialog header="Role Groups" [(visible)]="rolegroupsdisplay" modal="modal" showEffect="fade" >
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-6 p-xl-12">
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:auto;min-width: 250px;">
                <ul class="widget-image-list" *ngIf="mulgrps.length>0">
                <li *ngFor="let k of mulgrps">
                    <span>{{ k }}</span>
                </li>
                </ul>
                <ul class="widget-image-list" *ngIf="mulgrps.length==0">
                  <span>no role groups found</span>
                  </ul>
            </div>
        </div>
    </div>
  </p-dialog>

  <p-dialog header="Task Details" [(visible)]="taskgroupsdisplay" modal="modal" showEffect="fade" >
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <div>Product Name:&nbsp;<b>{{ProductTitle}}</b><br/>Document Id:&nbsp;<b>{{TaskGroups}}</b><br/>Title:&nbsp;<b>{{TaskGroups1}}</b><br/><br/></div>
            <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:auto;min-width:500px;">
                <table style="width:100%" class="table-align" >
                    <tr>
                        <th style="width:60%;text-align:left;" >
                            Task Name
                        </th>
                        <th style="width:10%">
                            
                        </th>
                        <th style="width:30%;text-align:left;">
                            Phase
                        </th>
                    </tr>
                    <tr *ngFor="let k of taskgrps">
                        <td style="width:60%;text-align:left;">
                            {{ k.split('|')[0] }}
                        </td>
                        <td style="width:10%">
                            
                        </td>
                        <td style="width:30%;text-align:left;">
                            {{ k.split('|')[1] }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
  </p-dialog>