import { Injectable } from '@angular/core';
import { HttputilityService } from '../services/httputility.service';

@Injectable({
  providedIn: 'root'
})
export class CreateclauseService {

  constructor(private _httputilityService: HttputilityService) {}
  //baseUrl: any = 'http://132.148.242.138/FPDMS/api/';
  
  getClauseGroup() {
    return this._httputilityService.Get('AllClauses');
  }

  clause(model) {
    return this._httputilityService.Add('AllClause', model);
  }

}
