import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { approvalNeededModel } from '../../models/approvalneeded_model';
import { FacadeService } from '../../facade/facade.service';
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintService } from "../../services/print.service";

@Component({
  selector: 'app-approvalsneeded',
  templateUrl: './approvalsneeded.component.html',
  styleUrls: ['./approvalsneeded.component.scss'],
})
export class ApprovalsneededComponent implements OnInit {
  @ViewChild("dt1") table1: Table;
  @ViewChild("dt3") table3: Table;
  @ViewChild("dt4") table4: Table;
  @ViewChild("dt5") table5: Table;
  index: number = 0;
  cols1: any[];
  cols2: any[];
  stateMode: string;
  constructor(
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private router: Router,
    public printService: PrintService,
    private route: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,
    private cdr: ChangeDetectorRef
  ) { }
  userId = sessionStorage.getItem('userId') != null ? parseInt(sessionStorage.getItem('userId')) : 0;
  userGuid = sessionStorage.getItem('userGuid');
  myApprovalModel: any;
  today:string;
  docsData: approvalNeededModel[];
  docsData1: approvalNeededModel[];
  docsData3: approvalNeededModel[];
  docsData4: approvalNeededModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading: boolean;
  DelegationExist: boolean;
  AreYouSureText: any;
  deleteDisplay: boolean;
  delegationId: any;
  source: any;
  status: any;
  selectedStatus: any;
  documentsName: any;
  DocumentsCount = 0;
  DocumentsCount1 = 0;
  DocumentsCount2 = 0;
  DocumentsCount3 = 0;
  DocumentsCount4 = 0;
  displaycardHeader: boolean;
  displaypTable: boolean;
  ngOnInit(): void {
    this.source = this.route.snapshot.paramMap.get('status');
    if (this.source == "Approved") {
      this.index = 2;
      this.approvedByMeData();
    }
    else if (this.source == "Rejected") {
      this.index = 3;
      this.rejectedByMeData();
    }
    else if(this.source=="dto"){
      this.index=1;
      this.DelegatedDocuments();
    }
    else {
      this.approvalNeededData();
      this.getDelegatedData();
    }
    //this.stateMode = this.route.snapshot.paramMap.get('StateMode');
    this.cols = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Document id' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev' },
      { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'Search by ECO' },
      { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Search by Originator' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
      { field: 'lastAccessedDate', header: 'Date', dynamicPlaceHolder: 'Date' },
    ];
    this.cols1 = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Document id' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev' },
      { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'Search by ECO' },
      { field: 'owner', header: 'Delegated By', dynamicPlaceHolder: 'Search by delegated by' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
    ];
    this.cols2 = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Document id' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev' },
      { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'Search by ECO' },
      { field: 'owner', header: 'Delegated To', dynamicPlaceHolder: 'Search by delegated to' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
    ];
    sessionStorage.code = "";
    sessionStorage.revision = "";
    sessionStorage.eco = "";
    sessionStorage.title = "";
    sessionStorage.departmentName = "";
    sessionStorage.owner = "";
    sessionStorage.status = "";
    sessionStorage.lastAccessedDate = "";
    sessionStorage.ecrn = "";
    sessionStorage.department = "";
    sessionStorage.role = "";
    sessionStorage.user = "";
    sessionStorage.toDepartment = "";
    sessionStorage.toRole = "";
    sessionStorage.toUser = "";
    sessionStorage.fromDate = "";
    sessionStorage.toDate = "";
    sessionStorage.active = "";
    sessionStorage.documentType = "";
  }
  TabClick($event) {
    sessionStorage.code = "";
    sessionStorage.revision = "";
    sessionStorage.eco = "";
    sessionStorage.title = "";
    sessionStorage.departmentName = "";
    sessionStorage.owner = "";
    sessionStorage.status = "";
    sessionStorage.lastAccessedDate = "";
    sessionStorage.ecrn = "";
    sessionStorage.department = "";
    sessionStorage.role = "";
    sessionStorage.user = "";
    sessionStorage.toDepartment = "";
    sessionStorage.toRole = "";
    sessionStorage.toUser = "";
    sessionStorage.fromDate = "";
    sessionStorage.toDate = "";
    sessionStorage.active = "";
    sessionStorage.documentType = "";
    var i = $event.index;
    if (i == 0) {
      this.approvalNeededData();
      this.getDelegatedData();
    }
    else if (i == 1) {
      this.DelegatedDocuments();
    }
    else if (i == 2) {
      this.approvedByMeData();
    }
    else if (i == 3) {
      this.rejectedByMeData();
    }
  }
  countApproved() {
    this.DocumentsCount3 = 0;
    if (this.docsData3 != undefined && this.docsData3 != null) {
      var approved = this.docsData3;
      if (sessionStorage.ecrn != "" && sessionStorage.ecrn != null)
        approved = approved.filter(x => x.ecrn != null && x.ecrn != "" && x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
      if (sessionStorage.code != "")
        //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        approved = approved.filter(x => x.code != null && x.code != "" && x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.revision != "")
        approved = approved.filter(x => x.revision.toString().includes(sessionStorage.revision.toString()));
      if (sessionStorage.eco != "")
        approved = approved.filter(x => x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
      if (sessionStorage.title != "")
        approved = approved.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.departmentName != "")
        approved = approved.filter(x => x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
      if (sessionStorage.owner != "")
        approved = approved.filter(x => x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
      if (sessionStorage.status != "")
        approved = approved.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      if (sessionStorage.lastAccessedDate != "")
        approved = approved.filter(x => x.lastAccessedDate.toString().toLowerCase().includes(sessionStorage.lastAccessedDate.toLowerCase()));
      this.DocumentsCount3 = approved.length;
    }
  }

  countReject() {
    if (this.docsData4 != undefined && this.docsData4 != null) {
      var reject = this.docsData4;
      if (sessionStorage.ecrn != "" && sessionStorage.ecrn != null)
        reject = reject.filter(x => x.ecrn != null && x.ecrn != "" && x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
      if (sessionStorage.code != "")
        //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        reject = reject.filter(x => x.code != null && x.code != "" && x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.revision != "")
        reject = reject.filter(x => x.revision.toString().includes(sessionStorage.revision.toString()));
      if (sessionStorage.eco != "")
        reject = reject.filter(x => x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
      if (sessionStorage.title != "")
        reject = reject.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.departmentName != "")
        reject = reject.filter(x => x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
      if (sessionStorage.owner != "")
        reject = reject.filter(x => x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
      if (sessionStorage.status != "")
        reject = reject.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      if (sessionStorage.lastAccessedDate != "")
        reject = reject.filter(x => x.lastAccessedDate.toString().toLowerCase().includes(sessionStorage.lastAccessedDate.toLowerCase()));
      this.DocumentsCount4 = reject.length;
    }
    else
      this.DocumentsCount4 = 0;
  }
  getDelegatedData() {
    this.showLoading = true;
    this._facadeService.approvalDelegatedData(this.userId).subscribe(
      (data) => {
        this.docsData1 = data.returnObject;
        console.log("DelegatedData: ", this.docsData1);
        this.CountData2();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  CountData1() {
    this.DocumentsCount = 0;
    if (this.docsData != undefined && this.docsData != null) {
      var temp1 = this.docsData;
      if (sessionStorage.ecrn != "" && sessionStorage.ecrn != null)
        temp1 = temp1.filter(x => x.ecrn != null && x.ecrn != "" && x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
      if (sessionStorage.code != "" && sessionStorage.code != null && sessionStorage.code != undefined)
        //temp1 = temp1.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        temp1 = temp1.filter(x => x.code != null && x.code != "" && x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.revision != "")
        temp1 = temp1.filter(x => x.revision.toString().includes(sessionStorage.revision.toString()));
      if (sessionStorage.eco != "")
        temp1 = temp1.filter(x => x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
      if (sessionStorage.title != "")
        temp1 = temp1.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.departmentName != "")
        temp1 = temp1.filter(x => x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
      if (sessionStorage.owner != "")
        temp1 = temp1.filter(x => x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
      if (sessionStorage.status != "")
        temp1 = temp1.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      this.DocumentsCount = temp1.length;
    }
  }
  CountData2() {
    this.DocumentsCount1 = 0;
    if (this.docsData1 != undefined && this.docsData1 != null) {
      var temp2 = this.docsData1;
      if (sessionStorage.ecrn != "" && sessionStorage.ecrn != null)
        temp2 = temp2.filter(x => x.ecrn != null && x.ecrn != "" && x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
      if (sessionStorage.code != "" && sessionStorage.code != null && sessionStorage.code != undefined)
        //temp1 = temp1.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        temp2 = temp2.filter(x => x.code != null && x.code != "" && x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.revision != "")
        temp2 = temp2.filter(x => x.revision.toString().includes(sessionStorage.revision.toString()));
      if (sessionStorage.eco != "")
        temp2 = temp2.filter(x => x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
      if (sessionStorage.title != "")
        temp2 = temp2.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.departmentName != "")
        temp2 = temp2.filter(x => x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
      if (sessionStorage.owner != "")
        temp2 = temp2.filter(x => x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
      if (sessionStorage.status != "")
        temp2 = temp2.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      this.DocumentsCount1 = temp2.length;
    }
  }

  DelegatedDocuments() {
    this.showLoading = true;
    this._facadeService.DelegatedDocuments(this.userId).subscribe(
      (data) => {
        if (data.returnObject.length > 0) {
          data.returnObject.forEach((value, key) => {
            if (value.eco != null && value.eco != "" && value.eco != undefined) {
              value.eco = value.eco;
            }
            else {
              value.eco = "N/A";
            }
          });
        }
        this.docsData = data.returnObject;
        this.CountData1();
        console.log(this.docsData);
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  test1(val, field, dt) {
    if (dt.name == "dt1") {
      if (field == "ecrn")
        sessionStorage.ecrn = val;
      else if (field == "code")
        sessionStorage.code = val;
      else if (field == "revision")
        sessionStorage.revision = val;
      else if (field == "title")
        sessionStorage.title = val;
      else if (field == "departmentName")
        sessionStorage.departmentName = val;
      else if (field == "eco")
        sessionStorage.eco = val;
      else if (field == "owner")
        sessionStorage.owner = val;
      else if (field == "status")
        sessionStorage.status = val;
      this.CountData1();
    }
    dt = dt.filter(val, field, 'contains');
  }
  test2(val, field, dt) {
    if (field == "ecrn")
      sessionStorage.ecrn = val;
    else if (field == "code")
      sessionStorage.code = val;
    else if (field == "revision")
      sessionStorage.revision = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "departmentName")
      sessionStorage.departmentName = val;
    else if (field == "eco")
      sessionStorage.eco = val;
    else if (field == "owner")
      sessionStorage.owner = val;
    else if (field == "status")
      sessionStorage.status = val;
    this.CountData2();
    dt = dt.filter(val, field, 'contains');
  }

  test3(val, field, dt) {
    if (field == "ecrn")
      sessionStorage.ecrn = val;
    else if (field == "code")
      sessionStorage.code = val;
    else if (field == "revision")
      sessionStorage.revision = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "departmentName")
      sessionStorage.departmentName = val;
    else if (field == "eco")
      sessionStorage.eco = val;
    else if (field == "owner")
      sessionStorage.owner = val;
    else if (field == "status")
      sessionStorage.status = val;
    else if (field == "lastAccessedDate")
      sessionStorage.lastAccessedDate = val;   
    dt = dt.filter(val, field, 'contains');
    this.countApproved();
  }

  test4(val, field, dt) {
    if (dt.name == "dt1") {
      if (field == "ecrn")
        sessionStorage.ecrn = val;
      else if (field == "code")
        sessionStorage.code = val;
      else if (field == "revision")
        sessionStorage.revision = val;
      else if (field == "title")
        sessionStorage.title = val;
      else if (field == "departmentName")
        sessionStorage.departmentName = val;
      else if (field == "eco")
        sessionStorage.eco = val;
      else if (field == "owner")
        sessionStorage.owner = val;
      else if (field == "status")
        sessionStorage.status = val;
      this.countReject();
    }
    dt = dt.filter(val, field, 'contains');
  }
  approvedByMeData() {
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null){
          userFeature = true;
        }
      }
    }
    this.myApprovalModel = {
      documentId: 0,
      departmentId: 0,
      isArchived: 'N',
      documentTypeId: 0,
      documentStatus: "Approved",
      todayDate: this.datePipe.transform(this.today, 'MM/dd/yyyy'),
      userID: this.userId,
      userGuid: this.userGuid,
      accessUserID:userFeature?0:parseInt(sessionStorage.getItem('userId'))
    };
    console.log("Search model2::" + JSON.stringify(this.myApprovalModel))
    this.showLoading = true;
    this._facadeService.approvalNeededData(this.myApprovalModel).subscribe(
      (data) => {
        if (data.returnObject.length > 0) {
          data.returnObject.forEach((value, key) => {
            if (value.status == 'New' || value.status == 'In-Process' || value.status == 'Obsolete In-Process') {
              value.lastAccessedDate = "--";
            }
            else {
              value.lastAccessedDate = this.datePipe.transform(value.lastAccessedDate, 'MM/dd/yyyy');
            }
            if (value.eco != null && value.eco != "" && value.eco != undefined) {
              value.eco = value.eco;
            }
            else {
              value.eco = "N/A";
            }
            /*
            if(value.revision!=null &&value.revision!="" && value.revision!=undefined){
              if(value.revision === -1)
                value.revision = "N/A"
            }
            */
          });
        }
        this.docsData3 = data.returnObject;
        this.countApproved();
        console.log(this.docsData3);
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  rejectedByMeData() {
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null){
          userFeature = true;
        }
      }
    }
    this.myApprovalModel = {
      documentId: 0,
      departmentId: 0,
      isArchived: 'N',
      documentTypeId: 0,
      documentStatus: "Rejected",
      todayDate: this.datePipe.transform(this.today, 'MM/dd/yyyy'),
      userID: this.userId,
      userGuid: this.userGuid,
      accessUserID:userFeature?0:parseInt(sessionStorage.getItem('userId'))
    };
    console.log("Search model2::" + JSON.stringify(this.myApprovalModel))
    this.showLoading = true;
    this._facadeService.approvalNeededData(this.myApprovalModel).subscribe(
      (data) => {
        if (data.returnObject.length > 0) {
          data.returnObject.forEach((value, key) => {
            if (value.status == 'New' || value.status == 'In-Process'|| value.status == 'Obsolete In-Process') {
              value.lastAccessedDate = "--";
            }
            else {
              value.lastAccessedDate = this.datePipe.transform(value.lastAccessedDate, 'MM/dd/yyyy');
            }
            if (value.eco != null && value.eco != "" && value.eco != undefined) {
              value.eco = value.eco;
            }
            else {
              value.eco = "N/A";
            }
            /*
            if(value.revision!=null &&value.revision!="" && value.revision!=undefined){
              if(value.revision === -1)
                value.revision = "N/A"
            }
            */
          });
        }
        this.docsData4 = data.returnObject;
        this.countReject();
        console.log(this.docsData4);
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  approvalNeededData() {
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null){
          userFeature = true;
        }
      }
    }
    this.myApprovalModel = {
      documentId: 0,
      departmentId: 0,
      isArchived: 'N',
      documentTypeId: 0,
      documentStatus: "In-Process",
      todayDate: this.datePipe.transform(this.today, 'MM/dd/yyyy'),
      userID: this.userId,
      userGuid: this.userGuid,
      accessUserID:userFeature?0:parseInt(sessionStorage.getItem('userId'))
    };
    console.log("Search model2::" + JSON.stringify(this.myApprovalModel));
    
    this.showLoading = true;
    this._facadeService.approvalNeededData(this.myApprovalModel).subscribe(
      (data) => {
        this.GetDelegations();
        if (data.returnObject.length > 0) {
          data.returnObject.forEach((value, key) => {
            if (value.status == 'New' || value.status == 'In-Process' || value.status == 'Obsolete In-Process') {
              value.lastAccessedDate = "--";
            }
            else {
              value.lastAccessedDate = this.datePipe.transform(value.lastAccessedDate, 'MM/dd/yyyy');
            }
            if (value.eco != null && value.eco != "" && value.eco != undefined) {
              value.eco = value.eco;
            }
            else {
              value.eco = "N/A";
            }
            /*
            if(value.revision!=null &&value.revision!="" && value.revision!=undefined){
              if(value.revision === -1)
                value.revision = "N/A"
            }
            */
          });
        }
        this.docsData = data.returnObject;
        this.CountData();
        console.log(this.docsData);
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  GetDelegations() {
    var delegationData = {
      departmentId: 0,
      roleId: 0,
      userId: this.userId,
      toDepartmentId: 0,
      toRoleId: 0,
      toUserId: 0,
      active: "Y"
    };
    this.showLoading = true;
    this._facadeService.getDelegations(delegationData).subscribe(
      (data) => {
        this.showLoading = false;
        if (data != null && data.returnCode == 0) {
          if (data.returnObject.length > 0) {
            this.delegationId = data.returnObject[0].delegationId;
            if (this.delegationId > 0) {
              this.DelegationExist = true;
              this.AreYouSureText = "Are you sure want to cancel the delegation to " + data.returnObject[0].toUser + " ?";
            }
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  CancelDelegation() {
    this.showLoading = true;
    this._facadeService.cancelDelegation(this.delegationId, this.userId).subscribe(
      (data) => {
        this.showLoading = false;
        if (data != null && data.returnCode == 0) {
          this.DelegationExist = false;
          this.deleteDisplay = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  // exportExcel1(ID, Title) {
  //   /* table id is passed over here */
  //   let element = document.getElementById(ID);
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   this.delete_row4(wb.Sheets.Sheet1, 1, ID);
  //   ws['!cols'] = [{ wpx: 80 }, { wpx: 300 }, { wpx: 150 }, { wpx: 30 }, { wpx: 50 }, { wpx: 100 }, { wpx: 70 }];
  //   /* save to file */
  //   XLSX.writeFile(wb, Title + ".xlsx");
  // }
  exportExcel1(ID, Title) {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel1(data,Title);
    }, 100); // Adjust the timeout as needed
  }
  // Helper function to get visible columns and data from the p-table
getDataFromTable(table: Table): { columns: any[], data: any[] } {
  const visibleColumns = table.columns.filter(column => column.field);
  const columns = visibleColumns.map(column => {
    return {
      header: typeof column.header === 'object' ? column.header['name'] : column.header,
      field: column.field
    };
  });
  // Use the 'filteredValue' property if available, otherwise fallback to 'value'
  const data = (table.filteredValue || table.value).map(item => {
    const rowData = {};
    visibleColumns.forEach(column => {
      const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
      rowData[columnHeader] = item[column.field];
    });
    return rowData;
  });

  return { columns, data };
}

// Example function to export data to Excel (you can customize this based on your needs)
exportDataToExcel1(data: { columns: any[], data: any[] },Title) {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });

  // Add headers separately
  const headerRows = [data.columns.map(column => column.header)];
  XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Customize the column widths if needed
  ws['!cols'] = [{ wpx: 80 }, { wpx: 300 }, { wpx: 150 }, { wpx: 30 }, { wpx: 50 }, { wpx: 100 }, { wpx: 70 }];

  // Save to file
  XLSX.writeFile(wb, Title + ".xlsx");
}
exportExcel2(ID, Title) {
  // Manually load all data
  this.table3.first = 0;
  this.table3.rows = this.table3.totalRecords;

  // Wait for data to load (assuming it's loaded asynchronously)
  setTimeout(() => {
    const data = this.getDataFromTable(this.table3);

    // Proceed with exporting data to Excel
    this.exportDataToExcel2(data,Title);
  }, 100); // Adjust the timeout as needed
}
exportDataToExcel2(data: { columns: any[], data: any[] },Title) {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });

  // Add headers separately
  const headerRows = [data.columns.map(column => column.header)];
  XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Customize the column widths if needed
  ws['!cols'] = [{ wpx: 130 }, { wpx: 300 }, { wpx: 150 }, { wpx: 30 }, { wpx: 50 }, { wpx: 140 }, { wpx: 100 }];

  // Save to file
  XLSX.writeFile(wb, Title + ".xlsx");
}
  // exportExcel2(ID, Title) {
  //   /* table id is passed over here */
  //   let element = document.getElementById(ID);
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   this.delete_row3(wb.Sheets.Sheet1, 1, ID);
  //   ws['!cols'] = [{ wpx: 130 }, { wpx: 300 }, { wpx: 150 }, { wpx: 30 }, { wpx: 50 }, { wpx: 140 }, { wpx: 100 }];
  //   /* save to file */
  //   XLSX.writeFile(wb, Title + ".xlsx");
  // }
  // exportExcel4(ID, Title) {
  //   /* table id is passed over here */
  //   let element = document.getElementById(ID);
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   this.delete_row4(wb.Sheets.Sheet1, 1, ID);
  //   ws['!cols'] = [{ wpx: 80 }, { wpx: 400 }, { wpx: 150 }, { wpx: 30 }, { wpx: 50 }, { wpx: 140 }, { wpx: 90 }, { wpx: 90 }];
  //   /* save to file */
  //   XLSX.writeFile(wb, Title + ".xlsx");
  // }

  exportExcel4(ID, Title) {
    // Manually load all data
    this.table4.first = 0;
    this.table4.rows = this.table4.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table4);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel4(data,Title);
    }, 100); // Adjust the timeout as needed
  }
  exportDataToExcel4(data: { columns: any[], data: any[] },Title) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx: 80 }, { wpx: 400 }, { wpx: 150 }, { wpx: 30 }, { wpx: 50 }, { wpx: 140 }, { wpx: 90 }, { wpx: 90 }];
  
    // Save to file
    XLSX.writeFile(wb, Title + ".xlsx");
  }
  exportExcel5(ID, Title) {
    // Manually load all data
    this.table5.first = 0;
    this.table5.rows = this.table5.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table5);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel5(data,Title);
    }, 100); // Adjust the timeout as needed
  }
  exportDataToExcel5(data: { columns: any[], data: any[] },Title) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx: 80 }, { wpx: 400 }, { wpx: 150 }, { wpx: 30 }, { wpx: 50 }, { wpx: 140 }, { wpx: 90 }, { wpx: 90 }];
  
    // Save to file
    XLSX.writeFile(wb, Title + ".xlsx");
  }
  print2(ID, SearchID, Title, HideColumn) {
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table3.virtualScroll;
    this.table3.virtualScroll = false;
  
    // Refresh the data of the table
    this.table3.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    var Content = document.getElementById(ID).innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table3.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content, Title, SearchID, HideColumn);
  }
  print3(ID, SearchID, Title, HideColumn) {
     // Disable virtual scrolling temporarily to ensure all rows are rendered
     const virtualScroll = this.table1.virtualScroll;
     this.table1.virtualScroll = false;
   
     // Refresh the data of the table
     this.table1.clear();
   
     // Detect changes immediately to ensure the table is rendered
     this.cdr.detectChanges();
   
     // Get the entire content of the 'invoice-content' element
     var Content = document.getElementById(ID).innerHTML;
   
     // Revert virtual scrolling to its original state
     this.table1.virtualScroll = virtualScroll;
   
     // Print the content
     this.printService.Print(Content, Title, SearchID, HideColumn);
  }
  print(ID, SearchID, Title, HideColumn) {
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    var Content = document.getElementById(ID).innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content, Title, SearchID, HideColumn);
  }
  print4(ID, SearchID, Title, HideColumn) {
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table4.virtualScroll;
    this.table4.virtualScroll = false;
  
    // Refresh the data of the table
    this.table4.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    var Content = document.getElementById(ID).innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table4.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content, Title, SearchID, HideColumn);
 }
 print5(ID, SearchID, Title, HideColumn) {
  // Disable virtual scrolling temporarily to ensure all rows are rendered
  const virtualScroll = this.table5.virtualScroll;
  this.table5.virtualScroll = false;

  // Refresh the data of the table
  this.table5.clear();

  // Detect changes immediately to ensure the table is rendered
  this.cdr.detectChanges();

  // Get the entire content of the 'invoice-content' element
  var Content = document.getElementById(ID).innerHTML;

  // Revert virtual scrolling to its original state
  this.table5.virtualScroll = virtualScroll;

  // Print the content
  this.printService.Print(Content, Title, SearchID, HideColumn);
}
  viewDocDetails(selectedDocId,WFID,status) {
    sessionStorage.docId = selectedDocId;
    sessionStorage.isFrom = "ApprovalNeeded";
    sessionStorage.Appstatus =status;
    this.router.navigate(['/docdetails']); 
  }

  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
      const activity = parseInt(value);

      if (!isNaN(activity)) {
        this.table.filter(activity, 'activity', 'gte');
      }
    }
  }

  test(val, field, dt) {
    if (field == "ecrn")
      sessionStorage.ecrn = val;
    else if (field == "code")
      sessionStorage.code = val;
    else if (field == "revision")
      sessionStorage.revision = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "departmentName")
      sessionStorage.departmentName = val;
    else if (field == "eco")
      sessionStorage.eco = val;
    else if (field == "owner")
      sessionStorage.owner = val;
    else if (field == "status")
      sessionStorage.status = val;
    else if (field == "lastAccessedDate")
      sessionStorage.lastAccessedDate = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }

  CountData() {
    if (this.docsData != undefined && this.docsData != null) {
      var temp = this.docsData;
      if (sessionStorage.ecrn != "" && sessionStorage.ecrn != null)
        temp = temp.filter(x => x.ecrn != null && x.ecrn != "" && x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
      if (sessionStorage.code != "")
        //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        temp = temp.filter(x => x.code != null && x.code != "" && x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.revision != "")
        temp = temp.filter(x => x.revision.toString().includes(sessionStorage.revision.toString()));
      if (sessionStorage.eco != "")
        temp = temp.filter(x => x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
      if (sessionStorage.title != "")
        temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.departmentName != "")
        temp = temp.filter(x => x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
      if (sessionStorage.owner != "")
        temp = temp.filter(x => x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
      if (sessionStorage.status != "")
        temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      if (sessionStorage.lastAccessedDate != "")
        temp = temp.filter(x => x.lastAccessedDate.toString().toLowerCase().includes(sessionStorage.lastAccessedDate.toLowerCase()));
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }

  UserDelegation() {
    sessionStorage.FromAdmin = false;
    location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'delegatetouser?action=approvalsneeded';
  }
  GetFile(rowData) {
    if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
      this.showLoading = true;
      var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
      this.Download(rowData.fileName, newname, rowData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  
  }
}
