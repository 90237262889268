<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
        <h5 style="color:#495057; padding:15px 0 0 15px;">Manage Document Originator</h5>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-6 card-header" style="margin-top: 25px; padding-left: 30px;">
          <label style="text-align: right;">Document:&nbsp;&nbsp;</label>
          <p-autoComplete [(ngModel)]="selectedDocument" [suggestions]="filteredProdType" class="width350"
            placeholder="Select Document" (completeMethod)="filterproductListChange($event)" field="documentName"
            [dropdown]="true" (onSelect)="documentChange()">
            <ng-template>
              <div>
                <div>{{ filteredProdType.documentName }}</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <hr />
      <div class="p-grid p-fluid p-formgrid" *ngIf="ShowDOC">
        <div class="p-field p-col-12 p-md-4 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 250px">
            <ul class="widget-image-list">
              <li>
                <span style="font-weight: bold">ID:</span>
                <span *ngIf="ShowDownload"><a href="javascript:;" (click)="GetFile()">{{ docsDetailsData?.code }}</a></span>
                <span *ngIf="!ShowDownload">{{ docsDetailsData?.code }}</span>
              </li>
              <li>
                <span style="font-weight: bold">{{ RevorECO }}:</span>
                <span>{{ RevorECOCount }} </span>
              </li>
              <li>
                <span style="font-weight: bold">Type:</span>
                <span>{{ docsDetailsData?.documentType }}</span>
              </li>
              <li>
                <span style="font-weight: bold">Site:</span>
                <span>{{ docsDetailsData?.site }}</span>
              </li>
              <li style="height: 41px; padding-bottom: 0px; padding-top: 4px">
                <span style="font-weight: bold">Product:</span>
                <span *ngIf="showProductName">{{ docsDetailsData?.productName }}</span>
                <span *ngIf="!showProductName" class="listitem-value">
                  <button pButton pRipple type="button" icon="pi pi-eye" (click)="projectdisplay=true" pTooltip="View"
                    tooltipPosition="top" class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="float: right; height: 20px; width: 20px;color: #ffffff;
                            background: #689F38; border: 1px solid #689F38;"></button>
                </span>
              </li>
              <li>
                <span style="font-weight: bold">Alt Doc Id:</span>
                <span>{{ docsDetailsData?.alternatedDocId }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 250px">
            <ul class="widget-image-list">
              <li>
                <span style="font-weight: bold">Initiated Date:</span>
                <span>{{ lastAccessDate | date:'MM/dd/yyyy' }}</span>
              </li>
              <li>
                <span style="font-weight: bold">Effective Date:</span>
                <span class="listitem-value">{{ effectiveDate | date:'MM/dd/yyyy' }}</span>
              </li>
              <li>
                <span style="font-weight: bold">Next Review Date:</span>
                <span class="listitem-value">{{ reviewDate | date:'MM/dd/yyyy' }}</span>
              </li>
              <li>
                <span style="font-weight: bold">Expiration Date:</span>
                <span class="listitem-value">{{ expiryDate | date:'MM/dd/yyyy' }}
                </span>
              </li>
              <li>
                <span style="font-weight: bold">Status:</span>
                <span class="listitem-value">{{ docsDetailsData?.displayStatus }}</span>
              </li>
              <li *ngIf="userFeature">
                <span style="font-weight: bold">Revise Document:</span>
                <span><a href="javascript:;" (click)="GetApprovedFile()">{{ApprovedFile}}</a></span>
              </li>
              <li *ngIf="!userFeature">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 250px">
            <ul class="widget-image-list">
              <li>
                <span style="font-weight: bold">Document Originator:</span>
                <span class="listitem-value">{{ docsDetailsData?.owner }}</span>
              </li>
              <li>
                <span style="font-weight: bold">Workflow:</span>
                <span class="listitem-value">{{ docsDetailsData?.workflow }}</span>
              </li>
              <li style=" padding-bottom: 0px; padding-top: 4px">
                <span style="font-weight: bold">Department:</span>
                <span class="listitem-value">{{docsDetailsData?.departmentName}}</span>
              </li>
              <li style="height: 41px; padding-bottom: 0px; padding-top: 4px">
                <span style="font-weight: bold">Comments:</span>
                <span class="listitem-value">
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="commentsList.length > 0" pTooltip="View"
                    tooltipPosition="top" (click)="commentsDisplay = true" class="p-button-raised p-button-primary p-mr-2 p-mb-2" 
                    style="float: right; height: 20px; width: 20px;color: #ffffff;background: #689F38;border: 1px solid #689F38;"></button>
                  <button pButton pRipple type="button" icon="pi pi-eye" [disabled]="true" *ngIf="commentsList.length === 0" pTooltip="View" tooltipPosition="top"
                    (click)="commentsDisplay = true" class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="float: right; height: 20px; width: 20px;"></button>
                </span>
              </li>
              <li style="padding-bottom: 0px; padding-top: 4px;">
                <span style="font-weight: bold">Remarks:</span>
                <span class="listitem-value">
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="docsDetailsData?.remarks" pTooltip="View" tooltipPosition="top" (click)="remarksDisplay = true"
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="float: right; height: 20px; width: 20px;color: #ffffff;background: #689F38;border: 1px solid #689F38;"></button>
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="!docsDetailsData?.remarks" [disabled]="true" pTooltip="View" tooltipPosition="top"
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="float: right; height: 20px; width: 20px"></button>
                </span>
              </li>
              <li style="padding-bottom: 0px; padding-top: 4px;">
                <span style="font-weight: bold">Originator Changes:</span>
                <span class="listitem-value">
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="Reasons.length > 0" pTooltip="View" tooltipPosition="top" (click)="OriginatorDisplay = true"
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="float: right; height: 20px; width: 20px;color: #ffffff;background: #689F38;  border: 1px solid #689F38;"></button>
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="Reasons.length == 0" [disabled]="true" pTooltip="View" tooltipPosition="top" 
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="float: right; height: 20px; width: 20px"></button>
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-12 p-xl-12" *ngIf="ShowECRN">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 100px">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label style="font-weight: bold">ECRN:</label>
              <input id="Title" type="text" pInputText placeholder="{{docsDetailsData?.ecrn}}" disabled="true" style="opacity: 1.0;" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label style="font-weight: bold">Date:</label>
              <input id="Title" type="text" pInputText placeholder="{{docsDetailsData?.changeDate}}" disabled="true" style="opacity: 1.0;" />
            </div>
            <div class="p-field p-col-12 p-md-6 ">
              <label style="font-weight: bold">ECRN Description of Change:</label>
              <textarea type="text" rows="4" placeholder="{{docsDetailsData?.changeDescription}}" disabled="true" style="opacity: 1.0;" pInputTextarea></textarea>
            </div>
            <div class="p-field p-col-12 p-md-6 ">
              <label style="font-weight: bold">Reasons for Change:</label>
              <textarea placeholder="{{docsDetailsData?.changeReasons}}" type="text" rows="4" disabled="true" style="opacity: 1.0;" pInputTextarea></textarea>
            </div>
          </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-12 p-xl-12">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 100px">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <span style="font-weight: bold">Description:</span>
                <textarea style="opacity: 0.9;" [(ngModel)]="DetailDescription" type="text"
                  rows="2" [maxLength]="300" pInputTextarea [disabled]=true></textarea>
              </div>
              <div class="p-col-12 p-md-4">
                <span style="font-weight: bold">Document Originator: <span style="color: red">*</span></span>
                <!-- <p-dropdown required="true" placeholder="Select Document Originator" optionLabel="fullName" [options]="users" [(ngModel)]="selecteduser" 
                  requiredMessage="The input must not be empty"></p-dropdown> -->
                  <p-dropdown [options]="users" [(ngModel)]="selecteduser" optionLabel="fullName" [filter]="true"
                  [filterBy]="fullName" placeholder="Select a User">
                </p-dropdown>
              </div>
              <div class="p-field p-col-8 ">
                <span style="font-weight: bold">Reason: <span style="color: red">*</span></span>
                <textarea [(ngModel)]="Reason" id="DetailDescription" placeholder="Enter Reason" type="text" rows="2" [maxLength]="300" pInputTextarea ></textarea>
              </div>
              <div class="p-field p-col-12">
                <div class="p-grid">
                  <div class="p-field p-col-12 p-md-10"></div>
                  <div class="p-field p-col-4 p-md-2">
                    <br />
                    <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Update" (click)="UpdateOrg()"
                      class="p-button-raised p-button-success p-mr-2 p-mb-2"
                      style="width: 150px;float: right;"></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <p>{{ Error }}</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" label="Ok" (click)="ErrorDisplay = false" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Products" [(visible)]="projectdisplay" modal="modal" showEffect="fade" >
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-6 p-xl-12">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:250px;">
              <ul class="widget-image-list">
              <li *ngFor="let k of mulproducts">
                  <span>{{ k.productName }}</span>
              </li>
              </ul>
          </div>
      </div>
  </div>
</p-dialog>

<p-dialog header="Comments" [(visible)]="commentsDisplay" modal="modal" showEffect="fade"
[style]="{ width: '60%' }">
<hr />

<div class="p-grid p-fluid p-formgrid">
  <div class="p-field p-col-12" style="text-align: center">
    <div *ngIf="commentsExist" class="card no-gutter widget-overview-box widget-overview-box-1"
      style="min-height: 230px; overflow-y: scroll">
      <ul class="widget-image-list" *ngFor="let appr of commentsList">
        <li>
          <span class="listitem-value">
            <ul>
              <li style="text-align: left">
                <div style="float: left">
                  <span style="color: red;font-size: 16px; padding-right: 10px;">{{ appr.approver }}:</span>
                  <span style="color: #2196f3;font-size: 14px;padding-right: 10px;">{{ appr.approvedDate | date: "MM/dd/yyyy hh:mm a" }}:</span>{{ appr.comments }}
                </div>
              </li>
            </ul>
          </span>
        </li>
      </ul>
    </div>
    <div *ngIf="!commentsExist" class="card no-gutter widget-overview-box widget-overview-box-1"
      style="min-height: 230px; overflow-y: scroll">
      <ul class="widget-image-list">
        <li>
          <span class="listitem-value">
            <ul>
              <li style="text-align: left">
                <div style="float: left">
                  <span style="font-size: 16px; padding-right: 10px">No Comments to show.</span>
                </div>
              </li>
            </ul>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
</p-dialog>

<p-dialog header="Remarks" [(visible)]="remarksDisplay" modal="modal" showEffect="fade"
[style]="{ width: '60%' }">
<hr />
<div class="p-grid p-fluid p-formgrid">
  <div class="p-field p-col-1"></div>
  <div class="p-field p-col-12 p-md-10" style="text-align: center">
    <ul class="widget-image-list">
      <li>
        <span class="listitem-value">
          <ul>
            <li style="text-align: left">
              <div style="float: left">
                <span style="font-size: 16px; padding-right: 10px">{{ docsDetailsData?.remarks }}</span>
              </div>
            </li>
          </ul>
        </span>
      </li>
    </ul>
  </div>
  <div class="p-field p-col-1"></div>

  <div class="p-field p-col-12 p-md-7"></div>
  <div class="p-field p-col-12 p-md-4"></div>
  <div class="p-field p-col-1 p-md-1"></div>
</div>
</p-dialog>

<p-dialog header="Originator Changes" [(visible)]="OriginatorDisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
          <p-table #dt [value]="Reasons" [columns]="cols" sortMode="multiple" [autoLayout]="true"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedReason"> 
              <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr><th style="text-align: center; width:50px;">S.No</th>
                  <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"
                  [ngClass]="{'width100': i != 4,'width50' : i == 4}"
                  >{{ col.header }}<p-sortIcon [field]="col.field"
                      style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                  <tr [pSelectableRow]="rowData" >
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                      <td *ngIf="i == 0" style="text-align: center;width: 50px;;">{{ j + 1 }}</td>
                      <td *ngIf="i == 0" style="text-align: left;width: 100px;">{{ rowData[col.field] }}</td>
                      <td *ngIf="i == 1" style="text-align: center;width: 100px;">{{ rowData[col.field] | date:'MM/dd/yyyy'}}</td>
                      <td *ngIf="i == 2" style="text-align: left;width: 100px;">{{ rowData[col.field] }}</td>
                      <td *ngIf="i == 3" style="text-align: left;width: 100px;">{{ rowData[col.field] }}</td>
                      <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 50%;">{{ rowData[col.field] }}</td>

                  </ng-container>
                  </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr><td colspan="6">No records found...</td></tr>
              </ng-template>
          </p-table>
      </div>
  </div>              
</p-dialog>