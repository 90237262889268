import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from "lodash";
// import { ecnReportModel } from 'src/app/models/ecnReport_model';

@Component({
  selector: 'app-ecn-reports',
  templateUrl: './ecn-reports.component.html',
  styleUrls: ['./ecn-reports.component.scss']
})
export class EcnReportsComponent implements OnInit {
  showLoading:boolean;
  cols: any[];
  fromDate:any = null;
  toDate:any = null;
  // ecnData: ecnReportModel;
  fromError:string;
  toError:string;

  constructor() { }

  ngOnInit(): void {
    
  this.cols = [
    {
      field: "ecnnumber",
      header: "ECN NUMBER",
      dynamicPlaceHolder: "Search by ECN Number",
    },
    {
      field: "elapsedDays",
      header: "Elapsed Days - Total",
      dynamicPlaceHolder: "Search by Elapsed Days",
    },
    {
      field: "currentapprover",
      header: "Elapsed Days - Current Approver",
      dynamicPlaceHolder: "Search by Current Approver",
    },
    {
      field: "docnumber",
      header: "Document Number",
      dynamicPlaceHolder: "Search by Document Number",
    },
    {
      field: "createdBy",
      header: "CreatedBy",
      dynamicPlaceHolder: "Search by CreatedBy",
    },
    {
      field: "current_approver_name",
      header: "Current Approver Name",
      dynamicPlaceHolder: "Search by Current Approver Name",
    }
  ];
  }

  fromDateChange(){
    this.fromError = "";
      this.toError = "";
     if(this.fromDate != undefined && this.fromDate != "" && 
     new Date(this.fromDate)>new Date(this.fromDate)){
      this.fromError = "From date should not be greater than to date.";
      return;
      }
    else if(this.toDate != undefined && this.toDate != "" && 
    new Date(this.fromDate)>new Date(this.toDate)){
     this.toError = "From date should not be greater than to date.";
     return;
     }
  }

  toDateChange(){
    this.fromError = "";
      this.toError = "";
    if(this.toDate != undefined && this.toDate != "" && 
   new Date(this.fromDate)<new Date(this.fromDate)){
      this.toError = "To date should not be less than From date";
      return;
      }
      else if(this.toDate != undefined && this.toDate != ""
       && new Date(this.toDate)>new Date(this.toDate)){
        this.toError = "Review date should not be greater than expiration date.";
        return;
        }
  }

  GetECNreport(){
    this.fromError = "";
    this.toError = "";
    if (this.fromDate === undefined || this.fromDate === "" || this.fromDate === null ) {
      this.fromError = "Please select Start date.";
    } else if (this.toDate === undefined || this.toDate === "" || this.toDate === null) {
      this.toError = "Please select To date.";
    } /*else if(this.fromDate !== undefined && this.fromDate !== "" && this.fromDate !== null && new Date(this.fromDate) < new Date(this.toDate)){
     this.fromError = "From date should not be greater than To date.";
     }*/
     else if(this.toDate !== undefined && this.toDate !== "" && this.toDate !== null && new Date(this.fromDate) > new Date(this.toDate)){
      this.toError = "To date should not be greater than From date.";
      }
     else{

     }
  }

  print() {
    window.print();
  }
}
