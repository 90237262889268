<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">

      <!-- <span style="float:right; margin-top:15px; margin-bottom:5px; ">
        <span>Department: </span> -->
        <div class="p-grid p-fluid p-formgrid" >
          <div class="p-field p-col-12 p-lg-5 p-md-12 p-sm-12"></div>
          <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
            <label style="padding-right:10px;margin-top: 10px;">Department:</label>
          </div>
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
        <p-dropdown inputId="Department" [options]="departmentsData" [(ngModel)]="selectedDept"
          placeholder="Select Department" optionLabel="departmentName">
        </p-dropdown>
          </div>
        <!-- <span style="margin-left:10px;">Application: </span>
          <span style="margin-right:10px;">
          <p-dropdown inputId="applicationId" [options]="statuses" [(ngModel)]="selectedStatus" placeholder="Select Application"
          optionLabel="applicationName">
          </p-dropdown>
          </span> -->
        <!-- <span> 
        </div>-->
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <button pButton pRipple type="button" (click)="SearchDocuments()" pButton type="button" icon="pi pi-search"
            label="Search" iconPos="left" class="p-button-raised p-button-info "
            style="margin-left:10px;margin-right:5px;width:100%;"></button>
          </div>
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <button pButton pRipple type="button" (click)="ResetDocuments()" pButton type="button" icon="pi pi-refresh"
            label="Reset" iconPos="left" class="p-button-raised p-button-secondary "
            style="margin-left:10px;margin-right:5px;width:100%;"></button>
          </div>
    </div>
        <!-- </span>
      </span> -->
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;width:99.8%;">
        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Document Search - {{DocumentsCount}}</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
          <button pButton pRipple type="button" (click)="exportExcel()" pButton type="button" icon="pi pi-download"
            pTooltip="Download" tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="width:25px;height:25px;"></button>
          <button pButton pRipple type="button" (click)="print()" pButton type="button" icon="pi pi-print"
            pTooltip="Print" tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="width:25px;height:25px;"></button>
        </span>
      </div>
      <!-- <div class="invoice invoice-header" id="invoice-content">
        <p-table #dt [value]="userSearchData" [columns]="cols" class="table-align"  [scrollable]="true" scrollHeight="380px" id="excel-table" dataKey="id"
          styleClass="p-datatable-striped" [rowHover]="true" [rows]="10" [filterDelay]="0" 
          [globalFilterFields]="['code','revision','eco','ecrn','title','departmentName','owner','status']">
          <ng-template pTemplate="header" let-columns>
            <tr>

              
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
              [ngClass]="{'docid': i == 0 || i == 8,'rev': i == 1,'eco': i == 2,'ecrn': i == 3,'title': i == 4,'department': i == 5,
              'status': i == 7,'owner': i == 6}">
                {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="text-align: center; width:80px;">Actions</th>
            </tr>
            <tr ID="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                (input)="test($event.target.value,col.field,dt)"
                  style="margin: -10px 0 -10px 0;width: 95%;  height:25px;">
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left;padding-left: 15px; width: 110px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: center;width: 60px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 2" style="text-align: center;width: 60px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width: 110px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 400px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width: 150px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 6" style="text-align: left; padding-left:15px;width: 160px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 7" style="text-align: center;font-weight: bold;width: 100px;"
                  [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':'#D32F2F'}">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 8" style="text-align: center;width: 110px;">
                  <span *ngIf="rowData.status == 'New' || rowData.status == 'In-Process'">--</span>
                  <span *ngIf="rowData.status != 'New' && rowData.status != 'In-Process'">{{rowData[col.field] | date:'MM/dd/yyyy'}}</span>
                </td>
              </ng-container>
              <td style="text-align: center; width:80px">
                <button pButton type="button" (click)="
                            viewDocDetails(rowData.id, rowData.wfDefinitionId)
                          " icon="pi pi-eye" pTooltip="View" class="p-button-rasised p-button-primary"
                  style="height:25px; width:25px;"></button>
              </td>
            </tr>
          </ng-template> -->

          <div class="invoice invoice-header" id="invoice-content" >
            <p-table (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="userSearchData" id="excel-table" [columns]="cols"  class="table-align" [virtualScroll]="true" [scrollable]="true" scrollHeight="380px"
              [filterDelay]="0" selectionMode="multiple" dataKey="id" styleClass="p-datatable-striped"
              [globalFilterFields]="[ 'code', 'revision','eco','ecrn','title', 'departmentName', 'owner', 'status']">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                  [ngClass]="{'docid': i == 0 || i==8,'rev': i == 1,'eco': i == 2,'ecrn': i == 3,'title': i == 4,'type': i == 5,
                  'owner': i == 6, 'status': i == 7}">  
                  {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th pSortableColumn="price" style="text-align: center; width:70px;">Actions</th>
                </tr>
                <tr id="trSearch">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0;width: 95%; height:25px;" />
                  </th>
                  <th></th>
    
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 90px;">
                      <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                      <td *ngIf="i == 1" style="text-align: center;width: 60px;">
                        {{ rowData[col.field] }}
                      </td>
                      <td *ngIf="i == 2" style="text-align: center;width: 60px;">
                        {{rowData[col.field]}}
                      </td>
                    <td *ngIf="i == 3" style="text-align: left; padding-left:15px;width:120px;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width:400px;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width:130px;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 6" style="text-align: left; padding-left:15px;width: 150px;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 7" style="text-align: left; padding-left:15px;font-weight: bold;width: 140px;"
                    [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                    {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 8 && selectedStatus != 'New' && selectedStatus  != 'In-Process' && selectedStatus != 'Obsolete In-Process'" style="text-align: center;width: 90px;">
                      <span>{{rowData[col.field]}}</span>
                    </td>
                  </ng-container>
                  <td style="text-align: center; width:70px">
                    <button pButton type="button" (click)="
                                viewDocDetails(docsData.id, docsData.wfDefinitionId)
                              " icon="pi pi-eye" pTooltip="View" class="p-button-rasised p-button-primary"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
    
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="10">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>