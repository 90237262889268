import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FacadeService } from "../../facade/facade.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BASE_API_URL_TOKEN } from "src/app/injectors";
import * as _ from "lodash";
import { SelectItem } from 'primeng/api';
import { DatePipe } from "@angular/common";
import { Table } from "primeng/table";

@Component({
  selector: 'app-documentoriginator',
  templateUrl: './documentoriginator.component.html',
  styleUrls: ['./documentoriginator.component.scss']
})
export class DocumentoriginatorComponent implements OnInit {

  selectedDocument: any;
  filteredProdType: any[];
  DocsData: any[];
  ShowDOC:boolean = false;
  docsDetailsData: any;
  ShowDownload: boolean;
  RevorECO: string = "";
  RevorECOCount: any;
  showProductName: boolean;
  projectdisplay: any = false;
  mulproducts: any[] = [];
  lastAccessDate: any;
  effectiveDate: any;
  reviewDate: any;
  expiryDate: any;
  userFeature: boolean;
  ApprovedFile: any;
  commentsList: any[] = [];
  commentsExist: boolean;
  commentsDisplay:boolean;
  approvalsData: any = [];
  remarksDisplay: boolean;
  ShowECRN: boolean;
  DetailDescription: any;
  Reason:any;
  OriginatorDisplay:boolean;
  Reasons:any[];
  selectedReason: any[];
 @ViewChild('dt') table: Table;
 cols:any[];

  users:any[];
  selecteduser:any;
  userId = parseInt(sessionStorage.getItem("userId"));
  userGUID = sessionStorage.getItem("userGuid");
  showLoading: boolean;
  ErrorDisplay: boolean = false;
  Error: string = "";
  constructor(private _facadeService: FacadeService,
    private router: Router, private datePipe: DatePipe,
    private route: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DOC_OWNER_CHANGE");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    this.cols = [
        { field: 'actionByName', header: 'Edited By' },
        { field: 'actionDate', header: 'Edited Date' },
        { field: 'oldOriginator', header: 'Old Originator' },
        { field: 'originator', header: 'New Originator' },
        { field: 'reason', header: 'Reason' }
      ];
    this.GetDocs();
  }
  GetDocs() {
    var myDocsMode = {
      documentId: 0,
      departmentId: 0,
      isArchived: "N",
      documentTypeId: 0,
      documentStatus: "In-Process,Approved",
      todayDate: new Date(),
      userID: 0,
    };
    this._facadeService.mydocsData(myDocsMode).subscribe(
      (data) => {
        if (data.returnObject.length > 0) {
          this.DocsData = data.returnObject;
          if(this.DocsData != null && this.DocsData != undefined)
          {
            this.DocsData.forEach((value, key) => {
              value.documentName = value.code + " : " + value.revision + " : " + value.title;
              if (value.status == 'New' || value.status == 'In-Process' || value.status == 'Obsolete In-Process') {
                value.lastAccessedDate = "";
              }
              else {
                value.lastAccessedDate = this.datePipe.transform(value.lastAccessedDate, 'MM/dd/yyyy');
              }
              if (value.eco != null && value.eco != "" && value.eco != undefined) {
                value.eco = value.eco;
              }
              else {
                value.eco = "N/A";
              }
            });
          }
        }
        this.getUsers();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  getUsers() {
    this.users=[];
    this._facadeService.getUsers().subscribe(
      (data) => {
        this.users = data.returnObject;
        if(this.users != null && this.users != undefined && this.users.length>0)
        {
          this.users.forEach((value, key) => {
            value.fullName = value.firstName + " " + value.lastName;
          });
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  filterproductListChange(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.DocsData.length; i++) {
      let product = this.DocsData[i];
      if (product.documentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(product);
      }
    }
    this.filteredProdType = filtered;
  }
  documentChange() {
    this.showLoading = true;
    this.Reason = "";
    this.ShowDOC = true;
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null)
          userFeature = true;
      }
    }
    var useId = userFeature?0:parseInt(sessionStorage.getItem('userId'));
    this._facadeService.getDocDetailsById(this.selectedDocument.id,useId).subscribe(
      (data) => {
        this.docsDetailsData= data.returnObject;
        this.selecteduser = this.users.find(x=>x.userId == this.docsDetailsData.createdBy);
        this.docsDetailsData.displayStatus=this.docsDetailsData.status;
        if(this.docsDetailsData.isObsInprocess=="Y")
          this.docsDetailsData.displayStatus= "( Obsolete In-Process ) " + this.docsDetailsData.status;
        this.mulproducts = data.returnObject.products;
        if (this.mulproducts == undefined || this.mulproducts == null) 
        {
          this.showProductName = true;
          this.mulproducts = [];
        }
        else 
        {
          if (this.mulproducts.length > 1)
            this.showProductName = false;
          else
            this.showProductName = true;
        }
        if(this.docsDetailsData.eco != null && this.docsDetailsData.eco != "" && this.docsDetailsData.eco != undefined) 
        {
          this.RevorECO = "Rev/Eco";
          this.RevorECOCount = this.docsDetailsData.revision + "/" + this.docsDetailsData.eco;
        } 
        else 
        {
          this.RevorECO = "Rev";
          this.RevorECOCount = this.docsDetailsData.revision;
        }
        if (this.docsDetailsData.effectiveDate !== null && this.docsDetailsData.effectiveDate !== undefined) {
          this.effectiveDate = new Date(this.docsDetailsData.effectiveDate);
          this.effectiveDate = this.effectiveDate.toLocaleDateString();
        }
        if (this.docsDetailsData.reviewDate !== null && this.docsDetailsData.reviewDate !== undefined) {
          this.reviewDate = new Date(this.docsDetailsData.reviewDate);
          this.reviewDate = this.reviewDate.toLocaleDateString();
        }
        if (this.docsDetailsData.expirationDate !== null && this.docsDetailsData.expirationDate !== undefined) {
          this.expiryDate = new Date(this.docsDetailsData.expirationDate);
          this.expiryDate = this.expiryDate.toLocaleDateString();
        }
        if (this.docsDetailsData.lastAccessedDate !== null && this.docsDetailsData.lastAccessedDate !== undefined) {
          this.lastAccessDate = new Date(this.docsDetailsData.lastAccessedDate + "Z");
          this.lastAccessDate = this.lastAccessDate.toLocaleDateString();
        }
        if (this.docsDetailsData.userFinalEditableFileName != '' && this.docsDetailsData.userFinalEditableFileName != undefined && this.docsDetailsData.userFinalEditableFileName != null) 
        {
          if (this.docsDetailsData.finalEditableFileName != '' && this.docsDetailsData.finalEditableFileName != undefined && this.docsDetailsData.finalEditableFileName != null)
            this.ApprovedFile = this.docsDetailsData.userFinalEditableFileName;
          else if (this.docsDetailsData.prePublishedFileName != '' && this.docsDetailsData.prePublishedFileName != undefined && this.docsDetailsData.prePublishedFileName != null)
            this.ApprovedFile = this.docsDetailsData.userFinalEditableFileName;
        }
        if (this.docsDetailsData.creationSource=="CMS") {
          this.ShowECRN = true;
          if(this.docsDetailsData.changeDate!=null&&this.docsDetailsData.changeDate!=""&&this.docsDetailsData.changeDate!=undefined){
            let convertactiondate = new Date(this.docsDetailsData.changeDate+'Z');
            this.docsDetailsData.changeDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
          }
        }
        this.DetailDescription = this.docsDetailsData.description;
        if (this.docsDetailsData.alternatedDocId === "null" || this.docsDetailsData.alternatedDocId === null)
          this.docsDetailsData.alternatedDocId = '';
        if (this.docsDetailsData.remarks === "null")
          this.docsDetailsData.remarks = '';
        if (this.DetailDescription === "null")
          this.DetailDescription = '';

        if(this.docsDetailsData.originatorChanges != null && this.docsDetailsData.originatorChanges != undefined)
        {
          this.docsDetailsData.originatorChanges.forEach(element => {
            var r = element.reason.split("#@#");
            element.oldOriginator = r[0];
            element.reason = r[1];
            if(element.actionDate!=null&&element.actionDate!=""&&element.actionDate!=undefined){
              let convertactiondate = new Date(element.actionDate+'Z');
              element.actionDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
            }
          });
        }
        this.Reasons = this.docsDetailsData.originatorChanges;
        if(this.Reasons == null || this.Reasons == undefined)
          this.Reasons = [];
        this.getApprovals(this.selectedDocument.id);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    
  }
  UpdateOrg()
  {
    if(this.Reason == null || this.Reason == undefined || this.Reason == "")
    {
      this.ErrorDisplay = true;
      this.Error="Please enter reason";
    }
    else if(this.selecteduser == null || this.selecteduser == undefined || this.selecteduser.length == 0)
    {
      this.ErrorDisplay = true;
      this.Error="Please select originator";
    }
    else{
      var myDocsMode = {
        documentId: this.selectedDocument.id,
        newOriginatorId: this.selecteduser.userId,
        reason: this.docsDetailsData.owner + "#@#" + this.Reason,
        userId: this.userId,
        userGuid: this.userGUID
      };
      this._facadeService.UpdateOrginator(myDocsMode).subscribe(
        (data) => {
          if (data.returnCode == 0)
          {
            this.documentChange();
            this.ErrorDisplay = true;
            this.Error="Saved successfully";
          }
          else
          {
            this.ErrorDisplay = true;
            this.Error= data.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  GetFile() {
    if (this.docsDetailsData.fileName != null && this.docsDetailsData.fileName != "" && this.docsDetailsData.fileName != undefined) {
      this.showLoading = true;
      var newname = this.docsDetailsData.userFileName.substr(0, this.docsDetailsData.userFileName.lastIndexOf('.'));
      this.Download(this.docsDetailsData.fileName, newname, this.docsDetailsData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }
  GetApprovedFile() {
    if (this.docsDetailsData.userFinalEditableFileName != '' && this.docsDetailsData.userFinalEditableFileName != undefined && this.docsDetailsData.userFinalEditableFileName != null) {
      if (this.docsDetailsData.finalEditableFileName != '' && this.docsDetailsData.finalEditableFileName != undefined && this.docsDetailsData.finalEditableFileName != null) {
        var newname = this.docsDetailsData.userFinalEditableFileName.substr(0, this.docsDetailsData.userFinalEditableFileName.lastIndexOf('.'));
        this.Download(this.docsDetailsData.finalEditableFileName, newname, this.docsDetailsData.userFinalEditableFileName);

      }
      else if (this.docsDetailsData.prePublishedFileName != '' && this.docsDetailsData.prePublishedFileName != undefined && this.docsDetailsData.prePublishedFileName != null) {
        var newname = this.docsDetailsData.userFinalEditableFileName.substr(0, this.docsDetailsData.userFinalEditableFileName.lastIndexOf('.'));
        this.Download(this.docsDetailsData.prePublishedFileName, newname, this.docsDetailsData.userFinalEditableFileName);

      }
    }
  }
  getApprovals(ID) {
    this.approvalsData = [];
    this.commentsList = [];
    this._facadeService.getApprovals(ID).subscribe(
      (data) => {
        let approvalsReturnData = [];
        approvalsReturnData = data.returnObject;
        if(approvalsReturnData == null || approvalsReturnData == undefined)
          approvalsReturnData = [];
        if (approvalsReturnData.length > 0) {
          approvalsReturnData.forEach(element => {
            if (element.role != "DCC Admin")
              this.approvalsData.push(element);
            if(element.role=="DCC Admin"&& (element.approvalStatus=="Rejected" || element.approvalStatus=="Withdrawn" ))
              this.approvalsData.push(element);
            if (element.approver == "Interim Admin")
              element.approvalStatus = "Skipped Approval";
          });
          this.approvalsData = _.sortBy( this.approvalsData, "ApprovedDate" ).reverse();
        }

        this.approvalsData.forEach((value, key) => {
          if (value.comments != null && value.comments != "")
            this.commentsList.push(value);
        });
        if (this.commentsList.length == 0)
          this.commentsExist = false;
        else
          this.commentsExist = true;
        if (this.docsDetailsData.fileName != "" && this.docsDetailsData.fileName != null && this.docsDetailsData.fileName != undefined)
          this.ShowDownload=true;
        this.showLoading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
