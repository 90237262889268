<div class="p-grid p-fluid p-formgrid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <h5>Delegation Documents</h5>
      <hr><br>
      <div class="p-grid">
        <div class="p-col-12">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <h5 style="text-align:center">Delegation From</h5>
              <hr>
              <div class="p-field p-col-12">
                <br>
                <label for="Department">Department<span style="color: red">*</span></label>
                <!-- <p-dropdown
                    inputId="Department"
                    [options]="departmentsData"
                    [(ngModel)]="selectedDept"
                    placeholder="Select"
                    optionLabel="departmentName"
                    (onChange)="getRoleList()"
                  >
                  </p-dropdown> -->
                <p-autoComplete [(ngModel)]="selectedDept" [suggestions]="filteredDept" (onSelect)="getRoleList('')"
                  (completeMethod)="filterDepartments($event)" field="departmentName" [dropdown]="true">
                  <ng-template>
                    <div>
                      <div>{{ filteredDept.departmentName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ fromDepartmentError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label for="Role">Role<span style="color: red">*</span></label>
                <!-- <p-dropdown
                    inputId="fromRole"
                    [options]="RolesData"
                    [(ngModel)]="selectedRole"
                    placeholder="Select"
                    optionLabel="roleName"
                    (onChange)="getUserList()"
                  >
                  </p-dropdown> -->
                <p-autoComplete [(ngModel)]="selectedRole" [suggestions]="filteredRoles" (onSelect)="getUserList('')"
                  (completeMethod)="filterRoles($event)" field="roleName" [dropdown]="true">
                  <ng-template>
                    <div>
                      <div>{{ filteredRoles.roleName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ fromRoleError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label for="User">User<span style="color: red">*</span></label>
                <p-dropdown inputId="fromUser" [options]="UsersData" [(ngModel)]="selectedUser" placeholder="Select"
                  optionLabel="firstName" (onChange)="ChangeDepartmentFrom()">
                </p-dropdown>
                <small class="p-invalid">{{ fromUserError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label for="FromDate">From Date<span style="color: red">*</span></label>
                <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="fromDate"></p-calendar>
                <small class="p-invalid">{{ fromDateError }}</small>
              </div>

            </div>
            <div class="p-col-12 p-md-6">
              <h5 style="text-align:center">Delegation To</h5>
              <hr>
              <div class="p-field p-col-12">
                <br>
                <label for="ToDepartment">Department<span style="color: red">*</span></label>
                <!-- <p-dropdown
                    inputId="ToDepartment"
                    [options]="TodepartmentsData"
                    [(ngModel)]="ToselectedDept"
                    placeholder="Select"
                    optionLabel="departmentName"
                    (onChange)="to_getRoleList()"
                  >
                  </p-dropdown> -->
                <p-autoComplete [(ngModel)]="ToselectedDept" [suggestions]="filteredToDept"
                  (onSelect)="to_getRoleList('')" (completeMethod)="filterToDepartments($event)" field="departmentName"
                  [dropdown]="true">
                  <ng-template>
                    <div>
                      <div>{{ filteredDept.departmentName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ TodepartmentError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label for="ToRole">Role<span style="color: red">*</span></label>
                <!-- <p-dropdown
                    inputId="ToRole"
                    [options]="ToRolesData"
                    [(ngModel)]="ToselectedRole"
                    placeholder="Select"
                    optionLabel="roleName"
                    (onChange)="to_getUserList()"
                  >
                  </p-dropdown> -->
                <p-autoComplete [(ngModel)]="ToselectedRole" [suggestions]="filteredToRoles"
                  (onSelect)="to_getUserList()" (completeMethod)="filterToRoles($event)" field="roleName"
                  [dropdown]="true">
                  <ng-template>
                    <div>
                      <div>{{ filteredToRoles.roleName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ ToRoleError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label for="ToUser">User<span style="color: red">*</span></label>
                <p-dropdown inputId="ToUser" [options]="ToUsersData" [(ngModel)]="ToselectedUser" placeholder="Select"
                  optionLabel="firstName" (onChange)="ChangeDepartment()">
                </p-dropdown>
                <small class="p-invalid">{{ ToUserError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label for="address">Comments</label>
                <!-- <textarea id="comments" type="text" rows="4" pInputTextarea></textarea> -->
                <textarea id="Comments" type="text" rows="4" [(ngModel)]="comments" pInputTextarea></textarea>
                <small class="p-invalid">{{ commentsError }}</small>
              </div>

            </div>
          </div>
          <br>
          <button pButton pRipple type="button" icon="pi pi-refresh" (click)="resetCtrls()" iconPos="left" label="Reset"
            class="p-button-raised p-button-secondary p-mr-2 p-mb-2" style="width:auto; float:right; "></button>
          <button pButton pRipple type="button" icon="pi pi-reply" iconPos="left" label="Delegation"
            class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="addDelegation()"
            style="width:auto; float:right; "></button>
        </div>
        <small class="p-invalid" style="float:right;">{{ responseError }}</small>
      </div>
    </div>


    <!-- <div class="card">
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; ">
     
        <h4 style="color:#495057; padding:10px 0 0 15px;">Delegation List</h4> 
        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">        
       
        <button pButton pRipple type="button" icon="pi pi-print"  (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;"></button>
        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:15px ; "></button>
  
</span>
    </div> 
      <div  class="invoice invoice-header" id="invoice-content">

            <p-table #dt [value]="delegateData" [columns]="cols" sortMode="multiple" class="table-align"styleClass="p-datatable-striped"
            [filterDelay]="0" selectionMode="multiple" dataKey="id" [globalFilterFields]="['code','title','documentType','effectiveDate','reviewDate','status']">
            <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>                                        
                </tr>
                <tr>
                  <th *ngFor="let col of columns" [ngSwitch]="col.field"
                      style="text-align: center;">
                      <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                          (input)="dt.filter($event.target.value, col.field, 'contains')"
                          style="margin: -10px 0 -10px 0; width:90%">
                  </th>
              </tr>
                
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                        <td *ngIf="i == 0" style="text-align: left;">
                            {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 1" style="text-align: left;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 2" style="text-align: left;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 3" style="text-align: left;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 4" style="text-align: left;">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="i == 5" style="text-align: left;">
                          {{rowData[col.field]}}
                      </td>
                      <td *ngIf="i == 6" style="text-align: center;">
                        {{rowData[col.field] | date: "MM/dd/yyyy"}}
                    </td>
                    <td *ngIf="i == 7" style="text-align: center;">
                      {{rowData[col.field] | date: "MM/dd/yyyy"}}
                  </td>
                    </ng-container>                                        
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="8">No documents found.</td>
              </tr>
          </ng-template>
        </p-table>
          </div>
          
      </div> -->
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
<p-dialog header="Information" [(visible)]="ErrorDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}">
  <hr>
  <div class="p-grid">

    <div class="p-col-12" style="text-align: center;">
      <span style="font-size:18px;">{{ErrorDisplayMessage}}</span>
    </div>
  </div>
</p-dialog>