import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-create-workflowsetup',
  templateUrl: './create-workflowsetup.component.html',
  styleUrls: ['./create-workflowsetup.component.scss']
})

export class CreateWorkflowsetupComponent implements OnInit {

  userId = parseInt(sessionStorage.getItem('userId'));
  docId = parseInt(sessionStorage.getItem('docId'));
  docTypedModel: any;
  docTypes: any;
  workFlowModel: any;
  workFlowData: any;
  docTypeId: any;
  docsTypesData: any;
  selectedDocType: any;
  selectedWorkflow: any;
  workFlowError: any;
  docTypeError: any;
  workflowdefinitions = [];
  workflowdefinitionsResult = [];
  selectedworkflowDefinition:any;
  showLoading:boolean;
  apiResponse: any;
  responseError: any;

  constructor(
    private _facadeService: FacadeService,
    private router: Router
  ) { }

  ngOnInit(): void {
    
    this.getDocsTypes();
    this.getWorkflowDefinitions();
  }

  getDocsTypes() {
    this.showLoading = true;
    this._facadeService.getDocsTypes().subscribe(
      (data) => {
        this.showLoading = false;
        this.docsTypesData = data.returnObject;
        console.log(this.docsTypesData);
        console.log("Document Identifier ::" + JSON.stringify( this.docId));       
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  } 

  getWorkflowDefinitions() {
    this.showLoading = true;
    this._facadeService.getAllWorkDefinitions(false).subscribe((response:any) => {
      this.showLoading = false;
      this.workflowdefinitions = response.returnObject;
      console.log(this.workflowdefinitions);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    })
  }

  createWokrflowSetUp() {
    this.workFlowError="";
    this.docTypeError="";
    if (this.selectedDocType == undefined || this.selectedDocType == '') {
      this.docTypeError = 'Please select document type.';
    }
    else if (this.selectedWorkflow == undefined || this.selectedWorkflow == '') {
      this.workFlowError = 'Please select workflow.';
    }
    else{
      this.showLoading = true;
      console.log("workflowdefinitions: ", JSON.stringify(this.workflowdefinitions));
      const workflow = {
        documentTypeId: this.selectedDocType.documentTypeId,
        documentType: this.selectedDocType.documentType,
        workflowDefinitionId: this.selectedWorkflow.definitionId,
        workflowDefinitionName: this.selectedWorkflow.name
     }
     console.log("workflow: ", JSON.stringify(workflow));
      this._facadeService.createWorkflowSetup(workflow).subscribe(
        (response) => {
          this.showLoading = false;
          this.apiResponse = response;
          if(this.apiResponse.returnCode >= 0){
            this.router.navigate(['/workflow/wfsetup']);
          }
          else{
            this.responseError = this.apiResponse.returnMessage;
          }
          console.log(response);
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );  
    }
  }
  resetCtrls()
  {
    this.workFlowError="";
    this.docTypeError="";
    this.selectedDocType="";
    this.selectedWorkflow="";
  }
}

