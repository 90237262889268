<div class="p-grid p-fluid p-formgrid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="p-field p-col-12 p-lg-9 p-md-12 p-sm-12">
        <h5>Delegation Document Approvals</h5>
        </div><div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12" style="float: right;">
        <a>
          <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left" label="Back"
            class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width: auto;"
            (click)="BackClick()"></button></a>
      </div>
  <p-tabView #tabView [activeIndex]="index" (onChange)="handleChange1($event)">
    <p-tabPanel>
      <ng-template pTemplate="header"><div>By Role</div></ng-template> <!-- (click)="handleChange($event,0)" -->
      <br>
          <div class="p-grid">
            <div class="p-col-12"> 
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                  <h5 style="text-align:center">Delegation From</h5>
                  <hr>
                  <div class="p-field p-col-12">
                    <br>
                    <label>User<span style="color: red">*</span></label>
                    <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="filteredUsers" 
                      (completeMethod)="filteredFromUsers($event)" 
                      field="firstName" 
                      (onSelect)="fromGetUserDetails()"
                      [dropdown]="true"
                      [disabled]="DisableFromUser||fromuserdisabled">
                      <ng-template>
                        <div>
                          <div>{{ filteredUsers.firstName }}</div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                    <small class="p-invalid">{{ fromUserError }}</small>
                  </div>
                  <div class="p-field p-col-12">
                      <br>
                      <label>Roles<span style="color: red">*</span></label>
                      <p-multiSelect [options]="RolesData" [(ngModel)]="selectedRole" defaultLabel="Select a Role" optionLabel="roleName" selectedItemsLabel="{0} items selected"></p-multiSelect>
                      <small class="p-invalid">{{ fromRoleError }}</small>
                    </div>
                  <div class="p-field p-col-12">
                    <br>
                    <label for="FromDate">From Date<span style="color: red">*</span></label>
                    <p-calendar [showIcon]="true" [minDate]="minDate" inputId="icon" [(ngModel)]="fromDate"></p-calendar>
                    <small class="p-invalid">{{ fromDateError }}</small>
                  </div>
                  <div class="p-field p-col-12">
                    <br>
                    <label for="address">Comments</label>
                    <textarea id="Comments" type="text" rows="4" [(ngModel)]="comments" pInputTextarea></textarea>
                    <small class="p-invalid">{{ commentsError }}</small>
                  </div>
                </div>
                <div class="p-col-12 p-md-6">
                  <h5 style="text-align:center">Delegation To</h5>
                  <hr>
                  <div class="p-field p-col-12">
                    <br>
                    <label>User<span style="color: red">*</span></label>
                    <p-autoComplete [(ngModel)]="ToselectedUser" [suggestions]="filteredToUsersData" 
                      (completeMethod)="filteredToUsers($event)" 
                      field="firstName" 
                      (onSelect)="toGetUserDetails()"
                      [dropdown]="true">
                      <ng-template>
                        <div>
                          <div>{{ filteredToUsersData.firstName }}</div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                    <small class="p-invalid">{{ ToUserError }}</small>
                  </div>
                  <div class="p-field p-col-12">
                    <br>
                    <label>Roles<span style="color: red">*</span></label>
                    <p-dropdown [options]="ToRolesData" [(ngModel)]="ToselectedRole" placeholder="Select Role"
                     optionLabel="roleName">                
                   </p-dropdown>
                   <small class="p-invalid">{{ toRoleError }}</small>
                  </div>
                  <div class="p-field p-col-12">
                    <br>
                    <label>To Date</label>
                    <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate"  [(ngModel)]="toDate"></p-calendar>
                  </div>
                  <div class="p-field p-col-12">
                    <br>
                    <br>
                    <br>
                    <br>
                  </div>
                  <div class="p-field p-col-12">
                    <br>
                    <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Clear"
                  class="p-button-raised p-button-secondary p-mr-2 p-mb-2" (click)="ClearControls()"
                  style="width:auto; float:right; "></button>
                  <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add to Delegation List"
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="addDelegation()"
                  style="width:auto; float:right; "></button>
                  
                </div>
                </div>
                <small class="p-invalid" style="float:right;">{{ roleresponseError }}</small>
                
              </div>
              <div class="p-field p-col-12 p-md-8" style="padding-left: 1px;"><br>
                <h5 style="margin-top:5px;">Added Delegations</h5>
              </div>
                  <div class="p-fluid p-formgrid p-grid">
                <p-table #dt [value]="delegateTempData" id="excel-table" [columns]="cols" sortMode="multiple"
                  class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                  dataKey="id" [globalFilterFields]="['user','role','toUser','toRole','fromDate','toDate','comments']">
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                      <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                      </th>
                      <th style="text-align: center; width:60px;">Action</th>
                    </tr>
                    <tr>
                      <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                            (input)="test($event.target.value,col.field,dt)"
                            style="margin: -10px 0 -10px 0;width: 90%; height:25px;" />
                    </th>
                      <th></th>
                    </tr>
                    
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: left;padding-left:10px;">
                          {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 1" style="text-align: left;padding-left:25px;">
                          {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 2" style="text-align: left;padding-left:10px;">
                          {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                          {{ rowData[col.field] }}
                        </td>
                        
                        <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                          {{ rowData[col.field]| date: "MM/dd/yyyy" }}
                        </td>
                        <td *ngIf="i == 5" style="text-align: center;">
                          {{rowData[col.field]| date: "MM/dd/yyyy"}}
                        </td>
                        <td *ngIf="i == 6" style="text-align: center;">
                          {{rowData[col.field]}}
                        </td>
                      </ng-container>
                      <td style="text-align: center; width:60px" >
                        <button pButton type="button" icon="pi pi-trash" pTooltip="Delete" tooltipPosition="right"
                    (click)="deleteTempDelegate(rowData)" class="p-button-raised p-button-danger"
                    style="height: 25px; width: 25px"></button>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="8" style="text-align: left;">No records found.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <br>
              <button pButton pRipple type="button" icon="pi pi-refresh" (click)="resetCtrls()" iconPos="left" label="Reset"
                class="p-button-raised p-button-secondary p-mr-2 p-mb-2" style="width:auto; float:right; "></button>
              <button pButton pRipple type="button" icon="pi pi-reply" iconPos="left" label="Delegation"
                class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="SaveDelegation()"
                style="width:auto; float:right; "></button>
            </div>
            <small class="p-invalid" style="float:right;">{{ responseError }}</small>
            <br>
            <div class="p-field p-col-12 p-md-8" style="padding-left: 1px;" *ngIf="delegateExistingTempData.length>0"><br>
              <h5 style="margin-top:5px;">Existing Delegations</h5>
            </div>
                <div class="p-fluid p-formgrid p-grid" *ngIf="delegateExistingTempData.length>0">
              <p-table #dt [value]="delegateExistingTempData" id="excel-table" [columns]="cols" sortMode="multiple"
                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                dataKey="id" [globalFilterFields]="['user','role','toUser','toRole','fromDate','toDate','comments']">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr>
                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                      {{col.header}}
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                  </tr>
                  <tr>
                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                      <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                          (input)="test($event.target.value,col.field,dt)"
                          style="margin: -10px 0 -10px 0;width: 90%; height:25px;" />
                  </th>
                  </tr>
                  
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr [pSelectableRow]="rowData">
                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                      <td *ngIf="i == 0" style="text-align: left;padding-left:10px;">
                        {{ rowData[col.field] }}
                      </td>
                      <td *ngIf="i == 1" style="text-align: left;padding-left:25px;">
                        {{ rowData[col.field] }}
                      </td>
                      <td *ngIf="i == 2" style="text-align: left;padding-left:10px;">
                        {{ rowData[col.field] }}
                      </td>
                      <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                        {{ rowData[col.field] }}
                      </td>
                      
                      <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                        {{ rowData[col.field]| date: "MM/dd/yyyy" }}
                      </td>
                      <td *ngIf="i == 5" style="text-align: center;">
                        {{rowData[col.field]| date: "MM/dd/yyyy"}}
                      </td>
                      <td *ngIf="i == 6" style="text-align: center;">
                        {{rowData[col.field]}}
                      </td>
                    </ng-container>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="7" style="text-align: left;">No records found.</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <br>
              <div class="p-field p-col-12 p-md-8" style="padding-left: 1px;" *ngIf="skipapprovalExistingTempData.length>0"><br>
                <h5 style="margin-top:5px;">Existing Skip Approval</h5>
              </div>
                  <div class="p-fluid p-formgrid p-grid" *ngIf="skipapprovalExistingTempData.length>0">
                <p-table #dt [value]="skipapprovalExistingTempData" id="excel-table" [columns]="skipcols" sortMode="multiple"
                  class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                  dataKey="id" [globalFilterFields]="['user','role','fromDate','toDate','comments']">
                  <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                      <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                      </th>
                    </tr>
                    <tr>
                      <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                            (input)="test($event.target.value,col.field,dt)"
                            style="margin: -10px 0 -10px 0;width: 90%; height:25px;" />
                    </th>
                    </tr>
                    
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData">
                      <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: left;padding-left:10px;">
                          {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 1" style="text-align: left;padding-left:25px;">
                          {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 2" style="text-align: left;padding-left:10px;">
                          {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                          {{ rowData[col.field] }}
                        </td>
                        
                        <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                          {{ rowData[col.field] }}
                        </td>
                        <td *ngIf="i == 5" style="text-align: center;">
                          {{rowData[col.field]| date: "MM/dd/yyyy"}}
                        </td>
                        <td *ngIf="i == 6" style="text-align: center;">
                          {{rowData[col.field]| date: "MM/dd/yyyy"}}
                        </td>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="7" style="text-align: left;">No records found.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
          </div>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header"><div>By Document</div></ng-template>
      <br>
      <div class="p-grid">
        <div class="p-col-12"> 
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <h5 style="text-align:center">Delegation From</h5>
              <div class="p-field p-col-12 p-md-4">
                <br>
                <label>User<span style="color: red">*</span></label>
                <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="filteredUsers" 
                  (completeMethod)="DocumentfilteredFromUsers($event)" 
                  field="firstName" 
                  (onSelect)="approvalNeededData()"
                  [dropdown]="true"
                  [disabled]="DisableFromUser||fromuserdisabled">
                  <ng-template>
                    <div>
                      <div>{{ filteredUsers.firstName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ fromUserError }}</small>
              </div>
              <div class="p-field p-col-12">
                  <br>
                  <label>Documents<span style="color: red">*</span></label>
                  <div class="invoice invoice-header"  id="invoice-content" style="overflow-x: auto;">
                    <p-table #dt [value]="docsData" [(selection)]="selectedDocuments" id="excel-table" [columns]="doccols" sortMode="multiple" class="table-align"
                      styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
                      [globalFilterFields]="['code','title','revision','owner','touser','torole','comments']">
                      <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                          <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th> 
                          <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                            {{col.header}}
                            <p-sortIcon [field]="col.field"></p-sortIcon>
                          </th>
                        </tr>
                        <tr id="trSearch">
                          <th></th>
                          <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                            <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                            (input)="test($event.target.value,col.field,dt)"
                              style="margin: -10px 0 -10px 0; width:90%; height:25px;">
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                        
                        <tr>
                          <td>
                            <p-tableCheckbox [value]="rowData" *ngIf="rowData.delegationId==0"></p-tableCheckbox>
                        </td>
                          <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                            <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">
                              <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                              <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                            </td>
                            <td *ngIf="i == 1" style="text-align: left;padding-left:35px;">
                              {{rowData[col.field]}}
                            </td>
                            <td *ngIf="i == 2" style="text-align: left;padding-left:35px;">
                              {{rowData[col.field]}}
                            </td>
                            <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                              {{rowData[col.field]}}
                            </td>
                            <td *ngIf="i == 4&&rowData.delegationId==0" style="text-align: left;padding-left:25px;">
                              <select  class="select-css width220" 
                                    [(ngModel)]="rowData.selectedUser" (change)="toGetUserDetailsMain(rowData)"
                                    aria-placeholder="Select">
                                    <option [value]="null" selected disabled hidden>Select</option>
                                    <option *ngFor="let item of ToUsersData"
                                        [value]="item.userGuid" >
                                        {{item.firstName}}
                                    </option>
                                </select>

                            </td>
                            <td *ngIf="i == 4&&rowData.delegationId>0" style="text-align: left;padding-left:25px;">
                              {{rowData[col.field]}}
                            </td>
                            <td *ngIf="i == 5&&rowData.delegationId==0"style="text-align: left;padding-left:30px;">
                              <select  class="select-css width220"
                              [(ngModel)]="rowData.selectedRole"
                              aria-placeholder="Select" >
                              <option [value]="null" disabled hidden>Select</option>
                              <option *ngFor="let item of rowData.ToRoleData"
                                  [value]="item.roleId" style="width: 40px;">
                                  {{item.roleName}}
                              </option>
                          </select>
                            </td>
                            <td *ngIf="i == 5&&rowData.delegationId>0" style="text-align: left;padding-left:25px;">
                              {{rowData[col.field]}}
                            </td>
                            <td *ngIf="i == 6" style="text-align: center;padding-left:15px;width: 80px;">
                              <button pButton pRipple type="button" icon="pi pi-plus" (click)="AddComments(rowData)" 
                    pTooltip="Add Comment" tooltipPosition="top" 
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    style="height: 25px; width: 25px;color: #ffffff;
                    background: #689F38;
                    border: 1px solid #689F38;"></button>
                            </td>
                          </ng-container>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="8" style="text-align: left;">No documents found.</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                  <small class="p-invalid">{{ docsError }}</small>
                </div>
            </div>
            <!-- <div class="p-col-12 p-md-12">
              <h5 style="text-align:center">Delegation To</h5>
              <div class="p-field p-col-12 p-md-4">
                <br>
                <label>User<span style="color: red">*</span></label>
                <p-autoComplete [(ngModel)]="ToselectedUser" [suggestions]="filteredToUsersData" 
                  (completeMethod)="filteredToUsers($event)" 
                  field="firstName" 
                  (onSelect)="toGetUserDetails()"
                  [dropdown]="true">
                  <ng-template>
                    <div>
                      <div>{{ filteredToUsersData.firstName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ ToUserError }}</small>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <br>
                <label>Role<span style="color: red">*</span></label>
                <p-dropdown [options]="ToRolesData" [(ngModel)]="ToselectedRole" placeholder="Select Role"
                 optionLabel="roleName">                
               </p-dropdown>
               <small class="p-invalid">{{ toRoleError }}</small>
              </div>
              <div class="p-field p-col-12 p-md-4">
                <br>
                <label>Comments</label>
                <textarea type="text" rows="4" [(ngModel)]="comments" pInputTextarea></textarea>
                <small class="p-invalid">{{ commentsError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
              <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
              class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="documentAddDelegation()"
              style="width:auto; float:right; "></button>
            </div>
            <small class="p-invalid" style="float:right;">{{ docError }}</small>
            </div> -->

            
          </div>
          <!-- <div class="p-field p-col-12 p-md-8" style="text-align:left;"><br>
            <h5 style="margin-top:5px;">Added Delegations</h5>
          </div>
              <div class="p-fluid p-formgrid p-grid">
            <p-table #dt [value]="delegateDocTempData" id="excel-table" [columns]="delegatedoccols" sortMode="multiple"
              class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
              dataKey="id" [globalFilterFields]="['user','department','toUser','toRole','comments']">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th>Action</th>
                </tr>
                <tr>
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"
                      style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: center;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 1" style="text-align: center;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 2" style="text-align: center;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 3" style="text-align: center;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 4" style="text-align: center;">
                      {{ rowData[col.field] }}
                    </td>
                  </ng-container>
                  <td>
                    <button pButton type="button" icon="pi pi-trash" pTooltip="Delete" tooltipPosition="right"
                (click)="deleteDocTempDelegate(rowData)" class="p-button-raised p-button-danger"
                style="height: 25px; width: 25px"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5" style="text-align: left;">No records found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div> -->
          <br>
          <button pButton pRipple type="button" icon="pi pi-refresh" (click)="resetCtrls()" iconPos="left" label="Reset"
            class="p-button-raised p-button-secondary p-mr-2 p-mb-2" style="width:auto; float:right; "></button>
          <button pButton pRipple type="button" icon="pi pi-reply" iconPos="left" label="Delegation"
            class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="addDocDelegation()"
            style="width:auto; float:right; "></button>
        </div>
        <small class="p-invalid" style="float:right;">{{ responseError }}</small>
      </div>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header"><div>By Document Type</div></ng-template>
      <br>
      <div class="p-grid">
        <div class="p-col-12"> 
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <h5 style="text-align:center">Delegation From</h5>
              <hr>
              <div class="p-field p-col-12">
                <br>
                <label>User<span style="color: red">*</span></label>
                <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="filteredUsers" 
                  (completeMethod)="DocfilteredFromUsers($event)" 
                  field="firstName" 
                  (onSelect)="getDocsTypesData()"
                  [dropdown]="true"
                  [disabled]="DisableFromUser||fromuserdisabled">
                  <ng-template>
                    <div>
                      <div>{{ filteredUsers.firstName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ fromUserError }}</small>
              </div>
              <div class="p-field p-col-12">
                  <br>
                  <label>Document Type<span style="color: red">*</span></label>
                  <p-multiSelect [options]="docsTypesData" [(ngModel)]="selectedDocType" defaultLabel="Select a doc type" optionLabel="documentType" selectedItemsLabel="{0} items selected"></p-multiSelect>
                  <small class="p-invalid">{{ fromDocTypeError }}</small>
                </div>
                <div class="p-field p-col-12">
                <br>
                <label for="FromDate">From Date<span style="color: red">*</span></label>
                <p-calendar [showIcon]="true" [minDate]="minDate" inputId="icon" [(ngModel)]="fromDate"></p-calendar>
                <small class="p-invalid">{{ fromDateError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label for="address">Comments</label>
                <textarea id="Comments" type="text" rows="4" [(ngModel)]="comments" pInputTextarea></textarea>
                <small class="p-invalid">{{ commentsError }}</small>
              </div>
            </div>
            <div class="p-col-12 p-md-6">
              <h5 style="text-align:center">Delegation To</h5>
              <hr>
              <div class="p-field p-col-12">
                <br>
                <label>User<span style="color: red">*</span></label>
                <p-autoComplete [(ngModel)]="ToselectedUser" [suggestions]="filteredToUsersData" 
                  (completeMethod)="filteredToUsers($event)" 
                  field="firstName" 
                  (onSelect)="toGetUserDetails()"
                  [dropdown]="true">
                  <ng-template>
                    <div>
                      <div>{{ filteredToUsersData.firstName }}</div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <small class="p-invalid">{{ ToUserError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label>Role<span style="color: red">*</span></label>
                <p-dropdown [options]="ToRolesData" [(ngModel)]="ToselectedRole" placeholder="Select Role"
                 optionLabel="roleName">                
               </p-dropdown>
               <small class="p-invalid">{{ toRoleError }}</small>
              </div>
              <div class="p-field p-col-12">
                <br>
                <label>To Date</label>
                <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate"  [(ngModel)]="toDate"></p-calendar>
              </div>
              <div class="p-field p-col-12">
                <br>
                <br>
                <br>
                <br>
              </div>
              <div class="p-field p-col-12">
                <br>
                <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Clear"
                class="p-button-raised p-button-secondary p-mr-2 p-mb-2" (click)="ClearControls()"
                style="width:auto; float:right; "></button>
              <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add to Delegation List"
              class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="DOcTypeaddDelegation()"
              style="width:auto; float:right; "></button>
            </div>
            </div>
            <small class="p-invalid" style="float:right;">{{ docTyperesponseError }}</small>
          </div>
          <div class="p-field p-col-12 p-md-8" style="text-align:left;padding-left: 1px;"><br>
            <h5 style="margin-top:5px;">Added Delegations</h5>
          </div>
              <div class="p-fluid p-formgrid p-grid">
            <p-table #dt [value]="delegateDocTypeTempData" id="excel-table" [columns]="doctypecols" sortMode="multiple"
              class="table-align"  documentType="{0} items selected" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
              dataKey="id" [globalFilterFields]="['user','documenttype','toUser','toRole','fromDate','toDate','comments']" >
              <ng-template pTemplate="header" let-columns class="invoice-items"  >
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th pSortableColumn="price" style="text-align: center; width:80px;">Action</th>
                </tr>
                <tr>
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"
                      style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;padding-left:10px;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 3" style="text-align: left;padding-left:10px;">
                      {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 4" style="text-align: center;">
                      {{rowData[col.field]| date: "MM/dd/yyyy"}}
                    </td>
                    <td *ngIf="i == 5" style="text-align: center;">
                      {{rowData[col.field]| date: "MM/dd/yyyy"}}
                    </td>
                    <td *ngIf="i == 6" style="text-align: left;padding-left:15px;">
                      {{ rowData[col.field] }}
                    </td>
                  </ng-container>
                  <td style="text-align: center; width:80px">
                    <button *ngIf="rowData.delegationId==0" pButton type="button" icon="pi pi-trash" pTooltip="Delete" tooltipPosition="right"
                (click)="deleteDocTypeTempDelegate(rowData)" class="p-button-raised p-button-danger"
                style="height: 25px; width: 25px"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No records found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <br>
          <button pButton pRipple type="button" icon="pi pi-refresh" (click)="resetCtrls()" iconPos="left" label="Reset"
            class="p-button-raised p-button-secondary p-mr-2 p-mb-2" style="width:auto; float:right; "></button>
          <button pButton pRipple type="button" icon="pi pi-reply" iconPos="left" label="Delegation"
            class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="SaveDelegation()"
            style="width:auto; float:right; "></button>
        </div>
        <small class="p-invalid" style="float:right;">{{ responseError }}</small>
        <div class="p-field p-col-12 p-md-8" style="text-align:left;padding-left: 1px;" *ngIf="delegateDocTypeTempDataExisting.length>0"><br>
          <h5 style="margin-top:5px;">Existing Delegations</h5>
        </div>
            <div class="p-fluid p-formgrid p-grid" *ngIf="delegateDocTypeTempDataExisting.length>0">
          <p-table #dt [value]="delegateDocTypeTempDataExisting" id="excel-table" [columns]="doctypecols" sortMode="multiple"
            class="table-align"  documentType="{0} items selected" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
            dataKey="id" [globalFilterFields]="['user','documenttype','toUser','toRole','fromDate','toDate','comments']" >
            <ng-template pTemplate="header" let-columns class="invoice-items"  >
              <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                  <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                    (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"
                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                  <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 2" style="text-align: left;padding-left:10px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 3" style="text-align: left;padding-left:10px;">
                    {{ rowData[col.field] }}
                  </td>
                  
                  
                  <td *ngIf="i == 4" style="text-align: center;">
                    {{rowData[col.field]| date: "MM/dd/yyyy"}}
                  </td>
                  <td *ngIf="i == 5" style="text-align: center;">
                    {{rowData[col.field]| date: "MM/dd/yyyy"}}
                  </td>
                  <td *ngIf="i == 6" style="text-align: left;padding-left:15px;">
                    {{ rowData[col.field] }}
                  </td>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7" style="text-align: left;">No records found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <br>
        <div class="p-field p-col-12 p-md-8" style="padding-left: 1px;" *ngIf="doctypeskipapprovalExistingTempData.length>0"><br>
          <h5 style="margin-top:5px;">Existing Skip Approval</h5>
        </div>
            <div class="p-fluid p-formgrid p-grid" *ngIf="doctypeskipapprovalExistingTempData.length>0">
          <p-table #dt [value]="doctypeskipapprovalExistingTempData" id="excel-table" [columns]="skipcols" sortMode="multiple"
            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
            dataKey="id" [globalFilterFields]="['user','role','comments','fromDate','toDate']">
            <ng-template pTemplate="header" let-columns class="invoice-items">
              <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
              <tr>
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                  <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="test($event.target.value,col.field,dt)"
                      style="margin: -10px 0 -10px 0;width: 90%; height:25px;" />
              </th>
              </tr>
              
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                  <td *ngIf="i == 0" style="text-align: left;padding-left:10px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 1" style="text-align: left;padding-left:25px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 2" style="text-align: left;padding-left:10px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                    {{ rowData[col.field] }}
                  </td>
                  
                  <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 5" style="text-align: center;">
                    {{rowData[col.field]| date: "MM/dd/yyyy"}}
                  </td>
                  <td *ngIf="i == 6" style="text-align: center;">
                    {{rowData[col.field]| date: "MM/dd/yyyy"}}
                  </td>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7" style="text-align: left;">No records found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-tabPanel>
</p-tabView>
</div>
</div>
   
  </div>
  <div
    style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>
  <p-dialog header="Information" [(visible)]="ErrorDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}">
    <hr>
    <div class="p-grid">
  
      <div class="p-col-12" style="text-align: center;">
        <span style="font-size:18px;">{{ErrorDisplayMessage}}</span>
      </div>
    </div>
  </p-dialog>

  <p-dialog header="Add Comment" [(visible)]="commentsdisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
    <hr>
    <br/>
    <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12" style="margin-top: -1.5rem;">
        <div class="p-col-12 p-md-12 p-xl-12">
          <label> Comments <span style="color: red">*</span> </label>
          <textarea rows="5" pInputTextarea [(ngModel)]="commentsrow" placeholder="Enter Comments"
           maxlength="1000" ng-trim="false"
            style="width: 100%;"></textarea>
          <small class="p-invalid">{{1000 - commentsrow.length}} characters are remaining</small>
        </div>
        <div class="p-col-12 p-md-12 p-xl-12">
          <span style="float: right;">
            <button pButton type="button" (click)="AddRowComment()" label="Add" icon="pi pi-plus" pTooltip="Add"
              tooltipPosition="right" class="p-button-primary p-button-raised p-button-success p-mr-2 p-mb-2"
              style="width: 100px;height: 25px;float: right;margin-left: 120px;"></button>
          </span>
        </div>
      </div>
    </div>
  </p-dialog>
  <p-confirmDialog header="Information" icon="pi pi-exclamation-triangle"></p-confirmDialog>