<div class="p-grid p-fluid">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <div class="card" style="min-height:600px;">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
              <a routerLink="/liststandard"> <button pButton pRipple type="button" icon="pi pi-chevron-left"
                  iconPos="left" label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                  style="width:auto;"></button></a>
            </div>
            <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12" style="text-align:center;">
              <h4 style="margin-top:5px;">Create Standards</h4>
            </div>
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
          </div>

          <!-- <h4>Create Standards</h4> -->
          <hr />
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <br>
              <label for="standardName">Standard Name<span style="color: red">*</span></label>
              <input id="standardName" type="text" pInputText [(ngModel)]="standardName" placeholder="Enter Standard" />
              <small class="p-invalid">{{ standardNameError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <br>
              <!-- <label for="standardType">Standard Type <span style="color: red">*</span></label>
              <p-dropdown inputId="standardType" [options]="StandardsData" [(ngModel)]="standardType"
                placeholder="Select" optionLabel="standardType">
              </p-dropdown> -->
              <label for="standardTypes">Standard Type <span style="color: red">*</span></label>
              <p-dropdown inputId="standardType" [options]="standardTypes" [(ngModel)]="standardType"
                placeholder="Select" optionLabel="standardType">
              </p-dropdown>
              <small class="p-invalid">{{ standardTypeError }}</small>
            </div>
            <br>
            <div class="p-field p-col-12 p-md-4">
              <br>
              <label for="startDate">Start Date<span style="color: red">*</span></label>
              <p-calendar [minDate]="minDate" (onSelect)="StartDateCheck(startDate)" [showIcon]="true" inputId="icon"
                [(ngModel)]="startDate"></p-calendar>
              <small class="p-invalid">{{ startDateError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <br>
              <label for="endDate">End Date<span style="color: red">*</span></label>
              <p-calendar [minDate]="minDate" [showIcon]="true" (onSelect)="EndDateCheck(endDate)" inputId="icon"
                [(ngModel)]="endDate"></p-calendar>
              <small class="p-invalid">{{ endDateError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <br>
              <label for="statuses">Status<span style="color: red">*</span></label>
              <p-dropdown [options]="statuses" [(ngModel)]="selectedStatus" placeholder="Select" selected="Active"
                optionLabel="name" [showClear]="true"></p-dropdown>
              <small class="p-invalid">{{ statusError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <br> <br>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <br /><br>

            </div>
            <div class="p-field p-col-12">
              <div class="p-grid">
                <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
              <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                  class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="createStandard()"
                  style="float:right; width:100%"></button>
                  </div>                  
                <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
              <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Reset"
                class="p-button-raised p-button-secondary p-mr-2 p-mb-2" (click)="resetCtrls()"
                style="float:right;width:100%"></button>
                </div>
                
              </div>
            </div>
            <small class="p-invalid" style="float:right;">{{ responseError }}</small>
          </div>



          

        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>