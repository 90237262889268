<div class="p-grid p-fluid">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="p-fluid p-formgrid p-grid">        
           
            <div class="p-field p-col-12 p-xl-2">
              <label style="padding-right:10px; margin-left:10px;">Application:</label>
              <p-multiSelect class="multiselect-custom" defaultLabel="Select Application" [options]="status" optionLabel="applicationName" [(ngModel)]="selectedStatus"
              (onChange)="projectstatuschange()">
                  <ng-template let-value pTemplate="selectedItems">
                      <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                          <div>{{option.name}}</div>
                      </div>
                      <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                          Select Application
                      </div>
                  </ng-template>
              </p-multiSelect>
              </div>
              <div class="p-field p-col-12 p-xl-2">
              <label style="padding-right:10px; margin-left:10px;">Period:</label>
              <p-dropdown [options]="period" [(ngModel)]="selectedPeriod" placeholder="Select Period"
              optionLabel="name" (onChange)="Dates()"></p-dropdown>
              </div>            
              <div *ngIf="datesdisplay" class="p-field p-col-12 p-xl-2">
              <label>Start Date:<span style="color: red"></span></label>
                <p-calendar [showIcon]="true" inputId="txtStartDate" (onClickOutside)="startdate()" (onSelect)="startdate()"
                   [(ngModel)]="editStartDate"></p-calendar>
              </div>
              <div *ngIf="datesdisplay"  class="p-field p-col-12 p-xl-2">
              <label>End Date:<span style="color: red"></span></label>
              <p-calendar [showIcon]="true" inputId="txtEndDate" (onClickOutside)="enddate()" (onSelect)="enddate()"
                  [(ngModel)]="editEndDate"></p-calendar>            
              </div>
              <div class="p-field p-col-12 p-xl-2">
                <a (click)="getprojects()">
                  <button pButton pRipple type="button" icon="pi pi-search" iconPos="left" label="Search" 
                  class="p-button-raised p-button-primary" style="width:100%;float:left;margin-top: 25px;"></button>
              </a>
              </div>            
        </div>
        <div class="p-fluid p-formgrid p-grid">  
            <div class="p-field p-col-12">
            <label style="color: red;width:auto;">{{error}}</label>       
            </div>
        </div>   
  
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
          <h5 style="color:#495057; padding:15px 0 0 15px;width: 100%;">Email Notification - {{Count}}</h5> 
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
    
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
              (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float:right; width:25px; height:25px;"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
              (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float:right;height:25px; width:25px; "></button>
          </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content">           
   <p-table #dt [value]="projects" [columns]="cols" sortMode="multiple" id="excel-table" [loading]="loading" [autoLayout]="true"
  styleClass="p-datatable-striped" class="table-align" [virtualScroll]="true" [scrollable]="true" scrollHeight="400px" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedprojects"
  dataKey="projectId"> 
  <ng-template pTemplate="header" let-columns class="invoice-items">
  <tr>              
      <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" style="white-space:wrap"
      [ngClass]="{'width150': i == 0 || i == 1,'width100': i == 2,'width200': i == 3}" >
      {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
  </tr>
  <tr id="trSearch">
    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
      <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search" style="margin: -10px 0 -10px 0; width: 95%;height:20px"/>
  </th>
  </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
  <tr [pSelectableRow]="rowData">
    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
      <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 200px;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 1" style="text-align: left; padding-left:20px;width: 200px;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 2" style="text-align: center;width: 150px;">{{ rowData[col.field] }}</td>
      <td *ngIf="i == 3" style="text-align: left;width: 730px;padding-left: 25px;">{{ rowData[col.field] }}</td>
    </ng-container>
  </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
  <tr>
      <td colspan="4" style="text-align: left;">No records found...</td>
  </tr>
  </ng-template>
  
   </p-table>
      </div>
      </div>
    </div>
  </div>
  
  
  <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 9999;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
  </div>