<div class="p-grid p-fluid p-formgrid">
    <div class="p-col-12">
      <div class="card" style="min-height: 600px">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;">         
          <h4 style="color:#495057; padding:10px 0 0 15px;width: 90%;">Document Types</h4> 
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">        
          <button pButton pRipple type="button" icon="pi pi-print"  (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:15px ; "></button>
   </span>
      </div>       
      <div class="invoice invoice-header"id="invoice-content">
       <p-table #dt [value]="docsData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align"  [virtualScroll]="true" [scrollable]="true" scrollHeight="380px" styleClass="p-datatable-striped"
        [filterDelay]="0" selectionMode="multiple" dataKey="id" [globalFilterFields]="['documentType','documentTypeCode','active']">
        <!-- <ng-template pTemplate="caption">
          <h5>My Approvals Needed</h5>
          <span
            class="p-input-icon-left"
            style="float: right; margin-top: -40px"
          >
             <button
              pButton
              pRipple
              type="button"
              icon="pi pi-print"
              class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float: right"
              (click)="print()"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-download"
              class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float: right; margin-left: 10px"
              (click)="exportExcel()"
            ></button>
          </span>
        </ng-template> -->
        <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
              [ngClass]="{'documentType': i == 0,'documentTypeCode': i == 1,'active': i == 2}">
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>                                        
            </tr>
            <tr id="trSearch">
                <th *ngFor="let col of columns" [ngSwitch]="col.field"
                    style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                        (input)="dt.filter($event.target.value, col.field, 'contains')"
                        style="margin: -10px 0 -10px 0;">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
  
                    <td *ngIf="i == 0" style="text-align: left;width: 200px;">
                        {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;width: 200px;">
                        {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;width: 200px;">
                        {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 3" style="text-align: center;width: 200px;">
                        {{rowData[col.field] | date: "MM/dd/yyyy"}}
                    </td>
                    <td *ngIf="i == 4" style="text-align: center;">
                        {{rowData[col.field] | date: "MM/dd/yyyy"}}
                    </td>
                    <td *ngIf="i == 5" style="text-align: left;">
                      {{rowData[col.field]}}
                  </td>
                </ng-container>                                        
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6" style="text-align: left;">No records found.</td>
          </tr>
        </ng-template>
      </p-table>
      </div>
    </div>
  </div>
      </div>
      <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
        <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
      </div>
  