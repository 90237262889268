<div class="p-grid p-fluid p-formgrid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <p-accordion [activeIndex]="0">
        <p-accordionTab header="Search Criteria">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2">
              <label>Document Type:</label>
              <p-multiSelect [options]="docTypesData" [(ngModel)]="selectedDocType" defaultLabel="Select Document Type" optionLabel="documentType" selectedItemsLabel="{0} docyment types selected"></p-multiSelect>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Document ID:</label>
                <input type="text" pInputText [(ngModel)]="docid" placeholder="Enter Document ID" maxlength="10"/>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <label>Originator:</label>
                <p-multiSelect [options]="usersData" [(ngModel)]="selectedUser" defaultLabel="Select Originator" optionLabel="fullName" selectedItemsLabel="{0} originators selected"></p-multiSelect>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label>Approver:</label>
              <p-autoComplete [(ngModel)]="selectedApprover" 
              [suggestions]="filteredDept" (completeMethod)="filterDepartments($event)" field="fullName" [dropdown]="true">
              <ng-template>
                <div>
                  <div>{{ filteredDept.fullName }}</div>
                </div>
              </ng-template>
            </p-autoComplete>

              <!-- <p-multiSelect [options]="usersData" [(ngModel)]="selectedApprover" defaultLabel="Select Approver" optionLabel="fullName" selectedItemsLabel="{0} approvers selected"></p-multiSelect> -->
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label>From:</label>
              <p-calendar [showIcon]="true" placeholder="Enter From Date" [(ngModel)]="startDate"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label>To:</label>
              <p-calendar [showIcon]="true" placeholder="Enter To Date" [(ngModel)]="endDate"></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label>Status:</label>
              <p-multiSelect [options]="status" [(ngModel)]="selectedstatus" defaultLabel="Select Status" optionLabel="label" selectedItemsLabel="{0} status selected"></p-multiSelect>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label>Total Approval Elapsed days:</label>
              <p-dropdown [options]="option" [(ngModel)]="selectedoption" placeholder="Select" optionLabel="label" (onChange)="Display()"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-2" *ngIf="DisplayDays">
              <label>Elapsed Days:</label>
              <input type="text" pInputText [(ngModel)]="days" (keypress)="isNumberKey($event)" placeholder="Enter Days" maxlength="3"/>
            </div>
            <div class="p-field p-col-12 p-md-2" *ngIf="DisplayRange">
              <label>From Days:</label>
              <input type="text" pInputText [(ngModel)]="fromdays" (keypress)="isNumberKey($event)" placeholder="Enter From Days" maxlength="3"/>
            </div>
            <div class="p-field p-col-12 p-md-2" *ngIf="DisplayRange">
              <label>To Days:</label>
              <input type="text" pInputText [(ngModel)]="todays" (keypress)="isNumberKey($event)" placeholder="Enter To Days" maxlength="3"/>
            </div>
            <div class="p-field p-col-12 p-md-4" style="text-align: center; margin-top: 24px;">               
                <button pButton pRipple type="button" icon="pi pi-search" pTooltip="Search" label="Search" (click)="Search()"
                class="p-button-raised p-button-primary" style="width: 120px;" ></button>
                &nbsp;&nbsp;
                <button pButton pRipple type="button" icon="pi pi-refresh" pTooltip="Reset" label="Reset" (click)="Reset()"
                class="p-button-raised p-button-secondary" style="width: 120px;" ></button>
            </div>
            <small class="p-invalid" style="margin-top: 35px;">{{ Error }}</small>
          </div>
        </p-accordionTab>
      </p-accordion>
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;">
        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Search Documents - {{ count }}</h5>
        <span style="width: 100%; padding-top: 15px;"> <h5 style="color:#495057;">Total Elapsed Days  -  Min: {{min}} &nbsp;&nbsp;&nbsp;&nbsp;  Max: {{max}}  &nbsp;&nbsp;&nbsp;&nbsp;  Average: {{avg}}</h5></span>
        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
          <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
            tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
        </span>
      </div>

      <div class="invoice invoice-header" id="invoice-content">
        <p-table #dt [value]="docsData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align" [virtualScroll]="true" [scrollable]="true" scrollHeight="400px"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                [ngClass]="{'ecrn':i==0,'dept':i==1,'code':i==2,'rev':i==3,'title':i==4,'owner':i==5,'elapsedDays':i==6,
                'status':i==7,'date':i==8,'ed':i==9,'approver':i==10}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="width:70px;">Actions</th>
            </tr>
            <tr id="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" (input)="test($event.target.value,col.field,dt)"
                  style="margin: -10px 0 -10px 0; width:95%; height:25px;">
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: center; width: 100px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;width: 150px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 2" style="text-align: center;width: 100px;">
                  <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                  <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                </td>
                <td *ngIf="i == 3" style="text-align: center;width: 60px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 320px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 150px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 6" style="text-align: center;width: 130px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 7" style="text-align: left;padding-left:15px;font-weight: bold;width: 140px;"
                [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 8" style="text-align: center;width: 100px;">
                  {{rowData[col.field] | date:'MM/dd/yyyy' }}
                </td>
                <td *ngIf="i == 9" style="text-align: center;width: 150px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 10" style="text-align: left;padding-left:15px;width: 200px;">
                  {{rowData[col.field]}}
                </td>
              
              </ng-container>
              <td style="text-align: center;width: 70px;">
                <button pButton type="button" (click)="viewDocDetails(rowData)" icon="pi pi-eye" pTooltip="View"
                class="p-button-raised p-button-primary" style="width:25px; height:25px;"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="9" style="text-align: left;">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>