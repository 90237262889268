<div class="p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
        <div class="p-grid p-fluid p-formgrid">
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <button pButton pRipple type="button"
            icon="pi pi-arrow-circle-right" iconPos="left" label="Executing Workflow"
            class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width:100%;"></button>
          </div>
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <button  pButton pRipple type="button"
            icon="pi pi-arrow-circle-right" iconPos="left" label="Failed Workflow"
            class="p-button-raised p-button-danger p-mr-2 p-mb-2" style="width:100%;"></button>
          </div>
          <div class="p-field p-col-12 p-lg-7 p-md-12 p-sm-12"></div>
        </div>
      
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:auto; ">
              <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">{{ status }}</h5> 
              <span class="p-input-icon-left" style="float:right; margin-right:10px; margin-top:8px;">
                <button pButton pRipple type="button" icon="pi pi-print" (click)="print()"
                  class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right; height:25px; width:25px;"></button>
                <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()"
                  class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                  style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
              </span>
            </div> 
    
        <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
          <p-table #dt [value]="workflowData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
          [globalFilterFields]="['name', 'status', 'createdAt']">
          <!-- <ng-template pTemplate="caption" >
            <h4 style="margin-bottom:-1px;">{{ status }}</h4>
            <span
              class="p-input-icon-left"
              style="float: right; margin-top: -28px;"
            >
               <button
                pButton
                pRipple
                type="button"
                icon="pi pi-print"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float: right"
                (click)="print()"
                style="height:25px; width:25px;"
              ></button>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-download"
                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float: right; margin-left: 15px"
                (click)="exportExcel()"
                style="height:25px; width:25px;"
              ></button>
            </span>
          </ng-template>  -->
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
              [ngClass]="{'name':i==0,'status':i==1,'createdAt':i==2}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
            <tr ID="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                  (input)="dt.filter($event.target.value, col.field, 'contains')"
                  style="margin: -10px 0 -10px 0;width:95%; height:25px;">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: left; padding-left:15px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 2" style="text-align: center;">
                  {{rowData[col.field]}}
                </td>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="3">No workflows found.</td>
            </tr>
            </ng-template>
        </p-table>
        </div>
     
    </div>
  </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #fff;opacity: 0.2;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem"></i>
</div>