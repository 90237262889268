<div class="p-grid">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-2"></div>
      <div class="p-field p-col-12 p-md-8">
        <div class="card">
          <h4>Create Profile</h4>
          <hr />
          <form #createProfileForm="ngForm">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <label for="profileName">Profile Name</label>
                <input id="profileName" name="profileName" type="text" [(ngModel)]="profileForm.profileName" pInputText
                  required name="Name" />
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="state">Document Type<span style="color: red">*</span></label>
                <p-dropdown inputId="documentType" [options]="docsTypesData" [(ngModel)]="selectedDocType"
                  placeholder="Select" optionLabel="documentType" required="true" name="docName"
                  (onChange)="getWorkFlowsList(selectedDocType)" requiredMessage="The input must not be empty">
                </p-dropdown>
                <small class="p-invalid">{{ docTypeError }}</small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="state">Department<span style="color: red">*</span></label>
                <p-dropdown inputId="departmentType" [options]="departments" [(ngModel)]="selectedDepartment"
                  placeholder="Select" optionLabel="departmentName" required="true"
                  (onChange)="getRolesByDepartmentGuid()" requiredMessage="The input must not be empty"
                  name="deparment">
                </p-dropdown>
                <small class="p-invalid">{{ docTypeError }}</small>
              </div>

              <div class="p-field p-col-12 p-md-6">
                <label for="state">Role<span style="color: red">*</span></label>
                <p-dropdown inputId="roleType" [options]="roles" [(ngModel)]="selectedRole" placeholder="Select"
                  optionLabel="roleName" required="true" (onChange)="getWorkFlowsList(selectedDocType)"
                  requiredMessage="The input must not be empty" name="role">
                </p-dropdown>
                <small class="p-invalid">{{ docTypeError }}</small>
              </div>

              <div class="p-field p-col-12 p-md-6">
                <label for="city">No of Approvals</label>
                <input id="noOfApprovals" name="noOfApprovals" type="number" [(ngModel)]="profileForm.noOfApprovals"
                  pInputText required name="approvals" />
                <br />
              </div>
              <div class="p-field p-col-12 p-md-8"></div>

              <div class="p-field p-col-12 p-md-2">
                <button pButton pRipple type="button" (click)="createProfile()" icon="pi pi-check" iconPos="right"
                  label="Submit" class="p-button-raised p-button-success p-mr-2 p-mb-2" style="width: 100%;"></button>
              </div>
              <div class="p-field p-col-12 p-md-2">
                <button pButton pRipple type="button" icon="pi pi-replay" iconPos="right" label="Reset"
                  class="p-button-raised p-button-danger p-mr-2 p-mb-2" style="width: 100%;"></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>