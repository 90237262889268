import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";

@Injectable({
  providedIn: "root",
})
export class CreatedocService {
  constructor(
    private _httputilityService: HttputilityService
    ) { }
    RptDocumentsBySearch(model) {
      return this._httputilityService.Add("RptDocumentsBySearch",model);
    }
  getDocsTypes() {
    return this._httputilityService.Get("GetDocumentTypes");
  }

  GetWorkflowApprovers(model) {
    return this._httputilityService.Add("GetWorkflowApprovers",model);
  }

  getDocsTypesESI(ESI,DTID) {
    return this._httputilityService.Get("GetDocumentTypes?ESI="+ESI+"&DTID="+DTID);
  }

  getDepartments() { 
    return this._httputilityService.GetU("user/GetDepartments");
  }

  getRoles() { 
    return this._httputilityService.GetU("user/getRoles");
  }

  getAllClasses(modelArray) {
    return this._httputilityService.Add("GetClauses", modelArray);
  }

  workFlowsList(model) {
    return this._httputilityService.Add("GetWorkflows", model);
  }

  UpdateChangeRequestDetails(changeRequest){
    return this._httputilityService.PostCMS("UpdateChangeRequestDetails",changeRequest);
  }

  getProductsList(){
    return this._httputilityService.GetProd("Product/GetAllActiveProducts");
  }
  GetAllTasks(){
    return this._httputilityService.GetProd("Product/GetAllTasks?DocumentRequired="+false);
  }
} 
