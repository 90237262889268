import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-doctypefolderstructure',
  templateUrl: './doctypefolderstructure.component.html',
  styleUrls: ['./doctypefolderstructure.component.scss']
})
export class DoctypefolderstructureComponent implements OnInit {
  cols = [];
  showLoading: boolean;
  files = [];
  addDisplay: boolean;
  addDisplayp:boolean;
  folderId = 0;
  userId = parseInt(sessionStorage.getItem('userId'));
  folderName = "";
  nameError = "";
  description = "";
  active = true;
  responseMessage = "";
  TextLabel = "Save";
  isdisabled = true;
  textlabelheader = "Add DCC";
  mainFolderId=0;
  count = 0;
  tableHeader="DCC Folders";
  level: number;
  mainData = [];
  parentFolderId=0;
  selectedfolder="D";
  userFeature:boolean;
  hierarchy:number;
  constructor(private _facadeService: FacadeService,) { }

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DOCTYPE_FLDR");
        if(featureExist!=null){
          //this.userFeature = true;
        }
      }
    }
    this.cols = [
      { field: 'folderName', header: 'Name' },
      { field: 'subcount', header: 'Level Folder Count' },
      { field: 'active', header: 'Active' }
    ];
    this.GetFolders('DCC');
  }
  AddNewButton() {
    this.addDisplay = true;
    this.TextLabel = "Save";
    if(this.selectedfolder=='D'){
      this.textlabelheader = "Add DCC Folder";
    }
    else{
      this.textlabelheader = "Add Product Level 1 Folder";
    }
    
    this.folderName = "";
    this.description = "";
    this.nameError = "";
    this.active = true;
    this.isdisabled = true;
    this.folderId = 0;
    this.responseMessage = "";
    this.parentFolderId=0;
    this.mainFolderId=0;
    this.hierarchy=0;
  }
  CreateUpdateFolder() {
    this.nameError = "";
    this.responseMessage = "";
    if (this.folderName == "" || this.folderName == null || this.folderName == undefined) {
      this.nameError = "Please enter name";
    }
    else {
      var model = {
        folderId: this.folderId,
        folderName: this.folderName,
        description: this.description,
        parentFolderId: this.parentFolderId,
        mainFolderId: this.mainFolderId,
        type: this.selectedfolder=='D'?"DCC":"Product",
        active: this.active,
        createdBy:this.userId,
        hierarchy:this.hierarchy
      }
   if (this.folderId > 0) {
        this.showLoading = true;
        this._facadeService.UpdateFolder(model).subscribe(
          (data) => {
            if (data.returnCode == 0) {
             // location.reload();
             this.addDisplay=false;
             this.addDisplayp=false;
             this.FolderChange();
            }
            else {
              this.showLoading = false;
              this.responseMessage = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else {
        this.showLoading = true;
        this._facadeService.CreateFolder(model).subscribe(
          (data) => {
            if (data.returnCode == 0) {
              this.addDisplay=false;
              this.addDisplayp=false;
              this.FolderChange();
            }
            else {
              this.showLoading = false;
              this.responseMessage = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }

  }
  GetFolders(type) {
    this.files=[];
    this.mainData=[];
    this.count=0;
    this.showLoading = true;
    var model ={
      "type": type
    }
    this._facadeService.GetFolders(model).subscribe(
      (data) => {
        this.showLoading = false;
        var f =[];
        this.count = data.returnObject.length;
        data.returnObject.forEach(element => {
          element.data=Object;
          element.data = element;
          element.data.folderName=element.folderName;
          element.expanded = false;
          element.data.active = element.active == true ? "Y" : "N";
          element.data.subcount = 0;
          element.children=element.subFolders;
          if (element.children != null && element.children != undefined && element.children.length > 0) {
            element.data.subcount = element.children.length;
            element.children.forEach(element1 => {
             
              element1.data=Object;
              element1.data=element1;
              element1.data.folderName=element1.folderName;
              element1.expanded = false;
              element1.data.active = element1.active == true ? "Y" : "N";
              element1.children=element1.subFolders;
              if(element1.children!=null&&element1.children!=undefined&&element1.children.length>0){
                element1.data.subcount = element1.children.length;
                element1.children.forEach(element2 => {
                  element2.data=Object;
                  element2.data=element2;
                  element2.expanded = false;
                  element2.data.folderName=element2.folderName;
                  element2.data.active = element2.active == true ? "Y" : "N";
                });
              }
            });
          }
            f.push(element);
        });
        this.files = f;
        this.mainData = this.files;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  AddSubFolder(rowData,level) {
    this.addDisplay = true;
    this.TextLabel = "Save";
      this.textlabelheader = "Add " + rowData.folderName + " Level 1 Folder";
    this.folderName = "";
    this.description = "";
    this.nameError = "";
    this.active = true;
    this.isdisabled = true;
    this.folderId = 0;
   
    if(level==1){
      this.parentFolderId=rowData.folderId;
      this.mainFolderId=rowData.mainFolderId;
    }
    else{
      this.mainFolderId=rowData.folderId;
      this.parentFolderId=rowData.folderId;
    }
    this.responseMessage = "";
    this.addDisplay = true;
    this.hierarchy=level==0?1:2;
  }
 
  EditFolder(rowData,level) {
    this.TextLabel = "Update";
    if(level == 0){
      if(this.selectedfolder=='D'){
        this.textlabelheader = "Edit DCC Folder";
      }
      else{
        this.textlabelheader = "Edit Product Level 1 Folder";
      }
    }
    else if(level == 1){
      if(this.selectedfolder=='D'){
        this.textlabelheader = "Edit " + rowData.folderName + " Level 1 Folder";
      }
      else{
        this.textlabelheader = "Edit " + rowData.folderName + " Level 2 Folder";
      }
    }
    else{
      if(this.selectedfolder=='D'){
        this.textlabelheader = "Edit " + rowData.folderName + " Level 2 Folder";
      }
      else{
        this.textlabelheader = "Edit " + rowData.folderName + " Level 3 Folder";
      }
    }
    this.folderName = "";
    this.description = "";
    this.nameError = "";
    this.active = true;
    this.isdisabled = false;
    this.folderId = rowData.folderId;
    this.mainFolderId = rowData.mainFolderId;
    this.parentFolderId=rowData.parentFolderId;
    this.responseMessage = "";
    this.showLoading = true;
      this._facadeService.GetFolderById(this.folderId).subscribe(
        (data) => {
          this.showLoading = false;
          var prod = data.returnObject;
          this.folderName = prod.folderName;
          this.description = prod.description;
          this.active = prod.active;
          this.folderId = prod.folderId;
          this.mainFolderId = prod.mainFolderId;
          this.parentFolderId = prod.parentFolderId;
          this.addDisplay = true;
          this.hierarchy=prod.hierarchy;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }

  expandAll() {
    this.showLoading = true;
    this.files = [];
    this.mainData.forEach(element => {
      element.expanded = true;
      element.data.subcount = 0;
      if (element.children != null && element.children != undefined && element.children.length > 0) {
        element.data.subcount = element.children.length;
        element.children.forEach(element1 => {
          element1.expanded = true;
          if(element1.children!=null&&element1.children!=undefined&&element1.children.length>0){
            element1.data.subcount = element1.children.length;
            element1.children.forEach(element2 => {
              element2.expanded = true;
            });
          }
        });
      }
      this.files.push(element);
    });
    this.showLoading = false;
  }

  collapseAll() {
    this.showLoading = true;
    this.files = [];
    this.mainData.forEach(element => {
      element.expanded = false;
      element.data.subcount = 0;
      if (element.children != null && element.children != undefined && element.children.length > 0) {
        element.data.subcount = element.children.length;
        element.children.forEach(element1 => {
          element1.expanded = false;
          if(element1.children!=null&&element1.children!=undefined&&element1.children.length>0){
            element1.data.subcount = element1.children.length;
            element1.children.forEach(element2 => {
              element2.expanded = false;
            });
          }
        });
      }
      this.files.push(element);
    });
    this.showLoading = false;
  }
  FolderChange(){
    this.TextLabel = "Save";
    this.folderName = "";
    this.description = "";
    this.nameError = "";
    this.active = true;
    this.isdisabled = true;
    this.folderId = 0;
    this.responseMessage = "";
    this.parentFolderId=0;
    this.mainFolderId=0;
    if(this.selectedfolder=="P"){
      this.textlabelheader="Add Product Level 1 Folder";
      this.tableHeader="Product Sub Folders";
      this.GetFolders('Product');
    }
    else{
      this.textlabelheader = "Add DCC Folder";
      this.tableHeader="DCC Folders";
      this.GetFolders('DCC');
    }
  }
}
