import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from 'src/app/facade/facade.service';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";
import { Table } from 'primeng/table';
@Component({
  selector: 'app-taskproductfolder',
  templateUrl: './taskproductfolder.component.html',
  styleUrls: ['./taskproductfolder.component.scss']
})
export class TaskproductfolderComponent implements OnInit {

  constructor(private _facadeService: FacadeService,public printService: PrintService,private cdr: ChangeDetectorRef) { }
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  Count=0;
  taskProductdata=[];
  cols=[];
  addDocsDisplay:boolean;
  selectedPhase: any;
  filteredPhase:any;
  phaseError:any;
  PhaseData: any[]=[];
  selectedTask: any;
  filteredTask:any;
  taskData: any[]=[];
  OrgtaskData: any[]=[];
  tasksError:any;
  Description:any;
  active=true;
  FromEdit=true;
  taskfolderData=[];
  taskError="";
  selectedMainTask=[];
  savelabel:any;
  responseMessage:any;
  Type:any;
  taskSubfolderData=[];
  selectedSUbTask=[];
  id=0;
  selectedSUbSubTask=[];
  taskSubSubfolderData=[];
  ngOnInit(): void {
    this.showLoading = true;
    sessionStorage.phaseName = "";
    sessionStorage.documentName ="";
    sessionStorage.documentLabels ="";
    sessionStorage.taskSubfolderNames ="";
    sessionStorage.taskSubfolder1Names ="";
    sessionStorage.taskSubfolder2Names ="";
    sessionStorage.active ="";
    this.cols = [
      { field: 'phaseName', header: 'Phase', dynamicPlaceHolder: 'Search' },
      { field: 'documentName', header: 'Task', dynamicPlaceHolder: 'Search' },
      { field: 'documentLabels', header: 'Document Label', dynamicPlaceHolder: 'Search' },
      { field: 'taskSubfolderNames', header: 'Folder', dynamicPlaceHolder: 'Search' },
      { field: 'taskSubfolder1Names', header: 'Level 1 Folder', dynamicPlaceHolder: 'Search' },
      { field: 'taskSubfolder2Names', header: 'Level 2 Folder', dynamicPlaceHolder: 'Search' },
      { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
    ];
    this.GetTaskProductFolders();
  }
  AddNewButton(){
    this.showLoading = true;
    this.Description="";
    this.Type="Add";
    this.tasksError="";
    this.taskError="";
    this.responseMessage="";
    this.selectedMainTask=[];
    this.selectedTask="";
    this.FromEdit=false;
    this.addDocsDisplay = true; 
    this.savelabel="Save";
    this.active=true;
    this.id=0;
    this.selectedSUbTask=[];
    this.taskSubfolderData=[];
    this.taskSubSubfolderData=[];
    this.selectedSUbSubTask=[];
    this.GetAllTasks('','','');
  }
  filterPhase(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.PhaseData.length; i++) {
        let doctype = this.PhaseData[i];
        if (doctype.phaseName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(doctype);
        }
    }    
    this.filteredPhase = filtered;
}
  filterTask(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.taskData.length; i++) {
        let doctype = this.taskData[i];
        if (doctype.documentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(doctype);
        }
    }    
    this.filteredTask = filtered;
}
EditTaskProduct(id,phaseName){
  this.showLoading = true;
  this.id=id;
  this.tasksError="";
  this.taskError="";
  this.responseMessage="";
  this.selectedMainTask=[];
  this.selectedTask="";
  this.FromEdit=true;
  this.addDocsDisplay = true; 
  this.savelabel="Update";
  this.active=true;
  this.Description="";
  this.selectedSUbTask=[];
  this.taskSubfolderData=[];
  this.taskSubSubfolderData=[];
  this.selectedSUbSubTask=[];
  this._facadeService.GetTaskProductDetailsById(this.id).subscribe(
    (data) => {
      this.active=data.returnObject.active;
      this.Description=data.returnObject.description;
      this.GetAllTasks(data.returnObject.requiredDocumentID,phaseName,data.returnObject.taskMainFolders);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
GetTaskProductFolders(){
  this._facadeService.GetTaskProductFolders().subscribe(
    (data) => {
      this.taskProductdata=data.returnObject;
      if(this.taskProductdata != null && this.taskProductdata != undefined)
      {
        this.taskProductdata.forEach(element => {
          element.active=element.active==true?"Y":"N";
        });
      }
      this.CountData();
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

test(val,field,dt)
{
  if(field == "phaseName")
    sessionStorage.phaseName = val;
  else if(field == "documentName")
    sessionStorage.documentName = val;
  else if(field == "documentLabels")
    sessionStorage.documentLabels = val;
  else if(field == "taskSubfolderNames")
    sessionStorage.taskSubfolderNames = val;
  else if(field == "taskSubfolder1Names")
    sessionStorage.taskSubfolder1Names = val;
  else if(field == "taskSubfolder2Names")
    sessionStorage.taskSubfolder2Names = val;
  else if(field == "active")
    sessionStorage.active = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.taskProductdata != undefined && this.taskProductdata != null)
  {
    var temp = this.taskProductdata;
    if(sessionStorage.phaseName != ""&&sessionStorage.phaseName != null)
      temp = temp.filter(x=> x.phaseName.toLowerCase().includes(sessionStorage.phaseName.toLowerCase()));
    if(sessionStorage.documentName != ""&&sessionStorage.documentName != null)
      temp = temp.filter(x=> x.documentName.toLowerCase().includes(sessionStorage.documentName.toLowerCase()));
    if(sessionStorage.documentLabels != ""&&sessionStorage.documentLabels != null)
      temp = temp.filter(x=> x.documentLabels != null && x.documentLabels != "" && x.documentLabels.toLowerCase().includes(sessionStorage.documentLabels.toLowerCase()));
    if(sessionStorage.taskSubfolderNames != ""&&sessionStorage.taskSubfolderNames != null)
      temp = temp.filter(x=> x.taskSubfolderNames.toLowerCase().includes(sessionStorage.taskSubfolderNames.toLowerCase()));
    if(sessionStorage.taskSubfolder1Names != "")
      temp = temp.filter(x=> x.taskSubfolder1Names!=null&&x.taskSubfolder1Names!=""&&x.taskSubfolder1Names.toLowerCase().includes(sessionStorage.taskSubfolder1Names.toLowerCase()));
    if(sessionStorage.taskSubfolder2Names != "")
      temp = temp.filter(x=> x.taskSubfolder2Names!=null&&x.taskSubfolder2Names!=""&&x.taskSubfolder2Names.toLowerCase().includes(sessionStorage.taskSubfolder2Names.toLowerCase()));
    if(sessionStorage.active != "")
      temp = temp.filter(x=> x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
    this.Count = temp.length;
  }
  else
    this.Count = 0;
}
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
              ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    ws['!cols'] = [{ wpx : 100 },{ wpx : 500 },{ wpx : 120 },{ wpx : 500 },,{ wpx : 500 },,{ wpx : 500 },,{ wpx : 70 },];
   /* save to file */
   XLSX.writeFile(wb, "Task - Product Folder Mapping.xlsx");
  }
  // export(){
  //   /* table id is passed over here */   
  //  let element = document.getElementById('excel-Departments'); 
  //  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //  /* generate workbook and add the worksheet */
  //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //  this.delete_row(wb.Sheets.Sheet1, 1);
  //  ws['!cols'] = [{ wpx : 100 },{ wpx : 500 },{ wpx : 120 },{ wpx : 500 },,{ wpx : 500 },,{ wpx : 500 },,{ wpx : 70 },];
  //  /* save to file */
  //  XLSX.writeFile(wb, "Task - Product Folder Mapping.xlsx");
  // }
  print() {
   
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content,'Task - Product Folder Mapping','trSearch',true);
}
GetMainProductFolderStructure(dccmainMainFolders){
  this.selectedMainTask=[];
  this._facadeService.GetMainProductFolderStructure().subscribe(
    (data) => {
      let dccsubs=[];
       this.taskfolderData=data.returnObject;
       if(this.taskfolderData != null && this.taskfolderData != undefined)
       {
        if(this.taskfolderData.length > 0)
        { 
          this.taskfolderData.forEach(d => {
            d.name = d.name + " (" + d.subFolderCount + ")";
          });
        }
       }
       if(dccmainMainFolders!=null&&dccmainMainFolders!=""&&dccmainMainFolders!=undefined&&dccmainMainFolders.length>0)
       {
        dccmainMainFolders.forEach(element => {
          var d = this.taskfolderData.find(m=>m.id==element.productFolderId);
          if(d!=null&&d!=""&&d!=undefined){
            this.selectedMainTask.push(d);
            if(element.tasksubFolders!=null&&element.tasksubFolders!=""&&element.tasksubFolders!=undefined&&element.tasksubFolders.length>0){
              dccsubs.push(element.tasksubFolders);
            }
            
          }
        });
        this.MainFolderChange(dccsubs);
       }
       else
        this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
MainFolderChange(subs){
  var mainModel=[];
  this.selectedMainTask.forEach(element => {
    var model={
      id:element.id
    }
    mainModel.push(model);
  });
  this._facadeService.GetChildProductFolderStructure(mainModel).subscribe(
    (data) => {
      this.taskSubfolderData=data.returnObject;
        let subsubs =[];
      if(subs!=null&&subs!=""&&subs!=undefined)
      {
        subs.forEach(element => {
          element.forEach(element1 => {
            var s= this.taskSubfolderData.find(m=>m.id==element1.productSubFolderId);
          if(s!=null){
            this.selectedSUbTask.push(s);
            if(element1.tasksubsubFolders!=null&&element1.tasksubsubFolders!=""&&element1.tasksubsubFolders!=undefined&&element1.tasksubsubFolders.length>0){
              subsubs.push(element1.tasksubsubFolders);
            }
          }
          });
        });
        this.SubFolderChange(subsubs);
      }
      else{
       
        this.selectedSUbSubTask=[];
        this.selectedSUbTask=[];
        this.taskSubSubfolderData=[];
        this.SubFolderChange('');
      }
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
GetAllTasks(requiredDocumentID,phaseName,Val) {
  this.showLoading = true;
  this._facadeService.GetAllTasks().subscribe(
    (data) => {
      console.log(data.returnObject);
      this.OrgtaskData=data.returnObject;
      this.taskData = [];
      if(this.OrgtaskData != null && this.OrgtaskData != undefined)
      {
        this.PhaseData = this.OrgtaskData.filter(
          (thing, i, arr) => arr.findIndex((t) => t.phaseName === thing.phaseName) === i
        );
      }
      if(phaseName!=null&&phaseName!=""&&phaseName!=undefined)
      {
        this.selectedPhase=this.PhaseData.find(m=>m.phaseName==phaseName);
        this.BindTaskData(phaseName);
        if(requiredDocumentID!=null&&requiredDocumentID!=""&&requiredDocumentID!=undefined)
          this.selectedTask=this.taskData.find(m=>m.requiredDocumentID==requiredDocumentID);
      }
      this.GetMainProductFolderStructure(Val);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
BindTaskData(phaseName)
{
  this.selectedTask="";
  this.taskData = this.OrgtaskData.filter(x=>x.phaseName == phaseName);
}
CreateTaskProduct(){
if(this.selectedPhase==null||this.selectedPhase==""||this.selectedPhase==undefined){
  this.phaseError="Please select Phase";
}
else if(this.selectedTask==null||this.selectedTask==""||this.selectedTask==undefined){
  this.tasksError="Please select Task";
}
else if(this.selectedMainTask==null||this.selectedMainTask==undefined||this.selectedMainTask.length==0){
  this.taskError="Please select at least one Product Folder";
}
else{
  var dccmainMainFolders=[];
  this.selectedMainTask.forEach(element => {
    var subs=[];
    if(this.selectedSUbTask.length>0){
      var exst = this.selectedSUbTask.filter(m=>m.folderStructureId==element.id);
      if(exst!=null&&exst!=undefined&&exst.length>0){
        exst.forEach(element1 => {
          var subsub=[];
          if(this.selectedSUbSubTask.length>0){
            var exist = this.selectedSUbSubTask.filter(m=>m.folderStructureId==element1.id);
            if(exist!=null&&exist!=undefined&&exist.length>0){
              exist.forEach(element2 => {
                var mmm={
                  ProductSubSubFolderId:element2.id
                }
                subsub.push(mmm);
              });
            }
          }
          var mm ={
            ProductSubFolderId:element1.id,
            tasksubsubFolders:subsub
          }
          subs.push(mm);
        });
      }
    }
    var m ={
      ProductFolderId:element.id,
      tasksubFolders:subs
    }
    dccmainMainFolders.push(m);
  });
  
  var model={
    requiredDocumentID:this.selectedTask.requiredDocumentID,
    Active:this.active,
    Description:this.Description,
    Id:this.id,
    taskMainFolders:dccmainMainFolders
  }
  this.showLoading=true;
  this._facadeService.CreateUpdateTaskProductFolder(model).subscribe(
    (data) => {
      this.showLoading = false;
      if(data.returnCode==0){
        this.addDocsDisplay=false;
        location.reload();
      }
      else{
        this.responseMessage=data.returnMessage;
      }
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
}
  SubFolderChange(subs){
    var mainModel=[];
    this.selectedSUbTask.forEach(element => {
      var model={
        id:element.id
      }
      mainModel.push(model);
    });
    this._facadeService.GetSubChildProductFolderStructure(mainModel).subscribe(
      (data) => {
        this.taskSubSubfolderData=data.returnObject;
          if(subs!=null&&subs!=""&&subs!=undefined){
            subs.forEach(element => {
              element.forEach(element1 => {
                var s= this.taskSubSubfolderData.find(m=>m.id==element1.productSubSubFolderId);
              if(s!=null){
                this.selectedSUbSubTask.push(s);
              }
              });
            });
          }
          else{
            this.selectedSUbSubTask=[];
            
          }
          this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
