<div class="p-grid p-fluid">    
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-col-11" style="float: left">
                  <h4 style="margin-top: 5px">{{Title}} Workflow</h4>
                </div>
                <div class="p-col-1" style="float: right">
                <a routerLink="/workflowlist"> <button pButton pRipple type="button" icon="pi pi-chevron-left"
                    iconPos="left" label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    style="width:auto;"></button></a>
                </div>
            </div>
            <hr />
            <div class="p-grid p-fluid" style="margin-left:auto;margin-right:auto;">
                <div class="p-field p-col-12 p-md-4">
                    <label>Workflow Name<span style="color: red">*</span></label>
                    <input type="text" pInputText [(ngModel)]="workflowname" placeholder="Workflow Name" />
                </div>
                <div class="p-field p-col-12 p-md-2">
                    <label>Version</label>
                    <input type="text" pInputText [(ngModel)]="version" placeholder="Version" [disabled]="true" />
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Description<span style="color: red">*</span></label>
                    <textarea [(ngModel)]="Description" type="text" rows="4" [maxLength]="8000" placeholder="Description" pInputTextarea></textarea>
                </div>
                <div class="p-field p-col-12 p-md-12" style="margin-top: -25px;">
                    <span class="p-input-icon-left" style="float:right; margin-right:5px;">
                        <button (click)="profiledisplay = true;" pButton type="button" icon="pi pi-plus"
                        class="ui-button-info" style="width:auto; float:right; margin-left:10px;" [label]="'Add Profile'"
                        ></button>
                    </span>
                </div>
                <div class="p-col-12 p-md-12" style="margin-top: -35px;">
                    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px;">
                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 20%;">Profiles - {{ProfileCount}}</h5>
                    </div>
                </div>
                <div class="p-col-12 p-md-12" style="margin-top: -29px;">
                    <div class="invoice invoice-header" id="invoice-content">
                    <p-table #dt [value]="profiledata" id="excel-table"  styleClass="p-datatable-striped" [columns]="cols" class="table-align"
                        [filterDelay]="0" selectionMode="multiple" dataKey="profileId"  >
                        <ng-template pTemplate="header" let-columns class="invoice-items">
                            <tr>
                                <th style="text-align: center;width: 100px;">Sequance</th>
                                <th style="text-align: center;width: 120px;">Profile Details</th>
                                <th *ngFor="let col of columns" style="text-align: center">{{col.header}}</th>
                                <th style="text-align: center; width:100px;">Action</th>
                            </tr>
                            <tr id="trSearch">
                                <th></th>
                                <th></th>
                                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="test($event.target.value,col.field,dt)" 
                                        style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-columns="columns" let-expanded="expanded">
                            <tr [pEditableRow]="rowData" [pReorderableRow]="index">
                            <td style="text-align: center;">
                                <i class="pi pi-bars" pReorderableRowHandle></i>
                                    <!-- {{index}} - {{rowData.seq}} -->
                            </td>
                            <td style="text-align: center;">
                                <button type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </td>
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="text-align: left; padding-left: 15px">
                                    {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 0" style="text-align: center">
                                    <button pButton type="button" icon="pi pi-minus" class="ui-button-info" (click)="delete(rowData)" style="height:25px; width:25px;"></button>
                                </td>
                            </ng-container>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-rowData>
                            <tr>
                                <td [attr.colspan]="4" style="padding: 5px;background-color: #ccc;">
                                    <p-table [value]="rowData.details">
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th>Type</th>
                                                <th>Department</th>
                                                <th>Role</th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData1>
                                            <tr>
                                                <td style="text-align: left; padding-left: 15px">{{rowData1.displayName}}</td>
                                                <td style="text-align: left; padding-left: 15px">{{rowData1.department}}</td>
                                                <td style="text-align: left; padding-left: 15px">{{rowData1.role}}</td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="3" style="text-align: left;">No records found...</td>
                                            </tr>
                                        </ng-template>
                                </p-table>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4" style="text-align: left;">No records found...</td>
                            </tr>
                        </ng-template>
                    
                    </p-table>
                    </div>
                    <div class="p-col-12">
                    <div class="card-header">
                        <div class="p-field p-col-12 p-md-9">&nbsp;</div>
                        <div class="p-field p-col-12 p-md-3"  style="float: right;margin-left: 70px;">
                        <button *ngIf="savemode" pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                        class="p-button-raised p-button-success p-mr-2 p-mb-2 width100" (click)="savedata()"></button>
                        <button *ngIf="!savemode" pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Update"
                        class="p-button-raised p-button-success p-mr-2 p-mb-2 width100" (click)="savedata()"></button>
                            &nbsp;&nbsp;&nbsp;
                        <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Reset"
                        class="p-button-raised p-button-secondary p-mr-2 p-mb-2 width100" (click)="resetCtrls()"></button>
                        </div>
                    </div></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>

  <p-dialog header="Add Profile" [(visible)]="profiledisplay" modal="modal" showEffect="fade" [style]="{ width: '40%' }">
  <hr />
  <div class="p-col-12">
    <div class="p-grid p-fluid">
      <div class="p-col-12 p-md-2">&nbsp;</div>
      <div class="p-col-12 p-md-8" style="text-align: left; margin-top:25px;">
        <label>Profile<span style="color: red">*</span></label>
        <!-- <p-dropdown [options]="AllProfileData" [(ngModel)]="selectedprofile" placeholder="Select" optionLabel="profile">
        </p-dropdown> -->
        <p-autoComplete [(ngModel)]="selectedprofile" [suggestions]="filteredProfile"
        (completeMethod)="filterP($event)" field="profile"
        [dropdown]="true">
        <ng-template>
            <div>
            <div>{{ filteredProfile.profile }}</div>
            </div>
        </ng-template>
        </p-autoComplete>
        <small class="p-invalid">{{ profileError }}</small>
      </div>
      <div class="p-col-12 p-md-2">&nbsp;</div>
    </div>
    <div class="p-grid p-fluid p-formgrid">
      <div class="p-field p-col-12 p-md-8"></div>
      <div class="p-field p-col-12 p-md-4">
        <button pButton type="button" label="Add" (click)="AddP()" icon="pi pi-check"
          iconPos="left" class="p-button-raised p-button-success p-mr-2 p-mb-2 width100"></button>
      </div>
    </div>
  </div>
  <br><br><br><br><br><br><br><br>
</p-dialog>


<p-dialog header="Message" [(visible)]="ErrorDisplay" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{ Error }}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="ErrorDisplay=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>