import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../../services/print.service";

@Component({
  selector: 'app-myreviewneeded',
  templateUrl: './myreviewneeded.component.html',
  styleUrls: ['./myreviewneeded.component.scss']
})
export class MyreviewneededComponent implements OnInit {
  constructor(
    private _facadeService: FacadeService,public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private cdr: ChangeDetectorRef
  ) {}
  userId =
    sessionStorage.getItem("userId") != null
      ? parseInt(sessionStorage.getItem("userId"))
      : 0;
  userGuid = sessionStorage.getItem("userGuid");
  cols: any[];
  docsData:any=[];
  DocumentsCount=0;
  showLoading:boolean;
  days:any;
  @ViewChild("dt") table: Table;
  EmailDisplay:boolean;
  isDisabled:boolean=true;
  userFeature:boolean;
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DSBL_SRCH_VIEW_REVIEW");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    sessionStorage.ecrn = "";
      sessionStorage.code ="";
      sessionStorage.revision = "";
      sessionStorage.eco = "";
      sessionStorage.title ="";
      sessionStorage.departmentName = "";
      sessionStorage.owner = "";
      sessionStorage.status = "";
    this.days=5;
    this.cols = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Document Id' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Rev' },
      { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'ECO' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Title' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
      { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Originator' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Status' }
    ];
    var day = this.route.snapshot.queryParamMap.get("days");
    if(day!=null && day!="" && day!=undefined)
    {
      this.days = parseInt(day);
    }
    this.SearchDocuments('R');
  }

  SearchDocuments(from){
    var days = sessionStorage.getItem("daysCountR");
    if(days!=null && days!=""&&days!=undefined &&from=="R"){
    this.days=parseInt(days);
    }
    this.showLoading = true;
    this._facadeService.ReviewDocumentsByDays(this.days,this.userId).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject.length>0){
          data.returnObject.forEach((value, key) => {
            if(value.eco!=null &&value.eco!="" && value.eco!=undefined){
              value.eco= value.eco;
            }
            else{
              value.eco= "N/A";
            }
          });
        }
        this.docsData = data.returnObject; 
        if(this.docsData!=null && this.docsData.length>0){
          this.isDisabled=false;
        }
        else{
          this.isDisabled=true;
        }
        this.CountData();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  
    viewDocDetails(selectedDocId, wfDefinitionId) {
      sessionStorage.docId = selectedDocId;
      sessionStorage.wfDefinitionId = wfDefinitionId;
      this.showLoading = true;
      sessionStorage.isFrom='myreviewneeded';
      sessionStorage.daysCountR= this.days;
        this.router.navigate(['/docdetails', "Approved"]);
    }
    isNumberKey(evt)
    {
       var charCode = (evt.which) ? evt.which : evt.keyCode;
       if (charCode != 46 && charCode > 31 
         && (charCode < 48 || charCode > 57))
          return false;

       return true;
    }
    exportExcel() {
      // Manually load all data
      this.table.first = 0;
      this.table.rows = this.table.totalRecords;
    
      // Wait for data to load (assuming it's loaded asynchronously)
      setTimeout(() => {
        const data = this.getDataFromTable(this.table);
    
        // Proceed with exporting data to Excel
        this.exportDataToExcel(data);
      }, 100); // Adjust the timeout as needed
    }
    
    // Helper function to get visible columns and data from the p-table
    getDataFromTable(table: Table): { columns: any[], data: any[] } {
      const visibleColumns = table.columns.filter(column => column.field);
      const columns = visibleColumns.map(column => {
        return {
          header: typeof column.header === 'object' ? column.header['name'] : column.header,
          field: column.field
        };
      });
      // Use the 'filteredValue' property if available, otherwise fallback to 'value'
      const data = (table.filteredValue || table.value).map(item => {
        const rowData = {};
        visibleColumns.forEach(column => {
          const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
          rowData[columnHeader] = item[column.field];
        });
        return rowData;
      });
    
      return { columns, data };
    }
    
    // Example function to export data to Excel (you can customize this based on your needs)
    exportDataToExcel(data: { columns: any[], data: any[] }) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
    
      // Add headers separately
      const headerRows = [data.columns.map(column => column.header)];
      XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
    
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
      // Customize the column widths if needed
      ws['!cols'] = [{ wpx : 100 },{ wpx :  40},{ wpx : 40 },{ wpx : 550 },{ wpx : 120 },{ wpx : 120 },{ wpx : 80 }];
     /* save to file */
     XLSX.writeFile(wb, "My Review Needed.xlsx");
    }
  // exportExcel() {
  //    /* table id is passed over here */   
  //    let element = document.getElementById('excel-table'); 
  //    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //    /* generate workbook and add the worksheet */
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //    this.delete_row(wb.Sheets.Sheet1, 1);
  //    ws['!cols'] = [{ wpx : 100 },{ wpx :  40},{ wpx : 40 },{ wpx : 550 },{ wpx : 120 },{ wpx : 120 },{ wpx : 80 }];
  //    /* save to file */
  //    XLSX.writeFile(wb, "My Review Needed.xlsx");
  // }

  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  print() {
    
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content,"My Review Needed",'trSearch',true);
  }
  SendEmail(){
    if(this.docsData!=null && this.docsData.length>0){
      this.EmailDisplay=true;
      this._facadeService.SendReviewReport(this.userId,"reviewneeded",this.docsData).subscribe(
        (response: any) => {
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  test(val,field,dt)
{
  if(field == "ecrn")
    sessionStorage.ecrn = val;
  else if(field == "code")
    sessionStorage.code = val;
  else if(field == "revision")
    sessionStorage.revision = val;
  else if(field == "title")
    sessionStorage.title = val;
  else if(field == "departmentName")
    sessionStorage.departmentName = val;
  else if(field == "eco")
    sessionStorage.eco = val;
  else if(field == "owner")
    sessionStorage.owner = val;
  else if(field == "status")
    sessionStorage.status = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.docsData != undefined && this.docsData != null)
  {
    var temp = this.docsData;
    if(sessionStorage.ecrn != ""&&sessionStorage.ecrn != null)
      temp = temp.filter(x=> x.ecrn!=null&&x.ecrn!=""&&x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
    if(sessionStorage.code != "")
      //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != "")
      temp = temp.filter(x=> x.revision.toString().includes(sessionStorage.revision.toString()));
      if(sessionStorage.eco != "")
      temp = temp.filter(x=> x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
    if(sessionStorage.departmentName != "")
      temp = temp.filter(x=> x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
    if(sessionStorage.owner != "")
      temp = temp.filter(x=> x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
    if(sessionStorage.status != "")
      temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
