import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LoginService } from "../services/login.service";
import { DashboardService } from "../services/dashboard.service";
import { AdminService } from "../services/admin.service";
import { HoldDocsService } from "../services/hold-docs.service";
import { CreatedocService } from "../services/createdoc.service";
import { ApprovalsNeededService } from "../services/approvals-needed.service";
import { WorkflowsService } from "../services/workflows.service";
import { DocdetailsService } from "../services/docdetails.service";
import { MydocsService } from "../services/mydocs.service";
import { WorkflowService } from "../services/workflow.service";
import { CommonService } from "../services/common.service";
import { EcoService } from "../services/eco.service";
import { CreatestandardService } from "../services/createstandard.service";
import { CreateclauseService } from '../services/createclause.service';
import { ArchivalService } from '../services/archival.service';
export interface Workflow {  productline;  workflowcharacteristic;}
export interface Ecn {  ecnlevel;  workflowcharacteristic;}

@Injectable({
  providedIn: "root",
})
export class FacadeService {
  constructor(
    private _loginService: LoginService,
    private _dashboardService: DashboardService,
    private _adminService: AdminService,
    private _holdDocsService: HoldDocsService,
    private _createdocService: CreatedocService,
    private _approvalsNeededService: ApprovalsNeededService,
    private _workflowsService: WorkflowsService,
    private _docdetailsService: DocdetailsService,
    private _mydocsService: MydocsService,
    private _http: HttpClient,
    private _workflowService: WorkflowService,
    private _commonService: CommonService,
    private _ecoService: EcoService,
    private __createstandardService: CreatestandardService,
    private _createclauseService: CreateclauseService,
    private _archivalService: ArchivalService,
  ) {}

  login(model) {
    return this._loginService.login(model);
  }

  getworkflowcharacteristic() {    
    return this._http.get<any>('assets/workflow.json').toPromise().then(res => <Workflow[]>res.data).then(data => { return data; });
  }

  getECNworkflowcharacteristic() {    
    return this._http.get<any>('assets/ecn.json').toPromise().then(res => <Ecn[]>res.data).then(data => { return data; });
  }
  
  getDashboardCounts(model,userGuid) {
    return this._dashboardService.getDashboardCounts(model,userGuid);
  }

  GetUserDetails(userGuid){
    return this._dashboardService.GetUserDetails(userGuid);
  }

  getDashboardTableData(model) {
    return this._dashboardService.getDashboardTableData(model);
  }

  getChartData(model) {
    return this._dashboardService.getChartData(model);
  }

  GetWorkflowApprovers(model){
    return this._createdocService.GetWorkflowApprovers(model);
  }
  RptDocumentsBySearch(model)
  {
    return this._createdocService.RptDocumentsBySearch(model);
  }
  getDocsTypes() {
    return this._createdocService.getDocsTypes();
  }
  getDocsTypesESI(ESI,DTID){
    return this._createdocService.getDocsTypesESI(ESI,DTID);
  }
  workFlowsList(model) {
    return this._createdocService.workFlowsList(model);
  }

  UpdateChangeRequestDetails(changeRequest){
    return this._createdocService.UpdateChangeRequestDetails(changeRequest);
  }

  getProductsList(){
    return this._createdocService.getProductsList();
  }
  approvalNeededData(model) {
    return this._approvalsNeededService.approvalNeededData(model);}

    DelegatedDocuments(userId){
      return this._approvalsNeededService.DelegatedDocuments(userId);
    }
  approvalNeededDataRPT(elapsedDays,timeZone,userId) {
    return this._approvalsNeededService.approvalNeededDataRPT(elapsedDays,timeZone,userId);
  }

  originatedByStatus(model) {
    return this._approvalsNeededService.originatedByStatus(model);
  }

  documentByStatus(model) {
    return this._approvalsNeededService.documentByStatus(model);
  }

  documentByProject(projectGuids,timeZone,userId){
    return this._approvalsNeededService.documentByProject(projectGuids,timeZone,userId);
  }

  altDocumentByProject(projectGuids,timeZone,userId){
    return this._approvalsNeededService.altDocumentByProject(projectGuids,timeZone,userId);
  }

  approvalDelegatedData(userId) {
    return this._approvalsNeededService.approvalDelegatedData(userId);
  }


  GetEmailNotifications(model)
  {
    return this._mydocsService.GetEmailNotifications(model);
  }
UpdateOrginator(model)
{
  return this._mydocsService.UpdateOrginator(model);
}
  mydocsData(model) {
    return this._mydocsService.mydocumentsData(model);
  }
  myobsdocsData(model) {
    return this._mydocsService.myobsdocumentsData(model);
  }


  holddocsData(model) {
    return this._holdDocsService.holddocumentsData(model);
  }

  getDocDetailsById(id,userId) {
    return this._docdetailsService.getDocDetailsById(id,userId);
  }

  getApprovals(id) {
    return this._docdetailsService.getApprovals(id);
  }

  sendForApproval(dId, wId) {
    return this._docdetailsService.sendForApproval(dId, wId);
  }
  SendForObsApproval(dId, wId){
    return this._docdetailsService.SendForObsApproval(dId, wId);
  }
  DocumentSupervisorPermissionLimitedAccess(UserGuid,typeId){
    return this._docdetailsService.DocumentSupervisorPermissionLimitedAccess(UserGuid, typeId);
  }
  documentAction(dId, uGid, action) {
    return this._docdetailsService.documentAction(dId, uGid, action);
  }

  //BEGIN :: Profile and WorkFlow setup
  getProfiles() {
    return this._workflowService.getProfiles();
  }
  getDepartments() {
    return this._createdocService.getDepartments();
  }
  getRoles(){
    return this._createdocService.getRoles();
  }
  getDocTypes() {
    return this._commonService.getDocTypes();
  }

  getDepartMents() {
    return this._commonService.getDepartMents();
  }

  getProjects(userid) {
    return this._commonService.getProjects(userid);
  }

  getAllActiveProducts(){
    return this._commonService.getAllActiveProducts();
  }

  getRolesByDepartmentGuid(departMentGuid) {
    return this._commonService.getRolesByDepartmentGuid(departMentGuid);
  }

  getRolesByDepartmentGuids(departMentGuids) {
    return this._commonService.getRolesByDepartmentGuids(departMentGuids);
  }

  createProfile(data) {
    return this._mydocsService.CreateProfile(data);
  }

  updateProfile(data) {
    return this._mydocsService.updateProfile(data);
  }
  GetProfileDetailsByID(profileId){
    return this._workflowService.GetProfileDetailsByID(profileId);
  }
  createWorkflowSetup(data) {
    return this._workflowService.createWorkflowSetup(data);
  }

  getAllWorkflows() {
    return this._workflowService.getAllWorkflows();
  }

  getAllWorkDefinitions(showprofile) {
    // return this._http.get(
    //   //this.baseUrl["API_ENDPOINTS"]["ELSAAPI"]+"DMSWorkflow/GetWorkflowDefinitions"
    //   this.baseUrl["API_ENDPOINTS"]["ELSAAPI"]+"v1/workflow-registry"
    // );
    return this._workflowService.getAllWorkDefinitions(showprofile);
  }

  DeleteWorkflow(id){
    return this._workflowService.DeleteWorkflow(id);
  }
  CreateWorkflow(model){
    return this._workflowService.CreateWorkflow(model);
  }
  UpdateWorkflow(model){
    return this._workflowService.UpdateWorkflow(model);
   }

  DeleteWFSetup(setupid){
    return this._workflowService.DeleteWFSetup(setupid);
  }

  DeleteDocument(docid){
    return this._mydocsService.DeleteDocument(docid);
  }

  // getAllWorkDefinitions() {
  //   return this._http.get(
  //     this._configService.get("ELSAAPI") + "api/DMSWorkflow/GetWorkflowDefinitions"
  //   );
  // }
  
  //End :: Profile and WorkFlow setup

  getDocumentTypesTableData() {
    return this._adminService.getDocumentTypesTableData();
  }

  docTypes(model) {
    return this._adminService.docTypes(model);
  }

  editdocTypes(model) {
    return this._adminService.editdocTypes(model);
  }

  deleteDoc(docId) {
    return this._adminService.deleteDoc(docId);
  }

  deleteProfile(docId) {
    return this._adminService.deleteProfile(docId);
  }

  getAllClasses(modelArray) {
    return this._createdocService.getAllClasses(modelArray);
  }

  getAllDepartments() {
    return this._createdocService.getDepartments();
  }

  getListStandardData(model) {
    return this._adminService.getClausesData(model);
  }

  getDashboardTimeline(userId,userGuid,days) {
    return this._dashboardService.getDashboardTimelineData(userId,userGuid,days);
  }

  getDocDetailsData(userId,userGuid,accessuserId) {
    return this._dashboardService.getDashboardDataFor30Days(userId,userGuid,accessuserId);
  }

  getStandardClauses(modelArray) {
    return this._ecoService.getStandardClauses(modelArray);
  }

  getECODocuments(did, uid) {
    return this._ecoService.getECODocuments(did, uid);
  }
  
  getRolesData() {
    return this._adminService.getRoles();
  }

  RolesList(DepartmentGUID) {
    return this._adminService.RoleList(DepartmentGUID);
  }

  GetUsersByRoleGuid(RoleGUID) {
    return this._commonService.GetUsersByRoleGuid(RoleGUID);
  }

  getUsers() {
    return this._commonService.getUsers();
  }

  createDelegation(model) {
    return this._adminService.createDelegation(model);
  }

  getStandards() {
    return this.__createstandardService.getStandards();
  }

  getStandardName() {
    return this.__createstandardService.getStandards();
  }

  standard(model) {
    return this.__createstandardService.standard(model);
  }

  getClauseGroup() {
    return this._createclauseService.getClauseGroup();
  }

  clause(model) {
    return this._createclauseService.clause(model);
  }

  /* Archieve and Delegation */
  GetAllDelegations() {
    return this._adminService.GetAllDelegations();
  }
  GetDelegationsByIDs(data) {
    return this._adminService.GetDelegationsByIDs(data);
  }
  ActiveDelegations() {
    return this._adminService.ActiveDelegations();
  }
  GetExpiredDocsById(userId) {
    return this._archivalService.GetExpiredDocsById(userId);
  }
  
  GetArchivedDocsById(userId) {
    return this._archivalService.GetArchivedDocsById(userId);
  }

  ArchiveDocs(uId,model) {
    return this._archivalService.ArchiveDocs(uId,model);
  }

  GetECRNDetails(Ecrn){
    return this._docdetailsService.GetECRNDetails(Ecrn);
  }
  getwfProfile(model) {
    return this._workflowService.getwfProfile(model);
  }
  IsResubmitDocumentAvailable(docId){
    return this._mydocsService.IsResubmitDocumentAvailable(docId);
  }
  sendRemainder(docID){
    return this._mydocsService.sendRemainder(docID);
  }
  SendRemainderExpiryReview(docID,action){
    return this._mydocsService.SendRemainderExpiryReview(docID,action);
  }
  GetProfileNames(){
    return this._workflowService.GetProfileNames();
  }

  searchDocuments(searchdata) {
    return this._mydocsService.searchDocuments(searchdata);
  }

  GetApplications(){
    return this._mydocsService.GetApplications();
  }

  getDelegations(model){
    return this._mydocsService.getDelegations(model);
  }
  cancelDelegation(DGID,UID){
    return this._mydocsService.cancelDelegation(DGID,UID);
  }
  cancelDelegations(UID,model){
    return this._mydocsService.cancelDelegations(UID,model);
  }
  HoldDocument(data){
    return this._docdetailsService.HoldDocument(data);

  }
  ReleaseDocument(data) {
    return this._docdetailsService.ReleaseDocument(data);
  }

  ApproveDocument(data) {
    return this._docdetailsService.ApproveDocument(data);
  }

  RejectDocument(data) {
    return this._docdetailsService.RejectDocument(data);
}

ReviewDocumentsByDays(days,userId){
  return this._mydocsService.ReviewDocumentsByDays(days,userId);
}
SendEmail(userId,reportName,documents){
  return this._mydocsService.SendEmail(userId,reportName,documents);
}
SendReviewReport(userId,reportName,documents){
  return this._mydocsService.SendReviewReport(userId,reportName,documents);
}
SendSummaryReport(userId,reportName,documents){
  return this._mydocsService.SendSummaryReport(userId,reportName,documents);
}
GetDepartments(){
  return this._docdetailsService.GetDepartments();
}
GetUsersByRole(roleGuid){
  return this._workflowService.GetUsersByRole(roleGuid);
}
IsCMSResubmitDocumentAvailable(docId){
  return this._mydocsService.IsCMSResubmitDocumentAvailable(docId);
}
UpdateSecurityToken(token){
  return this._dashboardService.UpdateSecurityToken(token);
}
CreateSecurityToken(){
  return this._dashboardService.CreateSecurityToken();
}
downloadFile(filename,newname){
  return this._mydocsService.downloadFile(filename,newname);
}
UpdateDocument(data){
  return this._mydocsService.UpdateDocument(data);
}
ResubmitDocument(data){
  return this._mydocsService.ResubmitDocument(data);
}
CreateDocument(data){
  return this._mydocsService.CreateDocument(data);
}
SignOffDocument(data){
  return this._mydocsService.SignOffDocument(data);
}
AddDelegations(data){
  return this._mydocsService.AddDelegations(data);
}
AddDelegation(data){
  return this._mydocsService.AddDelegation(data);
}
CreateStandard(data){
  return this._mydocsService.CreateStandard(data);
}
AddClause(data){
  return this._mydocsService.AddClause(data);
}
GetProductLines(){
  return this._mydocsService.GetProductLines();
}
getObsDoctypes(){
  return this._mydocsService.getObsDoctypes();
}
GetNewCode(doctypeId,parentdocId,action){
  return this._mydocsService.GetNewCode(doctypeId,parentdocId,action);
}
GetNewECN(){
  return this._mydocsService.GetNewECN();
}
GetProductFolderStructure() {
  return this._adminService.GetProductFolderStructure();
}

CreateUpdateProductFolderStructure(model) {
  return this._adminService.CreateUpdateProductFolderStructure(model);
}
CreateUpdateSubProductFolderStructure(model) {
  return this._adminService.CreateUpdateSubProductFolderStructure(model);
}
GetProductFolderStructureDetailsById(id) {
  return this._adminService.GetProductFolderStructureDetailsById(id);
}
GetSubProductFolderStructureDetailsById(id) {
  return this._adminService.GetSubProductFolderStructureDetailsById(id);
}

GetDocTypeFolderStructure() {
  return this._adminService.GetDocTypeFolderStructure();
}
GetMainDocTypeFolderStructure() {
  return this._adminService.GetMainDocTypeFolderStructure();
}
GetDocTypePermissions(){
  return this._adminService.GetDocTypePermissions();
}
CreateUpdateDocTypePermissions(model){
  return this._adminService.CreateUpdateDocTypePermissions(model);
}
SaveGroupFolderAccess(model){
  return this._adminService.SaveGroupFolderAccess(model);
}
GetTaskPermissions(){
  return this._adminService.GetTaskPermissions();
}
LimitedAccessGroups(roles){
  return this._adminService.LimitedAccessGroups(roles);
}
CreateUpdateTaskPermissions(model){
  return this._adminService.CreateUpdateTaskPermissions(model);
}
GetChildDocTypeFolderStructure(mainModel){
  return this._adminService.GetChildDocTypeFolderStructure(mainModel);
}
GetSubChildDocTypeFolderStructure(mainModel){
  return this._adminService.GetSubChildDocTypeFolderStructure(mainModel);
}
GetMainProductFolderStructure() {
  return this._adminService.GetMainProductFolderStructure();
}
GetChildProductFolderStructure(mainModel){
  return this._adminService.GetChildProductFolderStructure(mainModel);
}
GetSubChildProductFolderStructure(mainModel){
  return this._adminService.GetSubChildProductFolderStructure(mainModel);
}
GetDocTypeDCCFolders(){
  return this._adminService.GetDocTypeDCCFolders();
}
GetFolderMapsByIDs(type,model){
  return this._adminService.GetFolderMapsByIDs(type,model);
}
GetTaskProductFolders(){
  return this._adminService.GetTaskProductFolders();
}
CreateUpdateDocTypeFolderStructure(model) {
  return this._adminService.CreateUpdateDocTypeFolderStructure(model);
}
CreateUpdateSubDocTypeFolderStructure(model) {
  return this._adminService.CreateUpdateSubDocTypeFolderStructure(model);
}
CreateFolder(model) {
  return this._adminService.CreateFolder(model);
}
UpdateFolder(model) {
  return this._adminService.UpdateFolder(model);
}
CreateUpdateSubSubDocTypeFolderStructure(model) {
  return this._adminService.CreateUpdateSubSubDocTypeFolderStructure(model);
}
CreateUpdateSubSubProductFolderStructure(model){
  return this._adminService.CreateUpdateSubSubProductFolderStructure(model);
}
GetDocTypeFolderStructureDetailsById(id) {
  return this._adminService.GetDocTypeFolderStructureDetailsById(id);
}
GetSubDocTypeFolderStructureDetailsById(id) {
  return this._adminService.GetSubDocTypeFolderStructureDetailsById(id);
}
GetSubSubProductFolderStructureDetailsById(id){
  return this._adminService.GetSubSubProductFolderStructureDetailsById(id);
}
GetSubSubDocTypeFolderStructureDetailsById(id){
  return this._adminService.GetSubSubDocTypeFolderStructureDetailsById(id);
}
GetAllRoleGroups(){
  return this._adminService.GetAllRoleGroups();
}
GetGroups(model){
  return this._adminService.GetGroups(model);
}
GetGroupFolders(ftype,model){
  return this._adminService.GetGroupFolders(ftype,model);
}
CreateGroup(model){
  return this._adminService.CreateGroup(model);
}
UpdateGroup(model){
  return this._adminService.UpdateGroup(model);
}
GetFolderById(id){
  return this._adminService.GetFolderById(id);
}
GetGroupRoleDetailsById(GroupId){
  return this._adminService.GetGroupRoleDetailsById(GroupId);
}
GetGroupById(GetGroupById){
  return this._adminService.GetGroupById(GetGroupById);
}
CheckRoleGroupInactiveById(RoleGroupGuid){
  return this._adminService.CheckRoleGroupInactiveById(RoleGroupGuid);
}
GetAllTasks(){
  return this._createdocService.GetAllTasks();
}
CreateUpdateDocTypeDCCFolder(model){ 
  return this._adminService.CreateUpdateDocTypeDCCFolder(model);
}
FolderMapping(model){
  return this._adminService.FolderMapping(model);
}
UpdateFolderMapping(model){
  return this._adminService.UpdateFolderMapping(model);
}
CreateUpdateTaskProductFolder(model){
  return this._adminService.CreateUpdateTaskProductFolder(model);
}
GetTaskProductDetailsById(id){
  return this._adminService.GetTaskProductDetailsById(id);
}
GetDocTypeDCCDetailsById(id){
  return this._adminService.GetDocTypeDCCDetailsById(id);
}
ObsoleteDocument(model){
  return this._adminService.ObsoleteDocument(model);
}
GetMenus(model){
  return this._adminService.GetMenus(model);
}
GetMenusByRoles(applicationguid,model){
  return this._adminService.GetMenusByRoles(applicationguid,model);
}
GetMenusByUser(userId,applicationid){
  return this._adminService.GetMenusByUser(userId,applicationid);
}
GetFolders(model){
  return this._adminService.GetFolders(model);
}
LimitedAccessDocuments(userid,type){
  return this._adminService.LimitedAccessDocuments(userid,type);
}
LimitedAccessProducts(userid){
  return this._adminService.LimitedAccessProducts(userid);
}
LimitedAccessProductFoldersByID(userid,productid){
  return this._adminService.LimitedAccessProductFoldersByID(userid,productid);
}
LimitedAccessDocumentsByID(model){
  return this._adminService.LimitedAccessDocumentsByID(model);
}
AddUserTracking(model){
  return this._adminService.AddUserTracking(model);
}
}
