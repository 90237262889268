import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})

export class HoldDocsService {

  constructor(private _httputilityService: HttputilityService) {}
//baseUrl: any = "http://132.148.242.138/FPDMS/api/";

holddocumentsData(model) {
  return this._httputilityService.Add("HoldDocuments", model);
}

}
