import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortEvent, TreeNode } from 'primeng/api';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-allproduts',
  templateUrl: './allproduts.component.html',
  styleUrls: ['./allproduts.component.scss']
})
export class AllprodutsComponent implements OnInit {
  ascendingSort = "Decending";
  productroleGroupNames="";
  files:any=[];
  ProductTitle="";
  DocumentsCount=0;
  hierarchy=0;
  showLoading:boolean;
  headerName="Product Folders";
  userId = parseInt(sessionStorage.getItem("userId"));
  userGuid = sessionStorage.getItem('userGuid');
  source="NA";
  textlabelheader="";
  addDisplay=false;
  isdisabled=true;
  TextLabel="";
  folderName="";
  description="";
  active:boolean;
  nameError="";
  responseMessage="";
  folderId=0;
  mainFolderId=0;
  parentFolderId=0;
  roleGroupNames="";
  cols=[];
  mulgrps=[];
  docsData=[];
  folderfiles=[];
  rolegroupsdisplay=false;
  taskgrps=[];
  TaskGroups="";
  TaskGroups1="";
  taskgroupsdisplay=false;
  clientId = sessionStorage.getItem('clientId')!=''&& sessionStorage.getItem('clientId')!=null&&sessionStorage.getItem('clientId')!=undefined?parseInt(sessionStorage.getItem('clientId')):0;
  applicationId = parseInt(sessionStorage.getItem('appliId')); 
  constructor(private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,
    private route:ActivatedRoute) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Document Id' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Title' }
    ];
    var source=this.route.snapshot.paramMap.get('source');
    if(source=="admin"||source=="Admin"){
      this.source="A";
      this.headerName="Admin / Product Folders";
      this.GetSmartFolders('A');
    }
    else{
      this.source="NA";
      this.headerName="Product Folders";
      this.GetSmartFolders('NA');
    }
  }
  expandAll(){
    this.files.forEach( node => {
        this.expandRecursive(node, true);
    } );
}

collapseAll(){
    this.files.forEach( node => {
        this.expandRecursive(node, false);
    } );
}
private expandRecursive(node:TreeNode, isExpand:boolean){
  node.expanded = isExpand;
  if (node.children){
      node.children.forEach( childNode => {
          this.expandRecursive(childNode, isExpand);
      } );
  }
}

GetSmartFolders(type){
  sessionStorage.prodmainFiles="";
  this.files=[];
  this.showLoading = true;
  var userId=0;
  if(type=="NA"){
    userId = this.userId
  }
  this._facadeService.LimitedAccessProducts(userId).subscribe(
    (data) => {
      var folderData=data.returnObject;
      if(folderData!=null&&folderData!=""&&folderData!=undefined&&folderData.length>0){
        folderData.forEach(element => {
          element.childers=element.subProducts;
          let childs =[];
          if(element.childers!=null&& element.childers.length>0){
            element.childers.forEach(element1 => {
              var file = {
                key: element1.productId,
                label: element1.productName+" ("+element1.count+")",
                folderName:element1.productName,
                collapsedIcon: 'pi pi-folder',
                expandedIcon: 'pi pi-folder-open',
                expanded: false,
                type:"sub",
                hierarchy:1,
                searchopt:element1.count>0?true:false,
                usertype:type,
                doccount:element1.count
              }
              childs.push(file);
            });
          }
          var file = {
            key: element.productId,
            folderName:element.productName,
            label: element.productName,//+" - "+element.count,
            collapsedIcon: 'pi pi-folder',
            expandedIcon: 'pi pi-folder-open',
            children: childs,
            expanded: false,
            type: 'urlplus',
            usertype:type,
            doccount:element.count
          }
          this.files.push(file);
          
        });
      }
      this.showLoading = false;
      sessionStorage.prodmainFiles=JSON.stringify(this.files);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

  DMSToken(rowData){
    var urlfile =this.baseUrl["API_ENDPOINTS"]["DMSUI"] +
                        "entrypoint?userguid=" +
                        this.userGuid +
                        "&userId=" +
                        this.userId +
                        '&destination=docdetails'+"&docId="+rowData.documentId+"&appliId="+this.applicationId+"&clientId="+this.clientId
    this.showLoading = true;
    this._facadeService.CreateSecurityToken().subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
          var access_token = data.returnObject;
          urlfile = urlfile+'&access_token='+access_token;
          window.open(urlfile);
        }
        else{
          location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }

  EditFolder(node){
    this.TextLabel = "Update";
    if (node.hierarchy == 0){
        this.textlabelheader = "Edit Product Folder";
    }
    else if (node.hierarchy == 1){
      this.textlabelheader = "Edit " + node.folderName + " Level 1 Folder";
  }
    else{
        this.textlabelheader = "Edit " + node.folderName + " Level 2 Folder";
    }
    this.folderName = "";
    this.description = "";
    this.nameError = "";
    this.active = true;
    this.isdisabled = false;
    this.folderId = node.key;
    this.mainFolderId = node.mainFolderId;
    this.parentFolderId=node.parentFolderId;
    this.responseMessage = "";
    this.showLoading = true;
      this._facadeService.GetFolderById(this.folderId).subscribe(
        (data) => {
          this.showLoading = false;
          var prod = data.returnObject;
          this.folderName = prod.folderName;
          this.description = prod.description;
          this.active = prod.active;
          this.folderId = prod.folderId;
          this.mainFolderId = prod.mainFolderId;
          this.parentFolderId = prod.parentFolderId;
          this.addDisplay = true;
          this.hierarchy=prod.hierarchy;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }

  CreateSubFolder(node){
    this.addDisplay = true;
    this.TextLabel = "Save";
    this.textlabelheader = "Add " + node.folderName + " Level 1 Folder";
    this.folderName = "";
    this.description = "";
    this.nameError = "";
    this.active = true;
    this.isdisabled = true;
    this.folderId = 0;
    this.mainFolderId=node.key;
    this.parentFolderId=node.key;
    this.responseMessage = "";
    this.hierarchy=1;
  }

  CreateSubSubFolder(node){
    this.addDisplay = true;
    this.TextLabel = "Save";
    this.textlabelheader = "Add " + node.folderName + " Level 2 Folder";
    this.folderName = "";
    this.description = "";
    this.nameError = "";
    this.active = true;
    this.isdisabled = true;
    this.folderId = 0;
      this.parentFolderId=node.key;
      this.mainFolderId=node.mainFolderId;
    this.responseMessage = "";
    this.hierarchy=2;
  }
  CreateUpdateFolder(){
    this.nameError = "";
    this.responseMessage = "";
    if (this.folderName == "" || this.folderName == null || this.folderName == undefined) {
      this.nameError = "Please enter name";
    }
    else {
      var model = {
        folderId: this.folderId,
        folderName: this.folderName,
        description: this.description,
        parentFolderId: this.parentFolderId,
        mainFolderId: this.mainFolderId,
        type: "Product",
        active: this.active,
        createdBy:this.userId,
        hierarchy:this.hierarchy
      }
   if (this.folderId > 0) {
        this.showLoading = true;
        this._facadeService.UpdateFolder(model).subscribe(
          (data) => {
            if (data.returnCode == 0) {
              this.addDisplay=false;
             location.reload();
            }
            else {
              this.showLoading = false;
              this.responseMessage = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else {
        this.showLoading = true;
        this._facadeService.CreateFolder(model).subscribe(
          (data) => {
            if (data.returnCode == 0) {
              this.addDisplay=false;
             location.reload();
            }
            else {
              this.showLoading = false;
              this.responseMessage = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
  }
  AddNewButton(){
      this.addDisplay = true;
      this.TextLabel = "Save";
      this.textlabelheader="Add Product Folder";
      this.folderName = "";
      this.description = "";
      this.nameError = "";
      this.active = true;
      this.isdisabled = true;
      this.folderId = 0;
      this.responseMessage = "";
      this.parentFolderId=0;
      this.mainFolderId=0;
      this.hierarchy=0;
  }

  GetFile(rowdata) {
    var fileName=rowdata.fileName;
    var userFileName=rowdata.userFileName;
    if (fileName != null && fileName != "" && fileName!= undefined) {
      this.showLoading = true;
      var newname = userFileName.substr(0, userFileName.lastIndexOf('.'));
      this.Download(fileName, newname, userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      this.showLoading=false;
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
     this.showLoading=false;
  }
  updateFilteredNodes(node) {
    var filess= JSON.parse(sessionStorage.getItem('prodmainFiles'));
    var mnode:any;
    filess.forEach(element => {
      if(element.key==node.key&&element.mainFolderId==node.mainFolderId&&element.parentFolderId==node.parentFolderId){
        return mnode=element;
      }
      else{
        if(element.children!=null&&element.children.length>0){
          element.children.forEach(element1 => {
            if(element1.key==node.key&&element1.mainFolderId==node.mainFolderId&&element1.parentFolderId==node.parentFolderId){
              return mnode=element1;
            }
            else{
              if(element1.children!=null&&element1.children.length>0){
                element1.children.forEach(element2 => {
                  if(element2.key==node.key&&element2.mainFolderId==node.mainFolderId&&element2.parentFolderId==node.parentFolderId){
                    return mnode=element2;
                  }
                  else{
                    if(element2.children!=null&&element2.children.length>0){
                      element2.children.forEach(element3 => {
                        if(element3.key==node.key&&element3.mainFolderId==node.mainFolderId&&element3.parentFolderId==node.parentFolderId){
                          return mnode=element3;
                        }
                        else{
                          if(element3.children!=null&&element3.children.length>0){
                            element3.children.forEach(element4 => {
                              if(element4.key==node.key&&element4.mainFolderId==node.mainFolderId&&element4.parentFolderId==node.parentFolderId){
                                return mnode=element4;
                              }
                            });
                          }
                          
                        }
                      });
                    }
                   
                  }
                });
              }
            }
          });
        }
      }
    });
    const filteredChildren = this.filterNodes(mnode.children, node.searchQuery);
      node.children = filteredChildren;
}
filterNodes(node: TreeNode[], query: string): TreeNode[] {
  return node.filter(n => n.label.toLowerCase().includes(query.toLowerCase()));
}
sortNodes(type) {
  this.files.sort((a, b) => {
    if (type=="A") {
      return a.label.localeCompare(b.label);
    } else {
      return b.label.localeCompare(a.label);
    }
  });

  this.files.forEach(folder => {
    folder.children.sort((a, b) => {
      if (type=="A") {
        return a.label.localeCompare(b.label);
      } else {
        return b.label.localeCompare(a.label);
      }
    });
  });
}
handleTemplateClick(node){
  this.docsData=[];
  this.productroleGroupNames="";
  this.roleGroupNames="";
  this.DocumentsCount=0;
  this.productroleGroupNames=node.folderName; //+" ("+node.doccount+")";
  this.ProductTitle=node.folderName;
  this.showLoading = true;
  var userId=0;
  if(node.usertype=="NA"){
    userId = this.userId
  }
  this.folderfiles=[];
  this._facadeService.LimitedAccessProductFoldersByID(userId,node.key).subscribe(
    (data) => {
      var folderData=data.returnObject;
      if(folderData!=null&&folderData!=""&&folderData!=undefined&&folderData.length>0){
        folderData.forEach(element => {
          element.childers=element.subFolders;
          let childs =[];
          if(element.childers!=null&& element.childers.length>0){
            element.childers.forEach(element1 => {
              let subChilds= [];
              element1.childers=element1.subFolders;
              if(element1.childers!=null&&element1.childers.length>0){
                element1.childers.forEach(element3 => {
                  let subsubChilds= [];
                  element3.childers=element3.subFolders;
                  if(element3.childers!=null&&element3.childers.length>0){
                    element3.childers.forEach(element4 => {
                      let subsubsubChilds=[];
                      var file = {
                        key: element4.folderId,
                        folderName:element4.folderName,
                        label: element4.folderName+" ("+element4.count+")",
                        roleGroups : element4.groupNames,
                        collapsedIcon: 'pi pi-folder',
                        expandedIcon: 'pi pi-folder-open',
                        children: subsubsubChilds,
                        mainFolderId:element4.mainFolderId,
                        parentFolderId:element4.parentFolderId,
                        expanded: false,
                        hierarchy:element4.hierarchy,
                        type:element4.hierarchy==2?"subfol1":"",
                        searchopt:element4.count>0?true:false,
                        productId:node.key
                      }
                      subsubChilds.push(file);
                    });
                  }
                  var file = {
                    key: element3.folderId,
                    folderName:element3.folderName,
                    label: element3.folderName+" ("+element3.count+")",
                    mainFolderId:element3.mainFolderId,
                    parentFolderId:element3.parentFolderId,
                    roleGroups : element3.groupNames,
                    collapsedIcon: 'pi pi-folder',
                    expandedIcon: 'pi pi-folder-open',
                    children: subsubChilds,
                    expanded: false,
                    type:element3.hierarchy==1?"subfol":"",
                    hierarchy:element3.hierarchy,
                    searchopt:element3.count>0?true:false,
                    productId:node.key
                  }
                  subChilds.push(file);
                });
              }
              var file = {
                key: element1.folderId,
                label: element1.folderName+" ("+element1.count+")",
                mainFolderId:element1.mainFolderId,
                parentFolderId:element1.parentFolderId,
                folderName:element1.folderName,
                roleGroups : element1.groupNames,
                collapsedIcon: 'pi pi-folder',
                expandedIcon: 'pi pi-folder-open',
                children: subChilds,
                expanded: false,
                type:"sub",
                hierarchy:element1.hierarchy,
                searchopt:element1.count>0?true:false,
                productId:node.key
              }
              childs.push(file);
            });
          }
          var file = {
            key: element.folderId,
            folderName:element.folderName,
            label: element.folderName+" ("+element.count+")",
            roleGroups : element.groupNames,
            collapsedIcon: 'pi pi-folder',
            expandedIcon: 'pi pi-folder-open',
            children: childs,
            expanded: false,
            type: 'urlplus',
            productId:node.key
          }
          this.folderfiles.push(file);
          
        });
      }
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
  var model ={
    "operation": "",
    "userID": userId,
    "ID": node.key,
    "folderID": 0,
    "type": "product"
  }
  this._facadeService.LimitedAccessDocumentsByID(model).subscribe(
    (data) => {
      this.docsData=data.returnObject;
      this.showLoading = false;
      if(data.returnCode==0){
        this.DocumentsCount=this.docsData.length;
      }
      
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
folderhandleTemplateClick(node){
  var userId=0;
  if(node.usertype=="NA"){
    userId = this.userId
  }
  this.docsData=[];
    this.roleGroupNames=node.folderName;
  this.showLoading = true;
  var model ={
    "operation": "",
    "userID": userId,
    "ID": node.productId,
    "folderID": node.key,
    "type": "product"
  }
  this._facadeService.LimitedAccessDocumentsByID(model).subscribe(
    (data) => {
      this.docsData=data.returnObject;
      this.showLoading = false;
      if(data.returnCode==0){
        this.DocumentsCount=this.docsData.length;
      }
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
test(val,field,dt)
{
 if(field == "code")
    sessionStorage.code = val;
  else if(field == "revision")
    sessionStorage.revision = val;
  else if(field == "title")
    sessionStorage.title = val;
  else if(field=="folderName")
    sessionStorage.folderName = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.docsData != undefined && this.docsData != null)
  {
    var temp = this.docsData;
    if(sessionStorage.code != "")
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != "")
      temp = temp.filter(x=> x.revision.toString().includes(sessionStorage.revision.toString()));   
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if(sessionStorage.folderName != "")
      temp = temp.filter(x=> x.folderName.toLowerCase().includes(sessionStorage.folderName.toLowerCase()));
     this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'lastAccessedDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '' ? null : new Date(value1);
      const date2 = value2 === '' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });
  this.docsData = [...event.data];
}
sortInnerNodes(node){
  if(this.ascendingSort=="Decending"){
    this.ascendingSort="Ascending";
            node.children.sort((a, b) => {
                return b.label.localeCompare(a.label);
            });
  }
  else{
    this.ascendingSort="Decending";
    node.children.sort((a, b) => {
      return a.label.localeCompare(b.label);
  });
  }
}
ViewRoles(node){
  if(node.roleGroups!=null&&node.roleGroups!=""&&node.roleGroups!=undefined){
    this.mulgrps = node.roleGroups.split('|');
    this.rolegroupsdisplay=true;
  }
else{
  this.mulgrps =[];
  this.rolegroupsdisplay=true;
}
}

ViewTasks(data){
  this.TaskGroups=data.code;
  this.TaskGroups1=data.title;
  var tasks=data.transactionName;
  console.log(data.transactionName);
  var tasks1 = tasks.replace('&amp;','&');
  this.taskgrps =tasks1.split(',');
  this.taskgroupsdisplay=true;
}

}

