<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-1">
                    <a routerLink="/workflow/wfprofile"> <button pButton pRipple type="button" icon="pi pi-chevron-left"
                            iconPos="left" label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                            style="width:auto;"></button></a>
                </div>
                <div class="p-field p-col-12 p-md-9" style="text-align:center;">
                    <h4 style="margin-top:5px;">Create Profile</h4>
                </div>
                <div class="p-field p-col-12 p-md-2"></div>
            </div>
            <!-- <h4>Create Profile</h4> -->
            <hr />

            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2"></div>
                <div class="p-field p-col-12 p-md-8">
                    <div class="p-fluid p-formgrid p-grid">

                        <div class="p-field p-col-12 p-md-6">
                            <label for="ProfileName">Profile Name <span style="color: red">*</span></label>
                            <input id="profileName" name="profileName" type="text" [(ngModel)]="profileForm.profileName"
                                pInputText name="Name" />
                            <small class="p-invalid">{{ profileNameError }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label for="Department">Departments<span style="color: red">*</span></label>
                            <p-multiSelect [options]="departments" [(ngModel)]="selectedDepartments"
                                defaultLabel="Select a department" optionLabel="departmentName"></p-multiSelect>
                            <small class="p-invalid">{{ departmentError }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                            <label>Approval Level <span style="color: red">*</span></label>
                            <p-dropdown [options]="level" placeholder="Select a approval level" [(ngModel)]="selectedlevel"></p-dropdown>
                            <!-- <input  type="text" [(ngModel)]="profileForm.displayName"
                                pInputText/> -->
                            <small class="p-invalid">{{ displayNameError }}</small>
                        </div>
                        <!-- <div class="p-field p-col-12 p-md-6">
                        </div> -->
                        <div class="p-field p-col-12 p-md-3"></div>
                        <div class="p-field p-col-12 p-md-3">
                            <button pButton pRipple type="button" icon="pi pi-user" label="Show Roles"
                                class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                                style="float:right; margin-left:10px ; " (click)="getRolesByDepartmentGuids()"
                                style="margin-top:25px;"></button>
                        </div>

                        <div class="p-col-12" style="overflow-x: auto;">
                            <br>

                            <p-table #dt [value]="roles" sortMode="multiple" styleClass="p-datatable-striped"
                                [filterDelay]="0" [globalFilterFields]="['department', 'role', 'noOfApprovals']">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="code" style="width: 100px;">
                                            Select
                                        </th>
                                        <th pSortableColumn="department"  style="width: 150px;">
                                            Department
                                            <p-sortIcon field="department"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="roleName"  style="width: 150px;">
                                            Role
                                            <p-sortIcon field="roleName"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="shortDescription"  style="width: 150px;">
                                            No of Approvals
                                            <p-sortIcon field="shortDescription"></p-sortIcon>
                                        </th>
                                        <th style="width: 100px;">Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-roles let-i="rowIndex">
                                    <tr>
                                        <td style="text-align: center">
                                            <p-checkbox value="roles" [value]="roles" [inputId]="roles"
                                                [(ngModel)]="roles.selected" binary="roles.selected"
                                                (click)="selectedRoles(roles)"></p-checkbox>
                                        </td>
                                        <td style="text-align:center; padding-left:15px; ">{{ roles.departmentName }}
                                        </td>
                                        <td style="text-align:center; padding-left:15px; ">{{ roles.roleName }}</td>
                                        <td style="text-align:center; padding-left:15px; ">
                                            <input id="noOfApprovals" name="noOfApprovals" type="text"
                                                [(ngModel)]="roles.noOfApprovals" pInputText
                                                (keypress)="isNumberKey($event)" [maxLength]=2
                                                (input)="noOfApprClick(roles,$event.target.value)" name="Name" />
                                        </td>
                                        <td style="text-align:center; padding-left:15px;">
                                            <button pButton type="button"
                                                (click)="ViewUsers(roles.roleGuid,roles.departmentName,roles.roleName)"
                                                icon="pi pi-eye" pTooltip="view" tooltipPosition="left"
                                                class="p-button-raised p-button-primary"
                                                style="width:25px; height:25px;text-align:center;"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="5">No data found.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-2"></div>
            </div>
            <div class="p-col-12"></div>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-2"></div>
                <div class="p-field p-col-12 p-md-4"></div>
                <div class="p-field p-col-12 p-md-2"><br>
                    <button pButton pRipple type="button" (click)="createProfile()" icon="pi pi-check" label="Submit"
                        class="p-button-raised p-button-success p-mr-2 p-mb-2" style="float:right;"
                        *ngIf="userFeature"></button>
                    <span style="text-align:center; font-size:18px; color:red;">{{ responseError }}</span>
                </div>
                <div class="p-field p-col-12 p-md-2"><br>
                    <button pButton pRipple type="button" icon="pi pi-refresh" (click)="resetCtrls()" label="Reset"
                        class="p-button-raised p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:10px; "
                        *ngIf="userFeature"></button>
                </div>
                <div class="p-field p-col-12 p-md-2"></div>
                <!-- <div class="p-field p-col-12 p-md-2"><br> <button pButton pRipple type="button" icon="pi pi-trash" (click)="deletewfProfile()" label="Delete" class="p-button-raised p-button-danger p-mr-2 p-mb-2"style="float:right; margin-left:10px ; "></button> </div> -->
            </div>

        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
<p-dialog header="Users" [(visible)]="usersListdisplay" modal="modal" showEffect="fade" [style]="{width:'60%'}">
    <hr>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-xl-12">
            <p-table [value]="rolesUsers" sortMode="multiple" [autoLayout]="true" styleClass="p-datatable-striped"
                [filterDelay]="0" selectionMode="multiple">
                <ng-template pTemplate="header" let-columns class="invoice-items">
                    <tr>
                        <th style="text-align: center; width: 30px;;">Members of {{departmentRoleName}}</th>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex">
                    <tr [pSelectableRow]="rowData">
                        <td style="text-align: center;">{{rowData.fullName}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="1">No data found...</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-dialog>