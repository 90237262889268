import { Component, Inject, OnInit } from "@angular/core";
import { FacadeService, Workflow, Ecn } from "../../facade/facade.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors'; 

@Component({
  selector: "app-createnewdoc",
  templateUrl: "./createnewdoc.component.html",
  styleUrls: ["./createnewdoc.component.scss"],
})
export class CreatenewdocComponent implements OnInit {
  statusMode: string;
  constructor(
    private fb: FormBuilder,
    private _facadeService: FacadeService,
    private datePipe: DatePipe,private route: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {
    this.form = this.fb.group({
      name: [""],
      avatar: null,
    });
  }
  userId = parseInt(sessionStorage.getItem("userId"));
  docId = 0;
  AreYouSureText:string;
  saveAndSendApprovalDisplay:boolean;
  apiResponse: any;
  responseError: any;
  docsDetailsData: any;
  pageTitle: string = 'Create Document';
  form: FormGroup;
  docsTypesData: any[]=[];
  productLineData=[];
  workflowDefinitionId:string = "";
  workFlowData: any[]=[];
  selectedDocType: any;
  disablelinkdocid=false;
  alternatedDocId:any="";
  title: any;
  department: any = "";
  reviewDate: any = "";
  expirationDate: any = "";
  effectiveDate: any = "";
  Remarks: any = "";
  Description: any = "";
  titleError: any;
  departmentError: any;
  docTypeError: any;
  workFlowError: any;
  EfError: any;
  RError: any;
  ExError: any;
  fileError: any = "";
  fileName: any = "";
  departmentsData: any;
  docTypeId: any;
  selectedWorkflow: any;
  sub:any;
  todayDate: Date;
  showLoading:boolean;
  selectedFile:boolean;
  filesize:any;
  minDate:Date;
  selectedProductLine:any;
  filteredproductline:any;
  ECNLevelData=[];
  selectedECN:any;
  // isDisabledREVECO:boolean=false;
  productsData=[];
  selectedProduct:any;
  siteData:any;
  site:any;
  siteError:any;
  productGuidURL:any;
  filteredDept:any;
  filtereddoctype:any;
  filteredproduct:any;
  // IsFromResubmit:boolean;
  IsFromDMSResubmit:boolean;
  DepartmentDisplay:boolean;
  shortDescription:any;
  NotproductRelated=false;
  productError:any="";

  prodroles:boolean = false;
  prodrolesdisplay:boolean = false;
  prcols:any[];
  prodrolesdata:any[];
  teamdisplay: boolean;
  ShowECRN:boolean;
  ecndata:any;
  selectedECRN:any="";
  ECRNDescription:any="";
  ECRNReason:any="";
  ECRNDate=new Date();
  ecrnDateError:any="";
  ECRNDescriptionError="";
  ECRNReasonError="";
  docIdAssignment:any;
  disablePL:boolean = false;
  isFromDocId:boolean;
  public moduleArray: any[] = [
    {
      Dmid: 0,
      DocumentId: 0,
      ModuleId: 1,
      Active: true,
    },
  ];

  public standardsArray: any[] = [];
  ngOnInit(): void {
    this.showLoading = true;
    this.teamdisplay = false;
    this.sub = this.route.queryParams.subscribe(params => { this.docId = +params['docID'] || 0; });
    this.prcols = [
      { field: 'profileDisplayName', header: 'Approval Level'},
      { field: 'profileName', header: 'Profile'},
      { field: 'department', header: 'Department'},
      { field: 'role', header: 'Role'}
    ];
    var productGuidURL = this.route.snapshot.queryParamMap.get('productguid');
    this.statusMode = this.route.snapshot.queryParamMap.get("Mode");
    if(productGuidURL!='' && productGuidURL!=undefined && productGuidURL!=null)
      this.productGuidURL = productGuidURL;
    if(this.statusMode=="ResubmitMode")
      this.IsFromDMSResubmit=true;;

    this.todayDate = new Date();
    this.todayDate.setDate( this.todayDate.getDate() + 365 );
    this.minDate=new Date();

    this.siteData = [ 
      {label: 'Global', value: {id: 1, name: 'Global', code: 'GL'}},
      {label: 'San Jose', value: {id: 2, name: 'San Jose', code: 'SJ'}},
      {label: 'Zhubei', value: {id: 3, name: 'Zhubei', code: 'ZB'}},
      {label: 'Hong Kong', value: {id: 4, name: 'Hong Kong', code: 'HK'}},
      {label: 'Shanghai', value: {id: 5, name: 'Shanghai', code: 'SH'}},
      {label: 'Seoul', value: {id: 6, name: 'Seoul', code: 'SL'}},
      // {name: 'Global', value: 1, code: false},
      // {name: 'San Jose', value: 2, code: true},
      // {name: 'Zhubei', value: 3, code: true},
      // {name: 'Hong Kong', value: 4,  code: true},
      // {name: 'Shanghai', value: 5, code: true},
      // {name: 'Seoul', value: 6,  code: true},
    ];
    this.site=this.siteData[0].value;
    this.ECNLevelData=[
      {name: 'Minor', value: 1,code:true},
      {name: 'Major', value: 2,code:false},
    ]
    this.selectedECN=this.ECNLevelData[1];
  
    if(this.docId>0){
      this.pageTitle = "Edit Document - ";
      this.getDocDetailsById();
    }
    else{
      this.getDocsTypes();
      // if(this.baseUrl["API_ENDPOINTS"]["ISPDMFlagActive"]){
      //   this.getProductsList();
      // }
    }
  }

  saveAndSendApprovalClick(){
    this.saveAndSendApprovalDisplay=true;
    this.AreYouSureText="Do you want to Save and Submit for Approval for this Document?";
    this.showLoading = false;
  }
  
  getDocsTypes() {
    this.shortDescription="";
    this._facadeService.getDocsTypesESI(true,this.docId > 0?this.docTypeId:0).subscribe(
      (data) => {
        // this.showLoading = false;
        if(this.docId > 0){
          var doctype = data.returnObject.find(x => x.documentTypeId === this.docsDetailsData.typeId);
          if(doctype.creationSource=="CMS"){
            if(data.returnObject!=null && data.returnObject.length>0){
              data.returnObject.forEach((value, key) => {
                if(value.creationSource=="CMS" && value.documentTypeCode!="UCD"){
                  this.docsTypesData.push(value);
                }
                });
            }
          }
          else{
            if(data.returnObject!=null && data.returnObject.length>0){
              data.returnObject.forEach((value, key) => {
                if(value.creationSource=="DMS" && value.documentTypeCode!="UCD"){
                  this.docsTypesData.push(value);
                }
                });
            }
          }
          this.selectedDocType =  doctype;
          this.docTypeId = doctype.documentTypeId;
          this.shortDescription = doctype.shortDescription;
          if(this.selectedDocType.productRelated)
            this.NotproductRelated=false;
          else{
            this.NotproductRelated=true;
            this.selectedProduct="";
          }
        }
        else{
          this.pageTitle = "Create Document";
          if(data.returnObject!=null && data.returnObject.length>0){
            data.returnObject.forEach((value, key) => {
              if(value.documentTypeCode!="UCD"){
                this.docsTypesData.push(value);
              }
              });
          }
        } 
        this.getDepartments();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterProduct(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.productsData.length; i++) {
        let product = this.productsData[i];
        if (product.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(product);
        }
    }    
    this.filteredproduct = filtered;
  }

  filterDocType(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.docsTypesData.length; i++) {
        let doctype = this.docsTypesData[i];
        if (doctype.documentType.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(doctype);
        }
    }    
    this.filtereddoctype = filtered;
  }

  filterProductLine(event){
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.productLineData.length; i++) {
        let productline = this.productLineData[i];
        if (productline.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(productline);
        }
    }    
    this.filteredproductline = filtered;
  }

  filterDepartments(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.departmentsData.length; i++) {
        let dept = this.departmentsData[i];
        if (dept.departmentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(dept);
        }
    }    
    this.filteredDept = filtered;
  }

  getDepartments() {
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.departmentsData = data.returnObject;
        if(this.docId > 0){
          var dept = this.departmentsData.find(x => x.departmentId === this.docsDetailsData.departmentId)
          this.department = dept;
        }
        if(!this.NotproductRelated)
          this.getProductsList();
        else
          this.GetProductLines('');
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  SelectPL()
  {
    this.productError = "";
    if(this.selectedProduct != null && this.selectedProduct != undefined)
    {
      this.GetProductLines(this.selectedProduct.productLineID);
      this.disablePL = true;
    }
    else
      this.GetProductLines('');
  }
  getProductsList(){
    this.productsData=[];
    // this.showLoading = true;
    this._facadeService.getProductsList().subscribe( 
      (data) => {
        this.productsData.push({productGuid:"-1","productName":"Generic"});
        data.returnObject.forEach(element => {
          this.productsData.push(element);
        });
        if(this.productGuidURL!="" && this.productGuidURL!=null && this.productGuidURL!=undefined){
          var selProdct = this.productsData.find(x => x.productGuid === this.productGuidURL)
        this.selectedProduct =  selProdct;
        }
        else{
          if(this.docsDetailsData!=null && this.docsDetailsData!="" && this.docsDetailsData!=undefined && 
          this.docsDetailsData.productGuid !== null && this.docsDetailsData.productGuid !=='' && 
          this.docsDetailsData.productGuid!==undefined && this.docsDetailsData.productGuid !== 'null'){       
            var selProdct = this.productsData.find(x => x.productGuid === this.docsDetailsData.productGuid)
            this.selectedProduct =  selProdct;
          }
        }
        this.SelectPL();
        // console.log(this.productsData);
        // this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  GetProductLines(prodLine){
    this.showLoading = true;
    this.productLineData = [];
    this._facadeService.GetProductLines().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          this.productLineData.push(element);
        });
        this.productLineData.push({id:"-1","name":"ALL/Non-Specific"});
        if(this.selectedProduct!=null&&this.selectedProduct!=""&&this.selectedProduct!=undefined&&
        this.selectedProduct.productGuid=="-1"){
          this.selectedProductLine=this.productLineData.find(m=>m.id=="-1");
        }
        else{
          if(prodLine!="" && prodLine!=null && prodLine!=undefined){
            this.selectedProductLine = this.productLineData.find(m=>m.id.toString().toLowerCase() ==prodLine.toString().toLowerCase());
          }
          else if(this.docId > 0)
          {
            if(this.docsDetailsData.productLineId != null && this.docsDetailsData.productLineId != undefined && this.docsDetailsData.productLineId != "")
              this.selectedProductLine = this.productLineData.find(m=>m.id.toString().toLowerCase() ==this.docsDetailsData.productLineId.toString().toLowerCase());
          }
        }
        
        this.getWorkFlowsList('');
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getWorkFlowsList(type) {
    this.showLoading = true;
    this.workFlowError = "";
    this.docTypeError = "";
    this.selectedWorkflow = null;
    if(type=="DT")
    {
      this.disablelinkdocid=false;
      if(this.selectedDocType.creationSource=="CMS")
      {
        this.ShowECRN=true;
        this.ECRNDate=new Date();
      }
      else
      {
        this.ShowECRN=false;
        this.ECRNDescription="";
        this.ECRNReason="";
        this.selectedECRN="";
        this.ECRNDate=null;
        if(this.docId>0)
          this.selectedECN=this.ECNLevelData.find(m=>m.code==this.docsDetailsData.isMinor);
        else
          this.selectedECN=this.ECNLevelData[1];
      }
      if(this.selectedDocType!=null&&this.selectedDocType!=""&&this.selectedDocType!=undefined&&this.selectedDocType.productRelated){
        this.NotproductRelated=false;
        this.selectedProduct="";
        this.selectedProductLine="";
        this.selectedProduct=this.productsData[0];
        this.disablePL = true;
        this.selectedProductLine=this.productLineData.find(m=>m.id=="-1");
      }
      else{
        this.NotproductRelated=true;
        this.disablePL = true;
        this.selectedProduct="";
        this.selectedProductLine=this.productLineData.find(m=>m.id=="-1");
      }
    }
    this.docTypeId=0;
    if(this.selectedDocType!=null&&this.selectedDocType!=""&&this.selectedDocType!=undefined){
      this.docTypeId = this.selectedDocType.documentTypeId;
      this.shortDescription = this.selectedDocType.shortDescription;
    }
  
    let minor=false;
    if(this.selectedECN!=null&&this.selectedECN!=""&&this.selectedECN!=undefined)
      minor = this.selectedECN.value==1?true:false;
    let productlineId="";
    if(this.selectedProductLine!=null&&this.selectedProductLine!=""&&this.selectedProductLine!=undefined)
    {
      if(this.selectedProductLine.id!="-1")
        productlineId =this.selectedProductLine.id;
    }
    var workFlowModel = {
      setupId: 0,
      documentTypeId: this.docTypeId,
      documentType: null,
      workflowDefinitionId: null,
      workflowDefinitionName: null,
      isDeleted: false,
      productLineIds:productlineId,
      isMinor:minor
    };
    this._facadeService.workFlowsList(workFlowModel).subscribe(
      (data) => {
      this.workFlowData = data.returnObject;
        if(this.workFlowData.length>0)
        {
          if(this.workflowDefinitionId != "")
          {
            var workflow = this.workFlowData.find(x => x.workflowDefinitionId === this.workflowDefinitionId)
            if(workflow != null && workflow != undefined)
              this.selectedWorkflow = workflow; 
            else
              this.selectedWorkflow = this.workFlowData[0];
            this.workflowDefinitionId = "";
          }
          else
          {
            if(this.selectedProductLine!=null&&this.selectedProductLine!=""&&this.selectedProductLine!=undefined&&this.selectedProductLine.id=="-1"){
              this.workFlowData=this.workFlowData.filter(m=>m.productLineIds==null);
              this.selectedWorkflow= this.workFlowData[0];
            }
            else{
              this.selectedWorkflow = this.workFlowData[0];
            }
            
          
            this.workflowDefinitionId = "";
          }
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  uploadFile(event) {
    this.fileError ="";
    const file = (event.target as HTMLInputElement).files[0];
    if(file.size > 30000000)
      this.fileError = "File should be less than 30MB.";
    else
    {
      this.fileName = file.name;
      this.selectedFile=true;
      this.filesize = Math.round(file.size / 1024) + " KB";
      this.form.patchValue({
        avatar: file,
      });
      this.form.get("avatar").updateValueAndValidity();
    }
  }

  removeFile() {
    this.fileName ="";
    this.selectedFile=false;
    this.filesize="";
  }
  
  onUpload(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      avatar: file,
    });
    this.form.get("avatar").updateValueAndValidity();
  }

//Get document details by document identifier
getDocDetailsById() {
  this.showLoading = true;
  this.shortDescription="";
  var userFeature = false;
  const userFeatures = sessionStorage.getItem('userFeatures');
  if (userFeatures) {
    var feature = JSON.parse(userFeatures);
    if(feature.length>0){
      var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
      if(featureExist!=null)
        userFeature = true;
    }
  }
  var useId = userFeature?0:parseInt(sessionStorage.getItem('userId'));
  this._facadeService.getDocDetailsById(this.docId,useId).subscribe(
      (data) => {
      this.docsDetailsData = data.returnObject;
      this.pageTitle = this.pageTitle+this.docsDetailsData.code;
      this.workflowDefinitionId = this.docsDetailsData.workflowDefinitionId;
      this.title = this.docsDetailsData.title;
      this.docTypeId =this.docsDetailsData.typeId;
      if(this.docsDetailsData.alternatedDocId !== null && this.docsDetailsData.alternatedDocId !== 'null' && this.docsDetailsData.alternatedDocId !== undefined && this.docsDetailsData.alternatedDocId !== '')
        this.alternatedDocId = this.docsDetailsData.alternatedDocId;
      else
        this.alternatedDocId = "";
      this.selectedECN=this.ECNLevelData.find(m=>m.code==this.docsDetailsData.isMinor);
      if(this.docsDetailsData.site!=null && this.docsDetailsData.site!=''&& this.docsDetailsData.site!=undefined){
        var siteName = this.siteData.find(x => x.label === this.docsDetailsData.site)
        this.site =  siteName.value;
      }
      if(this.docsDetailsData.description !== null && this.docsDetailsData.description !== 'null' && this.docsDetailsData.description !== undefined && this.docsDetailsData.description !== '')
        this.Description = this.docsDetailsData.description;
      else
        this.Description = '';
      if(this.docsDetailsData.remarks !== '' && this.docsDetailsData.remarks !== undefined && this.docsDetailsData.remarks !== 'null' && this.docsDetailsData.remarks !== null)
        this.Remarks = this.docsDetailsData.remarks;
      else
        this.Remarks = '';
      // if(this.docsDetailsData.ecoParentId>0 ||this.docsDetailsData.revisionParentId>0)
      //   this.isDisabledREVECO=true;
      this.docIdAssignment=this.docsDetailsData.code;
      if(this.docsDetailsData.creationSource=="CMS")
      {
        this.ShowECRN=true;
        this.ECRNDate=new Date(this.docsDetailsData.changeDate);
        this.ECRNDescription=this.docsDetailsData.changeDescription;
        this.ECRNReason=this.docsDetailsData.changeReasons;
        this.selectedECRN=this.docsDetailsData.ecrn;
      }
      
      if(this.docsDetailsData.effectiveDate !== null && this.docsDetailsData.effectiveDate !== "" && this.docsDetailsData.effectiveDate !== undefined)
        this.effectiveDate = new Date(this.docsDetailsData.effectiveDate);
      else
        this.effectiveDate = null;

      if(this.docsDetailsData.reviewDate !== null && this.docsDetailsData.reviewDate !== 'null' && this.docsDetailsData.reviewDate !== undefined && this.docsDetailsData.reviewDate !== '')
        this.reviewDate = new Date(this.docsDetailsData.reviewDate);
      else
        this.reviewDate = null;

      if(!this.IsFromDMSResubmit)//!this.IsFromResubmit && 
      {
        if(this.docsDetailsData.userFileName!='' && this.docsDetailsData.userFileName!=undefined){
          this.selectedFile=true;
          this.fileName = this.docsDetailsData.userFileName;
        }
      }
      var CMDocId = this.route.snapshot.queryParamMap.get('docId');
      if((CMDocId!=null && CMDocId!="" && CMDocId!=undefined)){
        this.pageTitle = "Resubmit Document - "+this.docsDetailsData.code;
        this.statusMode="EditMode";
      }
      if(this.statusMode=="ResubmitMode")
        this.pageTitle = "Resubmit Document - "+this.docsDetailsData.code;

      this.getDocsTypes();
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
BackClick(){
    location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs';
} 

EffectiveDateChange(){
  this.EfError = "";
    this.RError = "";
    this.ExError = "";
   if(this.reviewDate != undefined && this.reviewDate != "" && 
   new Date(this.effectiveDate)>new Date(this.reviewDate)){
    this.EfError = "effective date should not be greater than review date.";
    return;
    }
  else if(this.expirationDate != undefined && this.expirationDate != "" && 
  new Date(this.effectiveDate)>new Date(this.expirationDate)){
   this.EfError = "effective date should not be greater than Expiration date.";
   return;
   }
}

ReviewDateChange(){
  this.EfError = "";
    this.RError = "";
    this.ExError = "";
  if(this.effectiveDate != undefined && this.effectiveDate != "" && 
 new Date(this.reviewDate)<new Date(this.effectiveDate)){
    this.RError = "Review date should not be less than effective date";
    return;
    }
    else if(this.expirationDate != undefined && this.expirationDate != ""
     && new Date(this.reviewDate)>new Date(this.expirationDate)){
      this.RError = "Review date should not be greater than expiration date.";
      return;
      }
}
ExpireDateChange(){
  this.EfError = "";
    this.RError = "";
    this.ExError = "";
   if(this.effectiveDate != undefined && this.effectiveDate != "" && 
   new Date(this.expirationDate)<new Date(this.effectiveDate)){
    this.ExError = "Expiration date should not be less than effective date.";
    return;
    }
    else if(this.reviewDate != undefined && this.reviewDate != "" &&new Date(this.expirationDate)<new Date(this.reviewDate)){
    this.ExError = "expiration date should not be less than review date.";
    return;
    }
}
  createDoc(action,from) {
    this.showLoading = true;
    this.isFromDocId=from;
    this.prodroles = false;
    this.titleError = "";
    this.departmentError = "";
    this.docTypeError = "";
    this.workFlowError = "";
    this.EfError = "";
    this.RError = "";
    this.ExError = "";
    this.siteError="";
    this.productError="";
    this.ecrnDateError="";
    this.ECRNDescriptionError="";
    this.ECRNReasonError="";
    if (this.title == undefined || this.title == "") {
      this.titleError = "Please enter document title.";this.showLoading = false;
    } else if (this.department == undefined || this.department == "") {
      this.departmentError = "Please select department.";this.showLoading = false;
    } else if (
      this.selectedDocType == undefined ||
      this.selectedDocType == ""
    ) {
      this.docTypeError = "Please select document type.";this.showLoading = false;
    } else if ( 
      this.selectedWorkflow == undefined || this.selectedWorkflow == "" || this.selectedWorkflow == null
    ) {
      this.workFlowError = "Workflow not defined. Please contact administrator.";this.showLoading = false;
    }
    else if(!this.NotproductRelated && (this.selectedProduct==""||this.selectedProduct==null||this.selectedProduct==undefined)){
      this.productError="Please select product";this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS"&&(this.ECRNDate==null||this.ECRNDate==undefined)) {
      this.ecrnDateError="Please select Date";this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS"&&(this.ECRNDescription==null||this.ECRNDescription==""||this.ECRNDescription==undefined)) {
      this.ECRNDescriptionError="Please enter description of change";this.showLoading = false;
    }
    else if (this.selectedDocType.creationSource == "CMS"&&(this.ECRNReason==null||this.ECRNReason==""||this.ECRNReason==undefined)) {
      this.ECRNReasonError="Please enter reasons for change";this.showLoading = false;
    }
    else if (
      this.site == undefined ||
      this.site == ""
    ) {
      this.siteError = "Please select site.";this.showLoading = false;
    }
    else if (action=="sendforapproval" && this.fileName == "") {
      this.fileError = "Please upload file.";this.showLoading = false;
    }
    else if(action === "sendforapproval"){
      this.showLoading = true;
      if(!this.NotproductRelated){
        var model = {
          wfName: "",
          definitionId: this.selectedWorkflow.workflowDefinitionId,
          productGuid: this.selectedProduct.productGuid
        };
        this._facadeService.GetWorkflowApprovers(model).subscribe(
          (data) => {
            if(data.returnObject.length>0){
              this.prodrolesdata = data.returnObject.filter(x=>x.productRelated == true && x.approversCount == 0);
              if(this.prodrolesdata != undefined && this.prodrolesdata != null)
              {
                if(this.prodrolesdata.length > 0)
                {
                  this.prodroles = true;
                  this.productError = "Product Team has missing approver(s).";
                  this.showLoading = false;
                }
                else
                  this.saveAndSendApprovalClick();
              }
              else
                this.saveAndSendApprovalClick();
            }
            else
                this.saveAndSendApprovalClick();
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
        this.saveAndSendApprovalClick();
    }
    else {
      this.showLoading = true;
      if(!this.NotproductRelated){
        var model = {
          wfName: "",
          definitionId: this.selectedWorkflow.workflowDefinitionId,
          productGuid: this.selectedProduct.productGuid
        };
        this._facadeService.GetWorkflowApprovers(model).subscribe(
          (data) => {
            if(data.returnObject.length>0){
              this.prodrolesdata = data.returnObject.filter(x=>x.productRelated == true && x.approversCount == 0);
              if(this.prodrolesdata != undefined && this.prodrolesdata != null)
              {
                if(this.prodrolesdata.length > 0)
                {
                  this.prodroles = true;
                  this.productError = "Product Team has missing approver(s).";
                  this.showLoading = false;
                }
                else
                {
                  if(this.docId <= 0 && this.ShowECRN)
                    this.GetNewECN(action);
                  else
                    this.Save(action);
                }
              }
              else
              {
                if(this.docId <= 0 && this.ShowECRN)
                  this.GetNewECN(action);
                else
                  this.Save(action);
              }
            }
            else
            {
              if(this.docId <= 0 && this.ShowECRN)
                this.GetNewECN(action);
              else
                this.Save(action);
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
      {
        if(this.docId <= 0 && this.ShowECRN)
          this.GetNewECN(action);
        else
          this.Save(action);
      }
    }
  }

  private Save(action)
  {
    this.responseError = "";
    if(this.docTypeId == null || this.docTypeId == undefined || this.docTypeId == 0)
    {
      this.responseError = "Please select document type";
    }
    else
    {
      this.showLoading = true;
    
      var formData: any = new FormData();
      var modleString = JSON.stringify(this.moduleArray);
      var standardString = JSON.stringify(this.standardsArray);
      formData.append("NewFileStream", this.form.get("avatar").value);
      if(this.docId > 0){
        var CMDocId = this.route.snapshot.queryParamMap.get('docId');
        if(CMDocId!=null && CMDocId!="" && CMDocId!=undefined&&CMDocId!="null"){
          formData.append("Id", 0);
          formData.append("Code", this.docsDetailsData.code);
          this.docId =0;
        }
        else{
          if(this.IsFromDMSResubmit){
            formData.append("Id", 0);
            formData.append("Code", this.docsDetailsData.code);
            this.docId =0;
          }
          else{
            formData.append("Id", this.docId);
            formData.append("Code", this.docsDetailsData.code);
          }
        }
      }
      else{
        formData.append("Id", 0);
        formData.append("Code", "");
      }
      
      formData.append("PlantId", 1);
      formData.append("DepartmentId", this.department.departmentId);
      formData.append("Title", this.title);
      formData.append('Description', this.Description === "null" ? "" : this.Description);
      if(this.selectedProduct!=null&&this.selectedProduct!=""&&this.selectedProduct!=undefined){
        if (this.selectedProduct.productGuid != null && this.selectedProduct.productGuid != undefined&&this.selectedProduct.productGuid!="") {
          if(this.selectedProduct.productGuid=="-1"){
            formData.append("ProductGuid", "");
          }
          else{
            formData.append("ProductGuid", this.selectedProduct.productGuid);
          }
          
        }
        else{
          formData.append("ProductGuid", "");
        }
      }
      else{
        formData.append("ProductGuid", "");
      }
      formData.append("Site", this.site.name);
      formData.append("SiteCode", this.site.code);

      formData.append("TypeId", this.docTypeId);
      if(this.alternatedDocId!=null&&this.alternatedDocId!=""&&this.alternatedDocId!=undefined&&this.alternatedDocId != "null"){
        formData.append('AlternatedDocId', this.alternatedDocId);
      }
      else{
        formData.append('AlternatedDocId', "");
      }
      
      formData.append('ChangeDescription', this.ECRNDescription);
      formData.append('ChangeReasons', this.ECRNReason);
      formData.append('Ecrn', this.selectedECRN);
      if(this.ECRNDate!=null&&this.ECRNDate!=undefined){
        formData.append('ChangeDate', this.datePipe.transform(this.ECRNDate, 'MM/dd/yyyy'));
      }

      if(this.effectiveDate !== '' && this.effectiveDate !== null){
      formData.append(
        "EffectiveDate",
        this.datePipe.transform(this.effectiveDate, "MM/dd/yyyy")
      );}
      if(this.expirationDate !== '' && this.expirationDate !== null){
        
      formData.append(
        "ExpirationDate",
        this.datePipe.transform(this.expirationDate, "MM/dd/yyyy")
      );}
      formData.append("Status", "New");
      formData.append('Remarks', this.Remarks === "null" ? "" : this.Remarks);

      formData.append("Revision", 1);
      formData.append("RevisionParentId", 0);
      if(this.reviewDate !== '' && this.reviewDate !== null){
        formData.append(
        "ReviewDate",
        this.datePipe.transform(this.reviewDate, "MM/dd/yyyy")
      );}
      formData.append("IsArchived", "N");

      formData.append("EcoParentId", 0);
      formData.append("HasWorkflow", true);
      formData.append(
        "WorkflowDefinitionId",
        this.selectedWorkflow.workflowDefinitionId
      );
      formData.append("CreatedBy", this.userId);
      let minor=false;
      if(this.selectedECN!=null&&this.selectedECN!=""&&this.selectedECN!=undefined)
        minor = this.selectedECN.value==1?true:false;
      if(this.selectedProductLine!=null&&this.selectedProductLine!=""&&this.selectedProductLine!=undefined)
      {
        if(this.selectedProductLine.id!="-1")
          formData.append("ProductLineId", this.selectedProductLine.id);
      }
      else
        formData.append("ProductLineId", "");
      formData.append("IsMinor", minor);
      formData.append("StandardClausesJson", standardString);
      formData.append("ModulesJson", modleString);
      if(action=='sendforapproval' || action == 'confirm'){
        if(this.docId > 0){
          if(this.docsDetailsData.ecoParentId>0){
            formData.append("Operation", "eco-sendforapproval");
          }
          else if(this.docsDetailsData.revisionParentId>0){
            formData.append("Operation", "rev-sendforapproval");
          }
          else{
            formData.append("Operation", "sendforapproval");
          }
        }
        else{
          formData.append("Operation", "sendforapproval");
        }
      }
      else{
        if(this.docId > 0){
          if(this.docsDetailsData.ecoParentId>0){
            formData.append("Operation", "eco");
          }
          else if(this.docsDetailsData.revisionParentId>0){
            formData.append("Operation", "rev");
          }
          formData.append("Operation", "");
        }
        else{
          formData.append("Operation", "");
        }
      }
      
      formData.forEach((value, key) => {
        console.log(key + " " + value);
      });
      console.log("Document data : " + JSON.stringify(formData));
      
      if(this.docId > 0){
        this.showLoading = true;
        this._facadeService.UpdateDocument(formData)
        .subscribe(
          (data) => { 
            console.log(data);
            this.apiResponse = data;
            if(this.apiResponse.returnCode >= 0){    
              this.saveAndSendApprovalDisplay=false;          
              var TileStatus= (action=="sendforapproval"||action=="confirm")?"In-Process":"New";
                location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs?TileStatus='+TileStatus;
            }
            else{
              this.showLoading = false;
              this.responseError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else {
        if(this.isFromDocId){
          this.showLoading = true;
          // this._facadeService.GetNewCode(this.selectedDocType.documentTypeId,0,"new").subscribe(
          //   (data) => {
          //     this.docIdAssignment=data.returnObject;
              
              // formData.append("Code", this.docIdAssignment);
              // this.showLoading = true;
              this._facadeService.CreateDocument(formData)
              .subscribe(
                (data) => {
                  this.apiResponse = data;
                  if(this.apiResponse.returnCode >= 0){
                    this.saveAndSendApprovalDisplay=false;    
                    this.disablelinkdocid=true;
                      this.docId=this.apiResponse.returnObject.id;
                      this.statusMode = 'EditMode';
                      this.pageTitle = "Create Document - ";
                      this.getDocDetailsById();
                  }
                  else{

                    this.showLoading = false;
                    this.responseError = this.apiResponse.returnMessage;
                  }
                },
                (error) => {
                  this.showLoading = false;
                  console.log(error);
                }
              );
          //   },
          //   (error) => {
          //     this.showLoading = false;
          //     console.log(error);
          //   }
          // );
        }
        else{
          this.showLoading = true;
          var CMDocId = this.route.snapshot.queryParamMap.get('docId');
          if((CMDocId!=null &&CMDocId!="null"
          && CMDocId!="" && CMDocId!=undefined) || (this.statusMode=="ResubmitMode")){
            this._facadeService.ResubmitDocument(formData)
            .subscribe(
              (data) => {
                console.log(data);
                this.apiResponse = data;
                if(this.apiResponse.returnCode >= 0){  
                  this.saveAndSendApprovalDisplay=false;     
                    var TileStatus= (action=="sendforapproval"||action=="confirm")?"In-Process":"New";
                    location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs?TileStatus='+TileStatus;
                }
                else{
                  this.responseError = this.apiResponse.returnMessage;
                  this.showLoading = false;
                }
              },
              (error) => {
                this.showLoading = false;
                console.log(error);
              }
            );
            }
            else{
              this._facadeService.CreateDocument(formData)
              .subscribe(
                (data) => {
                  this.apiResponse = data;
                  if(this.apiResponse.returnCode >= 0){
                    this.saveAndSendApprovalDisplay=false;   
                      var TileStatus= (action=="sendforapproval"||action=="confirm")?"In-Process":"New";
                      location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs?TileStatus='+TileStatus;
                  }
                  else{
                    this.showLoading = false;
                    this.responseError = this.apiResponse.returnMessage;
                  }
                },
                (error) => {
                  this.showLoading = false;
                  console.log(error);
                }
              );
            }
        }
      }
    }
  }

  SelectDepartment(){
    var departments = sessionStorage.getItem("userdepts");
    if(departments!=null && departments!=""&&departments!=undefined){
      let departmentslist = JSON.parse(departments);
      var department = departmentslist.find(m=>m.id==this.department.departmentGuid);
      if(department==null){
          this.DepartmentDisplay=true;
      }
    }
  }
  DepartmentNOClick(){
    this.DepartmentDisplay=false;
    this.department="";
  }
  GetNewCode(){
    this.createDoc('',true);
  }
  GetNewECN(action){
    this.showLoading = true;
    this._facadeService.GetNewECN().subscribe(
      (data) => {
        this.selectedECRN=data.returnObject;
        this.Save(action);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
