<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card" style="min-height: 600px">
            <!-- <div class="card-header" style=" border-radius: 5px;height:40px; ">
                <h5 style="color:#495057; padding:15px 0 0 15px;"></h5>
                <span class="p-input-icon-left" style="float:right; margin-bottom:10px; "> -->
                    <div class="p-grid p-fluid p-formgrid">
                        <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
                        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="float:right; margin-bottom:10px;">
                    <a routerLink="/createstandard">
                        <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add Standard"
                            class="p-button-raised p-button-primary " style="width:100%;float:right;"></button>
                    </a>
                        </div>
                    <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="float:right; margin-bottom:10px;">
                    <a routerLink="/createclause">
                        <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add Clause"
                            class="p-button-raised p-button-primary" style="width:100%; float:right;"></button>
                    </a>
                </div>
            </div>
                <!-- </span>
            </div> -->

            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;  height:auto;">
                <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Standard Clauses</h5>
                <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
                    <!-- <a routerLink="/createstandard"><button pButton type="button"  pTooltip="Add Standard"tooltipPosition="top" 
                        class="p-button-rounded p-button-primary p-mr-2 p-mb-2" icon="pi pi-plus" iconPos="left"
                        style="margin-right:10px ; width:25px; height:25px; "></button></a>
                        <a routerLink="/createclause"><button pButton type="button" class="p-button-info"   class="p-button-rounded p-button-primary p-mr-2 p-mb-2"
                            icon="pi pi-plus" pTooltip="Add Clause"tooltipPosition="top"   style="margin-right:10px ; width:25px; height:25px; "></button></a> -->
                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                        (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                        style="float:right;width:25px; height:25px;"></button>
                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                        tooltipPosition="top" (click)="exportExcel()"
                        class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; width:25px; height:25px; "></button>
                </span>
            </div>
            <div class="invoice invoice-header" id="invoice-content"  style="overflow-x: auto;">
                <p-table #dt [value]="liststandard" id="excel-table" [columns]="cols" sortMode="multiple"
                    class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
                    dataKey="id"
                    [globalFilterFields]="['serialNo','standardName','shortDescription','description','clauseGroup']">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                            [ngClass]="{'serialNo': i == 0,'standardName': i == 1,'shortDescription': i == 2,
                            'description': i == 3,'clauseGroup': i == 4}">
                                {{col.header}}
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                        </tr>
                        <tr id="trSearch">
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                    (input)="dt.filter($event.target.value, col.field, 'contains')"
                                    style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;">
                                    {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5" style="text-align: left;">No documents found.</td>
                        </tr>
                        </ng-template>
                </p-table>
            </div>
        </div>
        <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
            *ngIf="showLoading">
            <i class="pi pi-spin pi-spinner"
                style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
        </div>