import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(
    private _httputilityService: HttputilityService) {}

  getDocTypes(){
    return this._httputilityService.Get('GetDocumentTypes');
  }

  getDepartMents(){
    return this._httputilityService.GetU('user/GetDepartments'); 
  }

  getProjects(userid){
    return this._httputilityService.GetPM('Product/GetProductsbyUser?UserID='+userid); 
  }

  getAllActiveProducts(){
    return this._httputilityService.GetPM('Product/GetAllActiveProducts'); 
  }

  getRolesByDepartmentGuid(departmentGuid){
    return this._httputilityService.GetU(`user/GetRolesByDepartment?departmentGuid=${departmentGuid}`);
  }
  
  getRolesByDepartmentGuids(departmentGuids){
    return this._httputilityService.PostU("user/GetRolesByDepartments", departmentGuids);
  }

  GetUsersByRoleGuid(RoleGUID){
    return this._httputilityService.GetU(`user/GetUsersByRole?RoleGUID=${RoleGUID}`);
  }

  getUsers(){
    return this._httputilityService.GetU('user/GetUsers');
  }

  GetHelpByID(HelpID){
    return this._httputilityService.GetU("GetHelpByGuid?helpGuid=" + HelpID);
  }  
  GetQuestions()
  {
    return this._httputilityService.GetU("GetQuestions?active=true");
  }
  AddFeedback(model)
  {
    return this._httputilityService.PostU("AddFeedback",model);
  }
  SearchHelp(text,AppID){
    return this._httputilityService.GetU("SearchHelp?input=" + text+"&applicationguid=" + AppID);
  }  

}
