<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card" style="min-height: 560px">
      <!-- <div class="card-header" style=" border-radius: 5px;height:40px; ">

        <h5 style="color:#495057; padding:15px 0 0 15px;">
        </h5>
        <span class="p-input-icon-left" style="float:right; margin-bottom:10px;"> -->
          <div class="p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="float:right; margin-bottom:10px;">
          <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add New"
            (click)="AddNewButton()" class="p-button-raised p-button-primary " style="float:right;width:auto;"
            [disabled]="userFeature"></button>
          </div>
          </div>
        <!-- </span>
      </div> -->
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">

        <h5 style="color:#495057; padding:15px 0 0 15px;">Document Types - {{DocumentsCount}}</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">

          <!-- <button pButton pRipple  type="button" label="Add New" icon="pi pi-plus" (click)="AddNewButton()" class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="height:25px;"></button> -->
          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
            (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
            (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px; "></button>
          <span class="p-input-icon-left" style="float: right">
            <p-dialog header="Add Document Type" [(visible)]="addDisplay" modal="modal" showEffect="fade"
              [style]="{ width: '60%' }">
              <hr />
              <div class="p-col-12">
                <div class="p-grid p-fluid">
                  <div class="p-col-12 p-md-6">
                    <label for="Type">Document Type<span style="color: red">*</span><span
                        *ngIf="IstemplateCheckbox">(Ex:INV-0001)</span></label>
                    <input id="Type" type="text" pInputText [(ngModel)]="type" placeholder="Enter Type" maxlength="8"
                      (change)="DocTypeChange()" />
                    <small class="p-invalid">{{ typeError }}</small>
                  </div>
                  <div class="p-col-12 p-md-6" *ngIf="ShowDocCode">
                    <label for="Code">Document Prefix<span style="color: red">*</span></label>
                    <input id="Code" type="text" pInputText [(ngModel)]="code" placeholder="Enter Code"
                      (change)="DocCodeClick(code)" maxlength="3" (keypress)="allowAlphaNumericSpace($event)" />
                    <small class="p-invalid">{{ codeError }}</small>
                  </div>
                </div>
                <div class="p-grid p-fluid">
                  <div class="p-col-12 p-md-12" style="text-align: left">
                    <label for="ShortDesc">Short Description</label>
                    <input id="ShortDescription" type="text" pInputText [(ngModel)]="shortDescription"
                      placeholder="Enter Short Description" maxlength="150" />
                    <small class="p-invalid">{{ typeError }}</small>
                  </div>
                </div>
                <div class="p-grid p-fluid">
                  <div class="p-col-12 p-md-12" style="text-align: left">
                    <label for="Desc">Description</label>
                    <textarea style="opacity: 0.9;" [(ngModel)]="description" id="Description" type="text" rows="3"
                      [maxLength]="1000" placeholder="Enter Description" pInputTextarea></textarea>
                    <small class="p-invalid">{{ codeError }}</small>
                  </div>
                </div>
                <div class="p-grid p-fluid">
                  <div class="p-col-12 p-md-3" style="text-align: left; margin-top:25px;">
                    <p-checkbox (click)="ECNRequired($event)" [(ngModel)]="selectedModule" binary="selectedModule"
                      id="ecnReqiredcheckbox"></p-checkbox>
                    <label style="padding-left:10px;">ECN Required</label>
                  </div>
                  <div class="p-col-12 p-md-4" style="text-align: left; margin-top:25px;">
                    <p-checkbox name="group1" (click)="IsTemplateChange($event)" [(ngModel)]="IstemplateCheckbox"
                      binary="IstemplateCheckbox" id="la"></p-checkbox>
                    <label style="padding-left:10px;">Do Not Allow Multiple Doc Creation*</label>
                    <br />
                    <span style="padding-left: 20px;" *ngIf="IstemplateCheckbox">(*No New Docs for this Doc Type)</span>
                  </div>
                  <div class="p-col-12 p-md-3" style="text-align: left; margin-top:25px;">
                    <p-checkbox [(ngModel)]="productRelated"
                      binary="productRelated"></p-checkbox>
                    <label style="padding-left:10px;">Product Related</label>
                  </div>
                  <div class="p-col-12 p-md-2" style="text-align: left; margin-top:25px;">
                    <p-checkbox name="group1" disabled=true [(ngModel)]="NewCheckbox" binary="NewCheckbox" id="la">
                    </p-checkbox>
                    <label style="padding-left:10px;">Active</label>
                  </div>
                </div>
                <hr>
                <div class="p-grid p-fluid">
                  <div class="p-col-12 p-md-8"></div>
                  <div class="p-col-12 p-md-2">
                    <button pButton type="button" label="Save" (click)="addType()" icon="pi pi-check" iconPos="left"
                      class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
                  </div>
                  <div class="p-col-12 p-md-2">
                    <button pButton type="button" label="Reset" (click)="hideEditDialog()" icon="pi pi-refresh"
                      iconPos="left" class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
                  </div>
                </div>
                <span style="color: red">{{txterror}}</span>
              </div>
            </p-dialog>
          </span>
        </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content">

        <p-table #dt [value]="docTypes" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align" [scrollable]="true" scrollHeight="380px"
          styleClass="p-datatable-striped" [virtualScroll]="true" [filterDelay]="0" selectionMode="multiple" dataKey="id" 
          [globalFilterFields]="['documentTypeCode', 'documentTypeCodePrefix', 'singleInstanceName','creationSourceName','productRelatedName','active']">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
              [ngClass]="{'width100': i == 0 || i == 1,'w12': i == 4 || i == 6,'w10': i == 5 ,'w6':i == 7,
              'w30': i == 2 || i == 3}">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="text-align: center" style="width:80px;">Actions</th>
            </tr>
            <tr id="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" 
                (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0; width:95%; height:25px;" />
              </th>
              <th style="text-align: center"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left;width:100px;padding-left: 15px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: left;width:100px;padding-left: 15px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 2" style="text-align: left;width:30%;padding-left: 15px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 3" style="text-align: left;width:30%;padding-left: 15px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 4" style="text-align: center;width:12%;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 5" style="text-align: center;width: 10%;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 6" style="text-align: center;width: 12%;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 7" style="text-align: center;width: 6%;">
                  {{ rowData[col.field] }}
                </td>
              </ng-container>
              <td style="text-align: center;width: 80px;">
                <button pButton type="button" (click)="openModel(rowData)" icon="pi pi-pencil" pTooltip="Edit"
                  tooltipPosition="left" class="p-button-raised p-button-primary " style="text-align: center;width:25px; height:25px;"
                  [disabled]="rowData.isEditDisabled||(userFeature)||(rowData.documentType==='UCD')"></button>
                <!-- <button
                  pButton
                  type="button"
                
               
                  icon="pi pi-trash" pTooltip="Delete"tooltipPosition="right"
                  (click)="deleteDisplayClick(rowData)"
                  class="p-button-raised p-button-danger"
                  style="margin-left:15px; height:25px; width:25px;"
                ></button> -->
              </td>
            </tr>
            <!-- <span>
              <p-dialog header="Delete Document Type" [(visible)]="deleteDisplay" modal="modal" showEffect="fade"
                [style]="{ width: '80%' }">
                <hr />
                <div class="p-grid">

                  <div class="p-col-12 p-md-9" style="text-align:center;">
                    <h4 style="color:red">{{AreYouSureText}}</h4>
                  </div>
                  <div class="p-col-12 p-md-3">

                    <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">

                      <button pButton type="button" label="Delete" icon="pi pi-trash" iconPos="left"
                        (click)="deleteDoc(rowData)" class="p-button-raised p-button-danger"
                        style="margin-top:-5px;margin-bottom:-5px;"></button>
                      <button pButton type="button" label="Close" icon="pi pi-times" iconPos="left"
                        (click)="deleteDisplay = false" class="p-button-raised p-button-secondary"
                        style="margin-top:-5px;margin-bottom:-5px; margin-left:10px;">
                      </button>
                    </span>
                  </div>
                </div>
              </p-dialog>
            </span> -->
           
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="9">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 5000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>

  <p-dialog header="Edit Document Type" [(visible)]="editDisplay" modal="modal" showEffect="fade"
    [style]="{ width: '60%' }">
    <hr />
    <div class="p-col-12">
      <div class="p-grid p-fluid">


        <div class="p-field p-col-12 p-md-6" style="text-align: left">
          <label for="Type">Document Type<span style="color: red">*</span><span
              *ngIf="IstemplateCheckbox">(Ex:INV-0001)</span></label>
          <input id="Type" type="text" pInputText [(ngModel)]="type" placeholder="Enter Type" maxlength="8"
            (change)="DocTypeChange()" />
          <small class="p-invalid">{{ typeError }}</small>
        </div>
        <div class="p-field p-col-12 p-md-6" style="text-align: left" *ngIf="ShowDocCode">
          <label for="Code">Document Prefix<span style="color: red">*</span></label>
          <input id="Code" type="text" pInputText [(ngModel)]="code" placeholder="Enter Code"
            (change)="DocCodeClick(code)" maxlength="3" (keypress)="allowAlphaNumericSpace(event)" />
          <small class="p-invalid">{{ codeError }}</small>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-12 p-md-12" style="text-align: left">
          <label for="ShortDesc">Short Description</label>
          <input id="ShortDescription" type="text" pInputText [(ngModel)]="shortDescription"
            placeholder="Enter Short Description" maxlength="150" />
          <small class="p-invalid">{{ typeError }}</small>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-field p-col-12 p-md-12" style="text-align: left">
          <label for="Desc">Description</label>
          <textarea style="opacity: 0.9;" [(ngModel)]="description" id="Description" type="text" rows="3"
            [maxLength]="1000" placeholder="Enter Description" pInputTextarea></textarea>
          <small class="p-invalid">{{ codeError }}</small>
        </div>
      </div>
      <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-3" style="text-align: left; margin-top:25px;">
          <p-checkbox (click)="ECNRequired($event)" [(ngModel)]="selectedModule" binary="selectedModule"
            id="ecnReqiredcheckbox"></p-checkbox>
          <label style="padding-left:10px;">ECN Required</label>
        </div>
        <div class="p-col-12 p-md-4" style="text-align: left; margin-top:25px;">
          <p-checkbox name="group2" (click)="IsTemplateChange($event)" [(ngModel)]="IstemplateCheckbox"
            binary="IstemplateCheckbox" id="templatecheckbox"></p-checkbox>
          <label style="padding-left:10px;">Do Not Allow Multiple Doc Creation*</label>
          <br />
          <span style="padding-left: 20px;" *ngIf="IstemplateCheckbox">(*No New Docs for this Doc
            Type)</span>
        </div>
        <div class="p-col-12 p-md-3" style="text-align: left; margin-top:25px;">
          <p-checkbox [(ngModel)]="productRelated" binary="productRelated">
          </p-checkbox>
          <label style="padding-left:10px;">Product Related</label>
        </div>
        <div class="p-col-12 p-md-2" style="text-align: left; margin-top:25px;">
          <p-checkbox name="group1" [(ngModel)]="ActiveCheckbox" binary="ActiveCheckbox" id="la">
          </p-checkbox>
          <label style="padding-left:10px;">Active</label>
        </div>
      </div>

      <hr>
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12 p-md-8"></div>
        <div class="p-field p-col-12 p-md-2">
          <button pButton type="button" label="Update" (click)="editDocType()" icon="pi pi-check"
            iconPos="left" class="p-button-raised p-button-success p-mr-2 p-mb-2"></button>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <button pButton type="button" label="Reset" (click)="hideEditDialog()" icon="pi pi-refresh"
            iconPos="left" class="p-button-raised p-button-secondary p-mr-2 p-mb-2"></button>
        </div>


      </div>
      <span style="color: red">{{txterror}}</span>
    </div>
  </p-dialog>