<div class="p-fluid p-formgrid p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <!-- <div class="card-header" style="border-radius: 5px; height:40px;">

        <h5 style="color:#495057; padding:15px 0 0 15px;">
        </h5>
        <span class="p-input-icon-left" style="float:right; margin-bottom:10px; "> -->
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-9"></div>
            <div class="p-field p-col-12 p-md-3">
          <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left"
            label="Create Document Approval Profile" class="p-button-raised p-button-primary p-mr-2 p-mb-2"
            (click)="createProfile()" [disabled]="userFeature" 
            style="float: right; width: auto;"></button>
          </div>
        <!-- </span> -->
      </div>
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:auto;">
        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Document Approval Profiles - {{DocumentsCount}}</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
            (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right;height:25px; width:25px;margin:5px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
            (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right;height:25px; width:25px;margin:5px; "></button>
        </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content">
        <p-table #dt [value]="wfProfileData" id="excel-table" [columns]="cols" [virtualScroll]="true" [scrollable]="true" scrollHeight="380px" styleClass="p-datatable-striped"
          dataKey="id" [rowHover]="true" [rows]="10" [filterDelay]="0" [globalFilterFields]="['name']">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
              [ngClass]="{'name': i==0}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
            <tr ID="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;width: 60px;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                (input)="test($event.target.value,col.field,dt)"
                  style="margin: -10px 0 -10px 0;width: 80%; height:25px;">
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0 && rowData.name!='Supervisor-Approval-PF'"
                style="text-align: left;padding-left:3%;">
                  {{ rowData[col.field] }}
                </td>
                <!-- <td *ngIf="i == 1&& rowData.name!='Supervisor-Approval-PF'" style="text-align: left; padding-left:20px;">
							{{rowData[col.field]}}
						</td> -->
                <td *ngIf="i == 1&& rowData.name!='Supervisor-Approval-PF'"  style="text-align: left;padding-left:11%;">
                  <button pButton type="button" (click)="viewwfDetails(rowData)" icon="pi pi-eye" pTooltip="view"
                    tooltipPosition="left" class="p-button-raised p-button-primary"
                    style="width:25px; height:25px;text-align:center;" [disabled]="userFeature"></button>
                  <!-- <td *ngIf="i == 3" style="text-align: left;">
							{{rowData[col.field]}}
            </td>
            <td *ngIf="i == 4" style="text-align: center;">
              {{rowData[col.field]}}
             
            </td> -->

              </ng-container>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="2">No data found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>