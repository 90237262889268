<div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;">         
    <h4 style="color:#495057; padding:10px 0 0 15px;width: 90%;">{{headerName}}</h4> 
    <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">  
</span>
</div>  
<div style="margin-bottom: 1rem">
    <button pButton type="button" label="Expand all" (click)="expandAll()" style="margin-right: .5rem"></button>
    <button pButton type="button" label="Collapse all" (click)="collapseAll()"></button>
    <button *ngIf="source=='A'" pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add New DCC Folder"
              (click)="AddNewButton('d')" class="p-button-raised p-button-primary" style="float:right;width:auto;"
              ></button>&nbsp;&nbsp;&nbsp;&nbsp;
              <button *ngIf="source=='A'" pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add New Product Folder"
              (click)="AddNewButton('p')" class="p-button-raised p-button-primary" style="float:right;width:auto; margin-right: .5rem"
              ></button>
</div>
<p-tree [value]="files"> 
    <ng-template let-node pTemplate="urlplus" *ngIf="source=='A'">{{node.label}} &nbsp;&nbsp;&nbsp;&nbsp;<b *ngIf="node.roleGroups!=null&&node.roleGroups!=''">Role Groups: {{node.roleGroups}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="float: right;">
            <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;" class="p-button-raised p-button-primary" pTooltip="Edit" (click)="EditFolder(node.key,node.isDocType,'ME',node.mainFolderId,node)"></button>&nbsp;&nbsp;&nbsp;
            <button pButton type="button" icon="pi pi-plus" pTooltip="Create Level 1 Folder" class="p-button-raised p-button-primary" (click)="CreateFolder(node.key,node.isDocType,node.label)"
            style="height:20px; width:20px;"></button>
        </span>
    </ng-template>
    <ng-template let-node pTemplate="subfol" *ngIf="source=='A'">{{node.label}} &nbsp;&nbsp;&nbsp;&nbsp;<b *ngIf="node.roleGroups!=null&&node.roleGroups!=''">Role Groups: {{node.roleGroups}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="float: right;">
            <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;" class="p-button-raised p-button-primary" pTooltip="Edit" (click)="EditFolder(node.key,node.isDocType,'SE',node.mainFolderId,node)"></button>
        </span>
    </ng-template>
    <ng-template let-node pTemplate="subfol1" *ngIf="source=='A'">{{node.label}} &nbsp;&nbsp;&nbsp;&nbsp;<b *ngIf="node.roleGroups!=null&&node.roleGroups!=''">Role Groups: {{node.roleGroups}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style="float: right;">
            <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;" class="p-button-raised p-button-primary" pTooltip="Edit" (click)="EditFolder(node.key,node.isDocType,'SSE',node.mainFolderId,node)"></button>
        </span>
    </ng-template>
    <ng-template let-node pTemplate="url">
        <a href="javascript:;" (click)="GetFile(node.fileName,node.userFileName)">{{node.label}}</a> &nbsp;&nbsp;&nbsp;
        <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                        tooltipPosition="left" class="p-button-raised p-button-primary"
                                        style="width:20px; height:20px;text-align:left;margin-right: 15px;"
                                        (click)="DMSToken(node.data)"></button>
    </ng-template>
</p-tree>
<div style="
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 5500;
  " *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>

<p-dialog header="{{textlabelheader}}" [(visible)]="addDisplay" modal="modal" showEffect="fade"
                [style]="{ width: '50%' }">
                <hr />
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-2"></div>
                    <div class="p-field p-col-12 p-md-8">
                        <label>Name<span style="color: red">*</span></label>
                        <input disabled={{!isdisabled}} [(ngModel)]="name" type="text" pInputText placeholder="Enter Name"/>
                        <small class="p-invalid">{{ nameError }}</small>
                        <br /><br />
                        <label>Description</label>
                        <textarea [(ngModel)]="description" type="text" rows="3" [maxLength]="300" pInputTextarea placeholder="Enter Description"></textarea>
                        <br /><br />      
                        <label>Active
                        <p-checkbox disabled={{isdisabled}} [(ngModel)]="active" binary="active">
                        </p-checkbox> </label>     
                        <br /><br />                     
                    </div>
                    <div class="p-field p-col-12 p-md-2"></div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-8"></div>
                    <div class="p-field p-col-12 p-md-2">
                        <br />
                        <button pButton pRipple type="button" icon="pi pi-check" label="{{TextLabel}}"
                         class="p-button-raised p-button-success p-mr-2 p-mb-2"
                            style="float: right" (click)="SaveUpdate()"></button>
                    </div>
                    <div class="p-field p-col-12 p-md-2"></div>
                    <small class="p-invalid" style="float: right">{{responseMessage}}</small>
                </div>
              </p-dialog>