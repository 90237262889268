<div class="p-grid p-fluid p-formgrid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12 p-lg-7 p-md-12 p-sm-12"></div>
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
          <label style="padding-right:10px;margin-top: 10px;margin-left: 35px;">Project:</label>
        </div>
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <p-multiSelect [options]="projects" (onChange)="selectedStatusChange($event)" [(ngModel)]="selectedProjects"
            autoWidth="false" defaultLabel="Select a Project" optionLabel="productName" class="multiselect-custom">
            <ng-template let-value pTemplate="selectedItems">
              <div class="country-item country-item-value" *ngFor="let option of selectedMulti">
                <div>{{option.name}}</div>
              </div>
              <div *ngIf="!selectedMulti || selectedMulti.length === 0" class="country-placeholder">
                Select Project
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <a>
            <button pButton pRipple type="button" (click)="searchProject()" icon="pi pi-search" iconPos="left"
              label="Search" class="p-button-raised p-button-primary"
              style="width:100%; margin-left:10px;float: right;"></button>
          </a>
        </div>
      </div>
      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <small class="p-invalid">{{ projectError }}</small>
      </div>
      <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12"></div>
      </div>
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;">

        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Documents By Project - {{DocumentsCount}}</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
          <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
            tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
        </span>
      </div>

      <div class="invoice invoice-header" id="invoice-content">
        <p-table  (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="docsData" id="excel-table" [columns]="cols"  class="table-align" [virtualScroll]="true" [scrollable]="true" scrollHeight="380px"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
          [globalFilterFields]="['ecrn','code','revision','title','alternateDocID','project','owner','status','date']">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                [ngClass]="{'ecrn':i==0,'code':i==1,'rev':i==2,'title': i == 3,'alternateDocID': i == 4,'prj':i==5,
                'owner': i == 6,'status':i==7,'date':i==8}">
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="width:60px;">Actions</th>
            </tr>
            <tr id="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                (input)="test($event.target.value,col.field,dt)"
                  style="margin: -10px 0 -10px 0; width:95%; height:25px;">
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 110px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 120px;">
                  <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                </td>
                <td *ngIf="i == 2" style="text-align: center;width: 60px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;word-break:break-all;width: 250px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;word-break:break-all;width: 160px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 5" style="text-align: left;padding-left:15px;word-break:break-all;width: 200px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 6" style="text-align: left;padding-left:15px;width: 150px;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 7" style="text-align: left;padding-left:15px;font-weight: bold;width: 120px;"
                [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 8" style="text-align: center;width: 65px;">
                  <span>{{rowData[col.field]}}</span>
                </td>
              </ng-container>
              <td style="text-align: center;width: 60px;">
                <button pButton type="button" (click)="viewDocDetails(docsData)" icon="pi pi-eye" pTooltip="View"
                  tooltipPosition="left" class="p-button-rasised p-button-seconday"
                  style="height:25px; width:25px;"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="10" style="text-align: left;">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
<p-dialog header="Information" [(visible)]="EmailDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}">
  <hr>
  <div class="p-grid">

    <div class="p-col-12" style="text-align: center;">
      <span style="font-size:18px;">Email sent successfully.</span>
    </div>
  </div>
</p-dialog>