<div class="p-grid">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <div class="card" style="min-height:600px;">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
              <a> <button pButton pRipple type="button" icon="pi pi-chevron-left" label="Back" (click)="BackClick()"
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width:auto;"></button></a>
            </div>
            <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12" style="text-align:center;">
              <h4 style="margin-top:5px;">Revise Document</h4> 
            </div>
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
          </div>

          <hr />
          <br>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label for="Title">Title<span style="color: red">*</span></label>
              <input id="Title" type="text" pInputText [(ngModel)]="title" placeholder="Enter Title" 
                />
              <small class="p-invalid">{{ titleError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="Department">Department<span style="color: red">*</span></label>
              <p-dropdown inputId="Department" [options]="departmentsData" [(ngModel)]="department"
                placeholder="departmentsData" optionLabel="departmentName"
               [disabled]="true">
              </p-dropdown>
              <small class="p-invalid">{{ departmentError }}</small>
              <!-- [disabled] = "canDepartmentDisabled" -->
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="state">Current Revision</label>
              <input id="Title" type="text" pInputText [disabled]="true" [(ngModel)]="revision"
                placeholder="Enter Title" />

            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="Description">Description</label>
              <textarea [(ngModel)]="Description" id="Description" type="text" rows="2" pInputTextarea [maxLength]="1000"
               ></textarea>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="address">Remarks</label>
              <textarea id="Remarks" type="text" rows="2" [(ngModel)]="Remarks" pInputTextarea 
               ></textarea>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="alternatedDocId">Alt Doc Id</label>
              <input id="alternatedDocId" type="text" pInputText [(ngModel)]="alternatedDocId"
                placeholder="Alt Doc Id" />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label for="city">Effective Date</label>
              <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate" [(ngModel)]="effectiveDate"
                (change)="EffectiveDateChange()"></p-calendar>
              <small class="p-invalid">{{ EfError }}</small>
            </div>
            <!-- <span style="color: red">*</span> -->
            <div class="p-field p-col-12 p-md-3">
              <label for="city">Next Review Date</label>
              <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate" [(ngModel)]="reviewDate"
                (change)="ReviewDateChange()"></p-calendar>
              <small class="p-invalid">{{ RError }}</small>
            </div>
            <!-- <span style="color: red">*</span> -->
            <div class="p-field p-col-12 p-md-3">
              <label for="city">Expiration Date</label>
              <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate" [(ngModel)]="expirationDate"
                (change)="ExpireDateChange()"></p-calendar>
              <small class="p-invalid">{{ ExError }}</small>
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label for="state">Document Type<span style="color: red">*</span></label>
              <p-dropdown inputId="documentType" [options]="docsTypesData" [(ngModel)]="selectedDocType"
                placeholder="docsTypesData" optionLabel="documentType"
                [disabled]="true">
              </p-dropdown>
              <small class="p-invalid">{{ docTypeError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="state">Document ID</label>
              <input id="Title" type="text" pInputText [disabled]="true" [(ngModel)]="doccode" placeholder="Enter Title"
                />
  
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="address">Revise Document</label><br>
              <span><a href="javascript:;" (click)="GetDownloadFile()">{{ApprovedFile}}</a></span>
            </div>
            <!-- <div class="p-field p-col-12 p-md-4">
              <label>ECN Level</label>
            <p-dropdown [options]="ECNLevelData" [(ngModel)]="selectedECN"
            placeholder="--Select--" optionLabel="name" (onChange)="getWorkFlowsList()"> 
          </p-dropdown></div> -->
            <div class="p-field p-col-12 p-md-4">
              <label for="state">Product<span style="color: red" *ngIf="!NotproductRelated">*</span></label>
              <p-autoComplete [disabled]="NotproductRelated" [(ngModel)]="selectedProduct" [suggestions]="filteredproduct" (onSelect)="SelectPL()"
                (completeMethod)="filterProduct($event)" field="productName" [dropdown]="true">
                <ng-template>
                  <div>
                    <div>{{ filteredproduct }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
              <small class="p-invalid">{{ productError }}</small>
              <div *ngIf="prodroles" style="float: right;margin-top:3px;">
                <button pButton pRipple type="button" icon="pi pi-eye" iconPos="left"  pTooltip="View Roles"  tooltipPosition="top" 
                class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="prodrolesdisplay = true" 
                style="float: right; height: 20px; width: 20px;"></button>
                </div>
                <span>
                      <p-dialog header="Product Roles (Missing team members for below roles)" [(visible)]="prodrolesdisplay" modal="modal"
                      showEffect="fade" [style]="{ width: '60%' }">
                      <hr />
                      <div class="p-grid">
                        <div class="p-col-12 p-md-12" style="text-align: center; margin-top: 8px">
                          <p-table [value]="prodrolesdata" [columns]="prcols" sortMode="multiple" class="table-align"
                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple">
                            <ng-template pTemplate="header" let-columns class="invoice-items">
                              <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                                  {{col.header}}
                                  <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                              <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                  <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">
                                    {{ rowData[col.field] }}
                                  </td>
                                  <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                  </td>
                                  <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                  </td>
                                  <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                  </td>
                                </ng-container>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                              <tr>
                                <td colspan="4" style="text-align: left;">No data found.</td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </div>
                    </p-dialog>
                  </span>
            </div>
            
            <div class="p-field p-col-12 p-md-2">
              <label>Product Line</label>
              <p-autoComplete  [disabled]="true"
                [(ngModel)]="selectedProductLine" [suggestions]="filteredproductline"
                (onSelect)="getWorkFlowsList()" (completeMethod)="filterProductLine($event)"
                field="name" [dropdown]="true">
                <ng-template>
                  <div>
                    <div>{{ filteredproductline.name }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="p-field p-col-12 p-md-2" *ngIf="ShowECRN">
              <label>ECN Level</label>
            <p-dropdown [options]="ECNLevelData" [(ngModel)]="selectedECN"
            placeholder="Select" optionLabel="name" (onChange)="getWorkFlowsList()"> 
          </p-dropdown></div>
            <div class="p-field p-col-12 p-md-4">
              <label for="state">Workflow<span style="color: red">*</span></label>&nbsp;&nbsp;
              <p-dropdown inputId="workflowDefinitionId" [options]="workFlowData" [(ngModel)]="selectedWorkflow"
                placeholder="Select" optionLabel="workflowDefinitionName">
              </p-dropdown>
              <small class="p-invalid">{{ workFlowError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-2" *ngIf="!ShowECRN"></div>
           
            <div class="p-field p-col-12 p-md-6" *ngIf="ShowECRN">
              <label>Description Of Change<span style="color: red">*</span></label>
              <textarea [(ngModel)]="ECRNDescription" type="text" rows="2" [maxLength]="1000"
                pInputTextarea></textarea>
                <small class="p-invalid">{{ ECRNDescriptionError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-6" *ngIf="ShowECRN">
              <label>Reasons For Change<span style="color: red">*</span></label>
              <textarea [(ngModel)]="ECRNReason" type="text" rows="2" [maxLength]="1000"
                pInputTextarea></textarea>
                <small class="p-invalid">{{ ECRNReasonError }}</small>
            </div>
          <div class="p-field p-col-12 p-md-3" *ngIf="ShowECRN">
            <label>ECRN Date</label>
            <p-calendar [showIcon]="true" inputId="icon" [disabled]="true" [minDate]="minDate" [(ngModel)]="ECRNDate"
             [disabled]="true"></p-calendar>
            <!-- <small class="p-invalid">{{ ecrnDateError }}</small> -->
          </div>
          <div class="p-field p-col-12 p-md-3" *ngIf="ShowECRN">
            <label>ECRN</label>
            <input type="text" pInputText [(ngModel)]="selectedECRN"
              placeholder="ECRN" disabled/>
          </div>
          <div class="p-field p-col-12 p-md-2" *ngIf="ShowECRN" style="margin-top: 26px;">
            <p-button label="Assign ECRN" class="p-button-raised p-button-primary p-mr-2 p-mb-2"   (click)="GetNewCode()" [disabled]="disablelinkdocid"></p-button>
         </div>
         <div class="p-field p-col-12 p-md-4" *ngIf="ShowECRN"></div>

            <div class="p-field p-col-12 p-md-3"  style=" margin-top: 26px;float: right">
              <input type="file" (change)="uploadFile($event)" id="upload" style="display: none" />
              <!-- <span style="color: red">*</span> -->
              <small class="p-invalid">{{ fileError }}</small>
              <label pButton for="upload" pRipple type="button" icon="pi pi-paperclip" iconPos="right"
                label="Choose file" class="p-button-raised p-button-info p-mr-2 p-mb-2"></label>
            </div>
            <div class="p-field p-col-12 p-md-9" style="margin-top: 2.5rem" >
              <span *ngIf="selectedFile"> {{fileName}} - {{filesize}} </span>
              <span *ngIf="selectedFile"><button pButton pRipple type="button" icon="pi pi-times" (click)="removeFile()"
                class="p-button-raised p-button-info p-mr-2 p-mb-2"
                style="float: right; height: 30px"></button>
              </span>
            </div>

            <div class="p-field p-col-12 p-md-8"></div>
            <div class="p-field p-col-12 p-md-4">
              <span style="font-size:18px;"><small class="p-invalid">{{ responseError }}</small></span>
            </div>
            <div class="p-field p-col-12">
              <hr>
              <div class="p-grid">

                <!-- <div class="p-field p-col-12 p-md-4" style="margin-top:25px; float:right; "><span *ngIf="selectedFile" >
              {{fileName}} - {{filesize}}  </span>
            </div>

             <div class="p-col-12 p-md-1" >
         <span *ngIf="selectedFile">  <button pButton pRipple type="button" icon="pi pi-times" (click)="removeFile()" class="p-button-raised p-button-info p-mr-2 p-mb-2" style=" margin-top:19px ; "></button></span>  
            </div>
            <div class="p-field p-col-12 p-md-2">
              <input
                  type="file"
                  (change)="uploadFile($event)" 
                  id="upload"
                  style="display: none"
                />
                <span style="color: red">*</span>
              <small class="p-invalid">{{ fileError }}</small>
              <label
                  pButton
                  for="upload"
                  pRipple
                  type="button"
                  icon="pi pi-paperclip"
                  iconPos="right"
                  label="Choose file"
                  class="p-button-raised p-button-info p-mr-2 p-mb-2"
                ></label>
             </div>         -->
             <div class="p-field p-col-12 p-lg-7 p-md-12 p-sm-12"></div>
             <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                  <br />
                  <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                    class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="createDoc('',false)"
                    style="float:right; width:100%"></button>
                </div>
                <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
                  <br />
                  <button pButton pRipple type="button" icon="pi pi-check" iconPos="left"
                    label="Save & Send for Approval" class="p-button-raised p-button-success p-mr-2 p-mb-2"
                    (click)="createDoc('sendforapproval',false)" style="float:right; width:100%"></button>
                </div>
                <span>
                  <p-dialog header="Save and Submit for Approval" [(visible)]="saveAndSendApprovalDisplay" modal="modal"
                    showEffect="fade" [style]="{ width: '60%' }">
                    <hr />
                    <div class="p-grid">
                      <div class="p-col-12 p-md-8" style="text-align: center; margin-top: 8px">
                        <h5>{{ AreYouSureText }}</h5>
                      </div>
                      <div class="p-col-12 p-md-2">
                        <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                          <button pButton type="button" label="Yes" icon="pi pi-check" iconPos="left"
                            (click)="createDoc('confirm',false)" class="p-button-raised p-button-success"></button>
                        </span>
                      </div>
                      <div class="p-col-12 p-md-2">
                        <span class="p-input-icon-left" style="margin-right: 10px; margin-top: 8px">
                          <button pButton type="button" label="No" icon="pi pi-times" iconPos="left"
                            (click)="saveAndSendApprovalDisplay = false"
                            class="p-button-raised p-button-secondary"></button>
                        </span>
                      </div>
                    </div>
                  </p-dialog>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
      *ngIf="showLoading">
      <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
    </div>