import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { docTypedModel } from "../../../models/docTypes_model";
import { FacadeService } from "../../../facade/facade.service";
import { DatePipe } from "@angular/common";
import { Table } from "primeng/table";
import { PrintService } from "../../../services/print.service";
import { ConfirmationService, SelectItem } from "primeng/api";
import { MessageService } from "primeng/api";
import { ValueConverter } from "@angular/compiler/src/render3/view/template";
import * as XLSX from 'xlsx'; 

@Component({
  selector: "app-doc-types",
  templateUrl: "./doc-types.component.html",
  styleUrls: ["./doc-types.component.scss"],
  providers: [MessageService, ConfirmationService],
})
export class DocTypesComponent implements OnInit {
  productRelated:boolean;
  userId = parseInt(sessionStorage.getItem("userId"));
  docTypesTableData: docTypedModel;
  docTypedModel: any;
  docTypes: docTypedModel[];
  DocumentsCount =0;
  selectedProducts: docTypedModel[];
  docsTypessObject: any;
  data: number[];
  options: any;
  plugin: any;
  today: any;
  addDisplay: boolean;
  editDisplay: boolean;
  openNew: boolean;
  productDialog: boolean;
  submitted: boolean;
  docsData: docTypedModel;
  typeError: any;
  codeError: any;
  type: any;
  code: any;
  cols: any[];
  documentTypeId: any;
  showLoading: boolean;
  deleteDisplay: boolean;
  AreYouSureText: any;
  ActiveCheckbox: boolean;
  NewCheckbox: boolean = true;
  IstemplateCheckbox: boolean = false;
  selectedModule: boolean= false;
  Module: SelectItem[];
  ShowDocCode: boolean = true;
  txterror: any;
  shortDescription: any;
  description: any;
  @ViewChild("dt") table: Table;
  // workflowdefinitions: any;
  filteredWFDef: any[];
  // selectedWorkflow: any;
  // workFlowError: any;
  apiResponse: any;
  userFeature:boolean;
  constructor(
    private _facadeService: FacadeService,
    public printService: PrintService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
      if (userFeatures) {
        var feature = JSON.parse(userFeatures);
        if(feature.length>0){
          var featureExist = feature.find(m=>m.code=="DSBL_NEW_EDIT_DTYPE");
          if(featureExist!=null){
            this.userFeature = true;
          }
        }
      }
    this.getDocsTypes();
    this.selectedModule = false;
    this.cols = [
      {
        field: "documentType",
        header: "Type",
        dynamicPlaceHolder: "Search by type",
      },
      {
        field: "documentTypeCodePrefix",
        header: "Prefix",
        dynamicPlaceHolder: "Search by Prefix",
      },
      {
        field: "shortDescription",
        header: "Short Description",
        dynamicPlaceHolder: "Search by Short Description",
      },
      {
        field: "description",
        header: "Description",
        dynamicPlaceHolder: "Search by Description",
      },
      {
        field: "singleInstanceName",
        header: "Do Not Allow Multiple Doc Creation",
        dynamicPlaceHolder: "Search by Do Not Allow Multiple Doc Creation",
      },
      {
        field: "creationSourceName",
        header: "ECN Required",
        dynamicPlaceHolder: "Search by ECN Required",
      },
      {
        field: "productRelatedName",
        header: "Product Related",
        dynamicPlaceHolder: "Search by Product Related",
      },
      {
        field: "active",
        header: "Active",
        dynamicPlaceHolder: "Search by active",
      },
    ];
      sessionStorage.documentType = "";
      sessionStorage.documentTypeCodePrefix ="";
      sessionStorage.shortDescription ="";
      sessionStorage.description ="";
      sessionStorage.singleInstanceName = "";
      sessionStorage.creationSourceName = "";
      sessionStorage.productRelatedName = "";
      sessionStorage.active = "";
  }

  hideAddDialog() {
    this.addDisplay = false;
  }

  hideEditDialog() {
    this.typeError = "";
    this.codeError = "";
    this.type = "";
    this.code = "";
    this.txterror = "";
    this.IstemplateCheckbox = false;
    this.ShowDocCode = true;
    this.selectedModule = false;
    this.shortDescription = "";
    this.description = "";
    this.productRelated=false;
  }

  DocTypeChange() {
    let type = this.type;
    this.typeError = "";
    this.docTypes.forEach((value, key) => {
      if (type.toUpperCase() == value.documentType.toUpperCase()) {
        this.typeError = "Doc type already exist.";
        return;
      }
    });
  }

  // exportExcel() {
  //   /* table id is passed over here */   
  //   let element = document.getElementById('excel-table'); 
  //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   this.delete_row(wb.Sheets.Sheet1, 1);
  //   ws['!cols'] = [{ wpx : 80 },{ wpx :  50},{ wpx : 250 },{ wpx : 250 },{ wpx : 200 },{ wpx : 100 },{ wpx : 100},{ wpx : 50 }];
  //   /* save to file */
  //   XLSX.writeFile(wb, "Documents Types.xlsx");
  // }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;

    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);

      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }

  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }

  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx : 80 },{ wpx :  50},{ wpx : 250 },{ wpx : 250 },{ wpx : 200 },{ wpx : 100 },{ wpx : 100},{ wpx : 50 }];
  
    // Save to file
    XLSX.writeFile(wb, 'Documents Types.xlsx');
  }
  // delete_row (ws, row_index){
  //   let range = XLSX.utils.decode_range(ws["!ref"])
  //   for(var R = row_index; R < range.e.r; ++R){
  //       for(var C = range.s.c; C <= range.e.c; ++C){
  //         ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
  //         if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
  //           ws[this.ec(R+1, C)].v  = "";
  //       }
  //   }
  //   range.e.r--
  //   delete (ws['I1'])
  //   ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  // }
  // ec(r, c) {
  //   return XLSX.utils.encode_cell({r:r,c:c})
  // }
  
  allowAlphaNumericSpace(e) {
    var code = "charCode" in e ? e.charCode : e.keyCode;
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
    return true;
  }
  openModel(selectedRow) {
    this.typeError = "";
    this.codeError = "";
    this.txterror = "";
    this.editDisplay = true;
    this.code = selectedRow.documentTypeCode;
    this.type = selectedRow.documentType;
    this.documentTypeId = selectedRow.documentTypeId;
    this.shortDescription = selectedRow.shortDescription;
    this.description = selectedRow.description;
    if(selectedRow.creationSource=="CMS"){
      this.selectedModule=true;
    }
    else{
      this.selectedModule=false;
    }
    this.ActiveCheckbox = selectedRow.active == "Y" ? true : false;
    this.IstemplateCheckbox = selectedRow.singleInstance;
    this.productRelated =selectedRow.productRelated;
    if (selectedRow.singleInstance) {
      this.ShowDocCode = false;
      this.code = "";
    } else {
      this.ShowDocCode = true;
    }
  }
  IsTemplateChange(event) {
    const checked = event.target.ariaChecked;
    if (checked) {
      this.ShowDocCode = false;
      this.code = "";
    } else {
      this.ShowDocCode = true;
      this.code = "";
    }
  }
  ECNRequired(event){
    const checked = event.target.ariaChecked;
    if (checked) {
      this.selectedModule=true;
    } else {
      this.selectedModule=false;
    }
  }
  addType() {
    this.typeError = "";
    this.codeError = "";
    this.txterror = "";
    if (this.type == undefined || this.type == "") {
      this.typeError = "Please enter document type.";
    } 
    else if (
      this.code == undefined ||
      (this.code == "" && !this.IstemplateCheckbox)
    ) {
      this.codeError = "Please enter document code.";
    } else if (
      this.code != undefined &&
      this.code != "" &&
      !this.IstemplateCheckbox &&
      this.code.length < 3
    ) {
      this.codeError = "Please enter minimum 3 characters.";
    } else {
      if (this.IstemplateCheckbox) {
        let docType: RegExp = /^[a-zA-Z]{3}[\-]{1}[0-9]{4}$/;
        if (!docType.test(this.type)) {
          this.typeError = "Please enter valid format.";
          return;
        }
      }
      this.docTypes.forEach((value, key) => {
        if (this.type.toUpperCase() == value.documentType.toUpperCase()) {
          this.typeError = "Doc type already exist.";
          return;
        }
      });
      if (this.typeError == "" || this.typeError == undefined) {
        this.docTypedModel = {
          documentType: this.type.toUpperCase(),
          documentTypeCode: this.code.toUpperCase(),
          active: "Y",
          createdBy: this.userId,
          singleInstance: this.IstemplateCheckbox,
          creationSource: this.selectedModule==true?"CMS":"DMS",
          shortDescription: this.shortDescription,
          Description: this.description,
          productRelated:this.productRelated
        };

        this.showLoading = true;
        this._facadeService.docTypes(this.docTypedModel).subscribe(
          (data) => {
            this.showLoading = false;
            if(data.returnCode==0){
              this.type = "";
              this.code = "";
              this.addDisplay = false;
              this.IstemplateCheckbox = false;
              this.ShowDocCode = true;
              this.selectedModule = false;
              this.productRelated=false;
              this.shortDescription = "";
              this.description = "";
              this.ngOnInit();
            }else {
                  this.txterror = data.returnMessage;
                }
            
            // const workflow = {
            //   documentTypeId: data.returnObject.documentTypeId,
            //   documentType: data.returnObject.documentType,
            //   workflowDefinitionId: this.selectedWorkflow.definitionId,
            //   workflowDefinitionName: this.selectedWorkflow.name,
            // };
            // this._facadeService
            //   .createWorkflowSetup(workflow)
            //   .subscribe((response) => {
            //     debugger;
            //     this.showLoading = false;
            //     this.apiResponse = response;
            //     if (response.returnCode == 0) {
            //       this.messageService.add({
            //         severity: "info",
            //         summary: "Record is added successully",
            //         detail: "record added",
            //       });
            //       this.type = "";
            //       this.code = "";
            //       this.addDisplay = false;
            //       this.IstemplateCheckbox = false;
            //       this.ShowDocCode = true;
            //       this.selectedModule = this.Module[0].value;
            //       this.ngOnInit();
            //     } else {
            //       this.txterror = data.returnMessage;
            //     }
            //   });
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
  }
  deleteDisplayClick(rowdata) {
    this.deleteDisplay = true;
    this.AreYouSureText =
      "Are you sure you want to delete " + rowdata.documentType + " ?";
  }

  editDocType() {
    this.deleteDisplay = false;
    this.typeError = "";
    this.codeError = "";
    if (this.type == undefined || this.type == "") {
      this.typeError = "Please enter document type.";
    }
    else if (
      this.code == undefined ||
      (this.code == "" && !this.IstemplateCheckbox)
    ) {
      this.codeError = "Please enter document code.";
    } else if (
      this.code != undefined &&
      this.code != "" &&
      !this.IstemplateCheckbox &&
      this.code.length < 3
    ) {
      this.codeError = "Please enter minimum 3 characters.";
    } else {
      if (this.IstemplateCheckbox) {
        let docType: RegExp = /^[a-zA-Z]{3}[\-]{1}[0-9]{4}$/;
        if (!docType.test(this.type)) {
          this.typeError = "Please enter valid format.";
          return;
        }
      }
      this.docTypes.forEach((value, key) => {
        if (value.documentTypeId != this.documentTypeId) {
          if (this.type.toUpperCase() == value.documentType.toUpperCase()) {
            this.typeError = "Doc type already exist.";
            return;
          }
        }
      });
      if (this.typeError == undefined || this.typeError == "") {
        this.docTypedModel = {
          documentType: this.type.toUpperCase(),
          documentTypeCode: this.code.toUpperCase(),
          active: this.ActiveCheckbox == true ? "Y" : "N",
          createdBy: this.userId,
          documentTypeId: this.documentTypeId,
          singleInstance: this.IstemplateCheckbox,
          creationSource: this.selectedModule==true?"CMS":"DMS",
          shortDescription: this.shortDescription,
          description: this.description,
          productRelated:this.productRelated
        };
        this.showLoading = true;
        this._facadeService.editdocTypes(this.docTypedModel).subscribe(
          (data) => {
            this.showLoading = false;
            console.log(data);
            if (data.returnCode == 0) {
              this.type = "";
              this.code = "";
              this.editDisplay = false;

              this.IstemplateCheckbox = false;
              this.ShowDocCode = true;
              this.selectedModule = false;
              this.shortDescription = "";
              this.productRelated=false;
              this.description = "";
              this.ngOnInit();
            } else {
              this.txterror = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
  }
  deleteDoc(rowData) {
    this.deleteDisplay = false;
    this.showLoading = true;
    this._facadeService.deleteDoc(rowData.documentTypeId).subscribe(
      (data) => {
        this.showLoading = false;
        this.ngOnInit();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  getDocsTypes() {
    this.showLoading = true;
    this._facadeService.getDocumentTypesTableData().subscribe(
      (data) => {
        this.docTypes = data.returnObject;
        this.docTypes.forEach((value, key) => {
          if(value.shortDescription == null)
            value.shortDescription = "";
          if(value.description == null)
            value.description = "";
          if(value.creationSource=="CMS"){
            value.creationSourceName="Y";
          }
          else{
            value.creationSourceName="N";
          }
          if(value.productRelated==false){
            value.productRelatedName="N";
          }
          else{
            value.productRelatedName="Y";
          }
          if (value.documentTypeCode == "UD") {
            value.isEditDisabled = true;
          } else {
            value.isEditDisabled = false;
          }
          if(value.singleInstance==true){
            value.singleInstanceName="Y";
          }
          else{
            value.singleInstanceName="N";
          }
          value.documentTypeCodePrefix= value.documentTypeCode.slice(0, 3);
        });
        console.log(this.docTypes);
        this.CountData();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  AddNewButton() {
    this.typeError = "";
    this.codeError = "";
    this.type = "";
    this.code = "";
    this.IstemplateCheckbox = false;
    this.ShowDocCode = true;
    this.selectedModule = false;
    this.addDisplay = true;
    this.txterror = "";
    this.shortDescription = "";
    this.description = "";
    this.productRelated=false;
  }

  DocCodeClick(code) {
    if (code != "" && code != undefined) {
      this.code = code.toUpperCase();
    }
  }

//   print() {
//     var Content = document.getElementById('invoice-content').innerHTML;
//     this.printService.Print(Content,'Document Types','trSearch',true);
// }

print() {
  // Disable virtual scrolling temporarily to ensure all rows are rendered
  const virtualScroll = this.table.virtualScroll;
  this.table.virtualScroll = false;

  // Refresh the data of the table
  //this.table.clear();

  // Detect changes immediately to ensure the table is rendered
  this.cdr.detectChanges();

  // Get the entire content of the 'invoice-content' element
  const Content = document.getElementById('invoice-content').innerHTML;

  // Revert virtual scrolling to its original state
  this.table.virtualScroll = virtualScroll;

  // Print the content
  this.printService.Print(Content, "Document Types", 'trSearch', true);
}

test(val,field,dt)
{
  if(field == "documentType")
    sessionStorage.ecrn = val;
  else if(field == "documentTypeCodePrefix")
    sessionStorage.documentTypeCodePrefix = val;
  else if(field == "shortDescription")
    sessionStorage.shortDescription = val;
  else if(field == "description")
    sessionStorage.description = val;
  else if(field == "singleInstanceName")
    sessionStorage.singleInstanceName = val;
  else if(field == "creationSourceName")
    sessionStorage.creationSourceName = val;
  else if(field == "productRelatedName")
    sessionStorage.productRelatedName = val;
  else if(field == "active")
    sessionStorage.active = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.docTypes != undefined && this.docTypes != null)
  {
    var temp = this.docTypes;
    if(sessionStorage.documentType != ""&&sessionStorage.documentType != null)
      temp = temp.filter(x=> x.documentType.toLowerCase().includes(sessionStorage.documentType.toLowerCase()));
    if(sessionStorage.documentTypeCodePrefix != "")
      temp = temp.filter(x=> x.documentTypeCodePrefix.toLowerCase().includes(sessionStorage.documentTypeCodePrefix.toLowerCase()));
    if(sessionStorage.shortDescription != "")
      temp = temp.filter(x=> x.shortDescription.toLowerCase().includes(sessionStorage.shortDescription.toLowerCase()));
    if(sessionStorage.description != "")
      temp = temp.filter(x=> x.description.toLowerCase().includes(sessionStorage.description.toLowerCase()));
    if(sessionStorage.singleInstanceName != "")
      temp = temp.filter(x=> x.singleInstanceName.toString().includes(sessionStorage.singleInstanceName.toString()));
    if(sessionStorage.creationSourceName != "")
      temp = temp.filter(x=> x.creationSourceName.toLowerCase().includes(sessionStorage.creationSourceName.toLowerCase()));
    if(sessionStorage.productRelatedName != "")
      temp = temp.filter(x=> x.productRelatedName.toLowerCase().includes(sessionStorage.productRelatedName.toLowerCase()));
    if(sessionStorage.active != "")
      temp = temp.filter(x=> x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
}
