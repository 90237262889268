import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import * as _ from "lodash";
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { PrintService } from "../../services/print.service";
import { Table } from 'primeng/table';

@Component({
  selector: 'app-printablereport',
  templateUrl: './printablereport.component.html',
  styleUrls: ['./printablereport.component.scss']
})
export class PrintablereportComponent implements OnInit {
  @ViewChild("dt") table: Table;
  filteredDept:any;
  showLoading: boolean;
  userId = parseInt(sessionStorage.getItem('userId'));
  docTypesData: any;
  selectedDocType: any = [];
  docid:string = "";
  usersData:any;
  selectedUser: any;
  selectedApprover: any;
  status:any;
  selectedstatus: any;
  startDate:any;
  endDate:any;
  Error:string = "";
  option:any;
  selectedoption:any;
  DisplayDays:boolean = true;
  DisplayRange:boolean = false;
  days:any = 5;
  fromdays:any=1
  todays:any = 5;
  timeZone = sessionStorage.getItem("timeZone");
  min:number = 0;
  max:number = 0;
  avg:string = "0";
  count : number = 0;
  cols: any[];
  docsData: any[];
  constructor(
    private _facadeService: FacadeService,private router: Router,  
    public printService: PrintService,private cdr: ChangeDetectorRef
  ) { }

  Reset()
  {
    this.showLoading = true;
    this.endDate = "";
    this.startDate = "";
    this.docid = "";
    this.selectedstatus = [];
    this.status.forEach(element => {
      this.selectedstatus.push(element);
    });
    this.selectedDocType = [];
    if(this.docTypesData != null && this.docTypesData != undefined)
    {
      this.docTypesData.forEach(element => {
        this.selectedDocType.push(element);
      });
    }
    this.selectedUser = [];
    this.usersData.forEach(element => {
      this.selectedUser.push(element);
    });
    this.selectedoption = [];
    this.selectedoption = this.option.find(x=>x.value == "more");
    this.DisplayDays = true;
    this.DisplayRange = false;
    this.days = 5;
    this.fromdays=1;
    this.todays = 5;
    this.selectedApprover = "";
    this.Search();
  }
  ngOnInit(): void {
    this.showLoading = true;
    // this.endDate = new Date();
    // this.startDate = new Date();
    // this.endDate.setDate( this.endDate.getDate() + 3650 );
    this.option = [ 
      {label: 'More', value: 'more'},
      {label: 'Less', value: 'less'},
      {label: 'In Between', value: 'range'},
    ];
    this.selectedoption = [];
    this.selectedoption = this.option.find(x=>x.value == "more");
    this.status = [ 
      // {label: 'New', value: 'New'},
      {label: 'In-Process', value: 'In-Process'},
      {label: 'Hold', value: 'Hold'},
      {label: 'Approved', value: 'Approved'},
      {label: 'Rejected', value: 'Rejected'},
      {label: 'Withdrawn', value: 'Withdrawn'},
      {label: 'Expired', value: 'Expired'},
      {label: 'Archived', value: 'Archived'},
      // {label: 'Delegated', value: 'Delegated'},
      // {label: 'Obsoleted', value: 'Obsoleted'},
      // {label: 'Obsolete In-Process', value: 'Obsolete In-Process'},
      // {label: 'Obsolete Rejected', value: 'Obsolete Rejected'}
    ];
    this.selectedstatus = [];
    this.status.forEach(element => {
      this.selectedstatus.push(element);
    });
    sessionStorage.ecrn = "";
    sessionStorage.departmentName = "";
    sessionStorage.code = "";
    sessionStorage.revision = "";
    sessionStorage.title = "";
    sessionStorage.owner = "";
    sessionStorage.elapsedDays = "";
    sessionStorage.status = "";
    sessionStorage.date = "";
    sessionStorage.totalElapsedDays = "";
    sessionStorage.approver = "";
    this.cols = [
      { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'Search' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: ' Search' },
      { field: 'code', header: 'Doc Id', dynamicPlaceHolder: 'Search' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search' },
      { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Search' },
      { field: "totalElapsedDays", header: "Total Elapsed Days of Approval", dynamicPlaceHolder: "Search" },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search' },
      { field: 'date', header: 'Date of Status', dynamicPlaceHolder: 'Search' },
      { field: "elapsedDays", header: "Elapsed Days of Current Approver", dynamicPlaceHolder: "Search" },
      { field: "approver", header: "Pending Approver", dynamicPlaceHolder: "Search" }
    ];
    this.getDocsTypes();
  }
  getDocsTypes() {
    this.selectedDocType = [];
    this._facadeService.getDocsTypes().subscribe(
      (data) => {
        this.docTypesData = data.returnObject;
        if(this.docTypesData != null && this.docTypesData != undefined)
        {
          this.docTypesData.forEach(element => {
            this.selectedDocType.push(element);
          });
        }
        this.getUsers();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  getUsers() {
    this.selectedUser = [];
    this._facadeService.getUsers().subscribe(
      (data) => {
        if (data.returnObject != null && data.returnObject.length > 0) 
        {
          data.returnObject.forEach(value => { value.fullName = value.firstName + " " + value.lastName;  });
          this.usersData = data.returnObject;
          this.usersData.forEach(element => {
            this.selectedUser.push(element);
            // this.selectedApprover.push(element);
          });
        }
        this.Search();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterDepartments(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.usersData.length; i++) {
        let dept = this.usersData[i];
        if (dept.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(dept);
        }
    }    
    this.filteredDept = filtered;
  }

  Display()
  {
    this.DisplayDays = false;
    this.DisplayRange = false;
    if(this.selectedoption != null && this.selectedoption != undefined)
    {
      if(this.selectedoption.value == "range")
        this.DisplayRange = true;
      else
        this.DisplayDays = true;
    }
  }
  isNumberKey(evt)
  {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31 
      && (charCode < 48 || charCode > 57))
        return false;

    return true;
  }
  
  Search()
  {
    this.Error = "";
    if(this.selectedDocType == null || this.selectedDocType == undefined || this.selectedDocType.length == 0)
      this.Error = "Please select atleast one document type";
    else if(this.selectedUser == null || this.selectedUser == undefined || this.selectedUser.length == 0)
      this.Error = "Please select atleast one originator";
    if(this.selectedstatus == null || this.selectedstatus == undefined || this.selectedstatus.length == 0)
      this.Error = "Please select atleast one status";
    // if(this.selectedApprover == null || this.selectedApprover == undefined || this.selectedApprover.length == 0)
    //   this.Error = "Please select atleast one approver";
    else 
    {
      var ok = true;
      if(this.startDate !== undefined && this.startDate !== "" && this.startDate !== null && this.endDate !== undefined && this.endDate !== "" && this.endDate !== null)
      {
        if(new Date(this.startDate) > new Date(this.endDate))
        {
          ok = false;
          this.Error = "To date should not be greater than From date";
        }
      }
      if(ok)
      {
        if(this.selectedoption != "range" && (this.days == null || this.days == undefined || this.days == ""))
          this.days = 0;
        if(this.selectedoption == "range" && (this.fromdays == null || this.fromdays == undefined || this.fromdays == ""))
          this.fromdays = 0;
        if(this.selectedoption == "range" && (this.todays == null || this.todays == undefined || this.todays == ""))
          this.todays = 0;

        if(this.selectedoption == "range" && this.fromdays != null && this.fromdays != undefined && this.todays != null && this.todays != undefined)
        {
          if(this.fromdays > this.todays)
          {
            ok = false;
            this.Error = "To days should not be greater than From days";
          }
        }
      }
      if(ok)
      {
        this.showLoading = true;
        var doctypes = [];
        if(this.selectedDocType != null && this.selectedDocType != undefined)
        {
          this.selectedDocType.forEach(element => {
            doctypes.push(element.documentTypeId);
          });
        }
        var approvers = [];
        if(this.selectedApprover != null && this.selectedApprover != undefined)
        {
          if(this.selectedApprover != "")
            approvers.push(this.selectedApprover.userGuid);
        }
        var orginator = [];
        if(this.selectedUser != null && this.selectedUser != undefined)
        { 
          this.selectedUser.forEach(element => {
            orginator.push(element.userId);
          });
        }
        var status = [];
        this.selectedstatus.forEach(element => {
          status.push(element.value);
        });
        var min = 0, max = 0;
        if(this.selectedoption.value == "more")
        {
          min = parseInt(this.days);
          max = 0;
        }
        if(this.selectedoption.value == "less")
        {
          min = 0;
          max = parseInt(this.days);
        }
        if(this.selectedoption.value == "range")
        {
          min = parseInt(this.fromdays);
          max = parseInt(this.todays);
        }
        var model = { 
          "approvers": approvers,
          "code": this.docid,
          "documentTypes": doctypes,
          "originators": orginator,
          "statuses": status,
          "minDays": min,
          "maxDays": max,
          "fromDate": this.startDate == undefined || this.startDate == null ? "" : this.startDate,
          "toDate": this.endDate == undefined || this.endDate == null ? "" : this.endDate,
          "timeZone": this.timeZone,
          "userID":this.userId
        };
        this._facadeService.RptDocumentsBySearch(model).subscribe(
          (data) => {
            this.docsData = data.returnObject;
            if(this.docsData != null && this.docsData != undefined)
            {
              this.docsData.forEach(value => {
                if(value.date!=null && value.date != "" && value.date != undefined)
                {
                  let convertactiondate = new Date(value.date+'Z');
                  value.date = new Date(convertactiondate);
                }
              });
            }
            this.CountData();
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    } 
  }
  viewDocDetails(rowData) {
    sessionStorage.isFrom = 'documentsearchAdmin';
    sessionStorage.StatusDoc = rowData.status;
    sessionStorage.docId = rowData.documentId;
    this.router.navigate(['/docdetails', '']);
  }
  test(val, field, dt) {
    if (field == "ecrn")
      sessionStorage.ecrn = val;
    else if (field == "departmentName")
      sessionStorage.departmentName = val;
    else if (field == "code")
      sessionStorage.code = val;
    else if (field == "revision")
      sessionStorage.revision = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "owner")
      sessionStorage.owner = val;
    else if (field == "elapsedDays")
      sessionStorage.elapsedDays = val;
    else if (field == "status")
      sessionStorage.status = val;
    else if (field == "date")
      sessionStorage.date = val;
    else if (field == "totalElapsedDays")
      sessionStorage.totalElapsedDays = val;
    else if (field == "approver")
      sessionStorage.approver = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }
  CountData() {
    if (this.docsData != undefined && this.docsData != null) {
      var temp = this.docsData;
      if (sessionStorage.ecrn != "")
        temp = temp.filter(x => x.ecrn != null && x.ecrn != "" && x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
      if (sessionStorage.departmentName != "")
        temp = temp.filter(x => x.departmentName.toString().includes(sessionStorage.departmentName.toString()));
      if (sessionStorage.code != "")
        temp = temp.filter(x => x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.revision != "")
        temp = temp.filter(x => x.revision.toLowerCase().includes(sessionStorage.revision.toLowerCase()));
      if (sessionStorage.title != "")
        temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.owner != "")
        temp = temp.filter(x => x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
      if (sessionStorage.elapsedDays != "")
        temp = temp.filter(x => x.elapsedDays.includes(sessionStorage.elapsedDays));
      if (sessionStorage.status != "")
        temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      if (sessionStorage.date != "")
        temp = temp.filter(x => x.date.toString().toLowerCase().includes(sessionStorage.date.toLowerCase()));
      if (sessionStorage.totalElapsedDays != "")
        temp = temp.filter(x => x.totalElapsedDays.includes(sessionStorage.totalElapsedDays));
      if (sessionStorage.approver != "")
        temp = temp.filter(x => x.approver.toLowerCase().includes(sessionStorage.approver.toLowerCase()));

      this.count = temp.length;
      var main = []
      temp.forEach(e => {
        main.push(e.totalElapsedDays);
      });
      this.max = main.reduce((a, b)=>Math.max(a, b)); 
      this.min = main.reduce((a, b)=>Math.min(a, b)); 
      var total = main.reduce((a, b)=>a + b); 
      this.avg = (total / temp.length).toFixed(2);
    }
    else
    {
      this.count = 0; this.min = 0; this.max = 0; this.avg = "0";
    }
  }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    ws['!cols'] = [{ wpx: 100 }, { wpx: 140 }, { wpx: 80 }, { wpx: 80 }, { wpx: 550 }, { wpx: 150 }, { wpx: 200 }, { wpx: 120 }, { wpx: 120 }, { wpx: 300 }, { wpx: 310 }];
    /* save to file */
    XLSX.writeFile(wb, "Search Documents.xlsx");
  }

  print() {
  
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content,"Search Documents",'trSearch',true);
  }
  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]

        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    delete (ws['L1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }

GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
