import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MegaMenuItem, MenuItem, SortEvent } from 'primeng/api';
import { rptUserSearchModel } from '../../../models/rptUserSearch_model';
import { FacadeService } from '../../../facade/facade.service';
import { holddocumentsModel } from 'src/app/models/holddocuments_model';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import * as _ from "lodash";
import { PrintService } from "../../../services/print.service";
interface Status {
  name: string,
  code: number
}
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-document-search',
  templateUrl: './document-search.component.html',
  styleUrls: ['./document-search.component.scss']
})
export class DocumentSearchComponent implements OnInit {

  constructor(
    private _facadeService: FacadeService, public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router,private cdr: ChangeDetectorRef
  ) {
  }
  userId = parseInt(sessionStorage.getItem('userId'));
  DocumentsCount =0;
  today: string;
  userSearchData: rptUserSearchModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  //statuses: any;
  //selectedStatus: any;
  statusError: any;
  departmentsData: any;
  showLoading:boolean;
  selectedDept:any;
  departmentId: any;
  moduleId: any;
  //applications = JSON.parse(sessionStorage.getItem('applications'));
  roles = JSON.parse(sessionStorage.getItem('roles'));
  ngOnInit(): void {
    //this.getUserSearchData();
    //this.GetApplications();
    this.getDepartments();
    
    // this.statuses = [
    //   { name: 'Document Management', code: 1 },
    //   { name: 'Project Management', code: 2 },
    //   { name: 'Product Management', code: 3 },
    //   { name: 'Change Management', code: 4 },
    //   { name: 'Issue Tracker', code: 5 },
    // ]; 
    this.cols = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Document Id' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Rev' }, 
      { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'ECO' },
      { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'ECRN' }, 
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Title' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },     
      { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Originator' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Status' },
      { field: 'lastAccessedDate', header: 'Date', dynamicPlaceHolder: 'Date' },
      
    ];
    sessionStorage.ecrn = "";
    sessionStorage.code ="";
    sessionStorage.revision = "";
    sessionStorage.eco = "";
    sessionStorage.title ="";
    sessionStorage.departmentName = "";
    sessionStorage.owner = "";
    sessionStorage.status = "";
    sessionStorage.lastAccessedDate = "";
  }

  getDepartments() {
    this.showLoading = true;
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.showLoading = false;
        this.departmentsData = data.returnObject;
        if(sessionStorage.getItem('did') != null && sessionStorage.getItem('did') != undefined)
        {
          this.selectedDept = this.departmentsData.find(x=>x.departmentId == sessionStorage.getItem('did'));
        }
        console.log(this.departmentsData);
        this.SearchDocuments();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  SearchDocuments() {
    console.log("Search docuemnts");
    if(this.selectedDept!='' && this.selectedDept!=undefined){
      this.departmentId = this.selectedDept.departmentId;
    }
    else{
      this.departmentId =0;
    }
    sessionStorage.did = this.departmentId;
    // if(this.selectedStatus!='' && this.selectedStatus!=undefined){
    //   this.moduleId = this.selectedStatus.applicationId;
    // }
    // else{
    //   this.moduleId =0;
    // }
    var isAdmin=false;
    if(this.roles.length>0){
      this.roles.forEach((value, key) => {
        if(value.name=="Administrator" ||value.name=="Document Owner"){
          isAdmin = true;
        }
      });
    }
    var searchdata = {
      departmentId: this.departmentId,
      // moduleId: this.moduleId,
      userId: this.userId,
      isAdminOrDC: isAdmin
      }
    this.showLoading = true;
    this._facadeService.searchDocuments(searchdata).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject.length>0){
          data.returnObject.forEach((value, key) => {
            if(value.status=='New'||value.status == 'In-Process' || value.status == 'Obsolete In-Process'){
              value.lastAccessedDate ="--";
            }
            else{
              value.lastAccessedDate = this.datePipe.transform(value.lastAccessedDate, 'MM/dd/yyyy');
            }
            if(value.eco!=null &&value.eco!="" && value.eco!=undefined){
              value.eco= value.eco;
            }
            else{
              value.eco= "N/A";
            }
            /*
            if(value.revision!=null &&value.revision!="" && value.revision!=undefined){
              if(value.revision === -1)
                value.revision = "N/A"
            }
            */
          });
        }
        this.userSearchData = data.returnObject;
        //this.userSearchData = _.sortBy(this.userSearchData, "createdDate");
        console.log("User Search Data ::" + JSON.stringify( this.userSearchData));  
        this.CountData();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  // GetApplications(){
  //   this.showLoading = true;
  //   this._facadeService.GetApplications().subscribe(
  //     (data) => {
  //       this.showLoading = false;
  //       var allapplications = data.returnObject;
  //       var applicationData= [];
  //       allapplications.forEach((value, key) => {
  //         this.applications.forEach((value1, key1) => {
  //           if(value.applicationName ==value1.name){
  //             applicationData.push(value);
  //           }
  //         });
  //       });
  //       this.statuses=applicationData;
  //     },
  //     (error) => {
  //       this.showLoading = false;
  //       console.log(error);
  //     }
  //   );
  // }

  // getUserSearchData() {
  //   var today = new Date();
  //   this.today = this.datePipe.transform(this.today, 'MM/dd/yyyy');
  //   this.userSearchMode = {
  //     documentId: 0,
  //     departmentId: 0,
  //     isArchived: 'N',
  //     documentTypeId: 0,
  //     documentStatus: '',
  //     todayDate: this.today,
  //     userID: this.userId,
  //   };
  //   this.showLoading = true;
  //   this._facadeService.mydocsData(this.userSearchMode).subscribe(
  //     (data) => {
  //       this.showLoading = false;
  //       this.userSearchData = data.returnObject;
  //       console.log(this.userSearchData);
  //         console.log("User Search Data ::" + JSON.stringify( this.userSearchData));  
  //     },
  //     (error) => {
  //       this.showLoading = false;
  //       console.log(error);
  //     }
  //   );
  // }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx : 80 },{ wpx : 30 },{ wpx : 40 },{ wpx : 80 },{ wpx : 400 },{ wpx : 140 },{ wpx : 80 },{ wpx : 70 },
      { wpx : 100 }];
     /* save to file */
     XLSX.writeFile(wb, "Documents.xlsx");
  }
//   exportExcel() {
//      /* table id is passed over here */   
//      let element = document.getElementById('excel-table'); 
//      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//      /* generate workbook and add the worksheet */
//      const wb: XLSX.WorkBook = XLSX.utils.book_new();
//      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//      this.delete_row(wb.Sheets.Sheet1, 1);
//      ws['!cols'] = [{ wpx : 80 },{ wpx : 30 },{ wpx : 40 },{ wpx : 80 },{ wpx : 400 },{ wpx : 140 },{ wpx : 80 },{ wpx : 70 },
//       { wpx : 100 }];
//      /* save to file */
//      XLSX.writeFile(wb, "Documents.xlsx");
// }
print() {
  
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
  this.printService.Print(Content,"Documents",'trSearch',true);
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['J1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

ResetDocuments(){
  this.selectedDept='',
  sessionStorage.ecrn = "";
    sessionStorage.code ="";
    sessionStorage.revision = "";
    sessionStorage.eco = "";
    sessionStorage.title ="";
    sessionStorage.departmentName = "";
    sessionStorage.owner = "";
    sessionStorage.status = "";
    sessionStorage.lastAccessedDate = "";
  //this.selectedStatus='',
  this.SearchDocuments();
}

viewDocDetails(selectedDocId, wfDefinitionId,status) {
  sessionStorage.docId = selectedDocId;
  sessionStorage.wfDefinitionId = wfDefinitionId; 
  sessionStorage.isFrom='Reports';
  this.showLoading = true;
  sessionStorage.StatusDoc= status;
  this.router.navigate(['/docdetails']);
}
test(val,field,dt)
{
  if(field == "ecrn")
    sessionStorage.ecrn = val;
  else if(field == "code")
    sessionStorage.code = val;
  else if(field == "revision")
    sessionStorage.revision = val;
  else if(field == "title")
    sessionStorage.title = val;
  else if(field == "departmentName")
    sessionStorage.departmentName = val;
  else if(field == "eco")
    sessionStorage.eco = val;
  else if(field == "owner")
    sessionStorage.owner = val;
  else if(field == "status")
    sessionStorage.status = val;
  else if(field == "lastAccessedDate")
    sessionStorage.lastAccessedDate = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.userSearchData != undefined && this.userSearchData != null)
  {
    var temp = this.userSearchData;
    if(sessionStorage.ecrn != ""&&sessionStorage.ecrn != null)
      temp = temp.filter(x=> x.ecrn!=null&&x.ecrn!=""&&x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
    if(sessionStorage.code != "")
      //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != "")
      temp = temp.filter(x=> x.revision.toString().includes(sessionStorage.revision.toString()));
      if(sessionStorage.eco != "")
      temp = temp.filter(x=> x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
    if(sessionStorage.departmentName != "")
      temp = temp.filter(x=> x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
    if(sessionStorage.owner != "")
      temp = temp.filter(x=> x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
    if(sessionStorage.status != "")
      temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    if(sessionStorage.lastAccessedDate != "")
      temp = temp.filter(x=> x.lastAccessedDate.toString().toLowerCase().includes(sessionStorage.lastAccessedDate.toLowerCase()));
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'lastAccessedDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    if (e.status == 'New' || e.status == 'In-Process' || e.status == 'Obsolete In-Process') {
      e.lastAccessedDate = "--";
    } else if (event.field !== 'lastAccessedDate') {
      // Only format lastAccessedDate if it's not the field used for sorting
      e.lastAccessedDate = this.datePipe.transform(e.lastAccessedDate, 'MM/dd/yyyy');
    }
  });
  this.userSearchData = [...event.data];
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
