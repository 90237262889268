<div class="p-grid p-fluid">
  <div class="p-col-12">
    <div class="card" style="min-height: 800px">
      <div class="p-grid p-formgrid">
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <label>Department: </label>
          <p-multiSelect [options]="departments" [(ngModel)]="selectedDepartments" defaultLabel="Select a department"
            optionLabel="departmentName" (onChange)="departmentChange()" display="chip" overlayVisible="true">
          </p-multiSelect>
        </div>
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <label>Period: </label>
          <p-dropdown [options]="months" [(ngModel)]="selectedMonth" optionLabel="Name"
            (onChange)="monthChangeEvent(selectedMonth)" autoWidth="false"></p-dropdown>
        </div>
        <div *ngIf="customDisplay" class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <label>Start Date: </label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="From Date" [(ngModel)]="fromDate"></p-calendar>
        </div>
        <div *ngIf="customDisplay" class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <label>End Date: </label>
          <p-calendar [showIcon]="true" inputId="icon" placeholder="To Date" [(ngModel)]="toDate"></p-calendar>
        </div>
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
          <a>
            <button pButton pRipple type="button" (click)="originatedByStatus()" icon="pi pi-search" iconPos="left"
              label="Search" class="p-button-raised p-button-primary" style="width: auto;float: right;margin-top: 25px;"></button>
          </a>
        </div>
      </div>
      <div class="p-grid p-formgrid">
        <!-- <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12"></div> -->
        <div class="p-field p-col-12 p-lg-12 p-md-12 p-sm-12" style="float: right;">
      <!-- <span style="float: right"> -->
        <small class="p-invalid">{{ departmentErr }}</small>
        <small class="p-invalid">{{ periodErr }}</small>
        <small class="p-invalid">{{ fromDateErr }}</small>
        <small class="p-invalid">{{ toDateErr }}</small>
      <!-- </span> -->
        </div>
        </div>
      <br />
      <hr />

      <div class="p-col-12 p-lg-12 p-md-12 p-sm-12">
        <div class="card" style="min-height: 400px;overflow-x: auto;">
          <div class="card-header" style="padding-bottom:0px;">
            <h4>Documents Originated By Status</h4>
          </div>
          <div class="p-grid p-formgrid">
            <div *ngIf="isChartDataAvailable; else Chartemptymessage" class="revenue-chart-container"
              style="width:100%;">
              <p-chart type="bar" [data]="data" [options]="chartOptions"></p-chart>
            </div>
            <ng-template #Chartemptymessage>
              <div style="text-align: center;margin-left: 50px;">No status found.</div>
            </ng-template>
          </div>
          <!-- <p-chart type="line" [data]="data"></p-chart> -->
        </div>
      </div>

      <div class="card" style="min-height: 600px">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height: auto;">
          <h5 style="color: #495057; padding: 15px 0 0 15px;width: 90%;">Documents Originated By Status - {{DocumentsCount}}</h5>
          <!-- <h5 style="color:#495057; padding:15px 0 0 15px;">{{documentsName}}</h5>  -->
          <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
            <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
              tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float: right; height: 25px; width: 25px"></button>
            <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
              tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float: right; margin-left: 15px; height: 25px; width: 25px"></button>
          </span>
        </div>

        <div class="invoice invoice-header" id="invoice-content">
          <p-table   (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="docsData" id="excel-table" [columns]="cols" class="table-align" [virtualScroll]="true" [scrollable]="true" scrollHeight="380px"
            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="id"
            [globalFilterFields]="['departmentName','startDate','endDate','status','count']">
            <ng-template pTemplate="header" let-columns class="invoice-items">
              <tr>
                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                  [ngClass]="{'departmentName': i == 0,'startDate': i == 1, 'endDate': i == 2, 'status': i == 3, 'count': i == 4}">
                  {{ col.header }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th pSortableColumn="price" style="width: 60px">Actions</th>
              </tr>
              <tr id="trSearch">
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                  <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0; width: 95%; height: 25px" />
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
              <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                  <td *ngIf="i == 0" style="text-align: left; padding-left: 15px;width: 150px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 1" style="text-align: center; padding-left: 15px;width: 130px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 2" style="text-align: center; padding-left: 15px;width: 130px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 3" style="text-align: left; padding-left: 55px;font-weight: bold;width: 130px;"
                  [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                  {{ rowData[col.field] }}
                  </td>
                 
                  <td *ngIf="i == 4" style="text-align: center; padding-left: 15px;width: 120px;">
                    {{ rowData[col.field] }}
                  </td>
                </ng-container>
                <td style="text-align: center;width: 60px;">
                  <button pButton type="button" (click)="viewDocDetails(docsData)" icon="pi pi-eye" pTooltip="View"
                    tooltipPosition="left" class="p-button-rasised p-button-seconday"
                    style="height: 25px; width: 25px"></button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5" style="text-align: left">No documents found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed; left: 0;top: 0;width: 100%;height: 100%;background-color: #000000; opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>
<p-dialog header="Information" [(visible)]="EmailDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}">
  <hr>
  <div class="p-grid">

    <div class="p-col-12" style="text-align: center;">
      <span style="font-size:18px;">Email sent successfully.</span>
    </div>
  </div>
</p-dialog>