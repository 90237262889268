<div class="p-grid p-fluid p-formgrid" id="invoice-content">
  <div class="p-col-12">
    <div class="card" style="min-height: 600px">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
          <a (click)="BackClick()">
            <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left" label="Back"
              class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width: auto;"></button></a>
        </div>
        <div class="p-field p-col-12 p-lg-9 p-md-12 p-sm-12" style="text-align: center">
          <!-- <h4 style="margin-top: 5px">Document Details</h4> -->
          <h4> Document Title : {{ docsDetailsData?.title }}</h4>
        </div> 
        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <button *ngIf="ShowDownload" pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
            (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float: right; height: 25px; width: 25px;margin: 5px;"></button>
          <p-splitButton [hidden]="!showActionSplit" label="Actions" [model]="items"
            styleClass="p-button-primary p-mr-2 p-mb-2"></p-splitButton>

          <span>
            <p-dialog header="Delete document" [(visible)]="deleteDisplay" modal="modal" showEffect="fade"
              [style]="{ width: '60%' }">
              <hr />
              <div class="p-grid p-fluid p-formgrid">
                <div class="p-field p-col-12 p-md-8" style="text-align: center; margin-top: 8px">
                  <h4 style="color: red">{{ AreYouSureText }}</h4>
                </div>
                <div class="p-field p-col-12 p-md-2">
                  <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                    <button pButton type="button" label="Delete" icon="pi pi-trash" iconPos="left" (click)="deleteDoc()"
                      class="p-button-raised p-button-danger"></button>
                  </span>
                </div>
                <div class="p-field p-col-12 p-md-2">
                  <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                    <button pButton type="button" label="Close" icon="pi pi-times" iconPos="left"
                      (click)="deleteDisplay = false" class="p-button-raised p-button-secondary"></button>
                  </span>
                </div>
              </div>
            </p-dialog>
          </span>
        </div>
      </div>
      <!-- <div class="card-header">
       
        <h4 style="color:#495057; padding:15px 0 0 15px;">Document Details</h4> 
        <span class="p-input-icon-left" style="float:right; margin-right:10px;">        
      
      
        <p-splitButton *ngIf="showActionSplit" label="Actions" icon="pi pi-plus" [model]="items" styleClass="p-button-primary p-mr-2 p-mb-2"></p-splitButton>
</span>
    </div>  -->

      <hr />

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-12 p-md-4 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 275px">
            <ul class="widget-image-list">
              <li>
                <span style="font-weight: bold">ID:</span>
                <span *ngIf="ShowDownload"><a href="javascript:;" (click)="GetFile()">{{
                    docsDetailsData?.code
                    }}</a></span>
                <span *ngIf="!ShowDownload">{{
                  docsDetailsData?.code
                  }}</span>
              </li>
              <li>
                <span style="font-weight: bold">{{ RevorECO }}:</span>
                <span>{{ RevorECOCount }} </span>
              </li>
              <li>
                <span style="font-weight: bold">Type:</span>
                <span>{{ docsDetailsData?.documentType }}</span>
              </li>
              <li>
                <span style="font-weight: bold">Site:</span>
                <span>{{ docsDetailsData?.site }}</span>
              </li>
              <li style="height: 41px; padding-bottom: 0px; padding-top: 4px">
                <span style="font-weight: bold">Product:</span>
                <span *ngIf="showProductName">{{ productName }}</span>
                <span *ngIf="!showProductName" class="listitem-value">
                  <button pButton pRipple type="button" icon="pi pi-eye" (click)="projectdisplay=true" 
                    pTooltip="View" tooltipPosition="top" 
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    style="float: right; height: 20px; width: 20px;color: #ffffff;
                    background: #689F38;
                    border: 1px solid #689F38;"></button>
                </span>
              </li>
              <!--T0010454-->
              <li>
                <span style="font-weight: bold">Alt Doc Id:</span>
                <span>{{ docsDetailsData?.alternatedDocId }}</span>
              </li>
              <!--T0010454-->
            </ul>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 275px">
            <ul class="widget-image-list">
              <li>
                <span style="font-weight: bold">Initiated Date:</span>
                <span>{{ lastAccessDate | date:'MM/dd/yyyy' }}</span>
              </li>
              <li>
                <span style="font-weight: bold">Effective Date:</span>

                <span class="listitem-value">
                  <!-- {{
                  docsDetailsData.effectiveDate | date: "MM/dd/yyyy"
                }} -->
                 <span> {{ effectiveDate | date:'MM/dd/yyyy' }}</span>
                </span>
              </li>
              <li>
                <span style="font-weight: bold">Next Review Date:</span>

                <span class="listitem-value">
                  <!-- {{
                  docsDetailsData.reviewDate | date: "MM/dd/yyyy"
                }} -->
                  {{ reviewDate | date:'MM/dd/yyyy' }}
                </span>
              </li>
              <li>
                <span style="font-weight: bold">Expiration Date:</span>

                <span class="listitem-value">
                  <!-- {{
                  docsDetailsData.expirationDate | date: "MM/dd/yyyy"
                }} -->
                  {{ expiryDate | date:'MM/dd/yyyy' }}
                </span>
              </li>
              <li>
                <span style="font-weight: bold">Status:</span>

                <span class="listitem-value">{{ docsDetailsData?.displayStatus }}</span>
              </li>
              <li *ngIf="REV_DOWNLOAD">
                <span style="font-weight: bold">Revise Document:</span>
                <span><a href="javascript:;" (click)="GetApprovedFile()">{{ApprovedFile}}</a></span>
              </li> 
              <li *ngIf="!REV_DOWNLOAD">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="p-field p-col-12 p-md-4 p-xl-4">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 275px">
            <ul class="widget-image-list">
              <li style="height: 40px">
                <span style="font-weight: bold">Document Originator:</span>

                <span class="listitem-value">{{ docsDetailsData?.owner }}</span>
              </li>

              <li style="height: 40px">
                <span style="font-weight: bold">Workflow:</span>

                <span class="listitem-value">{{
                  docsDetailsData?.workflow
                  }}</span>
              </li>
              <li style="height: 41px; padding-bottom: 0px; padding-top: 4px">
                <span style="font-weight: bold">Department:</span>
                <span class="listitem-value">
                  {{docsDetailsData?.departmentName}}
                </span>
              </li>
              <li style="height: 41px; padding-bottom: 0px; padding-top: 4px">
                <span style="font-weight: bold">Comments:</span>

                <span class="listitem-value">
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="commentsList.length > 0"
                    pTooltip="View" tooltipPosition="top" (click)="commentsDisplay = true"
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    style="float: right; height: 20px; width: 20px;color: #ffffff;
                    background: #689F38;
                    border: 1px solid #689F38;"></button>
                    <button pButton pRipple type="button" icon="pi pi-eye" [disabled]="true" *ngIf="commentsList.length === 0"
                    pTooltip="View" tooltipPosition="top" (click)="commentsDisplay = true"
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    style="float: right; height: 20px; width: 20px;"></button>
                </span>
              </li>
              <li style="padding-bottom: 0px; padding-top: 4px; height: 40px">
                <span style="font-weight: bold">Remarks:</span>
                <span class="listitem-value">
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="docsDetailsData?.remarks"
                    pTooltip="View" tooltipPosition="top" (click)="remarksDisplay = true"
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    style="float: right; height: 20px; width: 20px;color: #ffffff;
                    background: #689F38;
                    border: 1px solid #689F38;"></button>
                    <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="!docsDetailsData?.remarks" [disabled]="true"
                    pTooltip="View" tooltipPosition="top"
                    class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    style="float: right; height: 20px; width: 20px"></button>
                </span>
                <!-- <span class="listitem-value">{{docsDetailsData.remarks}}</span> -->
              </li>
              <li style="padding-bottom: 0px; padding-top: 4px;">
                <span style="font-weight: bold">Originator Changes:</span>
                <span class="listitem-value">
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="Reasons.length > 0" pTooltip="View" tooltipPosition="top" (click)="OriginatorDisplay = true"
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="float: right; height: 20px; width: 20px;color: #ffffff;background: #689F38;  border: 1px solid #689F38;"></button>
                  <button pButton pRipple type="button" icon="pi pi-eye" *ngIf="Reasons.length == 0" [disabled]="true" pTooltip="View" tooltipPosition="top" 
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="float: right; height: 20px; width: 20px"></button>
                </span>
              </li>
            </ul>
          </div>
        </div>
       
        <div class="p-field p-col-12 p-md-12 p-xl-12" *ngIf="ShowECRN">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 100px">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label style="font-weight: bold">ECRN:</label>
              <input id="Title" type="text" pInputText placeholder="{{docsDetailsData?.ecrn}}" disabled="true"
                style="opacity: 1.0;" />
      
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label style="font-weight: bold">Date:</label>
              <input id="Title" type="text" pInputText placeholder="{{docsDetailsData?.changeDate}}" disabled="true"
                style="opacity: 1.0;" />
      
            </div>
            <div class="p-field p-col-12 p-md-6" *ngIf="ShowChange">
              <label style="font-weight: bold">ECRN Description of Change:</label>
              <textarea type="text" rows="4" placeholder="{{docsDetailsData?.changeDescription}}" disabled="true"
                style="opacity: 1.0;" pInputTextarea></textarea>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label style="font-weight: bold">Reasons for Change:</label>
              <textarea placeholder="{{docsDetailsData?.changeReasons}}" type="text" rows="4"
                disabled="true" style="opacity: 1.0;" pInputTextarea></textarea>
            </div>
          </div>
        </div></div>
        <div class="p-field p-col-12 p-md-12 p-xl-12" *ngIf="!ShowECRN&&!ShowChange">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 100px">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label style="font-weight: bold">Reasons for Change:</label>
              <textarea placeholder="{{docsDetailsData?.obsoleteComments}}" type="text" rows="4"
                disabled="true" style="opacity: 1.0;" pInputTextarea></textarea>
            </div>
          </div>
        </div></div>
        <div class="p-field p-col-12 p-md-12 p-xl-12" *ngIf="ShowChange">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height: 100px">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12">
                <span style="font-weight: bold">Description:</span>
                <textarea style="opacity: 0.9;" [(ngModel)]="DetailDescription" id="DetailDescription" type="text"
                  rows="3" [maxLength]="1000" pInputTextarea [disabled]=true></textarea>
              </div>
            </div>
          </div>
        </div>
        <span>
          <p-dialog header="Comments" [(visible)]="commentsDisplay" modal="modal" showEffect="fade"
            [style]="{ width: '60%' }">
            <hr />

            <div class="p-grid p-fluid p-formgrid">

              <div class="p-field p-col-12" style="text-align: center">
                <div *ngIf="commentsExist" class="card no-gutter widget-overview-box widget-overview-box-1"
                  style="min-height: 230px; overflow-y: scroll">
                  <ul class="widget-image-list" *ngFor="let appr of commentsList">
                    <li>
                      <span class="listitem-value">
                        <ul>
                          <li style="text-align: left">
                            <div style="float: left">
                              <span style="
                                  color: red;
                                  font-size: 16px;
                                  padding-right: 10px;
                                ">{{ appr.approver }}:</span>
                              <span style="
                                  color: #2196f3;
                                  font-size: 14px;
                                  padding-right: 10px;
                                ">{{
                                appr.approvedDate
                                | date: "MM/dd/yyyy hh:mm a"
                                }}:</span>{{ appr.comments }}
                            </div>
                          </li>
                        </ul>
                      </span>
                    </li>
                  </ul>
                </div>
                <div *ngIf="!commentsExist" class="card no-gutter widget-overview-box widget-overview-box-1"
                  style="min-height: 230px; overflow-y: scroll">
                  <ul class="widget-image-list">
                    <li>
                      <span class="listitem-value">
                        <ul>
                          <li style="text-align: left">
                            <div style="float: left">
                              <span style="font-size: 16px; padding-right: 10px">No Comments to show.</span>
                            </div>
                          </li>
                        </ul>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>


            </div>
          </p-dialog>
        </span>
        <span>
          <!-- <p-dialog
            header="Description"
            [(visible)]="descriptionDisplay"
            modal="modal"
            showEffect="fade"
            [style]="{ width: '60%' }"
          >
            <hr />
            <div class="p-grid">
              <div class="p-col-1"></div>
              <div class="p-col-12 p-md-10" style="text-align: center">
                <ul class="widget-image-list">
                  <li>
                    <span class="listitem-value">
                      <ul>
                        <li style="text-align: left">
                          <div style="float: left">
                            <span
                              style="font-size: 16px; padding-right: 10px"
                              >{{ docsDetailsData.description }}</span
                            >
                          </div>
                        </li>
                      </ul>
                    </span>
                  </li>
                </ul>
              </div>
              <div class="p-col-1"></div>

              <div class="p-col-12 p-md-7"></div>
              <div class="p-col-12 p-md-4"></div>
              <div class="p-col-1"></div>
            </div>
          </p-dialog>  -->
        </span>
        <span>
          <p-dialog header="Remarks" [(visible)]="remarksDisplay" modal="modal" showEffect="fade"
            [style]="{ width: '60%' }">
            <hr />
            <div class="p-grid p-fluid p-formgrid">
              <div class="p-field p-col-1"></div>
              <div class="p-field p-col-12 p-md-10" style="text-align: center">
                <ul class="widget-image-list">
                  <li>
                    <span class="listitem-value">
                      <ul>
                        <li style="text-align: left">
                          <div style="float: left">
                            <span style="font-size: 16px; padding-right: 10px">{{ docsDetailsData?.remarks }}</span>
                          </div>
                        </li>
                      </ul>
                    </span>
                  </li>
                </ul>
              </div>
              <div class="p-field p-col-1"></div>

              <div class="p-field p-col-12 p-md-7"></div>
              <div class="p-field p-col-12 p-md-4"></div>
              <div class="p-field p-col-1 p-md-1"></div>
            </div>
          </p-dialog>
        </span>
      </div>
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height: auto;">
        <h5 style="color: #495057; padding: 15px 0 0 15px;width: 80%;">Approval History</h5><span
          style="font-weight: bold; color:#EE9D0A; width: 150%;" *ngIf="DCCShow">Doc is pending DCC finalization</span>
        <!-- <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px"> -->

          <!-- <button
            pButton
            pRipple
            type="button"
            icon="pi pi-print"
            pTooltip="Print"
            tooltipPosition="top"
            (click)="printToCart('printSectionId')"
            class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float: right; height: 25px; width: 25px"
          ></button> -->
          <!-- <button
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            pTooltip="Download"
            tooltipPosition="top"
            (click)="exportExcel()"
            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float: right; margin-left: 15px; height: 25px; width: 25px"
          ></button> -->
        <!-- </span> -->
      </div>
      <div class="invoice invoice-header" style="overflow-x: auto;">
        <p-table [value]="approvalsData" sortMode="multiple" class="table-align">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="profileCode" style="width: 110px;">
                Approval Level<p-sortIcon field="profileCode"></p-sortIcon>
              </th>
              <th pSortableColumn="approver" style="width: 130px;">
                Approved By<p-sortIcon field="approver"></p-sortIcon>
              </th>
              <th pSortableColumn="approvedDate" style="width: 140px;">
                Approved Date <p-sortIcon field="approvedDate"></p-sortIcon>
              </th>
              <th pSortableColumn="department" style="width: 160px;">
                Department<p-sortIcon field="department"></p-sortIcon>
              </th>
              <th pSortableColumn="role" style="width: 180px;">
                Role<p-sortIcon field="role"></p-sortIcon>
              </th>

              <th pSortableColumn="approvalStatus" style="width: 130px">
                Status<p-sortIcon field="approvalStatus"></p-sortIcon>
              </th>
              <th pSortableColumn="comments" style="width: 300px">
                Comments<p-sortIcon field="comments"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" (input)="
                    dt.filter(
                      $event.target.value,
                      col.field,
                      'contains'
                    )
                  " style="margin: -10px 0 -10px 0" />
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-approvalsData>
            <tr>
              <td style="text-align: left; padding-left: 15px">
                {{ approvalsData.profileCode }}
              </td>
              <td style="text-align: left; padding-left: 15px">
                {{ approvalsData.approver }}
              </td>
              <td style="text-align: center">
                {{ approvalsData.approvedDate | date: "MM/dd/yyyy hh:mm a" }}
              </td>
              <td style="text-align: left; padding-left: 15px">
                {{ approvalsData.department }}
              </td>
              <td style="text-align: left; padding-left: 15px">
                {{ approvalsData.role }}
              </td>
              <td style="text-align: left;padding-left:15px;" [ngStyle]="{'color': approvalsData.approvalStatus === 'Obsolete Approved' ? '#1976d2' : ''}"> 
                {{ approvalsData.approvalStatus }}
              </td>
              <td style="text-align: left; padding-left: 15px">
                {{ approvalsData.comments }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <br />
      <div class="table-footer" style="float: right; margin-bottom: 10px" *ngIf="ShowECODocs">
        <span> </span>
      </div>
      <div *ngIf="ShowECODocs" class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height: auto;">
        <h5 style="color: #495057; padding: 15px 0 0 15px;width: 70%;">ECO Documents</h5>
        <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
          <!-- <button
            pButton
            pRipple
            type="button"
            icon="pi pi-print"
            pTooltip="Print"
            tooltipPosition="top"
            (click)="print()"
            class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float: right; height: 25px; width: 25px"
          ></button> -->
          <!-- <button
            pButton
            pRipple
            type="button"
            icon="pi pi-download"
            pTooltip="Download"
            tooltipPosition="top"
            (click)="exportExcelECO()"
            class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float: right; margin-left: 15px; height: 25px; width: 25px"
          ></button> -->
        </span>
      </div>
      <div class="invoice invoice-header" *ngIf="ShowECODocs" style="overflow-x: auto;">
        <p-table [value]="ECODocs" sortMode="multiple" styleClass="p-datatable-striped">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th pSortableColumn="code" style="width: 150px;">
                Document ID <p-sortIcon field="code"></p-sortIcon>
              </th>
              <th pSortableColumn="title" style="width: 250px;">
                Title <p-sortIcon field="title"></p-sortIcon>
              </th>
              <!-- <th pSortableColumn="documentType">
            Type<p-sortIcon field="documentType"></p-sortIcon>
          </th> -->
              <th pSortableColumn="departmentName" style="width: 180px;">
                Department<p-sortIcon field="departmentName"></p-sortIcon>
              </th>
              <th pSortableColumn="effectiveDate" style="width: 150px;">
                Effective Date <p-sortIcon field="effectiveDate"></p-sortIcon>
              </th>
              <th pSortableColumn="expirationDate" style="width: 150px;">
                Expiration Date <p-sortIcon field="expirationDate"></p-sortIcon>
              </th>
              <th pSortableColumn="status" style="width: 100px;">
                Status<p-sortIcon field="status"></p-sortIcon>
              </th>
            </tr>
            <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" (input)="
                    dt.filter(
                      $event.target.value,
                      col.field,
                      'contains'
                    )
                  " style="margin: -10px 0 -10px 0" />
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-docsData>
            <tr>
              <td style="text-align: left; padding-left: 15px">
                {{ docsData.code }}
              </td>
              <td style="text-align: left; padding-left: 15px">
                {{ docsData.title }}
              </td>
              <!-- <td style="text-align: left; padding-left:15px;">{{ docsData.documentType }}</td> -->
              <td style="text-align: left; padding-left: 15px">
                {{ docsData.departmentName }}
              </td>
              <td style="text-align: center">
                {{ docsData.effectiveDate | date: "MM/dd/yyyy" }}
              </td>
              <td style="text-align: center">
                {{ docsData.expirationDate | date: "MM/dd/yyyy" }}
              </td>
              <td style="text-align: left; padding-left: 15px">
                {{ docsData.status }}
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <br />
      <span>
        <p-dialog header="Approve Document" [(visible)]="approvedisplay" modal="modal" showEffect="fade"
          [style]="{ width: '60%' }">
          <hr />
          <div class="p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12" style="text-align: left">
              <label for="AprDescription">Comments:</label>
              <textarea [(ngModel)]="AprDescription" id="AprDescription" type="text" rows="4" [maxLength]="1000"
                pInputTextarea></textarea>
            </div>

            <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
              <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                <button pButton type="button" label="Continue" icon="pi pi-check" iconPos="left"
                  (click)="ApproveDocument()" class="p-button-raised p-button-success"
                  style="margin-top: -5px; margin-bottom: -5px"></button>
              </span>
            </div>
            <small class="p-invalid" style="float: right">{{
              responseError
              }}</small>
          </div>
        </p-dialog>
      </span>
      <span>
        <p-dialog header="Reject Document" [(visible)]="rejectdisplay" modal="modal" showEffect="fade"
          [style]="{ width: '60%' }">
          <hr />
          <div class="p-field p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12" style="text-align: left">
              <label for="RejectDescription">Are you sure you want to reject the document ? please specify
                the reason.</label>
              <textarea [(ngModel)]="RejectDescription" id="RejectDescription" type="text" rows="4" [maxLength]="1000"
                pInputTextarea></textarea>
            </div>
            <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
              <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                <button pButton type="button" label="Continue" icon="pi pi-times" iconPos="left"
                  (click)="RejectDocument()" class="p-button-raised p-button-danger"
                  style="margin-top: -5px; margin-bottom: -5px"></button>
              </span>
            </div>
            <small class="p-invalid" style="float: right">{{
              responseError
              }}</small>
          </div>
        </p-dialog>
      </span>
      <span>
        <p-dialog header="Hold Document" [(visible)]="clarificationdisplay" modal="modal" showEffect="fade"
          [style]="{ width: '60%' }">
          <hr />
          <div class="p-field p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12" style="text-align: left">
              <label for="Description">Are you sure you want to hold the document ? please specify the
                reason.</label>
              <textarea [(ngModel)]="Description" id="Description" type="text" rows="4" [maxLength]="1000"
                pInputTextarea></textarea>
            </div>
            <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
              <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                <button pButton type="button" label="Continue" icon="pi pi-check" iconPos="left"
                  (click)="HoldDocument()" class="p-button-raised p-button-success"
                  style="margin-top: -5px; margin-bottom: -5px"></button>
              </span>
            </div>
            <small class="p-invalid" style="float: right">{{
              responseError
              }}</small>
          </div>
        </p-dialog>
      </span>
      <span>
        <p-dialog header="Release Document" [(visible)]="releasedisplay" modal="modal" showEffect="fade"
          [style]="{ width: '60%' }">
          <hr />
          <div class="p-field p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12" style="text-align: left">
              <label for="Description">Are you sure you want to release the document ? please specify
                the reason.</label>
              <textarea [(ngModel)]="Description" id="Description" type="text" rows="4" [maxLength]="1000"
                pInputTextarea></textarea>
            </div>
            <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
              <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                <button pButton type="button" label="Continue" icon="pi pi-check" iconPos="left"
                  (click)="ReleaseDocument()" class="p-button-raised p-button-success"
                  style="margin-top: -5px; margin-bottom: -5px"></button>
              </span>
            </div>
            <small class="p-invalid" style="float: right">{{
              responseError
              }}</small>
          </div>
        </p-dialog>
      </span>
      <span>
        <p-dialog header="Withdrawn Document" [(visible)]="withdrawdisplay" modal="modal" showEffect="fade"
          [style]="{ width: '60%' }">
          <hr />
          <div class="p-grid p-fluid">
            <div class="p-field p-col-12" style="text-align: left">
              <label for="RejectDescription">Are you sure you want to withdraw the document ? please specify
                the reason.</label>
              <textarea [(ngModel)]="WithDrawDescription" id="WithDrawDescription" type="text" rows="4"
                [maxLength]="1000" pInputTextarea></textarea>
            </div>
            <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
              <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                <button pButton type="button" label="Continue" icon="pi pi-times" iconPos="left"
                  (click)="WithdrawDocument()" class="p-button-raised p-button-danger"
                  style="margin-top: -5px; margin-bottom: -5px"></button>
              </span>
            </div>
            <small class="p-invalid" style="float: right">{{
              responseError
              }}</small>
          </div>
        </p-dialog>
      </span>
      <br /><br />
      <!-- <small class="p-invalid" style="float:right;" >{{ responseError }}</small> -->
    </div>
  </div>
</div>
<div style="
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 1500;
  " *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>

<span>
  <p-dialog header="ECRN Details" [(visible)]="ECRNDisplay" modal="modal" showEffect="fade" [style]="{ width: '60%' }">
    <hr />
    
  </p-dialog>
</span>
<p-dialog header="Information !" [(visible)]="DepartmentDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}"
  [closable]="false">
  <hr>
  <div class="p-grid p-fluid p-formgrid">

    <div class="p-field p-col-12 p-md-8" style="text-align: center;">
      <span style="font-size:18px;">{{DepartmentDisplayText}}</span>
    </div>
    <div class="p-field p-col-12 p-md-2">
      <!-- <span style="float: right;"> -->
      <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Yes"
        class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="DepartmentsYesClick()"></button>
    </div>
    <div class="p-field p-col-12 p-md-2">
      <button pButton pRipple type="button" icon="pi pi-times" iconPos="left" label="No"
        class="p-button-danger p-mr-2 p-mb-2 p-ripple p-button p-component" (click)="DepartmentNOClick()"></button>
      <!-- </span> -->
    </div>
  </div>
</p-dialog>

<p-dialog header="Products" [(visible)]="projectdisplay" modal="modal" showEffect="fade" >
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-6 p-xl-12">
          <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:250px;">
              <ul class="widget-image-list">
              <li *ngFor="let k of mulproducts">
                  <span>{{ k.productName }}</span>
              </li>
              </ul>
          </div>
      </div>
  </div>
</p-dialog>


<p-dialog header="Originator Changes" [(visible)]="OriginatorDisplay" modal="modal" showEffect="fade" [style]="{width:'80%'}" >
  <hr>
  <div class="p-grid">
      <div class="p-col-12 p-md-12 p-xl-12">
          <p-table #dt [value]="Reasons" [columns]="cols" sortMode="multiple" [autoLayout]="true"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" [(selection)]="selectedReason"> 
              <ng-template pTemplate="header" let-columns class="invoice-items">
                  <tr><th style="text-align: center; width:50px;">S.No</th>
                  <th style="text-align:center;" *ngFor="let col of columns" [pSortableColumn]="col.field"
                  [ngClass]="{'width100': i != 4,'width50' : i == 4}"
                  >{{ col.header }}<p-sortIcon [field]="col.field"
                      style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-j="rowIndex" >
                  <tr [pSelectableRow]="rowData" >
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                      <td *ngIf="i == 0" style="text-align: center;width: 50px;;">{{ j + 1 }}</td>
                      <td *ngIf="i == 0" style="text-align: center;width: 100px;">{{ rowData[col.field] }}</td>
                      <td *ngIf="i == 1" style="text-align: center;width: 100px;">{{ rowData[col.field] | date:'MM/dd/yyyy'}}</td>
                      <td *ngIf="i == 2" style="text-align: center;width: 100px;">{{ rowData[col.field] }}</td>
                      <td *ngIf="i == 3" style="text-align: center;width: 100px;">{{ rowData[col.field] }}</td>
                      <td *ngIf="i == 4" style="text-align: left; padding-left:15px;width: 50%;">{{ rowData[col.field] }}</td>

                  </ng-container>
                  </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr><td colspan="6">No records found...</td></tr>
              </ng-template>
          </p-table>
      </div>
  </div>              
</p-dialog>

<p-dialog header="Access" [(visible)]="DownloadInfoDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}" >
  <hr>
  <div class="p-grid">
 
  <div class="p-col-12" style="text-align: center;">
   <span style="font-size:18px;">User doesn't have permission to perform the operation</span>
  </div>
  </div>
  </p-dialog>