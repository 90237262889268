import { Component, OnInit, ViewChild } from '@angular/core';
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { workflowByStatusModel } from '../../models/workflowByStatusModel';
import { FacadeService } from '../../facade/facade.service';
import { holddocumentsModel } from 'src/app/models/holddocuments_model';
import { approvalNeededModel } from 'src/app/models/approvalneeded_model';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import * as XLSX from 'xlsx';
import { PrintService } from "../../services/print.service";

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.scss']
})
export class WorkflowsComponent implements OnInit {
  constructor(
    private _facadeService: FacadeService,public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  userId = parseInt(sessionStorage.getItem('userId'));
  workflowData: workflowByStatusModel[];
  CompletedWorkflowMode: any;
  CurrentWorkflowMode: any;
  //documentsName: string = 'New Documents';
  status = sessionStorage.getItem('WorkflowStatus');
  cols: any[];
  showLoading:boolean;
  dynamicPlaceHolder: string;

  @ViewChild('dt') table: Table;

  ngOnInit(): void {

    if(this.status==""||this.status==undefined||this.status==null){
      this.status = 'Executing';
    }
    //this.getWorkflowsByStatus(this.status);

    this.cols = [
      { field: 'name', header: 'Workflow Name', dynamicPlaceHolder: 'Search' },
      { field: 'status', header: 'Workflow Status', dynamicPlaceHolder: 'Search' },
      { field: 'createdAt', header: 'Performed At', dynamicPlaceHolder: 'Search' },
    ];
  }  

  // getWorkflowsByStatus(status) {
  //   sessionStorage.status = status;
  //   this.showLoading = true;
  //   if (status == 'Executing') {
  //     this.status = 'Executing';
  //   }
  //   if (status == 'Failed') {
  //     this.status = 'Failed';
  //   }
  //   this._facadeService.GetWorkflowsByStatus(this.status).subscribe(
  //     (data) => {
  //       this.showLoading = false;
  //       console.log(data);
  //       this.workflowData=data.returnObject;
  //     },
  //     (error) => {
  //       this.showLoading = false;
  //       console.log(error);
  //     }
  //   );
  // }

  exportExcel() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Workflow " + this.status + ".xlsx");
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
      }
  }
  range.e.r--
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,"Workflow " + this.status ,'trSearch',false);
}
}
