<div class="p-grid p-fluid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
                  <div class="p-grid p-fluid p-formgrid" >
                    <div class="p-field p-col-12 p-lg-5 p-md-12 p-sm-12">
                      <h5 style="padding-right:10px;float: left;margin-top: 5px;">{{Label}}</h5>
                    </div>
                    <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
                    <label style="padding-right:10px;margin-top: 10px;margin-left: 35px;">Status:</label>
                    </div>
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
            <p-multiSelect [options]="status" [(ngModel)]="selectedStatus"  placeholder="Select Status"></p-multiSelect>
          
          </div>
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
              <button pButton pRipple type="button" icon="pi pi-search" iconPos="left"
                label="Search" class="p-button-raised p-button-primary" (click)="mydocumentsData()"
                style="width:100%; margin-left:10px;"></button>
          </div>
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <a *ngIf="CreateNewDocSH" routerLink="/createnewdoc"> <button pButton type="button" label="Create New Doc"
              iconPos="left" class="p-button-raised p-button-primary " icon="pi pi-plus"
              style="margin-left:5px ; height:35px;"></button> </a>
           <!-- </span> -->
          </div>
        </div>

      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">

        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">{{ documentsName }} - {{DocumentsCount}}</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">

          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
            (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right;height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
            (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;"></button>

        </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content" >
        <p-table  (sortFunction)="customSort($event)" [customSort]="true"
         #dt [value]="docsData" id="excel-table" [columns]="cols" class="table-align" 
         [scrollable]="true" scrollHeight="380px" [virtualScroll]="true"
          [filterDelay]="0"  dataKey="documentId" styleClass="p-datatable-striped"
          [globalFilterFields]="[ 'code', 'revision','eco','ecrn','title', 'departmentName', 'owner', 'status']">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
              [ngClass]="{'docid': i == 0 || i==8,'rev': i == 1,'eco': i == 2,'ecrn': i == 3,'title': i == 4,'departmentName': i == 5,
              'owner': i == 6, 'status': i == 7}">  
              {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="text-align: center; width:60px;">Actions</th>
            </tr>
            <tr id="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0;width: 90%; height:25px;" />
              </th>
              <th></th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                  <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                  <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                </td>
                <td *ngIf="i == 1" style="text-align: left;padding-left:15px; width:60px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 2" style="text-align: left;padding-left:15px; width:60px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 3" style="text-align: left; padding-left:15px; width:120px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 4" style="text-align: left; padding-left:25px;width:390px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 5" style="text-align: left; padding-left:15px;width: 180px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 6" style="text-align: left; padding-left:15px;width: 110px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 7" style="text-align: center;font-weight: bold;width: 120px;"
                [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 8 && selectedStatus != 'New' && selectedStatus != 'In-Process' && selectedStatus != 'Obsolete In-Process'" style="text-align: center;width: 100px;">
                  <span>{{rowData[col.field]}}</span>
                </td>
              </ng-container>
              <td style="text-align: center;width:60px">
                <button pButton type="button" (click)="
                            viewDocDetails(docsData.id, docsData.wfDefinitionId)
                          " icon="pi pi-eye" pTooltip="View" class="p-button-rasised p-button-primary"
                  style="height:25px; width:25px;"></button>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="10" style="text-align: left;" *ngIf="selectedStatus != 'New' && selectedStatus != 'In-Process' && selectedStatus != 'Obsolete In-Process'">No documents found.</td>
              <td colspan="9" style="text-align: left;" *ngIf="selectedStatus == 'New' || selectedStatus == 'In-Process' && selectedStatus != 'Obsolete In-Process'">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>