import { Injectable } from '@angular/core';
import { HttputilityService } from '../services/httputility.service';
@Injectable({
  providedIn: 'root'
})
export class EcoService {
  
  constructor(private _httputilityService: HttputilityService) {}
  //baseUrl: any = 'http://132.148.242.138/FPDMS/api/';

  getDocsTypes() {
    return this._httputilityService.Get('GetDocumentTypes');
  }

  getDepartments(){
  return this._httputilityService.GetU('user/GetDepartments'); 
  }

  getStandardClauses(modelArray) {
    return this._httputilityService.Add('GetClauses',modelArray);
  }
  
  workFlowsList(model) {
    return this._httputilityService.Add('GetWorkflows', model);
  }

  getECODocuments(did, uid) {
    return this._httputilityService.Get('GetECODocuments?docId=' + did + '&userId=' + uid);
  }
}
