import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrls: ['./new-profile.component.scss']
})
export class NewProfileComponent implements OnInit {

  selectedDocType: any;
  selectedDepartment: any;
  selectedRole: any;
  rolesData: any;

  workFlowModel: any;
  workFlowData: any;
  docTypeError: any;
  docsTypesData: any;
  docTypes = [];
  docTypeResult = [];
  departments = [];
  departmentsResult = [];
  roles = [];
  rolesResult = [];
  showLoading:boolean;
  departmentObject: any;
  public departmentArray: any[] = [];

  rolesObject: any;
  public rolesArray: any[] = [];

  profileForm = {
    profileName: '',
    noOfApprovals: '',
  };

  constructor(
    private facadeService: FacadeService, 
    private router: Router) { }

  ngOnInit(): void {
    this.getDocsTypes();
    this.getDepartments();
  }

  getDocsTypes() {
    this.facadeService.getDocsTypes().subscribe(
      (data) => {
        this.docsTypesData = data.returnObject;
        console.log("Document types ::" + JSON.stringify(this.docsTypesData));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getDepartments() {
    this.facadeService.getDepartMents().subscribe((response: any) => {
      this.departments = response.returnObject;
      
      console.log("Departments ::" + JSON.stringify(this.departments));
    });
  }

  getRolesByDepartmentGuid(departmentGuid) {
    this.facadeService
      .getRolesByDepartmentGuid(departmentGuid)
      .subscribe((result: any) => {
        this.roles = result.returnObject;
        console.log(this.roles);
      });
  }
  
  // getRolesByDepartmentGuid() {
  //   console.log(this.selectedDocType);
  //   this.facadeService
  //     .getRolesByDepartmentGuid(this.selectedDepartment.departmentGuid)
  //     .subscribe((result: any) => {
  //       this.roles = result.returnObject;
  //       console.log(this.roles);
  //     });
  // }
/*
  selectDepartment(department) {
    this.departmentArray.push(department);
    console.log("SElected departments ::" + JSON.stringify(this.departmentObject));

    this.getRolesByDepartmentGuids(this.departmentArray);
  }

  getRolesByDepartmentGuids(departmentGuids) {
    this.facadeService
      .getRolesByDepartmentGuids(departmentGuids)
      .subscribe((result: any) => {
        this.roles = result.returnObject;
        console.log(this.roles);
      });
  }
*/
  getWorkFlowsList(selectedDoc) {
    this.workFlowModel = {
      documentTypeId: selectedDoc.documentTypeId,
    };
    this.facadeService.workFlowsList(this.workFlowModel).subscribe(
      (data) => {
        this.workFlowData = data.returnObject;
        //console.log("Inner WF ::" + JSON.stringify( this.workFlowData));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  createProfile() {
    var profile: any = new FormData();
    profile.append('ProfileId ', 0);
    profile.append('profileCode ', this.profileForm.profileName);
    profile.append('DocumentTypeId', this.selectedDocType.documentTypeId);
    profile.append('DepartmentId ', this.selectedDepartment.departmentId);
    profile.append('DepartmentGuid', this.selectedDepartment.departmentGuid);
    profile.append('RoleId', this.selectedRole.roleId);
    profile.append('RoleGuid', this.selectedRole.roleGuid);
    profile.append('ApprovalNumbers', this.profileForm.noOfApprovals);

    this.facadeService.createProfile(profile).subscribe((response) => {
      console.log(response);
      this.router.navigate(['workflow/wfprofile']);
    });
  }


}
