import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class ApprovalsNeededService {
  constructor(private _httputilityService: HttputilityService) {}

  approvalNeededData(model) {
    return this._httputilityService.Add("MyApprovals", model);
  }
  DelegatedDocuments(userId){
    return this._httputilityService.Get("DelegatedDocuments?UID="+userId);
  }
  approvalNeededDataRPT(elapsedDays,timeZone,userId) {
    return this._httputilityService.Get("RptDocumentsForApproval?days="+ elapsedDays+"&timezone="+timeZone+"&userId="+userId);
  }

  originatedByStatus(model) {
    return this._httputilityService.Add("RptDocSummaryByStatus", model);
  }

  documentByStatus(model) {
    return this._httputilityService.Add("RptDocumentsByStatus", model);
  }

  //documentByProject(userguid){
  //  return this._httputilityService.Get("RptAltDocumentsByProject?productGuids="+userguid);
  //}
  documentByProject(projectGuids,timeZone,userId){
    return this._httputilityService.Add("RptDocumentsByProject?timezone="+timeZone+"&userId="+userId, projectGuids);
  }
  
  altDocumentByProject(projectGuids,timeZone,userId){
    return this._httputilityService.Add("RptAltDocumentsByProject?timezone="+timeZone+"&userId="+userId, projectGuids);
  }
  
  approvalDelegatedData(userId) {
    return this._httputilityService.Get('MyDelegatedApprovals?UID=' + userId);
  }

}
