<div class="p-grid">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <div class="card" style="min-height:600px;">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
              <a> <button pButton pRipple type="button" icon="pi pi-chevron-left" label="Back" (click)="BackClick()"
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width:auto;"></button></a>
            </div>
            <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12" style="text-align:center;">
              <h4 style="margin-top:5px;">Obsolete Document</h4> 
            </div>
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
          </div>

          <hr />
          <br>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <label>Title</label>
              <input type="text" pInputText [(ngModel)]="title" placeholder="Enter Title" disabled/>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>Department</label>
              <p-dropdown [options]="departmentsData" [(ngModel)]="department" placeholder="departmentsData" optionLabel="departmentName"
               [disabled]="true"></p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>Current Revision</label>
              <input type="text" pInputText [disabled]="true" [(ngModel)]="revision"placeholder="Enter Revision" />
            </div>
            <!-- <div class="p-field p-col-12 p-md-6">
              <label>Description</label>
              <textarea [(ngModel)]="Description" id="Description" type="text" rows="2" pInputTextarea [maxLength]="300" disabled
               ></textarea>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label>Remarks</label>
              <textarea id="Remarks" type="text" rows="2" [(ngModel)]="Remarks" disabled pInputTextarea ></textarea>
            </div> -->
            <div class="p-field p-col-12 p-md-3">
              <label>Alt Doc Id</label>
              <input type="text" pInputText [(ngModel)]="alternatedDocId" placeholder="Alt Doc Id" disabled />
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>Effective Date</label>
              <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate" [(ngModel)]="effectiveDate" disabled></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>Next Review Date</label>
              <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate" [(ngModel)]="reviewDate" disabled></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <label>Expiration Date</label>
              <p-calendar [showIcon]="true" inputId="icon" [minDate]="minDate" [(ngModel)]="expirationDate" disabled></p-calendar>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Document Type</label>
              <p-dropdown inputId="documentType" [options]="docsTypesData" [(ngModel)]="selectedDocType"
                placeholder="docsTypesData" optionLabel="documentType" [disabled]="true">
              </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Document ID</label>
              <input type="text" pInputText [disabled]="true" [(ngModel)]="doccode" placeholder="Enter docID"/>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Obsolete Document</label><br>
              <span><a href="javascript:;" (click)="GetDownloadFile()">{{ApprovedFile}}</a></span>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Product</label>
              <p-autoComplete [disabled]="true" [(ngModel)]="selectedProduct" [suggestions]="filteredproduct" field="productName" [dropdown]="true">
                <ng-template>
                  <div>
                    <div>{{ filteredproduct }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
              <small class="p-invalid">{{ productError }}</small>
              <div *ngIf="prodroles" style="float: right;margin-top:3px;">
                <button pButton pRipple type="button" icon="pi pi-eye" iconPos="left"  pTooltip="View Roles"  tooltipPosition="top" 
                class="p-button-raised p-button-primary p-mr-2 p-mb-2" (click)="prodrolesdisplay = true" 
                style="float: right; height: 20px; width: 20px;"></button>
                </div>
                <span>
                      <p-dialog header="Product Roles (Missing team members for below roles)" [(visible)]="prodrolesdisplay" modal="modal"
                      showEffect="fade" [style]="{ width: '60%' }">
                      <hr />
                      <div class="p-grid">
                        <div class="p-col-12 p-md-12" style="text-align: center; margin-top: 8px">
                          <p-table [value]="prodrolesdata" [columns]="prcols" sortMode="multiple" class="table-align"
                            styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple">
                            <ng-template pTemplate="header" let-columns class="invoice-items">
                              <tr>
                                <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field">
                                  {{col.header}}
                                  <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                              <tr [pSelectableRow]="rowData">
                                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                  <td *ngIf="i == 0" style="text-align: left; padding-left:15px;">
                                    {{ rowData[col.field] }}
                                  </td>
                                  <td *ngIf="i == 1" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                  </td>
                                  <td *ngIf="i == 2" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                  </td>
                                  <td *ngIf="i == 3" style="text-align: left;padding-left:15px;">
                                    {{rowData[col.field]}}
                                  </td>
                                </ng-container>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                              <tr>
                                <td colspan="4" style="text-align: left;">No data found.</td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </div>
                    </p-dialog>
                  </span>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <label>Product Line</label>
              <p-autoComplete [disabled]="true" [(ngModel)]="selectedProductLine" [suggestions]="filteredproductline" field="name" [dropdown]="true">
                <ng-template>
                  <div>
                    <div>{{ filteredproductline.name }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <div class="p-field p-col-12 p-md-2" *ngIf="ShowECRN">
              <label>ECN Level</label>
              <p-dropdown [options]="ECNLevelData" [(ngModel)]="selectedECN" placeholder="Select" disabled optionLabel="name"> 
              </p-dropdown>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Workflow<span style="color: red">*</span></label>&nbsp;&nbsp;
              <p-dropdown inputId="workflowDefinitionId" [options]="workFlowData" [(ngModel)]="selectedWorkflow"
                placeholder="Select" optionLabel="workflowDefinitionName">
              </p-dropdown>
              <small class="p-invalid">{{ workFlowError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-2" *ngIf="!ShowECRN"></div>
           
            <!-- <div class="p-field p-col-12 p-md-6" *ngIf="ShowECRN">
              <label>Description Of Change<span style="color: red">*</span></label>
              <textarea [(ngModel)]="ECRNDescription" type="text" rows="2" [maxLength]="300"
                pInputTextarea></textarea>
                <small class="p-invalid">{{ ECRNDescriptionError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-6" *ngIf="ShowECRN">
              <label>Reasons For Change<span style="color: red">*</span></label>
              <textarea [(ngModel)]="ECRNReason" type="text" rows="2" [maxLength]="300"
                pInputTextarea></textarea>
                <small class="p-invalid">{{ ECRNReasonError }}</small>
            </div> -->
          <div class="p-field p-col-12 p-md-3" *ngIf="ShowECRN">
            <label>ECRN Date</label>
            <p-calendar [showIcon]="true" inputId="icon" [disabled]="true" [minDate]="minDate" [(ngModel)]="ECRNDate"
             [disabled]="true"></p-calendar>
          </div>
          <div class="p-field p-col-12 p-md-3" *ngIf="ShowECRN">
            <label>ECRN</label>
            <input type="text" pInputText [(ngModel)]="selectedECRN" placeholder="ECRN" disabled/>
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label>Reasons For Change<span style="color: red">*</span></label>
            <textarea type="text" rows="2" [(ngModel)]="obsComments" [maxLength]="1000" pInputTextarea></textarea>
          </div>
          <div class="p-field p-col-12 p-md-6" *ngIf="!ShowECRN"></div>
          <div class="p-field p-col-12 p-md-8"></div>
          <div class="p-field p-col-12 p-md-4">
            <span style="font-size:18px;"><small class="p-invalid">{{ responseError }}</small></span>
          </div>
          <div class="p-field p-col-12">
              <hr>
          <div class="p-grid">

             <div class="p-field p-col-12 p-lg-9 p-md-12 p-sm-12"></div>
              <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
                <br />
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left"
                  label="Send for Obsolete" class="p-button-raised p-button-success p-mr-2 p-mb-2"
                  (click)="createDoc('sendforapproval',false)" style="float:right; width:100%"></button>
              </div>
                <span>
                  <p-dialog header="Send for Obsolete" [(visible)]="saveAndSendApprovalDisplay" modal="modal"
                    showEffect="fade" [style]="{ width: '60%' }">
                    <hr />
                    <div class="p-grid">
                      <div class="p-col-12 p-md-8" style="text-align: center; margin-top: 8px">
                        <h5>{{ AreYouSureText }}</h5>
                      </div>
                      <div class="p-col-12 p-md-2">
                        <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                          <button pButton type="button" label="Yes" icon="pi pi-check" iconPos="left"
                            (click)="createDoc('confirm',false)" class="p-button-raised p-button-success"></button>
                        </span>
                      </div>
                      <div class="p-col-12 p-md-2">
                        <span class="p-input-icon-left" style="margin-right: 10px; margin-top: 8px">
                          <button pButton type="button" label="No" icon="pi pi-times" iconPos="left"
                            (click)="saveAndSendApprovalDisplay = false"
                            class="p-button-raised p-button-secondary"></button>
                        </span>
                      </div>
                    </div>
                  </p-dialog>
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>