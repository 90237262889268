import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-productfolderstructure',
  templateUrl: './productfolderstructure.component.html',
  styleUrls: ['./productfolderstructure.component.scss']
})
export class ProductfolderstructureComponent implements OnInit {
  cols=[];
  showLoading:boolean;
  files= [];
  addDisplay:boolean;
  Id=0;
  name="";
  nameError="";
  description="";
  active=true;
  responseMessage="";
  TextLabel="Save";
  isdisabled=true;
  textlabelheader="Add Product";
  ProductFolderStructureId=0;
  count = 0;
  mainData=[];
  level: number;
  subsubClick=false;
  SubFolderId=0;
  userFeature:boolean;
  constructor( private _facadeService: FacadeService,) { }

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="PRODUCT_FLDR");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'subcount', header: 'Level Folder Count' },
      { field: 'active', header: 'Active' }
  ];
  this.GetProductFolderStructure();
  }
  AddNewButton(){
    this.addDisplay=true;
    this.TextLabel="Save";
    this.textlabelheader="Add product";
    this.name="";
    this.description="";
    this.nameError="";
    this.active=true;
    this.isdisabled=true;
    this.Id=0;
    this.responseMessage="";
  }
  SaveUpdateProduct(){
    this.nameError="";
    this.responseMessage="";
    if(this.name==""||this.name==null||this.name==undefined){
      this.nameError="Please enter name";
    }
    else{
      var model={
        id:this.Id,
        Name:this.name,
        Description:this.description,
        active:this.active,
        productFolderStructureId:this.ProductFolderStructureId,
        SubFolderId:this.SubFolderId
      }
      if(this.subsubClick){
        this.showLoading = true;
        this._facadeService.CreateUpdateSubSubProductFolderStructure(model).subscribe(
          (data) => {
            if (data.returnCode == 0) {
              location.reload();
            }
            else {
              this.showLoading = false;
              this.responseMessage = data.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
     else if(this.ProductFolderStructureId>0){
        this.showLoading = true;
        this._facadeService.CreateUpdateSubProductFolderStructure(model).subscribe(
          (data) => {
            if(data.returnCode==0){
              location.reload();
            }
            else {
              this.showLoading = false;
                  this.responseMessage = data.returnMessage;
                }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else{
        this.showLoading = true;
        this._facadeService.CreateUpdateProductFolderStructure(model).subscribe(
          (data) => {
            if(data.returnCode==0){
              location.reload();
            }
            else {
              this.showLoading = false;
                  this.responseMessage = data.returnMessage;
                }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
   
  }
  GetProductFolderStructure(){
    this.showLoading = true;
    this._facadeService.GetProductFolderStructure().subscribe(
      (data) => {
        this.showLoading = false;
         this.files=data.returnObject;
         this.count = this.files.length;
         this.files.forEach(element => {
          element.expanded = false;
          element.data.active=element.data.active==true?"Y":"N";
          element.data.subcount = 0;
          if(element.children!=null&&element.children!=undefined&&element.children.length>0){
            element.data.subcount = element.children.length;
            element.children.forEach(element1 => {
              element1.expanded = false;
              element1.data.active=element1.data.active==true?"Y":"N";
              if(element1.children!=null&&element1.children!=undefined&&element1.children.length>0){
                element1.data.subcount = element1.children.length;
                element1.children.forEach(element2 => {
                  element2.expanded = false;
                  element2.data.active = element2.data.active == true ? "Y" : "N";
                });
              }
            });
          }
         });
         this.mainData = this.files;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  AddSubProduct(rowData){
    this.SubFolderId=0;
    this.subsubClick=false;
    this.addDisplay=true;
    this.TextLabel="Save";
    this.textlabelheader="Add " + rowData.name + " Sub";
    this.name="";
    this.description="";
    this.nameError="";
    this.active=true;
    this.isdisabled=true;
    this.Id=0;
    this.responseMessage="";
    if(rowData.id>0&&rowData.folderStructureId>0){
      this.subsubClick=true;
      this.ProductFolderStructureId = rowData.folderStructureId;
      this.SubFolderId=rowData.id;
    }
    else{
      this.subsubClick=false;
      this.ProductFolderStructureId = rowData.id;
      this.SubFolderId=0;
    }
   
    this.addDisplay = true;
  }
  EditProduct(rowData,level){
    this.TextLabel="Update";
    if(this.ProductFolderStructureId>0)
      this.textlabelheader="Edit Product";
    else
      this.textlabelheader="Edit " + rowData.name + " Sub";
    this.name="";
    this.description="";
    this.nameError="";
    this.active=true;
    this.isdisabled=false;
    this.Id=rowData.id;
    this.ProductFolderStructureId=rowData.folderStructureId;
    this.responseMessage="";
    this.showLoading = true;
    if(level==2){
      this._facadeService.GetSubSubProductFolderStructureDetailsById(this.Id).subscribe(
        (data) => {
          this.showLoading = false;
          var prod=data.returnObject;
          this.name=prod.name;
          this.description=prod.description;
          this.active=prod.active;
          this.Id=prod.id;
          this.ProductFolderStructureId=prod.productFolderStructureId;
          this.addDisplay=true;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      if(this.ProductFolderStructureId>0){
        this._facadeService.GetSubProductFolderStructureDetailsById(this.Id).subscribe(
          (data) => {
            this.showLoading = false;
            var prod=data.returnObject;
            this.name=prod.name;
            this.description=prod.description;
            this.active=prod.active;
            this.Id=prod.id;
            this.ProductFolderStructureId=prod.productFolderStructureId;
            this.addDisplay=true;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else{
        this._facadeService.GetProductFolderStructureDetailsById(this.Id).subscribe(
          (data) => {
            this.showLoading = false;
            var prod=data.returnObject;
            this.name=prod.name;
            this.description=prod.description;
            this.active=prod.active;
            this.Id=prod.id;
            this.ProductFolderStructureId=prod.productFolderStructureId;
            this.addDisplay=true;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
    
  }

  expandAll() {
    this.showLoading = true;
    this.files = [];
    this.mainData.forEach(element => {
      element.expanded = true;
      element.data.subcount = 0;
      if (element.children != null && element.children != undefined && element.children.length > 0) {
        element.data.subcount = element.children.length;
        element.children.forEach(element1 => {
          element1.expanded = true;
          if(element1.children!=null&&element1.children!=undefined&&element1.children.length>0){
            element1.data.subcount = element1.children.length;
            element1.children.forEach(element2 => {
              element2.expanded = true;
            });
          }
        });
      }
      this.files.push(element);
    });
    this.showLoading = false;
  }

  collapseAll() {
    this.showLoading = true;
    this.files = [];
    this.mainData.forEach(element => {
      element.expanded = false;
      element.data.subcount = 0;
      if (element.children != null && element.children != undefined && element.children.length > 0) {
        element.data.subcount = element.children.length;
        element.children.forEach(element1 => {
          element1.expanded = false;
          if(element1.children!=null&&element1.children!=undefined&&element1.children.length>0){
            element1.data.subcount = element1.children.length;
            element1.children.forEach(element2 => {
              element2.expanded = false;
            });
          }
        });
      }
      this.files.push(element);
    });
    this.showLoading = false;
  }
}
