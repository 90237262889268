import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-entrypoint',
  templateUrl: './entrypoint.component.html',
  styleUrls: ['./entrypoint.component.scss']
})
export class EntrypointComponent implements OnInit {
  constructor(private route: Router, private router: ActivatedRoute, private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }
  showLoading: boolean;
  ngOnInit(): void {
    var guid = this.router.snapshot.queryParamMap.get('userguid');
    var userId = this.router.snapshot.queryParamMap.get('userId');
    var IsDCCQuery = this.router.snapshot.queryParamMap.get('IsDCC');
    var token = this.router.snapshot.queryParamMap.get('access_token');
    var destination = this.router.snapshot.queryParamMap.get('destination');
    var docId = this.router.snapshot.queryParamMap.get('docId');
    var userIdint = parseInt(userId);
    var ECRN = this.router.snapshot.queryParamMap.get('ECRN');
    var CMSId = this.router.snapshot.queryParamMap.get("CMSId");
    var CMSType = this.router.snapshot.queryParamMap.get("CMSType");
    var S = this.router.snapshot.queryParamMap.get("S");
    var ShowMenu = this.router.snapshot.queryParamMap.get("ShowMenu");
    var DocCMSstatus = this.router.snapshot.queryParamMap.get("DocCMSstatus");
    var action = this.router.snapshot.queryParamMap.get("action");
    var bearer_token = this.router.snapshot.queryParamMap.get('bt');
    var appliId = this.router.snapshot.queryParamMap.get('appliId');
    var clientId = this.router.snapshot.queryParamMap.get('clientId');
    sessionStorage.isFrom = "ApprovalNeeded";
    sessionStorage.StatusDoc = "In-Process";
    sessionStorage.userId = userIdint;
    sessionStorage.bt = bearer_token;
    sessionStorage.userGuid = guid;
    sessionStorage.IsDCC = IsDCCQuery;
    sessionStorage.appliId = appliId;
    sessionStorage.clientId = clientId;
    sessionStorage.setItem("access_token", token);
    this.showLoading = true;
    this._facadeService.UpdateSecurityToken(token).subscribe(
      (data) => {
        if (data.returnObject) {
          this.showLoading = false;
          if (DocCMSstatus != null && DocCMSstatus != "" && DocCMSstatus != undefined) {
            sessionStorage.StatusDoc = "In-Process";
            sessionStorage.isFrom = "";
          }
          if (destination == "docdetails") {
            if (S == "CMS") {
              location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'docdetails?docId=' + docId + '&CMSId=' + CMSId + '&S=' + S + '&CMSType=' + CMSType + '&ShowMenu=' + ShowMenu;
            }
            else {
              sessionStorage.docId = docId;
              if (action == "view") {
                location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'docdetails?action=' + action;
              }
              else {
                this.route.navigate(["/docdetails"]);
              }
            }
          }
          else if(destination=="docdetailsdownload"){
            location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'docdetails?docId=' + docId + '&CMSId=' + CMSId + '&S=' + S + '&CMSType=' + CMSType + '&ShowMenu=' + ShowMenu+"&downloaddirectly=true";
          }
          else if (destination == "approvalsneeded") {
            this.route.navigate(['/approvalsneeded', { status: 'In-Process' }]);
          }
          else if (destination == "publishedneeded") {
            this.route.navigate(['/publishedneeded', { status: 'In-Process' }]);
          }
          else if (destination == "delegatedtome") {
            this.route.navigate(['/approvalsneeded', { status: 'In-Process', tabIndex: 2 }]);
          }
          else {
            this.route.navigate(["/dashboard", { IsDCC: IsDCCQuery }]);
          }
        }
        else {
          location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
