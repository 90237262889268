import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { approvalNeededModel } from '../../../models/approvalneeded_model';

import * as _ from "lodash";
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { FacadeService } from 'src/app/facade/facade.service';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../../services/print.service";
@Component({
  selector: 'app-alt-docid-report',
  templateUrl: './alt-docid-report.component.html',
  styleUrls: ['./alt-docid-report.component.scss'],
  styles: [`:host ::ng-deep .p-multiselect {
		min-width: 15rem;
	}
	:host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}
	:host ::ng-deep .multiselect-custom .p-multiselect-label {
		padding-top: .25rem;
		padding-bottom: .25rem;

	}
	:host ::ng-deep .multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}
	:host ::ng-deep .multiselect-custom .country-item.country-item-value img.flag {
		width: 17px;
	}
	:host ::ng-deep .multiselect-custom .country-item {
		display: flex;
		align-items: center;
	}
	:host ::ng-deep .multiselect-custom .country-item img.flag {
		width: 18px;
		margin-right: .5rem;
	}
	:host ::ng-deep .multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}
	:host ::ng-deep .p-colorpicker {
		width: 2.5em
	}`]
})
export class AltDocidReportComponent implements OnInit {
  projects: any;
  selecteprojectArray: any[] = [];
  Prjarray: string;
  selectedStatusChecked: any[];
  docum: any[];
  isFrom: any;
  DocumentsCount=0;
  constructor( private _facadeService: FacadeService, public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router,    
    private route:ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private cdr: ChangeDetectorRef) { }
    userId = sessionStorage.getItem('userId')!=null?parseInt(sessionStorage.getItem('userId')):0;
    userGuid = sessionStorage.getItem('userGuid');
    projectError:string;
    docsData: approvalNeededModel[];
    cols: any[];
    @ViewChild("dt") table: Table;
    dynamicPlaceHolder: string;
    showLoading:boolean;
    source:any;
    status:any;
    selectedStatus:any;
    selectedProjects:any;
    EmailDisplay:boolean;
    isDisabled:boolean=true;
  ngOnInit(): void {
    this.source = '';  
    this.isFrom = this.route.snapshot.paramMap.get('isFrom');
    this.source = this.route.snapshot.paramMap.get('status');
    console.log('this.source-----', this.source); 
    this.Prjarray = "";
    if(this.isFrom === 'docdetails'){
      this.selectedProjects = JSON.parse(sessionStorage.selectedProjects);
      this.selecteprojectArray=[];
      this.selectedProjects.forEach((value, key) => {
      this.selecteprojectArray.push(value.productGuid);
      });
      this.searchProject();
      console.log('docdetails =====',this.selecteprojectArray);
    }
        if(this.baseUrl["API_ENDPOINTS"]["ISPDMFlagActive"]){
        this.getProjects();
        }
        this.cols = [
          // { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'Search by ECRN' }, 
          { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Doc Id' },
          { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev'},
          { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by Title' },         
          { field: 'alternateDocID', header: 'Alt-Doc Id', dynamicPlaceHolder: 'Search by Alt-Doc Id' },          
          { field: 'project', header: 'Project', dynamicPlaceHolder: 'Search by Project'},        
          { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Search by Originator'},         
          { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by Status' },
        ];
        sessionStorage.ecrn = "";
      sessionStorage.code ="";
      sessionStorage.revision = "";
      sessionStorage.eco = "";
      sessionStorage.alternateDocID ="";
      sessionStorage.project ="";
      sessionStorage.title ="";
      sessionStorage.departmentName = "";
      sessionStorage.owner = "";
      sessionStorage.status = "";
  }

  selectedStatusChange(selectedStatus){
    this.selecteprojectArray=[];
    selectedStatus.value.forEach((value, key) => {
      this.selecteprojectArray.push(value.productGuid);
    });
  }

  searchProject(){
    this.projectError = '';
    if(this.selectedProjects!== null && this.selectedProjects !== undefined && this.selectedProjects.length > 0){
    this.showLoading = true;
    console.log('selectedProjects ======',this.selectedProjects);
    
    if(this.selectedProjects !== null && this.selectedProjects !== undefined){
    if (this.selectedProjects.length > 0) {
      sessionStorage.setItem('selectedProjects', JSON.stringify(this.selectedProjects));
    this._facadeService.altDocumentByProject(this.selecteprojectArray,sessionStorage.getItem('timeZone'),this.userId).subscribe(
      (data) => {
        this.showLoading = false;
        this.docsData = data.returnObject;
        if(this.docsData!=null && this.docsData.length>0){
          this.docsData.forEach(element => {
            if(element.ecrn === null || element.ecrn === undefined || element.ecrn === ""){
              element.ecrn = 'N/A';
            }
            else{
              element.ecrn = element.ecrn
            }
            if(element.alternateDocID !== null && element.alternateDocID !== 'null' && element.alternateDocID !== undefined)
            {
            }
            else
              element.alternateDocID = '';
          });
          this.isDisabled=false;
        }
        else{
          this.isDisabled=true;
        }
        console.log('-----------',this.docsData);
        this.CountData();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    
  }
}
else{
  this.showLoading = false;
}
}
else{
    this.projectError ='select atleast one project';
}
  }

getProjects() { 
  this.showLoading = true;
    var userDepartments = JSON.parse(sessionStorage.getItem("userdepts"));
    var salesexist = userDepartments.find(m=>m.name=="Sales");
    if(userDepartments.length==1&&salesexist!=null){
      this._facadeService.getProjects(this.userGuid).subscribe(
        (response: any) => {
          this.projects = response.returnObject;
          this.showLoading = false;
          console.log("Projects ::" + JSON.stringify(this.projects));
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      this._facadeService.getAllActiveProducts().subscribe(
        (response: any) => {
          this.projects = response.returnObject;
          this.showLoading = false;
          console.log("Projects ::" + JSON.stringify(this.projects));
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
}
test(val,field,dt)
{
  if(field == "ecrn")
    sessionStorage.ecrn = val;
  else if(field == "code")
    sessionStorage.code = val;
  else if(field == "revision")
    sessionStorage.revision = val;
  else if(field == "title")
    sessionStorage.title = val;
  else if(field == "project")
    sessionStorage.project = val;
  else if(field == "alternateDocID")
    sessionStorage.alternateDocID = val;
  else if(field == "owner")
    sessionStorage.owner = val;
  else if(field == "status")
    sessionStorage.status = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.docsData != undefined && this.docsData != null)
  {
    var temp = this.docsData;
    if(sessionStorage.ecrn != ""&&sessionStorage.ecrn != null)
      temp = temp.filter(x=> x.ecrn!=null&&x.ecrn!=""&&x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
    if(sessionStorage.code != "")
      //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != "")
      temp = temp.filter(x=> x.revision.toString().includes(sessionStorage.revision.toString()));
      if(sessionStorage.project != "")
      temp = temp.filter(x=> x.project.toLowerCase().includes(sessionStorage.project.toLowerCase()));
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
    if(sessionStorage.alternateDocID != "")
      temp = temp.filter(x=> x.alternateDocID.toLowerCase().includes(sessionStorage.alternateDocID.toLowerCase()));
    if(sessionStorage.owner != "")
      temp = temp.filter(x=> x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
    if(sessionStorage.status != "")
      temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
exportExcel() {
  // Manually load all data
  this.table.first = 0;
  this.table.rows = this.table.totalRecords;

  // Wait for data to load (assuming it's loaded asynchronously)
  setTimeout(() => {
    const data = this.getDataFromTable(this.table);

    // Proceed with exporting data to Excel
    this.exportDataToExcel(data);
  }, 100); // Adjust the timeout as needed
}

// Helper function to get visible columns and data from the p-table
getDataFromTable(table: Table): { columns: any[], data: any[] } {
  const visibleColumns = table.columns.filter(column => column.field);
  const columns = visibleColumns.map(column => {
    return {
      header: typeof column.header === 'object' ? column.header['name'] : column.header,
      field: column.field
    };
  });
  // Use the 'filteredValue' property if available, otherwise fallback to 'value'
  const data = (table.filteredValue || table.value).map(item => {
    const rowData = {};
    visibleColumns.forEach(column => {
      const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
      rowData[columnHeader] = item[column.field];
    });
    return rowData;
  });

  return { columns, data };
}

// Example function to export data to Excel (you can customize this based on your needs)
exportDataToExcel(data: { columns: any[], data: any[] }) {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });

  // Add headers separately
  const headerRows = [data.columns.map(column => column.header)];
  XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Customize the column widths if needed
  ws['!cols'] = [{ wpx : 120 },{ wpx :  80},{ wpx : 530 },{ wpx : 180 },{ wpx : 250},{ wpx : 120 },{ wpx : 120 }];
   /* save to file */
   XLSX.writeFile(wb, "Alternative DocId Report.xlsx");
}
// exportExcel() {
//    /* table id is passed over here */   
//    let element = document.getElementById('excel-table'); 
//    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//    /* generate workbook and add the worksheet */
//    const wb: XLSX.WorkBook = XLSX.utils.book_new();
//    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//    this.delete_row(wb.Sheets.Sheet1, 1);
//    ws['!cols'] = [{ wpx : 120 },{ wpx :  80},{ wpx : 530 },{ wpx : 180 },{ wpx : 250},{ wpx : 120 },{ wpx : 120 }];
//    /* save to file */
//    XLSX.writeFile(wb, "Alternative DocId Report.xlsx");
// }
print() {
  
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
  this.printService.Print(Content,"Alternative DocId Report",'trSearch',true);
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['H1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

viewDocDetails(selectedDocId) {
  sessionStorage.docId = selectedDocId.documentId;
  sessionStorage.StatusDoc =selectedDocId.status;
  sessionStorage.isFrom= "altdocid";
  this.router.navigate(['/docdetails']);
}

onActivityChange(event) {
  const value = event.target.value;
  if (value && value.trim().length) {
      const activity = parseInt(value);

      if (!isNaN(activity)) {
          this.table.filter(activity, 'activity', 'gte');
      }
  }
}
SendEmail(){
  if(this.docsData!=null && this.docsData.length>0){
    this.EmailDisplay=true;
    this._facadeService.SendEmail(this.userId,"byalternativedocid",this.docsData).subscribe(
      (response: any) => {
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
