import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { DocdetailsComponent } from '../docdetails/docdetails.component';

@Component({
  selector: 'app-signoffdocument',
  templateUrl: './signoffdocument.component.html',
  styleUrls: ['./signoffdocument.component.scss']
})
export class SignoffdocumentComponent implements OnInit {
  isPubish=false;
  statusMode: string;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private router: Router,private route: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {
    this.form = this.fb.group({
      name: [""],
      avatar: null,
      avatar1:null
    });
  }
  userId = parseInt(sessionStorage.getItem("userId"));
  userGuid = sessionStorage.getItem('userGuid');
  form: FormGroup;
  docId:any;
  title:any="";
  selectedepartment:any="";
  site:any="";
  Description:any="";
  alternatedDocId:any="";
  docType:any="";
  workflow:any="";
  product:any="";
  effectiveDate:any="";
  reviewDate:any="";
  Remarks:any="";
  responseError:any="";
  showLoading:any="";
  fileName :any;
  PrePublishedFileName:any;
    selectedFile:boolean=false;
    FinalselectedFile:boolean=false;
    filesize:any;
    pageTitle:any;
    fileError:any;
    fileError1:any;
    filesizeFinal:any;
    fileNameFinal:any;
    downloadFile:any;
    ApprovedFile:any;
    docsDetailsData:any;
  ngOnInit(): void {
    this.docId =parseInt(sessionStorage.getItem("docId"));
    this.getDocDetailsById();
  }
  signOffDoc(){
    this.fileError="";
    this.fileError1 = "";
   if (!this.isPubish&&(this.fileName == "" ||this.fileName == null||this.fileName == undefined)) {
      this.fileError = "Please chosse file to publish.";
    }
    else{
      var formData: any = new FormData();
      if(!this.isPubish){
        formData.append("NewFileStream", this.form.get("avatar").value);
        formData.append("EditableFileStream",this.form.get("avatar1").value);
      }
      
      formData.append("Id", this.docId);
      formData.append("CreatedBy", this.userId);
      formData.append("Status", "Approved");
      formData.append("UserGuid",this.userGuid);
      
      formData.forEach((value, key) => {
        console.log(key + " " + value);
      });
      this.showLoading = true;
      this._facadeService.SignOffDocument(formData)
      .subscribe(
        (data) => {
          console.log(data);
          var apiResponse:any;
          apiResponse = data;
          if(apiResponse.returnCode >= 0){
            location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'publishedneeded';
          }
          else{
            this.responseError = apiResponse.returnMessage;
          }
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  uploadFile(event) {
    this.fileError ="";
    const file = (event.target as HTMLInputElement).files[0];
    if(file.size > 30000000)
      this.fileError = "File should be less than 30MB.";
    else
    {
      this.fileName = file.name;
      this.selectedFile=true;
      this.filesize = Math.round(file.size / 1024) + " KB";
      this.form.patchValue({
        avatar: file,
      });
      this.form.get("avatar").updateValueAndValidity();
    }
  }
  FinaluploadFile(event) {
    this.fileError1 ="";
    const file = (event.target as HTMLInputElement).files[0];
    if(file.size > 30000000)
      this.fileError1 = "File should be less than 30MB.";
    else
    {
      this.fileNameFinal = file.name;
      this.FinalselectedFile=true;
      this.filesizeFinal = Math.round(file.size / 1024) + " KB";
      this.form.patchValue({
        avatar1: file,
      });
      this.form.get("avatar1").updateValueAndValidity();
    }
  }
  removeFile() {
    this.fileName ="";
    this.selectedFile=false;
    this.filesize="";
}
removeFinalFile(){
  this.fileNameFinal ="";
  this.FinalselectedFile=false;
  this.filesizeFinal="";
}
onUpload(event) {
  const file = (event.target as HTMLInputElement).files[0];
  this.form.patchValue({
    avatar: file,
  });
  this.form.get("avatar").updateValueAndValidity();
}
getDocDetailsById() {
  this.showLoading = true;
  var userFeature = false;
  const userFeatures = sessionStorage.getItem('userFeatures');
  if (userFeatures) {
    var feature = JSON.parse(userFeatures);
    if(feature.length>0){
      var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
      if(featureExist!=null)
        userFeature = true;
    }
  }
  var useId = userFeature?0:parseInt(sessionStorage.getItem('userId'));
  this._facadeService.getDocDetailsById(this.docId,useId).subscribe(
      (data) => {
        this.docsDetailsData = data.returnObject;
      var docsDetailsData = data.returnObject;
      console.log("Docuemnt deatils data ::" + JSON.stringify(docsDetailsData));
      this.pageTitle = "Publish Document - "+docsDetailsData.code;
      this.title = docsDetailsData.title; 
      this.isPubish=docsDetailsData.obsoleteParentID>0?true:false;
      if(this.isPubish)
        this.pageTitle = "Obsolute Document - "+docsDetailsData.code;
      this.alternatedDocId = docsDetailsData.alternatedDocId;
      this.Description=docsDetailsData.description;
      this.workflow=docsDetailsData.workflow;
      this.product=docsDetailsData.productName;
      this.Remarks=docsDetailsData.remarks;
      this.site=docsDetailsData.site;
      this.selectedepartment=docsDetailsData.departmentName;
      this.PrePublishedFileName=docsDetailsData.fileName;
      if(this.alternatedDocId=="" || this.alternatedDocId==null|| this.alternatedDocId==undefined || this.alternatedDocId=="null"){
        this.alternatedDocId ="";
      }
      if(this.Description=="" || this.Description==null|| this.Description==undefined|| this.Description=="null"){
        this.Description ="";
      }
      if(this.Remarks=="" || this.Remarks==null|| this.Remarks==undefined|| this.Remarks=="null"){
        this.Remarks ="";
      }
      if(docsDetailsData.effectiveDate !== null && docsDetailsData.effectiveDate !== "" && docsDetailsData.effectiveDate !== undefined){
        this.effectiveDate = new Date(docsDetailsData.effectiveDate);
        this.effectiveDate = this.effectiveDate.toLocaleDateString();
        }
        if(docsDetailsData.reviewDate !== null && docsDetailsData.reviewDate !== "" && docsDetailsData.reviewDate !== undefined){
          this.reviewDate = new Date(docsDetailsData.reviewDate);
          this.reviewDate = this.reviewDate.toLocaleDateString();
        }
        if(docsDetailsData.userFileName!='' && docsDetailsData.userFileName!=undefined && docsDetailsData.userFileName!=null){
         this.ApprovedFile = docsDetailsData.userFileName;
        } 
        this.docType=docsDetailsData.documentType;
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
GetFile(){
  if(this.docsDetailsData.fileName!=null && this.docsDetailsData.fileName!=""&& this.docsDetailsData.fileName!=undefined){
  
      this.showLoading=true;
      var newname = this.docsDetailsData.userFileName.substr(0, this.docsDetailsData.userFileName.lastIndexOf('.'));
      this.Download(this.docsDetailsData.fileName,newname,this.docsDetailsData.userFileName);
    }
}
Download(filename,newname,userfilename)
{
  this._facadeService.downloadFile(filename,newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
   error => console.log('Error downloading the file'),
  () => console.info('File downloaded successfully');

}
}
