import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";

@Component({
  selector: 'app-rolegroup',
  templateUrl: './rolegroup.component.html',
  styleUrls: ['./rolegroup.component.scss']
})
export class RolegroupComponent implements OnInit {
  cols: any[];
  Description:any="";
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string; 
  addDocsDisplay: boolean;
  rolesData:any=[];
  groupName:any;
  groupNameError:any;
  Count=0;
  RoleError:any;
  responseMessage:any;
  Type:any="Add";
  FromEdit:boolean=false;
  savelabel:any="Save";
  selectedRole:any=[];
  active:boolean=true;
  RoleGroupId:any=0;
  groupsData:any=[];
  UserID = parseInt(sessionStorage.getItem("userId"));
  constructor(private _facadeService: FacadeService,public printService: PrintService,private cdr: ChangeDetectorRef
     ,@Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
    sessionStorage.groupName = "";
    sessionStorage.roleNames = "";
    sessionStorage.active ="";
  this.cols = [
  { field: 'groupName', header: 'Role Group', dynamicPlaceHolder: 'Search' },   
  { field: 'roleNames', header: 'Roles', dynamicPlaceHolder: 'Search' },   
  { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
];
   
    this.GetGroups();
  }
  AddNewButton() {
    this.GetRoles('');
    this.Type="Add";
    this.groupNameError="";
    this.RoleError="";
    this.responseMessage="";
    this.selectedRole=[];
    this.groupName="";
    this.FromEdit=false;
    this.addDocsDisplay = true; 
    this.savelabel="Save";
    this.active=true;
    this.RoleGroupId=0;
    this.Description="";
  }

  ViewGroup(GroupId){
    this.showLoading=true;
    this.groupNameError="";
    this.RoleError="";
    this.responseMessage="";
    this.groupName="";
    this.selectedRole=[];
    this._facadeService.GetGroupById(GroupId).subscribe(
      (data) => {
        this.addDocsDisplay = true; 
        this.showLoading=false;
        if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
            this.groupName=data.returnObject.groupName;
            this.RoleGroupId=data.returnObject.groupId;
            this.Description=data.returnObject.description;
            this.active=data.returnObject.active;
            this.GetRoles(data.returnObject.groupRoleIds);
            
          }
          this.Type="Edit";
          this.FromEdit=true;
          this.savelabel="Update";
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }

  GetRoles(roles){
    this.showLoading=true;
    this._facadeService.getRoles().subscribe(
      (data) => {
        this.showLoading=false;
      this.rolesData = data.returnObject;
      this.rolesData.forEach(element => {
        element.roleName = element.roleName + " (" + element.departmentName + ")";
      });
      console.log("Roles data ::" + JSON.stringify(this.rolesData));
      if(roles!=null&&roles.length>0){
        var sa=[];
        roles.forEach(element => {
          var grop= this.rolesData.find(m=>m.roleId==element);
          if(grop!=null){
            sa.push(grop);
          }
        });
        this.selectedRole=sa;
      }
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  GetGroups(){
    this.showLoading=true;
    var model={
      "bindRoles": true
    }
    this._facadeService.GetGroups(model).subscribe(
      (data) => {
       
      this.groupsData = data.returnObject;
      if(this.groupsData!=null&&this.groupsData.length>0){
        this.groupsData.forEach(element => {
          element.active=element.active==true?"Y":"N";
        });
        console.log("Groups data ::" + JSON.stringify(this.groupsData));
        this.Count=this.groupsData.length;
      }
      
      
      this.showLoading=false;
      
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  CreateRoleGroup(){
    this.groupNameError="";
    this.RoleError="";
    this.responseMessage="";
    if(this.groupName==""||this.groupName==null||this.groupName==undefined){
        this.groupNameError="Please enter group name.";
    }
    else if(this.selectedRole==""||this.selectedRole==null||this.selectedRole==undefined){
      this.RoleError="Please select role.";
  }
  else{
    this.showLoading=true;
    var roles = [];
    this.selectedRole.forEach(element => {
      roles.push(element.roleId);
    });
    var group={
      groupName:this.groupName,
      groupRoleIds:roles,
      Active:this.active,
      groupId:this.RoleGroupId,
      createdBy:this.UserID,
      description:this.Description
    };
    if(this.RoleGroupId>0){
      this._facadeService.UpdateGroup(group).subscribe(
        (data) => {
          this.showLoading=false;
          if(data.returnCode==0){
            this.addDocsDisplay=false;
           location.reload();
          }
          else{
           this.responseMessage=data.returnMessage;
          }
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
    else{
      this._facadeService.CreateGroup(group).subscribe(
        (data) => {
          this.showLoading=false;
          if(data.returnCode==0){
            this.addDocsDisplay=false;
            location.reload();
          }
          else{
           this.responseMessage=data.returnMessage;
          }
      },
      (error) => { 
        this.showLoading=false;
        console.log(error);
      }
    );
    }
  }
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
              ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['D1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx : 140 },{ wpx : 900 },{ wpx : 50 }];
   /* save to file */
   XLSX.writeFile(wb, "Role Groups.xlsx");
  }
  // export(){
  //   /* table id is passed over here */   
  //  let element = document.getElementById('excel-Departments'); 
  //  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //  /* generate workbook and add the worksheet */
  //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //  this.delete_row(wb.Sheets.Sheet1, 1);
  //  ws['!cols'] = [{ wpx : 140 },{ wpx : 900 },{ wpx : 50 }];
  //  /* save to file */
  //  XLSX.writeFile(wb, "Role Groups.xlsx");
  // }
  print() {
   
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content,'Role Groups','trSearch',true);
}

test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "roleNames")
      sessionStorage.roleNames = val;
      else if(field == "groupName")
      sessionStorage.groupName = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.groupsData != undefined && this.groupsData != null)
    {
      var temp = this.groupsData;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.roleNames != "")
        temp = temp.filter(x=> x.roleNames!=null&&x.roleNames!=""&&x.roleNames.toLowerCase().includes(sessionStorage.roleNames.toLowerCase()));
        if(sessionStorage.groupName != "")
        temp = temp.filter(x=> x.groupName!=null&&x.groupName!=""&&x.groupName.toLowerCase().includes(sessionStorage.groupName.toLowerCase()));
      
       this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
}
