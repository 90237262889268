import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { approvalNeededModel } from "../../models/approvalneeded_model";
import { FacadeService } from "../../facade/facade.service";
import * as _ from "lodash";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from "src/app/injectors";
import { SortEvent } from "primeng/api";
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";
@Component({
  selector: "app-originatedbystatus",
  templateUrl: "./originatedbystatus.component.html",
  styleUrls: ["./originatedbystatus.component.scss"],
  styles: [
    `
      :host ::ng-deep .p-multiselect {
        min-width: 15rem;
      }
      :host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
        min-width: 20rem;
      }
      :host ::ng-deep .multiselect-custom .p-multiselect-label {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
      :host ::ng-deep .multiselect-custom .country-item.country-item-value {
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
      }
      :host
        ::ng-deep
        .multiselect-custom
        .country-item.country-item-value
        img.flag {
        width: 17px;
      }
      :host ::ng-deep .multiselect-custom .country-item {
        display: flex;
        align-items: center;
      }
      :host ::ng-deep .multiselect-custom .country-item img.flag {
        width: 18px;
        margin-right: 0.5rem;
      }
      :host ::ng-deep .multiselect-custom .country-placeholder {
        padding: 0.25rem;
      }
      :host ::ng-deep .p-colorpicker {
        width: 2.5em;
      }
    `,
  ],
})
export class OriginatedbystatusComponent implements OnInit {
  months: ({ Id: string; Name: string } | { Id: number; Name: string })[];
  projects: any;
  columns: { field: string; header: string; dynamicPlaceHolder: string }[];
  documentByStatus: any;
  dataarr: any = [];
  monthselect: any;
  deptNameArr: any = [];
  currentweekStart: any;
  startDate: any;
  endDate: any;
  gridstatus: any;
  deptarr: any[] = [];
  isFrom: string = "";
  docum: any[] = [];
  departcolors: any[];
  selectedcolors: any;
  selecteddept: any;
  chartOptions: any;
  
  constructor(
    private _facadeService: FacadeService,public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private cdr: ChangeDetectorRef
  ) {}
  userId = sessionStorage.getItem("userId") != null ? parseInt(sessionStorage.getItem("userId")) : 0;
  userGuid = sessionStorage.getItem("userGuid");
  myApprovalModel: any;
  today: string;
  docsData: approvalNeededModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading: boolean;
  DelegationExist: boolean;
  AreYouSureText: any;
  deleteDisplay: boolean;
  delegationId: any;
  departments: any[];
  selectedDepartments: any[];
  //departmentArray: any[];
  public departmentArray: any[] = [];
  departmentError: any;
  source: any = "";
  status: any;
  departmentErr: string;
  periodErr: string;
  data: any;
  index: any;
  docsDataarr:[];
  fromDate: any;
  toDate: any;
  ordersChartOptions: any;
  ordersChart: any;
  selectedMonth: any;
  documentsName: any;
  customDisplay: boolean = false;
  chartModel: any;
  fromDateErr: string;
  toDateErr: string;
  documentChart: any;
  labels: any[] = [];
  //selectedStatusChecked:any[]=[];
  datasourcelist: any = {};
  result :any = {}
  deptarray: string = "";
  timelineSubTitle: any;
  isChartDataAvailable: boolean = false;
  options: any;
  EmailDisplay:boolean;
  isDisabled:boolean=true;
  DocumentsCount =0;
  ngOnInit(): void {
    this.showLoading = true;
    this.departments = [];
    this.selectedDepartments = [];
    this.getDepartments();
    this.isFrom = sessionStorage.isFrom;
    if(this.isFrom === 'originatedReport')
    {
      this.source = sessionStorage.originatedbystatusData;
    }
    
    this.months = [
      {
        Id: "",
        Name: "Select",
      },
      {
        Id: 0,
        Name: "Current Week",
      },
      {
        Id: 1,
        Name: "Previous Week",
      },
      {
        Id: 2,
        Name: "Current Month",
      },
      {
        Id: 3,
        Name: "Previous Month",
      },
      {
        Id: 4,
        Name: "Current Quarter",
      },
      {
        Id: 5,
        Name: "Previous Quarter",
      },
      {
        Id: 6,
        Name: "Custom",
      },
    ];
      this.cols = [
        {
          field: "departmentName",
          header: "Department",
          dynamicPlaceHolder: "Search by Department",
        },
        {
          field: "startDate",
          header: "Start Date",
          dynamicPlaceHolder: "Search by Month",
        },
        {
          field: "endDate",
          header: "End Date",
          dynamicPlaceHolder: "Search by Month",
        },
        {
          field: "status",
          header: "Status",
          dynamicPlaceHolder: "Search by status",
        },
        {
          field: "count",
          header: "Count",
          dynamicPlaceHolder: "Search by Count",
        },
      ];
      sessionStorage.count = "";
      sessionStorage.status ="";
      sessionStorage.startDate ="";
      sessionStorage.departmentName = "";
      sessionStorage.endDate = "";
      if(this.isFrom === 'originatedReport'){
        this.myApprovalModel = JSON.parse(this.source);
        //this.selectedDepartments = this.myApprovalModel.departmentIDs;
        var depts= sessionStorage.getItem("selectedDepts");
        if(depts!=null && depts!=""&&depts!=undefined){
          this.selectedDepartments=JSON.parse(depts);
        }
        // this.selectedMonth = {
        //     Id:sessionStorage.getItem('selectedmonthId'),
        //     Name:sessionStorage.getItem('selectedmonth')
        // }
        this.deptarray = sessionStorage.getItem('deptarray');
        this.startDate =  this.myApprovalModel.startDate;
        this.endDate = this.myApprovalModel.endDate;
        this.selectedMonth=this.months.find(m=>m.Id==sessionStorage.getItem('selectedmonthId'));
        this.monthselect = this.selectedMonth;
        this.originatedByStatus();
      }
      else{
        // this.departments.forEach(element => {
        //   this.selectedDepartments.push(element.departmentId);
        //   this.selectedStatusChecked.push(element.departmentId);
        // });
          //this.selectedDepartments.push(this.departments);
          //this.originatedByStatus();
      }
  }

  selectedStatusChange(selectedStatus){
    this.selectedDepartments=[];
    selectedStatus.value.forEach((value, key) => {
      this.selectedDepartments.push(value.productGuid);
    });
  }

  departmentChange(){
    
    if (this.selectedDepartments.length > 0) {
      this.deptarray = "";
      this.departmentArray=[];
      this.selectedDepartments.forEach((value, key) => {
        this.departmentArray.push(value.departmentId);
        if (value.departmentId !== undefined) {
          this.deptarray += value.departmentId + ",";
          sessionStorage.setItem('deptarray', this.deptarray);
        }
      });       
    }
  }

  getDepartments() {
    this.showLoading = true;
    this.selectedDepartments=[];
    this._facadeService.getDepartMents().subscribe(
      (response: any) => {
        this.departments = response.returnObject;
        this.showLoading = false;
        console.log("Departments ::" + JSON.stringify(this.departments));
        var depts= sessionStorage.getItem("selectedDepts");
        if(depts!=null && depts!=""&&depts!=undefined){
          this.selectedDepartments=JSON.parse(depts);
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  monthChangeEvent(selectedMonth) {
    this.monthselect = selectedMonth;
    
    //sessionStorage.selectedmonth = this.monthselect;
    sessionStorage.setItem('selectedmonth', selectedMonth.Name)
    sessionStorage.setItem('selectedmonthId', selectedMonth.Id)
    console.log('selectedMonth----------',sessionStorage.getItem('selectedmonth'));
    console.log('selectedMonth----------',sessionStorage.getItem('selectedmonthId'));
    if (selectedMonth.Name === "Custom") {
      this.customDisplay = true; 
      this.fromDate ="";
      this.toDate = "";    
    } 
    else if(selectedMonth.Name === "Current Week") {
      this.customDisplay = false;
      let curr = new Date; 
      let week = [];
      for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i 
        let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
        week.push(day)
        console.log('current week---', week);
        
      }  
      // first item of the array 
      this.fromDate = week[0];  
      // last item of the array 
      this.toDate = week[week.length-1];
      this.startDate =this.fromDate;
        console.log('this.startDate-------', this.startDate);
        this.endDate = this.toDate;
        console.log('this.endDate-------', this.endDate);
    }
    else if(selectedMonth.Name === "Previous Week") {
      this.customDisplay = false;
      
      var curr = new Date; // get current date
      var firstDay = curr.getDate() - curr.getDay() - 6; // Gets day of the month (e.g. 21) - the day of the week (e.g. wednesday = 3) = Sunday (18th) - 6
      var lastDay = firstDay + 6; // last day is the first day + 6
      var startDate = new Date(curr.setDate(firstDay));
      var endDate = new Date(curr.setDate(lastDay));
      // this.fromDate = firstDay.toLocaleString();
      // this.toDate = lastDay.toLocaleString();
      //this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
      this.startDate = this.datePipe.transform(startDate.toISOString(), 'yyyy-MM-dd');
      console.log("this.startDate-------", this.startDate);
      this.endDate = this.datePipe.transform(endDate.toISOString(), 'yyyy-MM-dd');
      console.log("this.endDate-------", this.endDate);  
    }
    else if(selectedMonth.Name === 'Current Month'){
      this.customDisplay = false;
      function daysInMonth (month, year) { 
        return new Date(year, month, 0).getDate(); 
    } 
    // this.fromDate = "";
    // this.toDate = "";
      let date = new Date(); 
                let firstDay = new Date(date.getFullYear(), 
                                date.getMonth(), 1); 
                                  
                let lastDay = new Date(date.getFullYear(), 
                        date.getMonth(), daysInMonth(date.getMonth()+1, 
                        date.getFullYear())); 

                        this.fromDate = new Date(firstDay);
                        this.fromDate = this.fromDate.toLocaleDateString()
                        this.toDate = new Date(lastDay);
                        this.toDate = this.toDate.toLocaleDateString();

                        this.startDate = this.fromDate;
        console.log('this.startDate-------', this.startDate);
        this.endDate = this.toDate;
        console.log('this.endDate-------', this.endDate);  
                        
    }
    else if(selectedMonth.Name === 'Previous Month'){
      this.customDisplay = false;
      /*let date = new Date();
      this.toDate = date.toLocaleDateString();
      let month = new Date().getMonth();
      //let prevMonth = date.setMonth(month - 1)
      let formatPrevMonth = new Date(date.setMonth(month - 1));
      this.fromDate = formatPrevMonth.toLocaleDateString();
      
      this.startDate = this.fromDate;
      console.log('this.startDate-------', this.startDate);
      this.endDate = this.toDate;
      console.log('this.endDate-------', this.endDate); */
      var now = new Date();
      this.toDate = new Date(now.getFullYear(), now.getMonth(), 0);
      this.fromDate = new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1);

      var formatDateComponent = function(dateComponent) {
        return (dateComponent < 10 ? '0' : '') + dateComponent;
      };

      var formatDate = function(date) {
        return formatDateComponent(date.getMonth() + 1) + '/' + formatDateComponent(date.getDate()) + '/' + date.getFullYear();
      };

//this.datePipe.transform(startDate.toISOString(), 'yyyy-MM-dd');
this.startDate = this.datePipe.transform(formatDate(this.fromDate), 'yyyy-MM-dd');
console.log('this.startDate-------', this.startDate);
this.endDate = this.datePipe.transform(formatDate(this.toDate), 'yyyy-MM-dd');
console.log('this.endDate-------', this.endDate);
      
    }
    else if(selectedMonth.Name === 'Current Quarter'){
      this.customDisplay = false;
      var currentMonth=(new Date()).getMonth()
      var yyyy = (new Date()).getFullYear()
      var start = (Math.floor(currentMonth/3)*3)+1;
      var end= start+3;
      var startDate=new Date(start+'-01-'+ yyyy);
      var endDate= end>12?new Date('01-01-'+ (yyyy+1)):new Date(end+'-01-'+ (yyyy));
      endDate=new Date((endDate.getTime())-1)
      this.fromDate = startDate.toLocaleDateString();
      this.toDate = endDate.toLocaleDateString();
      console.log('startDate =', this.fromDate,'endDate =', this.toDate);

      this.startDate = this.fromDate;
      console.log('this.startDate-------', this.startDate);
      this.endDate = this.toDate;
      console.log('this.endDate-------', this.endDate); 
    }
    else{
      this.customDisplay = false;
      const today = new Date();
      const quarter = Math.floor((today.getMonth() / 3));
      const startFullQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
      const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
      console.log('start date:'+startFullQuarter.toLocaleDateString()+'end date:'+endFullQuarter.toLocaleDateString());
      this.fromDate = startFullQuarter.toLocaleDateString();
      this.toDate = endFullQuarter.toLocaleDateString();

      this.startDate = this.fromDate;
      console.log('this.startDate-------', this.startDate);
      this.endDate = this.toDate;
      console.log('this.endDate-------', this.endDate); 
    }

  }

  selectData(event) {
    console.log("selectData=====", event);
    //this.messageService.add({severity: 'info', summary: 'Data Selected', 'detail': this.data.datasets[event.element._datasetIndex].data[event.element._index]});
  }

  originatedByStatus() {
    
    this.docsData = [];
    this.departmentErr = "";
    this.periodErr = "";
    this.fromDateErr = "";
    this.toDateErr = "";
    if (this.selectedDepartments.length === 0) {
      this.departmentErr = "Please select atleast one Department";
    } 
    else if (
      this.selectedMonth === "" ||
      this.selectedMonth === undefined ||
      this.selectedMonth === null
    ) {
      this.periodErr = "Please select period";
    }
    else if (this.monthselect.Name === "Custom" && (this.fromDate === "" ||
    this.fromDate === undefined ||
    this.fromDate === null)) {  
        this.fromDateErr = "Please select From Date";}
      
      else if ( this.monthselect.Name === "Custom" && (this.toDate === "" ||
      this.toDate === undefined ||
      this.toDate === null)) {
        this.toDateErr = "Please select To Date";
      }     
    else {
      
      if(this.customDisplay === true){
      this.startDate = this.fromDate;
      this.endDate = this.toDate;
      }
      
      if(this.isFrom !== 'originatedbystatus'){
      this.myApprovalModel = {
        operation: "",
        departmentIDs: this.deptarray,
        month: 0,
        //status: "",
        startDate: this.startDate,
        endDate: this.endDate,
        userID:this.userId
      };
    }
    else{
      this.myApprovalModel = {
        operation: "",
        departmentIDs: this.deptarray,
        month: 0,
        status: "",
        startDate: this.startDate,
        endDate: this.endDate,
        timeZone:sessionStorage.getItem('timeZone'),
        userID:this.userId
      };
      
    }
    sessionStorage.setItem('originatedbystatusData', JSON.stringify(this.myApprovalModel));
      console.log(
        "originatedByStatus::" + JSON.stringify(this.myApprovalModel)
      );
      this.showLoading = true;
      
      this._facadeService.originatedByStatus(this.myApprovalModel).subscribe(
        (data) => {
          
          this.docsData = data.returnObject;
         
          if(this.docsData !== null && this.docsData !== undefined){
          if (this.docsData.length > 0) {
            this.isDisabled=false;
            this.isChartDataAvailable = true;
            console.log("originatedByStatus==============", this.docsData);            
            this.departcolors = [{"Marketing":"#FBC02D"},{"Quality":"#2196F3"},{"Operations-TQM & Technology":"#689F38"}];
            this.labels = [
              "New",
              "In-Process",
              "Approved",
              "Hold",
              "Rejected",
              "Delegated",
              "Expired",
              "Archived",              
            ];              
            let dataset = [];
            var temp = '';
            this.docsData.forEach((item) => {
              item.startDate = this.datePipe.transform(this.startDate, 'MM/dd/yyyy');
              item.endDate = this.datePipe.transform(this.endDate, 'MM/dd/yyyy');
              var obj = dataset.find((_obj) => _obj.label === item.departmentName); 
                      
              let index = -1;
              if (!obj) {
                const colorCodes = this.getColorFromDepartmentName(item.departmentName);
                obj = {
                    label: item.departmentName,
                    backgroundColor: colorCodes.backgroundColor,
                    borderColor: colorCodes.borderColor,
                    data: [0, 0, 0, 0, 0, 0, 0]
                }
            }
              else {
                index = dataset.indexOf(obj);
              }
              const statusIndex = this.labels.indexOf(item.status);
              obj.data[statusIndex] = item.count;
              if (index >= 0) {
                dataset[index] = obj;
              } else {
                dataset.push(obj);
              }
            });
           
            this.data = {
              labels: this.labels,
              datasets: dataset
          };
          console.log(this.data);
          this.options = {
            legend: {
              display: true,
              position: "right",
              align: "center",
              labels: { fontColor: "#7b7c7c", fontSize: 16, padding: 28 },   
              
            }
          };   
          this.chartOptions = {            
            scales: {
              yAxes: [{
                ticks: {
                  stepSize: 5,
                  beginAtZero: true
                }
              }]
            },
            legend: {
              display: true,
              position: "right",
              align: "center",
              labels: { fontColor: "#7b7c7c", fontSize: 16, padding: 28 },   
              backgroundColor: [
                "#FBC02D",
                "#2196F3",
                "#689F38",                    
                "#607D8B",
                "#FF0000",
              ],   
            }
          };           
          }
         else{
          this.isDisabled=false;
         }
        }
        else{
          this.isChartDataAvailable = false;
        }
        this.showLoading = false;
        this.CountData();
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }  

  getColorFromDepartmentName(deptName) {
            var defaultValues = { deptName: '', backgroundColor: '#42A5F5', borderColor: '#1E88E5' };
            const colorCodes = [
                { deptName: 'Corporate / Operations', backgroundColor: '#C733FF', borderColor: '#C733FF' },
                { deptName: 'DCC', backgroundColor: '#FF3374', borderColor: '#FF3374' },
                { deptName: 'Marketing', backgroundColor: '#FBC02D', borderColor: '#FBC02D' },
                { deptName: 'Operations-MFG & SCM', backgroundColor: '#2196F3', borderColor: '#2196F3' },
                { deptName: 'Operations-TQM & Technology', backgroundColor: '#689F38', borderColor: '#689F38' },
                { deptName: 'Quality', backgroundColor: '#607D8B', borderColor: '#607D8B' },
                { deptName: 'Operations-Engineering', backgroundColor: '#FF0000', borderColor: '#FF0000' },
                { deptName: 'Operations-TQM', backgroundColor: '#FF5733', borderColor: '#FF5733' },
                { deptName: 'R&D', backgroundColor: '#9633FF', borderColor: '#9633FF' },
                { deptName: 'Corporate', backgroundColor: '#9CFF33', borderColor: '#9CFF33' },
                { deptName: 'Operations', backgroundColor: '#AAFFAA', borderColor: '#AAFFAA' },
                { deptName: 'Operations-Engineering', backgroundColor: '#33FFDD', borderColor: '#33FFDD' },
                { deptName: 'Operations-IT & ERP', backgroundColor: '#97797A', borderColor: '#97797A' },
                { deptName: 'Test Engineering', backgroundColor: '#2A00FF', borderColor: '#2A00FF' },
            ];
            const result = colorCodes.find(item => item.deptName === deptName);
            if (result) { return result; }
            else { return defaultValues; }
        }
        exportExcel() {
          // Manually load all data
          this.table.first = 0;
          this.table.rows = this.table.totalRecords;
        
          // Wait for data to load (assuming it's loaded asynchronously)
          setTimeout(() => {
            const data = this.getDataFromTable(this.table);
        
            // Proceed with exporting data to Excel
            this.exportDataToExcel(data);
          }, 100); // Adjust the timeout as needed
        }
        
        // Helper function to get visible columns and data from the p-table
        getDataFromTable(table: Table): { columns: any[], data: any[] } {
          const visibleColumns = table.columns.filter(column => column.field);
          const columns = visibleColumns.map(column => {
            return {
              header: typeof column.header === 'object' ? column.header['name'] : column.header,
              field: column.field
            };
          });
          // Use the 'filteredValue' property if available, otherwise fallback to 'value'
          const data = (table.filteredValue || table.value).map(item => {
            const rowData = {};
            visibleColumns.forEach(column => {
              const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
              rowData[columnHeader] = item[column.field];
            });
            return rowData;
          });
        
          return { columns, data };
        }
        
        // Example function to export data to Excel (you can customize this based on your needs)
        exportDataToExcel(data: { columns: any[], data: any[] }) {
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
        
          // Add headers separately
          const headerRows = [data.columns.map(column => column.header)];
          XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
        
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
          ws['!cols'] = [{ wpx : 120 },{ wpx : 100},{ wpx : 100 },{ wpx : 90 },{ wpx : 80 }];
          /* save to file */
          XLSX.writeFile(wb, "Documents Originated By Status.xlsx");
        }
  // exportExcel() {
  //   /* table id is passed over here */   
  //   let element = document.getElementById('excel-table'); 
  //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   this.delete_row(wb.Sheets.Sheet1, 1);
  //   ws['!cols'] = [{ wpx : 120 },{ wpx : 100},{ wpx : 100 },{ wpx : 90 },{ wpx : 80 }];
  //   /* save to file */
  //   XLSX.writeFile(wb, "Documents Originated By Status.xlsx");
  // }
  print() {
    
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content,"Documents Originated By Status",'trSearch',true);
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['F1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  viewDocDetails(selectedDocId) {   
    this.gridstatus = selectedDocId.status;
    this.myApprovalModel.departmentIDs = selectedDocId.departmentId.toString();
    this.myApprovalModel = {...this.myApprovalModel, status:selectedDocId.status}
    console.log('this.myApprovalModel-----------',this.myApprovalModel);
    sessionStorage.setItem('originatedreportData', JSON.stringify(this.myApprovalModel));
    console.log('originatedreportData===========',sessionStorage.originatedreportData);
    this.router.navigate(["/originatedreport"]);
    sessionStorage.selectedDepts= JSON.stringify(this.selectedDepartments);
  }
  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
      const activity = parseInt(value);

      if (!isNaN(activity)) {
        this.table.filter(activity, "activity", "gte");
      }
    }
  }
  
  SendEmail(){
    if(this.docsData!=null && this.docsData.length>0){
      this.docsData.forEach(element => {
        element.startDate=this.startDate;
        element.endDate=this.endDate;
      });
      this.EmailDisplay=true;
      this._facadeService.SendSummaryReport(this.userId,"bydepartment",this.docsData).subscribe(
        (response: any) => {
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  test(val,field,dt)
{
  if(field == "count")
    sessionStorage.count = val;
  else if(field == "status")
    sessionStorage.status = val;
  else if(field == "departmentName")
    sessionStorage.departmentName = val;
  else if(field == "departmentName")
    sessionStorage.departmentName = val;
  else if(field == "startDate")
    sessionStorage.startDate = val;
  else if(field == "endDate")
    sessionStorage.endDate = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.docsData != undefined && this.docsData != null)
  {
    var temp = this.docsData;
    if(sessionStorage.status != ""&&sessionStorage.status != null)
      temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    if(sessionStorage.endDate != "")
      temp = temp.filter(x=> x.endDate.toLowerCase().includes(sessionStorage.endDate.toLowerCase()));
    if(sessionStorage.count != "")
      temp = temp.filter(x=> x.count.toString().includes(sessionStorage.count.toString()));
      if(sessionStorage.startDate != "")
      temp = temp.filter(x=> x.startDate.includes(sessionStorage.startDate));
    if(sessionStorage.departmentName != "")
      temp = temp.filter(x=> x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
    if(temp != null)
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'startDate'||event.field === 'endDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    e.startDate = this.datePipe.transform( e.startDate, 'MM/dd/yyyy');
    e.endDate = this.datePipe.transform( e.endDate, 'MM/dd/yyyy');
  });
  this.docsData = [...event.data];
}
}
