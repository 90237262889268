<div class="p-grid p-fluid p-formgrid">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
      <p-tabView [activeIndex]="index" (onChange)="TabClick($event)">
        <p-tabPanel header="Awaiting for my approval" leftIcon="pi pi-directions">
          <div class="p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12 p-lg-9 p-md-12 p-sm-12"></div>  
            <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="margin-left: 110px;">
              <a (click)="UserDelegation()">
                <button pButton pRipple type="button" icon="pi pi-reply" iconPos="left" label="Add Delegation"
                  class="p-button-raised p-button-primary" style="width:100%; float:right;"></button>
              </a>
            </div>
          </div>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;;width: 80%;">Awaiting for my approval - {{DocumentsCount}}</h5>
            <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
              <button pButton pRipple type="button" icon="pi pi-print"
                (click)="print('invoice-content','trSearch','Awaiting for my approval',false)" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float:right; height:25px; width:25px;"></button>
              <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel1('excel-table','Awaiting for my approval')" pTooltip="Download"
                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
              <span>
                <p-dialog header="Cancel Delegation" [(visible)]="deleteDisplay" modal="modal" showEffect="fade"
                  [style]="{ width: '80%' }">
                  <hr />
                  <div class="p-grid p-fluid p-formgrid">
                    <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12" style="text-align:center;">
                      <h4 style="color:red">{{AreYouSureText}}</h4>
                    </div>
                    <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="margin-top:8px;">
                      <!-- <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;"> -->
                      <button pButton type="button" label="Yes" icon="pi pi-check-circle" iconPos="left"
                        (click)="CancelDelegation()" class="p-button-raised p-button-danger"></button>
                    </div>
                    <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="margin-top:8px;">
                      <button pButton type="button" label="No" icon="pi pi-times" iconPos="left"
                        (click)="deleteDisplay = false" class="p-button-raised p-button-secondary"></button>
                      <!-- </span> -->
                    </div>
                  </div>
                </p-dialog>
              </span>
            </span>
          </div>
          <div class="invoice invoice-header" id="invoice-content">
            <p-table #dt [value]="docsData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align"
            [virtualScroll]="true" [scrollable]="true" scrollHeight="200px" styleClass="p-datatable-striped" [filterDelay]="0"
              selectionMode="multiple" dataKey="id"
              [globalFilterFields]="['code','title','documentType','revision','eco','owner','status']">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'code': i == 0 || i == 7,'title': i == 1,'departmentName': i == 2,'revision': i == 3,'eco': i == 4,
              'owner': i == 5, 'status': i == 6}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th style="width:80px;">View</th>
                </tr>
                <tr id="trSearch">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="test($event.target.value,col.field,dt)"
                      style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                      <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 400px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 190px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 60px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 60px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 120px;">
                      {{rowData[col.field]}}
                    </td>
                      <!-- [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':'#D32F2F'}"> -->
                    <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 90px;"
                      [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 7 && documentsName != 'Awaiting for my approvals'"
                      style="text-align: center;width: 100px;">
                      <span>{{rowData[col.field]}}</span>
                    </td>
                  </ng-container>
                  <td style="text-align: center;width: 80px;">
                    <button pButton type="button" (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId,'In-Process')"
                      icon="pi pi-eye" pTooltip="View" tooltipPosition="left" class="p-button-rasised p-button-seconday"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="9" style="text-align: left;" *ngIf="documentsName != 'Awaiting for my approvals'">No
                    documents found.</td>
                  <td colspan="8" style="text-align: left;" *ngIf="documentsName == 'Awaiting for my approvals'">No
                    documents found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <br>
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;">
            <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Documents delegated to me - {{DocumentsCount1}}
            </h5>
            <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
              <button pButton pRipple type="button" icon="pi pi-print"
                (click)="print2('invoice-content3','trSearch','Documents delegated to me',false)" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float:right; height:25px; width:25px;"></button>
              <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel2('excel-table1','Documents delegated to me')" pTooltip="Download"
                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
            </span>
          </div>
          <div class="invoice invoice-header" id="invoice-content3">
            <p-table #dt3 [value]="docsData1" id="excel-table1" [columns]="cols1" sortMode="multiple"
              class="table-align" [virtualScroll]="true" [scrollable]="true" scrollHeight="380px" styleClass="p-datatable-striped"
              [filterDelay]="0" selectionMode="multiple" dataKey="id">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'code': i == 0,'title': i == 1,'departmentName': i == 2,'revision': i == 3,
                    'eco': i == 4,'owner': i == 5, 'status': i == 6}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th style="width:80px;">View</th>
                </tr>
                <tr>
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="test2($event.target.value,col.field,dt3)"
                      style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                      <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 400px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;;padding-left:15px;width: 190px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 3" style="text-align: left;;padding-left:15px;width: 60px">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 4" style="text-align: left;;padding-left:15px;width: 60px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 5" style="text-align: left;width: 120px;;padding-left:15px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 6" style="text-align: left;;padding-left:15px;font-weight: bold;width: 90px;"
                    [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                      {{rowData[col.field]}}
                    </td>
                  </ng-container>
                  <td style="text-align: center;width: 80px;">
                    <button pButton type="button" (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId,'In-Process')"
                      icon="pi pi-eye" pTooltip="View" tooltipPosition="left" class="p-button-rasised p-button-seconday"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No documents found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Documents delegated to others" leftIcon="pi pi-directions">
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Documents delegated to others-{{DocumentsCount2}}</h5>
            <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
              <button pButton pRipple type="button" icon="pi pi-print"
                (click)="print3('invoice-content1','trSearch','Documents delegated to others',false)" pTooltip="Print"
                tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float:right; height:25px; width:25px;"></button>
              <button pButton pRipple type="button" icon="pi pi-download"
                (click)="exportExcel2('excel-table1','Documents delegated to others')" pTooltip="Download"
                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
            </span>
          </div>
          <div class="invoice invoice-header" id="invoice-content1">
            <p-table #dt1 [value]="docsData" id="excel-table1" [virtualScroll]="true" [scrollable]="true" scrollHeight="200px"
              [columns]="cols2" sortMode="multiple" class="table-align" styleClass="p-datatable-striped"
              [filterDelay]="0" selectionMode="multiple" dataKey="id"
              [globalFilterFields]="['code','title','departmentName','revision','eco','owner','status']">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'code': i == 0,'title': i == 1,'departmentName': i == 2,'revision': i == 3,
                            'eco': i == 4,'owner': i == 5, 'status': i == 6}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th style="width:80px;">View</th>
                </tr>
                <tr id="trSearch1">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="test1($event.target.value,col.field,dt1)"
                      style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                      <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                      <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 400px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;width: 190px;padding-left:15px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 60px">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 60px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 120px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 90px;"
                    [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                      {{rowData[col.field]}}
                    </td>
                  </ng-container>
                  <td style="text-align: center;width: 80px;">
                    <button pButton type="button" (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId,'dto')"
                      icon="pi pi-eye" pTooltip="View" tooltipPosition="left" class="p-button-rasised p-button-seconday"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="8" style="text-align: left;">No documents found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Approved by me" leftIcon="pi pi-directions">
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;;width: 80%;">Approved by me - {{DocumentsCount3}}</h5>
            <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
              <button pButton pRipple type="button" icon="pi pi-print"
              (click)="print4('invoice-content4','trSearch','Approved by me',true)" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float:right; height:25px; width:25px;"></button>
              <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel4('excel-table4','Approved by me')" pTooltip="Download"
                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
            </span>
          </div>

          <div class="invoice invoice-header" id="invoice-content4">
            <p-table #dt4 [value]="docsData3" id="excel-table4" [columns]="cols" sortMode="multiple" class="table-align"
            [virtualScroll]="true" [scrollable]="true" scrollHeight="200px" styleClass="p-datatable-striped" [filterDelay]="0"
              selectionMode="multiple" dataKey="id"
              [globalFilterFields]="['code','title','documentType','revision','eco','owner','status']">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'code': i == 0 || i == 7,'title': i == 1,'departmentName': i == 2,'revision': i == 3,'eco': i == 4,
              'owner': i == 5, 'status': i == 6}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th pSortableColumn="price" style="width:80px;">View</th>
                </tr>
                <tr id="trSearch">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="test3($event.target.value,col.field,dt4)"
                      style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                      <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 400px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 190px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 60px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 60px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 120px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 90px;"
                    [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 7 && documentsName != 'Awaiting for my approvals'"
                      style="text-align: left;width: 100px;padding-left:15px;">
                      <span>{{rowData[col.field]}}</span>
                    </td>
                  </ng-container>
                  <td style="text-align: center;width: 80px;">
                    <button pButton type="button" (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId,'Approved')"
                      icon="pi pi-eye" pTooltip="View" tooltipPosition="left" class="p-button-rasised p-button-seconday"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="9" style="text-align: left;" *ngIf="documentsName != 'Awaiting for my approvals'">No
                    documents found.</td>
                  <td colspan="8" style="text-align: left;" *ngIf="documentsName == 'Awaiting for my approvals'">No
                    documents found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <br>
        </p-tabPanel>
        <p-tabPanel header="Rejected by me" leftIcon="pi pi-directions">
          <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto; ">
            <h5 style="color:#495057; padding:15px 0 0 15px;;width: 80%;">Rejected by me - {{DocumentsCount4}}</h5>
            <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
              <button pButton pRipple type="button" icon="pi pi-print"
                (click)="print5('invoice-content5','trSearch','Rejected by me',true)" pTooltip="Print" tooltipPosition="top"
                class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                style="float:right; height:25px; width:25px;"></button>
              <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel5('excel-table5','Rejected by me')" pTooltip="Download"
                tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
            </span>
          </div>
          <div class="invoice invoice-header" id="invoice-content5">
            <p-table #dt5 [value]="docsData4" id="excel-table5" [columns]="cols" sortMode="multiple" class="table-align"
            [virtualScroll]="true" [scrollable]="true" scrollHeight="200px" styleClass="p-datatable-striped" [filterDelay]="0"
              selectionMode="multiple" dataKey="id"
              [globalFilterFields]="['code','title','documentType','revision','eco','owner','status']">
              <ng-template pTemplate="header" let-columns class="invoice-items">
                <tr>
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'code': i == 0 || i == 7,'title': i == 1,'departmentName': i == 2,'revision': i == 3,'eco': i == 4,
              'owner': i == 5, 'status': i == 6}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                  </th>
                  <th pSortableColumn="price" style="width:80px;">View</th>
                </tr>
                <tr id="trSearch">
                  <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="test4($event.target.value,col.field,dt5)"
                      style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                  </th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                <tr [pSelectableRow]="rowData">
                  <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                      <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 400px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 190px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 60px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 60px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 120px;">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 90px;"
                    [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                      {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 7 && documentsName != 'Awaiting for my approvals'"
                      style="text-align: left;width: 100px;padding-left:15px;">
                      <span>{{rowData[col.field]}}</span>
                    </td>
                  </ng-container>
                  <td style="text-align: center;width: 80px;">
                    <button pButton type="button" (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId,'Rejected')"
                      icon="pi pi-eye" pTooltip="View" tooltipPosition="left" class="p-button-rasised p-button-seconday"
                      style="height:25px; width:25px;"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="9" style="text-align: left;" *ngIf="documentsName != 'Awaiting for my approvals'">No
                    documents found.</td>
                  <td colspan="8" style="text-align: left;" *ngIf="documentsName == 'Awaiting for my approvals'">No
                    documents found.</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1001;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>