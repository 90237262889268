
<div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height: 560px">
        <button pButton type="button" label="Expand all" (click)="expandAll()" style="margin-right: .5rem"></button>
        <button pButton type="button" label="Collapse all" (click)="collapseAll()"></button>
            <div class="p-grid p-fluid p-formgrid">
              <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12"></div>
              <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="float:right; margin-bottom:10px;">
            <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add New"
              (click)="AddNewButton()" class="p-button-raised p-button-primary " style="float:right;width:auto;"
              [disabled]="userFeature"></button>
            </div>
            </div>
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:40px; ">
  
          <h5 style="color:#495057; padding:15px 0 0 15px;">Product Folders - {{count}}</h5>
           
        </div>
        <div class="invoice invoice-header" id="invoice-content">
  
            <p-treeTable [value]="files" [columns]="cols">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" style="text-align: center;">
                            {{col.header}}
                        </th>
                        <th style="text-align: center;">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                    <tr>
                        <td *ngFor="let col of columns; let i = index">
                            <p-treeTableToggler [rowNode]="rowNode" *ngIf="i == 0"></p-treeTableToggler>
                           <span *ngIf="i == 0"> {{rowData[col.field]}}</span><span *ngIf="i != 0" style="text-align: center;"> {{rowData[col.field]}}</span>
                        </td>
                        <td style="text-align: center;">
                            <p-button icon="pi pi-pencil" styleClass="p-button-raised p-button-primary" pTooltip="Edit" (click)="EditProduct(rowData,rowNode.level)"></p-button>&nbsp;&nbsp;
                            <p-button icon="pi pi-plus" *ngIf="rowNode.level==0||rowNode.level==1" (click)="AddSubProduct(rowData)" pTooltip="Add Level 1 Folder" styleClass="p-button-raised p-button-primary"></p-button>
                        </td>
                    </tr>            
                </ng-template>
            </p-treeTable>
        </div>
  
      </div>
    </div>
  </div>
  <div
    style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 5000;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>

   <span class="p-input-icon-left" style="float: right">
              <p-dialog header="{{textlabelheader}} Folder" [(visible)]="addDisplay" modal="modal" showEffect="fade"
                [style]="{ width: '60%' }">
                <hr />
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-2"></div>
                    <div class="p-field p-col-12 p-md-8">
                        <label>Name<span style="color: red">*</span></label>
                        <input disabled={{!isdisabled}} [(ngModel)]="name" type="text" pInputText placeholder="Enter Name"/>
                        <small class="p-invalid">{{ nameError }}</small>
                        <br /><br />
                        <label>Description</label>
                        <textarea [(ngModel)]="description" type="text" rows="3" [maxLength]="300" pInputTextarea placeholder="Enter Description"></textarea>
                        <br /><br />      
                        <label>Active
                        <p-checkbox disabled={{isdisabled}} [(ngModel)]="active" binary="active">
                        </p-checkbox> </label>     
                        <br /><br />                     
                    </div>
                    <div class="p-field p-col-12 p-md-2"></div>
                </div>
                <div class="p-fluid p-formgrid p-grid" style="margin-top: -40px;">
                    <div class="p-field p-col-12 p-md-8"><small class="p-invalid" style="float: right;margin-top: 10px;">{{responseMessage}}</small></div>
                    <div class="p-field p-col-12 p-md-2">
                        <button pButton pRipple type="button" icon="pi pi-check" label="{{TextLabel}}"
                         class="p-button-raised p-button-success p-mr-2 p-mb-2"
                            style="float: right" (click)="SaveUpdateProduct()"></button>
                    </div>
                </div>
              </p-dialog>
            </span>
