<div class="layout-dashboard">
  <div class="p-grid">

    <div class="p-col-12 p-md-6 p-xl-5">
      <div class="card no-gutter widget-overview-box widget-overview-box-1" style="height:150px;">
        <span class="overview-icon">
          <i class="pi pi-file"></i>
        </span>
        <span class="overview-title">My Documents</span>
        <div class="p-grid overview-detail">
          <a class="p-col-3" (click)="MyDocTile('New')" style="cursor: pointer;border-right: 1px solid #dee2e6;">
            <div class="overview-number">{{ dashBoardCounts?.myDocs_New }}</div>
            <div class="overview-subtext">New </div>
          </a>
          <a class="p-col-3" (click)="MyDocTile('In-Process')" style="cursor: pointer;border-right: 1px solid #dee2e6;">
            <div class="overview-number">
              {{ dashBoardCounts?.myDocs_Inprocess }}
            </div>
            <div class="overview-subtext">In-Process</div>
          </a>
          <a class="p-col-2" (click)="MyDocTile('Hold')" style="cursor: pointer;border-right: 1px solid #dee2e6;">
            <div class="overview-number">{{ dashBoardCounts?.hold_Docs }}</div>
            <div class="overview-subtext">Hold </div>
          </a>
          <a class="p-col-4" (click)="MyDocTile('Obsolete In-Process')" style="cursor: pointer">
            <div class="overview-number">{{ dashBoardCounts?.myObs_Inprocess }}</div>
            <div class="overview-subtext">Obsolete In-Process</div>
          </a>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-xl-4" *ngIf="IsDCC">
      <div class="card no-gutter widget-overview-box widget-overview-box-2" style="height:150px;">
        <span class="overview-icon">
          <i class="pi pi-check-square"></i>
        </span>
        <span class="overview-title">Document Publication</span>
        <div class="p-grid overview-detail">
          <a class="p-col-6" (click)="MyPublished('I')" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCounts?.myApprovalNeeded_Active }}
            </div>
            <div class="overview-subtext">Pending</div>
          </a>

          <a class="p-col-6" (click)="MyPublished('A')" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCounts?.myApprovalNeeded_Completed }}
            </div>
            <div class="overview-subtext">Published</div>
          </a>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-xl-4" *ngIf="!IsDCC">
      <div class="card no-gutter widget-overview-box widget-overview-box-2" style="height:150px;">
        <span class="overview-icon">
          <i class="pi pi-check-square"></i>
        </span>
        <span class="overview-title">My Approvals</span>
        <div class="p-grid overview-detail">
          <a class="p-col-4" (click)="approvalNeeded()" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCounts?.myApprovalNeeded_Active + dashBoardCounts?.delegatedToMe}}
            </div>
            <div class="overview-subtext">Approvals Needed</div>
          </a>

          <a class="p-col-4" (click)="approvalDone()" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCounts?.myApprovalNeeded_Completed }}
            </div>
            <div class="overview-subtext">Approvals Done</div>
          </a>
          <a class="p-col-4" (click)="approvalRejected()" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCounts?.myApprovalNeeded_Rejected }}
            </div>
            <div class="overview-subtext">Rejected</div>
          </a>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-md-6 p-xl-3">
      <div class="card no-gutter widget-overview-box widget-overview-box-3" style="height:150px;">
        <span class="overview-icon">
          <i class="pi pi-directions"></i>
        </span>
        <span class="overview-title">Delegation Documents</span>
        <div class="p-grid overview-detail">
          <a class="p-col-12" (click)="MyDocTileDelegate('ByMe')" style="cursor: pointer">
            <div class="overview-number">{{ dashBoardCounts?.delegated }}</div>
            <div class="overview-subtext">Delegated To Others</div>
          </a>
          <!-- <a class="p-col-6" (click)="MyDocTileDelegate('Delegated')" style="cursor: pointer">
            <div class="overview-number">{{ dashBoardCounts?.delegatedToMe }}</div>
            <div class="overview-subtext">To Me</div>
          </a> -->
        </div>
      </div>
    </div>
    <!-- <div class="p-col-12 p-md-6 p-xl-3">
      <div class="card no-gutter widget-overview-box widget-overview-box-4"style="height:150px;">
        <span class="overview-icon">
          <i class="pi pi-chevron-circle-right"></i>
        </span>
        <span class="overview-title">Workflows</span>
        <div class="p-grid overview-detail">
          <a class="p-col-6" (click)="WorkFlowTitle('Executing')" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCounts?.workFlow_Current }}
            </div>
            <div class="overview-subtext">Current</div>
          </a>
          <a class="p-col-6" (click)="WorkFlowTitle('Failed')" style="cursor: pointer">
            <div class="overview-number">
              {{ dashBoardCounts?.workFlow_Failed }}
            </div>
            <div class="overview-subtext">Failed</div> 
          </a>
        </div>
      </div>
    </div> -->

    <div class="p-col-12 p-lg-8 p-md-12 p-sm-12">
      <div class="card" style="height:400px;overflow-x: auto;">
        <div class="card-header" style="padding-bottom:0px;">
          <h4>My documents by status</h4>
        </div>
        <hr>
        <div class="p-grid p-formgrid">
          <div *ngIf="isChartDataAvailable;else Chartemptymessage;" class="revenue-chart-container"
            style="width:65%;margin-left: 20%;">
            <p-chart type="pie" id="revenue-chart" [data]="documentChart" [options]="options"></p-chart>
          </div>
          <ng-template #Chartemptymessage>
            <div style="text-align: center;margin-left: 15px;">No status found.</div>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- <div class="p-col-12 p-lg-8 p-md-12 p-sm-12">
      <div class="card" style="height:400px;overflow-x: auto;">
        <div class="card-header" style="padding-bottom:0px;">
          <h4>My documents By Status</h4>
        </div>
        <hr>
        <div class="p-grid p-formgrid">
          <div *ngIf="isChartDataAvailable;else Chartemptymessage;" class="revenue-chart-container"
          style="width:550px;">
            <p-chart type="pie" id="revenue-chart" [data]="issueChart" [options]="options"></p-chart>
          </div>
          <ng-template #Chartemptymessage>
            <div style="text-align: center;margin-left: 15px;">No status found.</div>
          </ng-template>
        </div>
      </div>
    </div> -->
    <div class="p-col-12 p-lg-4 p-md-12 p-sm-12">
      <div class="card" style="height:400px; overflow-y: scroll;">
        <div class="card-header" style="padding-bottom:0px;">
          <h4>Timeline</h4>
          <p-dropdown [options]="TimelineOptions" [(ngModel)]="selectedTimelineOption" optionLabel="name"
            (onChange)="GetTimeline($event.value)" styleClass="dashbard-demo-dropdown" [ngStyle]="{'min-width':'8rem'}">
          </p-dropdown>
        </div>
        <hr>
        <div class="widget-timeline" *ngIf='isTimelineDataAvailable;else TimeLineemptymessage'>
          <div *ngFor="let item of Timeline;">
            <div class="timeline-event">
              <span class="timeline-event-icon" style="background-color: #64B5F6;">
                <i class="pi {{item.icon}}"></i>
              </span>
              <div class="timeline-event-title">{{item.title}}</div>
              <div class="timeline-event-detail" (click)="docDetails(item)" [innerHTML]="item.subTitleMod"></div>

            </div>
          </div>
        </div>
        <ng-template #TimeLineemptymessage>
          <div style="text-align: center;">No timeline found.</div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">
      <h5 style="color:#495057; padding:15px 0 0 10px;width: 90%;">Recent Documents - {{DocumentsCount}}</h5> <!-- T0010003 -->
      <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
        <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
          tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
          style="float:right;height:25px; width:25px;"></button>
        <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
          tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
          style="float:right; height:25px; width:25px;"></button>
      </span>
    </div>
    <div class="invoice invoice-header" id="invoice-content">
      <p-table (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="docsData" id="excel-table" [columns]="cols"  class="table-align"
      dataKey="id" styleClass="p-datatable-striped" [filterDelay]="0" [scrollable]="true" scrollHeight="200px"
      [virtualScroll]="true" [globalFilterFields]="['code','revision','eco','title','departmentName','owner','status']">
        <ng-template pTemplate="header" let-columns class="invoice-items">
          <tr>
            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
              [ngClass]="{'docid': i == 0 || i == 7,'rev': i == 1, 'eco': i == 2, 'title': i == 3, 'type': i == 4,'owner': i == 5, 'status': i == 6}">
              {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th style="width:60px;">Actions</th>
          </tr>
          <tr id="trSearch">
            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
              <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
              (input)="test($event.target.value,col.field,dt)"
                style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
            </th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr [pSelectableRow]="rowData">
            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

              <td *ngIf="i == 0" style="text-align: left;padding-left:15px;width: 100px;">
                <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
              </td>
              <td *ngIf="i == 1" style="text-align: center;width: 60px;">
                {{ rowData[col.field] }}
              </td>
              <td *ngIf="i == 2" style="text-align: center;width: 60px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 400px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 150px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 130px;">
                {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 140px;"
              [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
              {{rowData[col.field]}}
              </td>
              <td *ngIf="i == 7" style="text-align: center;width: 100px;">
                <span>{{rowData[col.field]}}</span>
              </td>
            </ng-container>
            <td style="text-align: center;width: 60px;">
              <button pButton type="button" (click)="viewDocDetails(rowData)" icon="pi pi-eye" pTooltip="View"
                class="p-button-raised p-button-primary" style="width:25px; height:25px;"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9" style="text-align: left;">No documents are found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>