import { Injectable } from '@angular/core';
import { HttputilityService } from '../services/httputility.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private _httputilityService: HttputilityService) {}
  //baseUrl: any = 'http://132.148.242.138/FPDMS/api/';
  //UMSbaseUrl: any = 'http://132.148.242.138/FPUMS/api/user/';

  getDocumentTypesTableData() {
    return this._httputilityService.Get('AllDocumentTypes?active='+'');
  }

  docTypes(model) {
    return this._httputilityService.Add('CreateDocType', model);
  }

  editdocTypes(model) {
    return this._httputilityService.Add('UpdateDocType', model);
  }

  // bindDocumentTypesData(typeID) {
  //   return this._httputilityService.Get('GetDocTypeByID');
  // }

  deleteDoc(DTID) {
    return this._httputilityService.Add('DeleteDocType?DTID=' + DTID,'');
  }

  deleteProfile(pfID) {
    return this._httputilityService.Add('DeleteProfile?PID=' + pfID, '');
  }

  getClausesData(model) {
    return this._httputilityService.Add("GetClauses", model);
  }

  getRoles() {
    return this._httputilityService.GetU('user/GetRoles');
  }

  RoleList(DepartmentGUID) {
    return this._httputilityService.GetU('user/GetRolesByDepartment?DepartmentGUID=' + DepartmentGUID,
      ''
    );
  }

  createDelegation(model) {
    return this._httputilityService.Add('AddDelegation', model);
  }

  GetAllDelegations() {
    return this._httputilityService.Get('AllDelegations');
  }
  GetDelegationsByIDs(data) {
    return this._httputilityService.Add('GetDelegationsByIDs', data);
  }
  ActiveDelegations() {
    return this._httputilityService.Get('ActiveDelegations');
  }
  GetProductFolderStructure() {
    return this._httputilityService.Get('GetProductFolderStructure');
  }

  CreateUpdateProductFolderStructure(model) {
    return this._httputilityService.Add('CreateUpdateProductFolderStructure',model);
  }
  CreateUpdateSubProductFolderStructure(model) {
    return this._httputilityService.Add('CreateUpdateSubProductFolderStructure',model);
  }
  GetProductFolderStructureDetailsById(id) {
    return this._httputilityService.Get('GetProductFolderStructureDetailsById?id='+id);
  }
  GetSubProductFolderStructureDetailsById(id) {
    return this._httputilityService.Get('GetSubProductFolderStructureDetailsById?id='+id);
  }

  GetDocTypeFolderStructure() {
    return this._httputilityService.Get('GetDocTypeFolderStructure');
  }
  GetFolders(model){
    return this._httputilityService.Add('GetFolders',model);
  }
  GetMainDocTypeFolderStructure(){
    return this._httputilityService.Get('GetMainDocTypeFolderStructure');
  }
  GetDocTypePermissions(){
    return this._httputilityService.Get('GetDocTypePermissions');
  }
  CreateUpdateDocTypePermissions(model){
    return this._httputilityService.Add('CreateUpdateDocTypePermissions',model);
  }
  SaveGroupFolderAccess(model){
    return this._httputilityService.Add('SaveGroupFolderAccess',model);
  }
  GetTaskPermissions(){
    return this._httputilityService.Get('GetTaskPermissions');
  }
  LimitedAccessGroups(roles){
    return this._httputilityService.Add('LimitedAccessGroups',roles);
  }
  CreateUpdateTaskPermissions(model){
    return this._httputilityService.Add('CreateUpdateTaskPermissions',model);
  }
  GetChildDocTypeFolderStructure(mainModel){
    return this._httputilityService.Add('GetChildDocTypeFolderStructure',mainModel);
  }
  GetSubChildDocTypeFolderStructure(mainModel){
    return this._httputilityService.Add('GetSubChildDocTypeFolderStructure',mainModel);
  }
  GetMainProductFolderStructure(){
    return this._httputilityService.Get('GetMainProductFolderStructure');
  }
  GetChildProductFolderStructure(mainModel){
    return this._httputilityService.Add('GetChildProductFolderStructure',mainModel);
  }
  GetSubChildProductFolderStructure(mainModel){
    return this._httputilityService.Add('GetSubChildProductFolderStructure',mainModel);
  }
  GetDocTypeDCCFolders(){
    return this._httputilityService.Get('GetDocTypeDCCFolders');
  }
  GetFolderMapsByIDs(type,model){
    return this._httputilityService.Add('GetFolderMapsByIDs?ftype='+type,model);
  }
  GetTaskProductFolders(){
    return this._httputilityService.Get('GetTaskProductFolders');
  }
  CreateUpdateDocTypeFolderStructure(model) {
    return this._httputilityService.Add('CreateUpdateDocTypeFolderStructure',model);
  }
  CreateUpdateSubDocTypeFolderStructure(model) {
    return this._httputilityService.Add('CreateUpdateSubDocTypeFolderStructure',model);
  }
  CreateFolder(model) {
    return this._httputilityService.Add('CreateFolder',model);
  }
  UpdateFolder(model) {
    return this._httputilityService.Add('UpdateFolder',model);
  }
  CreateUpdateSubSubDocTypeFolderStructure(model) {
    return this._httputilityService.Add('CreateUpdateSubSubDocTypeFolderStructure',model);
  }
  CreateUpdateSubSubProductFolderStructure(model){
    return this._httputilityService.Add('CreateUpdateSubSubProductFolderStructure',model);
  }
  GetDocTypeFolderStructureDetailsById(id) {
    return this._httputilityService.Get('GetDocTypeFolderStructureDetailsById?id='+id);
  }
  GetSubDocTypeFolderStructureDetailsById(id) {
    return this._httputilityService.Get('GetSubDocTypeFolderStructureDetailsById?id='+id);
  }
  GetSubSubProductFolderStructureDetailsById(id){
    return this._httputilityService.Get('GetSubSubProductFolderStructureDetailsById?id='+id);
  }
  GetSubSubDocTypeFolderStructureDetailsById(id){
    return this._httputilityService.Get('GetSubSubDocTypeFolderStructureDetailsById?id='+id);
  }
  GetFolderById(id){
    return this._httputilityService.Get('GetFolderById?folderid='+id);
  }
  GetAllRoleGroups(){
    return this._httputilityService.Get('GetAllRolesGroups');
  }
  GetGroupFolders(ftype,model){
    return this._httputilityService.Add('GetGroupFolders?type='+ftype,model);
  }
  GetGroups(model){
    return this._httputilityService.Add('GetGroups',model);
  }
  CreateGroup(model){
    return this._httputilityService.Add('CreateGroup',model);
  }
  UpdateGroup(model){
    return this._httputilityService.Add('UpdateGroup',model);
  }
  GetGroupRoleDetailsById(GroupId){
    return this._httputilityService.Get('GetGroupRoleDetailsById?groupGuid='+GroupId);
  }
  GetGroupById(groupid){
    return this._httputilityService.Get('GetGroupById?groupid='+groupid);
  }
  CheckRoleGroupInactiveById(RoleGroupGuid){
    return this._httputilityService.Get('CheckRoleGroupInactiveById?roleGroupGuid'+RoleGroupGuid);
  }
  CreateUpdateDocTypeDCCFolder(model){
    return this._httputilityService.Add('CreateUpdateDocTypeDCCFolder',model);
  }
  FolderMapping(model){
    return this._httputilityService.Add('FolderMapping',model);
  }
  UpdateFolderMapping(model){
    return this._httputilityService.Add('UpdateFolderMapping',model);
  }
  CreateUpdateTaskProductFolder(model){
    return this._httputilityService.Add('CreateUpdateTaskProductFolder',model);
  }
  GetTaskProductDetailsById(id){
    return this._httputilityService.Get('GetTaskProductDetailsById?id='+id);
  }
  GetDocTypeDCCDetailsById(id){
    return this._httputilityService.Get('GetDocTypeDCCDetailsById?id=' + id);
  }
  ObsoleteDocument(model){
    return this._httputilityService.Add('ObsoleteDocument',model);
  }
  GetMenus(model){
    return this._httputilityService.PostU('user/GetMenus',model);
  }
  GetMenusByRoles(applicationguid,model){
    return this._httputilityService.PostU('user/GetMenusByRoles?applicationguid='+applicationguid,model);
  }
  GetMenusByUser(userId,applicationid){
    return this._httputilityService.GetU('GetMenusByUser?userid='+userId+'&applicationid='+applicationid);

  }
  LimitedAccessDocuments(userid,type){
    return this._httputilityService.Add('LimitedAccessDocuments?userid='+userid+"&type="+type,null);
  }
  LimitedAccessProducts(userid){
    return this._httputilityService.Add('LimitedAccessProducts?userid='+userid,null);
  }
  LimitedAccessProductFoldersByID(userid,productid){
    return this._httputilityService.Add('LimitedAccessProductFoldersByID?userid='+userid+'&productid='+productid,null);
  }
  LimitedAccessDocumentsByID(model){
    return this._httputilityService.Add('LimitedAccessDocumentsByID',model);
  }
  AddUserTracking(model){
    return this._httputilityService.PostU('user/AddUserTracking',model);
  }
}
