import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { docTypedModel } from '../../models/docTypes_model';
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from 'primeng/table';
import { PrintService } from '../../services/print.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-documenttypes',
  templateUrl: './documenttypes.component.html',
  styleUrls: ['./documenttypes.component.scss']
})
export class DocumenttypesComponent implements OnInit {
  userId = parseInt(sessionStorage.getItem('userId'));
  docTypesTableData: docTypedModel;
  docTypedModel: any;
  docTypes: any;
  selectedProducts: docTypedModel[];
  docsTypessObject: any;
  data: number[];
  options: any;
  plugin: any;
  today: any;
  addDisplay: boolean;
  editDisplay: boolean;
  openNew: boolean;
  productDialog: boolean;
  submitted: boolean;
  docsData: docTypedModel;
  typeError: any;
  codeError: any;
  type: any;
  exportColumns: any[];
  code: any;
  cols: any[];
  documentTypeId: any;
  
  showLoading : boolean;
  @ViewChild('dt') table: Table;

  constructor(
    private _facadeService: FacadeService,
    public printService: PrintService,
    private messageService: MessageService,private cdr: ChangeDetectorRef
  ) {}
  //baseUrl: any = 'http://132.148.242.138/FPDMS/api/';

  ngOnInit(): void {
    this.getDocsTypes();

    this.cols = [
      { field: 'documentType', header: 'Type', dynamicPlaceHolder: 'Search by type' },
      { field: 'documentTypeCode', header: 'Code',dynamicPlaceHolder: 'Search by code' },
      { field: 'active', header: 'Status',dynamicPlaceHolder: 'Search by status' },
    ];
    this.exportColumns = this.cols.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  hideAddDialog() {
    this.addDisplay = false;
  }

  hideEditDialog() {
    this.editDisplay = false;
  }

  print() {
    
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content,"Document Types",'trSearch',false);
  }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    //ws['!cols'] = [{ wpx : 250},{ wpx : 350 }];
  
    // Save to file
    XLSX.writeFile(wb, "Document Types.xlsx");
  }
  // exportExcel() {
  //    /* table id is passed over here */   
  //    let element = document.getElementById('excel-table'); 
  //    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //    /* generate workbook and add the worksheet */
  //    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //    this.delete_row(wb.Sheets.Sheet1, 1);
  //    /* save to file */
  //    XLSX.writeFile(wb, "Document Types.xlsx");
  // }

  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['F1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  
  openModel(selectedRow) {
    this.editDisplay = true;
    this.code = selectedRow.documentTypeCode;
    this.type = selectedRow.documentType;
    this.documentTypeId = selectedRow.documentTypeId;
  }
  addType() {
    this.typeError = '';
    this.codeError = '';
    if (this.type == undefined || this.type == '') {
      this.typeError = 'Please enter document type.';
    }
    if (this.code == undefined || this.code == '') {
      this.codeError = 'Please enter document code.';
    }
    if (
      this.code != undefined &&
      this.code != '' &&
      this.type != undefined &&
      this.type != ''
    ) {
      this.docTypedModel = {
        documentType: this.type,
        documentTypeCode: this.code,
        active: 'Y',
        createdBy: this.userId,
      };
      
this.showLoading = true;
      this._facadeService.docTypes(this.docTypedModel).subscribe(
        (data) => {
          this.showLoading = false;
          console.log(data);
          this.messageService.add({severity:'info', summary: 'Record is added successully', detail:'record added'});
          this.type = '';
          this.code = '';
          this.addDisplay = false;

          this.ngOnInit();
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  editDocType() {
    this.typeError = '';
    this.codeError = '';
    if (this.type == undefined || this.type == '') {
      this.typeError = 'Please enter document type.';
    }
    if (this.code == undefined || this.code == '') {
      this.codeError = 'Please enter document code.';
    }
    if (
      this.code != undefined &&
      this.code != '' &&
      this.type != undefined &&
      this.type != ''
    ) {
      this.docTypedModel = {
        documentType: this.type,
        documentTypeCode: this.code,
        active: 'Y',
        createdBy: this.userId,
        documentTypeId: this.documentTypeId,
      };
      this.showLoading = true;
      this._facadeService.editdocTypes(this.docTypedModel).subscribe(
        (data) => {
          this.showLoading = false;
          console.log(data);
          // this.messageService.add({severity:'info', summary: 'Record is updated successully', detail:'record updated'});
          this.type = '';
          this.code = '';
          this.editDisplay = false;

          this.ngOnInit();
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  deleteDoc(rowData) {
    this.showLoading = true;
    this._facadeService.deleteDoc(rowData.documentTypeId).subscribe(
      (data) => {
        this.showLoading = false;
        this.ngOnInit();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  getDocsTypes() {
    this.showLoading = true;
    this._facadeService.getDocumentTypesTableData().subscribe(
      (data) => {
        this.showLoading = false;
        this.docTypes = data;
        this.docTypes = this.docTypes.returnObject;
        console.log(this.docTypes);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
}
