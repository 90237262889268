import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { approvalNeededModel } from '../../models/approvalneeded_model';
import { FacadeService } from '../../facade/facade.service';
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";
@Component({
  selector: 'app-originatedreport',
  templateUrl: './originatedreport.component.html',
  styleUrls: ['./originatedreport.component.scss']
})
export class OriginatedreportComponent implements OnInit {
  documentByStatus: any;
  deptarray: string;
  selectedDepartments: any[];
  docum: any[];
  constructor(
    private _facadeService: FacadeService,public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router,    
    private route:ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  userId = sessionStorage.getItem('userId')!=null?parseInt(sessionStorage.getItem('userId')):0;
  userGuid = sessionStorage.getItem('userGuid');
  myApprovalModel: any;
  today: string;
  docsData: approvalNeededModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading:boolean;
  source:any;
  documentsName:any;
  ngOnInit(): void {
    this.source = '';
    this.source = sessionStorage.originatedreportData;
    console.log('this.source-----', this.source); 
        this.originatedReport(this.source);
        this.cols = [
          { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'Search by ECRN' }, 
          { field: 'departmentName', header: 'Department', dynamicPlaceHolder: ' Search by Dept' }, 
          { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Doc Id' },
          { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev'},
          { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by Title' },
          { field: 'date', header: 'Date', dynamicPlaceHolder: 'Search by Date'},
          { field: 'owner', header: 'Originated By', dynamicPlaceHolder: 'Search by Originator'}, 
          { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by Status' } ,
          // { field: 'departmentName', header: 'Elapsed Days Pending For Approval', dynamicPlaceHolder: 'Search by Pending days' }, 
          { field: 'elapsedDays', header: 'Elapsed Days Total', dynamicPlaceHolder: 'Search by Total Days' }, 
         
          // { field: 'eco', header: 'Approvers', dynamicPlaceHolder: 'Search by Approvers'},        
                
          // { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
        ];
  }

  backClick(){
    sessionStorage.originatedReport = this.source;
    sessionStorage.isFrom = 'originatedReport';
    this.router.navigate(["/originatedbystatus"]);

  }

  originatedReport(source){
    this.showLoading = true;
    sessionStorage.setItem('originatedReport', source);
    this._facadeService.documentByStatus(source).subscribe(
      (data) => {
        this.docsData = data.returnObject;
        console.log("originatedByStatus==============", this.docsData);
        this.docsData.forEach(element => {
          if(element.ecrn === null || element.ecrn === undefined || element.ecrn === ""){
            element.ecrn = 'N/A';
          }
          else{
            element.ecrn = element.ecrn
          }
        });
        // this.deptarray = "";
        // this.selectedDepartments = [];
        // this.fromDate = null;
        // this.toDate = null;
        //this.docsData = _.sortBy(this.docsData, "createdDate");
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

    
  statusChangeEvent(selectedStatus) {
    sessionStorage.StatusDoc = selectedStatus.code;
    if (selectedStatus.code == 'In-Process') {
      this.documentsName = 'Awaiting for my approvals';
      this.source = selectedStatus.code;
      this.approvalNeededData();
    }    
    else if (selectedStatus.code == 'Approved') {
      this.documentsName = 'Approved by me';
      this.source = selectedStatus.code;
      this.approvalNeededData();
    }
    else if (selectedStatus.code == 'Rejected') {
      this.documentsName = 'Rejected by me';
      this.source = selectedStatus.code;
      this.approvalNeededData();
    }
    else{}
  }

  approvalNeededData() {
    var today = new Date();
    this.today = this.datePipe.transform(this.today, 'MM/dd/yyyy');
    /*if( window.history.state.retrievingfrom === 'dashboard'){
      this.viewDocDetails(window.history.state.docid, );
    }*/
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null){
          userFeature = true;
        }
      }
    }
    this.myApprovalModel = {
      documentId: 0,
      departmentId: 0,
      isArchived: 'N',
      documentTypeId: 0,
      documentStatus: this.source,
      todayDate: this.today,
      userID: this.userId,
      userGuid: this.userGuid,
      accessUserID:userFeature?0:parseInt(sessionStorage.getItem('userId'))
    };
    console.log("Search model2::" + JSON.stringify(this.myApprovalModel))
    this.showLoading = true;
    this._facadeService.approvalNeededData(this.myApprovalModel).subscribe(
      (data) => {
        this.docsData = data.returnObject;
        this.docsData = _.sortBy(this.docsData, "createdDate");
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  exportExcel() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Document Originated By Status Details.xlsx");
}
print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,"Document Originated By Status Details",'trSearch',true);
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['J1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

  viewDocDetails(selectedDocId) {
    sessionStorage.docId = selectedDocId.documentId;
    sessionStorage.StatusDoc =selectedDocId.status;
    sessionStorage.isFrom= "originatedReport";
    this.router.navigate(['/docdetails']);
  }
  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
        const activity = parseInt(value);

        if (!isNaN(activity)) {
            this.table.filter(activity, 'activity', 'gte');
        }
    }
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
