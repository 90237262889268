import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../../services/print.service";
import { Table } from 'primeng/table';

@Component({
  selector: 'app-workflowlist',
  templateUrl: './workflowlist.component.html',
  styleUrls: ['./workflowlist.component.scss']
})
export class WorkflowlistComponent implements OnInit {
  @ViewChild("dt") table: any;
  workflowsData: any;
  cols: any[];
  showLoading:boolean;
  DocumentsCount=0;
  deleteDisplay:boolean;
  AreYouSureText:any;
  id:any;
  userFeature:boolean;
  constructor(
    private facadeService: FacadeService, public printService: PrintService,
    private router: Router,private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DSBL_NEW_EDIT_DEL_WF");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    this.cols = [
      { field: 'displayName', header: 'Name', dynamicPlaceHolder: 'Name' },
      { field: 'description', header: 'Description', dynamicPlaceHolder: 'Description'},
      { field: 'version', header: 'Latest Version', dynamicPlaceHolder: 'Version'},
      { field: 'wfProfilesCount', header: 'Profile Count', dynamicPlaceHolder: 'Profile Count'}
    ];
    sessionStorage.displayName="";
    sessionStorage.description="";
    sessionStorage.version="";
    sessionStorage.wfProfilesCount="";
    this.getWorkflows();
  }

  getWorkflows() {
    this.showLoading = true;
    this.facadeService.getAllWorkDefinitions(true).subscribe(
      (data) => {
        this.showLoading = false;
        this.workflowsData = data.returnObject;
        if(this.workflowsData != null && this.workflowsData != undefined)
        {
          this.workflowsData.forEach(element => {
            element.wfProfilesCount = 0;
            if(element.wfProfiles != null && element.wfProfiles != undefined)
              element.wfProfilesCount = element.wfProfiles.length;
          });
        }
        console.log("workflow Data ::" + JSON.stringify( this.workflowsData));  
        this.CountData();     
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  } 
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    ws['!cols'] = [{ wpx : 300 },{ wpx :  100},{ wpx : 30 },{ wpx : 40 }];
    /* save to file */
    XLSX.writeFile(wb, "Workflows.xlsx");
  }
//   exportExcel() {
//     /* table id is passed over here */   
//     let element = document.getElementById('excel-table'); 
//     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//     /* generate workbook and add the worksheet */
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     this.delete_row(wb.Sheets.Sheet1, 1);
//     ws['!cols'] = [{ wpx : 300 },{ wpx :  100},{ wpx : 30 },{ wpx : 40 }];
//     /* save to file */
//     XLSX.writeFile(wb, "Workflows.xlsx");
// }

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "&nbsp;  &nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['E1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
print() {
 
    // Disable virtual scrolling temporarily to ensure all rows are rendered
const virtualScroll = this.table.virtualScroll;
this.table.virtualScroll = false;

// Detect changes immediately to ensure the table is rendered
this.cdr.detectChanges();

// Get the visible rows in the table
const visibleRows = this.table.rows; // Update this based on your table API

// Extract data from visible rows
const rowData = [];
for (let i = 0; i < visibleRows.length; i++) {
  // Customize this logic based on your table structure
  rowData.push({
    // Example: Assuming your row data is stored in a 'data' property
    data: visibleRows[i].data
  });
}

// Revert virtual scrolling to its original state
this.table.virtualScroll = virtualScroll;

// Now you can print the extracted row data
this.printService.Print(JSON.stringify(rowData), "Workflows", 'trSearch', true);


}
Edit(ID)
{
  sessionStorage.WorkflowDesignerID = ID;
  this.router.navigate(["/workflowdesign"]);
}
createWorkflow(){
  sessionStorage.WorkflowDesignerID = 0;
  this.router.navigate(["/workflowdesign"]);
  }

  deleteDisplayClick(rowdata){
    this.deleteDisplay=true;
    this.id=rowdata.id;
    this.AreYouSureText="Are you sure you want to delete "+ rowdata.displayName+" ?";
      }

      deleteDoc(){
        this.showLoading = true;
        this.facadeService.DeleteWorkflow(this.id).subscribe((response:any) => {
          this.showLoading = false;
          this.deleteDisplay = false;
          if(response.returnCode==0){
            this.ngOnInit();
          }
          else{
            alert(response.returnMessage);
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        });
      }

test(val,field,dt)
{
  if(field == "displayName")
    sessionStorage.displayName = val;
  else if(field == "description")
    sessionStorage.description = val;
  else if(field == "version")
    sessionStorage.version = val;
  else if(field == "wfProfilesCount")
    sessionStorage.wfProfilesCount = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.workflowsData != undefined && this.workflowsData != null)
  {
    var temp = this.workflowsData;
    if(sessionStorage.displayName != ""&&sessionStorage.displayName != null)
      temp = temp.filter(x=> x.displayName.toLowerCase().includes(sessionStorage.displayName.toLowerCase()));
    if(sessionStorage.description != ""&&sessionStorage.description != null)
      temp = temp.filter(x=> x.description.toLowerCase().includes(sessionStorage.description.toLowerCase()));      
    if(sessionStorage.version != ""&&sessionStorage.version != null)
      temp = temp.filter(x=> x.version.includes(sessionStorage.version));      
    if(sessionStorage.wfProfilesCount != ""&&sessionStorage.wfProfilesCount != null)
      temp = temp.filter(x=> x.wfProfilesCount.includes(sessionStorage.wfProfilesCount));      
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
}
