import { Component, OnInit, ViewChild } from '@angular/core';
import { approvalNeededModel } from '../../models/approvalneeded_model';
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Table } from "primeng/table";
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";
@Component({
  selector: 'app-approvals-delegated',
  templateUrl: './approvals-delegated.component.html',
  styleUrls: ['./approvals-delegated.component.scss']
})
export class ApprovalsDelegatedComponent implements OnInit {
  constructor(
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private printService:PrintService,
    private router: Router
  ) {}
  userId = parseInt(sessionStorage.getItem('userId'));
  userGuid = sessionStorage.getItem('userGuid');
  myApprovalModel: any;
  today: string;
  docsData: approvalNeededModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading:boolean;
  ngOnInit(): void {
    this.approvalsDelegatedData();

    this.cols = [
      { field: 'code', header: 'Id', dynamicPlaceHolder: 'Search by id' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
      { field: 'documentType', header: 'Type', dynamicPlaceHolder: 'Search by type' },
      { field: 'effectiveDate', header: 'Effective Date', dynamicPlaceHolder: 'Search by YYYY-MM-DD' },
      { field: 'reviewDate', header: 'Review Date', dynamicPlaceHolder: 'Search by YYYY-MM-DD' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
      { field: 'owner', header: 'Delegated By', dynamicPlaceHolder: 'Search by delegated by' },
    ];
  }

  approvalsDelegatedData() {
    // var today = new Date();
    // this.today = this.datePipe.transform(this.today, 'MM/dd/yyyy');
    // this.myApprovalModel = {
    //   documentId: 0,
    //   departmentId: 0,
    //   isArchived: 'N',
    //   documentTypeId: 0,
    //   documentStatus: 'In-Process',
    //   todayDate: this.today,
    //   userID: this.userId,
    //   userGuid: this.userGuid
    // };
    console.log("user identifier::" + JSON.stringify(this.userId))
    this.showLoading = true;
    this._facadeService.approvalDelegatedData(this.userId).subscribe(
      (data) => {
        this.docsData = data.returnObject;
        this.showLoading = false;
        console.log("Delegated Documents::" + JSON.stringify(this.docsData))
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  exportExcel() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Approvals Delegation.xlsx");
  }

  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['G1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }

  viewDocDetails(selectedDocId) {
    sessionStorage.docId = selectedDocId;
    this.router.navigate(['/docdetails']);
  }

  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
        const activity = parseInt(value);

        if (!isNaN(activity)) {
            this.table.filter(activity, 'activity', 'gte');
        }
    }
  }
  
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Approvals Delegation','trSearch',true);
}

}
