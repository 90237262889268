import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from 'src/app/facade/facade.service';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";
import { forEach } from 'lodash';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-doctypedccfolder',
  templateUrl: './doctypedccfolder.component.html',
  styleUrls: ['./doctypedccfolder.component.scss']
})
export class DoctypedccfolderComponent implements OnInit {
  @ViewChild("dt") table: Table;
  @ViewChild('multiSelectComponent', { static: false }) multiSelectComponent: any;
  @ViewChild('multiSelectComponent1', { static: false }) multiSelectComponent1: any;
  @ViewChild('multiSelectComponent2', { static: false }) multiSelectComponent2: any;
  constructor(private _facadeService: FacadeService,public printService: PrintService,private cdr: ChangeDetectorRef) { }
  showLoading:boolean;
  Count=0;
  dccdoctypedata=[];
  cols=[];
  addDocsDisplay:boolean;
  userId = parseInt(sessionStorage.getItem("userId"));
  selectedDocType: any;
  filtereddoctype:any;
  docsTypesData: any[]=[];
  docTypeError:any;
  Description:any;
  active=true;
  FromEdit=true;
  dccfolderData=[];
  dccError="";
  selectedDCC=[];
  savelabel:any;
  responseMessage:any;
  Type:any;
  dccSubfolderData=[];
  selectedSUbDCC=[];
  id=0;
  dccSubSubfolderData=[];
  selectedSubSUbDCC=[];
  selectedfolder="D";
  colsp=[];
  taskProductdata=[];
  Countp=0;
  selectedMainTask=[];
  taskfolderData=[];
  taskSubfolderData=[];
  selectedSUbTask=[];
  Descriptionp="";
  selectedSUbSubTask=[];
  taskSubSubfolderData=[];
  selectedPhase: any;
  phaseError:any;
  selectedTask: any;
  tasksError:any;
  taskError="";
  activep=true;
  responseMessagep="";
  idp=0;
  addDocsDisplayp:boolean;
  taskData: any[]=[];
  OrgtaskData: any[]=[];
  PhaseData: any[]=[];
  filteredPhase:any;
  filteredTask:any;
  mapId=0;
  ngOnInit(): void {
    this.showLoading = true;
    sessionStorage.transactionName = "";
    sessionStorage.active ="";
    sessionStorage.folderPath ="";
    sessionStorage.dccSubfolder1Names ="";
    sessionStorage.dccSubfolder2Names ="";
    this.cols = [
    { field: 'transactionName', header: 'Doc Type', dynamicPlaceHolder: 'Search' },
    { field: 'folderPath', header: 'Folder Path', dynamicPlaceHolder: 'Search' },
    // { field: 'dccSubfolder1Names', header: 'Sub Folder', dynamicPlaceHolder: 'Search' },
    // { field: 'dccSubfolder2Names', header: 'Sub - Sub Folder', dynamicPlaceHolder: 'Search' },
    { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
    ];
    this.GetDocTypeDCCFolders();

    this.colsp = [
      { field: 'phaseName', header: 'Phase', dynamicPlaceHolder: 'Search' },
      { field: 'transactionName', header: 'Task', dynamicPlaceHolder: 'Search' },
      { field: 'documentLabels', header: 'Document Label', dynamicPlaceHolder: 'Search' },
      { field: 'folderPath', header: 'Folder Path', dynamicPlaceHolder: 'Search' },
      { field: 'active', header: 'Active', dynamicPlaceHolder: 'Search' }
    ];
  }

  GetDocTypeDCCFolders(){
    this.dccdoctypedata=[];
    var model =[];
    var type =this.selectedfolder=="D"?"DCC":"Product";
    this.showLoading=true;
    this._facadeService.GetFolderMapsByIDs(type,model).subscribe(
      (data) => {
        this.dccdoctypedata=data.returnObject;
        if(this.dccdoctypedata != null && this.dccdoctypedata != undefined)
        {
          this.dccdoctypedata.forEach(element => {
          element.active=element.active==true?"Y":"N";
          if(element.folderNames!=null&&element.folderNames.length>0){
            var level0 = element.folderNames.find(m=>m.level==0);
            if(level0!=null){
              element.folderName = level0.folderNames;
            }

            var level1 = element.folderNames.find(m=>m.level==1);
            if(level1!=null){
              element.dccSubfolder1Names = level1.folderNames;
            }

            var level2 = element.folderNames.find(m=>m.level==2);
            if(level2!=null){
              element.dccSubfolder2Names = level2.folderNames;
            }
          }
         
          });
        }
        this.CountData();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  test(val,field,dt)
  {
    if(field == "active")
      sessionStorage.active = val;
    else if(field == "transactionName")
      sessionStorage.transactionName = val;
    else if(field == "folderPath")
      sessionStorage.folderPath = val;
    else if(field == "dccSubfolder1Names")
      sessionStorage.dccSubfolder1Names = val;
    else if(field == "dccSubfolder2Names")
      sessionStorage.dccSubfolder2Names = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.dccdoctypedata != undefined && this.dccdoctypedata != null)
    {
      var temp = this.dccdoctypedata;
      if(sessionStorage.active != ""&&sessionStorage.active != null)
        temp = temp.filter(x=> x.active!=null&&x.active!=""&&x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.transactionName != "")
        temp = temp.filter(x=> x.transactionName!=null&&x.transactionName!=""&&x.transactionName.toLowerCase().includes(sessionStorage.transactionName.toLowerCase()));
      // if(sessionStorage.folderName != "")
      //   temp = temp.filter(x=> x.folderName!=null&&x.folderName!=""&&x.folderName.toLowerCase().includes(sessionStorage.folderName.toLowerCase()));
      if(sessionStorage.dccSubfolder1Names != "")
        temp = temp.filter(x=> x.dccSubfolder1Names!=null&&x.dccSubfolder1Names!=""&&x.dccSubfolder1Names.toLowerCase().includes(sessionStorage.dccSubfolder1Names.toLowerCase()));
      if(sessionStorage.dccSubfolder2Names != "")
        temp = temp.filter(x=> x.dccSubfolder2Names!=null&&x.dccSubfolder2Names!=""&&x.dccSubfolder2Names.toLowerCase().includes(sessionStorage.dccSubfolder2Names.toLowerCase()));
        if(sessionStorage.folderPath != "")
        temp = temp.filter(x=> x.folderPath!=null&&x.folderPath!=""&&x.folderPath.toLowerCase().includes(sessionStorage.folderPath.toLowerCase()));
      this.Count = temp.length;
    }
    else
      this.Count = 0;
  }
  AddNewButton(){
    this.showLoading = true;
    this.Type="Add";
    this.docTypeError="";
    this.dccError="";
    this.responseMessage="";
    this.selectedDCC=[];
    this.selectedDocType="";
    this.FromEdit=false;
    this.addDocsDisplay = true; 
    this.Description="";
    this.savelabel="Save";
    this.active=true;
    this.id=0;
    this.selectedSUbDCC=[];
    this.selectedSubSUbDCC=[];
    this.dccSubfolderData=[];
    this.dccSubSubfolderData=[];
    this.mapId=0;
    this.getDocsTypes('','');
    this.multiSelectComponent.filterValue="";
    this.multiSelectComponent1.filterValue="";
    this.multiSelectComponent2.filterValue="";
  }
  filterDocType(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.docsTypesData.length; i++) {
        let doctype = this.docsTypesData[i];
        if (doctype.documentType.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(doctype);
        }
    }    
    this.filtereddoctype = filtered;
}
EditDocTypeDCC(id){
  this.multiSelectComponent.filterValue="";
  this.multiSelectComponent1.filterValue="";
  this.multiSelectComponent2.filterValue="";
  this.showLoading=true;
  this.id=id;
  this.docTypeError="";
  this.dccError="";
  this.responseMessage="";
  this.selectedDCC=[];
  this.selectedDocType="";
  this.FromEdit=true;
  this.addDocsDisplay = true; 
  this.savelabel="Update";
  this.active=true;
  this.selectedSUbDCC=[];
  this.selectedSubSUbDCC=[];
  this.dccSubfolderData=[];
  this.dccSubSubfolderData=[];
  var model =[];
  this.mapId=id;
  model.push(this.id);
  var type = this.selectedfolder=="D"?"DCC":"Product";
  this._facadeService.GetFolderMapsByIDs(type,model).subscribe(
    (data) => {
      this.showLoading=false;
      this.active=data.returnObject[0].active;
      this.Description=data.returnObject[0].description;
      this.mapId=data.returnObject[0].mapId;
      this.getDocsTypes(data.returnObject[0].transactionId,data.returnObject[0].parentFolders);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
              ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    delete (ws['F1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  // export(){
  //   /* table id is passed over here */   
  //  let element = document.getElementById('excel-Departments'); 
  //  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //  /* generate workbook and add the worksheet */
  //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //  this.delete_row(wb.Sheets.Sheet1, 1);
  //  ws['!cols'] = [{ wpx : 140 },{ wpx : 500 },{ wpx : 500 },{ wpx : 500 },{ wpx : 70 }];
  //  /* save to file */
  //  XLSX.writeFile(wb, "Doc Type - DCC Folder Mapping.xlsx");
  // }
  export() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    ws['!cols'] = [{ wpx : 140 },{ wpx : 500 },{ wpx : 500 },{ wpx : 500 },{ wpx : 70 }];
    /* save to file */
    XLSX.writeFile(wb, "Doc Type - DCC Folder Mapping.xlsx");
  }
  print() {
    
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content,'Doc Type - DCC Folder Mapping','trSearch',true);
}
GetDocTypeFolderStructure(dccmainMainFolders){
  this.selectedDCC=[];
  var model ={
    "type": this.selectedfolder=="D"?"DCC":"Product",
    "active": true
  }
  this.showLoading=true;
  this._facadeService.GetFolders(model).subscribe(
    (data) => {
      this.showLoading=false;
      this.dccfolderData=data.returnObject;
      if(this.dccfolderData != null && this.dccfolderData != undefined)
      {
        if(this.dccfolderData.length > 0)
        { 
          this.dccfolderData.forEach(d => {
            d.displayfolderName = d.folderName + " (" + d.subFolders.length + ")";
          });
        }
      }
      if(dccmainMainFolders!=null&&dccmainMainFolders!=""&&dccmainMainFolders!=undefined&&dccmainMainFolders.length>0){
        dccmainMainFolders.forEach(element => {
          var d = this.dccfolderData.find(m=>m.folderId==element.folderId);
          if(d!=null&&d!=""&&d!=undefined){
            this.selectedDCC.push(d);
          }
        });
        var sub = dccmainMainFolders.filter(m=>m.hierarchy==1);
        var subsub = dccmainMainFolders.filter(m=>m.hierarchy==2);
        this.MainFolderChange(sub,subsub);
      }
      else 
        this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
MainFolderChange(subs,subsub){
  this.dccSubfolderData=[];
  if(subs=="c"){
    subs =this.selectedSUbDCC;
  }
  var subFOl=[];
  this.selectedDCC.forEach(element => {
    if(element.subFolders!=null&&element.subFolders.length>0){
      element.subFolders.forEach(element1 => {
        element1.displayfolderName = element1.folderName + " (" + element.folderName + ")";
        subFOl.push(element1);
      });
    }
  });
  this.dccSubfolderData = subFOl;

    var sel=[];
    if(subs.length>0){
      subs.forEach(element => {
        var se = this.dccSubfolderData.find(m=>m.folderId==element.folderId);
        if(se!=null){
          sel.push(se);
        }
      });
    }
    this.selectedSUbDCC = sel;
    if(subsub!=null&&subsub!=""&&subsub!=undefined&&subsub.length>0){
      this.selectedSubSUbDCC=subsub;
      this.SubFolderChange(subsub);
    }
    else{
      this.SubFolderChange(this.selectedSubSUbDCC);
    }
  
}

SubFolderChange(subs){
  this.dccSubSubfolderData=[];
  if(subs=="c"){
    subs = this.selectedSubSUbDCC;
  }
   
  var subFOl=[];
  this.selectedSUbDCC.forEach(element => {
    if(element.subFolders!=null&&element.subFolders.length>0){
      element.subFolders.forEach(element1 => {
        element1.displayfolderName = element1.folderName + " (" + element.folderName + ")";
        subFOl.push(element1);
      });
    }
  });
  this.dccSubSubfolderData = subFOl;
    var sel=[];
    if(subs.length>0){
      subs.forEach(element => {
        var se = this.dccSubSubfolderData.find(m=>m.folderId==element.folderId);
        if(se!=null){
          sel.push(se);
        }
      });
    }
    this.selectedSubSUbDCC = sel;
}
getDocsTypes(doctype,val) {
  this.docsTypesData=[];
  this.showLoading=true;
  this._facadeService.getDocsTypes().subscribe(
    (data) => {
      if(data.returnObject!=null && data.returnObject.length>0){
        data.returnObject.forEach((value, key) => {
          if(value.documentTypeCode!="UCD"){
            this.docsTypesData.push(value);
          }
        });
      }
      this.showLoading=false;
      if(doctype!=null&&doctype!=""&&doctype!=undefined)
        this.selectedDocType=this.docsTypesData.find(m=>m.documentTypeId==doctype);
      this.GetDocTypeFolderStructure(val);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

CreateDocTypeDCC(){
  debugger
if(this.selectedDocType==null||this.selectedDocType==""||this.selectedDocType==undefined){
  this.docTypeError="Please select Doc Type";
}
else if(this.selectedDCC==null||this.selectedDCC==undefined||this.selectedDCC.length==0){
  this.dccError="Please select at least one DCC Folder";
}
else{
  var reqModel =[];
  this.selectedDCC.forEach(element => {
   var subFolder = this.selectedSUbDCC.filter(m=>m.mainFolderId==element.folderId);
   if(subFolder!=null&&subFolder.length>0){
    subFolder.forEach(element1 => {
      var subsubFolder = this.selectedSubSUbDCC.filter(m=>m.parentFolderId==element1.folderId);
      if(subsubFolder!=null&&subsubFolder.length>0){
        subsubFolder.forEach(element2 => {
          var mod = 
          {
            "transactionId": this.selectedDocType.documentTypeId,
            "folderId": element2.folderId,
            "description": this.Description,
            "type": "DCC",
            "active": this.active,
            "createdBy": this.userId,
            mapId:this.mapId
          }
          reqModel.push(mod);
          });
      }
      else{
        var mod = 
        {
          "transactionId": this.selectedDocType.documentTypeId,
          "folderId": element1.folderId,
          "description": this.Description,
          "type": "DCC",
          "active": this.active,
          "createdBy": this.userId,
          mapId:this.mapId
        }
        reqModel.push(mod);
      }
     });
   }
   else{
    var mod = 
    {
      "transactionId": this.selectedDocType.documentTypeId,
      "folderId": element.folderId,
      "description": this.Description,
      "type": "DCC",
      "active": this.active,
      "createdBy": this.userId,
      mapId:this.mapId
    }
    reqModel.push(mod);
   }
  });
  debugger
  this.showLoading=true;
  if(this.mapId>0){
    this._facadeService.UpdateFolderMapping(reqModel).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnCode==0){
          this.addDocsDisplay=false;
          this.addDocsDisplayp=false;
          this.FolderChange();
          //location.reload();
        }
        else{
          this.responseMessage=data.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  else{
    this._facadeService.FolderMapping(reqModel).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnCode==0){
          this.addDocsDisplay=false;
          this.addDocsDisplayp=false;
          this.FolderChange();
          //location.reload();
        }
        else{
          this.responseMessage=data.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  
}
}

FolderChange(){
  if(this.selectedfolder=="P"){
    sessionStorage.phaseName = "";
    sessionStorage.transactionName ="";
    sessionStorage.documentLabels ="";
    sessionStorage.folderPath ="";
    sessionStorage.taskSubfolder1Names ="";
    sessionStorage.taskSubfolder2Names ="";
    sessionStorage.active ="";
    this.GetTaskProductFolders();
  }
  else{
    sessionStorage.transactionName = "";
    sessionStorage.active ="";
    sessionStorage.folderPath ="";
    sessionStorage.dccSubfolder1Names ="";
    sessionStorage.dccSubfolder2Names ="";
    this.GetDocTypeDCCFolders();
  }
}

GetTaskProductFolders(){
  var model =[];
  this.taskProductdata=[];
  var type =this.selectedfolder=="D"?"DCC":"Product";
  this.showLoading=true;
  this._facadeService.GetFolderMapsByIDs(type,model).subscribe(
    (data) => { 
      this.taskProductdata=data.returnObject;
      if(this.taskProductdata != null && this.taskProductdata != undefined)
      {
        this.taskProductdata.forEach(element => {
          element.active=element.active==true?"Y":"N";
          if(element.folderNames!=null&&element.folderNames.length>0){
            var level0 = element.folderNames.find(m=>m.level==0);
            if(level0!=null){
              element.taskSubfolderNames = level0.folderNames;
            }

            var level1 = element.folderNames.find(m=>m.level==1);
            if(level1!=null){
              element.taskSubfolder1Names = level1.folderNames;
            }

            var level2 = element.folderNames.find(m=>m.level==2);
            if(level2!=null){
              element.taskSubfolder2Names = level2.folderNames;
            }
          }
        });
      }
      this.CountDatap();
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

CountDatap(){
  if(this.taskProductdata != undefined && this.taskProductdata != null)
  {
    var temp = this.taskProductdata;
    if(sessionStorage.phaseName != ""&&sessionStorage.phaseName != null)
      temp = temp.filter(x=> x.phaseName.toLowerCase().includes(sessionStorage.phaseName.toLowerCase()));
    if(sessionStorage.transactionName != ""&&sessionStorage.transactionName != null)
      temp = temp.filter(x=> x.transactionName.toLowerCase().includes(sessionStorage.transactionName.toLowerCase()));
    if(sessionStorage.documentLabels != ""&&sessionStorage.documentLabels != null)
      temp = temp.filter(x=> x.documentLabels != null && x.documentLabels != "" && x.documentLabels.toLowerCase().includes(sessionStorage.documentLabels.toLowerCase()));
    if(sessionStorage.folderPath != ""&&sessionStorage.folderPath != null)
      temp = temp.filter(x=> x.folderPath.toLowerCase().includes(sessionStorage.folderPath.toLowerCase()));
    if(sessionStorage.taskSubfolder1Names != "")
      temp = temp.filter(x=> x.taskSubfolder1Names!=null&&x.taskSubfolder1Names!=""&&x.taskSubfolder1Names.toLowerCase().includes(sessionStorage.taskSubfolder1Names.toLowerCase()));
    if(sessionStorage.taskSubfolder2Names != "")
      temp = temp.filter(x=> x.taskSubfolder2Names!=null&&x.taskSubfolder2Names!=""&&x.taskSubfolder2Names.toLowerCase().includes(sessionStorage.taskSubfolder2Names.toLowerCase()));
    if(sessionStorage.active != "")
      temp = temp.filter(x=> x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
    this.Countp = temp.length;
  }
  else
    this.Countp = 0;
}

testp(val,field,dt)
{
  if(field == "phaseName")
    sessionStorage.phaseName = val;
  else if(field == "transactionName")
    sessionStorage.transactionName = val;
  else if(field == "documentLabels")
    sessionStorage.documentLabels = val;
  else if(field == "folderPath")
    sessionStorage.folderPath = val;
  else if(field == "taskSubfolder1Names")
    sessionStorage.taskSubfolder1Names = val;
  else if(field == "taskSubfolder2Names")
    sessionStorage.taskSubfolder2Names = val;
  else if(field == "active")
    sessionStorage.active = val;
  dt = dt.filter(val,field, 'contains');
  this.CountDatap();
}

delete_rowp (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['H1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ecp(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}
// exportp(){
//   /* table id is passed over here */   
//  let element = document.getElementById('excel-Departments'); 
//  const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//  /* generate workbook and add the worksheet */
//  const wb: XLSX.WorkBook = XLSX.utils.book_new();
//  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//  this.delete_row(wb.Sheets.Sheet1, 1);
//  ws['!cols'] = [{ wpx : 100 },{ wpx : 500 },{ wpx : 120 },{ wpx : 500 },,{ wpx : 500 },,{ wpx : 500 },,{ wpx : 70 },];
//  /* save to file */
//  XLSX.writeFile(wb, "Task - Product Sub Folder Mapping.xlsx");
// }
exportp() {
  // Manually load all data
  this.table.first = 0;
  this.table.rows = this.table.totalRecords;

  // Wait for data to load (assuming it's loaded asynchronously)
  setTimeout(() => {
    const data = this.getDataFromTable(this.table);

    // Proceed with exporting data to Excel
    this.exportDataToExcelp(data);
  }, 100); // Adjust the timeout as needed
}


// Example function to export data to Excel (you can customize this based on your needs)
exportDataToExcelp(data: { columns: any[], data: any[] }) {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });

  // Add headers separately
  const headerRows = [data.columns.map(column => column.header)];
  XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   ws['!cols'] = [{ wpx : 100 },{ wpx : 500 },{ wpx : 120 },{ wpx : 500 },,{ wpx : 500 },,{ wpx : 500 },,{ wpx : 70 },];
 /* save to file */
 XLSX.writeFile(wb, "Task - Product Sub Folder Mapping.xlsx");
}
printp() {
  
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
  this.printService.Print(Content,'Task - Product Sub Folder Mapping','trSearch',true);
}

GetMainProductFolderStructure(dccmainMainFolders){
  this.selectedMainTask=[];
  var model ={
    "type": this.selectedfolder=="D"?"DCC":"Product",
    "active": true
  }
  this.showLoading=true;
  this._facadeService.GetFolders(model).subscribe(
    (data) => {
      this.showLoading=false;
      this.taskfolderData=data.returnObject;
      if(this.taskfolderData != null && this.taskfolderData != undefined)
      {
        if(this.taskfolderData.length > 0)
        { 
          this.taskfolderData.forEach(d => {
            d.displayfolderName = d.folderName + " (" + d.subFolders.length + ")";
          });
        }
      }
      if(dccmainMainFolders!=null&&dccmainMainFolders!=""&&dccmainMainFolders!=undefined&&dccmainMainFolders.length>0){
        dccmainMainFolders.forEach(element => {
          var d = this.taskfolderData.find(m=>m.folderId==element.folderId);
          if(d!=null&&d!=""&&d!=undefined){
            this.selectedMainTask.push(d);
          }
        });
        var sub = dccmainMainFolders.filter(m=>m.hierarchy==1);
        var subsub = dccmainMainFolders.filter(m=>m.hierarchy==2);
        this.MainFolderChangep(sub,subsub);
      }
      else 
        this.showLoading = false;

       
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

MainFolderChangep(subs,subsub){
  this.taskSubfolderData=[];
  if(subs=="c"){
    subs =this.selectedSUbTask;
  }
  var subFOl=[];
  this.selectedMainTask.forEach(element => {
    if(element.subFolders!=null&&element.subFolders.length>0){
      element.subFolders.forEach(element1 => {
        element1.displayfolderName = element1.folderName + " (" + element.folderName + ")";
        subFOl.push(element1);
      });
    }
  });
  this.taskSubfolderData = subFOl;

    var sel=[];
    if(subs.length>0){
      subs.forEach(element => {
        var se = this.taskSubfolderData.find(m=>m.folderId==element.folderId);
        if(se!=null){
          sel.push(se);
        }
      });
    }
    this.selectedSUbTask = sel;
    if(subsub!=null&&subsub!=""&&subsub!=undefined&&subsub.length>0){
      this.selectedSUbSubTask=subsub;
      this.SubFolderChangep(subsub);
    }
    else{
      this.SubFolderChangep(this.selectedSUbSubTask);
    }
}

SubFolderChangep(subs){
  this.taskSubSubfolderData=[];
  if(subs=="c"){
    subs = this.selectedSUbSubTask;
  }
   
  var subFOl=[];
  this.selectedSUbTask.forEach(element => {
    if(element.subFolders!=null&&element.subFolders.length>0){
      element.subFolders.forEach(element1 => {
        element1.displayfolderName = element1.folderName + " (" + element.folderName + ")";
        subFOl.push(element1);
      });
    }
  });
  this.taskSubSubfolderData = subFOl;
    var sel=[];
    if(subs.length>0){
      subs.forEach(element => {
        var se = this.taskSubSubfolderData.find(m=>m.folderId==element.folderId);
        if(se!=null){
          sel.push(se);
        }
      });
    }
    this.selectedSUbSubTask = sel;
}

CreateTaskProduct(){
  if(this.selectedPhase==null||this.selectedPhase==""||this.selectedPhase==undefined){
    this.phaseError="Please select Phase";
  }
  else if(this.selectedTask==null||this.selectedTask==""||this.selectedTask==undefined){
    this.tasksError="Please select Task";
  }
  else if(this.selectedMainTask==null||this.selectedMainTask==undefined||this.selectedMainTask.length==0){
    this.taskError="Please select at least one Product Sub Folder";
  }
  else{
    var reqModel =[];
    this.selectedMainTask.forEach(element => {
     var subFolder = this.selectedSUbTask.filter(m=>m.mainFolderId==element.folderId);
     if(subFolder!=null&&subFolder.length>0){
      subFolder.forEach(element1 => {
        var subsubFolder = this.selectedSUbSubTask.filter(m=>m.parentFolderId==element1.folderId);
        if(subsubFolder!=null&&subsubFolder.length>0){
          subsubFolder.forEach(element2 => {
            var mod = 
            {
              "transactionId": this.selectedTask.requiredDocumentID,
              "folderId": element2.folderId,
              "description": this.Descriptionp,
              "type": "Product",
              "active": this.activep,
              "createdBy": this.userId,
              mapId:this.mapId,
            }
            reqModel.push(mod);
            });
        }
        else{
          var mod = 
          {
            "transactionId": this.selectedTask.requiredDocumentID,
            "folderId": element1.folderId,
            "description": this.Descriptionp,
            "type": "Product",
            "active": this.activep,
            "createdBy": this.userId,
            mapId:this.mapId,
          }
          reqModel.push(mod);
        }
       });
     }
     else{
      var mod = 
      {
        "transactionId": this.selectedTask.requiredDocumentID,
        "folderId": element.folderId,
        "description": this.Descriptionp,
        "type": "Product",
        "active": this.activep,
        "createdBy": this.userId,
        mapId:this.mapId,
      }
      reqModel.push(mod);
     }
    });
    
    this.showLoading=true;
    if(this.mapId>0){
      this._facadeService.UpdateFolderMapping(reqModel).subscribe(
        (data) => {
          this.showLoading = false;
          if(data.returnCode==0){
            this.addDocsDisplay=false;
            this.addDocsDisplayp=false;
           // location.reload();
           this.FolderChange();
          }
          else{
            this.responseMessage=data.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      this._facadeService.FolderMapping(reqModel).subscribe(
        (data) => {
          this.showLoading = false;
          if(data.returnCode==0){
            this.addDocsDisplay=false;
            this.addDocsDisplayp=false;
            //location.reload();
            this.FolderChange();
          }
          else{
            this.responseMessage=data.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }

  
  }
  BindTaskData(phaseName)
{
  this.selectedTask="";
  this.taskData = this.OrgtaskData.filter(x=>x.phaseName == phaseName);
}
GetAllTasks(requiredDocumentID,Val) {
  this.showLoading = true;
  this._facadeService.GetAllTasks().subscribe(
    (data) => {
      console.log(data.returnObject);
      this.OrgtaskData=data.returnObject;
      this.taskData = [];
      if(this.OrgtaskData != null && this.OrgtaskData != undefined)
      {
        this.PhaseData = this.OrgtaskData.filter(
          (thing, i, arr) => arr.findIndex((t) => t.phaseName === thing.phaseName) === i
        );
      }
      if(requiredDocumentID!=null&&requiredDocumentID!=""&&requiredDocumentID!=undefined){
        this.selectedTask=this.OrgtaskData.find(m=>m.requiredDocumentID==requiredDocumentID);
        this.selectedPhase=this.PhaseData.find(m=>m.phaseName==this.selectedTask.phaseName);
        this.taskData = this.OrgtaskData.filter(x=>x.phaseName == this.selectedTask.phaseName);
      }
         
      this.GetMainProductFolderStructure(Val);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
EditTaskProduct(id){
  this.multiSelectComponent.filterValue="";
  this.multiSelectComponent1.filterValue="";
  this.multiSelectComponent2.filterValue="";
  this.showLoading = true;
  this.idp=id;
  this.tasksError="";
  this.taskError="";
  this.responseMessagep="";
  this.selectedMainTask=[];
  this.selectedTask="";
  this.FromEdit=true;
  this.addDocsDisplayp = true; 
  this.savelabel="Update";
  this.activep=true;
  this.Descriptionp="";
  this.selectedSUbTask=[];
  this.taskSubfolderData=[];
  this.taskSubSubfolderData=[];
  this.selectedSUbSubTask=[];
  this.mapId=id;
  var model=[];
  model.push(this.idp);
  var type = this.selectedfolder=="D"?"DCC":"Product";
  this._facadeService.GetFolderMapsByIDs(type,model).subscribe(
    (data) => {
      this.mapId=data.returnObject[0].mapId;
      this.activep=data.returnObject[0].active;
      this.Descriptionp=data.returnObject[0].description;
      this.GetAllTasks(data.returnObject[0].transactionId,data.returnObject[0].parentFolders);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

AddNewButtonp(){
  this.multiSelectComponent.filterValue="";
  this.multiSelectComponent1.filterValue="";
  this.multiSelectComponent2.filterValue="";
  this.showLoading = true;
  this.Descriptionp="";
  this.Type="Add";
  this.tasksError="";
  this.taskError="";
  this.selectedPhase="";
  this.responseMessagep="";
  this.selectedMainTask=[];
  this.selectedTask="";
  this.FromEdit=false;
  this.addDocsDisplayp = true; 
  this.savelabel="Save";
  this.activep=true;
  this.idp=0;
  this.selectedSUbTask=[];
  this.taskSubfolderData=[];
  this.taskSubSubfolderData=[];
  this.selectedSUbSubTask=[];
  this.mapId=0;
  this.GetAllTasks('','');
}
filterPhase(event) {
  let filtered : any[] = [];
  let query = event.query;
  for(let i = 0; i < this.PhaseData.length; i++) {
      let doctype = this.PhaseData[i];
      if (doctype.phaseName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(doctype);
      }
  }    
  this.filteredPhase = filtered;
}
filterTask(event) {
  let filtered : any[] = [];
  let query = event.query;
  for(let i = 0; i < this.taskData.length; i++) {
      let doctype = this.taskData[i];
      if (doctype.documentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(doctype);
      }
  }    
  this.filteredTask = filtered;
}
}
