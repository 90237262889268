import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})

export class MydocsService { 
  constructor(private _httputilityService: HttputilityService) {}
//baseUrl: any = "http://132.148.242.138/FPDMS/api/";

GetEmailNotifications(model)
{
  return this._httputilityService.Add("GetEmailNotifications", model);
}
UpdateOrginator(model) {
  return this._httputilityService.Add("UpdateOrginator", model);
}
mydocumentsData(model) {
  return this._httputilityService.Add("MyDocuments", model);
}
myobsdocumentsData(model) {
  return this._httputilityService.Add("MyObsDocuments", model);
}

searchDocuments(searchdata){
  return this._httputilityService.Add('SearchDocuments',searchdata);
}
IsResubmitDocumentAvailable(docId){
  return this._httputilityService.Get('IsResubmitDocumentAvailable?docId='+docId);
}
sendRemainder(docId){
  return this._httputilityService.Add("SendReminderForApproval?docId="+docId+'&isManual=true','');
}
SendRemainderExpiryReview(docId,action){
  return this._httputilityService.Add("SendReminder?docId="+docId+'&action='+action,'');
}
GetApplications(){
  return this._httputilityService.GetU('user/GetApplications');
}

getDelegations(model){
  return this._httputilityService.Add("GetDelegations", model);
}

cancelDelegation(DGID,UID){
  return this._httputilityService.Add("CancelDelegation?DGID="+DGID+'&UID='+UID,'');
}
cancelDelegations(UID,model){
  return this._httputilityService.Add("cancelDelegations?UID="+UID,model);
}
DeleteDocument(docid){
  return this._httputilityService.Add("DeleteDocument?ID="+docid,'');
}
ReviewDocumentsByDays(days,userId){
  return this._httputilityService.Get("ReviewDocumentsByDays?days="+days+"&userId="+userId);
}
SendEmail(userId,reportName,documents){
  return this._httputilityService.Add("SendReports?userId="+userId+'&reportName='+reportName,documents);
}
SendReviewReport(userId,reportName,documents){
  return this._httputilityService.Add("SendReviewReport?userId="+userId+'&reportName='+reportName,documents);
}
SendSummaryReport(userId,reportName,documents){
  return this._httputilityService.Add("SendSummaryReport?userId="+userId+'&reportName='+reportName,documents);
}
IsCMSResubmitDocumentAvailable(docId){
  return this._httputilityService.Get('IsCMSResubmitDocumentAvailable?docId='+docId);
}
downloadFile(filename,newname){
  return this._httputilityService.downloadFile('Download?filename='+filename+"&newname="+newname);
}
UpdateDocument(data){
  return this._httputilityService.DMSPost("UpdateDocument",data);
}
ResubmitDocument(data){
  return this._httputilityService.DMSPost("ResubmitDocument",data);
}
CreateDocument(data){
  return this._httputilityService.DMSPost("CreateDocument",data);
}
SignOffDocument(data){
  return this._httputilityService.DMSPost("SignOffDocument",data);
}
AddDelegations(data){
  return this._httputilityService.Add("AddDelegations",data);
}
AddDelegation(data){
  return this._httputilityService.Add("AddDelegation",data);
}
CreateStandard(data){
  return this._httputilityService.Add("CreateStandard",data);
}
AddClause(data){
  return this._httputilityService.Add("AddClause",data);
}
CreateProfile(data){
  return this._httputilityService.DMSPost("CreateProfile",data);
}
updateProfile(data){
  return this._httputilityService.DMSPost("updateProfile",data);
}
GetProductLines(){
  return this._httputilityService.GetProd('CustomFields/GetData?Query=select * from tblproductline');
}
getObsDoctypes(){
  return this._httputilityService.Get('GetDocumentTypes');
}
GetNewCode(doctypeId,parentdocId,action){
  return this._httputilityService.Get('GetNewCode?doctypeId='+doctypeId+"&parentdocId="+parentdocId+"&action="+action);
}
GetNewECN(){
  return this._httputilityService.Get('GetNewECN');
}
}
