import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss'],
  styles: [
    `
      :host ::ng-deep .p-multiselect {
        min-width: 15rem;
      }
      :host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
        min-width: 20rem;
      }
      :host ::ng-deep .multiselect-custom .p-multiselect-label {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
      :host ::ng-deep .multiselect-custom .country-item.country-item-value {
        padding: 0.25rem 0.5rem;
        border-radius: 3px;
        display: inline-flex;
        margin-right: 0.5rem;
        background-color: var(--primary-color);
        color: var(--primary-color-text);
      }
      :host
        ::ng-deep
        .multiselect-custom
        .country-item.country-item-value
        img.flag {
        width: 17px;
      }
      :host ::ng-deep .multiselect-custom .country-item {
        display: flex;
        align-items: center;
      }
      :host ::ng-deep .multiselect-custom .country-item img.flag {
        width: 18px;
        margin-right: 0.5rem;
      }
      :host ::ng-deep .multiselect-custom .country-placeholder {
        padding: 0.25rem;
      }
      :host ::ng-deep .p-colorpicker {
        width: 2.5em;
      }
    `,
  ],
})
export class AddProfileComponent implements OnInit {

    userId = parseInt(sessionStorage.getItem("userId"));
    profileId = parseInt(sessionStorage.getItem("profileId"));
    apiResponse: any;
    responseError: any;
    selectedDocType: any;

    workFlowModel: any;
    workFlowData: any;
    docTypeError: any;
    docsTypesData: any;

    departments : any[];
    selectedDepartments: any=[];
    usersListdisplay:boolean=false;
    departmentError: any;
    profileNameError:any;
    roles = [];
    profileRoleModel: any;
    showLoading:boolean;
    public departmentArray: any[] = [];
    public approvalNumbersArray: any[] = [];
    public checkedRolesArray: any[] = [];
    public profileRolesArray: any[] = [];
     profileRoles:any[]=[];
     rolesUsers:any=[];
     departmentRoleName:any;
     level: SelectItem[];
     selectedlevel: any;
     userFeature:boolean;
     displayNameError:any;
  //rolesObject: any;
  //public rolesArray: any[] = [];

  profileForm = {
    profileName: '',
    noOfApprovals: ''
  };

  constructor(
    private facadeService: FacadeService, 
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="SHOW_SUBMIT_RESET_PF");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    this.departments = [];
    this.selectedDepartments = [];  
    this.level = [
      { label: 'DCC', value: 'DCC'},
      { label: 'Major1', value: 'Major1'},
      { label: 'Major2', value:'Major2'},
      { label: 'Major3', value: 'Major3' },
      { label: 'Minor', value: 'Minor' },
      { label: 'Supervisor', value: 'Supervisor' }
    ];

    // this.getDocsTypes();
    this.getDepartments();
  }

  getDocsTypes() {
    this.showLoading = true;
    this.facadeService.getDocsTypes().subscribe(
      (data) => {
        this.showLoading = false;
        this.getDepartments();
        this.docsTypesData = data.returnObject;
        console.log("Document types ::" + JSON.stringify(this.docsTypesData));
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getDepartments() {
    this.showLoading = true;
    this.facadeService.getDepartMents().subscribe((response: any) => {
      this.departments = response.returnObject;
      this.showLoading = false;
      console.log("Departments ::" + JSON.stringify(this.departments));
      if(this.profileId>0){
        this.GetProfileDetailsByID();
      }
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    });
  }

  noOfApprClick(role,noofapp){
    if(noofapp=="" || noofapp=="0" ||noofapp=="00"){
      this.roles.forEach((value, key) => {
        if(value.roleGuid==role.roleGuid){
          value.selected=false;
          value.noofapprov="";
          value.approvalNumbers="";
        }
              });
              this.checkedRolesArray.forEach((value, key) => {
                if(value.roleGuid===role.roleGuid){
                  this.checkedRolesArray.splice((key), 1);
                }
              });
              this.profileRoles.forEach((value, key) => {
                if(value.roleGuid===role.roleGuid){
                  this.profileRoles.splice((key), 1);
                }
              });
    }
    else{
      if (this.checkedRolesArray.length>0) {
        this.roles.forEach((value, key) => {
          if(value.roleGuid==role.roleGuid){
            value.selected=true;
            value.noofapprov=noofapp;
            value.approvalNumbers=noofapp;
          }
                });
  
                let isexist= false;
                this.checkedRolesArray.forEach((value, key) => {
                  if(value.roleGuid===role.roleGuid){
                   value.selected==true;
                   value.noofapprov=noofapp;
                   value.approvalNumbers=noofapp;
                   isexist=true;
                  }
                });
                if(!isexist){
                  this.roles.forEach((value, key) => {
                    if(value.roleGuid==role.roleGuid){
                      this.checkedRolesArray.push(value);
                      this.profileRoles.push(value);
                    }
                          });
                }
      }
      else{
        this.roles.forEach((value, key) => {
          if(value.roleGuid==role.roleGuid){
            value.selected=true;
            value.noofapprov=noofapp;
            value.approvalNumbers=noofapp;
            this.checkedRolesArray.push(value);
            this.profileRoles.push(value);
          }
                });
      }
    }
  }

  getRolesByDepartmentGuids(){
    this.departmentError="";
    this.departmentArray = [];
    this.checkedRolesArray=[];
    if(this.selectedDepartments.length>0){
      this.showLoading = true;
      for (var i in this.selectedDepartments) {
        this.departmentArray.push(this.selectedDepartments[i].departmentGuid);
      }
      this.facadeService
        .getRolesByDepartmentGuids(this.departmentArray)
        .subscribe((result: any) => {
          this.showLoading = false;
          this.roles = result.returnObject;
          this.roles.forEach(element => {
            var d = this.selectedDepartments.find(m=>m.departmentGuid==element.departmentGuid);
            if(d!=null){
             element.departmentId=d.departmentId;
            }
          });
          if(this.profileRoles!=null && this.profileRoles!=undefined&& this.profileRoles.length>0){
            this.profileRoles.forEach((rd, key) => {
              this.roles.forEach((r, key) => {
                if(rd.roleGuid==r.roleGuid){
                  r.noOfApprovals=rd.approvalNumbers;
                  r.selected=true;
                  this.checkedRolesArray.push(r);
                }
              });
            });
          }
          console.log("Current selected roles:: " +  JSON.stringify(this.roles));
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        });
    }
    else{
      this.departmentError="Please select atlest one department.";
      this.roles =[];
      this.approvalNumbersArray=[];
    }
  }

  selectedRoles(roleObj) {
    if (roleObj.selected==false) {
      this.checkedRolesArray.forEach((value, key) => {
        if(value.roleGuid===roleObj.roleGuid){
          this.checkedRolesArray.splice((key), 1);
        }
      });
      this.profileRoles.forEach((value, key) => {
        if(value.roleGuid===roleObj.roleGuid){
          this.profileRoles.splice((key), 1);
        }
      });
      this.roles.forEach((value, key) => {
        if(value.roleGuid==roleObj.roleGuid){
          value.noOfApprovals="";
          value.approvalNumbers="";
          value.selected=false;
        }
      });
    } 
    else {
      this.roles.forEach((value, key) => {
        if(value.roleGuid==roleObj.roleGuid){
          value.noOfApprovals="1";
          value.approvalNumbers="1";
          value.selected=true;
          this.checkedRolesArray.push(value);
          this.profileRoles.push(value);
        }
              });
    }
  }

  deletewfProfile(){
    console.log('profileid------', window.history.state.wfdata.profileId);
    this.showLoading = true;
    this.facadeService.deleteProfile( window.history.state.wfdata.profileId ).subscribe(
      (data) => {
        this.showLoading = false;
        this.router.navigate(['/workflow/wfprofile']);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  createProfile() {
   this.departmentError="";
   this.responseError="";
   this.profileNameError="";
   this.displayNameError="";
     if (this.profileForm.profileName == undefined || this.profileForm.profileName == '') {
      this.profileNameError = 'Please enter Profile Name.';
    }
    else if (this.selectedlevel == "" || this.selectedlevel == null || this.selectedlevel == undefined) {
      this.showLoading = false;
      this.displayNameError = "Please select approval level";
    }   
    else if (this.selectedDepartments == undefined || this.selectedDepartments.length == 0) {
      this.departmentError = 'Please select departments.';
    }
    else if(this.checkedRolesArray.length==0){
      this.responseError = 'Please select atleast one row in the data.';
    }
    else{
      
      this.profileRolesArray = [];
  
      for(var j in this.checkedRolesArray){
        for (var i in this.roles) {
          if(this.roles[i].roleGuid == this.checkedRolesArray[j].roleGuid)
          {
            this.profileRoleModel = {
              pdid: 0,
              profileId: this.profileId>0?this.profileId:0,
              departmentId: this.roles[i].departmentId,
              departmentGuid: this.roles[i].departmentGuid,
              roleId: this.roles[i].roleId,
              roleGuid: this.roles[i].roleGuid,
              approvalNumbers:this.checkedRolesArray[j].noOfApprovals ,
            };
            this.profileRolesArray.push(this.profileRoleModel);
          }
        }
      }
      if(this.profileId>0){
        this.UpdateProfile();
      }
      else{
        this.SaveProfile();
      }
    }
  }

  SaveProfile(){
    this.showLoading = true;
    var profile: any = new FormData();
      profile.append('Id', 0);
    profile.append('Name', this.profileForm.profileName);
    //profile.append('DocumentTypeId', 0);
    //profile.append('DocumentType ', "");
    profile.append('DisplayName', this.selectedlevel);
    profile.append('CreatedBy', this.userId);
    profile.append('ProfileRolesJson', JSON.stringify(this.profileRolesArray));

    profile.forEach((value, key) => {
      console.log(key + " " + value);
    });
    console.log("Create Profile selected roles:: " +  JSON.stringify(this.profileRolesArray));
    this.facadeService.createProfile(profile).subscribe((response) => {
      
      console.log("Response :: " +  JSON.stringify(response));
      this.apiResponse = response;
      if(this.apiResponse.returnCode >= 0){
        this.router.navigate(['workflow/wfprofile']);
      }
      else{
        this.responseError = this.apiResponse.returnMessage;
      }
      this.showLoading = false;
    },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
  }

  UpdateProfile(){
    this.showLoading = true;
    var profile: any = new FormData();
      profile.append('Id', this.profileId);
    profile.append('Name', this.profileForm.profileName);
    //profile.append('DocumentTypeId', 0);
    //profile.append('DocumentType ', "");
    profile.append('DisplayName', this.selectedlevel);
    profile.append('CreatedBy', this.userId);
    profile.append('ProfileRolesJson', JSON.stringify(this.profileRolesArray));

    profile.forEach((value, key) => {
      console.log(key + " " + value);
    });
    console.log("Create Profile selected roles:: " +  JSON.stringify(this.profileRolesArray));
    this.facadeService.updateProfile(profile).subscribe((response) => {
      console.log("Response :: " +  JSON.stringify(response));
      this.apiResponse = response;
      if(this.apiResponse.returnCode >= 0){
        this.router.navigate(['workflow/wfprofile']);
      }
      else{
        this.responseError = this.apiResponse.returnMessage;
      }
      this.showLoading = false;
    },
      (error) => {
        this.showLoading = false;
        console.log(error);
      });
  }

  GetProfileDetailsByID() {
    this.selectedDepartments=[];
    this.showLoading = true;
    this.facadeService.GetProfileDetailsByID(this.profileId).subscribe(
      (data) => {
        this.showLoading = false;
        var profileData = data.returnObject; 
        this.profileForm.profileName= profileData[0].name;
        this.selectedlevel= profileData[0].displayName;
        var selDept=[];
        profileData[0].profileDepartments.forEach((value, key) => {
        this.departmentArray.push(value.departmentGuid);
        var sel = this.departments.find(m=>m.departmentGuid==value.departmentGuid);
        if(sel!=null){
          selDept.push(sel);
        }
        });
        this.selectedDepartments=selDept;
        this.profileRoles = profileData[0].profileRoles;
          this.getRolesByDepartmentGuids();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

 /*
  getWorkFlowsList(selectedDoc) {
    this.workFlowModel = {
      documentTypeId: selectedDoc.documentTypeId,
    };
    this.facadeService.workFlowsList(this.workFlowModel).subscribe(
      (data) => {
        this.workFlowData = data.returnObject;
        //console.log("Inner WF ::" + JSON.stringify( this.workFlowData));
      },
      (error) => {
        console.log(error);
      }
    );
  }
  */
 resetCtrls()
 {
  this.docTypeError="";
  this.responseError="";
  this.profileNameError="";
  this.departmentError="";
  // this.departments = [];
  this.selectedDepartments = [];
  this.profileRoles=[];
  if(this.profileId>0){
    this.getDocsTypes();
  }
  else{
    this.selectedlevel = "";   
    this.selectedDocType="";
    this.profileForm.profileName="";
    this.profileForm.noOfApprovals='';
    this.approvalNumbersArray=[];
    this.roles =[];
  }
  
 }
 isNumberKey(evt)
 {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
  }
  return true;
}
ViewUsers(roleGuid,deptName,roleName){
  this.rolesUsers=[];
  this.showLoading = true;
  this.departmentRoleName="";
  this.facadeService.GetUsersByRole(roleGuid).subscribe(
    (data) => {
      this.showLoading = false;
      data.returnObject.forEach(element => {
        element.fullName= element.firstName+" "+element.lastName;
        this.rolesUsers.push(element);
      });
      console.log("GetUsersByRole ::" + JSON.stringify(this.rolesUsers));
      this.usersListdisplay=true;
      this.departmentRoleName = deptName+" / "+roleName;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}
}
