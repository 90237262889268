<div class="p-grid p-fluid">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <div class="card" style="min-height:600px;">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
              <a routerLink="/liststandard"> <button pButton pRipple type="button" icon="pi pi-chevron-left"
                  iconPos="left" label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                  style="width:auto;"></button></a>
            </div>
            <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12" style="text-align:center;">
              <h4 style="margin-top:5px;">Create Clause</h4>
            </div>
            <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12"></div>
            </div>
          <!-- <h4>Create Clause</h4> -->
          <hr />
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
              <br>
              <label for="standardName">Standard<span style="color: red">*</span></label>
              <p-dropdown inputId="standardName" [options]="standardNameData" [(ngModel)]="standardName"
                placeholder="Select" optionLabel="standardName">
              </p-dropdown>
              <small class="p-invalid">{{ standardNameError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-6">
              <br>
              <label for="serialNo">Clause<span style="color: red">*</span></label>
              <input id="serialNo" type="text" (keypress)="isNumberKey($event)" pInputText [(ngModel)]="serialNo"
                placeholder="Enter Clause" />
              <small class="p-invalid">{{ serialNoError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-6">

              <label for="shortDescription">Short Description<span style="color: red">*</span></label>
              <textarea [(ngModel)]="shortDescription" id="shortDescription" type="text" rows="4"
                pInputTextarea></textarea>
              <small class="p-invalid">{{ shortDescriptionError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-6">

              <label for="description">Description<span style="color: red">*</span></label>
              <textarea [(ngModel)]="description" id="description" type="text" rows="4" pInputTextarea></textarea>
              <small class="p-invalid">{{ descriptionError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-6">

              <label for="clauseGroup">Group<span style="color: red">*</span></label>
              <p-dropdown inputId="clauseGroup" [options]="clauseGroupData" [(ngModel)]="clauseGroup"
                placeholder="Select" optionLabel="clauseGroup">
              </p-dropdown>
              <small class="p-invalid">{{ clauseGroupError }}</small>
            </div>
            <!-- <div class="p-field-checkbox">
              <p-checkbox [(ngModel)]="checked" binary="true" inputId="binary"></p-checkbox>
              <label for="binary">{{checked}}</label>
            </div> -->
            <div class="p-field p-col-12 p-md-3">
              <br><br>
              <div class="p-field-checkbox">
                <p-checkbox name="group1" [(ngModel)]="checked" binary="true" [disabled]="true"></p-checkbox>
                <label for="active">Active</label>
              </div>
            </div>
            <div class="p-field p-col-12 p-md-3"> </div>
            <div class="p-field p-col-12 p-md-6">
              <br> <br>
            </div>
            <div class="p-field p-col-12 p-md-3">
              <br /><br>

            </div>
            <div class="p-field p-col-12">
              <div class="p-grid">
                <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
              <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
                  class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="createClause()"
                  style="float:right; width:100%"></button>
                  </div>                  
                <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
              <button pButton pRipple type="button" icon="pi pi-refresh" iconPos="left" label="Reset"
                class="p-button-raised p-button-secondary p-mr-2 p-mb-2" (click)="resetCtrls()"
                style="float:right;width:100%"></button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>