import { Injectable } from '@angular/core';
import { NgModel } from '@angular/forms';
import { HttputilityService } from '../services/httputility.service';
@Injectable({
  providedIn: 'root',
})
export class DocdetailsService {
  constructor(private _httputilityService: HttputilityService) {}
  //baseUrl: any = 'http://132.148.242.138/FPDMS/api/';

  getDocDetailsById(id,userId) {
    return this._httputilityService.Add('GetDocumentByID?ID=' + id + '&userId=' + userId, '');
  }

  sendForApproval(dId, wId) {
    return this._httputilityService.Add('SendForApprovalById?docId=' + dId + '&wId=' + wId, '');
  }
  SendForObsApproval(dId, wId){
    return this._httputilityService.Add('SendForObsApproval?docId=' + dId + '&wId=' + wId, '');
  }
  DocumentSupervisorPermissionLimitedAccess(UserGuid,typeId){
    return this._httputilityService.Get('DocumentSupervisorPermissionLimitedAccess?UserGuid=' + UserGuid + '&typeId=' + typeId);
  }
  documentAction(dId, uGId, action) {
    var apiUrl;
    if (action == 1) {
      return this._httputilityService.Add('ApproveDocument?Id=' + dId + '&userGuid=' + uGId, '' );
    }
    if (action == 2) {
      return this._httputilityService.Add('RejectDocument?Id=' + dId + '&userGuid=' + uGId, '' );
    }
    if (action == 3) {
      return this._httputilityService.Add('HoldDocument?Id=' + dId + '&userGuid=' + uGId, '');
    }
    if (action == 4) {
      return this._httputilityService.Add('ReleaseDocument?Id=' + dId + '&userGuid=' + uGId, '');
    }
  }

  ReleaseDocument(data) {
    return this._httputilityService.Add('ReleaseDocument', data);
  }

  ApproveDocument(data) {
      return this._httputilityService.Add('ApproveDocument', data);
  }

  RejectDocument(data) {
    return this._httputilityService.Add('RejectDocument', data);
}
HoldDocument(data) {
  return this._httputilityService.Add('HoldDocument', data);
}

GetECRNDetails(Ecrn){
  return this._httputilityService.GetCMS('GetChangeRequestDetailsByECRN?ECRN='+Ecrn);
}

  getApprovals(id) {
    return this._httputilityService.Get('GetPreApprovers?documentId=' + id);
  }
  GetDepartments(){
    return this._httputilityService.GetU('user/GetDepartments');
  }
}
