import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { BASE_API_URL_TOKEN } from './injectors';
import { BnNgIdleService } from './bn-ng-idle.service';
import { FacadeService } from './facade/facade.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {
  constructor(private httpClient: HttpClient, private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object, private bnIdle: BnNgIdleService) {
  }
  private config: { version: string };
  ngOnInit() {
    var timeout = this.baseUrl["API_ENDPOINTS"]["Timeout"];
    this.bnIdle.startWatching(timeout).subscribe((res) => {
      if (res) {
        var userId = parseInt(sessionStorage.getItem('userId'));
        var appId = parseInt(sessionStorage.getItem('appliId'));
        var modelData = {
          "userId": userId,
          "applicationId": appId,
          "source": "logout"
        };
        this._facadeService.AddUserTracking(modelData).subscribe(
          (data) => {
            console.log('session expired');
            sessionStorage.clear();
            location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
          },
          (error) => {
            console.log(error);
          }
        );

      }
    });
    this.config = null;//require("../assets/Version.json");
    //console.log(this.config.version);
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');

    this.httpClient
      .get<{ version: string }>("assets/Version.json", { headers })
      .subscribe(config => {
        console.log(config);
        if (config.version !== this.config.version) {
          this.httpClient
            .get("", { headers, responseType: "text" })
            .subscribe(() => location.reload());
        }
      });
  }
}
export const CONSTANTS = {
  'ums': '50629fadc11f421a91b890b6b500577e',
  'plm': 'cc0a99a2a4904d8bb844918eb1bcda25',
  'prt': '766185032c604d81ad4d23fa76b74e55',
  'authKey': 'd0e0d5adb67d499da5b11c0e141229bb'
}
export const API_ENDPOINTS = {
  "UMSAPI": "https://kineticv2.azure-api.net/ums/api/",
  "PLMAPI": "https://kineticv2.azure-api.net/plm/api/",
  "PRTAPI": "https://kineticv2.azure-api.net/prt/api/",
  "UMSAuthAPI": "https://kineticv2.azure-api.net/auth/api/"
}