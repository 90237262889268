<div class="p-grid table-demo">
    <div class="p-col-12">
        <div class="card" style="min-height: 600px">
                    <div class="p-grid p-fluid p-formgrid">
                        <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12"></div>
                        <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                    <button (click)="UserDelegation()" pButton pRipple type="button"
                        icon="pi pi-reply" iconPos="left" label="Add Delegation" class="p-button-raised p-button-primary"
                        style="width:100%; float:right;"></button>
                    </div>
            <p-tabView [activeIndex]="index" (onChange)="TabClick($event)">
                <p-tabPanel header="Delegations by me" leftIcon="pi pi-directions">
                    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:auto;">

                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Delegations by me - {{DocumentsCount}}</h5>
                        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
                           <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content','trSearch','Delegation by me',false)" pTooltip="Print"
                                tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>
                            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                                tooltipPosition="top" (click)="exportExcel1('excel-table','Delegation by me')"
                                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;  height:25px; width:25px;"></button>
                        </span>
                    </div>
                    <div class="invoice invoice-header" id="invoice-content">
                            <p-table #dt [value]="delegateData" id="excel-table" [(selection)]="selectedDelegates" [columns]="cols" sortMode="multiple"
                                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                                selectionMode="multiple" dataKey="delegationId"
                                [globalFilterFields]="['department','role','user','toDepartment','toRole','toUser','documentType','docid','revision','fromDate','toDate']">
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr>
                                        <th style="width: 60px;text-align: center;">
                                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                        </th>     
                                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                        [ngClass]="{'department': i == 0,'role': i == 1,'user': i == 2,'toDepartment': i == 3,
                                        'toRole': i == 4,'toUser': i == 5, 'fromDate': i == 9,'toDate': i == 10,'docid': i == 7,'revision': i == 8,'documentType': i == 6}">
                                            {{col.header}}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </th>
                                        <th style="width:60px"></th>
                                    </tr>
                                    <tr id="trSearch">
                                        <th></th>
                                        <th *ngFor="let col of columns" [ngSwitch]="col.field"
                                            style="text-align: center;">
                                            <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                            (input)="test($event.target.value,col.field,dt)"
                                                style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                        </th>
                                        <th></th>
                                    </tr>

                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <td style="text-align: center; width: 60px;">
                                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                                        </td>
                                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">

                                            <td *ngIf="i == 0" style="text-align: left;padding-left: 15px;width: 120px;">
                                                {{ rowData[col.field] }}
                                            </td>
                                            <td *ngIf="i == 1" style="text-align: left;padding-left: 15px;width: 120px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 2" style="text-align: left;padding-left: 15px;width: 120px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 3" style="text-align: left;padding-left: 15px;width: 130px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 4" style="text-align: left;padding-left: 15px;width: 100px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 5" style="text-align: left;padding-left: 15px;width: 100px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 6" style="text-align: center;width: 120px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 7" style="text-align: center;width: 100px;">
                                                <a href="javascript:;" (click)="GetDoc(rowData)">
                                                    {{rowData[col.field]}}
                                                    </a> 
                                            </td>
                                             <td *ngIf="i == 8" style="text-align: center;width: 100px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 9" style="text-align: center;width: 100px;">
                                                {{rowData[col.field]| date: 'MM/dd/yyyy'}}
                                            </td>
                                            <td *ngIf="i == 10" style="text-align: center; width:60px;">
                                                {{rowData[col.field]| date: 'MM/dd/yyyy'}}
                                            </td>
                                        </ng-container>
                                        <td style="text-align: center; width:60px;">
                                            <button pButton type="button" (click)="ViewComment(rowData.comments)" icon="pi pi-eye" pTooltip="View" class="p-button-rasised p-button-primary"
                                  style="height:25px; width:25px;"></button>
                                          </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="12">No documents found.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                    </div>
                    &nbsp;
                    <div class="p-field p-col-12">
                    <button *ngIf="DelegationExist" (click)="CancelDelegateClick()" pButton pRipple type="button"
                        icon="pi pi-reply" iconPos="left" label="Cancel Delegation"
                        class="p-button-raised p-button-primary" style="float:right;width: auto; "></button>
                        <small class="p-invalid">{{ delegateError }}</small>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Documents delegated to others" leftIcon="pi pi-directions">
                    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto; ">

                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Documents delegated to others - {{DocumentsCount}}</h5>
                        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
                            <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content1','trSearch1','My Approval Documents',true)" pTooltip="Print"
                                tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                                style="float:right; height:25px; width:25px;"></button>
                            <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel3('excel-table1','My Approval Documents')"
                                pTooltip="Download" tooltipPosition="top"
                                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
                        </span>
                    </div>
                    <div class="invoice invoice-header" id="invoice-content1">
                        <p-table #dt1 [value]="docsData" id="excel-table1"  [virtualScroll]="true" [scrollable]="true" scrollHeight="200px" [columns]="cols1" sortMode="multiple"
                            class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                            selectionMode="multiple" dataKey="id"
                            [globalFilterFields]="['code','title','departmentName','revision','eco','owner','status']">
                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                <tr>
                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                    [ngClass]="{'code': i == 0,'title': i == 1,'departmentName': i == 2,'revision': i == 3,
                                    'eco': i == 4,'owner': i == 5, 'status': i == 6}">
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="price" style="width:80px;">View</th>
                                </tr>
                                <tr id="trSearch1">
                                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                        (input)="test($event.target.value,col.field,dt1)"
                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                    </th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                                <tr [pSelectableRow]="rowData">
                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                                            <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                                         <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                                        </td>
                                        <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 250px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 2" style="text-align: left;width: 180px;padding-left:15px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 3" style="text-align: center;width: 70px">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 4" style="text-align: left;padding-left:15px; width: 70px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 150px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 100px;"
                                        [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                                        {{rowData[col.field]}}
                                        </td>
                                    </ng-container>
                                    <td style="text-align: center;width: 80px;">
                                        <button pButton type="button"
                                            (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId)"
                                            icon="pi pi-eye" pTooltip="View" tooltipPosition="left"
                                            class="p-button-rasised p-button-seconday"
                                            style="height:25px; width:25px;"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8" style="text-align: left;">No documents found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Delegations to me" leftIcon="pi pi-directions">
                    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:auto;">

                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Delegations to me - {{DocumentsCount}}</h5>
                        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
                            <!-- <a routerLink="/delegation"><button pButton type="button" pTooltip="Add Delegation"tooltipPosition="top" 
                            class="p-button-rounded p-button-help p-mr-2 p-mb-2" icon="pi pi-plus" 
                           style="height:25px; width:25px;"></button></a>
                            -->
                            <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content2','trSearch2','Delegations to me',false)" pTooltip="Print"
                                tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                                style="float:right;height:25px; width:25px;"></button>
                            <!-- <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" style="float:right; margin-left:10px ; "></button> -->
                            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                                tooltipPosition="top" (click)="exportExcel1('excel-table2','Delegations to me')"
                                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right;  height:25px; width:25px;"></button>

                        </span>
                    </div>
                    <div class="invoice invoice-header" id="invoice-content2">
                            <p-table #dt2 [value]="delegateData" id="excel-table2"  [virtualScroll]="true" [scrollable]="true" scrollHeight="200px" [columns]="cols" sortMode="multiple"
                                class="table-align" styleClass="p-datatable-striped" [filterDelay]="0"
                                selectionMode="multiple" dataKey="delegationId"
                                [globalFilterFields]="['department','role','user','toDepartment','toRole','toUser','documentType','docid','revision','fromDate','toDate']">
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr>
                                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                        [ngClass]="{'department': i == 0,'role': i == 1,'user': i == 2,'toDepartment': i == 3,
                                        'toRole': i == 4,'toUser': i == 5, 'fromDate': i == 9,'toDate': i == 10,'docid': i == 7,'revision': i == 8,'documentType': i == 6}">
                                            {{col.header}}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </th>
                                        <th style="width:30px"></th>
                                    </tr>
                                    <tr id="trSearch2">
                                        <th *ngFor="let col of columns" [ngSwitch]="col.field"
                                            style="text-align: center;">
                                            <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                            (input)="test($event.target.value,col.field,dt2)"
                                                style="margin: -10px 0 -10px 0; width:90%; height:25px;">
                                        </th>
                                        <th></th>
                                    </tr>

                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr [pSelectableRow]="rowData">
                                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                            <td *ngIf="i == 0" style="text-align: left;padding-left: 10px;width: 120px;">
                                                {{ rowData[col.field] }}
                                            </td>
                                            <td *ngIf="i == 1" style="text-align: left;padding-left: 15px;width: 120px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 2" style="text-align: left;padding-left: 10px;width: 120px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 3" style="text-align: left;padding-left: 10px;width: 130px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 4" style="text-align: left;padding-left: 10px;width: 100px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 5" style="text-align: left;padding-left: 10px;width: 100px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 6" style="text-align: center;width: 120px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 7" style="text-align: center;width: 100px;">
                                                <a href="javascript:;" (click)="GetDoc(rowData)">
                                                    {{rowData[col.field]}}
                                                      </a> 
                                            </td>
                                             <td *ngIf="i == 8" style="text-align: center;width: 100px;">
                                                {{rowData[col.field]}}
                                            </td>
                                        
                                        <td *ngIf="i == 9" style="text-align: left;padding-left: 1px;width: 5%;">
                                           
                                           {{rowData[col.field] | date: 'MM/dd/yyyy'}}
                                       </td>
                                            <td *ngIf="i == 10" style="text-align: left;padding-left: 1px;width: 10%;">
                                                {{rowData[col.field] | date: 'MM/dd/yyyy'}}
                                            </td>
                                            <!-- <td *ngIf="i == 0" style="text-align: left;padding-left: 15px;width: 150px;">
                                                {{ rowData[col.field] }}
                                            </td>
                                            <td *ngIf="i == 1" style="text-align: left;padding-left: 15px;width: 150px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 2" style="text-align: left;padding-left: 15px;width: 150px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 3" style="text-align: left;padding-left: 15px;width: 150px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 4" style="text-align: left;padding-left: 15px;width: 150px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 5" style="text-align: left;padding-left: 15px;width: 150px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 6" style="text-align: center;width: 120px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 7" style="text-align: center;width: 120px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 8" style="text-align: center;width: 80px;">
                                                {{rowData[col.field]}}
                                            </td> -->
                                        </ng-container>
                                        <td style="text-align: center;">
                                            <button pButton type="button" (click)="ViewComment(rowData.comments)" icon="pi pi-eye" pTooltip="View" class="p-button-rasised p-button-primary"
                                  style="height:25px; width:25px;"></button>
                                          </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="12">No documents found.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Documents delegated to me" leftIcon="pi pi-directions">
                    <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;">

                        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Documents delegated to me - {{DocumentsCount}}</h5>
                        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
                            <button pButton pRipple type="button" icon="pi pi-print" (click)="print('invoice-content3','trSearch3','Documents delegated to me',true)" pTooltip="Print"
                                tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                                style="float:right; height:25px; width:25px;"></button>
                            <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel3('excel-table3','Documents delegated to me')"
                                pTooltip="Download" tooltipPosition="top"
                                class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                                style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
                        </span>
                    </div>
                    <div class="invoice invoice-header" id="invoice-content3">
                        <p-table #dt3 [value]="docsData" id="excel-table3" [columns]="cols2" sortMode="multiple"
                            class="table-align" [virtualScroll]="true" [scrollable]="true" scrollHeight="380px" styleClass="p-datatable-striped" [filterDelay]="0"
                            selectionMode="multiple" dataKey="id"
                            [globalFilterFields]="['code','title','departmentName','revision','eco','owner','status']">
                            <ng-template pTemplate="header" let-columns class="invoice-items">
                                <tr>
                                    <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                    [ngClass]="{'code': i == 0,'title': i == 1,'departmentName': i == 2,'revision': i == 3,
                                    'eco': i == 4,'owner': i == 5, 'status': i == 6}">
                                        {{col.header}}
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th pSortableColumn="price" style="width:80px;">View</th>
                                </tr>
                                <tr>
                                    <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                        <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                        (input)="test($event.target.value,col.field,dt3)"
                                            style="margin: -10px 0 -10px 0; width:95%; height:25px;">
                                    </th>
                                    <th></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                                <tr [pSelectableRow]="rowData">
                                    <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                        <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                                            <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                                            <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                                        </td>
                                        <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 250px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 2" style="text-align: left;width: 180px;padding-left:10px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 3" style="text-align: center;width: 60px">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 4" style="text-align: center;width: 70px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 150px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 100px;"
                                        [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                                        {{rowData[col.field]}}
                                        </td>
                                        <!-- <td *ngIf="i == 0" style="text-align: left; padding-left:15px;width: 100px;">
                                            {{ rowData[col.field] }}
                                        </td>
                                        <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 350px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 150px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 60px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 60px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 150px;">
                                            {{rowData[col.field]}}
                                        </td>
                                        <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 100px;"
                                            [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':'#D32F2F'}">
                                            {{rowData[col.field]}}
                                        </td> -->
                                    </ng-container>
                                    <td style="text-align: center;width: 80px;">
                                        <button pButton type="button"
                                            (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId)"
                                            icon="pi pi-eye" pTooltip="View" tooltipPosition="left"
                                            class="p-button-rasised p-button-seconday"
                                            style="height:25px; width:25px;"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8" style="text-align: left;">No documents found.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
        <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 5001;"
            *ngIf="showLoading">
            <i class="pi pi-spin pi-spinner"
                style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
        </div>

        <span>
            <p-dialog header="Cancel Delegation" [(visible)]="deleteDisplay" modal="modal" showEffect="fade"
              [style]="{ width: '80%' }">
              <hr />
              <div class="p-grid p-fluid p-formgrid">
                <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12" style="text-align:center;">
                  <h4 style="color:red">Are you sure you want to cancel the selected delegates?</h4>
                </div>
                <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="margin-top:8px;">
                  <!-- <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;"> -->
                    <button pButton type="button" label="Yes" icon="pi pi-check-circle" iconPos="left"
                      (click)="CancelDelegation()" class="p-button-raised p-button-danger"></button>
                </div>
                <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="margin-top:8px;">
                    <button pButton type="button" label="No" icon="pi pi-times" iconPos="left"
                      (click)="deleteDisplay = false" class="p-button-raised p-button-secondary"></button>
                  <!-- </span> -->
                </div>
              </div>
            </p-dialog>
        </span>
        <p-dialog header="Comments" [(visible)]="commentDisplay" modal="modal" showEffect="fade"
        [style]="{ width: '80%' }">
        <hr />
        <div class="p-grid">

          <div class="p-col-12 p-md-7" style="text-align:center;margin-top: 25px;">
            <h4>{{commentText}}</h4>
          </div>
        </div>
      </p-dialog>