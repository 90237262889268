import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../../services/print.service";
import { Table } from 'primeng/table';

@Component({
  selector: 'app-wfsetup',
  templateUrl: './wfsetup.component.html',
  styleUrls: ['./wfsetup.component.scss']
})
export class WfsetupComponent implements OnInit {
  @ViewChild("dt") table: Table;
  workflowSetups:any[];
  workflowsData: any;
  cols: any[];
  dynamicPlaceHolder: string;
  showLoading:boolean;
  workflowsetupDisplay:boolean;
  workFlowError: any;
  docTypeError: any;
  selectedDocType: any;
  selectedWorkflow: any;
  workflowdefinitions = [];
  apiResponse: any;
  DocumentsCount=0;
  responseError: any;
  docsTypesData: any;
  deleteDisplay:boolean;
  AreYouSureText:any;
  docId = parseInt(sessionStorage.getItem('docId'));
  // elsaURL:any;
  filteredDocType: any[];
  filteredWFDef: any[];
  setupId:any;
  userFeature:boolean;
  userFeature1:boolean;
  constructor(
    private facadeService: FacadeService, public printService: PrintService,
    private router: Router,private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DSBL_NEW_DEL_WFMAP");
        if(featureExist!=null){
          this.userFeature = true;
        }

        var featureExist1 = feature.find(m=>m.code=="SHOW_WFDESIGN");
        if(featureExist1!=null){
          this.userFeature1 = true;
        }
      }
    }
    this.getDocsTypes();
    this.getWorkflows();
   
    this.cols = [
      { field: 'documentType', header: 'Document Type', dynamicPlaceHolder: 'Search by Document Type' },
      { field: 'workflowDefinitionName', header: 'Workflow Definition Name', dynamicPlaceHolder: 'Search by Workflow Definition Name'}
    
    ];
sessionStorage.documentType="";
sessionStorage.workflowDefinitionName="";
  }

  filterDocTypes(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.docsTypesData.length; i++) {
        let doctype = this.docsTypesData[i];
        if (doctype.documentType.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(doctype);
        }
    }    
    this.filteredDocType = filtered;
}

  getDocsTypes() {
    this.showLoading = true;
    this.facadeService.getDocsTypes().subscribe(
      (data) => {
        this.showLoading = false;
        this.docsTypesData = data.returnObject;
        console.log(this.docsTypesData);
        console.log("Document Identifier ::" + JSON.stringify(this.docId));       
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  } 

  getWorkflows() {
    this.showLoading = true;
    this.facadeService.getAllWorkflows().subscribe(
      (data) => {
        this.showLoading = false;
        this.workflowsData = data.returnObject;
        console.log(this.workflowsData);
        console.log("workflow Data ::" + JSON.stringify( this.workflowsData));  
        this.CountData();     
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  } 

//   exportExcel() {
//     /* table id is passed over here */   
//     let element = document.getElementById('excel-table'); 
//     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//     /* generate workbook and add the worksheet */
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     this.delete_row(wb.Sheets.Sheet1, 1);
//     ws['!cols'] = [{ wpx : 250},{ wpx : 350 }];
//     /* save to file */
//     XLSX.writeFile(wb, "Workflow List.xlsx");
// }

exportExcel() {
  // Manually load all data
  this.table.first = 0;
  this.table.rows = this.table.totalRecords;

  // Wait for data to load (assuming it's loaded asynchronously)
  setTimeout(() => {
    const data = this.getDataFromTable(this.table);

    // Proceed with exporting data to Excel
    this.exportDataToExcel(data);
  }, 100); // Adjust the timeout as needed
}

// Helper function to get visible columns and data from the p-table
getDataFromTable(table: Table): { columns: any[], data: any[] } {
  const visibleColumns = table.columns.filter(column => column.field);
  const columns = visibleColumns.map(column => {
    return {
      header: typeof column.header === 'object' ? column.header['name'] : column.header,
      field: column.field
    };
  });
  // Use the 'filteredValue' property if available, otherwise fallback to 'value'
  const data = (table.filteredValue || table.value).map(item => {
    const rowData = {};
    visibleColumns.forEach(column => {
      const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
      rowData[columnHeader] = item[column.field];
    });
    return rowData;
  });

  return { columns, data };
}

// Example function to export data to Excel (you can customize this based on your needs)
exportDataToExcel(data: { columns: any[], data: any[] }) {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });

  // Add headers separately
  const headerRows = [data.columns.map(column => column.header)];
  XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Customize the column widths if needed
  ws['!cols'] = [{ wpx : 250},{ wpx : 350 }];

  // Save to file
  XLSX.writeFile(wb, 'Workflow List.xlsx');
}

// delete_row (ws, row_index){
//   let range = XLSX.utils.decode_range(ws["!ref"])
//   for(var R = row_index; R < range.e.r; ++R){
//       for(var C = range.s.c; C <= range.e.c; ++C){
//         ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
//         if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
//           ws[this.ec(R+1, C)].v  = "";
//       }
//   }
//   range.e.r--
//   delete (ws['C1'])
//   ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
//   }
// ec(r, c) {
//   return XLSX.utils.encode_cell({r:r,c:c})
// }
print() {
 
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  // Get the filtered data of the table
  //const filteredData = this.table.filteredValue || this.table.value;
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
  this.printService.Print(Content,"Workflow List",'trSearch',true);
}

  createWorkflowSetup(){
    this.resetCtrls();
    this.getWorkflowDefinitions();
    this.workflowsetupDisplay=true;
  }

  openWorkflowDesign(){
    this.router.navigate(["/workflowlist"]);
  }

  createWokrflowSetUp() {
    this.workFlowError="";
    this.docTypeError="";
    this.responseError="";
    if (this.selectedDocType == undefined || this.selectedDocType == '') {
      this.docTypeError = 'Please select document type.';
    }
    else if (this.selectedWorkflow == undefined || this.selectedWorkflow == '') {
      this.workFlowError = 'Please select workflow.';
    }
    else{ 
      this.showLoading = true;
      console.log("workflowdefinitions: ", JSON.stringify(this.workflowdefinitions));
      const workflow = {
        setupId: 0,
        documentTypeId: Number(this.selectedDocType.documentTypeId),
        documentType: this.selectedDocType.documentType,
        workflowDefinitionId: this.selectedWorkflow.definitionId,//,
        workflowDefinitionName: this.selectedWorkflow.name,
        isDeleted: false
     }
     console.log("workflow: ", JSON.stringify(workflow));
      this.facadeService.createWorkflowSetup(workflow).subscribe(
        (response) => {
          this.showLoading = false;
          this.apiResponse = response;
          if(this.apiResponse.returnCode >= 0){
            this.workflowsetupDisplay=false;
            this.ngOnInit();
          }
          else{
            this.responseError = this.apiResponse.returnMessage;
          }
          console.log(response);
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );  
    }
  }
  resetCtrls()
  {
    this.workFlowError="";
    this.docTypeError="";
    this.selectedDocType="";
    this.selectedWorkflow="";
    this.responseError="";
  }
  deleteDisplayClick(rowdata){
    this.deleteDisplay=true;
    this.setupId=rowdata.setupId;
    this.AreYouSureText="Are you sure you want to delete "+ rowdata.documentType+" ?";
      }

      deleteDoc(){
        this.showLoading = true;
        this.facadeService.DeleteWFSetup(this.setupId).subscribe((response:any) => {
          this.showLoading = false;
          this.deleteDisplay = false;
          if(response.returnCode==0){
            this.ngOnInit();
          }
          else{
            alert(response.returnMessage);
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        });
      }

  getWorkflowDefinitions() {
    this.showLoading = true;
    this.facadeService.getAllWorkDefinitions(false).subscribe((response:any) => {
      this.showLoading = false;
      this.workflowdefinitions =  response.returnObject;//response.items;
      //BEGIN : Added by Suresh for latest worflows filter
      let latestwfs : any[] = [];
      if(this.workflowdefinitions !== null && this.workflowdefinitions !== undefined){
        this.workflowdefinitions.forEach(wfDef => {
          //if(wfDef.isLatest == true && wfDef.isPublished == true && wfDef.name !== null && wfDef.name !== undefined && wfDef.name !== ""){
          if(wfDef.name !== null && wfDef.name !== undefined && wfDef.name !== ""){
              latestwfs.push(wfDef);
          }
        });
      }
      this.workflowdefinitions = latestwfs;
      //END - Added by Suresh for latest worflows filter
      console.log(this.workflowdefinitions);
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    })
  }

  filterWFDefinitions(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.workflowdefinitions.length; i++) {
        let wfDef = this.workflowdefinitions[i];
        if (wfDef.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(wfDef);
        }
    }    
    this.filteredWFDef = filtered;
}
test(val,field,dt)
{
  if(field == "documentType")
    sessionStorage.documentType = val;
  else if(field == "workflowDefinitionName")
    sessionStorage.workflowDefinitionName = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.workflowsData != undefined && this.workflowsData != null)
  {
    var temp = this.workflowsData;
    if(sessionStorage.documentType != ""&&sessionStorage.documentType != null)
      temp = temp.filter(x=> x.documentType.toLowerCase().includes(sessionStorage.documentType.toLowerCase()));
    if(sessionStorage.workflowDefinitionName != ""&&sessionStorage.workflowDefinitionName != null)
      temp = temp.filter(x=> x.workflowDefinitionName.toLowerCase().includes(sessionStorage.workflowDefinitionName.toLowerCase()));      
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
}
