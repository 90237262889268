//import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { TabView } from 'primeng/tabview';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-skipapproval',
  templateUrl: './skipapproval.component.html',
  styleUrls: ['./skipapproval.component.scss'],
  providers:[ConfirmationService]
  
})
export class SkipapprovalComponent implements OnInit {
  delegateDocTypeTempDataExisting=[];
  Change : boolean = false;
  @ViewChild('tabView') tabView: TabView;
  filteredDept: any[];
  filteredToDept: any[];
  filteredRoles: any[];
  filteredToRoles: any[];
  constructor(
    private fb: FormBuilder,private confirmationService:ConfirmationService,
    private http: HttpClient,
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,
  ) {
    this.form = this.fb.group({
      name: [""],
      avatar: null,
    });
  }
  docsData=[];
  index=0;
  userId = parseInt(sessionStorage.getItem("userId"));
  userRoles = JSON.parse(sessionStorage.getItem("roles"));
  userDepartments = JSON.parse(sessionStorage.getItem("userdepts"));
  userGuid = sessionStorage.getItem('userGuid');
  apiResponse: any;
  today: string;
  responseError: any;
  docError="";
  minDate=new Date();
  cols: any[];
  MaindepartmentsData:any=[];
  MainrolesData:any=[];
  form: FormGroup;
  delegationModel: any;
  departmentsData: any;
  TodepartmentsData: any;
  fromRoleData: any;
  ToRolesData: any;
  fromUserData: any;  
  roleresponseError="";
  commentsrow="";
  ToUsersData: any;
  selectedDept: any;
  selectedUser: any;
  commentsdisplay:boolean;
  fromDate: any;
  toDate: any;
  toDateError: any;
  selectedDocType=[];
  selectedDocuments=[];
  RolesData: any;
  ToselectedDept: any;
  selectedRole: any=[];
  UsersData: any;
  ToselectedRole: any;
  ToselectedUser: any;
  fromUserError: any;
  fromRoleError: any;
  fromDateError: any;
  ErrorDisplayMessage:any;
  ToUserError: any;
  commentsError: any;
  TabChangeDisplay=false;
  docsTypesData=[];
  ErrorDisplay:boolean;
  comments: any;
  docsError="";
  delegateData: any=[];
  showLoading:boolean;
  filteredUsers:any=[];
    toRoleError="";
  filteredToUsersData:any=[];
  DisableFromUser:boolean;
  fromuserdisabled=false;
  delegateTempData=[];
  doccols=[];
  docTyperesponseError="";
  delegatedoccols=[];
  delegateDocTempData=[];
  delegateDocTypeTempData=[];
  fromDocTypeError="";
  doctypecols=[];
  RowCommentId=0;
  AllDelegations=[];
  delegateExistingTempData=[];
  skipapprovalExistingTempData=[];
  colsdel=[];
  skipDocTypeTempDataExisting=[];
  delegatetomeTempData=[];
  delegatetomeTempDatadoctype=[];
  @ViewChild("dt") table: Table;
  ngOnInit(): void {
    
    this.fromDate = new Date();
    this.doccols = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search', width: '130px' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search', width: '400px' }, 
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search',width: '80px'},
      { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Search', width: '140px'}, 
      { field: 'comments', header: 'Comment', dynamicPlaceHolder: 'Search',width: '100px'}
    ];
    this.cols = [
      { field: 'user', header: 'User',dynamicPlaceHolder:'Search', width: '130px' },
      { field: 'role', header: 'Role' ,dynamicPlaceHolder:'Search', width: '400px' },
      { field: 'fromDate', header: 'From Date' ,dynamicPlaceHolder:'Search', width: '110px' },
      { field: 'toDate', header: 'To Date',dynamicPlaceHolder: 'Search', width: '110px' },
      { field: 'comments', header: 'Comments',dynamicPlaceHolder: 'Search', width: '200px' },
    ];
    this.colsdel = [
      { field: 'user', header: 'User',dynamicPlaceHolder:'Search', width: '130px' },
      { field: 'role', header: 'Role' ,dynamicPlaceHolder:'Search', width: '400px' },
      { field: 'toUser', header: 'To User' ,dynamicPlaceHolder:'Search', width: '80px' },
      { field: 'toRole', header: 'To Role', dynamicPlaceHolder:'Search', width: '180px' },
      { field: 'comments', header: 'Comments',dynamicPlaceHolder: 'Search', width: '200px' },
      { field: 'fromDate', header: 'From Date' ,dynamicPlaceHolder:'Search', width: '110px' },
      { field: 'toDate', header: 'To Date',dynamicPlaceHolder: 'Search', width: '110px' },
    ];
    this.doctypecols = [
      { field: 'user', header: 'User',dynamicPlaceHolder:'Search', width: '140px'  },
      { field: 'documenttype', header: 'Doc Type' ,dynamicPlaceHolder:'Search', width: '110px' },
    
      { field: 'fromDate', header: 'From Date' ,dynamicPlaceHolder:'Search', width: '110px' },
      { field: 'toDate', header: 'To Date',dynamicPlaceHolder: 'Search' , width: '110px'},
      { field: 'comments', header: 'Comments',dynamicPlaceHolder: 'Search' , width: '230px'},
    ];
    this.delegatedoccols = [
      { field: 'user', header: 'User',dynamicPlaceHolder:'Search' },
      { field: 'document', header: 'Documents' ,dynamicPlaceHolder:'Search' },
      { field: 'toUser', header: 'To User' ,dynamicPlaceHolder:'Search' },
      { field: 'toRole', header: 'To Role', dynamicPlaceHolder:'Search' },
      { field: 'comments', header: 'Comments',dynamicPlaceHolder: 'Search' },
    ];
    this.GetAllDelegations();
    //this.getUsers();
    this.getDepartments();
    this.getRoles();
    
  }
  getUsers() {
    this.showLoading = true;
    this.fromUserData=[];
    this._facadeService.getUsers().subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject!=null && data.returnObject.length>0){
          let users=[];
          data.returnObject.forEach((value, key) => {
            if(value.firstName!=null && value.firstName!="" && value.firstName!=undefined
          && value.lastName !=null && value.lastName!="" && value.lastName!=undefined){
            value.firstName = value.firstName + " " + value.lastName;
          }
          users.push(value);
        });
        if(sessionStorage.FromAdmin=="true"){
          this.fromUserData=users;
          this.DisableFromUser=false;
        }
        else{
          this.DisableFromUser=true;
          this.fromUserData=users.filter(m=>m.userGuid==this.userGuid);
          this.selectedUser=this.fromUserData[0];
          this.fromGetUserDetails();
        }
          this.ToUsersData=users;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  fromGetUserDetails(){
    this.Change = true;
    this.departmentsData=[];
    this.RolesData=[];
    this.showLoading = true;
    this.fromUserError="";
    this.fromRoleError="";
    this.delegateExistingTempData=[];
    this.delegatetomeTempData=[];
    this.skipapprovalExistingTempData=[];
    this._facadeService.GetUserDetails(this.selectedUser.userGuid).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject!=null){
          this.departmentsData=data.returnObject.userDepartments;
          var roles =[];
          if(this.MainrolesData!=null&&this.MainrolesData.length>0){
            data.returnObject.userRoles.forEach(element => {
              var role = this.MainrolesData.find(m=>m.roleGuid==element.id);
              if(role!=null){
                role.roleName=element.name+" ("+role.departmentName+")";
                roles.push(role);
              }
            });
          }
          this.RolesData=roles;
          if(this.selectedUser!=null&&this.selectedUser!=""&&this.selectedUser!=undefined){
            if(this.AllDelegations!=null&&this.AllDelegations.length>0){
              var roledelegations = this.AllDelegations.filter(m=>m.userId==this.selectedUser.userId);
              if(roledelegations!=null&&roledelegations!=undefined&&roledelegations.length>0){
                roledelegations.forEach(element => {
                  if(element.skipApproval==true){
                    var skipdelegationModel = {
                      delegationId: element.delegationId, 
                      departmentId: element.departmentId,
                      role: element.role+" ("+element.department+")",
                      userId: element.userId,
                      user: element.user,
                      //toDepartmentId: element.toDepartmentId,
                      // toRoleId: element.toRoleId,
                      // toRole: element.toRole+" ("+element.toDepartment+")",
                      // toUserId: element.toUserId,
                      // toUser: element.toUser,
                      fromDate: element.fromDate,
                      toDate: element.toDate,
                      comments: element.comments,
                      active: element.active,
                      createdBy: element.createdBy,
                      selectedroles:element
                    };
                    this.skipapprovalExistingTempData.push(skipdelegationModel);
                  }
                  else{
                    if(element.documentID==0&&element.documentTypeID==0){
                      var delegationModel = {
                        delegationId: element.delegationId, 
                        departmentId: element.departmentId,
                        role: element.role+" ("+element.department+")",
                        userId: element.userId,
                        user: element.user,
                        toDepartmentId: element.toDepartmentId,
                        toRoleId: element.toRoleId,
                        toRole: element.toRole+" ("+element.toDepartment+")",
                        toUserId: element.toUserId,
                        toUser: element.toUser,
                        fromDate: element.fromDate,
                        toDate: element.toDate,
                        comments: element.comments,
                        active: element.active,
                        createdBy: element.createdBy,
                        selectedroles:element
                      };
                        this.delegateExistingTempData.push(delegationModel);
                    }
                  }
                 
                });
              }
              else{
                this.selectedRole=this.RolesData;
              }
            }
            else{
              this.selectedRole=this.RolesData;
            }
          }
          else{
            this.selectedRole=this.RolesData;
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    if(this.AllDelegations!=null&&this.AllDelegations.length>0){
      this.AllDelegations.forEach(element => {
        if(element.documentID==0&&element.documentTypeID==0){
          if(element.toUserId==this.selectedUser.userId){
            this.delegatetomeTempData.push(element);
           }
        }
        
      });
    }
  }

  fromGetDepRole(){
    this.departmentsData=[];
    this.RolesData=[];
    this.showLoading = true;
    this.fromUserError="";
    this.fromRoleError="";
    this._facadeService.GetUserDetails(this.selectedUser.userGuid).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject!=null){
          this.departmentsData=data.returnObject.userDepartments;
          var roles =[];
          if(this.MainrolesData!=null&&this.MainrolesData.length>0){
            data.returnObject.userRoles.forEach(element => {
              var role = this.MainrolesData.find(m=>m.roleGuid==element.id);
              if(role!=null){
                role.roleName=element.name+" ("+role.departmentName+")";
                roles.push(role);
              }
            });
          }
          this.RolesData=roles;
          this.selectedRole = this.RolesData[0];
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filteredFromUsers(event){
    this.departmentsData=[];
    this.RolesData=[];
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.fromUserData.length; i++) {
        let users = this.fromUserData[i];
        if (users.firstName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(users);
        }
    }    
    this.filteredUsers = filtered;
  }

  DocumentfilteredFromUsers(event){
    this.docsData=[];
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.fromUserData.length; i++) {
        let users = this.fromUserData[i];
        if (users.firstName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(users);
        }
    }    
    this.filteredUsers = filtered;
  }
  DocfilteredFromUsers(event){
    this.docsTypesData=[];
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.fromUserData.length; i++) {
        let users = this.fromUserData[i];
        if (users.firstName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(users);
        }
    }    
    this.filteredUsers = filtered;
  }
  filteredToUsers(event){
    this.TodepartmentsData=[];
    this.ToRolesData=[];
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.ToUsersData.length; i++) {
        let users = this.ToUsersData[i];
        if (users.firstName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(users);
        }
    }    
    this.filteredToUsersData = filtered;
  }

toGetUserDetails(){
  this.Change = true;
  this.TodepartmentsData=[];
  this.ToRolesData=[];
  this.toRoleError="";
  this.ToUserError="";
    this.showLoading = true;
    this._facadeService.GetUserDetails(this.ToselectedUser.userGuid).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject!=null){
          this.TodepartmentsData=data.returnObject.userDepartments;
          var roles =[];
          if(this.MainrolesData!=null&&this.MainrolesData.length>0){
            data.returnObject.userRoles.forEach(element => {
              var role = this.MainrolesData.find(m=>m.roleGuid==element.id);
              if(role!=null){
                role.roleName=element.name+" ("+role.departmentName+")";
                roles.push(role);
              }
            });
          }
          this.ToRolesData=roles;
          if(this.ToRolesData.length==1){
            this.ToselectedRole=this.ToRolesData[0];
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  toGetUserDetailsMain(rowData){
    this.showLoading = true;
    this._facadeService.GetUserDetails(rowData.selectedUser).subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject!=null){
          this.TodepartmentsData=data.returnObject.userDepartments;
          var roles =[];
          if(this.MainrolesData!=null&&this.MainrolesData.length>0){
            data.returnObject.userRoles.forEach(element => {
              var role = this.MainrolesData.find(m=>m.roleGuid==element.id);
              if(role!=null){
                role.roleName=element.name+" ("+role.departmentName+")";
                roles.push(role);
              }
            });
          }
          this.ToRolesData=roles;
          var docData = this.docsData.find(m=>m.id==rowData.id);
          docData.ToRoleData=roles;
          if(docData.ToRoleData.length==1){
            docData.selectedRole=docData.ToRoleData[0].roleId;
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  addDelegation() {
    this.Change = true;
    this.fromUserError = '';
    //this.ToUserError= '';
    this.commentsError= '';
    this.fromRoleError="";
    //this.toRoleError="";
    this.responseError="";
    this.roleresponseError="";
    if (this.selectedUser == undefined || this.selectedUser == ''||this.selectedUser == null) {
      this.fromUserError = 'Please select user.';
    }
    else if(this.selectedRole==null||this.selectedRole.length==0){
      this.fromRoleError="Please select role.";
    }
    else if (this.fromDate == undefined || this.fromDate == ''||this.fromDate == null) {
      this.fromDateError = 'Please select From date.';
    }
   
    else if(this.departmentsData.length==0){
      this.roleresponseError = 'Please select valid from user.';
    }
    else{
      var isalreadyexist = false;
      var roles = "";
      if(this.delegateExistingTempData.length>0){
        this.delegateExistingTempData.forEach(element => {
          if(element.delegationId==0){
            element.selectedroles.forEach(element1 => {
              var d = this.selectedRole.find(m=>m.roleName==element1.roleName);
              if(d!=null){
                isalreadyexist=true;
              }
            });
          }
          else{
            var d = this.selectedRole.find(m=>m.roleName==element.role);
            if(d!=null){
              isalreadyexist=true;
            }
          }
        });
        this.selectedRole.forEach(element => {
          roles += element.roleName+",";
        });
        roles = roles.substring(0, roles.length - 1);
      }
      if(this.skipapprovalExistingTempData.length>0){
        this.skipapprovalExistingTempData.forEach(element => {
          if(element.delegationId==0){
            element.selectedroles.forEach(element1 => {
              var d = this.selectedRole.find(m=>m.roleName==element1.roleName);
              if(d!=null){
                isalreadyexist=true;
              }
            });
          }
          else{
            var d = this.selectedRole.find(m=>m.roleName==element.role);
            if(d!=null){
              isalreadyexist=true;
            }
          }
        });
        this.selectedRole.forEach(element => {
          roles += element.roleName+",";
        });
        roles = roles.substring(0, roles.length - 1);
      }
      if(this.delegateTempData.length>0){
        this.delegateTempData.forEach(element => {
          if(element.delegationId==0){
            element.selectedroles.forEach(element1 => {
              var d = this.selectedRole.find(m=>m.roleName==element1.roleName);
              if(d!=null){
                isalreadyexist=true;
              }
            });
          }
          else{
            var d = this.selectedRole.find(m=>m.roleName==element.role);
            if(d!=null){
              isalreadyexist=true;
            }
          }
        });
        this.selectedRole.forEach(element => {
          roles += element.roleName+",";
        });
        roles = roles.substring(0, roles.length - 1);
      }
      else{
        this.selectedRole.forEach(element => {
          roles += element.roleName+",";
        });
        roles = roles.substring(0, roles.length - 1);
      }
      if(isalreadyexist){
        this.roleresponseError = "Role already exist please select another";
      }
      else{
        var roleNames = "";
        this.selectedRole.forEach(element => {
          roleNames += element.roleName+",";
        });
        roleNames = roleNames.substring(0, roleNames.length - 1);
        var delegationModel = {
          delegationId: 0, 
          departmentId: this.selectedRole.departmentGuid,
          role: roleNames,
          userId: this.selectedUser.userId,
          user: this.selectedUser.firstName,
          fromDate: this.fromDate,
          toDate: this.toDate!=null&&this.toDate!=undefined?new Date(new Date(this.toDate).toDateString() + " " + new Date("5/4/2018 23:59:00").toTimeString()):null,
          comments: this.comments,
          active: 'Y',
          toDepartmentId: 0,             
          toRoleId: 0,           
          toUserId: 0,
          skipApproval: true,
          createdBy: this.userId,
          selectedroles:this.selectedRole
        };
        this.delegateTempData.push(delegationModel);
  
        if(this.selectedRole!=null&&this.selectedRole.length>0){
          this.selectedRole.forEach(element => {
            var fromdepid = this.MaindepartmentsData.find(m=>m.departmentGuid==element.departmentGuid);
            this.delegateData.push({
              delegationId: 0, 
              departmentId: fromdepid.departmentId,
              department: "",
              roleId: element.roleId,
              role: "",
              userId: this.selectedUser.userId,
              user: "",
              fromDate: this.fromDate,
              toDate: this.toDate!=null&&this.today!=undefined?new Date(new Date(this.toDate).toDateString() + " " + new Date("5/4/2018 23:59:00").toTimeString()):null,
              comments: this.comments,
              documentID: 0,
              documentGUID: "",
              documentTypeID: 0,
              active: 'Y',
              toDepartmentId: 0,             
              toRoleId: 0,           
              toUserId: 0,
              createdBy: this.userId,
              skipapproval:true
            });
          });
        }
  
        this.selectedRole=[];
       // this.ToselectedUser="";
       // this.ToselectedRole="";
        this.comments="";
        this.ToRolesData=[];
        this.today=null;
        this.fromuserdisabled=true;
      }
    }
  } 
  documentAddDelegation(){
    this.Change = true;
    this.fromUserError = '';
    this.ToUserError= '';
    this.commentsError= '';
    this.toRoleError="";
    this.responseError="";
    this.docsError="";
    this.docError="";
    if (this.selectedUser == undefined || this.selectedUser == ''||this.selectedUser == null) {
      this.fromUserError = 'Please select from user.';
    }
    else if(this.selectedDocuments==null||this.selectedDocuments.length==0){
      this.docsError="Please select atleast one document.";
    }
    else if (this.ToselectedUser == undefined || this.ToselectedUser == ''||this.ToselectedUser == null) {
      this.ToUserError = 'Please select to user.';
    }
    else if(this.ToselectedRole==null||this.ToselectedRole.length==0){
      this.toRoleError="Please select to role.";
    }
    else if(this.selectedUser.userGuid==this.ToselectedUser.userGuid){
      this.docError = 'From user and to user should not be same.';
    }
    else if(this.TodepartmentsData.length==0){
      this.docError = 'Please select valid to user.';
    }
    else{
      var isalreadyexist = false;
      var documentCode = "";
      if(this.delegateDocTempData.length>0){
        this.delegateDocTempData.forEach(element => {
          element.selecteddocs.forEach(element1 => {
            var d = this.selectedDocuments.find(m=>m.code==element1.code);
            if(d!=null){
              isalreadyexist=true;
            }
          });
          
        });
        this.selectedDocuments.forEach(element => {
          documentCode += element.code+",";
        });
        documentCode = documentCode.substring(0, documentCode.length - 1);
      }
      else{
        this.selectedDocuments.forEach(element => {
          documentCode += element.code+",";
        });
        documentCode = documentCode.substring(0, documentCode.length - 1);
      }
      if(isalreadyexist){
        this.docError = "Document already exist please select another";
      }
      else{
        var delegationModel = {
          document: documentCode,
          user: this.selectedUser.firstName,
          toUser: this.ToselectedUser.firstName,
          comments: this.comments,
          active: 'Y',
          toDepartmentId: 0,             
          toRoleId: 0,           
          toUserId: 0,
          createdBy: this.userId,
          toRole: this.ToselectedRole.roleName,
          selecteddocs:this.selectedDocuments
        };
        this.delegateDocTempData.push(delegationModel);
  
        // if(this.selectedRole!=null&&this.selectedRole.length>0){
        //   this.selectedRole.forEach(element => {
        //     var fromdepid = this.MaindepartmentsData.find(m=>m.departmentGuid==element.departmentGuid);
        //     var todepid = this.MaindepartmentsData.find(m=>m.departmentGuid==this.ToselectedRole.departmentGuid);
        //     this.delegateData.push({
        //       delegationId: 0, 
        //       departmentId: fromdepid.departmentId,
        //       department: "",
        //       roleId: element.roleId,
        //       role: "",
        //       userId: this.selectedUser.userId,
        //       user: "",
        //       toDepartmentId: todepid.departmentId,
        //       toDepartment: "",
        //       toRoleId: this.ToselectedRole.roleId,
        //       toRole: "",
        //       toUserId: this.ToselectedUser.userId,
        //       toUser: "",
        //       fromDate: this.fromDate,
        //       toDate: this.toDate,
        //       comments: this.comments,
        //       documentID: 0,
        //       documentGUID: "",
        //       documentTypeID: 0,
        //       active: 'Y',
        //       createdBy: this.userId,
        //     });
        //   });
        // }

        this.selectedDocuments=[];
        this.ToselectedUser="";
        this.ToselectedRole="";
        this.comments="";
        this.fromuserdisabled=true;
      }
    }
  }
  DOcTypeaddDelegation() {
    this.Change = true;
    this.fromUserError = '';
    this.fromDateError = '';
   
    this.commentsError= '';
    this.fromRoleError="";
   
    this.responseError="";
    this.fromDocTypeError="";
    this.docTyperesponseError="";
    if (this.selectedUser == undefined || this.selectedUser == ''||this.selectedUser == null) {
      this.fromUserError = 'Please select user.';
    }
    else if(this.selectedDocType==null||this.selectedDocType.length==0){
      this.fromDocTypeError="Please select doc type.";
    }
   
    else if (this.fromDate == undefined || this.fromDate == ''||this.fromDate == null) {
      this.fromDateError = 'Please select From date.';
    }
   
    else if(this.selectedUser.userGuid==this.ToselectedUser.userGuid){
      this.docTyperesponseError = 'From user and to user should not be same.';
    }
   
    else{
      var isalreadyexist = false;
      var documentType = "";
      if(this.delegateDocTypeTempDataExisting.length>0){
        this.delegateDocTypeTempDataExisting.forEach(element => {
          if(element.delegationId==0){
            element.selecteddocstypes.forEach(element1 => {
              var d = this.selectedDocType.find(m=>m.documentType==element1.documentType);
              if(d!=null){
                isalreadyexist=true; 
              }
            });
          }
          else{
            var d = this.selectedDocType.find(m=>m.documentType==element.documenttype);
            if(d!=null){
              isalreadyexist=true;
            }
          } 
        });
        this.selectedDocType.forEach(element => {
          documentType += element.documentType+",";
        });
        documentType = documentType.substring(0, documentType.length - 1);
      }
      if(this.skipDocTypeTempDataExisting.length>0){
        this.skipDocTypeTempDataExisting.forEach(element => {
          if(element.delegationId==0){
            element.selecteddocstypes.forEach(element1 => {
              var d = this.selectedDocType.find(m=>m.documentType==element1.documentType);
              if(d!=null){
                isalreadyexist=true; 
              }
            });
          }
          else{
            var d = this.selectedDocType.find(m=>m.documentType==element.documenttype);
            if(d!=null){
              isalreadyexist=true;
            }
          } 
        });
        this.selectedDocType.forEach(element => {
          documentType += element.documentType+",";
        });
        documentType = documentType.substring(0, documentType.length - 1);
      }
      if(this.delegateDocTypeTempData.length>0){
        this.delegateDocTypeTempData.forEach(element => {
          if(element.delegationId==0){
            element.selecteddocstypes.forEach(element1 => {
              var d = this.selectedDocType.find(m=>m.documentType==element1.documentType);
              if(d!=null){
                isalreadyexist=true; 
              }
            });
          }
          else{
            var d = this.selectedDocType.find(m=>m.documentType==element.documenttype);
            if(d!=null){
              isalreadyexist=true;
            }
          } 
        });
        this.selectedDocType.forEach(element => {
          documentType += element.documentType+",";
        });
        documentType = documentType.substring(0, documentType.length - 1);
      }
      else{
        this.selectedDocType.forEach(element => {
          documentType += element.documentType+",";
        });
        documentType = documentType.substring(0, documentType.length - 1);
      }
      if(isalreadyexist){
        this.docTyperesponseError = "Document Type already exist please select another";
      }
      else{
        var delegationModel = {
          delegationId: 0, 
          documenttype: documentType,
          user: this.selectedUser.firstName,         
          comments: this.comments,
          active: 'Y',
          toDepartmentId: 0,             
          toRoleId: 0,           
          toUserId: 0,
          createdBy: this.userId,
         
          selecteddocstypes:this.selectedDocType,
          fromDate: this.fromDate,
          toDate: this.toDate!=null&&this.toDate!=undefined?new Date(new Date(this.toDate).toDateString() + " " + new Date("5/4/2018 23:59:00").toTimeString()):null,
        };
        this.delegateDocTypeTempData.push(delegationModel);
        if(this.selectedDocType!=null&&this.selectedDocType.length>0){
          this.selectedDocType.forEach(element => {
            var fromdepid = this.MaindepartmentsData.find(m=>m.departmentGuid==this.selectedRole.departmentGuid);           
            this.delegateData.push({
              delegationId: 0, 
              departmentId: fromdepid.departmentId,
              department: "",
              roleId: this.selectedRole.roleId,
              role: "",
              userId: this.selectedUser.userId,
              user: "",
              fromDate: this.fromDate,
              toDate: this.toDate!=null&&this.toDate!=undefined?new Date(new Date(this.toDate).toDateString() + " " + new Date("5/4/2018 23:59:00").toTimeString()):null,
              comments: this.comments,
              documentID: 0,
              documentGUID: "",
              documentTypeID: element.documentTypeId,
              active: 'Y',
              toDepartmentId: 0,             
              toRoleId: 0,           
              toUserId: 0,
              skipApproval: true,
              createdBy: this.userId,
            });
          });
        }
        
        this.selectedDocType=[];
        // this.ToselectedUser="";
        // this.ToselectedRole="";
        // this.ToRolesData=[];
        this.comments="";
        this.fromuserdisabled=true;
        // this.ToRolesData=[];

    }
  }
  } 
  addDocDelegation(){
    this.Change = true;
     if (this.selectedUser == undefined || this.selectedUser == ''||this.selectedUser == null) {
      this.responseError = 'Please select user.';
    }
    else if(this.selectedDocuments==null || this.selectedDocuments.length==0){
      this.responseError ="Please select at least one documemt."
    }
    else{
      this.delegateData=[];
      this.selectedDocuments.forEach(element => {
        //var role = element.ToRoleData.find(m=>m.roleId==element.selectedRole);
        //var user = this.ToUsersData.find(m=>m.userGuid==element.selectedUser);
           // var todepid = this.MaindepartmentsData.find(m=>m.departmentGuid==role.departmentGuid);
            var fromdepid = this.MaindepartmentsData.find(m=>m.departmentGuid==this.selectedRole.departmentGuid);
            this.delegateData.push({
              delegationId: 0, 
              departmentId: fromdepid.departmentId,
              department: "",
              roleId:this.selectedRole.roleId ,
              role: "",
              userId: this.selectedUser.userId,
              user: "",
              toDepartmentId: 0,
              //toDepartment: "",
              toRoleId: 0,
             // toRole: "",
              toUserId: 0,
              //toUser: "",
              comments: element.selectedComment,
              documentID: parseInt(element.id),
              documentGUID: element.fileGuid,
              documentTypeID: 0,
              active: 'Y',
              skipApproval: true,
             
              createdBy: this.userId,
              fromDate: new Date(),
            });
      });
      console.log(JSON.stringify(this.delegateData));
     this.SaveDelegation();
    }
  }
  SaveDelegation(){
    if(this.delegateData.length>0){
      this.showLoading = true;
       this._facadeService.AddDelegations(this.delegateData)
        .subscribe(
          (data) => {
            this.showLoading = false;
            console.log(data);
            this.apiResponse = data;
            if(this.apiResponse.returnCode >= 0){
              if(sessionStorage.FromAdmin=="true"){
                this.router.navigate(["/skippedapprovallist"]);
              }
              else{
                this.router.navigate(["/userdelegations"]);
              }
            }
            else{
              this.responseError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
    }
    else{
      this.responseError = "Please select at least one Skip Approval and click on the Add to Skip Approval List button before you click on the Skip button.";
    }
  }
  resetCtrls()
  {
    this.fromuserdisabled=false;
        if(!this.DisableFromUser){
          this.selectedUser='',
          this.RolesData=[];
          this.departmentsData=[];
        }
        this.delegateTempData=[];
        this.responseError="";
        this.fromUserError = '';
        this.fromDateError = '';
        this.toDateError='';
        
        this.ToUserError= '';
        this.docTyperesponseError="";
        this.commentsError= '';
        this.delegateData=[];
        this.selectedDept='',
        this.docsError="";
        this.selectedRole='',
        this.fromDate='',
        this.toDate='',
        this.ToselectedDept='',
        this.ToselectedRole='',
        this.ToselectedUser='',
        this.comments=''
        this.docsData=[];
        this.selectedDocType=[];
        this.selectedDocuments=[];
        this.delegateDocTypeTempData=[];
        this.responseError="";
        this.ToRolesData=[];
        this.TodepartmentsData=[];
        this.fromDocTypeError="";
        this.roleresponseError="";
        this.TabChangeDisplay=false;
        if(this.DisableFromUser){
          if(this.index==1)
            this.approvalNeededData();
          else if(this.index==2)
            this.getDocsTypesData();
            else
            this.getUsers();
        }
        
  }

  getDepartments() {
    this.MaindepartmentsData=[];
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.MaindepartmentsData=data.returnObject;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getRoles() {
    this.MainrolesData=[];
    this._facadeService.getRoles().subscribe(
      (data) => {
        this.MainrolesData=data.returnObject;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  deleteTempDelegate(rowdataTemp){
    this.Change = true;
    var dedd = [];
    this.delegateTempData.forEach(elementm => {
      var count =0;
      if(elementm.delegationId==0){
        if(elementm.selectedroles.length==rowdataTemp.selectedroles.length){
          elementm.selectedroles.forEach(element => {
         var d = rowdataTemp.selectedroles.find(m=>m.roleId==element.roleId);
         if(d!=null){
          count+=1;
         }
          });
          if(count!=elementm.selectedroles.length){
            dedd.push(elementm);
          }
        }
        else{
          dedd.push(elementm);
        }
      }
      else{
         dedd.push(elementm);
      }
      
   });
   this.delegateTempData = dedd;
   if(this.delegateTempData.length==0){
    this.fromuserdisabled=false;
   }
   //Change logic remove element from main list.
   var listDel=[];
   this.delegateData.forEach(element => {
    var exis = rowdataTemp.selectedroles.find(m=>m.roleId==element.roleId);
    if(exis==null||exis==undefined){
      listDel.push(element)
    }
   });
   this.delegateData =listDel;
  }

  getDocsTypesData() {
    this.Change = true;
    this.showLoading = true;
    this.delegatetomeTempDatadoctype=[];
          this._facadeService.getDocsTypes().subscribe(
            (data) => {
              this.showLoading = false;
              var doc=[];
              if(data.returnObject!=null && data.returnObject.length>0){
                data.returnObject.forEach((value, key) => {
                  if(value.documentTypeCode!="UCD"){
                    doc.push(value);
                  }
                 });
              }
              this.docsTypesData = doc;

              if(this.selectedUser!=null&&this.selectedUser!=""&&this.selectedUser!=undefined){
                if(this.AllDelegations!=null&&this.AllDelegations.length>0){
                  var roledelegations = this.AllDelegations.filter(m=>m.userId==this.selectedUser.userId);
                  if(roledelegations!=null&&roledelegations!=undefined&&roledelegations.length>0){
                    roledelegations.forEach(element => {
                      if(element.skipApproval==true &&element.documentTypeID>0){
                          var delegationModel1 = {
                            documenttype: element.documentType,
                            user: element.user,
                            comments: element.comments,
                            active: element.active,
                            createdBy: element.createdBy,
                            selecteddocstypes:element,
                            fromDate: element.fromDate,
                            toDate: element.toDate
                          };
                          this.skipDocTypeTempDataExisting.push(delegationModel1);
                      }
                      else{
                        if(element.documentTypeID>0){
                          var delegationModel = {
                            documenttype: element.documentType,
                          user: element.user,
                          toUser: element.toUser,
                          comments: element.comments,
                          active: element.active,
                          createdBy: element.createdBy,
                          toRole: element.toRole+" ("+element.toDepartment+")",
                          selecteddocstypes:element,
                          fromDate: element.fromDate,
                            toDate: element.toDate,
                          };
                          this.delegateDocTypeTempDataExisting.push(delegationModel);
                        }
                      }
                      
                    });
                  }
                }
              }
              
             this.fromGetDepRole();
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            }
          );

          if(this.AllDelegations!=null&&this.AllDelegations.length>0){
            this.AllDelegations.forEach(element => {
              if(element.documentTypeID>0){
                if(element.toUserId==this.selectedUser.userId){
                  this.delegatetomeTempDatadoctype.push(element);
                 }
              }
            });
          }
  }

  approvalNeededData() {
    this.Change = true;
    this.today = this.datePipe.transform(this.today, 'MM/dd/yyyy');
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null){
          userFeature = true;
        }
      }
    }
    var myApprovalModel = {
      documentId: 0,
      departmentId: 0,
      isArchived: 'N',
      documentTypeId: 0,
      documentStatus: "In-Process",
      todayDate: this.datePipe.transform(this.today, 'MM/dd/yyyy'),
      userID: this.selectedUser.userId,
      userGuid: this.selectedUser.userGuid,
      accessUserID:userFeature?0:parseInt(sessionStorage.getItem('userId'))
    };
    this.showLoading = true;
    this._facadeService.approvalNeededData(myApprovalModel).subscribe(
      (data) => {
        this.docsData = data.returnObject;
        this.docsData.forEach(docelement => {
          docelement.delegationId=0;
        });
        console.log(this.docsData);
        this.showLoading = false;
       
        //logic for already exist docs
        if(this.selectedUser!=null&&this.selectedUser!=""&&this.selectedUser!=undefined){
          if(this.AllDelegations!=null&&this.AllDelegations.length>0){
            var roledelegations = this.AllDelegations.filter(m=>m.userId==this.selectedUser.userId);
            if(roledelegations!=null&&roledelegations!=undefined&&roledelegations.length>0){
              roledelegations.forEach(element => {
                if(element.documentID>0){
                  this.docsData.forEach(docelement => {
                    if(docelement.id==element.documentID){
                      docelement.delegationId=element.delegationId;
                      // docelement.touser=element.user; 
                      // docelement.torole=element.role+" ("+element.department+")";
                      docelement.comments=element.comments;
                    }
                   });
                }
              });
            }
            // else{
            //   this.selectedDocuments = this.docsData;
            // }
          }
          // else{
          //   this.selectedDocuments = this.docsData;
          // }
        }
        // else{
        //   this.selectedDocuments = this.docsData;
        // }
       this.fromGetDepRole();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  
  deleteDocTempDelegate(model){
    this.Change = true;
    var dedd = [];
    this.delegateDocTempData.forEach(elementm => {
      var count =0;
      if(elementm.selecteddocs.length==model.selecteddocs.length){
        elementm.selecteddocs.forEach(element => {
       var d = model.selecteddocs.find(m=>m.code==element.code);
       if(d!=null){
        count+=1;
       }
        });
        if(count!=elementm.selecteddocs.length){
          dedd.push(elementm);
        }
      }
      else{
        dedd.push(elementm);
      }
   });
   this.delegateDocTempData = dedd;
   if(this.delegateDocTempData.length==0){
    this.fromuserdisabled=false;
   }
  }

  deleteDocTypeTempDelegate(model){
    this.Change = true;
    var dedd = [];
    this.delegateDocTypeTempData.forEach(elementm => {
      var count =0;
      if(elementm.selecteddocstypes.length==model.selecteddocstypes.length){
        elementm.selecteddocstypes.forEach(element => {
       var d = model.selecteddocstypes.find(m=>m.documentType==element.documentType);
       if(d!=null){
        count+=1;
       }
        });
        if(count!=elementm.selecteddocstypes.length){
          dedd.push(elementm);
        }
      }
      else{
        dedd.push(elementm);
      }
   });
   this.delegateDocTypeTempData = dedd;
   if(this.delegateDocTypeTempData.length==0){
    this.fromuserdisabled=false;
   }

   var listDel=[];
   this.delegateData.forEach(element => {
    var exis = model.selecteddocstypes.find(m=>m.documentTypeId==element.documentTypeID);
    if(exis==null||exis==undefined){
      listDel.push(element);
    }
   });
   this.delegateData =listDel;
  }
  AddComments(rowData){
    this.RowCommentId=rowData.id;
    if(rowData.selectedComment!=""&&rowData.selectedComment!=null&&rowData.selectedComment!=undefined){
      this.commentsrow = rowData.selectedComment;
    }
    else{
      this.commentsrow="";
    }
    this.commentsdisplay=true;
  }
  AddRowComment(){
    var rowda=this.docsData.find(m=>m.id==this.RowCommentId);
    rowda.selectedComment = this.commentsrow;
    this.commentsdisplay=false;
  }
  handleChange1(e)
  { 
    const previoustab = this.tabView.tabs[this.index]; //saved previous/initial index
    previoustab.selected = true;
    const selectedTab = this.tabView.tabs[e.index];
    selectedTab.selected = false;
    this.tabView.activeIndex = this.index;
    // this.nextIndex= event.index;
    if(this.Change)
      this.handleChange(e,e.index);
    else
      this.try(e.index);
  }
  handleChange(e:MouseEvent,tabIndex) {
    // e.stopPropagation();
    if (this.index == tabIndex){
      return;
    }
    this.confirmationService.confirm({
      message: "If you want to change the tab. You may lose changes in current tab. Are you sure want to change?",
      accept: () => {
       this.try(tabIndex);
      },
      reject: () => {}
    });
  }

  private try(tabIndex)
  {
    this.Change = false;
    this.fromuserdisabled=false;
    if(!this.DisableFromUser){
      this.selectedUser='',
      this.RolesData=[];
      this.departmentsData=[];
    }
    this.delegateTempData=[];
    this.responseError="";
    this.fromUserError = '';
    this.fromDateError = '';
    this.ToUserError= '';
    this.docTyperesponseError="";
    this.commentsError= '';
    this.selectedDept='',
    this.delegateData=[];
    this.docsError="";
    this.selectedRole='',
    this.fromDate=new Date();
    this.ToselectedDept='';
    this.ToselectedRole='';
    this.ToselectedUser='';
    this.comments='';
    this.toDate='';
    this.docsData=[];
    this.selectedDocType=[];
    this.selectedDocuments=[];
    this.delegateDocTypeTempData=[];
    this.responseError="";
    this.ToRolesData=[];
    this.TodepartmentsData=[];
    this.fromDocTypeError="";
    this.roleresponseError="";
    this.TabChangeDisplay=false;
    this.index = tabIndex;
    if(this.DisableFromUser){
      if(this.index==1)
        this.approvalNeededData();
      else if(this.index==2)
        this.getDocsTypesData();
    }
  }

  GetAllDelegations() {
    this.AllDelegations=[];
    var dataModel =[-1];
    this.showLoading = true;
    this._facadeService.GetDelegationsByIDs(dataModel).subscribe(
      (data) => {
        this.selectedRole=[];
        this.showLoading = false;
        data.returnObject.forEach(element => {
          element.fromDate=this.datePipe.transform(element.fromDate, 'MM/dd/yyyy');
          element.toDate=this.datePipe.transform(element.toDate, 'MM/dd/yyyy');
            this.AllDelegations.push(element);
        });
        this.getUsers();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  
  BackClick(){
    var action = this.route.snapshot.queryParamMap.get('action');
    if(action=="listdelegate"){
      this.router.navigate(["/listdelegate"]);
    }
    else if(action=="userdelegations"){
      this.router.navigate(["/userdelegations"]);
    }
    else if(action=="approvalsneeded"){
      this.router.navigate(["/approvalsneeded"]);
    }
  }
  ClearControls(){
    this.delegatetomeTempDatadoctype=[];
    this.responseError="";
    this.fromUserError = '';
    this.fromDateError = '';
    this.ToUserError= '';
    this.docTyperesponseError="";
    this.commentsError= '';
    this.delegateData=[];
    this.selectedDept='',
    this.docsError="";
    this.selectedRole='',
    this.fromDate='',
    this.selectedUser='',
    this.toDate='',
    this.ToselectedDept='',
    this.ToselectedRole='',
    this.ToselectedUser='',
    this.comments=''
    this.docsData=[];
    this.selectedDocType=[];
    this.selectedDocuments=[];
    this.responseError="";
    this.ToRolesData=[];
    this.TodepartmentsData=[];
    this.fromDocTypeError="";
    this.roleresponseError="";
    this.skipapprovalExistingTempData=[];
    this.delegatetomeTempDatadoctype=[];
  }
  GetFile(rowData) {
    if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
      this.showLoading = true;
      var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
      this.Download(rowData.fileName, newname, rowData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  
  }
}
