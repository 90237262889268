<div class="p-grid p-fluid p-formgrid">
    <div class="p-col-12">
        <div class="card" style="min-height: 600px">   
            <div class="p-grid p-fluid p-formgrid">
                <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12"></div>
            <!-- <div class="card-header" style=" border-radius: 5px;height:40px; ">       
                <h5 style="color:#495057; padding:15px 0 0 15px;"></h5> 
                <span class="p-input-icon-left" style="float:right; margin-bottom:10px; ">         -->
                    <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="float:right; margin-bottom:10px;">
                    <p-dropdown inputId="statuses" [options]="statuses" [(ngModel)]="selectedStatus" placeholder="Select Status"
                    (onChange)="selectDocList(selectedStatus)" optionLabel="name" required="true"        
                       requiredMessage="The input must not be empty">                
                   </p-dropdown>
                   <small class="p-invalid">{{ statusError }}</small>
                </div>                
                <!-- <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                   <button pButton pRipple type="button" icon="pi pi-clone" label="Archive" iconPos="left" (click)="archiveDocuments()" 
                   class="p-button-raised p-button-primary" style="float:right;width: 100%;"></button>
                </div> -->
                </div>
              <!-- </span>
            </div> -->
            
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:auto;">
                <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Documents List</h5> 
                <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;"> 
                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print"tooltipPosition="top" (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right; height:25px; width:25px;"></button>
                <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"tooltipPosition="top"(click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right;  height:25px; width:25px;"></button>
        </span>
            </div> 
              <div  class="invoice invoice-header" id="invoice-content">                                                          
                            <p-table (sortFunction)="customSort($event)" [customSort]="true" #dt [value]="ExpiredDocs" id="excel-table"  styleClass="p-datatable-striped" [columns]="cols" [virtualScroll]="true" [scrollable]="true" scrollHeight="380px" [filterDelay]="0" [(selection)]="selectedCustomers" dataKey="id"
                                [globalFilterFields]="['code', 'title', 'documentType','departmentName']">
                               
                                <ng-template pTemplate="header" let-columns class="invoice-items">
                                    <tr>                                       
                                        <th style="width: 50px" *ngIf="ArchiveHideShow"></th>
                                        <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                                        [ngClass]="{'docid': i == 0,'rev': i == 1,'ecrn': i == 2,'title': i == 3,'departmentName':i==4,'owner':i==5,'status1':i==6,'status': i==7}">
                                            {{col.header}}
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </th>                                                                            
                                    </tr>
                                    <tr id="trSearch">
                                        <th *ngIf="ArchiveHideShow" style="text-align: center;width: 50px;">  
                                            <p-tableHeaderCheckbox [(ngModel)]="headerCheckbox" binary="headerCheckbox" (click)="allExpiredDataChecked($event)"></p-tableHeaderCheckbox>                                     
                                        </th>
                                        <th *ngFor="let col of columns" [ngSwitch]="col.field"
                                            style="text-align: center;">
                                            <input pInputText type="text" [placeholder]="col.dynamicplaceholder"
                                                (input)="dt.filter($event.target.value, col.field, 'contains')"
                                                style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr [pSelectableRow]="rowData">
                                       
                                        <td *ngIf="ArchiveHideShow" style="text-align: center;width: 50px;">
                                            <p-tableCheckbox
                                            (click)="selectedId(rowData)" [value]="rowData"></p-tableCheckbox>
                                        </td>
                                        <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                           
                                            <td *ngIf="i == 0" style="text-align: center;width: 110px;">
                                                {{ rowData[col.field] }}
                                            </td>
                                            <td *ngIf="i == 1" style="text-align: center;width: 90px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 130px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 450px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 180px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 110px;">
                                                {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 6" style="text-align: left;padding-left:15px;font-weight: bold;width: 120px;"
                                            [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                                             {{rowData[col.field]}}
                                            </td>
                                            <td *ngIf="i == 7" style="text-align: left;width: 80px;padding-left:15px;">
                                                <span>{{rowData[col.field]}}</span>
                                              </td>
                                            <!-- <td *ngIf="i == 4" style="text-align: center;">
                                                {{rowData[col.field] | date: "MM/dd/yyyy"}}
                                            </td>
                                            <td *ngIf="i == 5" style="text-align: center;">
                                              {{rowData[col.field] | date: "MM/dd/yyyy"}}
                                          </td>
                                          <td *ngIf="i == 6" style="text-align: center;">
                                            {{rowData[col.field] | date: "MM/dd/yyyy"}}
                                        </td> -->
                                       
                                        </ng-container>                                        
                                    </tr>
                                </ng-template>
                               
                              <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td *ngIf="ArchiveHideShow" colspan="9">No documents found.</td>
                                        <td *ngIf="!ArchiveHideShow" colspan="8">No documents found.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                         <div class="p-grid p-fluid p-formgrid" *ngIf="ArchiveHideShow">
                            <!-- <div class="p-field p-col-12">    -->
                                <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12"></div>
                                <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">                                    
                            <!-- <span class="p-input-icon-left" style="float:right; padding:10px 0 10px 5px;"> -->
                                <button pButton type="button" label="Archive"
                                class="p-button-info" icon="pi pi-clone" iconPos="left" (click)="archiveDocuments()"
                                style="float: right;margin-top: 10px;width: 100%;"></button>
                                    <!-- </span> -->
                                </div>   
                            <!-- </div> -->
                        </div>
                    </div>

                </div>
            </div>
            <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
                <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
              </div>