import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FacadeService } from "../../facade/facade.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BASE_API_URL_TOKEN } from "src/app/injectors";
import * as _ from "lodash";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { WindowScrollController } from "@fullcalendar/core";
import { Table } from "primeng/table";
@Component({
  selector: "app-docdetails",
  templateUrl: "./docdetails.component.html",
  styleUrls: ["./docdetails.component.scss"],
})
export class DocdetailsComponent implements OnInit {
  userId = parseInt(sessionStorage.getItem("userId"));
  docId = parseInt(sessionStorage.getItem("docId"));
  userGuid = sessionStorage.getItem("userGuid");
  StatusDoc = sessionStorage.getItem("StatusDoc");
  Appstatus = sessionStorage.getItem('Appstatus');
  isFrom = sessionStorage.getItem("isFrom");
  apiResponse: any;
  projectdisplay: any = false;
  mulproducts: any[] = [];
  ShowDownload: boolean;
  responseError: any;
  docsDetailsData: any;
  btnprint: boolean = true;
  approvalsModels: any;
  approvalsData: any = [];
  today: any;
  sendFpprovalModel: any;
  workflowDefinitionId: String;
  sendForApp: any;
  approveDoc: any;
  rejectDoc: any;
  holdDoc: any;
  releaseDoc: any;
  showSendForApproval: boolean;
  showApprove: boolean = false;
  showReject: boolean = false;
  showHold: boolean;
  showRelease: boolean;
  showEdit: boolean;
  cols: any[];
  showLoading: boolean;
  approvedisplay: boolean;
  rejectdisplay: boolean;
  clarificationdisplay: boolean;
  commentsDisplay: boolean;
  descriptionDisplay: boolean;
  remarksDisplay: boolean;
  Description: any;
  DetailDescription: any;
  deleteDisplay: boolean;
  items: any = [];
  showActionSplit: boolean = true;
  releasedisplay: boolean;
  archiveArray: any[] = [];
  source: any;
  RejectDescription: any;
  AprDescription: any;
  commentsList: any[] = [];
  commentsExist: boolean;
  ECODocs: any;
  documentId: any;
  ShowECODocs: boolean = false;
  RevorECO: string = "";
  RevorECOCount: any;
  effectiveDate: any;
  reviewDate: any;
  expiryDate: any;
  lastAccessDate: any;
  approvedDate: any;
  AreYouSureText: string;
  ECRNDisplay: boolean;
  ShowECRN=false;
  RedirectCMS: boolean = false;
  DCCShow: boolean;
  withdrawdisplay: boolean;
  WithDrawDescription: any = "";
  DepartmentDisplay: boolean = false;
  ECORevClicked: boolean = false;
  OBSClicked:boolean = false;
  DepartmentDisplayText: any = "";
  ApprovedFile: any;
  productName: any;
  showProductName: boolean;
  action = "";
  docsTypesData: any[] = [];
  selectedProduct: any;
  NotproductRelated = true;
  selectedDocType: any;
  workFlowData = [];
  selectedWorkflow: any;
  selectedECN: any;
  ECNLevelData = [];
  docTypeId: any;
  selectedProductLine: any;
  workFlowModel: any;
  productLineData = [];
  OriginatorDisplay:boolean;
  Reasons:any[]=[];
  selectedReason: any[];
 @ViewChild('dt') table: Table;
 cols1:any[];
 ShowChange:boolean = true;
  SHOW_REV_ECO_OBS:boolean;
  REV_DOWNLOAD:boolean;
  HIDE_ACTION:boolean;
  FILE_DOWNLOAD:boolean;
  RELEASE_ACTION:boolean;
  DownloadInfoDisplay:boolean;
  constructor(
    private _facadeService: FacadeService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) { }
  ngOnInit(): void {
    this.showLoading = true;
    this.REV_DOWNLOAD = false;

    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var SHOW_REV_ECO_OBS = feature.find(m=>m.code=="SHOW_REV_ECO_OBS");
        if(SHOW_REV_ECO_OBS!=null){
          this.SHOW_REV_ECO_OBS = true;
        }

        var REV_DOWNLOAD = feature.find(m=>m.code=="REV_DOWNLOAD");
        if(REV_DOWNLOAD!=null){
          this.REV_DOWNLOAD = true;
        }

        var HIDE_ACTION = feature.find(m=>m.code=="HIDE_ACTION");
        if(HIDE_ACTION!=null){
          this.HIDE_ACTION = true;
        }

        var FILE_DOWNLOAD = feature.find(m=>m.code=="FILE_DOWNLOAD");
        if(FILE_DOWNLOAD!=null){
          this.FILE_DOWNLOAD = true;
        }

        var RELEASE_ACTION = feature.find(m=>m.code=="RELEASE_ACTION");
        if(RELEASE_ACTION!=null){
          this.RELEASE_ACTION = true;
        }
      }
    }

    this.source = this.route.snapshot.paramMap.get("source");
    if (this.source === "" || this.source === null) {
      this.source === 'Awaiting for my approvals';
    }
    this.documentId = this.route.snapshot.queryParamMap.get("docId");

    if (
      this.documentId != "" &&
      this.documentId != undefined
    ) {
      this.docId = parseInt(this.documentId);
      sessionStorage.docId = this.docId;
    }
    this.action = this.route.snapshot.queryParamMap.get("action");
    this.ECNLevelData = [
      { name: 'Minor', value: 1, code: true },
      { name: 'Major', value: 2, code: false },
    ]
    this.selectedECN = this.ECNLevelData[1];
    this.getDocDetailsById();
    
    this.getECODocuments();
    this.cols = [
      {
        field: "approver",
        header: "Approved By",
        dynamicPlaceHolder: "Search by code",
      },
      {
        field: "approvedDate",
        header: "Approved Date",
        dynamicPlaceHolder: "Search by YYYY-MM-DD",
      },
      {
        field: "department",
        header: "Department",
        dynamicPlaceHolder: "Search by title",
      },
      {
        field: "role",
        header: "Role",
        dynamicPlaceHolder: "Search by YYYY-MM-DD",
      },
      {
        field: "approvalStatus",
        header: "Status",
        dynamicPlaceHolder: "Search by status",
      },
    ];
  }
  BackClick() {
    this.showLoading = true;
    // var isFromCMS = this.route.snapshot.queryParamMap.get("S");
    // var changeReqId = this.route.snapshot.queryParamMap.get('CMSId');
    // var CMSType = this.route.snapshot.queryParamMap.get('CMSType');
    var ShowMenu = this.route.snapshot.queryParamMap.get("ShowMenu");
    if (this.action == "delegate") {
      this.router.navigate(["/userdelegations"]);
    }
    if (this.action == "listdelegate") {
      this.router.navigate(["/listdelegate"]);
    }
    else if (this.action == "view") {
      if (this.userId == this.docsDetailsData.createdBy) {
        this.router.navigate(["/mydocs"]);
      }
      else {
        this.router.navigate(["/dashboard"]);
      }
    }
    else {
      if (ShowMenu == "Y") {
        if (this.source !== null) {
          this.router.navigate(["/mydocs", { source: this.source }]);
        }
        else {
          this.router.navigate(["/mydocs", { source: this.StatusDoc }]);
        }
      }
      else {
        if (this.isFrom == "ApprovalNeeded")
          this.router.navigate(["/approvalsneeded", { status: this.Appstatus }]);
        else if (this.isFrom == "Dashboard")
          this.router.navigate(["/dashboard"]);
        else if (this.isFrom === 'byproject')
          this.router.navigate(["/byproject", { isFrom: 'docdetails' }]);
        else if (this.isFrom === 'originatedReport') {
          sessionStorage.isFrom = 'docdetails';
          this.router.navigate(["/originatedreport"]);
        }
        else if (this.isFrom === 'pendingforapproval')
          this.router.navigate(["/pendingforapproval", { isFrom: 'docdetails' }]);
        else if (this.isFrom == "publishedNeeded")
          this.router.navigate(["/publishedneeded", { status: this.StatusDoc }]);
        else if (this.isFrom === 'altdocid')
          this.router.navigate(["reports/altdocid", { isFrom: 'docdetails' }]);
        else if (this.isFrom == "Reports")
          this.router.navigate(["/docsearch"]);
        else if (this.isFrom == "myreviewneeded")
          this.router.navigate(["reports/myreviewneeded"]);
        else if (this.isFrom == "documentsearchAdmin")
          this.router.navigate(["/totalelapseddays"]);
        else if (this.isFrom == "UserDelegation")
          this.router.navigate(["/userdelegations"]);
        else {
          if (this.source !== null) {
            if (this.source == "Admin")
              this.router.navigate(["/mydocsAdmin", { source: this.source }]);
            else
            this.router.navigate(["/mydocs"]);
          }
          else {
            this.router.navigate(["/mydocs"]);
          }
        }
        // }
      }
    }
  }
  getDocDetailsById() {
    this.showLoading = true;
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null)
          userFeature = true;
      }
    }
    var useId = userFeature?0:parseInt(sessionStorage.getItem('userId'));
    this._facadeService.getDocDetailsById(this.docId,useId).subscribe(
      (data) => {
        this.showActionSplit = false;
        this.docsDetailsData = data.returnObject;
        this.docsDetailsData.displayStatus=this.docsDetailsData.status;
        
        if(this.docsDetailsData?.displayStatus.indexOf("Obsolete") == 0)
          this.ShowChange = false;

        if(this.docsDetailsData.isObsInprocess=="Y"){
          this.docsDetailsData.displayStatus= "( Obsolete In-Process ) " + this.docsDetailsData.status;
        }
        console.log(
          "this.docsDetailsData====",
          JSON.stringify(this.docsDetailsData)
        );
        this.mulproducts = data.returnObject.products;
        if (this.mulproducts == undefined || this.mulproducts == null) {
          if(data.returnObject.productName != null && data.returnObject.productName != undefined)
            this.productName = data.returnObject.productName;
          else 
            this.productName = "";
          this.showProductName = true;
          this.mulproducts = [];
        }
        else {
          if (this.mulproducts.length > 1) {
            this.showProductName = false;
            this.productName = "";
            var exists = false;
            if(data.returnObject.productName != null && data.returnObject.productName != undefined)
            {
              this.mulproducts.forEach(e => {
                if(e.productName == data.returnObject.productName && !exists)
                  exists = true;
              });
              if(!exists)
              {
                var p = {
                  "documentId": 0,
                  "productGuid": "",
                  "productName": data.returnObject.productName
                }
                this.mulproducts.push(p);
              }
            }
          }
          else {
            if(data.returnObject.productName != null && data.returnObject.productName != undefined)
            {
              if(data.returnObject.productName == this.mulproducts[0].productName)
              {
                this.showProductName = true;
                this.productName = this.mulproducts[0].productName;
              }
              else{
                this.showProductName = false;
                this.productName = "";
                var p = {
                  "documentId": 0,
                  "productGuid": "",
                  "productName": data.returnObject.productName
                }
                this.mulproducts.push(p);
              }
            }
            else{
              this.showProductName = true;
              this.productName = this.mulproducts[0].productName;
            }
          }
        }
        if (this.docsDetailsData.creationSource=="CMS") {
          this.ShowECRN = true;
          if(this.docsDetailsData.changeDate!=null&&this.docsDetailsData.changeDate!=""&&this.docsDetailsData.changeDate!=undefined){
            let convertactiondate = new Date(this.docsDetailsData.changeDate+'Z');
            this.docsDetailsData.changeDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
          }
        }
        this.DetailDescription = this.docsDetailsData.description;
        if (this.docsDetailsData.alternatedDocId === "null" || this.docsDetailsData.alternatedDocId === null) {
          this.docsDetailsData.alternatedDocId = '';
        }
        if (this.docsDetailsData.remarks === "null") {
          this.docsDetailsData.remarks = '';
        }
        if (this.DetailDescription === "null") {
          this.DetailDescription = '';
        }
        if (this.docsDetailsData.effectiveDate !== null && this.docsDetailsData.effectiveDate !== undefined) {
          this.effectiveDate = new Date(this.docsDetailsData.effectiveDate);
          this.effectiveDate = this.effectiveDate.toLocaleDateString();
        }

        if (this.docsDetailsData.reviewDate !== null && this.docsDetailsData.reviewDate !== undefined) {
          this.reviewDate = new Date(this.docsDetailsData.reviewDate);
          this.reviewDate = this.reviewDate.toLocaleDateString();
        }
        if (this.docsDetailsData.expirationDate !== null && this.docsDetailsData.expirationDate !== undefined) {
          this.expiryDate = new Date(this.docsDetailsData.expirationDate);
          this.expiryDate = this.expiryDate.toLocaleDateString();
        }
        if (this.docsDetailsData.lastAccessedDate !== null && this.docsDetailsData.lastAccessedDate !== undefined) {
          this.lastAccessDate = new Date(this.docsDetailsData.lastAccessedDate + "Z");
          this.lastAccessDate = this.lastAccessDate.toLocaleDateString();
        }
        
        if (
          this.docsDetailsData.eco != null &&
          this.docsDetailsData.eco != "" &&
          this.docsDetailsData.eco != undefined
        ) {
          this.RevorECO = "Rev/Eco";
          this.RevorECOCount =
            this.docsDetailsData.revision + "/" + this.docsDetailsData.eco;
        } else {
          this.RevorECO = "Rev";
          this.RevorECOCount = this.docsDetailsData.revision;
        }
        if (this.documentId != "" && this.documentId != undefined) {
          sessionStorage.StatusDoc = this.docsDetailsData.status;
          this.StatusDoc = this.docsDetailsData.status;
        }
        
        if (this.isFrom == "Dashboard" || this.isFrom == "myreviewneeded" || this.isFrom == "publishedNeeded" || this.isFrom == "documentsearchAdmin") {
          sessionStorage.StatusDoc = this.docsDetailsData.status;
          this.StatusDoc = this.docsDetailsData.status;
        }
        if (this.source == "Delegated") {
          sessionStorage.StatusDoc = this.docsDetailsData.status;
          this.StatusDoc = "Delegated";
        }
        if (this.docsDetailsData.isArchived == "Y") {
          this.showActionSplit = false;
        }
        else if (this.expiryDate != null && this.expiryDate != "" && this.expiryDate != undefined && new Date(this.expiryDate) < new Date()) {
          this.showActionSplit = true;
          this.items = [
            {
              label: "Archive",
              icon: "pi pi-clone",
              command: () => {
                this.archiveDocuments();
              },
            },
          ];
        }
        else {
          if (this.docsDetailsData.displayStatus == "Obsolete New" && this.userId == this.docsDetailsData.createdBy) {
            this.showActionSplit = true;
              this.items = [
                {
                  label: "Delete",
                  icon: "pi pi-trash",
                  command: () => {
                    this.deleteDisplayClick();
                  },
                },
                { separator: true },
                {
                  label: "Send for approval",
                  icon: "pi pi-check-circle",
                  command: () => {
                    this.SendForObsApproval();
                  },
                },
              ];
          }
          else if (this.docsDetailsData.status == "New" && this.userId == this.docsDetailsData.createdBy) {
            if (this.docsDetailsData.fileName != "" && this.docsDetailsData.fileName != null && this.docsDetailsData.fileName != undefined) {
              this.showActionSplit = true;
              this.items = [
                {
                  label: "Edit",
                  icon: "pi pi-pencil",
                  command: () => {
                    this.updateDocument();
                  },
                },
                {
                  label: "Delete",
                  icon: "pi pi-trash",
                  command: () => {
                    this.deleteDisplayClick();
                  },
                },
                { separator: true },
                {
                  label: "Send for approval",
                  icon: "pi pi-check-circle",
                  command: () => {
                    this.sendForApproval();
                  },
                },
              ];
            }
            else {
              this.showActionSplit = true;
              this.items = [
                {
                  label: "Edit",
                  icon: "pi pi-pencil",
                  command: () => {
                    this.updateDocument();
                  },
                },
                {
                  label: "Delete",
                  icon: "pi pi-trash",
                  command: () => {
                    this.deleteDisplayClick();
                  },
                }
              ];
            }
          }
          else if (this.docsDetailsData.status == "Approved") 
          {
            this.ShowECODocs = true;
            if ( this.docsDetailsData.ecoParentId > 0 && this.docsDetailsData.isRevExists != "Y") 
            {
              this.showActionSplit = true;
              this.items = [
                {
                  label: "Revision",
                  icon: "pi pi-replay",
                  command: () => {
                    this.reviewDocument();
                  },
                },
              ];
            }
            if (this.docsDetailsData.ecoParentId <= 0 && this.docsDetailsData.isRevExists == "Y") 
            {
              this.showActionSplit = true;
              this.items = [
                {
                  label: "Create ECO",
                  icon: "pi pi-plus",
                  command: () => {
                    this.createECO();
                  },
                },
              ];
            } 
            else if ( this.docsDetailsData.ecoParentId > 0 && this.docsDetailsData.isRevExists == "Y") 
            {
              this.showActionSplit = false;
            } 
            else {
              this.showActionSplit = true;
              if(this.docsDetailsData.isObsInprocess == "N" &&
               (this.userId == this.docsDetailsData.createdBy 
                || this.docsDetailsData.owner == "File Transfer"
              || this.SHOW_REV_ECO_OBS))
              {
                this.items = [
                  {
                    label: "Revision",
                    icon: "pi pi-replay",
                    command: () => {
                      this.reviewDocument();
                    },
                  },
                  { separator: true },
                  {
                    label: "Create ECO",
                    icon: "pi pi-plus",
                    command: () => {
                      this.createECO();
                    },
                  },
                  { separator: true },
                  {
                    label: "Obsolete",
                    icon: "pi pi-plus",
                    command: () => {
                      this.obsoleteDocument();
                    },
                  },
                ];
              }
              else
              {
                this.items = [
                  {
                    label: "Revision",
                    icon: "pi pi-replay",
                    command: () => {
                      this.reviewDocument();
                    },
                  },
                  { separator: true },
                  {
                    label: "Create ECO",
                    icon: "pi pi-plus",
                    command: () => {
                      this.createECO();
                    },
                  }
                ];
              }
            }
            if (this.showActionSplit && this.docsDetailsData.reminders != null
              && this.docsDetailsData.reminders != "" && this.docsDetailsData.reminders != undefined && this.docsDetailsData.reminders.length > 0) {
              if (this.reviewDate != null && this.reviewDate != "" && this.reviewDate != undefined) {
                var currentDate = new Date();
                var showrevDa = new Date(this.reviewDate);
                showrevDa.setDate(showrevDa.getDate() + this.docsDetailsData.reminders[1].days);
                if (showrevDa < currentDate && currentDate <= new Date(this.reviewDate)) {
                  if (this.items.length > 0) {
                    this.items.push(
                      { separator: true },
                      {
                        label: "Send Review Reminder",
                        icon: "pi pi-clock",
                        command: () => {
                          this.SendRemainderExpiryReview('review');
                        },
                      })
                  }
                  else {
                    this.items.push(
                      {
                        label: "Send Review Reminder",
                        icon: "pi pi-clock",
                        command: () => {
                          this.SendRemainderExpiryReview('review');
                        },
                      })
                  }
                }
              }
              if (this.expiryDate != null && this.expiryDate != "" && this.expiryDate != undefined) {
                var showexpDa = new Date(this.reviewDate);
                showexpDa.setDate(showexpDa.getDate() + this.docsDetailsData.reminders[2].days);
                if (showexpDa < currentDate && currentDate <= new Date(this.expiryDate)) {
                  if (this.items.length > 0) {
                    this.items.push(
                      { separator: true }, {
                      label: "Send Expiration Reminder",
                      icon: "pi pi-clock",
                      command: () => {
                        this.SendRemainderExpiryReview('expiration');
                      },
                    })
                  }
                  else {
                    this.items.push({
                      label: "Send Expiration Reminder",
                      icon: "pi pi-clock",
                      command: () => {
                        this.SendRemainderExpiryReview('expiration');
                      },
                    })
                  }
                }
              }
            }
          } else if (this.docsDetailsData.status == "Hold" && this.RELEASE_ACTION) {
            this.showActionSplit = true;
            this.items = [
              {
                label: "Release",
                icon: "pi pi-arrow-circle-right",
                command: () => {
                  this.releasedisplay = true;
                  (this.Description = ""), (this.responseError = "");
                },
              },
            ];
          }
          else if (this.docsDetailsData.status == "Rejected" || this.docsDetailsData.status == "Withdrawn") {
            if (this.docsDetailsData.revisionParentId == 0 &&this.userId == this.docsDetailsData.createdBy) {
              if(this.docsDetailsData.creationSource=="CMS"){
                this.IsCMSResubmitDocumentAvailable();
              }
              else{
                this.IsResubmitDocumentAvailable();
              }
            }
            else {
              this.showActionSplit = false;
            }
          }
          if (
            (this.docsDetailsData.status == "Obsolete In-Process" ||  this.docsDetailsData.status == "In-Process" ) &&
            (this.isFrom == "" || this.isFrom == "Dashboard" || this.isFrom == null || this.isFrom === 'originatedReport' || this.isFrom === 'altdocid' || this.isFrom === 'pendingforapproval' || this.isFrom === 'byproject')
          ) {
            this.showActionSplit = true;
            this.items = [
              {
                label: "Hold",
                icon: "pi pi-pause",
                command: () => {
                  this.clarificationdisplay = true;
                  (this.Description = ""), (this.responseError = "");
                },
              },
              { separator: true },
              {
                label: "Withdraw",
                icon: "pi pi-times",
                command: () => {
                  this.withdrawdisplay = true;
                  (this.WithDrawDescription = ""), (this.responseError = "");
                },
              },
              { separator: true },
              {
                label: "Send Approval Reminder",
                icon: "pi pi-clock",
                command: () => {
                  this.sendRemainder();
                },
              }
            ];
          } else if (
            (this.docsDetailsData.status == "Obsolete In-Process" ||  this.docsDetailsData.status == "In-Process" ) &&
            this.isFrom == "ApprovalNeeded"
          ) {
            if (this.docsDetailsData.status == "Hold") {
              this.showActionSplit = false;
            }
            else if (this.docsDetailsData.status == "In-Process" && this.userId == this.docsDetailsData.createdBy && this.isFrom != "ApprovalNeeded") {
              this.showActionSplit = true;
              this.items = [
                {
                  label: "Hold",
                  icon: "pi pi-pause",
                  command: () => {
                    this.clarificationdisplay = true;
                    (this.Description = ""), (this.responseError = "");
                  },
                },
                { separator: true },
                {
                  label: "Withdrawn",
                  icon: "pi pi-times",
                  command: () => {
                    this.withdrawdisplay = true;
                    (this.WithDrawDescription = ""), (this.responseError = "");
                  },
                },
                { separator: true },
                {
                  label: "Send Approval Reminder",
                  icon: "pi pi-clock",
                  command: () => {
                    this.sendRemainder();
                  },
                }
              ];
            }
            else if(this.Appstatus=="Approved"){
              this.showActionSplit=false;
            }
            else {
              this.showActionSplit = true;
              this.items = [
                {
                  label: "Approve",
                  icon: "pi pi-check",
                  command: () => {
                    this.approvedisplay = true;
                    (this.AprDescription = ""), (this.responseError = "");
                  },
                },
                { separator: true },
                {
                  label: "Reject",
                  icon: "pi pi-times",
                  command: () => {
                    this.rejectdisplay = true;
                    (this.RejectDescription = ""), (this.responseError = "");
                  },
                },
              ];
            }
          } else if (this.docsDetailsData.status == "Delegated" || this.StatusDoc == "Delegated") {
            this.showActionSplit = true;
            this.items = [
              {
                label: "Approve",
                icon: "pi pi-check",
                command: () => {
                  this.approvedisplay = true;
                  (this.AprDescription = ""), (this.responseError = "");
                },
              },
              { separator: true },
              {
                label: "Reject",
                icon: "pi pi-times",
                command: () => {
                  this.rejectdisplay = true;
                  (this.RejectDescription = ""), (this.responseError = "");
                },
              },
            ];
          }

          console.log(
            "Document Status onInit ::" + JSON.stringify(this.docsDetailsData.status)
          );
          if (
            this.docsDetailsData.status === "Approved" && this.isFrom === "ApprovalNeeded" && this.source === 'Approved by me' && this.userId !== this.docsDetailsData.createdBy) {
            this.showActionSplit = false;
          }
          if (
            this.docsDetailsData.status === "Expired") {
            this.showActionSplit = true;
          }
          if (
            (this.docsDetailsData.status === "In-Process" || this.docsDetailsData.status === "Hold") && this.isFrom === "ApprovalNeeded" &&
            this.userId != this.docsDetailsData.createdBy  && this.source === 'Approved by me'
          ) {
            this.showActionSplit = false;
          }
          if (!this.showActionSplit) {
            if (this.docsDetailsData.status === "In-Process" && (this.SHOW_REV_ECO_OBS|| this.userId == this.docsDetailsData.createdBy)) {
              this.showActionSplit = true;
              this.items = [
                {
                  label: "Send Approval Reminder",
                  icon: "pi pi-clock",
                  command: () => {
                    this.sendRemainder();
                  },
                }
              ];
            }
          }
          if((this.isFrom == "Dashboard" || this.isFrom == "UserDelegation" || this.isFrom == "byproject" || this.isFrom == "originatedReport" || this.isFrom == "altdocid" ||  this.isFrom == "myreviewneeded" || this.isFrom == "documentsearchAdmin" || this.isFrom == "Reports" || this.isFrom == "Admin") 
          && ((this.userId != this.docsDetailsData.createdBy || this.docsDetailsData.owner != "File Transfer") && this.HIDE_ACTION))
        {
          if(this.docsDetailsData.status="Approved"&&this.showActionSplit){

          }
          else{
            this.showActionSplit = false;
          }
        }
           
        
          if (
            this.documentId != null &&
            this.documentId != "" &&
            this.documentId != undefined &&
            (this.userId != this.docsDetailsData.createdBy || this.docsDetailsData.owner != "File Transfer")
          ) {
            this.showActionSplit = false;
          }

          //var isFromCMS = this.route.snapshot.queryParamMap.get("S");
          // if(isFromCMS=="CMS"&&this.docsDetailsData.status!="In-Process")
          // {
          //   this.showActionSplit = false;
          // }
          // if(this.docsDetailsData.status=="Approved" && this.docsDetailsData.creationSource=="CMS" && this.docsDetailsData.status !== "Expired"){
          //   this.showActionSplit = false;
          // }
          // if (this.docsDetailsData.status == "Approved"&& isFromCMS!="CMS"){
          //   this.showActionSplit = true;
          //   this.ShowECODocs = true;
          //   if (
          //     this.docsDetailsData.ecoParentId > 0 &&
          //     this.docsDetailsData.isRevExists != "Y"
          //   ) {
          //     this.showActionSplit = true;
          //     this.items = [
          //       {
          //         label: "Revision",
          //         icon: "pi pi-replay",
          //         command: () => {
          //           this.reviewDocument();
          //         },
          //       },
          //     ];
          //   }
          //   if (
          //     this.docsDetailsData.ecoParentId <= 0 &&
          //     this.docsDetailsData.isRevExists == "Y"
          //   ) {
          //     this.showActionSplit = true;
          //     this.items = [
          //       {
          //         label: "Create ECO",
          //         icon: "pi pi-plus",
          //         command: () => {
          //           this.createECO();
          //         },
          //       },
          //     ];
          //   } else if (
          //     this.docsDetailsData.ecoParentId > 0 &&
          //     this.docsDetailsData.isRevExists == "Y"
          //   ) {
          //     this.showActionSplit = false;
          //   } else {
          //     this.showActionSplit = true;
          //     this.items = [
          //       {
          //         label: "Revision",
          //         icon: "pi pi-replay",
          //         command: () => {
          //           this.reviewDocument();
          //         },
          //       },
          //       { separator: true },
          //       {
          //         label: "Create ECO",
          //         icon: "pi pi-plus",
          //         command: () => {
          //           this.createECO();
          //         },
          //       },
          //       { separator: true },
          //       {
          //         label: "Obsolete",
          //         icon: "pi pi-plus",
          //         command: () => {
          //           this.obsoleteDocument();
          //         },
          //       },
          //     ];
          //   }
          //   if(this.showActionSplit&&this.docsDetailsData.reminders!=null
          //     &&this.docsDetailsData.reminders!=""&&this.docsDetailsData.reminders!=undefined&&this.docsDetailsData.reminders.length>0){
          //     if(this.reviewDate!=null&&this.reviewDate!=""&&this.reviewDate!=undefined){
          //       var currentDate = new Date();
          //       var showrevDa=new Date(this.reviewDate);
          //       showrevDa.setDate(showrevDa.getDate()+ this.docsDetailsData.reminders[1].days);
          //     if(showrevDa<currentDate&&currentDate<=new Date(this.reviewDate)){
          //       if(this.items.length>0){
          //         this.items.push(
          //           { separator: true },
          //           {
          //           label: "Send Review Reminder",
          //           icon: "pi pi-clock",
          //           command: () => {
          //             this.SendRemainderExpiryReview('review');
          //           },
          //         })
          //       }
          //       else{
          //         this.items.push(
          //           {
          //           label: "Send Review Reminder",
          //           icon: "pi pi-clock",
          //           command: () => {
          //             this.SendRemainderExpiryReview('review');
          //           },
          //         })
          //       }
          //     }
          //    }
          //    if(this.expiryDate!=null&&this.expiryDate!=""&&this.expiryDate!=undefined){
          //     var showexpDa=new Date(this.reviewDate);
          //     showexpDa.setDate(showexpDa.getDate()+ this.docsDetailsData.reminders[2].days);
          //     if(showexpDa<currentDate&&currentDate<=new Date(this.expiryDate)){
          //       if(this.items.length>0){
          //         this.items.push(
          //           { separator: true },{
          //           label: "Send Expiration Reminder",
          //           icon: "pi pi-clock",
          //           command: () => {
          //             this.SendRemainderExpiryReview('expiration');
          //           },
          //         })
          //       }
          //       else{
          //         this.items.push({
          //           label: "Send Expiration Reminder",
          //           icon: "pi pi-clock",
          //           command: () => {
          //             this.SendRemainderExpiryReview('expiration');
          //           },
          //         })
          //       }
          //     }
          //  }
          //   }
          // }
          if( this.docsDetailsData.obsoleteParentID == null ||  this.docsDetailsData.obsoleteParentID == undefined)
            this.docsDetailsData.obsoleteParentID = 0;
          if (this.isFrom == "publishedNeeded" && this.source == "Pending Publication") {
            this.showActionSplit = true;
            this.items = [
              {
                label: this.docsDetailsData.obsoleteParentID==0?"Doc Publish":"Doc Obsolete",
                icon: "pi pi-cloud-upload",
                command: () => {
                  this.publishedDocument();
                },
              },
              { separator: true },
              {
                label: "Reject",
                icon: "pi pi-times",
                command: () => {
                  this.rejectdisplay = true;
                  (this.RejectDescription = ""), (this.responseError = "");
                },
              }
            ];
          }
          else if (this.isFrom == "publishedNeeded" && this.source == "Published") {
            this.showActionSplit = false;
          }
          if (this.docsDetailsData.documentType == "UCD"||this.docsDetailsData.documentType=="ECC-XXXX") {
            this.showActionSplit = false;
          }
          if (this.source === 'Approved by me') {
            this.showActionSplit = false;
          }
          // if(this.docsDetailsData.status=="Approved"&&this.RedirectCMS){
          //   this.showActionSplit = true;
          //   this.items = [
          //     {
          //       label: "Revision",
          //       icon: "pi pi-replay",
          //       command: () => {
          //         this.CMSRedirection('R');
          //       },
          //     },
          //     { separator: true },
          //     {
          //       label: "Create ECO",
          //       icon: "pi pi-plus",
          //       command: () => {
          //         this.CMSRedirection('E');
          //       },
          //     },
          //     { separator: true },
          //     {
          //       label: "Obsolete",
          //       icon: "pi pi-plus",
          //       command: () => {
          //         this.obsoleteDocument();
          //       },
          //     },
          //   ];
          // }
          // if ((this.docsDetailsData.status=="Rejected"||this.docsDetailsData.status=="Withdrawn")&&this.RedirectCMS)
          //  {
          //   if(this.docsDetailsData.revisionParentId==0 && 
          //     this.docsDetailsData.creationSource=="CMS" &&this.userId == this.docsDetailsData.createdBy){
          //     this.IsCMSResubmitDocumentAvailable();
          //   }
          //   else{
          //     this.showActionSplit = false;
          //   }
          // }
          var CMSType = this.route.snapshot.queryParamMap.get('CMSType');
          if (CMSType == "Rpt") {
            this.showActionSplit = false;
          }
        }
        if (this.action == "view" || this.action == "listdelegate" || this.action == "delegate") {
          this.showActionSplit = false;
        }
        this.archiveArray.push(this.docsDetailsData.fileGuid);

        if (this.docsDetailsData.userFinalEditableFileName != '' && this.docsDetailsData.userFinalEditableFileName != undefined && this.docsDetailsData.userFinalEditableFileName != null) {
          if (this.docsDetailsData.finalEditableFileName != '' && this.docsDetailsData.finalEditableFileName != undefined && this.docsDetailsData.finalEditableFileName != null) {
            this.ApprovedFile = this.docsDetailsData.userFinalEditableFileName;
          }
          else if (this.docsDetailsData.prePublishedFileName != '' && this.docsDetailsData.prePublishedFileName != undefined && this.docsDetailsData.prePublishedFileName != null) {
            this.ApprovedFile = this.docsDetailsData.userFinalEditableFileName;
          }
        }
        if(this.docsDetailsData.originatorChanges != null && this.docsDetailsData.originatorChanges != undefined)
        {
          this.docsDetailsData.originatorChanges.forEach(element => {
            var r = element.reason.split("#@#");
            element.oldOriginator = r[0];
            element.reason = r[1];
            if(element.actionDate!=null&&element.actionDate!=""&&element.actionDate!=undefined){
              let convertactiondate = new Date(element.actionDate+'Z');
              element.actionDate =  convertactiondate.toLocaleDateString() +' '+ convertactiondate.toLocaleTimeString();
            }
          });
        }
        this.Reasons = this.docsDetailsData.originatorChanges;
        if(this.Reasons == null || this.Reasons == undefined)
          this.Reasons = [];

        if (this.docsDetailsData.expirationDate !== null) {
          let expDate = new Date(this.docsDetailsData.expirationDate);
          /*if (expDate < new Date()) {
            this.showActionSplit = false;
          }*/
        }
        //this.docsDetailsData = _.sortBy(this.docsDetailsData, "createdDate");
        this.workflowDefinitionId = data.returnObject.workflowDefinitionId;
        this.showButtons(this.docsDetailsData.status);
        this.showLoading = false;
        this.docTypeId = this.docsDetailsData.typeId;
        this.getDocsTypes();
        this.GetProductLines();
        this.bindWorkFlowsList(this.docsDetailsData.productLineId,this.docsDetailsData.isMinor,this.docsDetailsData.typeId, this.docsDetailsData.workflowDefinitionId);
        this.getApprovals();
       
        // this.bindWorkFlowsList(this.docsDetailsData.typeId, this.docsDetailsData.workflowDefinitionId);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  deleteDisplayClick() {
    this.deleteDisplay = true;
    this.AreYouSureText = "Are you sure you want to delete the document ?";
  }

  // confirmDelete(){
  //   if (confirm("Are you sure you want to delete the document!")) {
  //     this.deleteDoc(sessionStorage.docId);
  //   } else {
  //     return false;
  //   }
  // }

  deleteDoc() {
    this.showLoading = true;
    this._facadeService.DeleteDocument(sessionStorage.docId).subscribe(
      (response: any) => {
        this.showLoading = false;
        this.router.navigate(["/mydocs"]);
        /*if(response.returnCode==0){
          this.router.navigate(["/mydocs"]);
        }
        else{
          alert(response.returnMessage);
        }*/
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getECODocuments() {
    this._facadeService.getECODocuments(this.docId, 0).subscribe(
      (data) => {
        this.ECODocs = data.returnObject;
        console.log("this.ECODocs===", this.ECODocs);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // exportExcelECO() {
  //   import("xlsx").then((xlsx) => {
  //     const worksheet = xlsx.utils.json_to_sheet(this.ECODocs);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //     const excelBuffer: any = xlsx.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });
  //     this.saveAsExcelFile(excelBuffer, "ECODocs");
  //   });
  // }
  getApprovals() {
    this.approvalsData = [];
    this.commentsList = [];
    this._facadeService.getApprovals(this.docId).subscribe(
      (data) => {
        let approvalsReturnData = [];
        approvalsReturnData = data.returnObject;
        if(approvalsReturnData == null || approvalsReturnData == undefined)
          approvalsReturnData = [];
        if (approvalsReturnData.length > 0) {
          approvalsReturnData.forEach(element => {
            if (element.role != "DCC Admin") {
              this.approvalsData.push(element);
            }
            if(element.role=="DCC Admin"&& (element.approvalStatus=="Rejected" || element.approvalStatus=="Withdrawn" )){
              this.approvalsData.push(element);
            }
            if (element.role == "DCC Admin" && element.approvalStatus == "Pending") {
              this.DCCShow = true;
              if(this.userId == this.docsDetailsData.createdBy){
                this.showActionSplit = true;
                this.items =[];
                this.items = [
                  {
                    label: this.docsDetailsData.obsoleteParentID==0?"Doc Publish":"Doc Obsolete",
                    icon: "pi pi-cloud-upload",
                    command: () => {
                      this.publishedDocument();
                    },
                  },
                  { separator: true },
                  {
                    label: "Reject",
                    icon: "pi pi-times",
                    command: () => {
                      this.rejectdisplay = true;
                      (this.RejectDescription = ""), (this.responseError = "");
                    },
                  },
                  { separator: true },
                  {
                    label: "Hold",
                    icon: "pi pi-pause",
                    command: () => {
                      this.clarificationdisplay = true;
                      (this.Description = ""), (this.responseError = "");
                    },
                  },
                  { separator: true },
                  {
                    label: "Withdraw",
                    icon: "pi pi-times",
                    command: () => {
                      this.withdrawdisplay = true;
                      (this.WithDrawDescription = ""), (this.responseError = "");
                    },
                  },
                  { separator: true },
                  {
                    label: "Send Approval Reminder",
                    icon: "pi pi-clock",
                    command: () => {
                      this.sendRemainder();
                    },
                  }
                ];
              }
              else{
                if(this.RELEASE_ACTION){
                  this.showActionSplit = true;
                  this.items =[];
                  this.items = [
                    {
                      label: this.docsDetailsData.obsoleteParentID==0?"Doc Publish":"Doc Obsolete",
                      icon: "pi pi-cloud-upload",
                      command: () => {
                        this.publishedDocument();
                      },
                    },
                    { separator: true },
                    {
                      label: "Reject",
                      icon: "pi pi-times",
                      command: () => {
                        this.rejectdisplay = true;
                        (this.RejectDescription = ""), (this.responseError = "");
                      },
                    },
                    { separator: true },
                    {
                      label: "Hold",
                      icon: "pi pi-pause",
                      command: () => {
                        this.clarificationdisplay = true;
                        (this.Description = ""), (this.responseError = "");
                      },
                    },
                    { separator: true },
                    {
                      label: "Withdraw",
                      icon: "pi pi-times",
                      command: () => {
                        this.withdrawdisplay = true;
                        (this.WithDrawDescription = ""), (this.responseError = "");
                      },
                    },
                    { separator: true },
                    {
                      label: "Send Approval Reminder",
                      icon: "pi pi-clock",
                      command: () => {
                        this.sendRemainder();
                      },
                    }
                  ];
                }
              }
            }
            if (element.approver == "Interim Admin") {
              element.approvalStatus = "Skipped Approval";
            }
          });
          this.approvalsData = _.sortBy(
            this.approvalsData,
            "ApprovedDate"
          ).reverse();
        }

        this.approvalsData.forEach((value, key) => {
          if (value.comments != null && value.comments != "") {
            this.commentsList.push(value);
          }
          if (
            value.approvedDate != "" &&
            value.approvedDate != null &&
            value.approvedDate != undefined
          ) {
            let approvedDate = new Date(value.approvedDate);
            value.approvedDate = approvedDate.toString();
          }
          if (
            value.userGuid === this.userGuid &&
            (value.approvalStatus === "Pending" ||
              value.approvalStatus === "Release")
          ) {
            this.showApprove = true;
            this.showReject = true;
            console.log(
              "IF Key:Value => " + value.userGuid + " : " + value.approvalStatus
            );
            return;
          }
        });
        if (this.commentsList.length == 0) {
          this.commentsExist = false;
        } else {
          this.commentsExist = true;
        }
        
        if (this.docsDetailsData.fileName != "" && this.docsDetailsData.fileName != null && this.docsDetailsData.fileName != undefined) {
          var downloadDirectly = this.route.snapshot.queryParamMap.get("downloaddirectly");
          if(this.docsDetailsData.userGuid.toUpperCase()==this.userGuid.toUpperCase()){
            this.ShowDownload=true;
          }
          else if(this.FILE_DOWNLOAD){  //DCC Admin || DMS Admin 
            this.ShowDownload=true;
          }
          else if(this.approvalsData!=null&&this.approvalsData.length>0&&this.docsDetailsData.status=="In-Process"){
            var limitedAccess = this.approvalsData.find(m=>m.userGuid.toUpperCase()==this.userGuid.toUpperCase());
            if(limitedAccess!=null){
              this.ShowDownload=true;
            }
            if(!this.ShowDownload){
              if(this.docsDetailsData.isDocAccess){
                this.ShowDownload=true;
              }
            }
          }
          else{
            if(this.docsDetailsData.isDocAccess){
              this.ShowDownload=true;
            }
          }

          if(downloadDirectly!=null){
            if(this.ShowDownload)
               this.GetFile();
            else
              this.DownloadInfoDisplay=true;
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  sendForApproval() {
    this.showLoading = true;
    this.sendFpprovalModel = {};
    this._facadeService
      .sendForApproval(this.docId, this.workflowDefinitionId)
      .subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode >= 0) {
            if (this.source == "Admin") {
              this.router.navigate(["/mydocsAdmin",{ source: 'Admin' }]);
            } else {
              this.router.navigate(["/mydocs"]);
            }
          } else {
            this.responseError = this.apiResponse.returnMessage;
          }

          this.showLoading = false;
          console.log(data);
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }

  SendForObsApproval() {
    this.showLoading = true;
    this.sendFpprovalModel = {};
    this._facadeService
      .SendForObsApproval(this.docId, this.workflowDefinitionId)
      .subscribe(
        (data) => {
          this.apiResponse = data;
          if (this.apiResponse.returnCode >= 0) {
            if (this.source == "Admin") {
              this.router.navigate(["/mydocsAdmin",{ source: 'Admin' }]);
            } else {
              this.router.navigate(["/mydocs"]);
            }
          } else {
            this.responseError = this.apiResponse.returnMessage;
          }

          this.showLoading = false;
          console.log(data);
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }

 

  createECO() {
    this.showLoading = true;
    this.ECORevClicked = true;
    this.OBSClicked = false;
    this.GetDepartments("E");
  }
  DepartmentNOClick() {
    this.DepartmentDisplay = false;
  }
  reviewDocument() {
    this.showLoading = true;
    this.ECORevClicked = false;
    this.OBSClicked = false;
    this.GetDepartments("R");
  }
  updateDocument() {
    this.showLoading = true;
    this.router.navigate(["/createnewdoc"], {
      queryParams: { docID: this.docId, Mode: 'EditMode' },
    });
  }

  showButtons(docStatus) {
    this.showSendForApproval = false;
    //this.showApprove = false;
    //this.showReject = false;
    this.showRelease = false;
    this.showHold = false;
    this.showEdit = false;
    if (docStatus == "New") {
      this.showSendForApproval = true;
      this.showEdit = true;
    }
    if (docStatus == "In-Process") {
      //this.showApprove = true;
      //this.showReject = true;
      this.showHold = true;
    }
    if (docStatus == "Hold") {
      this.showRelease = true;
    }
  }
  HoldDocument() {
    var model = {
      id: this.docId,
      userGuid: this.userGuid,
      comments: this.Description,
    };
    this.showLoading = true;
    this._facadeService.HoldDocument(model).subscribe(
      (data) => {
        this.showLoading = false;
        this.apiResponse = data;
        if (this.apiResponse.returnCode >= 0) {
          if (this.isFrom == "ApprovalNeeded") {
            this.router.navigate(["/approvalsneeded"]);
          } else if (this.isFrom == "Dashboard") {
            this.router.navigate(["/dashboard"]);
          } else if (this.isFrom == "Reports") {
            this.router.navigate(["/docsearch"]);
          }
          else if (this.isFrom == "myreviewneeded") {
            this.router.navigate(["reports/myreviewneeded"]);
          }
          else if(this.isFrom == "documentsearchAdmin")
            this.router.navigate(["/totalelapseddays"]);
          else {
            if (this.source == "Admin") {
              this.router.navigate(["/mydocsAdmin",{ source: 'Admin' }]);
            } else {
              this.router.navigate(["/mydocs"]);
            }
          }
        } else {
          this.responseError = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
        this.responseError = "Please try again.";
      }
    );
  }

  ApproveDocument() {
    var model = {
      id: this.docId,
      userGuid: this.userGuid,
      comments: this.AprDescription,
    };
    this.showLoading = true;
    this._facadeService.ApproveDocument(model).subscribe(
      (data) => {
        this.showLoading = false;
        this.apiResponse = data;
        if (this.apiResponse.returnCode >= 0) {
          if (this.isFrom == "ApprovalNeeded") {
            this.router.navigate(["/approvalsneeded"]);
          } else if (this.isFrom == "Reports") {
            this.router.navigate(["/docsearch"]);
          } else if (this.isFrom == "Dashboard") {
            this.router.navigate(["/dashboard"]);
          }
          else if (this.isFrom == "myreviewneeded") {
            this.router.navigate(["reports/myreviewneeded"]);
          } 
          else if (this.isFrom == "documentsearchAdmin") {
            this.router.navigate(["/totalelapseddays"]);
          } 
          else {
            if (this.source == "Admin") {
              this.router.navigate(["/mydocsAdmin",{ source: 'Admin' }]);
            } else {
              this.router.navigate(["/mydocs"]);
            }
          }
        } else {
          this.responseError = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
        this.responseError = "Please try again.";
      }
    );
  }

  WithdrawDocument() {
    if (
      this.WithDrawDescription == "" ||
      this.WithDrawDescription == null ||
      this.WithDrawDescription == undefined
    ) {
      this.responseError = "Please enter comments.";
    } else {
      var model = {
        id: this.docId,
        userGuid: this.userGuid,
        comments: this.WithDrawDescription,
        AdditionalStatus: "Withdraw"
      };
      this.showLoading = true;
      this._facadeService.RejectDocument(model).subscribe(
        (data) => {
          this.showLoading = false;
          this.apiResponse = data;
          if (this.apiResponse.returnCode >= 0) {
            if (this.isFrom == "ApprovalNeeded") {
              this.router.navigate(["/approvalsneeded"]);
            } else if (this.isFrom == "Dashboard") {
              this.router.navigate(["/dashboard"]);
            } else if (this.isFrom == "Reports") {
              this.router.navigate(["/docsearch"]);
            }
            else if (this.isFrom == "myreviewneeded") {
              this.router.navigate(["reports/myreviewneeded"]);
            }
            else if (this.isFrom == "documentsearchAdmin") {
              this.router.navigate(["/totalelapseddays"]);
            }
            else if (this.isFrom == "altdocid") {
              this.router.navigate(["reports/altdocid"]);
            }
            else if (this.isFrom === 'byproject') {
              this.router.navigate(["/byproject"]);
            }
            else {
              if (this.source == "Admin") {
                this.router.navigate(["/mydocsAdmin",{ source: 'Admin' }]);
              } else {
                this.router.navigate(["/mydocs"]);
              }
            }
          } else {
            this.responseError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
          this.responseError = "Please try again.";
        }
      );
    }
  }
  RejectDocument() {
    if (
      this.RejectDescription == "" ||
      this.RejectDescription == null ||
      this.RejectDescription == undefined
    ) {
      this.responseError = "Please enter comments.";
    } else {
      var model = {
        id: this.docId,
        userGuid: this.userGuid,
        comments: this.RejectDescription,
      };
      this.showLoading = true;
      this._facadeService.RejectDocument(model).subscribe(
        (data) => {
          this.showLoading = false;
          this.apiResponse = data;
          if (this.apiResponse.returnCode >= 0) {
            if (this.isFrom == "ApprovalNeeded") {
              this.router.navigate(["/approvalsneeded"]);
            } else if (this.isFrom == "Dashboard") {
              this.router.navigate(["/dashboard"]);
            } else if (this.isFrom == "Reports") {
              this.router.navigate(["/docsearch"]);
            }
            else if (this.isFrom == "myreviewneeded") {
              this.router.navigate(["reports/myreviewneeded"]);
            }
            else if (this.isFrom == "documentsearchAdmin") {
              this.router.navigate(["/totalelapseddays"]);
            }
            else {
              if (this.source == "Admin") {
                this.router.navigate(["/mydocsAdmin",{ source: 'Admin' }]);
              } else {
                this.router.navigate(["/mydocs"]);
              }
            }
          } else {
            this.responseError = this.apiResponse.returnMessage;
          }
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
          this.responseError = "Please try again.";
        }
      );
    }
  }

  ReleaseDocument() {
    var model = {
      id: this.docId,
      userGuid: this.userGuid,
      comments: this.Description,
    };
    this.showLoading = true;
    this._facadeService.ReleaseDocument(model).subscribe(
      (data) => {
        this.showLoading = false;
        this.apiResponse = data;
        if (this.apiResponse.returnCode >= 0) {
          if (this.source == "Admin") {
            this.router.navigate(["/mydocsAdmin",{ source: 'Admin' }]);
          } else {
            this.router.navigate(["/mydocs"]);
          }
        } else {
          this.responseError = this.apiResponse.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
        this.responseError = "Please try again.";
      }
    );
  }

  archiveDocuments() {
    this.showLoading = true;
    this._facadeService.ArchiveDocs(this.userId, this.archiveArray).subscribe(
      (data) => {
        this.showLoading = false;
        this.apiResponse = data;
        if (this.apiResponse.returnCode >= 0) {
          if (this.source == "Admin") {
            this.router.navigate(["/mydocsAdmin",{ source: 'Admin' }]);
          } else {
            this.router.navigate(["/mydocs"]);
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  // exportExcel() {
  //   import("xlsx").then((xlsx) => {
  //     const worksheet = xlsx.utils.json_to_sheet(this.approvalsData);
  //     const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  //     const excelBuffer: any = xlsx.write(workbook, {
  //       bookType: "xlsx",
  //       type: "array",
  //     });
  //     this.saveAsExcelFile(excelBuffer, "Approvals History");
  //   });
  // }

  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   this.showLoading = true;
  //   import("file-saver").then((FileSaver) => {
  //     let EXCEL_TYPE =
  //       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  //     let EXCEL_EXTENSION = ".xlsx";
  //     const data: Blob = new Blob([buffer], {
  //       type: EXCEL_TYPE,
  //     });
  //     FileSaver.saveAs(
  //       data,
  //       fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
  //     );
  //   });
  //   this.showLoading = false;
  // }

  print() {
    window.print();
  }

  sendRemainder() {
    this.showLoading = true;
    this._facadeService.sendRemainder(this.docsDetailsData.id).subscribe(
      (data) => {
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  publishedDocument() {
    this.showLoading = true;
    this.router.navigate(["/publisheddocment"]);
  }

  IsResubmitDocumentAvailable() {
    this.showLoading = true;
    this._facadeService.IsResubmitDocumentAvailable(this.docsDetailsData.id).subscribe(
      (data) => {
        this.showLoading = false;
        if (data.returnCode == 0) {
          this.showActionSplit = true;
          this.items = [
            {
              label: "Resubmit",
              icon: "pi pi-pencil",
              command: () => {
                this.showLoading = true;
                this.router.navigate(["/createnewdoc"], {
                  queryParams: { docID: this.docId, Mode: 'ResubmitMode' },
                });
              },
            }
          ];
        }
        else {
          this.showActionSplit = false;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  IsCMSResubmitDocumentAvailable(){
    this._facadeService.IsCMSResubmitDocumentAvailable(this.docsDetailsData.id).subscribe(
      (data) => {
        if(data.returnCode==0){
          this.showActionSplit = true;
          this.items = [
            {
              label: "Resubmit",
              icon: "pi pi-pencil",
              command: () => {
                this.showLoading = true;
                this.router.navigate(["/createnewdoc"], {
                  queryParams: { docID: this.docId, Mode: 'ResubmitMode' },
                });
              },
            }
          ];
        }
        else{
          this.showActionSplit = false;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  GetDepartments(from) {
    this.showLoading = true;
    this._facadeService.GetDepartments().subscribe(
      (data) => {
        this.showLoading = false;
        var depts = data.returnObject;
        if (depts != null && depts.length > 0) {
          var departments = sessionStorage.getItem("userdepts");
          if (departments != null && departments != "" && departments != undefined) {
            let departmentslist = JSON.parse(departments);
            var dept = depts.find(m => m.departmentId == this.docsDetailsData.departmentId);
            if (dept != null) {
              var department = departmentslist.find(m => m.id == dept.departmentGuid);
              if (department != null) {
                if (from == "R") {
                  this.router.navigate(["/createversion"]);
                }
                else if (from == "O") {
                  this.router.navigate(["/obsoletedocument"]);
                }
                else {
                  this.router.navigate(["/eco"]);
                }
              }
              else {
                this.DepartmentDisplay = true;
                if (from == "R") {
                  this.DepartmentDisplayText = "Are you sure want to Revise the document for " + this.docsDetailsData.departmentName + " department?";
                }
                else if (from == "O") {
                  this.DepartmentDisplayText = "Are you sure want to Obsolete the document for " + this.docsDetailsData.departmentName + " department?";
                }
                else {
                  this.DepartmentDisplayText = "Are you sure want to ECO the document for " + this.docsDetailsData.departmentName + " department?";
                }
              }
            }
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  DepartmentsYesClick() {
    this.DepartmentDisplay = false;
    if (this.ECORevClicked) {
      this.router.navigate(["/eco"]);
    }
    else if (this.OBSClicked) {
      this.router.navigate(["/obsoletedocument"]);
    }
    else {
      this.router.navigate(["/createversion"]);
    }
  }

  SendRemainderExpiryReview(action) {
    this.showLoading = true;
    this._facadeService.SendRemainderExpiryReview(this.docsDetailsData.id, action).subscribe(
      (data) => {
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  GetFile() {
    if (this.docsDetailsData.fileName != null && this.docsDetailsData.fileName != "" && this.docsDetailsData.fileName != undefined) {
      this.showLoading = true;
      var newname = this.docsDetailsData.userFileName.substr(0, this.docsDetailsData.userFileName.lastIndexOf('.'));
      this.Download(this.docsDetailsData.fileName, newname, this.docsDetailsData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');

  }
  GetApprovedFile() {
    if (this.docsDetailsData.userFinalEditableFileName != '' && this.docsDetailsData.userFinalEditableFileName != undefined && this.docsDetailsData.userFinalEditableFileName != null) {
      if (this.docsDetailsData.finalEditableFileName != '' && this.docsDetailsData.finalEditableFileName != undefined && this.docsDetailsData.finalEditableFileName != null) {
        var newname = this.docsDetailsData.userFinalEditableFileName.substr(0, this.docsDetailsData.userFinalEditableFileName.lastIndexOf('.'));
        this.Download(this.docsDetailsData.finalEditableFileName, newname, this.docsDetailsData.userFinalEditableFileName);

      }
      else if (this.docsDetailsData.prePublishedFileName != '' && this.docsDetailsData.prePublishedFileName != undefined && this.docsDetailsData.prePublishedFileName != null) {
        var newname = this.docsDetailsData.userFinalEditableFileName.substr(0, this.docsDetailsData.userFinalEditableFileName.lastIndexOf('.'));
        this.Download(this.docsDetailsData.prePublishedFileName, newname, this.docsDetailsData.userFinalEditableFileName);

      }
    }
  }
  getDocsTypes() {
    this.showLoading = true;

    this._facadeService.getDocsTypesESI(true, this.docId > 0 ? this.docTypeId : 0).subscribe(
      (data) => {
        var doType = [];
        this.showLoading = false;
        if (this.docsDetailsData.creationSource != null && this.docsDetailsData.creationSource != "" && this.docsDetailsData.creationSource != undefined && this.docsDetailsData.creationSource.toUpperCase() == "CMS") {
          if (data.returnObject != null && data.returnObject.length > 0) {
            data.returnObject.forEach((value, key) => {
              if (value.creationSource == "CMS" && value.documentTypeCode != "UCD") {
                doType.push(value);
              }
            });
          }
        }
        else {
          console.log('this.docsTypesData---', data.returnObject);
          console.log("Document Identifier ::" + JSON.stringify(this.docId));
          if (this.docId > 0) {
            var doctype = data.returnObject.find(x => x.documentTypeId === this.docsDetailsData.typeId);
            if (doctype.creationSource == "CMS") {
              if (data.returnObject != null && data.returnObject.length > 0) {
                data.returnObject.forEach((value, key) => {
                  if (value.creationSource == "CMS" && value.documentTypeCode != "UCD") {
                    doType.push(value);
                  }
                });
              }
            }
            else {
              if (data.returnObject != null && data.returnObject.length > 0) {
                data.returnObject.forEach((value, key) => {
                  if (value.creationSource == "DMS" && value.documentTypeCode != "UCD") {
                    doType.push(value);
                  }
                });
              }
            }
          }
          else {
            if (data.returnObject != null && data.returnObject.length > 0) {
              data.returnObject.forEach((value, key) => {
                if (value.creationSource == "DMS" && value.documentTypeCode != "UCD") {
                  doType.push(value);
                }
              });
            }
          }
        }
        this.docsTypesData = doType;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  GetProductLines() {
    this.showLoading = true;
    this._facadeService.GetProductLines().subscribe(
      (data) => {
        this.showLoading = false;
        this.productLineData = data.returnObject;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  bindWorkFlowsList(PL,Minor,DTID, WFID) {
    this.showLoading = true;
    this.workFlowModel = {
      setupId: 0,
      documentTypeId: DTID,
     documentType: null,
      workflowDefinitionId: null,
      workflowDefinitionName: null,
      isDeleted: false,
      productLineIds:PL,
      isMinor:Minor
    };
    // this.workFlowModel = {
    //   documentTypeId: DTID,
    // };
    this._facadeService.workFlowsList(this.workFlowModel).subscribe(
      (data) => {
        this.workFlowData = data.returnObject;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  obsoleteDocument() {
    this.showLoading = true;
    this.OBSClicked = true;
    this.ECORevClicked = false;
    this.GetDepartments("O");
  }
}
