<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
            <div class="p-grid p-fluid p-formgrid" >
                <div class="p-field p-col-12 p-lg-10"></div>
                <div class="p-field p-col-12 p-lg-2">
                <a>
                    <button pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add"
                        class="p-button-raised p-button-primary" (click)="AddNewButton()"
                        style="width:auto;float: right;"></button>
                </a>
        </div>
    </div>   
  
            <span class="p-input-icon-left" style="float: right">
                <p-dialog header="{{Type}} Task - Product Folder Mapping" [(visible)]="addDocsDisplay" modal="modal" showEffect="fade"
                    [style]="{ width: '60%'}">
                    <hr /><br>
                    <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-4">
                        <label>Phase<span style="color: red">*</span></label>
                        <p-autoComplete
                            [(ngModel)]="selectedPhase" [suggestions]="filteredPhase"
                            (completeMethod)="filterPhase($event)" [disabled]="FromEdit" (onSelect)="BindTaskData(selectedPhase.phaseName)"
                            field="phaseName" [dropdown]="true">
                            <ng-template>
                            <div>
                                <div>{{ filteredTask.phaseName }}</div>
                            </div>
                            </ng-template>
                        </p-autoComplete>
                        <small class="p-invalid">{{ phaseError }}</small>
                        </div>
                      <div class="p-field p-col-12 p-md-4">
                        <label>Task<span style="color: red">*</span></label>
                        <p-autoComplete
                          [(ngModel)]="selectedTask" [suggestions]="filteredTask"
                          (completeMethod)="filterTask($event)" [disabled]="FromEdit"
                          field="documentName" [dropdown]="true">
                          <ng-template>
                            <div>
                              <div>{{ filteredTask.documentName }}</div>
                            </div>
                          </ng-template>
                        </p-autoComplete>
                        <small class="p-invalid">{{ tasksError }}</small>
                      </div>
                        <div class="p-field p-col-12 p-md-4">
                            <label>Product Folder<span style="color: red">*</span></label>
                            <p-multiSelect [options]="taskfolderData" (onChange)="MainFolderChange('')"
                            [(ngModel)]="selectedMainTask" defaultLabel="Select" optionLabel="name" >
                          </p-multiSelect>
                            <small class="p-invalid">{{ taskError }}</small>
                        </div>
                        <div class="p-field p-col-12 p-md-4">
                          <label>Task Level 1 Folder</label>
                          <p-multiSelect [options]="taskSubfolderData" (onChange)="SubFolderChange('')"
                          [(ngModel)]="selectedSUbTask" defaultLabel="Select" optionLabel="name">
                        </p-multiSelect>
                      </div>
                      <div class="p-field p-col-12 p-md-4">
                        <label>Task Level 2 Folder</label>
                        <p-multiSelect [options]="taskSubSubfolderData"
                        [(ngModel)]="selectedSUbSubTask" defaultLabel="Select" optionLabel="name">
                      </p-multiSelect>
                    </div>
                        <div class="p-field p-col-12 p-md-12">
                            <label>Description</label>
                            <textarea [(ngModel)]="Description" type="text" rows="2" [maxLength]="300"
                pInputTextarea></textarea>
                        </div>
                        <div class="p-field p-col-12 p-md-6">
                          <label style="margin-right: 20px;margin-bottom: -5px;">Active</label>
                          <p-checkbox binary="active" [disabled]="!FromEdit" [(ngModel)]="active"
                          ></p-checkbox>
                      </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-9"><small class="p-invalid" style="padding-left: 20px;">{{ responseMessage}}</small></div>
                        <div class="p-field p-col-12 p-md-2">
                            <br />
                            <button pButton pRipple type="button" icon="pi pi-check" label="{{savelabel}}"
                                (click)="CreateTaskProduct();" class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                style="float: right;width: auto;"></button>
                        </div>
                        <div class="p-field p-col-12 p-md-1"></div>
                    </div>
                </p-dialog>
            </span>
  
            <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
                <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">Task - Product Folder Mapping - {{Count}}</h5>
                <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
                    <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
                        class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
                        style="float:right;height:25px; width:25px;" (click)="print()"></button>
                    <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
                        tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
                        style="float:right; height:25px; width:25px;" (click)="exportExcel()"></button>
                </span>
            </div>
            <div class="invoice invoice-header" id="invoice-content">
                <p-table #dt [value]="taskProductdata" id="excel-Departments" [columns]="cols" sortMode="multiple"
                    class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" [virtualScroll]="true"  [scrollable]="true" scrollHeight="400px"  selectionMode="multiple"
                    dataKey="id" [globalFilterFields]="['phaseName','documentName','documentLabels','taskSubfolderNames','taskSubfolder1Names','active']">
                    <ng-template pTemplate="header" let-columns class="invoice-items">
                        <tr>
                            <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
                            [ngClass]="{'documentName': i == 0, 'documentLabels':i==2,'taskSubfolderNames': i == 1 || i == 3 || i ==4 || i == 5,'active': i == 6}" >
                                {{ col.header }}<p-sortIcon [field]="col.field" style="margin: -10px 0 -10px 0; width:95%; height:25px;"></p-sortIcon></th>
                            <th style="width:60px;">Actions</th>
                        </tr>
                        <tr id="trSearch">
                            <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                                (input)="test($event.target.value,col.field,dt)"
                                    style="margin: -10px 0 -10px 0;width: 95%; height:25px;">
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr [pSelectableRow]="rowData">
                            <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                                <td *ngIf="i == 0" style="text-align: left;padding-left:15px;width: 110px;">
                                    {{ rowData[col.field] }}
                                </td>
                                <td *ngIf="i == 1" style="text-align: left;padding-left:15px;width: 20%;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 2" style="text-align: left;padding-left:15px;width: 140px;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 3" style="text-align: left;padding-left:15px;width: 20%;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 4" style="text-align: left;padding-left:15px;width: 20%;">
                                  {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 5" style="text-align: left;padding-left:15px;width: 20%;">
                                    {{rowData[col.field]}}
                                </td>
                                <td *ngIf="i == 6" style="text-align: center;width: 70px;">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                            <td style="text-align: center;width:60px;">
                                <a> <button pButton type="button" icon="pi pi-eye" pTooltip="View"
                                        tooltipPosition="left" class="p-button-raised p-button-primary"
                                        style="width:25px; height:25px;"
                                        (click)="EditTaskProduct(rowData.id,rowData.phaseName);"></button>
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8" style="text-align: left;">No records found...</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
  </div>
  <div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1500;"
    *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>