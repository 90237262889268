import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(private _httputilityService: HttputilityService) {}

  getDashboardCounts(userID,userGuid) {
    return this._httputilityService.Get("GetDashboardSummary?userId=" + userID+"&userGuid="+userGuid);
  }

  getDashboardTableData(model) {
    return this._httputilityService.Add("MyDocuments", model);
  }

  getChartData(model) {
    return this._httputilityService.Add("GetChartData", model);
  }

  getDashboardTimelineData(userId,userGuid,days) {
    return this._httputilityService.Get("UserTimelines?UID="+userId+"&UGUID="+userGuid+'&days='+days);
  }

  getDashboardDataFor30Days(userId,userGuid,accessuserId) {
    return this._httputilityService.Get("GetDashboardData?userId=" + userId +"&userGuid="+userGuid+"&accessuserId="+accessuserId);
  }

  GetUserDetails(userGuid){
    return this._httputilityService.GetU("user/GetUserDetails?UserGUID=" + userGuid );
  }
  UpdateSecurityToken(token){
    return this._httputilityService.PostU("user/UpdateSecurityToken?Token=" + token ,null);
  }
  CreateSecurityToken(){
    return this._httputilityService.PostU("user/CreateSecurityToken" ,null);
  }
}
