import { Component, OnInit, ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";
@Component({
  selector: 'app-liststandard',
  templateUrl: './liststandard.component.html',
  styleUrls: ['./liststandard.component.scss']
})
export class ListstandardComponent implements OnInit {

  userId = parseInt(sessionStorage.getItem('userId'));
  liststandard: any;
  myDocsMode: any;  
  today: any;  
  cols: any[];
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;

  constructor(private _facadeService: FacadeService, public printService: PrintService,private datePipe: DatePipe) {
  }
  
  ngOnInit(): void {
    this.ListStandardData();

    this.cols = [
      { field: 'serialNo', header: 'SNo', dynamicPlaceHolder: 'Search by sno' },
      { field: 'standardName', header: 'Standard', dynamicPlaceHolder: 'Search by standard'  },
      { field: 'shortDescription', header: 'Clause', dynamicPlaceHolder: 'Search by clause' },
      { field: 'description', header: 'Description', dynamicPlaceHolder: 'Search by description' },
      { field: 'clauseGroup', header: 'Group', dynamicPlaceHolder: 'Search by group' },
    ];
    
  }

  ListStandardData() {
    this.showLoading = true;
    var today = new Date();
    this.today = this.datePipe.transform(this.today, 'MM/dd/yyyy');
    this.myDocsMode = [
      -1
      ]  
       this._facadeService.getListStandardData(this.myDocsMode).subscribe(
      (data) => {
        this.showLoading = false;
        this.liststandard = data.returnObject;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,"Standards List",'trSearch',false);
  }
  exportExcel() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     /* save to file */
     XLSX.writeFile(wb, "Standards List.xlsx");
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
      }
  }
  range.e.r--
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}


}