import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";
@Component({
  selector: 'app-taskfolderpermission',
  templateUrl: './taskfolderpermission.component.html',
  styleUrls: ['./taskfolderpermission.component.scss']
})
export class TaskfolderpermissionComponent implements OnInit {
  cols: any[];
  Description:any="";
  showLoading:boolean;
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string; 
  addDocsDisplay: boolean;
  permissionData:any=[];
  groupName:any;
  groupNameError:any;
  Count=0;
  RoleError:any;
  responseMessage:any;
  active:boolean=true;
  RoleGroupGuid:any;
  RoleGroupId:any=0;
  groupsData:any=[];
  UserID = sessionStorage.getItem("userGuid");
  responseError:any;
  constructor(private _facadeService: FacadeService,public printService: PrintService,
     @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
  this.cols = [
  { field: 'name', header: 'Product Folder', dynamicPlaceHolder: 'Search' }
];
    this.GetMainDocTypeFolderStructure();
   
  }
  cancel()
  {
    this.cols=[];
    this.cols = [
      { field: 'name', header: 'Product Folder', dynamicPlaceHolder: 'Search' }
    ];
    this.GetMainDocTypeFolderStructure();
  }
  toggleActivation(row,rowdata)
  {
    this.showLoading=true;
    if(this.permissionData!=null&&this.permissionData.length>0){
      this.permissionData.forEach(element => {
      if(element.information != null && element.information != undefined && element.information.length > 0)
        element.information.forEach(e => {
          if(e.roleGroupId == row.roleGroupId && e.roleGroupName == row.roleGroupName && rowdata.name == element.name)
          {
            e.Edit = false;
            if(e.orgselectedRole != e.selectedRole)
              e.Edit = true;
          }
        });
      });
    }
    this.showLoading = false;
  }
  toggleActivation1(row,rowdata)
  {
    this.showLoading=true;
    if(this.permissionData!=null&&this.permissionData.length>0){
      this.permissionData.forEach(element => {
       if(element.subFolders!=null&&element.subFolders.length>0&&element.subFolders!=undefined){
        element.subFolders.forEach(element1 => {
          if(element1.information != null && element1.information != undefined && element1.information.length > 0)
          element1.information.forEach(e => {
          if(e.roleGroupId == row.roleGroupId && e.roleGroupName == row.roleGroupName && rowdata.name == element1.name)
          {
            e.Edit = false;
            if(e.orgselectedRole != e.selectedRole)
              e.Edit = true;
          }
        });
        });
       }
      });
    }
    this.showLoading = false;
  }
  toggleActivation2(row,rowdata)
  {
    this.showLoading=true;
    if(this.permissionData!=null&&this.permissionData.length>0){
      this.permissionData.forEach(element => {
       if(element.subFolders!=null&&element.subFolders.length>0&&element.subFolders!=undefined){
        element.subFolders.forEach(element1 => {
          if(element1.subSubFolders!=null&&element1.subSubFolders!=undefined&&element1.subSubFolders.length>0){
            element1.subSubFolders.forEach(element2 => {
              if(element2.information != null && element2.information != undefined && element2.information.length > 0)
              element2.information.forEach(e => {
              if(e.roleGroupId == row.roleGroupId && e.roleGroupName == row.roleGroupName && rowdata.name == element2.name)
              {
                e.Edit = false;
                if(e.orgselectedRole != e.selectedRole)
                  e.Edit = true;
              }
            });
            });
          }
        });
       }
      });
    }
    this.showLoading = false;
  }
  GetMainDocTypeFolderStructure(){
    this.showLoading=true;
    this.permissionData = [];
    this._facadeService.GetTaskPermissions().subscribe(
      (data) => {
        this.showLoading=false;
        this.permissionData = data.returnObject;
        if(this.permissionData!=null&&this.permissionData.length>0){
          this.permissionData.forEach(element => {
          if(element.information != null && element.information != undefined && element.information.length > 0){
             element.information.forEach(e => {
              e.Edit = false;
              e.orgselectedRole = e.selectedRole;
            });
          }
          if(element.subFolders!=null&&element.subFolders!=undefined&&element.subFolders.length>0){
            element.subFolders.forEach(element1 => {
              if(element1.information != null && element1.information != undefined && element1.information.length > 0){
              element1.information.forEach(e => {
            e.Edit = false;
            e.orgselectedRole = e.selectedRole;
          });
        }
          if(element1.subSubFolders!=null&&element1.subSubFolders!=undefined&&element1.subSubFolders.length>0){
            element1.subSubFolders.forEach(element2 => {
              if(element2.information != null && element2.information != undefined && element2.information.length > 0){
                element2.information.forEach(e => {
              e.Edit = false;
              e.orgselectedRole = e.selectedRole;
            });
          }
            });
          }
            });
          }
          });
        }
        if(data.returnObject!=null&&data.returnObject.length>0){
          var r = data.returnObject[0];
            r.information.forEach(element => {
              this.cols.push({ field: element.roleGroupName, header: element.roleGroupName, dynamicPlaceHolder: 'Search'})
            });
        }
    },
    (error) => { 
      this.showLoading=false;
      console.log(error);
    }
  );
  }
  delete_row (ws, row_index){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
              ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    // delete (ws['D1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  export(){
    /* table id is passed over here */   
   let element = document.getElementById('excel-Departments'); 
   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
   /* generate workbook and add the worksheet */
   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   this.delete_row(wb.Sheets.Sheet1, 1);
   ws['!cols'] = [{ wpx : 140 },{ wpx : 500 },{ wpx : 50 }];
   /* save to file */
   XLSX.writeFile(wb, "Acccess to Product Folder.xlsx");
  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    this.printService.Print(Content,'Access to Product Folder','trSearch',true);
}
SavePermissions(){
  this.showLoading=true;
  this._facadeService.CreateUpdateTaskPermissions(this.permissionData).subscribe(
    (data) => {
      this.showLoading=false;
      location.reload();
  },
  (error) => { 
    this.showLoading=false;
    console.log(error);
  }
);
}
}