import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { AppMainComponent } from "./app.main.component";
import { LoginComponent } from "./components/login/login.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ApprovalsneededComponent } from "./components/approvalsneeded/approvalsneeded.component";
import { MydocsComponent } from "./components/mydocs/mydocs.component";
import { HolddocsComponent } from "./components/holddocs/holddocs.component";
import { WorkflowsComponent } from "./components/workflows/workflows.component";
import { AdminComponent } from "./components/admin/admin.component";
import { CreatenewdocComponent } from "./components/createnewdoc/createnewdoc.component";
import { DocdetailsComponent } from "./components/docdetails/docdetails.component";
//import { createComponent } from "@angular/compiler/src/core";
import { CreateProfileComponent } from "./components/workflows/create-profile/create-profile.component";
import { CreateWorkflowsetupComponent } from "./components/workflows/create-workflowsetup/create-workflowsetup.component";
import { WfsetupComponent } from "./components/workflows/wfsetup/wfsetup.component";
import { WfprofileComponent } from "./components/workflows/wfprofile/wfprofile.component";
import { DocTypesComponent } from "./components/admin/doc-types/doc-types.component";
import { CreateversionComponent } from "./components/createversion/createversion.component";
import { EcoComponent } from "./components/eco/eco.component";
import { ListstandardComponent } from "./components/liststandard/liststandard.component";
import { DelegationComponent } from './components/delegation/delegation.component';
import { CreatestandardComponent } from './components/createstandard/createstandard.component';
import { CreateclauseComponent } from './components/createclause/createclause.component';
import { NewProfileComponent } from './components/workflows/new-profile/new-profile.component';
import { TaskAssignmentComponent } from './components/reports/task-assignment/task-assignment.component';
import { DocumentSearchComponent } from './components/reports/document-search/document-search.component';
import { EcnReportsComponent } from './components/reports/ecn-reports/ecn-reports.component';
import { AddProfileComponent } from './components/workflows/add-profile/add-profile.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { DocumenttypesComponent } from './components/documenttypes/documenttypes.component';
import { ListdelegateComponent } from './components/listdelegate/listdelegate.component'
import { ApprovalsDelegatedComponent } from './components/approvals-delegated/approvals-delegated.component';
import { ByprojectComponent } from './components/byproject/byproject.component';
import { OriginatedbystatusComponent } from './components/originatedbystatus/originatedbystatus.component';
import { PendingforapprovalComponent } from './components/pendingforapproval/pendingforapproval.component';
import { OriginatedreportComponent } from './components/originatedreport/originatedreport.component';
import { SignoffdocumentComponent} from './components/signoffdocument/signoffdocument.component';
import { PublishedneededComponent } from './components/publishedneeded/publishedneeded.component';
import {  MyreviewneededComponent } from './components/reports/myreviewneeded/myreviewneeded.component';
import {  AltDocidReportComponent } from './components/reports/alt-docid-report/alt-docid-report.component';
import { UserdelegationsComponent } from './components/userdelegations/userdelegations.component';
import { EntrypointComponent } from "./components/entrypoint/entrypoint.component";
import {AuthGuard} from './guard/auth.guard';
import { HelpguideComponent } from './components/helpguide/helpguide.component';
import { EmailnotificationComponent } from './components/emailnotification/emailnotification.component';
import { DelegatetouserComponent } from "./components/delegatetouser/delegatetouser.component";
import { WorkflowlistComponent } from "./components/workflows/workflowlist/workflowlist.component";
import { WorkflowdesignComponent } from "./components/workflows/workflowdesign/workflowdesign.component";
import {SkipapprovallistComponent  } from "./components/skipapprovallist/skipapprovallist.component";
import {SkipapprovalComponent} from "./components/skipapproval/skipapproval.component";
import { ObsoletedocumentComponent } from './components/obsoletedocument/obsoletedocument.component';
import { DccobsoletedocumentComponent } from './components/dccobsoletedocument/dccobsoletedocument.component';
import { ProductfolderstructureComponent } from "./components/productfolderstructure/productfolderstructure.component";
import { DoctypefolderstructureComponent } from "./components/doctypefolderstructure/doctypefolderstructure.component";
import { RolegroupComponent } from "./components/rolegroup/rolegroup.component";
import { DoctypedccfolderComponent } from "./components/doctypedccfolder/doctypedccfolder.component";
import { TaskproductfolderComponent } from "./components/taskproductfolder/taskproductfolder.component";
import { DccfolderpermissionComponent } from "./components/dccfolderpermission/dccfolderpermission.component";
import { TaskfolderpermissionComponent } from "./components/taskfolderpermission/taskfolderpermission.component";
import { RoleaccessfoldersComponent } from "./components/roleaccessfolders/roleaccessfolders.component";
import { DocumentoriginatorComponent } from './components/documentoriginator/documentoriginator.component';
import { PrintablereportComponent } from './components/printablereport/printablereport.component';
import { DccfoldersComponent } from './components/dccfolders/dccfolders.component';
import { ProductfoldersComponent } from './components/productfolders/productfolders.component';
import { AlldccfoldersComponent } from "./components/alldccfolders/alldccfolders.component";
import { AllproductfoldersComponent } from "./components/allproductfolders/allproductfolders.component";
import { AllprodutsComponent } from "./components/allproduts/allproduts.component";
@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: "", component: LoginComponent },
        {
          path: "",
          component: AppMainComponent,
          children: [
            { path: 'entrypoint', component:EntrypointComponent},
            { path: "dashboard", component: DashboardComponent,canActivate:[AuthGuard] },
            { path: 'originatedreport', component:OriginatedreportComponent,canActivate:[AuthGuard]},
            { path: 'byproject', component:ByprojectComponent,canActivate:[AuthGuard]},
            { path: 'originatedbystatus', component:OriginatedbystatusComponent,canActivate:[AuthGuard]},
            { path: 'pendingforapproval', component:PendingforapprovalComponent,canActivate:[AuthGuard]},
            { path: 'documenttypes', component:DocumenttypesComponent,canActivate:[AuthGuard]},
            { path: 'createclause', component:CreateclauseComponent,canActivate:[AuthGuard]},
            { path: 'createstandard', component: CreatestandardComponent,canActivate:[AuthGuard] },
            { path: "delegation", component: DelegationComponent,canActivate:[AuthGuard] },
            { path: "delegatetouser", component: DelegatetouserComponent,canActivate:[AuthGuard] },
            { path: "liststandard", component: ListstandardComponent ,canActivate:[AuthGuard]},
            { path: "admin/docTypes", component: DocTypesComponent ,canActivate:[AuthGuard]},
            { path: "createnewdoc", component: CreatenewdocComponent ,canActivate:[AuthGuard]},
            { path: "mydocs", component: MydocsComponent ,canActivate:[AuthGuard]},
            { path: "approvalsneeded", component: ApprovalsneededComponent,canActivate:[AuthGuard] },
            { path: "mydocsAdmin", component: MydocsComponent ,canActivate:[AuthGuard]},
            { path: "workflows", component: WorkflowsComponent ,canActivate:[AuthGuard]},
            { path: "create-version", component: CreateversionComponent ,canActivate:[AuthGuard]},
            { path: "admin", component: AdminComponent ,canActivate:[AuthGuard]},
            { path: "docdetails", component: DocdetailsComponent,canActivate:[AuthGuard] },
            { path: "docdetails/:source", component: DocdetailsComponent ,canActivate:[AuthGuard]},
            { path: "workflow/wfsetup", component: WfsetupComponent ,canActivate:[AuthGuard]},
            { path: "workflow/wfprofile", component: WfprofileComponent,canActivate:[AuthGuard] },
            { path: "workflow/createprofile",component: CreateProfileComponent,canActivate:[AuthGuard]},
            { path: "workflow/createworkflowsetup", component: CreateWorkflowsetupComponent,canActivate:[AuthGuard]},
            { path: "createversion", component: CreateversionComponent ,canActivate:[AuthGuard]},
            { path: "eco", component: EcoComponent,canActivate:[AuthGuard] },
            { path: "newprofile", component: NewProfileComponent,canActivate:[AuthGuard]},
            { path: "addprofile", component: AddProfileComponent,canActivate:[AuthGuard]},
            { path: "taskassignment", component: TaskAssignmentComponent,canActivate:[AuthGuard]},
            { path: "docsearch", component: DocumentSearchComponent,canActivate:[AuthGuard]},
            { path: "ecnreport", component: EcnReportsComponent,canActivate:[AuthGuard]},
            { path: "archive", component: ArchiveComponent ,canActivate:[AuthGuard]},
            { path: "listdelegate", component: ListdelegateComponent ,canActivate:[AuthGuard]},
            { path: "publisheddocment", component: SignoffdocumentComponent ,canActivate:[AuthGuard]},
            { path: "publishedneeded", component: PublishedneededComponent,canActivate:[AuthGuard]},
            { path: "delegatedapprovals", component: ApprovalsDelegatedComponent,canActivate:[AuthGuard] },
            { path: "reports/myreviewneeded", component: MyreviewneededComponent,canActivate:[AuthGuard] },
            { path: "reports/altdocid", component: AltDocidReportComponent ,canActivate:[AuthGuard]},
            { path: "userdelegations", component: UserdelegationsComponent ,canActivate:[AuthGuard]},
            { path: "searchhelp", component: HelpguideComponent ,canActivate:[AuthGuard]},
            { path: "emailnotifications", component: EmailnotificationComponent ,canActivate:[AuthGuard]},
            { path: "workflowlist", component: WorkflowlistComponent ,canActivate:[AuthGuard]},
            { path: "workflowdesign", component: WorkflowdesignComponent ,canActivate:[AuthGuard]},
            { path: "skippedapprovallist", component: SkipapprovallistComponent ,canActivate:[AuthGuard]},
            { path: "skipapproval", component: SkipapprovalComponent ,canActivate:[AuthGuard]},
            { path: "obsoletedocument", component: ObsoletedocumentComponent ,canActivate:[AuthGuard]},
            { path: "dccobsoletedocument", component: DccobsoletedocumentComponent ,canActivate:[AuthGuard]},
            { path: "productfolderstructure", component: ProductfolderstructureComponent ,canActivate:[AuthGuard]},
            { path: "doctypefolderstructure", component: DoctypefolderstructureComponent ,canActivate:[AuthGuard]},
            { path: "rolegroup", component: RolegroupComponent ,canActivate:[AuthGuard]},
            { path: "doctypedccfolder", component: DoctypedccfolderComponent ,canActivate:[AuthGuard]},
            { path: "taskproductfolder", component: TaskproductfolderComponent ,canActivate:[AuthGuard]},
            { path: "dccfolderpermission", component: DccfolderpermissionComponent ,canActivate:[AuthGuard]},
            { path: "taskfolderpermission", component: TaskfolderpermissionComponent ,canActivate:[AuthGuard]},
            { path: "roleaccessfolders", component: RoleaccessfoldersComponent ,canActivate:[AuthGuard]},
            {path:"roleaccessfolders/:source",component: RoleaccessfoldersComponent ,canActivate:[AuthGuard]},
            {path:"documentoriginator",component: DocumentoriginatorComponent ,canActivate:[AuthGuard]},
            {path:"totalelapseddays",component: PrintablereportComponent ,canActivate:[AuthGuard]},
            //{path:"dccfolders",component: DccfoldersComponent ,canActivate:[AuthGuard]},
            {path:"dccfolders",component: AlldccfoldersComponent ,canActivate:[AuthGuard]},
            //{path:"dccfolders/:source",component: DccfoldersComponent ,canActivate:[AuthGuard]},
            {path:"dccfolders/:source",component: AlldccfoldersComponent ,canActivate:[AuthGuard]},
            //{path:"productfolders",component: ProductfoldersComponent ,canActivate:[AuthGuard]},
            //{path:"productfolders",component: AllproductfoldersComponent ,canActivate:[AuthGuard]},
           // {path:"productfolders",component: AllproductfoldersComponent ,canActivate:[AuthGuard]},
            {path:"productfolders",component: AllprodutsComponent ,canActivate:[AuthGuard]},
            // {path:"productfolders/:source",component: ProductfoldersComponent ,canActivate:[AuthGuard]},
            {path:"productfolders/:source",component: AllprodutsComponent ,canActivate:[AuthGuard]},
          ],
        },
        { path: "**", redirectTo: "/notfound" },
      ],
      { scrollPositionRestoration: "enabled" }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
