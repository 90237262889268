<div class="p-grid p-fluid p-formgrid">
  <div class="p-col-12">
    <div class="card" style="min-height: 600px">
          <div class="p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12 p-lg-10 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="float:right; margin-bottom:10px;">
          <button (click)="UserDelegation()" *ngIf="!DelegationExist" [disabled]="userFeature" pButton
            pRipple type="button" icon="pi pi-reply" iconPos="left" label="Add Delegation"
            class="p-button-raised p-button-primary" style="width:auto; float:right;"></button>
          <button *ngIf="DelegationExist" (click)="deleteDisplay=true" pButton pRipple
            [disabled]="userFeature" type="button" icon="pi pi-times" iconPos="left"
            label="Cancel Delegation" class="p-button-raised p-button-danger" style="width:auto; float:right; "></button>
          </div>
        </div>
      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:auto;">
        <h5 style="color:#495057; padding:15px 0 0 15px;width: 70%;">Delegations - {{DocumentsCount}}</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
          <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right;height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
            (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right;  height:25px; width:25px;"></button>

        </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content">
        <p-table #dt [value]="delegateData" id="excel-table" [(selection)]="selectedDelegates" [columns]="cols" sortMode="multiple" class="table-align"
          styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple" dataKey="delegationId"
          [globalFilterFields]="['user','role','toUser','toRole','documentType','code','revision','fromDate','toDate']">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th style="width: 3rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>  
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" >
                {{col.header}}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="width: 75px;">Comments</th>
            </tr>
            <tr id="trSearch">
              <th></th>
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center;">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                (input)="test($event.target.value,col.field,dt)"
                  style="margin: -10px 0 -10px 0; width:90%; height:25px;">
              </th>
              <th></th>
            </tr>

          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td>
                <p-tableCheckbox [value]="rowData" style="padding-left: 5px;"></p-tableCheckbox>
            </td>
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left;padding-left: 15px;width: 10%;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 1" style="text-align: left;padding-left: 15px;width: 13%;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 2" style="text-align: left;padding-left: 15px;width: 10%;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 3" style="text-align: left;padding-left: 15px;width: 14%;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 4" style="text-align: center;width: 4%;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 5" style="text-align: center;width: 4%;">
                  <a href="javascript:;" (click)="GetDoc(rowData)">
                    {{rowData[col.field]}}
                    </a> 
                </td>
                <td *ngIf="i == 6" style="text-align: center;width: 12%;">
                  {{rowData[col.field]}}
                </td>
                <td *ngIf="i == 7" style="text-align: center;width: 10%;">
                  {{rowData[col.field] | date: 'MM/dd/yyyy'}}
                </td>
                <td *ngIf="i == 8" style="text-align: center;width: 4%;">
                  {{rowData[col.field] | date: 'MM/dd/yyyy'}}
                </td>
              </ng-container>
              <td style="text-align: center;">
                <button pButton type="button" (click)="ViewComment(rowData.comments)" icon="pi pi-eye" pTooltip="View" class="p-button-rasised p-button-primary"
      style="height:25px; width:25px;"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="10">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      &nbsp;
      <div class="p-field p-col-12">
      <button [disabled]="userFeature" (click)="CancelDelegateClick()" pButton pRipple type="button"
          icon="pi pi-times" iconPos="left" label="Cancel Delegatation"
          class="p-button-raised p-button-danger" style="float:right;width: auto; "></button>
          <small class="p-invalid">{{ delegateError }}</small>
      </div>
      &nbsp;
    </div>
    <div
      style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 5001;"
      *ngIf="showLoading">
      <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
    </div>

    <span>
      <!-- <p-dialog header="Cancel Delegation" [(visible)]="deleteDisplay" modal="modal" showEffect="fade"
        [style]="{ width: '80%' }">
        <hr />
        <div class="p-grid">

          <div class="p-col-12 p-md-7" style="text-align:center;margin-top: 25px;">
            <h4 style="color:red">{{AreYouSureText}}</h4>
          </div>
          <div class="p-col-12 p-md-2">

            <span class="p-input-icon-left" style="margin-top:8px;">

              <button pButton type="button" label="Yes" icon="pi pi-check-circle" iconPos="left"
                (click)="CancelDelegation()" class="p-button-raised p-button-danger"
                style="margin-bottom:13px; margin-left:10px;"></button>

            </span>
          </div>
          <div class="p-col-12 p-md-2">

            <span class="p-input-icon-left" style="margin-top:8px;">
              <button pButton type="button" label="No" icon="pi pi-times" iconPos="left" (click)="deleteDisplay = false"
                class="p-button-raised p-button-secondary"
               >
              </button>

            </span>
          </div>
        </div>
      </p-dialog> -->

       <p-dialog header="Cancel Delegation" [(visible)]="AlldeleteDisplay" modal="modal" showEffect="fade"
        [style]="{ width: '80%' }">
        <hr />
        <div class="p-grid">
          &nbsp;
          <div class="p-col-12 p-md-12" style="text-align:center;">
            <h4 style="color:red">Are you sure you want to cancel the below selected delegations?</h4>
          </div>
          <div class="invoice invoice-header" id="invoice-content">
            
            <p-table #dt1 [value]="usersdelegateData" id="excel-table" [columns]="userscols" sortMode="multiple" [autoLayout]="true"
          styleClass="p-datatable-striped" class="table-align" [filterDelay]="0" selectionMode="multiple"
          [globalFilterFields]="['user','role','toUser','toRole','documentType','code','revision','fromDate','toDate']"
             dataKey="delegationId"> 
             <ng-template pTemplate="header" let-columns class="invoice-items">
              <tr>      
                <th *ngFor="let col of columns" [pSortableColumn]="col.field">{{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon></th>
              
              </tr>
              <tr id="trSearch">
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center" >
                  <input pInputText type="text" (input)="test($event.target.value,col.field,dt)" placeholder="Search" style="margin: -10px 0 -10px 0;width:95%;height:25px;"/>
              </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns" let-Data>
              <tr>
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                  <td *ngIf="i == 0" style="text-align: left;padding-left: 15px;width: 10%;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 1" style="text-align: left;padding-left: 15px;width: 13%;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 2" style="text-align: left;padding-left: 15px;width: 10%;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 3" style="text-align: left;padding-left: 15px;width: 15%;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 4" style="text-align: center;width: 10%;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 5" style="text-align: center;width: 10%;">
                    <a href="javascript:;" (click)="GetDoc(rowData)">
                      {{rowData[col.field]}}
                      </a> 
                  </td>
                  <td *ngIf="i == 6" style="text-align: center;width: 11%;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 7" style="text-align: center;width: 10%;">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 8" style="text-align: center;width: 5%;">
                    {{rowData[col.field]}}
                  </td>
                </ng-container>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="10" style="text-align: left;">No records found...</td>
              </tr>
          </ng-template>
               </p-table>
          </div>
          <div class="p-col-12 p-md-11"></div>
          <div class="p-col-12 p-md-1">

            <span class="p-input-icon-left" style="margin-top:8px;">
              <button pButton type="button" label="Ok" icon="pi pi-check" iconPos="left"
                (click)="AllCancelDelegation()" class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                ></button>
            </span>
          </div>
        </div>
      </p-dialog>

      <p-dialog header="Comments" [(visible)]="commentDisplay" modal="modal" showEffect="fade"
        [style]="{ width: '80%' }">
        <hr />
        <div class="p-grid">

          <div class="p-col-12 p-md-7" style="text-align:center;margin-top: 25px;">
            <h4>{{commentText}}</h4>
          </div>
        </div>
      </p-dialog>
    </span>