<div class="p-grid table-demo">
  <div class="p-col-12">
    <div class="card" style="min-height:600px;">
<!-- 
      <div class="card-header" style=" border-radius: 5px;height:40px; ">

        <h5 style="color:#495057; padding:15px 0 0 15px;">
        </h5>
        <span class="p-input-icon-left" style="float:right; margin-bottom:10px; ">
          <span>Review need in </span> -->
          <div class="p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12 p-lg-7 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
          <label style="margin-top: 10px;margin-left: 50%">Review need in </label></div>
          <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12" style="margin-left: -20px;">
            <input id="days" type="text" pInputText [(ngModel)]="days" (keypress)="isNumberKey($event)" [maxLength]=3
            style="width:100px;margin-left:5px;margin-right:5px;" /></div>
          <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12">
          <label style="margin-top: 10px;">days</label>
          </div>
          <div class="p-field p-col-12 p-lg-1 p-md-12 p-sm-12" >
            <a>
              <button pButton pRipple type="button" [disabled]="userFeature" (click)="SearchDocuments('C')"
              icon="pi pi-search" iconPos="left" label="Search" class="p-button-raised p-button-primary"style="width:100%;"></button>
            </a>
          </div>
          </div>
         
                      <!-- </span> -->
          <!-- <span>
            <a>
              <button pButton pRipple type="button" icon="pi pi-envelope" iconPos="left" label="Send Email"
                class="p-button-raised p-button-primary" style="width: auto;float: right;" [disabled]="isDisabled"
                (click)="SendEmail()"></button>
            </a>
          </span> -->
        <!-- </span>
      </div> -->

      <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px;height:auto;">
        <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Review Needed - {{DocumentsCount}}</h5>
        <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">
          <button pButton pRipple type="button" icon="pi pi-print" (click)="print()" pTooltip="Print"
            tooltipPosition="top" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download" (click)="exportExcel()" pTooltip="Download"
            tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; margin-left:15px ;height:25px; width:25px; "></button>
        </span>
      </div>

      <div class="invoice invoice-header" id="invoice-content">
        <p-table #dt [value]="docsData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align" [virtualScroll]="true" [scrollable]="true" scrollHeight="400px"
          [filterDelay]="0" selectionMode="multiple" dataKey="id" styleClass="p-datatable-striped"
          [globalFilterFields]="[ 'code', 'Revision','eco','title', 'documentType', 'owner', 'status']">
          <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                [ngClass]="{'docid': i == 0,'rev': i == 1,'eco': i == 2,'title': i == 3,'departmentName': i == 4,
                'owner': i == 5, 'status': i == 6}">
                {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="width:80px;">Actions</th>
            </tr>
            <tr ID="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" 
                (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0;width: 90%; height:25px;" />
              </th>
              <th></th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left; padding-left:5px;width: 120px;">
                  <span *ngIf="rowData.isDocAccess==true"><a href="javascript:;" (click)="GetFile(rowData)">{{rowData[col.field] }} </a> </span>
                  <span *ngIf="rowData.isDocAccess!=true">{{ rowData[col.field] }}</span>
                </td>
                <td *ngIf="i == 1" style="text-align: center; width:120px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 2" style="text-align: center; width:120px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 3" style="text-align: left; padding-left:35px;width:430px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 4" style="text-align: center;width: 150px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 5" style="text-align: center;width: 140px;">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 6" style="text-align: center;font-weight: bold;width: 120px;"
                [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                {{ rowData[col.field] }}
                </td>
              </ng-container>
              <td style="text-align: center; width: 80px;">
                <button pButton type="button" [disabled]="userFeature" (click)="
                  viewDocDetails(docsData.id, docsData.wfDefinitionId)
                  " icon="pi pi-eye" pTooltip="View" class="p-button-rasised p-button-primary"
                  style="height:25px; width:25px;"></button>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="text-align: left;">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
<p-dialog header="Information" [(visible)]="EmailDisplay" modal="modal" showEffect="fade" [style]="{width:'50%'}">
  <hr>
  <div class="p-grid">

    <div class="p-col-12" style="text-align: center;">
      <span style="font-size:18px;">Email sent successfully.</span>
    </div>
  </div>
</p-dialog>