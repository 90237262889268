<div class="p-grid">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12">
        <div class="card" style="min-width: 600px">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-1">
              <a routerLink="/publishedneeded">
                <button pButton pRipple type="button" icon="pi pi-chevron-left" iconPos="left" label="Back"
                  class="p-button-raised p-button-primary p-mr-2 p-mb-2" style="width: auto;"></button></a>
            </div>
            <div class="p-field p-col-12 p-md-9" style="text-align: center">
              <h4 style="margin-top: 5px">{{pageTitle}}</h4>
            </div>
            <div class="p-field p-col-12 p-md-2"></div></div>
          <hr />
          <br />
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-4">
              <label for="Title">Title</label>
              <input id="Title" type="text" pInputText [(ngModel)]="title" [disabled]=true />
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="Department">Department</label>
              <input id="department" type="text" pInputText [(ngModel)]="selectedepartment" [disabled]=true />
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="site">Site</label>
              <input id="site" type="text" pInputText [(ngModel)]="site" [disabled]=true />
            </div>
            <div class="p-field p-col-12 p-md-8">
              <label for="Description">Description</label>
              <textarea [(ngModel)]="Description" id="Description" type="text" rows="2" [maxLength]="300" pInputTextarea
                [disabled]=true></textarea>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="alternatedDocId">Alt Doc Id</label>
              <input id="alternatedDocId" type="text" pInputText [(ngModel)]="alternatedDocId" [disabled]=true />
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="docType">Document Type</label>
              <input id="docType" type="text" pInputText [(ngModel)]="docType" [disabled]=true />
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label for="Workflow">Workflow</label>
              <input id="Workflow" type="text" pInputText [(ngModel)]="workflow" [disabled]=true />
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Product</label>
              <input type="text" pInputText [disabled]=true [(ngModel)]="product" />
            </div>

            <div class="p-field p-col-12 p-md-4">
              <label>Effective Date</label>
              <input type="text" pInputText [disabled]=true [(ngModel)]="effectiveDate" />
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Next Review Date</label>
              <input type="text" pInputText [disabled]=true [(ngModel)]="reviewDate" />
            </div>
            <div class="p-field p-col-12 p-md-4">
              <label>Remarks</label>
              <textarea id="Remarks" type="text" rows="2" [(ngModel)]="Remarks" pInputTextarea
                [disabled]=true></textarea>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <span style="font-weight: bold">Approved File:</span>
                <span style="padding-left: 20px;"><a href="javascript:;" (click)="GetFile()">{{ApprovedFile}}</a></span>
            </div>
            
            <div class="p-field p-col-12 p-md-4" *ngIf="!isPubish">
              <input type="file" #fileInput (click)="fileInput.value = null" value="" (change)="uploadFile($event)"
                id="upload" style="display: none" />
              <label pButton for="upload" pRipple type="button" icon="pi pi-paperclip" iconPos="right"
                label="  Choose File to Publish *" class="p-button-raised p-button-info p-mr-2 p-mb-2"></label>
              <small class="p-invalid">{{ fileError }}</small>
            </div>
            <div class="p-field p-col-12 p-md-4" *ngIf="!isPubish">
              <input type="file" #fileInput (click)="fileInput.value = null" value="" (change)="FinaluploadFile($event)"
                id="upload1" style="display: none" />
              <label pButton for="upload1" pRipple type="button" icon="pi pi-paperclip" iconPos="right"
                label="  Choose Final Editable File" class="p-button-raised p-button-info p-mr-2 p-mb-2"></label>
                <small class="p-invalid">{{ fileError1 }}</small>
            </div>
            <div class="p-field p-col-12 p-md-4"></div>
            <div class="p-field p-col-12 p-md-4">
              <span *ngIf="selectedFile">
                {{ fileName }} - {{ filesize }}
              </span>
              <span *ngIf="selectedFile">
                <button pButton pRipple type="button" icon="pi pi-times" (click)="removeFile()"
                  class="p-button-raised p-button-info p-mr-2 p-mb-2"
                  style="float: right; height: 30px"></button></span>
            </div>
            <div class="p-field p-col-12 p-md-4">
              <span *ngIf="FinalselectedFile">
                {{ fileNameFinal }} - {{ filesizeFinal }}
              </span>
              <span *ngIf="FinalselectedFile">
                <button pButton pRipple type="button" icon="pi pi-times" (click)="removeFinalFile()"
                  class="p-button-raised p-button-info p-mr-2 p-mb-2"
                  style="float: right; height: 30px"></button></span>
            </div>
            <!-- <div class="p-field p-col-12 p-md-4"></div> -->
            <div class="p-field p-col-12">
              <hr />
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-9"></div>

                <div class="p-field p-col-12 p-md-3">
                  <br />
                  <button *ngIf="!isPubish" pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Publish Document" style="width: 100%;"
                    class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="signOffDoc()"></button>
                    <button *ngIf="isPubish" pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Obsolete Document" style="width: 100%;"
                    class="p-button-raised p-button-success p-mr-2 p-mb-2" (click)="signOffDoc()"></button>
                </div>

                <small class="p-invalid" style="float: right">{{
                  responseError
                  }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.5;
      z-index: 1000;
    " *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>