import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { identity } from 'lodash';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../../services/print.service";

@Component({
  selector: 'app-workflowdesign',
  templateUrl: './workflowdesign.component.html',
  styleUrls: ['./workflowdesign.component.scss']
})
export class WorkflowdesignComponent implements OnInit {
  Title:string="";
  savemode:boolean=true;
  profiledisplay:boolean=false;
  ProfileCount:number = 0;
  profiledata: any[] = [];
  cols: any[];
  @ViewChild("dt") table: Table;

  workflowname:string="";
  version:any;
  Description:any;
  WKID: any = 0;
  workflowsData:any;
  CurrentWorkflow:any;
  AllOrgProfiles:any[];
  AllProfileData:any[];
  selectedprofile:any;
  filteredProfile:any;
  profileError:string="";
  showLoading:boolean=false;
  ErrorDisplay:boolean=false;
  Error:string="";
  constructor(
    private facadeService: FacadeService, public printService: PrintService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'profileName', header: 'Profile Name', dynamicPlaceHolder: 'Search by Profile Name' }
    ];
    sessionStorage.profileName="";
    var ID = sessionStorage.getItem("WorkflowDesignerID");
    if (ID != undefined && ID != null && ID != "")
    {
      this.WKID = Number(ID);
      if(this.WKID == 0)
      {
        this.Title = "Add";
        this.savemode = true;
        this.version = "1";
      }
      else
      {
        this.savemode = false;
        this.Title = "Edit";
      }
    }
    else
      this.router.navigate(["/workflowlist"]);
    this.getprofiles();
  }
  filterP(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.AllProfileData.length; i++) {
        let dept = this.AllProfileData[i];
        if (dept.profile.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(dept);
        }
    }    
    this.filteredProfile = filtered;
}

  getprofiles()
  {
    this.showLoading=true;
    this.facadeService.getProfiles().subscribe(
      (data) => {
        this.AllOrgProfiles = data.returnObject;
        this.AllProfileData = [];
        this.AllOrgProfiles.forEach(i => {
          var exists = false;
          if(this.AllProfileData.length != 0)
          { 
            this.AllProfileData.forEach(j => {
              if(i.profileId == j.profileId && !exists)
                exists = true;
            });
          }
          if(!exists)
          {
            const w = {
              profileId: i.profileId,
              profile: i.profile
            }
            this.AllProfileData.push(w);
          }
        });
        if(this.WKID != 0)
          this.getWorkflows();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  getWorkflows() {
    this.showLoading = true;
    this.facadeService.getAllWorkDefinitions(true).subscribe(
      (data) => {
        this.workflowsData = data.returnObject;
        if(this.workflowsData != null && this.workflowsData != undefined)
        {
          this.CurrentWorkflow = this.workflowsData.filter(x=> x.id == this.WKID);
          if(this.CurrentWorkflow != null && this.CurrentWorkflow != undefined)
          {
            if(this.CurrentWorkflow.length > 0)
            {
              this.profiledata = this.CurrentWorkflow[0].wfProfiles;
              if(this.profiledata != undefined && this.profiledata != null && this.profiledata.length != 0)
              {
                this.profiledata.forEach(i => {
                 i.details = this.getdetails(i.profileId);
                });
              }
              this.workflowname = this.CurrentWorkflow[0].name;
              this.version = this.CurrentWorkflow[0].version;
              this.Description = this.CurrentWorkflow[0].description;
              this.CountData();
            }
          } 
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  } 
  getdetails(ProfileID) : any[]
  {
    var details = [];
    var temp = this.AllOrgProfiles.filter(x=>x.profileId == ProfileID);
    if(temp != undefined && temp != null)
    {
      if(temp.length > 0)
      {
        temp.forEach(i => {
          const workflow = {
            displayName:i.displayName,
            department:i.department,
            role:i.role
          }
          details.push(workflow);
        });
        
      }
    }
    return details;
  }
  AddP()
  {
    this.profileError = "";
    if(this.selectedprofile != null && this.selectedprofile != undefined && this.selectedprofile != "")
    {
      this.showLoading = true;
      var exists = false;
      if(this.profiledata != undefined && this.profiledata != null && this.profiledata.length != 0)
      {
        this.profiledata.forEach(i => {
          if(i.profileId == this.selectedprofile.profileId && !exists)
            exists = true;
        });
      }
      if(exists)
      {
        this.profiledisplay = true;
        this.profileError = "Profile already exists";
      }
      else
      {
        const workflow = {
          profileId: this.selectedprofile.profileId,
          profileName: this.selectedprofile.profile,
          seq: this.ProfileCount + 1,
          details: this.getdetails(this.selectedprofile.profileId)
        }
        this.profiledata.push(workflow);
        this.CountData();
        this.profiledisplay = false;
        this.selectedprofile = [];
      }
      this.showLoading = false;
    }
    else
    {
      this.profiledisplay = true;
      this.profileError = "Please select profile";
    }
  }
  test(val,field,dt)
  {
    if(field == "profileName")
      sessionStorage.profileName = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.profiledata != undefined && this.profiledata != null)
    {
      var temp = this.profiledata;
      if(sessionStorage.profileName != ""&&sessionStorage.profileName != null)
        temp = temp.filter(x=> x.profileName.toLowerCase().includes(sessionStorage.profileName.toLowerCase()));
      this.ProfileCount = temp.length;
    }
    else
      this.ProfileCount = 0;
  }
  savedata()
  {
    this.Error="";
    this.ErrorDisplay=false;
    if (this.profiledata == undefined || this.profiledata == null || this.profiledata.length == 0) {
      this.ErrorDisplay = true;
      this.Error = 'Please select atleast one profile';
    }
    else if (this.workflowname == undefined || this.workflowname == '' || this.workflowname == null) {
      this.ErrorDisplay = true;
      this.Error = 'Please enter workflow name';
    }
    else if (this.Description == undefined || this.Description == '' || this.Description == null) {
      this.ErrorDisplay = true;
      this.Error = 'Please enter description';
    }
    else{ 
      this.showLoading = true;
      let wfProfileslist= [];
      let profileSeq = 1;
      this.profiledata.forEach(i => {
        const ii = {
          definitionId: this.WKID == 0 ? null : this.CurrentWorkflow[0].definitionId,
          profileId: i.profileId,
          profileName: i.profileName,
          seq: profileSeq++
          //seq: i.seq
        }
        wfProfileslist.push(ii);
      });
      
      const workflow = {
        id: this.WKID,
        definitionId: this.WKID == 0 ? null : this.CurrentWorkflow[0].definitionId,
        name:this.workflowname,
        displayName:this.workflowname,
        description:this.Description,
        version:Number(this.version),
        active:true,
        wfProfiles: wfProfileslist
     }
     console.log("workflow: ", JSON.stringify(workflow));
     if(this.WKID == 0)
     {
        this.facadeService.CreateWorkflow(workflow).subscribe(
          (response) => {
            this.showLoading = false;
            if(response.returnCode == 0)
              this.router.navigate(["/workflowlist"]);
            else
            {
              this.ErrorDisplay = true;
              this.Error = response.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );  
    }
    else
    { 
      this.facadeService.UpdateWorkflow(workflow).subscribe(
      (response) => {
        this.showLoading = false;
        if(response.returnCode == 0)
          this.router.navigate(["/workflowlist"]);
        else
        {
          this.ErrorDisplay = true;
          this.Error = response.returnMessage;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
     );
    }
  }
  }
  resetCtrls()
  {
    this.ProfileCount = 0;
    this.profiledata = [];
    this.workflowname = "";
    this.Description = "";
    this.version = "1";
    if(this.WKID == 0)
    {
      this.Title = "Add";
      this.savemode = true;
    }
    else
    {
      this.Title = "Edit";
      this.savemode = false;
      this.getWorkflows();
    }
  }
  delete(rowdata)
  {
    this.showLoading = true;
    let doc= [];
    this.profiledata.forEach(i => {
      if(i.profileId != rowdata.profileId)
        doc.push(i);
    });
    this.profiledata = doc;
    this.CountData();
    this.showLoading = false;
  }
  ngAfterViewInit() {
    this.table.onRowReorder.subscribe(data => {
      var dropIndex = data.dropIndex + 1;
      var dragIndex = data.dragIndex + 1;
      if(dragIndex > dropIndex)
      {
        this.profiledata.forEach(e =>
        {
          if(e.seq >= dropIndex && e.seq <= dragIndex)
          {
            if(e.seq == dragIndex)
              e.seq = dropIndex;
            else
              e.seq = e.seq + 1;
          }
        });
      }
      if(dragIndex < dropIndex)
      {
        this.profiledata.forEach(e =>
        {
          if(e.seq <= dropIndex && e.seq >= dragIndex)
          {
            if(e.seq == dragIndex)
              e.seq = dropIndex;
            else
              e.seq = e.seq - 1;
          }
        });
      }
  });
  }
}
