import { Component, OnInit,ViewChild,Inject, ChangeDetectorRef } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
//import { DatePipe } from '@angular/common';
//import { Router } from '@angular/router';
import { Table } from "primeng/table";
import { FormBuilder, FormGroup } from "@angular/forms";
//import { HttpClient } from "@angular/common/http";
import * as XLSX from 'xlsx'; 
import { approvalNeededModel } from '../../models/approvalneeded_model';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PrintService } from "../../services/print.service";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: 'app-userdelegations',
  templateUrl: './userdelegations.component.html',
  styleUrls: ['./userdelegations.component.scss']
})
export class UserdelegationsComponent implements OnInit {

    constructor(
      private fb: FormBuilder,
      //private http: HttpClient,
      private _facadeService: FacadeService,
      private datePipe: DatePipe,
      private router: Router,
      public printService: PrintService,
      private route: ActivatedRoute,
      @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private cdr: ChangeDetectorRef
    ) {
      this.form = this.fb.group({
        name: [""],
        avatar: null,
      });
    }
    commentText="";
    ShowHide:boolean;
    userId = parseInt(sessionStorage.getItem("userId"));
    today: string;
    // apiResponse: any;
    commentDisplay:boolean;
    cols: any[];
    cols1: any[];
    cols2:any[];
    form: FormGroup;
    DelegationExist:boolean;
    delegationId:number=0;
    AreYouSureText:any="";
    userGuid = sessionStorage.getItem('userGuid');
    deleteDisplay:boolean;
    DocumentsCount=0;
    delegateError="";
    // delegationModel: any;
    // departmentsData: any;
    // TodepartmentsData: any;
    // fromRoleData: any;
    // ToRolesData: any;
    // fromUserData: any;  
    // ToUsersData: any;
    // selectedDept: any;
    // selectedUser: any;
    // fromDate: any;
    // RolesData: any;
    // ToselectedDept: any;
    // selectedRole: any;
    // UsersData: any;
    // ToselectedRole: any;
    // ToselectedUser: any;
    showLoading:boolean;
    delegateData: any;
    selectedDelegates=[];
    docsData: approvalNeededModel[];
    @ViewChild("dt") table: Table;
    @ViewChild("dt1") table1: Table;
    @ViewChild("dt2") table2: Table;
    @ViewChild("dt3") table3: Table;
    index : number = 0;
    ngOnInit(): void {
      sessionStorage.ecrn = "";
      sessionStorage.code ="";
      sessionStorage.revision = "";
      sessionStorage.eco = "";
      sessionStorage.title ="";
      sessionStorage.departmentName = "";
      sessionStorage.owner = "";
      sessionStorage.status = "";
      sessionStorage.department= "";
      sessionStorage.role= "";
      sessionStorage.user= "";
      sessionStorage.toDepartment= "";
      sessionStorage.toRole= "";
      sessionStorage.toUser= "";
      sessionStorage.fromDate= "";
      sessionStorage.toDate= "";
      sessionStorage.active= "";
      sessionStorage.documentType= "";
      var index = this.route.snapshot.queryParamMap.get('index');
      if(index!=null &&index!=""&&index!=undefined){
        this.index=parseInt(index);
      }
      if(this.index==1){
        this.approvalNeededData();
      }
      else if(this.index==3){
        this.getDelegatedData();
      }
      else{
        this.GetAllDelegations(0);
      }
      this.cols = [
        { field: 'department', header: 'Department',dynamicPlaceHolder: 'Search by department' },
        { field: 'role', header: 'Role' ,dynamicPlaceHolder:'Search by role' },
        { field: 'user', header: 'User',dynamicPlaceHolder:'Search by user' },
        { field: 'toDepartment', header: 'To Department' ,dynamicPlaceHolder:'Search by toDepartment' },
        { field: 'toRole', header: 'To Role', dynamicPlaceHolder:'Search by toRole' },
        { field: 'toUser', header: 'To User' ,dynamicPlaceHolder:'Search by toUser' },
        { field: 'documentType', header: 'Document Type' ,dynamicPlaceHolder:'Search DocumentType' },
        { field: 'code', header: 'Document Id' ,dynamicPlaceHolder: 'Search by Document id' },
        { field: 'revision', header: 'Rev' ,dynamicPlaceHolder:'Search by Rev' },
        { field: 'fromDate', header: 'From Date' ,dynamicPlaceHolder:'Search by YYYY-MM-DD' },
        { field: 'toDate', header: 'To Date',dynamicPlaceHolder: 'Search by YYYY-MM-DD' }
      ];
      this.cols1 = [
        { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Document id' },
        { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
        //{ field: 'documentType', header: 'Type', dynamicPlaceHolder: 'Search by type' }, 
        { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' }, 
        { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev'},
        { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'Search by ECO'},        
        { field: 'owner', header: 'Delegated To', dynamicPlaceHolder: 'Search by delegated to'},         
        { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
      ];
      this.cols2 = [
        { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Document id' },
        { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
        //{ field: 'documentType', header: 'Type', dynamicPlaceHolder: 'Search by type' }, 
        { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' }, 
        { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev'},
        { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'Search by ECO'},        
        { field: 'owner', header: 'Delegated By', dynamicPlaceHolder: 'Search by delegated by'},         
        { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
      ];
    }

    approvalNeededData() {
      var today = new Date();
      this.today = this.datePipe.transform(this.today, 'MM/dd/yyyy');
      var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null){
          userFeature = true;
        }
      }
    }
      var myApprovalModel = {
        documentId: 0,
        departmentId: 0,
        isArchived: 'N',
        documentTypeId: 0,
        documentStatus: 'In-Process',
        todayDate: this.today,
        userID: this.userId,
        userGuid: this.userGuid,
        accessUserID:userFeature?0:parseInt(sessionStorage.getItem('userId'))
      };
      console.log("Search model2::" + JSON.stringify(myApprovalModel))
      this.showLoading = true;
      this._facadeService.approvalNeededData(myApprovalModel).subscribe(
        (data) => {
          if(data.returnObject.length>0){
            data.returnObject.forEach((value, key) => {
              if(value.eco!=null &&value.eco!="" && value.eco!=undefined){
                value.eco= value.eco;
              }
              else{
                value.eco= "N/A";
              }
            });
          }
          this.docsData = data.returnObject;
          this.CountData('');
          console.log(this.docsData);
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }

    viewDocDetails(selectedDocId, wfDefinitionId) {
      sessionStorage.docId = selectedDocId;
      sessionStorage.wfDefinitionId = wfDefinitionId;
      this.showLoading = true;
      sessionStorage.isFrom='UserDelegation';
        this.router.navigate(['/docdetails', 'Delegated']);
    }

    TabClick($event){
      sessionStorage.ecrn = "";
      sessionStorage.code ="";
      sessionStorage.revision = "";
      sessionStorage.eco = "";
      sessionStorage.title ="";
      sessionStorage.departmentName = "";
      sessionStorage.owner = "";
      sessionStorage.status = "";
      sessionStorage.department= "";
      sessionStorage.role= "";
      sessionStorage.user= "";
      sessionStorage.toDepartment= "";
      sessionStorage.toRole= "";
      sessionStorage.toUser= "";
      sessionStorage.fromDate= "";
      sessionStorage.toDate= "";
      sessionStorage.active= "";
      sessionStorage.documentType= "";
      this.ShowHide=false;
      var i = $event.index;
      if(i==1){
        this.approvalNeededData();
      }
      else if(i==0||i==2){
        this.GetAllDelegations(i);
      }
      else{
         this.getDelegatedData();
      }
    }

    getDelegatedData() {
      this.showLoading = true;
      this._facadeService.approvalDelegatedData(this.userId).subscribe(
        (data) => {
          this.docsData = data.returnObject;
          this.CountData('');
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }

    GetAllDelegations(i) {
      this.delegateData=[];
      var dataModel =[-1];
      this.showLoading = true;
      this._facadeService.GetDelegationsByIDs(dataModel).subscribe(
        (data) => {
          this.showLoading = false;
          data.returnObject.forEach(element => {
            if(element.documentType!=null&&element.documentType!=""&&element.documentType!=undefined){
              element.role="--";
            }
            if(element.code!=null&&element.code!=""&&element.code!=undefined){
              element.role="--";
            }
            // element.fromDate=this.datePipe.transform(element.fromDate, 'MM/dd/yyyy');
            // element.toDate=this.datePipe.transform(element.toDate, 'MM/dd/yyyy');
            if(i==2){
              if(element.toUserId==this.userId){
                this.delegateData.push(element);
               }
            }
           else{
            if(element.userId==this.userId){
              this.delegateData.push(element);
             }
           }
          });
          if(i==0){
            this.ShowHide=true;
            if(this.delegateData!=null && this.delegateData.length>0){
              var delegate=this.delegateData.find(m=>m.active=="Y");
              if(delegate!=null){
                this.delegationId = delegate.delegationId;
              }
              if(this.delegationId>0){
                 this.DelegationExist=true;
                 this.AreYouSureText = "Are you sure want to cancel the delegation to " + delegate.toUser + " ?";
              }
            }
            else{
              this.delegationId=0;
              this.DelegationExist=false;
              this.AreYouSureText =""
            }
          }
          else{
            this.ShowHide=false;
            this.delegationId=0;
            this.DelegationExist=false;
            this.AreYouSureText =""
          }
          console.log(this.delegateData);
          this.CountData('D');
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }

    print(ID,SearchID,Title,HideColumn) {
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
      this.printService.Print(Content,Title,SearchID,HideColumn);
    }
    exportExcel1(ID,Title) {
      // Manually load all data
      this.table.first = 0;
      this.table.rows = this.table.totalRecords;
    
      // Wait for data to load (assuming it's loaded asynchronously)
      setTimeout(() => {
        const data = this.getDataFromTable(this.table);
    
        // Proceed with exporting data to Excel
        this.exportDataToExcel(data,Title);
      }, 100); // Adjust the timeout as needed
    }
    
    // Helper function to get visible columns and data from the p-table
    getDataFromTable(table: Table): { columns: any[], data: any[] } {
      const visibleColumns = table.columns.filter(column => column.field);
      const columns = visibleColumns.map(column => {
        return {
          header: typeof column.header === 'object' ? column.header['name'] : column.header,
          field: column.field
        };
      });
      // Use the 'filteredValue' property if available, otherwise fallback to 'value'
      const data = (table.filteredValue || table.value).map(item => {
        const rowData = {};
        visibleColumns.forEach(column => {
          const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
          rowData[columnHeader] = item[column.field];
        });
        return rowData;
      });
    
      return { columns, data };
    }
    
    // Example function to export data to Excel (you can customize this based on your needs)
    exportDataToExcel(data: { columns: any[], data: any[] },Title) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
    
      // Add headers separately
      const headerRows = [data.columns.map(column => column.header)];
      XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
    
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
      ws['!cols'] = [{ wpx : 150},{ wpx : 150 },{ wpx : 120 },{ wpx : 100 },{ wpx : 150 },{ wpx : 140 },{ wpx : 100 },{ wpx : 80 },{ wpx : 80 },{ wpx : 90 },{ wpx : 80 },{ wpx : 30 }];
      //ws['!cols'] = [{ wpx : 130},{ wpx : 300 },{ wpx : 150 },{ wpx : 30 },{ wpx : 50 },{ wpx : 140 },{ wpx : 100 },{ wpx : 100 }];
      /* save to file */
      XLSX.writeFile(wb, Title + ".xlsx");
    }
  //   exportExcel1(ID,Title) {
  //      /* table id is passed over here */   
  //      let element = document.getElementById(ID); 
  //      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
  //      /* generate workbook and add the worksheet */
  //      const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //      this.delete_row1(wb.Sheets.Sheet1, 1,ID);
  //      ws['!cols'] = [{ wpx : 150},{ wpx : 150 },{ wpx : 120 },{ wpx : 100 },{ wpx : 150 },{ wpx : 140 },{ wpx : 100 },{ wpx : 80 },{ wpx : 80 },{ wpx : 90 },{ wpx : 80 },{ wpx : 30 }];
  //      //ws['!cols'] = [{ wpx : 130},{ wpx : 300 },{ wpx : 150 },{ wpx : 30 },{ wpx : 50 },{ wpx : 140 },{ wpx : 100 },{ wpx : 100 }];
  //      /* save to file */
  //      XLSX.writeFile(wb, Title + ".xlsx");
  // }
  exportExcel3(ID,Title) {
    // Manually load all data
    this.table3.first = 0;
    this.table3.rows = this.table3.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table3);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel3(data,Title);
    }, 100); // Adjust the timeout as needed
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel3(data: { columns: any[], data: any[] },Title) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    ws['!cols'] = [{ wpx : 130},{ wpx : 300 },{ wpx : 150 },{ wpx : 30 },{ wpx : 50 },{ wpx : 140 },{ wpx : 100 },{ wpx : 100 }];
    /* save to file */
    XLSX.writeFile(wb, Title + ".xlsx");
  }
//   exportExcel3(ID,Title) {
//     /* table id is passed over here */   
//     let element = document.getElementById(ID); 
//     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//     /* generate workbook and add the worksheet */
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     this.delete_row3(wb.Sheets.Sheet1, 1,ID);
//     ws['!cols'] = [{ wpx : 130},{ wpx : 300 },{ wpx : 150 },{ wpx : 30 },{ wpx : 50 },{ wpx : 140 },{ wpx : 100 },{ wpx : 100 }];
//     /* save to file */
//     XLSX.writeFile(wb, Title + ".xlsx");
// }
  delete_row1 (ws, row_index,ID){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    if(ID == "excel-table1" || ID == "excel-table3")
      delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  delete_row3 (ws, row_index,ID){
    let range = XLSX.utils.decode_range(ws["!ref"])
    for(var R = row_index; R < range.e.r; ++R){
        for(var C = range.s.c; C <= range.e.c; ++C){
            ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
            if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
        }
    }
    range.e.r--
    if(ID == "excel-table1" || ID == "excel-table3")
      delete (ws['H1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({r:r,c:c})
  }
  CancelDelegation(){ 
    var el = [];
      var userid = 0;
      this.selectedDelegates.forEach(element => {
        el.push(element.delegationId);
        userid = element.userId;
      });
    this.showLoading = true;
    this._facadeService.cancelDelegations(userid,el).subscribe(
      (data) => {
        this.showLoading = false;
        if(data!=null && data.returnCode==0){
          this.DelegationExist=false;
          this.deleteDisplay=false;
          location.reload();
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  
test(val,field,dt)
{
  if(dt.name == "dt1")
  {
    if(field == "ecrn")
      sessionStorage.ecrn = val;
    else if(field == "code")
      sessionStorage.code = val;
    else if(field == "revision")
      sessionStorage.revision = val;
    else if(field == "title")
      sessionStorage.title = val;
    else if(field == "departmentName")
      sessionStorage.departmentName = val;
    else if(field == "eco")
      sessionStorage.eco = val;
    else if(field == "owner")
      sessionStorage.owner = val;
    else if(field == "status")
      sessionStorage.status = val;
      this.CountData('');
  }
  else
  {
    if(this.delegateData.length>0){
      if(field == "department")
        sessionStorage.department = val;
      else if(field == "role")
        sessionStorage.role = val;
      else if(field == "user")
        sessionStorage.user = val;
      else if(field == "toDepartment")
        sessionStorage.toDepartment = val;
      else if(field == "toRole")
        sessionStorage.toRole = val;
      else if(field == "toUser")
        sessionStorage.toUser = val;
      else if(field == "fromDate")
        sessionStorage.fromDate = val;
      else if(field == "toDate")
        sessionStorage.toDate = val;
      else if(field == "active")
        sessionStorage.active = val;
        else if(field == "code")
    sessionStorage.code = val;
    else if(field == "revision")
    sessionStorage.revision = val;
    else if(field == "documentType")
    sessionStorage.documentType = val;
        this.CountData("D");
    }
  }
  dt = dt.filter(val,field, 'contains');
}
CountData(from){
  this.DocumentsCount = 0;
  if(from=="D"){
    if(this.delegateData != undefined && this.delegateData != null)
    {
      var temp = this.delegateData;
      if(sessionStorage.department != ""&&sessionStorage.department != null)
      temp = temp.filter(x=> x.department.toLowerCase().includes(sessionStorage.department.toLowerCase()));
      if(sessionStorage.role != ""&&sessionStorage.role != null)
      temp = temp.filter(x=> x.role.toLowerCase().includes(sessionStorage.role.toLowerCase()));
      if(sessionStorage.user != ""&&sessionStorage.user != null)
      temp = temp.filter(x=> x.user.toLowerCase().includes(sessionStorage.user.toLowerCase()));
      if(sessionStorage.toDepartment != ""&&sessionStorage.toDepartment != null)
      temp = temp.filter(x=> x.toDepartment.toLowerCase().includes(sessionStorage.toDepartment.toLowerCase()));
      if(sessionStorage.toRole != ""&&sessionStorage.toRole != null)
      temp = temp.filter(x=> x.toRole.toLowerCase().includes(sessionStorage.toRole.toLowerCase()));
      if(sessionStorage.toUser != ""&&sessionStorage.toUser != null)
      temp = temp.filter(x=> x.toUser.toLowerCase().includes(sessionStorage.toUser.toLowerCase()));
      if(sessionStorage.fromDate != ""&&sessionStorage.fromDate != null)
      temp = temp.filter(x=> x.fromDate.toLowerCase().includes(sessionStorage.fromDate.toLowerCase()));
      if(sessionStorage.toDate != ""&&sessionStorage.toDate != null)
      temp = temp.filter(x=> x.toDate.toLowerCase().includes(sessionStorage.toDate.toLowerCase()));
      if(sessionStorage.active != ""&&sessionStorage.active != null)
      temp = temp.filter(x=> x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
      if(sessionStorage.code != ""&&sessionStorage.code != null)
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if(sessionStorage.revision != ""&&sessionStorage.revision != null)
      temp = temp.filter(x=> x.revision!=null&&x.revision!=""&&x.revision.toLowerCase().includes(sessionStorage.revision.toLowerCase()));
      if(sessionStorage.documentType != ""&&sessionStorage.documentType != null)
      temp = temp.filter(x=> x.documentType!=null&&x.documentType!=""&&x.documentType.toLowerCase().includes(sessionStorage.documentType.toLowerCase()));
      this.DocumentsCount = temp.length;
    }
  }
  else{
    if(this.docsData != undefined && this.docsData != null)
    {
      var temp1 = this.docsData;
      if(sessionStorage.ecrn != ""&&sessionStorage.ecrn != null)
      temp1 = temp1.filter(x=> x.ecrn!=null&&x.ecrn!=""&&x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
      if(sessionStorage.code != ""&&sessionStorage.code != null&&sessionStorage.code != undefined)
      //temp1 = temp1.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      temp1 = temp1.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if(sessionStorage.revision != "")
      temp1 = temp1.filter(x=> x.revision.toString().includes(sessionStorage.revision.toString()));
      if(sessionStorage.eco != "")
      temp1 = temp1.filter(x=> x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
      if(sessionStorage.title != "")
      temp1 = temp1.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if(sessionStorage.departmentName != "")
      temp1 = temp1.filter(x=> x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
      if(sessionStorage.owner != "")
      temp1 = temp1.filter(x=> x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
      if(sessionStorage.status != "")
      temp1 = temp1.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      this.DocumentsCount = temp1.length;
    }
  }
}
UserDelegation(){
  sessionStorage.FromAdmin=false;
  location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'delegatetouser?action=userdelegations';
}
CancelDelegateClick(){
  this.delegateError="";
  if(this.selectedDelegates.length==0){
    this.delegateError="Please select at least one to cancel the delegation.";
  }
  else{
    this.deleteDisplay=true;
  }
}
GetDoc(rowData){
  this.showLoading=true;
  sessionStorage.docId = rowData.documentID;
  location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'docdetails?action=delegate';
}
ViewComment(comment){
  this.commentDisplay=true;
  this.commentText=comment;
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
  }

 
