import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import {
  DashboardCountsModel,
  DashboardTableModel,
  DashboardPieChartData,
  DashboardTimelineModel,
} from '../../models/dashboard_model';
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Table } from "primeng/table";
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from "lodash";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintService } from "../../services/print.service";
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DashboardComponent implements OnInit {
  userId = parseInt(sessionStorage.getItem('userId'));
  userGuid = sessionStorage.getItem('userGuid');
  myDocsObject: any;
  dashBoardCounts: DashboardCountsModel;
  dashboardTableData: DashboardTableModel;
  dashboardPieChartData: DashboardPieChartData;
  chartModel: any;
  documentChart: any;
  labels: number[] = [];
  data: number[] = [];
  timelineSubTitle: any;
  isChartDataAvailable: boolean = false;
  options: any;
  plugin: any;
  today: any;
  Timeline: any;
  //Timeline: Timeline[];
  TimelineOptions: any;//TimelineOptions[];
  selectedTimelineOption: any;//TimelineOptions;
  profileDisplayPic: any;
  profileDisplayExist: boolean;
  fileName: any;
  userDetails: any;
  docsData: DashboardTableModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading: boolean;
  isTimelineDataAvailable: boolean = false;
  DocumentsCount = 0;
  IsDCC: boolean;
  
  constructor(
    private _facadeService: FacadeService, public printService: PrintService,
    private datePipe: DatePipe,
    private route: ActivatedRoute, private router: Router, private sanitizer: DomSanitizer,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private cdr: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.showLoading = true;
    sessionStorage.ecrn = "";
    sessionStorage.code = "";
    sessionStorage.revision = "";
    sessionStorage.eco = "";
    sessionStorage.title = "";
    sessionStorage.departmentName = "";
    sessionStorage.owner = "";
    sessionStorage.status = "";
    sessionStorage.lastAccessedDate = "";
    var IsDCCQuery = this.route.snapshot.queryParamMap.get('IsDCC');
    if (IsDCCQuery != null && IsDCCQuery != "" && IsDCCQuery != undefined && IsDCCQuery == "true") {
      this.IsDCC = true;
    }
    else{
      const userFeatures = sessionStorage.getItem('userFeatures');
      if (userFeatures) {
        var feature = JSON.parse(userFeatures);
        if(feature.length>0){
          var featureExist = feature.find(m=>m.code=="DCCTILE");
          if(featureExist!=null){
            this.IsDCC = true;
          }
        }
      }
    }
   
    if (this.userGuid != null && this.userGuid != "" && this.userGuid != undefined) {
      var day = this.route.snapshot.queryParamMap.get("days");
      if (day != null && day != "" && day != undefined) {
        var days = parseInt(day);
        location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + "reports/myreviewneeded?days=" + days;
      }
    }
    this.getDashboardSummary();
    this.getChartData();
    this.getDashboardTimeline(7);
   

    this.cols = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Document Id' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Rev' },
      { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'ECO' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Title' },
      //{ field: 'documentType', header: 'Type', dynamicPlaceHolder: 'Type' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
      { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Originator' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Status' },
      { field: 'lastAccessedDate', header: 'Date', dynamicPlaceHolder: 'Date' },

    ];

    this.TimelineOptions = [
      { name: 'Week', value: 7 },
      { name: '2 Weeks', value: 14 },
      { name: '3 Weeks', value: 21 },
      { name: 'Month', value: 30 },
    ];


  }
  GetTimeline(data) {
    this.getDashboardTimeline(data.value);
  }
  approvalNeeded() {
    this.router.navigate(['/approvalsneeded', { status: 'In-Process' }]);
  }

  approvalDone() {
    this.router.navigate(['/approvalsneeded', { status: 'Approved' }]);
  }
  approvalRejected() {
    this.router.navigate(['/approvalsneeded', { status: 'Rejected' }]);
  }
  viewDocDetails(rowData) {
    console.log('rowdat--------', rowData);
    sessionStorage.isFrom = 'Dashboard';
    sessionStorage.StatusDoc = rowData.status;
    var source = '';
    sessionStorage.docId = rowData.id;
    this.router.navigate(['/docdetails', source]);
  }

  docDetails(docdet) {
    console.log('doc==========', docdet);
    sessionStorage.isFrom = 'Dashboard';
    var source = '';
    sessionStorage.StatusDoc = "";
    sessionStorage.docId = docdet.documentID;
    this.router.navigate(['/docdetails', source]);
    // this.router.navigateByUrl('/mydocs', {state: {  retrievingfrom: 'dashboard', docid: docdet.documentID }} );
  }


  getDashboardSummary() {
    this.showLoading = true;
    this._facadeService.getDashboardCounts(this.userId, this.userGuid).subscribe(
      (data) => {
        this.dashBoardCounts = data.returnObject;
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getDashboardTableData() {
    this.showLoading = true;
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null)
          userFeature = true;
      }
    }
    var useId = userFeature?0:parseInt(sessionStorage.getItem('userId'));
    this._facadeService.getDocDetailsData(0,"",useId).subscribe(
      (data) => {
        console.log(data);
        if (data.returnObject != null && data.returnObject.length > 0) {
          data.returnObject.forEach((value, key) => {
            if (value.status == 'New' || value.status == 'In-Process' || value.status == 'Obsolete In-Process') {
              value.lastAccessDateMain=value.lastAccessedDate;
              value.lastAccessedDate = "--";
            }
            else {
              value.lastAccessedDate = this.datePipe.transform(value.lastAccessedDate, 'MM/dd/yyyy');
            }
            if (value.eco != null && value.eco != "" && value.eco != undefined) {
              value.eco = value.eco;
            }
            else {
              value.eco = "N/A";
            }
          });
          this.docsData = data.returnObject;
          this.CountData();
          //  this.docsData = _.sortBy(this.docsData, "createdDate");
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getDashboardTimeline(days) {
    this.showLoading = true;
    this._facadeService.getDashboardTimeline(this.userId, this.userGuid, days).subscribe(
      (data) => {
        this.Timeline = data.returnObject;
        if (this.Timeline.length > 0) {
          this.isTimelineDataAvailable = true;
          var dict = []; // create an empty dictionary  
          for (let i = 0; i < this.Timeline.length; i++) {
            //var arr = arr.push(new Date(this.Timeline[i].actionDate));
            //this.arr = this.arr.push(this.Timeline[i].actionDate+' UTC')
            let actiondate = new Date(this.Timeline[i].actionDate + 'Z')

            dict.push({
              documentID: this.Timeline[i].documentID,
              title: this.Timeline[i].title,
              subTitle: this.Timeline[i].subTitle,
              icon: this.Timeline[i].icon,
              actionBy: this.Timeline[i].actionBy,
              //actionDate: (new Date(this.Timeline[i].actionDate+'Z')).toString(),          
              subTitleMod: this.sanitizer.bypassSecurityTrustHtml(this.Timeline[i].subTitle.substring(0, 13) + '<a style="cursor:pointer;" (click)="docDetails()">' + this.Timeline[i].subTitle.substring(12, 21) + '</a>' + this.Timeline[i].subTitle.substring(21) + actiondate.toLocaleDateString() + ' ' + actiondate.toLocaleTimeString())
            });
          }
          console.log("Time Line subtitle modified data Data :: " + JSON.stringify(dict));
          this.Timeline = dict;
        }
        this.showLoading = false;
        
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
      const activity = parseInt(value);

      if (!isNaN(activity)) {
        this.table.filter(activity, 'activity', 'gte');
      }
    }
  }

  getChartData() {
    this.chartModel = {
      documentId: 0,
      departmentId: 0,
      isArchived: 'N',
      documentTypeId: 0,
      documentStatus: '',
      todayDate: new Date(),
      userID: this.userId,
      showAllDocs: true
    };
    this._facadeService.getChartData(this.chartModel).subscribe(
      (res) => {
        for (let i = 0; i < res.returnObject.length; i++) {
          this.labels.push(res.returnObject[i].status);
        }
        for (let i = 0; i < res.returnObject.length; i++) {
          this.data.push(res.returnObject[i].count);
          if (res.returnObject[i].count > 0) {
            this.isChartDataAvailable = true;
          }
        }
        console.log("Dash board chart data :: " + JSON.stringify(res.returnObject));
        this.documentChart = {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: [
                
                // '#2196F3',
                // '#FBC02D',
                '#0288D1',
                '#e8a704',
                '#689F38',
                '#FF0000',
                '#607D8B',
                '#4DB6AC',
                '#7986CB',
                '#FBC02D',
              ],
              // backgroundColor: [
              //   '#64B5F6',
              //   '#7986CB',
              //   '#4DB6AC',
              //   '#073554',
              //   '#ddc3ca',
              // ],
            },
          ],
        };

        this.options = {
          legend: {
            display: true, position: 'right', align: 'center',
            labels: { fontColor: "#7b7c7c", fontSize: 16, padding: 28 }
          },
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  // exportExcel() {
  //   /* table id is passed over here */
  //   let element = document.getElementById('excel-table');
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   this.delete_row(wb.Sheets.Sheet1, 1);
  //   ws['!cols'] = [{ wpx: 90 }, { wpx: 30 }, { wpx: 40 }, { wpx: 500 }, { wpx: 140 }, { wpx: 100 }, { wpx: 80 }, { wpx: 70 }];
  //   /* save to file */
  //   XLSX.writeFile(wb, "Recent Documents.xlsx");
  // }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;

    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);

      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }

  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }

  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx: 90 }, { wpx: 30 }, { wpx: 40 }, { wpx: 500 }, { wpx: 140 }, { wpx: 100 }, { wpx: 80 }, { wpx: 70 }];
  
    // Save to file
    XLSX.writeFile(wb, 'Recent Documents.xlsx');
  }
  
  
// delete_row(ws, row_index) {
//     let range = XLSX.utils.decode_range(ws["!ref"])
//     for (var R = row_index; R < range.e.r; ++R) {
//         for (var C = range.s.c; C <= range.e.c; ++C) {
//             ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
//             if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
//                 ws[this.ec(R + 1, C)].v = "";
//         }
//     }
//     range.e.r--
//     delete (ws['I1'])
//     ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
// }
// ec(r, c) {
//     return XLSX.utils.encode_cell({ r: r, c: c })
// }

  // print() {
  //   var Content = document.getElementById('invoice-content').innerHTML;
  //   this.printService.Print(Content, "Recent Documents", 'trSearch', true);
  // }
  print() {
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content, "Recent Documents", 'trSearch', true);
  }
  
  MyDocTile(status) {
    location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'mydocs?TileStatus=' + status;
  }

  MyDocTileDelegate(status) {
    if (status == "ByMe") {
      location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'userdelegations?index=1';
    }
    else {
      location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'userdelegations?index=3';
    }
  }

  WorkFlowTitle(status) {
    sessionStorage.WorkflowStatus = status;
    this.router.navigate(['/workflows']);
  }
  MyPublished(type) {
    if (type == "A") {
      this.router.navigate(['/publishedneeded', { status: 'Approved' }]);
    }
    else {
      this.router.navigate(['/publishedneeded', { status: 'In-Process' }]);
    }
  }

  test(val, field, dt) {
    if (field == "code")
      sessionStorage.code = val;
    else if (field == "revision")
      sessionStorage.revision = val;
    else if (field == "title")
      sessionStorage.title = val;
    else if (field == "departmentName")
      sessionStorage.departmentName = val;
    else if (field == "eco")
      sessionStorage.eco = val;
    else if (field == "owner")
      sessionStorage.owner = val;
    else if (field == "status")
      sessionStorage.status = val;
    else if (field == "lastAccessedDate")
      sessionStorage.lastAccessedDate = val;
    dt = dt.filter(val, field, 'contains');
    this.CountData();
  }
  CountData() {
    if (this.docsData != undefined && this.docsData != null) {
      var temp = this.docsData;
      if (sessionStorage.code != "")
        //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
        temp = temp.filter(x => x.code != null && x.code != "" && x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      if (sessionStorage.revision != "")
        temp = temp.filter(x => x.revision.toString().includes(sessionStorage.revision.toString()));
      if (sessionStorage.eco != "")
        temp = temp.filter(x => x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
      if (sessionStorage.title != "")
        temp = temp.filter(x => x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
      if (sessionStorage.departmentName != "")
        temp = temp.filter(x => x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));

      if (sessionStorage.owner != "")
        temp = temp.filter(x => x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
      if (sessionStorage.status != "")
        temp = temp.filter(x => x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
      if (sessionStorage.lastAccessedDate != "")
        temp = temp.filter(x => x.lastAccessedDate.toString().toLowerCase().includes(sessionStorage.lastAccessedDate.toLowerCase()));

      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }
  customSort(event: SortEvent) {
   event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;
  
      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (event.field === 'lastAccessedDate') {
        // Convert the string dates to Date objects for correct sorting
        const date1 = value1 === '--' ? null : new Date(value1);
        const date2 = value2 === '--' ? null : new Date(value2);
  
        // Handle special case: treat "--" as greater or smaller than any date
        if (date1 === null) {
          result = date2 === null ? 0 : -1;
        } else if (date2 === null) {
          result = 1;
        } else {
          result = date1.getTime() - date2.getTime();
        }
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
  
      return (event.order * result);
    });
  
    event.data.forEach(e => {
      if (e.status == 'New' || e.status == 'In-Process' || e.status == 'Obsolete In-Process') {
        e.lastAccessedDate = "--";
      } else if (event.field !== 'lastAccessedDate') {
        // Only format lastAccessedDate if it's not the field used for sorting
        e.lastAccessedDate = this.datePipe.transform(e.lastAccessedDate, 'MM/dd/yyyy');
      }
    });
    this.docsData = [...event.data];
  }
  
  
  
  GetFile(rowData) {
    if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
      this.showLoading = true;
      var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
      this.Download(rowData.fileName, newname, rowData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  
  }
}
