import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx';
import { PrintService } from "../../services/print.service";
@Component({
  selector: 'app-dccfolderpermission',
  templateUrl: './dccfolderpermission.component.html',
  styleUrls: ['./dccfolderpermission.component.scss']
})
export class DccfolderpermissionComponent implements OnInit {
  cols: any[];
  Description: any = "";
  showLoading: boolean;
  @ViewChild("dt") table: Table;
  expandedRows: {} = {};
  dynamicPlaceHolder: string;
  addDocsDisplay: boolean;
  permissionData: any = [];
  groupName: any;
  groupNameError: any;
  Count = 0;
  RoleError: any;
  responseMessage: any;
  active: boolean = true;
  RoleGroupGuid: any;
  RoleGroupId: any = 0;
  groupsData: any = [];
  UserID = parseInt(sessionStorage.getItem("userId"));
  responseError: any;
  selectedfolder = "D";
  groupFolderData = [];
  header = "Access to DCC Folders";
  public columnActivationState: { [key: string]: boolean } = {};
  constructor(private _facadeService: FacadeService, public printService: PrintService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'folderName', header: 'DCC Folder', dynamicPlaceHolder: 'Search' }
    ];

    this.GetGroupFolders();

  }
  cancel() {
    this.cols = [];
    if (this.selectedfolder == "P") {
      this.cols = [
        { field: 'folderName', header: 'Product Sub Folder', dynamicPlaceHolder: 'Search' }
      ];
    }
    else {
      this.cols = [
        { field: 'folderName', header: 'DCC Folder', dynamicPlaceHolder: 'Search' }
      ];
    }

    this.GetGroupFolders();
  }
  toggleActivation(row, rowdata) {
    this.showLoading = true;
    if (this.permissionData != null && this.permissionData.length > 0) {
      this.permissionData.forEach(element => {
        if (element.information != null && element.information != undefined && element.information.length > 0)
          element.information.forEach(e => {
            if (e.groupId == row.groupId && e.groupName == row.groupName && rowdata.folderName == element.folderName) {
              var isExist = this.groupFolderData.find(m => m.groupId == e.groupId && m.folderId == element.folderId);
              if (isExist != null) {
                if (e.selectedRole == false) {
                  e.action = "delete";
                }
                else {
                  e.action = "";
                }
              }
              else {
                if (e.selectedRole == true) {
                  e.action = "new";
                }
                else {
                  e.action = "";
                }
              }
              e.Edit = false;
              if (e.InitialValue != e.selectedRole)
                e.Edit = true;
            }
          });
      });
    }
    this.showLoading = false;
  }
  toggleActivation1(row, rowdata) {
    this.showLoading = true;
    if (this.permissionData != null && this.permissionData.length > 0) {
      this.permissionData.forEach(element => {
        if (element.subFolders != null && element.subFolders.length > 0 && element.subFolders != undefined) {
          element.subFolders.forEach(element1 => {
            if (element1.information != null && element1.information != undefined && element1.information.length > 0)
              element1.information.forEach(e => {
                if (e.groupId == row.groupId && e.groupName == row.groupName && rowdata.folderName == element1.folderName) {
                  var isExist = this.groupFolderData.find(m => m.groupId == e.groupId && m.folderId == element1.folderId);
                  if (isExist != null) {
                    if (e.selectedRole == false) {
                      e.action = "delete";
                    }
                    else {
                      e.action = "";
                    }
                  }
                  else {
                    if (e.selectedRole == true) {
                      e.action = "new";
                    }
                    else {
                      e.action = "";
                    }
                  }
                  e.Edit = false;
                  if (e.InitialValue != e.selectedRole)
                    e.Edit = true;


                }
              });
          });
        }
      });
    }
    this.showLoading = false;
  }
  toggleActivation2(row, rowdata) {
    this.showLoading = true;
    if (this.permissionData != null && this.permissionData.length > 0) {
      this.permissionData.forEach(element => {
        if (element.subFolders != null && element.subFolders.length > 0 && element.subFolders != undefined) {
          element.subFolders.forEach(element1 => {
            if (element1.subFolders != null && element1.subFolders != undefined && element1.subFolders.length > 0) {
              element1.subFolders.forEach(element2 => {
                if (element2.information != null && element2.information != undefined && element2.information.length > 0)
                  element2.information.forEach(e => {
                    if (e.groupId == row.groupId && e.groupName == row.groupName && rowdata.folderName == element2.folderName) {
                      var isExist = this.groupFolderData.find(m => m.groupId == e.groupId && m.folderId == element2.folderId);
                      if (isExist != null) {
                        if (e.selectedRole == false) {
                          e.action = "delete";
                        }
                        else {
                          e.action = "";
                        }
                      }
                      else {
                        if (e.selectedRole == true) {
                          e.action = "new";
                        }
                        else {
                          e.action = "";
                        }
                      }
                      e.Edit = false;
                      if (e.InitialValue != e.selectedRole)
                        e.Edit = true;
                    }
                  });
              });
            }
          });
        }
      });
    }
    this.showLoading = false;
  }
  toggleActivationHeader(columnHeader) {
    // Find the index of the column based on the header
    const columnIndex = this.cols.findIndex(col => col.header === columnHeader);

    if (columnIndex !== -1) {
      // Toggle the state of the column
      this.columnActivationState[columnHeader] = !this.columnActivationState[columnHeader];
      if (this.permissionData != null && this.permissionData.length > 0) {
        const isColumnActivated = this.columnActivationState[columnHeader];
        this.permissionData.forEach(element => {
          if (element.information != null && element.information != undefined && element.information.length > 0) {
            element.information.forEach(e => {
              if (e.groupName == columnHeader) {
                if (e.selectedRole == false) {
                  if (e.InitialValue) {
                    e.action = "";
                    e.Edit = false;
                  }
                  else {
                    e.action = "new";
                    e.Edit = true;
                  }
                }
                else if (e.selectedRole == true) {
                  if (e.action != null && e.action != undefined && e.action != "") {
                    if (e.action == "new") {
                      e.action = "";
                      e.Edit = false;
                    }
                    else if (e.action == "delete") {
                      e.action = "";
                      e.Edit = false;
                    }
                  }
                  else {
                    if (!isColumnActivated) {
                      e.action = "delete";
                      e.Edit = true;
                    }
                  }
                }

                if (isColumnActivated) {
                  e.selectedMainRole = true;
                  e.selectedRole = true;
                }
                else {
                  e.selectedMainRole = false;
                  e.selectedRole = false;
                }
              }
            });
          }
          if (element.subFolders != null && element.subFolders.length > 0) {
            element.subFolders.forEach(sub => {
              if (sub.information != null && sub.information != undefined && sub.information.length > 0) {
                sub.information.forEach(e => {
                  if (e.groupName == columnHeader) {
                    if (e.selectedRole == false) {
                      if (e.InitialValue) {
                        e.action = "";
                        e.Edit = false;
                      }
                      else {
                        e.action = "new";
                        e.Edit = true;
                      }
                    }
                    else if (e.selectedRole == true) {
                      if (e.action != null && e.action != undefined && e.action != "") {
                        if (e.action == "new") {
                          e.action = "";
                          e.Edit = false;
                        }
                        else if (e.action == "delete") {
                          e.action = "";
                          e.Edit = false;
                        }
                      }
                      else {
                        if (!isColumnActivated) {
                          e.action = "delete";
                          e.Edit = true;
                        }
                      }
                    }

                    if (isColumnActivated) {
                      e.selectedMainRole = true;
                      e.selectedRole = true;
                    }
                    else {
                      e.selectedMainRole = false;
                      e.selectedRole = false;
                    }
                  }
                });
              }
              if (sub.subFolders != null && sub.subFolders.length > 0) {
                sub.subFolders.forEach(subsub => {
                  if (subsub.information != null && subsub.information != undefined && subsub.information.length > 0) {
                    subsub.information.forEach(e => {
                      if (e.groupName == columnHeader) {
                        if (e.selectedRole == false) {
                          if (e.InitialValue) {
                            e.action = "";
                            e.Edit = false;
                          }
                          else {
                            e.action = "new";
                            e.Edit = true;
                          }
                        }
                        else if (e.selectedRole == true) {
                          if (e.action != null && e.action != undefined && e.action != "") {
                            if (e.action == "new") {
                              e.action = "";
                              e.Edit = false;
                            }
                            else if (e.action == "delete") {
                              e.action = "";
                              e.Edit = false;
                            }
                          }
                          else {
                            if (!isColumnActivated) {
                              e.action = "delete";
                              e.Edit = true;
                            }
                          }
                        }

                        if (isColumnActivated) {
                          e.selectedMainRole = true;
                          e.selectedRole = true;
                        }
                        else {
                          e.selectedMainRole = false;
                          e.selectedRole = false;
                        }
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    }
  }
  GetFolders() {
    this.columnActivationState = {};
    this.showLoading = true;
    this.permissionData = [];
    var model = {
      type: this.selectedfolder == "D" ? "DCC" : "Product",
      "active": true
    }
    this._facadeService.GetFolders(model).subscribe(
      (data) => {
        this.showLoading = false;
        this.permissionData = data.returnObject;
        if (this.permissionData != null && this.permissionData.length > 0) {
          this.permissionData.forEach(element => {
            element.information = [];
            if (this.groupsData != null && this.groupsData.length > 0) {
              this.groupsData.forEach(group => {
                var isGroupSelected = this.groupFolderData.find(m => m.groupId == group.groupId && m.folderId == element.folderId);
                if (isGroupSelected != null) {
                  var info = {
                    groupId: group.groupId,
                    groupName: group.groupName,
                    selectedRole: true,
                    InitialValue: true
                  }
                  element.information.push(info);
                }
                else {
                  var info = {
                    groupId: group.groupId,
                    groupName: group.groupName,
                    selectedRole: false,
                    InitialValue: false
                  }
                  element.information.push(info);
                }
              });
            }
            if (element.subFolders != null && element.subFolders.length > 0) {
              element.subFolders.forEach(sub => {
                sub.information = [];
                if (this.groupsData != null && this.groupsData.length > 0) {
                  this.groupsData.forEach(group => {
                    var isGroupSelected = this.groupFolderData.find(m => m.groupId == group.groupId && m.folderId == sub.folderId);
                    if (isGroupSelected != null) {
                      var info = {
                        groupId: group.groupId,
                        groupName: group.groupName,
                        selectedRole: true,
                        InitialValue: true
                      }
                      sub.information.push(info);
                    }
                    else {
                      var info = {
                        groupId: group.groupId,
                        groupName: group.groupName,
                        selectedRole: false,
                        InitialValue: false
                      }
                      sub.information.push(info);
                    }
                  });
                }

                if (sub.subFolders != null && sub.subFolders.length > 0) {
                  sub.subFolders.forEach(subsub => {
                    subsub.information = [];
                    if (this.groupsData != null && this.groupsData.length > 0) {
                      this.groupsData.forEach(group => {
                        var isGroupSelected = this.groupFolderData.find(m => m.groupId == group.groupId && m.folderId == subsub.folderId);
                        if (isGroupSelected != null) {
                          var info = {
                            groupId: group.groupId,
                            groupName: group.groupName,
                            selectedRole: true,
                            InitialValue: true
                          }
                          subsub.information.push(info);
                        }
                        else {
                          var info = {
                            groupId: group.groupId,
                            groupName: group.groupName,
                            selectedRole: false,
                            InitialValue: false
                          }
                          subsub.information.push(info);
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        }

        if (this.permissionData != null && this.permissionData.length > 0) {
          var r = this.permissionData[0];
          r.information.forEach(element => {
            this.cols.push({ field: element.groupName, header: element.groupName, dynamicPlaceHolder: 'Search' })
          });
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  GetGroupFolders() {
    this.columnActivationState = {};
    this.permissionData = [];
    this.groupFolderData = [];
    this.showLoading = true;
    this.groupsData = [];
    var model = [];
    var ftype = "";
    if (this.selectedfolder == "D") {
      ftype = "DCC";
    }
    else {
      ftype = "Product";
    }
    this._facadeService.GetGroupFolders(ftype, model).subscribe(
      (data) => {
        this.showLoading = false;
        this.groupFolderData = data.returnObject;
        this.GetGroups();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  GetGroups() {
    this.showLoading = true;
    this.groupsData = [];
    var model = {
      "active": true
    }
    this._facadeService.GetGroups(model).subscribe(
      (data) => {
        this.showLoading = false;
        this.groupsData = data.returnObject;
        this.GetFolders();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  delete_row(ws, row_index) {
    let range = XLSX.utils.decode_range(ws["!ref"])
    for (var R = row_index; R < range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)]
        if (ws[this.ec(R + 1, C)].v == "&nbsp;" || ws[this.ec(R + 1, C)].v == "" || ws[this.ec(R + 1, C)].v == null)
          ws[this.ec(R + 1, C)].v = "";
      }
    }
    range.e.r--
    // delete (ws['D1'])
    ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
  ec(r, c) {
    return XLSX.utils.encode_cell({ r: r, c: c })
  }
  export() {
    /* table id is passed over here */
    let element = document.getElementById('excel-Departments');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { raw: true });
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.delete_row(wb.Sheets.Sheet1, 1);
    ws['!cols'] = [{ wpx: 140 }, { wpx: 500 }, { wpx: 50 }];
    /* save to file */
    if (this.selectedfolder == "P") {
      XLSX.writeFile(wb, "Access to Product Sub Folders.xlsx");
    }
    else {
      XLSX.writeFile(wb, "Access to DCC Folders.xlsx");
    }

  }
  print() {
    var Content = document.getElementById('invoice-content').innerHTML;
    if (this.selectedfolder == "P") {
      this.printService.Print(Content, 'Access to Product Sub Folder', 'trSearch', true);
    }
    else {
      this.printService.Print(Content, 'Access to DCC Folders', 'trSearch', true);
    }

  }
  SavePermissions() {
    debugger
    var model = [];
    if (this.permissionData != null && this.permissionData.length > 0) {

      this.permissionData.forEach(element => {
        if (element.information != null && element.information.length > 0) {
          element.information.forEach(info => {
            if (info.action == "new" || info.action == "delete") {
              var perm =
              {
                "groupId": info.groupId,
                "folderId": element.folderId,
                "createdBy": this.UserID,
                "action": info.action
              }
              model.push(perm);
            }
          });
        }

        if (element.subFolders != null && element.subFolders.length > 0) {
          element.subFolders.forEach(sub => {
            if (sub.information != null && sub.information.length > 0) {
              sub.information.forEach(info => {
                if (info.action == "new" || info.action == "delete") {
                  var perm =
                  {
                    "groupId": info.groupId,
                    "folderId": sub.folderId,
                    "createdBy": this.UserID,
                    "action": info.action
                  }
                  model.push(perm);
                }
              });
            }

            if (sub.subFolders != null && sub.subFolders.length > 0) {
              sub.subFolders.forEach(subsub => {
                if (subsub.information != null && subsub.information.length > 0) {
                  subsub.information.forEach(info => {
                    if (info.action == "new" || info.action == "delete") {
                      var perm =
                      {
                        "groupId": info.groupId,
                        "folderId": subsub.folderId,
                        "createdBy": this.UserID,
                        "action": info.action
                      }
                      model.push(perm);
                    }
                  });
                }
              });
            }
          });
        }
      });

    }
    console.log(JSON.stringify(model));
    this.showLoading = true;
    this._facadeService.SaveGroupFolderAccess(model).subscribe(
      (data) => {
        this.showLoading = false;
        //location.reload();
        this.FolderChange();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  FolderChange() {
    this.columnActivationState = {};
    this.cols = [];
    this.permissionData = [];
    if (this.selectedfolder == "P") {
      this.header = "Access to Product Folders";
      this.cols = [
        { field: 'folderName', header: 'Product Folder', dynamicPlaceHolder: 'Search' }
      ];
    }
    else {
      this.header = "Access to DCC Folders";
      this.cols = [
        { field: 'folderName', header: 'DCC Folder', dynamicPlaceHolder: 'Search' }
      ];
    }
    this.GetGroupFolders();
  }
  expandAll() {
    this.expandedRows = [];
    this.showLoading = true;
    if (this.permissionData != null && this.permissionData.length > 0) {
      this.permissionData.forEach(element => {

        if (element.subFolders != null && element.subFolders.length > 0) {
          this.expandedRows[element.folderName] = true;
          element.subFolders.forEach(sub => {

            if (sub.subFolders != null && sub.subFolders.length > 0) {
              this.expandedRows[sub.folderName] = true;
              sub.subFolders.forEach(subsub => {
                this.expandedRows[subsub.folderName] = true;
              });
            }
          });
        }
      });
      this.expandedRows = Object.assign({}, this.expandedRows);
    }
    this.showLoading = false;
  }

  collapseAll() {
    this.expandedRows = [];
    this.expandedRows = Object.assign({}, this.expandedRows);
  }
  toggleSubFolderActivation(subfolders, row) {
    if (this.permissionData != null && this.permissionData.length > 0) {
      this.permissionData.forEach(element => {
        if (element.folderId == subfolders.folderId) {
          if (element.information != null && element.information.length > 0) {
            element.information.forEach(e => {
              if (e.groupId == row.groupId) {
                if (e.selectedRole == false) {
                  if (e.InitialValue) {
                    e.action = "";
                    e.Edit = false;
                  }
                  else {
                    e.action = "new";
                    e.Edit = true;
                  }
                }
                else if (e.selectedRole == true) {
                  if (e.action != null && e.action != undefined && e.action != "") {
                    if (e.action == "new") {
                      e.action = "";
                      e.Edit = false;
                    }
                    else if (e.action == "delete") {
                      e.action = "";
                      e.Edit = false;
                    }
                  }
                  else {
                    if (!row.selectedMainRole) {
                      e.action = "delete";
                      e.Edit = true;
                    }
                  }
                }
                if (row.selectedMainRole) {
                  e.selectedMainRole = true;
                  row.selectedMainRole = true;
                  e.selectedRole = true;
                }
                else {
                  e.selectedMainRole = false;
                  row.selectedMainRole = false;
                  e.selectedRole = false;
                }

              }
            });
          }
          if (element.subFolders != null && element.subFolders.length > 0) {
            element.subFolders.forEach(elementinfo => {
              if (elementinfo.information != null && elementinfo.information.length > 0) {
                elementinfo.information.forEach(e => {
                  if (e.groupId == row.groupId) {
                    if (e.selectedRole == false) {
                      if (e.InitialValue) {
                        e.action = "";
                        e.Edit = false;
                      }
                      else {
                        e.action = "new";
                        e.Edit = true;
                      }
                    }
                    else if (e.selectedRole == true) {
                      if (e.action != null && e.action != undefined && e.action != "") {
                        if (e.action == "new") {
                          e.action = "";
                          e.Edit = false;
                        }
                        else if (e.action == "delete") {
                          e.action = "";
                          e.Edit = false;
                        }
                      }
                      else {
                        if (!row.selectedMainRole) {
                          e.action = "delete";
                          e.Edit = true;
                        }
                      }
                    }
                    if (row.selectedMainRole) {
                      e.selectedMainRole = true;
                      row.selectedMainRole = true;
                      e.selectedRole = true;
                    }
                    else {
                      e.selectedMainRole = false;
                      row.selectedMainRole = false;
                      e.selectedRole = false;
                    }

                  }
                });
              }
              if (elementinfo.subFolders != null && elementinfo.subFolders.length > 0) {
                elementinfo.subFolders.forEach(subsub => {
                  if (subsub.information != null && subsub.information.length > 0) {
                    subsub.information.forEach(e => {
                      if (e.groupId == row.groupId) {
                        if (e.selectedRole == false) {
                          if (e.InitialValue) {
                            e.action = "";
                            e.Edit = false;
                          }
                          else {
                            e.action = "new";
                            e.Edit = true;
                          }
                        }
                        else if (e.selectedRole == true) {
                          if (e.action != null && e.action != undefined && e.action != "") {
                            if (e.action == "new") {
                              e.action = "";
                              e.Edit = false;
                            }
                            else if (e.action == "delete") {
                              e.action = "";
                              e.Edit = false;
                            }
                          }
                          else {
                            if (!row.selectedMainRole) {
                              e.action = "delete";
                              e.Edit = true;
                            }
                          }
                        }
                        if (row.selectedMainRole) {
                          e.selectedMainRole = true;
                          e.selectedRole = true;
                        }
                        else {
                          e.selectedMainRole = false;
                          e.selectedRole = false;
                        }

                      }
                    });
                  }
                });
              }
            });
          }
        }
      });
    }
  }
  toggleSubSubFolderActivation(subsubfolders, row) {
    if (this.permissionData != null && this.permissionData.length > 0) {
      this.permissionData.forEach(element => {
        if (element.subFolders != null && element.subFolders.length > 0) {
          element.subFolders.forEach(element3 => {
            if (element3.folderId == subsubfolders.folderId) {
              if (element3.information != null && element3.information.length > 0) {
                element3.information.forEach(e => {
                  if (e.groupId == row.groupId) {
                    if (e.selectedRole == false) {
                      if (e.InitialValue) {
                        e.action = "";
                        e.Edit = false;
                      }
                      else {
                        e.action = "new";
                        e.Edit = true;
                      }
                    }
                    else if (e.selectedRole == true) {
                      if (e.action != null && e.action != undefined && e.action != "") {
                        if (e.action == "new") {
                          e.action = "";
                          e.Edit = false;
                        }
                        else if (e.action == "delete") {
                          e.action = "";
                          e.Edit = false;
                        }
                      }
                      else {
                        if (!row.selectedMainRole) {
                          e.action = "delete";
                          e.Edit = true;
                        }
                      }
                    }
                    if (row.selectedMainRole) {
                      row.selectedMainRole = true;
                      e.selectedRole = true;
                    }
                    else {
                      row.selectedMainRole = false;
                      e.selectedRole = false;
                    }
                  }
                });
              }

              if (element3.subFolders != null && element3.subFolders.length > 0) {
                element3.subFolders.forEach(elementinfo => {

                  if (elementinfo.information != null && elementinfo.information.length > 0) {
                    elementinfo.information.forEach(e => {
                      if (e.groupId == row.groupId) {
                        if (e.selectedRole == false) {
                          if (e.InitialValue) {
                            e.action = "";
                            e.Edit = false;
                          }
                          else {
                            e.action = "new";
                            e.Edit = true;
                          }
                        }
                        else if (e.selectedRole == true) {
                          if (e.action != null && e.action != undefined && e.action != "") {
                            if (e.action == "new") {
                              e.action = "";
                              e.Edit = false;
                            }
                            else if (e.action == "delete") {
                              e.action = "";
                              e.Edit = false;
                            }
                          }
                          else {
                            if (!row.selectedMainRole) {
                              e.action = "delete";
                              e.Edit = true;
                            }
                          }
                        }
                        if (row.selectedMainRole) {
                          row.selectedMainRole = true;
                          e.selectedRole = true;
                        }
                        else {
                          row.selectedMainRole = false;
                          e.selectedRole = false;
                        }
                      }
                    });
                  }
                });
              }
            }
          });
        }
      });
    }
  }
}
