import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacadeService } from 'src/app/facade/facade.service';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss'],
})
export class CreateProfileComponent implements OnInit {
  filteredCountries: any[];

  selectedDocType: any;
  selectedDepartment: any;
  selectedRole: any;
  showLoading:boolean;
  countries: any[];
  
  workFlowModel: any;
  workFlowData: any;
  docTypeError: any;
  docsTypesData: any;
  docTypes = [];
  docTypeResult = [];
  departments = [];
  departmentsResult = [];
  roles = [];
  rolesResult = [];

  profileForm = {
    profileName: '',
    noOfApprovals: '',
  };

  constructor(private facadeService: FacadeService, private router: Router) {}

  ngOnInit(): void {
    this.getDocsTypes();
    this.getDepartments();
  }
  filterCountry(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.countries.length; i++) {
      const country = this.countries[i];
      if (country.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredCountries = filtered;
  }

  // getDocTypes() {
  //   this.facadeService.getDocTypes().subscribe((response) => {
  //     this.docTypes = response.returnObject;
  //     console.log(this.docTypes);
  //   })
  // }

  getDocsTypes() {
    this.facadeService.getDocsTypes().subscribe(
      (data) => {
        this.docsTypesData = data;
        this.docsTypesData = this.docsTypesData.returnObject;
        console.log(this.docsTypesData);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  filterDocTypes(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.docTypes.length; i++) {
      const dcType = this.docTypes[i];
      if (dcType.documentType.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(dcType);
      }
    }
    this.docTypeResult = filtered;
  }

  getDepartments() {
    this.facadeService.getDepartMents().subscribe((response: any) => {
      this.departments = response.returnObject;
      console.log(this.departments);
    });
  }

  filterDepartments(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.departments.length; i++) {
      const departMent = this.departments[i];
      if (
        departMent.departmentName.toLowerCase().indexOf(query.toLowerCase()) ==
        0
      ) {
        filtered.push(departMent);
      }
    }
    this.departmentsResult = filtered;
  }

  getRolesByDepartmentGuid() {
    console.log(this.selectedDocType);
    this.facadeService
      .getRolesByDepartmentGuid(this.selectedDepartment.departmentGuid)
      .subscribe((result: any) => {
        this.roles = result.returnObject;
        console.log(this.roles);
      });
  }

  filterRoles(event) {
    const filtered: any[] = [];
    const query = event.query;
    for (let i = 0; i < this.roles.length; i++) {
      const role = this.roles[i];
      if (role.roleName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(role);
      }
    }
    this.rolesResult = filtered;
  }

  getWorkFlowsList(selectedDoc) {
    this.workFlowModel = {
      documentTypeId: selectedDoc.documentTypeId,
    };
    this.facadeService.workFlowsList(this.workFlowModel).subscribe(
      (data) => {
        this.workFlowData = data.returnObject;
        //console.log("Inner WF ::" + JSON.stringify( this.workFlowData));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  createProfile() {
    var profile: any = new FormData();
    profile.append('ProfileId ', 0);
    profile.append('profileCode ', this.profileForm.profileName);
    profile.append('DocumentTypeId', this.selectedDocType.documentTypeId);
    profile.append('DepartmentId ', this.selectedDepartment.departmentId);
    profile.append('DepartmentGuid', this.selectedDepartment.departmentGuid);
    profile.append('RoleId', this.selectedRole.roleId);
    profile.append('RoleGuid', this.selectedRole.roleGuid);
    profile.append('ApprovalNumbers', this.profileForm.noOfApprovals);

    this.facadeService.createProfile(profile).subscribe((response) => {
      console.log(response);
      this.router.navigate(['workflow/wfprofile']);
    });
  }
}
