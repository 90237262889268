import { Injectable } from '@angular/core';
import { HttputilityService } from './httputility.service';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(private _httputilityService: HttputilityService) {}
  //baseUrlWF: any = 'http://132.148.242.138/elsa/api/DMSWorkflow/';
  //baseUrl: any = 'http://132.148.242.138/FPDMS/api/';
  
  getAllWorkDefinitions(showprofile) {
    return this._httputilityService.Get("AllDMSWorkflows?active=true&showprofiles="+showprofile);
  }
  
  DeleteWorkflow(id){
    return this._httputilityService.Add("DeleteWorkflow?WFID="+id,'');
  }

  CreateWorkflow(model){
    return this._httputilityService.Add("CreateWorkflow",model);
  }
  UpdateWorkflow(model){
    return this._httputilityService.Add("UpdateWorkflow",model);
  }

  getProfiles() {
    return this._httputilityService.Add("GetProfiles", {});
  }

  getAllWorkflows() {
    return this._httputilityService.Get("AllWorkflows");
  }

  DeleteWFSetup(setupid){
    return this._httputilityService.Add("DeleteWFSetup?SID="+setupid,'');
  }

  // getAllWorkDefinitions() {
  //   return this._httputilityService.Get("GetWorkflowDefinitions");
  // }

  createWorkflowSetup(data) {
    return this._httputilityService.Add("SaveWFSetup",data);
  }

  // getWorkflowsByStatus(status) {
  //   return this._httputilityService.GetWF('DMSWorkflow/GetWorkflowsByStatus?status=' + status);
  // }

  getwfProfile(model){
    return this._httputilityService.Add("GetProfiles",model);
  }

  GetProfileNames(){
    return this._httputilityService.Get("GetProfileNames");
  }

  GetProfileDetailsByID(profileId){
    return this._httputilityService.Get("GetProfileDetailsByID?profileID="+profileId);
  }

  GetUsersByRole(roleGuid){
    return this._httputilityService.GetU("user/GetUsersByRole?RoleGUID="+roleGuid);
  }

}
