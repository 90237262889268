<div class="p-grid">
  <div class="p-col-12">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-2"></div>
      <div class="p-field p-col-12 p-md-8">
        <h4>Create Profile</h4>
        <hr />
        <div class="p-field p-col-12">
          <label for="profileName">Profile Name</label>
          <input id="profileName" name="profileName" type="text" [(ngModel)]="profileForm.profileName" pInputText
            required name="Name" />
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label for="documentType">Document Type<span style="color: red">*</span></label>
          <p-dropdown inputId="documentType" [options]="docsTypesData" [(ngModel)]="selectedDocType"
            placeholder="Select" optionLabel="documentType" required="true" name="docName"
            (onChange)="getWorkFlowsList(selectedDocType)" requiredMessage="The input must not be empty">
          </p-dropdown>
          <small class="p-invalid">{{ docTypeError }}</small>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <label for="departments">Departments</label>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div class="card" style="overflow-y: auto; height: 135px">
            <div class="p-field-checkbox" *ngFor="let a of departments" class="p-col-12" style="height: 30px">
              <p-checkbox name="group2" value="Admin" id="ny" (click)="getRolesByDepartmentGuid(a.departmentGuid)">
              </p-checkbox>
              <label for="ny" style="padding-left: 10px">{{
                a.departmentName
                }}</label>
            </div>
          </div>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <div class="card">
            <div class="p-grid p-fluid" style="overflow-x: auto;">
              <p-table #dt [value]="roles" sortMode="multiple" class="table-align" [filterDelay]="0"
                [globalFilterFields]="['department', 'role', 'noOfApprovals']">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="code">
                      Slect
                      <!-- <p-checkbox value="rolesData"></p-checkbox> -->
                    </th>
                    <th pSortableColumn="department" style="width: 200px;">
                      Department
                      <p-sortIcon field="department"></p-sortIcon>
                    </th>
                    <th pSortableColumn="roleName" style="width: 200px;">
                      Role
                      <p-sortIcon field="roleName"></p-sortIcon>
                    </th>
                    <th pSortableColumn="shortDescription" style="width: 200px;">
                      No of Approvals
                      <p-sortIcon field="shortDescription"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-roles>
                  <tr>
                    <td style="text-align: center">
                      <p-checkbox value="roles" (click)="selectedCheckBox(classesData)"></p-checkbox>
                    </td>
                    <td></td>
                    <td>{{ roles.roleName }}</td>
                    <td>
                      <input id="noOfApprovals" name="noOfApprovals" type="text" [(ngModel)]="profileForm.noOfApprovals"
                        pInputText required name="Name" />
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            
            </div>
          </div>
        </div>
        
      </div>
      <div class="p-field p-col-12 p-md-2"></div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6"></div>        
      <div class="p-field p-col-12 p-md-2" style="float: right;">
        <button pButton pRipple type="button" (click)="createProfile()" icon="pi pi-check" iconPos="right"
          label="Submit" class="p-button-raised p-button-success p-mr-2 p-mb-2" style="float:right; width: 100%;"></button>
      </div>
      <div class="p-field p-col-12 p-md-2" style="float: right;">
        <button pButton pRipple type="button" icon="pi pi-replay" iconPos="right" label="Reset"
          class="p-button-raised p-button-danger p-mr-2 p-mb-2" style="float:right; width: 100%;"></button>
      </div>
      <div class="p-field p-col-12 p-md-2"></div>   
    </div>

  </div>
</div>
<div
  style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;"
  *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>

<!-- <div class="p-grid">
    <div class="p-col-12">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-12 p-md-2"></div>
        <div class="p-col-12 p-md-8">
            <h4>Create Profile</h4>
            <hr />
            <div class="p-field p-col-12">
                <label for="profileName">Profile Name</label>
                <input
                  id="profileName"
                  name="profileName"
                  type="text"
                  [(ngModel)]="profileForm.profileName"
                  pInputText
                  required
                  name="Name"
                />
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="documentType">Document Type<span style="color: red">*</span></label>
                <p-dropdown
                  inputId="documentType"
                  [options]="docsTypesData"
                  [(ngModel)]="selectedDocType"
                  placeholder="Select"
                  optionLabel="documentType"
                  required="true"
                  name="docName"
                  (onChange)="getWorkFlowsList(selectedDocType)"
                  requiredMessage="The input must not be empty"
                >
                </p-dropdown>
                <small class="p-invalid">{{ docTypeError }}</small>
              </div>
              
              <div class="p-field p-col-12 p-md-6">
                <label for="state"
                  >Department<span style="color: red">*</span></label
                >
                <p-dropdown
                  inputId="departmentType"
                  [options]="departments"
                  [(ngModel)]="selectedDepartment"
                  placeholder="Select"
                  optionLabel="departmentName"
                  required="true"
                  (onChange)="getRolesByDepartmentGuid()"
                  requiredMessage="The input must not be empty"
                  name="deparment"
                >
                </p-dropdown>


              <div class="p-field p-col-12 p-md-6">
                <label for="departments">Departments</label>
                <div 
                class="p-col-12" 
                style="border: 1px solid #cad3d6;height: 92px; padding-top: 15px; border-radius: 5px;">
                  <div class="p-field-checkbox">
                    <p-checkbox
                      name="group2"
                      value="Admin"
                      id="ny"
                      (click)="selectedDepartment(1)"
                    ></p-checkbox>
                    <label for="ny">Administrator</label>
                  </div>
                  <div class="p-field-checkbox">
                    <p-checkbox
                      (click)="selectedDepartment(2)"
                      name="group1"
                      value="QA"
                      id="sf"
                    ></p-checkbox>
                    <label for="sf">Qulaity Engineer</label>
                  </div>
                </div>
                <p-dialog
                  header="DepartmentRoles"
                  
                  modal="modal"
                  showEffect="fade"
                  [style]="{ width: '80%' }"
                >
                  <hr />
                  <br />
                  <div class="p-col-12">
                    <div class="p-grid p-fluid">
                      <p-table
                        #dt
                        [value]="rolesData"
                        sortMode="multiple"
                        class="table-align"
                        [filterDelay]="0"
                        [globalFilterFields]="[
                          'department',
                          'role',
                          'noOfApprovals'
                        ]"
                      >
                        <ng-template pTemplate="header">
                          <tr>
                            <th pSortableColumn="code">
                                <p-checkbox value="rolesData"></p-checkbox>
                            </th>
                            <th pSortableColumn="department">
                                Department
                                <p-sortIcon field="department"></p-sortIcon>
                            </th>
                            <th pSortableColumn="roleName">
                                Role
                                <p-sortIcon field="roleName"></p-sortIcon>
                            </th>
                            <th pSortableColumn="shortDescription">
                                No of Approvals
                              <p-sortIcon field="shortDescription"></p-sortIcon>
                            </th>
                            <th pSortableColumn="description">
                              Description
                              <p-sortIcon field="description"></p-sortIcon>
                            </th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-classesData>
                          <tr>
                            <td style="text-align: center">
                              <p-checkbox
                                value="rolesData"
                                (click)="selectedCheckBox(classesData)"
                              ></p-checkbox>
                            </td>
                            <td>{{ rolesData.department }}</td>
                            <td>{{ rolesData.rolsName }}</td>
                            <td>{{ rolesData.noOfApprovals }}</td>
                          </tr>
                        </ng-template>
                      </p-table>
                      <div class="p-col-12 p-md-8"></div>
                    </div>
                  </div>
                </p-dialog>

              </div>
        </div>
    </div>
  </div>
</div> -->