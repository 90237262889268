import { Component, Inject, OnInit,ViewChild } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Table } from "primeng/table";
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as _ from "lodash";

@Component({
  selector: 'app-delegation',
  templateUrl: './delegation.component.html',
  styleUrls: ['./delegation.component.scss']
})
export class DelegationComponent implements OnInit {
  filteredDept: any[];
  filteredToDept: any[];
  filteredRoles: any[];
  filteredToRoles: any[];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,
  ) {
    this.form = this.fb.group({
      name: [""],
      avatar: null,
    });
  }
  userId = parseInt(sessionStorage.getItem("userId"));
  userRoles = JSON.parse(sessionStorage.getItem("roles"));
  userDepartments = JSON.parse(sessionStorage.getItem("userdepts"));
  userGuid = sessionStorage.getItem('userGuid');
  apiResponse: any;
  responseError: any;
  cols: any[];
  form: FormGroup;
  delegationModel: any;
  departmentsData: any;
  TodepartmentsData: any;
  fromRoleData: any;
  ToRolesData: any;
  fromUserData: any;  
  ToUsersData: any;
  selectedDept: any;
  selectedUser: any;
  fromDate: any;
  RolesData: any;
  ToselectedDept: any;
  selectedRole: any;
  UsersData: any;
  ToselectedRole: any;
  ToselectedUser: any;

  fromDepartmentError: any;  
  fromRoleError: any;
  fromUserError: any;
  fromDateError: any;
  ErrorDisplayMessage:any;
  TodepartmentError: any;
  ToRoleError: any;
  ToUserError: any;
  commentsError: any;
  ErrorDisplay:boolean;
  comments: any;
  delegateData: any;
  showLoading:boolean;
  userFeature:boolean;
  @ViewChild("dt") table: Table;
  // virtualCars: DashboardTableModel[];
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DELEGATE_BY_USER");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    this.getDepartments("PageLoad");
    this.getRoles();
    this.getUsers();
    this.to_getDepartments("PageLoad");
    this.to_getRoles();
    this.GetAllDelegations();
    this.fromDate = new Date();

    this.cols = [
      { field: 'department', header: 'Department',dynamicPlaceHolder: 'Search by department' },
      { field: 'role', header: 'Role' ,dynamicPlaceHolder:'Search by department' },
      { field: 'user', header: 'User',dynamicPlaceHolder:'Search by department' },
      { field: 'toDepartment', header: 'To Department' ,dynamicPlaceHolder:'Search by toDepartment' },
      { field: 'toRole', header: 'To Role', dynamicPlaceHolder:'Search by toRole' },
      { field: 'toUser', header: 'To User' ,dynamicPlaceHolder:'Search by toUser' },
      { field: 'fromDate', header: 'From Date' ,dynamicPlaceHolder:'Search by YYYY-MM-DD' },
      { field: 'toDate', header: 'To Date',dynamicPlaceHolder: 'Search by YYYY-MM-DD' },
    ];
  }

  GetAllDelegations() {
    this.showLoading = true;
    this._facadeService.GetAllDelegations().subscribe(
      (data) => {
        this.showLoading = false;
        this.delegateData = data;
        this.delegateData = this.delegateData.returnObject;
        this.delegateData = _.sortBy(this.delegateData, "createdDate");
        console.log(this.delegateData);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getDepartments(from) {
    this.showLoading = true;
    this.departmentsData=[];
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.showLoading = false;
        console.log(this.departmentsData);
        if(this.userFeature){
          if(data.returnObject!=null && data.returnObject.length>0){
            data.returnObject.forEach(element => {
              var dept = this.userDepartments.find(m=>m.id==element.departmentGuid);
              if(dept!=null){
                this.departmentsData.push(element);
              }
            });
          }
          if(from="PageLoad"){
            this.selectedDept=this.departmentsData[0];
            this.getRoleList("PageLoad");
          }
        }
        else{
          this.departmentsData=data.returnObject;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterDepartments(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.departmentsData.length; i++) {
        let dept = this.departmentsData[i];
        if (dept.departmentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(dept);
        }
    }    
    this.filteredDept = filtered;
  }

  to_getDepartments(from) {
    this.showLoading = true;
    this._facadeService.getDepartments().subscribe(
      (data) => {
        this.showLoading = false;
        this.TodepartmentsData = data;
        this.TodepartmentsData = this.TodepartmentsData.returnObject;
        console.log(this.TodepartmentsData);
        if(this.userFeature){
          if(from=="PageLoad"){
            this.ToselectedDept=this.departmentsData[0];
            this.to_getRoleList("PageLoad");
          }
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterToDepartments(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.TodepartmentsData.length; i++) {
        let dept = this.TodepartmentsData[i];
        if (dept.departmentName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(dept);
        }
    }    
    this.filteredToDept = filtered;
  }

  getRoles() {
    this.fromRoleData =[];
    this.showLoading = true;
    this._facadeService.getRolesData().subscribe(
      (data) => {
        this.showLoading = false;
        if(this.userFeature){
          if(data.returnObject!=null&&data.returnObject.length>0){
            data.returnObject.forEach(element => {
            var role = this.userRoles.find(m=>m.id==element.roleGuid);
            if(role!=null){
              this.fromRoleData.push(element);
            }
            });
          }
        }
        else{
          this.fromRoleData = data.returnObject;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterRoles(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i <  this.RolesData.length; i++) {
        let roles =  this.RolesData[i];
        if (roles.roleName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(roles);
        }
    }    
    this.filteredRoles = filtered;
  }

  to_getRoles() {
    this.showLoading = true;
    this._facadeService.getRolesData().subscribe(
      (data) => {
        this.showLoading = false;
        this.ToRolesData = data;
        this.ToRolesData = this.ToRolesData.returnObject;
        console.log(this.ToRolesData);
        if(this.userFeature){
          this.selectToUserRoles();
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterToRoles(event) {
    let filtered : any[] = [];
    let query = event.query;
    for(let i = 0; i < this.ToRolesData.length; i++) {
        let roles = this.ToRolesData[i];
        if (roles.roleName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(roles);
        }
    }    
    this.filteredToRoles = filtered;
  }

  selectToUserRoles(){
    if (this.userRoles != undefined && this.ToRolesData != undefined) {
    let roleGuid = this.userRoles[0].id;
    this.ToselectedRole = this.ToRolesData.filter(ele => {
        return ele.roleGuid == roleGuid;
    })[0];
    this.to_getUserList();
    }
  }

  getUsers() {
    this.showLoading = true;
    this.fromUserData=[];
    this._facadeService.getUsers().subscribe(
      (data) => {
        this.showLoading = false;
        
        if(data.returnObject!=null && data.returnObject.length>0){
          let users=[];
          if(this.userFeature){
            //this.selectUser();
            data.returnObject.forEach((value, key) => {
              if(value.userGuid==this.userGuid){
                if(value.firstName!=null && value.firstName!="" && value.firstName!=undefined
              && value.lastName !=null && value.lastName!="" && value.lastName!=undefined){
                value.firstName = value.firstName + " " + value.lastName;
              }
              users.push(value);
              }
            });
          }
          else{
            data.returnObject.forEach((value, key) => {
                if(value.firstName!=null && value.firstName!="" && value.firstName!=undefined
              && value.lastName !=null && value.lastName!="" && value.lastName!=undefined){
                value.firstName = value.firstName + " " + value.lastName;
              }
              users.push(value);
            });
          }
          this.fromUserData=users;
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  to_getUsers() {
    this.showLoading = true;
    this._facadeService.getUsers().subscribe(
      (data) => {
        this.showLoading = false;
        this.ToUsersData = data;
        this.ToUsersData = this.ToUsersData.returnObject;

        //BEGIN - Temporary  solution :: Display user firstName + ' ' + lastName
        if(this.ToUsersData.length>0){
          this.ToUsersData.forEach((value, key) => {
            if(value.firstName!=null && value.firstName!="" && value.firstName!=undefined
            && value.lastName !=null && value.lastName!="" && value.lastName!=undefined){
              value.firstName = value.firstName + " " + value.lastName;
            }
          });
        } 
        //console.log("First Name + Last name :: " , JSON.stringify(this.ToUsersData));
        //END - Temporary  solution :: Display user firstName + ' ' + lastName

        if(this.userFeature){
          this.selectToUser();
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  selectToUser(){
      if (this.userId != undefined && this.ToUsersData != undefined) {
      this.ToselectedUser = this.ToUsersData.filter(ele => {
          return ele.userId == this.userId;
      })[0];
    }
    else if (this.userId != undefined && this.ToUsersData != undefined) {
      this.ToselectedUser = this.ToUsersData.filter(ele => {
        return ele.userId == this.userId;
    })[0];
    }
  }

  getRoleList(from) {
    this.showLoading = true;
    this.RolesData=[];
    this.selectedRole="";
    this.selectedRole="";
    this.selectedUser="";
    console.log(this.selectedDept);
    this._facadeService
      .getRolesByDepartmentGuid(this.selectedDept.departmentGuid)
      .subscribe((result: any) => {
        this.showLoading = false;
        if(this.userFeature){
          if(result.returnObject!=null&&result.returnObject.length>0){
            result.returnObject.forEach(element => {
            var role = this.userRoles.find(m=>m.id==element.roleGuid);
            if(role!=null){
              this.RolesData.push(element);
            }
            });
            if(from=="PageLoad"){
              this.selectedRole = this.RolesData[0];
              this.getUserList("PageLoad");
            }
          }
        }
        else{
          this.RolesData = result.returnObject;
        }
        console.log("from_Roles ::" + JSON.stringify( this.RolesData));
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
  }

  to_getRoleList(from) {
    this.showLoading = true;
    this.ToselectedRole="";
    this.ToselectedUser="";
    console.log(this.ToselectedDept);
    this._facadeService
      .getRolesByDepartmentGuid(this.ToselectedDept.departmentGuid)
      .subscribe((result: any) => {
        this.showLoading = false;
        this.ToRolesData = result.returnObject;
        console.log("to_Roles ::" + JSON.stringify( this.ToRolesData));
        if(this.userFeature){
        if(from=="PageLoad"){
          this.ToselectedRole=this.RolesData[0];
        }
      }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
  }

  getUserList(from) {
    this.showLoading = true;
    this.UsersData=[];
    this._facadeService
      .GetUsersByRoleGuid(this.selectedRole.roleGuid)
      .subscribe((result: any) => {
        this.showLoading = false;
        if(this.userFeature){
          let users=[];
          if(result.returnObject!=null && result.returnObject.length>0){
            result.returnObject.forEach(element => {
              if(element.userGuid==this.userGuid){
                if(element.firstName!=null && element.firstName!="" && element.firstName!=undefined
                && element.lastName !=null && element.lastName!="" && element.lastName!=undefined){
                  element.firstName = element.firstName + " " + element.lastName;
                }
                users.push(element);
              }
            });
            this.UsersData=users;
          } 
          if(from=="PageLoad"){
            this.selectedUser=this.UsersData[0];
          }
        }
        else{
          let users=[];
          if(result.returnObject!=null && result.returnObject.length>0){
            result.returnObject.forEach(element => {
                if(element.firstName!=null && element.firstName!="" && element.firstName!=undefined
                && element.lastName !=null && element.lastName!="" && element.lastName!=undefined){
                  element.firstName = element.firstName + " " + element.lastName;
                }
                users.push(element);
            });
            this.UsersData=users;
          } 
        }
        console.log("from_Users ::" + JSON.stringify( this.UsersData));
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
  }

  to_getUserList() {
    this.showLoading = true;
    this.ToselectedUser="";
    this._facadeService
      .GetUsersByRoleGuid(this.ToselectedRole.roleGuid)
      .subscribe((result: any) => {
        this.showLoading = false;
        this.ToUsersData = result.returnObject;
        console.log("to_Users ::" + JSON.stringify( this.ToUsersData));

        //BEGIN - Temporary  solution :: Display user firstName + ' ' + lastName
        if(this.ToUsersData.length>0){
          this.ToUsersData.forEach((value, key) => {
            if(value.firstName!=null && value.firstName!="" && value.firstName!=undefined
            && value.lastName !=null && value.lastName!="" && value.lastName!=undefined){
              value.firstName = value.firstName + " " + value.lastName;
            }
          });
        } 
        //console.log("First Name + Last name :: " , JSON.stringify(this.ToUsersData));
        //END - Temporary  solution :: Display user firstName + ' ' + lastName
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
      );
      if(this.userFeature){
        this.selectToUser();
      }
  }

  addDelegation() {
    this.fromDepartmentError = '';  
    this.fromRoleError = '';
    this.fromUserError = '';
    this.fromDateError = '';

    this.TodepartmentError = '';
    this.ToRoleError= '';
    this.ToUserError= '';
    this.commentsError= '';

    
    if (this.selectedDept == undefined || this.selectedDept == '') {
      this.fromDepartmentError = 'Please select from department.';
    }
    if (this.selectedRole == undefined || this.selectedRole == '') {
      this.fromRoleError = 'Please select from role.';
    }
    if (this.selectedUser == undefined || this.selectedUser == '') {
      this.fromUserError = 'Please select from user.';
    }
    if (this.fromDate == undefined || this.fromDate == '') {
      this.fromDateError = 'Please select a date.';
    }

    if (this.ToselectedDept == undefined || this.ToselectedDept == '') {
      this.TodepartmentError = 'Please select to department.';
    }
    if (this.ToselectedRole == undefined || this.ToselectedRole == '') {
      this.ToRoleError = 'Please select to role.';
    }
    if (this.ToselectedUser == undefined || this.ToselectedUser == '') {
      this.ToUserError = 'Please select to user.';
    }
    // if (this.comments == undefined || this.comments == '') {
    //   this.commentsError = 'Please select a date.';
    // }
    if (
      this.selectedDept != undefined &&
      this.selectedDept != '' &&
      this.selectedRole != undefined &&
      this.selectedRole != '' &&
      this.selectedUser != undefined &&
      this.selectedUser != '' &&
      this.fromDate != undefined &&
      this.fromDate != '' &&

      this.ToselectedDept != undefined &&
      this.ToselectedDept != '' &&
      this.ToselectedRole != undefined &&
      this.ToselectedRole != '' &&
      this.ToselectedUser != undefined &&
      this.ToselectedUser != ''
    ) {
      this.delegationModel = {
        delegationId: 0,
        departmentId: this.selectedDept.departmentId,
        department: "",
        // this.selectedDept.departmentName,
        roleId: this.selectedRole.roleId,
        role: "",
        // this.selectedRole.roleName,
        userId: this.selectedUser.userId,
        user: "",
        // this.selectedUser.firstName,
        toDepartmentId: this.ToselectedDept.departmentId,
        toDepartment: "",
        // this.ToselectedDept.departmentName,
        toRoleId: this.ToselectedRole.roleId,
        toRole: "",
        // this.ToselectedRole.roleName,
        toUserId: this.ToselectedUser.userId,
        toUser: "",
        // this.ToselectedUser.firstName,
        fromDate: new Date(),
        // fromDate: this.datePipe.transform(this.fromDate, "MM/dd/yyyy"),
        toDate: new Date(),
        comments: this.comments,
        active: 'Y',
        createdBy: this.userId,
      };
     console.log("Input Delegation Data ::" + JSON.stringify(this.delegationModel));
     this.showLoading = true;
     this._facadeService.AddDelegation(this.delegationModel)
        .subscribe(
          (data) => {
            this.showLoading = false;
            console.log(data);
            this.apiResponse = data;
            if(this.apiResponse.returnCode >= 0){
              this.router.navigate(["/userdelegations"]);
            }
            else{
              this.responseError = this.apiResponse.returnMessage;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
    }
  } 
  exportExcel() {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.delegateData);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "delegateData");
    });
}

saveAsExcelFile(buffer: any, fileName: string): void {
  this.showLoading = true;
  import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  });
  this.showLoading = false;
}


  print() {
    window.print();
  }
     
  resetCtrls()
  {
    this.fromDepartmentError = '';  
    this.fromRoleError = '';
    this.fromUserError = '';
    this.fromDateError = '';
    this.TodepartmentError = '';
    this.ToRoleError= '';
    this.ToUserError= '';
    this.commentsError= '';
    this.selectedDept='',
    this.selectedRole='',
    this.selectedUser='',
    this.selectedUser='',
    this.fromDate='',
    this.ToselectedDept='',
    this.ToselectedRole='',
    this.ToselectedUser='',
    this.comments=''
  }
  ChangeDepartment(){
   if(this.ToselectedUser.userGuid==this.selectedUser.userGuid){
    this.ErrorDisplayMessage="From user and to user should not be same";
      this.ErrorDisplay=true;
     this.ToselectedUser="";
   }
  }
  ChangeDepartmentFrom(){
    if(this.ToselectedUser.userGuid==this.selectedUser.userGuid){
      this.ErrorDisplayMessage="From user and to user should not be same";
      this.ErrorDisplay=true;
      this.selectedUser="";
    }
  }
}

