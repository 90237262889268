import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { approvalNeededModel } from 'src/app/models/approvalneeded_model';
import * as _ from "lodash";
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";

@Component({
  selector: 'app-publishedneeded',
  templateUrl: './publishedneeded.component.html',
  styleUrls: ['./publishedneeded.component.scss']
})
export class PublishedneededComponent implements OnInit {
  stateMode: string;
  constructor(
    private _facadeService: FacadeService,public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router,    
    private route:ActivatedRoute,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private cdr: ChangeDetectorRef
  ) {}
  userId = sessionStorage.getItem('userId')!=null?parseInt(sessionStorage.getItem('userId')):0;
  userGuid = sessionStorage.getItem('userGuid');
  myApprovalModel: any;
  today: string;
  docsData: approvalNeededModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading:boolean;
  DelegationExist:boolean;
  AreYouSureText:any;
  deleteDisplay:boolean;
  delegationId:any;
  source:any;
  status:any;
  index:any;
  selectedStatus:any;
  documentsName:any;
  DocumentsCount =0;
  userFeature:boolean;
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DSBL_PUBLISH");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    sessionStorage.ecrn = "";
      sessionStorage.code ="";
      sessionStorage.revision = "";
      sessionStorage.eco = "";
      sessionStorage.title ="";
      sessionStorage.departmentName = "";
      sessionStorage.owner = "";
      sessionStorage.status = "";
    this.source = this.route.snapshot.paramMap.get('status');
    this.stateMode = this.route.snapshot.paramMap.get('StateMode');
    console.log('this.source-----', this.source);  
this.status = [ 
          {label: 'Pending Publication', value: {id: 1, name: 'In-Process', code: 'In-Process'}},
          {label: 'Published', value: {id: 2, name: 'Approved', code: 'Approved'}}
      ];
      if(this.source==="Approved"){
        this.selectedStatus = this.status[1].value;
      }
      else{
        this.selectedStatus = this.status[0].value;
      }
      this.statusChangeEvent(this.selectedStatus);
        this.approvalNeededData();
        
        this.cols = [
          { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Document id' },
          { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by title' },
          { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' }, 
          { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev'},
          { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'Search by ECO'},        
          { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Search by Originator'},         
          { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by status' },
        ];
  } 
  statusChangeEvent(selectedStatus) {
    if (selectedStatus.code == 'In-Process') {
      this.documentsName = 'Pending Publication';
    }    
    else if (selectedStatus.code == 'Approved') {
      this.documentsName = 'Published';
    }
    this.source = selectedStatus.code;
    this.approvalNeededData();
  }

  approvalNeededData() {
    var today = new Date();
    this.today = this.datePipe.transform(this.today, 'MM/dd/yyyy');
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null){
          userFeature = true;
        }
      }
    }
    this.myApprovalModel = {
      documentId: 0,
      departmentId: 0,
      isArchived: 'N',
      documentTypeId: 0,
      documentStatus: this.source,
      todayDate: this.today,
      userID: this.userId,
      userGuid: this.userGuid,
      accessUserID:userFeature?0:parseInt(sessionStorage.getItem('userId'))
    };
    console.log("Search model2::" + JSON.stringify(this.myApprovalModel))
    this.showLoading = true;
    this._facadeService.approvalNeededData(this.myApprovalModel).subscribe(
      (data) => {
        if(data.returnObject.length>0){
          data.returnObject.forEach((value, key) => {
            if(value.eco!=null &&value.eco!="" && value.eco!=undefined){
              value.eco= value.eco;
            }
            else{
              value.eco= "N/A";
            }
          });
        }
        this.docsData = data.returnObject;
        console.log(this.docsData);
        this.CountData();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx : 90 },{ wpx :  550},{ wpx : 150 },{ wpx : 80 },{ wpx : 90 },{ wpx : 200 },{ wpx : 90 }];
    /* save to file */
    if(this.documentsName == "Published")
    XLSX.writeFile(wb, "Published Documents.xlsx");
    else
    XLSX.writeFile(wb, "Documents Pending for Publication.xlsx");
  }
//   exportExcel() {
//      /* table id is passed over here */   
//      let element = document.getElementById('excel-table'); 
//      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//      /* generate workbook and add the worksheet */
//      const wb: XLSX.WorkBook = XLSX.utils.book_new();
//      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//      this.delete_row(wb.Sheets.Sheet1, 1);
//      ws['!cols'] = [{ wpx : 90 },{ wpx :  550},{ wpx : 150 },{ wpx : 80 },{ wpx : 90 },{ wpx : 200 },{ wpx : 90 }];
//      /* save to file */
//      if(this.documentsName == "Published")
//      XLSX.writeFile(wb, "Published Documents.xlsx");
//      else
//      XLSX.writeFile(wb, "Documents Pending for Publication.xlsx");
// }
print() {
  
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
  if(this.documentsName == "Published")
  this.printService.Print(Content,"Published Documents",'trSearch',true);
  else
  this.printService.Print(Content,"Documents Pending for Publication",'trSearch',true);
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['H1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

  viewDocDetails(selectedDocId) {
    sessionStorage.docId = selectedDocId;
     sessionStorage.isFrom= "publishedNeeded";
    this.router.navigate(['/docdetails',this.documentsName]);
  }
  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
        const activity = parseInt(value);

        if (!isNaN(activity)) {
            this.table.filter(activity, 'activity', 'gte');
        }
    }
}
test(val,field,dt)
{
  if(field == "ecrn")
    sessionStorage.ecrn = val;
  else if(field == "code")
    sessionStorage.code = val;
  else if(field == "revision")
    sessionStorage.revision = val;
  else if(field == "title")
    sessionStorage.title = val;
  else if(field == "departmentName")
    sessionStorage.departmentName = val;
  else if(field == "eco")
    sessionStorage.eco = val;
  else if(field == "owner")
    sessionStorage.owner = val;
  else if(field == "status")
    sessionStorage.status = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.docsData != undefined && this.docsData != null)
  {
    var temp = this.docsData;
    if(sessionStorage.ecrn != ""&&sessionStorage.ecrn != null)
      temp = temp.filter(x=> x.ecrn!=null&&x.ecrn!=""&&x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
    if(sessionStorage.code != "")
      //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != "")
      temp = temp.filter(x=> x.revision.toString().includes(sessionStorage.revision.toString()));
      if(sessionStorage.eco != "")
      temp = temp.filter(x=> x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
    if(sessionStorage.departmentName != "")
      temp = temp.filter(x=> x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
    if(sessionStorage.owner != "")
      temp = temp.filter(x=> x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
    if(sessionStorage.status != "")
      temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
