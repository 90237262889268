<div class="card">
<div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto;">
  <h5 style="color:#495057; padding:15px 0 0 10px;width: 90%;">{{headerName}}</h5> 
    <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:18px;">  
      <button *ngIf="source=='A'" pButton pRipple type="button" icon="pi pi-plus" iconPos="left" label="Add New DCC Folder"
      (click)="AddNewButton()" class="p-button-raised p-button-primary" style="float:right;width:auto;"
      ></button>&nbsp;&nbsp;&nbsp;&nbsp;
</span>
</div>
            <div class="container">
                <div class="left-container">
            <p-tree [value]="files" [filter]="true" filterPlaceholder="Search" scrollHeight="480px"> 
                <ng-template let-node pTemplate="urlplus" *ngIf="source=='A'">
                    <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;" class="p-button-raised p-button-primary" pTooltip="Edit" (click)="EditFolder(node)"></button>&nbsp;&nbsp;
                    <button pButton type="button" icon="pi pi-plus" pTooltip="Create Level 1 Folder" class="p-button-raised p-button-primary" (click)="CreateSubFolder(node)"
                    style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                    <button pButton type="button" (click)="ViewRoles(node)" icon="pi pi-eye" pTooltip="View Roles" class="p-button-rasised p-button-primary"
                      style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                  <span (click)="handleTemplateClick(node)" style="cursor: pointer;">
                    {{node.label}} &nbsp;&nbsp;
                  </span>
                </ng-template>
                <ng-template let-node pTemplate="urlplus" *ngIf="source !== 'A'">
                    <div (click)="handleTemplateClick(node)" style="cursor: pointer;">
                      {{ node.label }}
                    </div>
                  </ng-template>
                <ng-template let-node pTemplate="subfol" *ngIf="source=='A'">
                  <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;" class="p-button-raised p-button-primary" pTooltip="Edit" (click)="EditFolder(node)"></button>&nbsp;&nbsp;
                  <button pButton type="button" icon="pi pi-plus" pTooltip="Create Level 1 Folder" class="p-button-raised p-button-primary" (click)="CreateSubSubFolder(node)"
                  style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                  <button pButton type="button" (click)="ViewRoles(node)" icon="pi pi-eye" pTooltip="View Roles" class="p-button-rasised p-button-primary"
                  style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                  <span (click)="handleTemplateClick(node)" style="cursor: pointer;">
                  {{node.label}}
                  </span>
                </ng-template>
                <ng-template let-node pTemplate="subfol" *ngIf="source!='A'">
                  <div (click)="handleTemplateClick(node)" style="cursor: pointer;">
                  {{node.label}} &nbsp;&nbsp;
                  </div>
                </ng-template>
                <ng-template let-node pTemplate="subfol1" *ngIf="source=='A'">
                  <button pButton pRipple icon="pi pi-pencil" style="height:20px; width:20px;" class="p-button-raised p-button-primary" pTooltip="Edit" (click)="EditFolder(node)"></button>&nbsp;&nbsp;
                        <button pButton type="button" (click)="ViewRoles(node)" icon="pi pi-eye" pTooltip="View Roles" class="p-button-rasised p-button-primary"
                  style="height:20px; width:20px;"></button>&nbsp;&nbsp;
                  <span (click)="handleTemplateClick(node)" style="cursor: pointer;">
                  {{node.label}}
                        
                  </span>
                </ng-template>
                <ng-template let-node pTemplate="subfol1" *ngIf="source!='A'">
                  <div (click)="handleTemplateClick(node)" style="cursor: pointer;">
                  {{node.label}} &nbsp;&nbsp;
                  </div>
                </ng-template>
            </p-tree>
        </div>
        <div class="right-container">
            <div class="card" style="height:100%;">
              <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:auto; display: flex; justify-content: space-between; align-items: center;">

                <h5 style="color:#495057; padding:15px 0 0 15px;">{{roleGroupNames}} Documents ({{DocumentsCount}})</h5>
               
              </div>
                <div class="invoice invoice-header" id="invoice-content" >
                  <p-table  (sortFunction)="customSort($event)" [customSort]="true"
                  #dt [value]="docsData" id="excel-table" [columns]="cols" class="table-align" 
                  [scrollable]="true" scrollHeight="480px" [virtualScroll]="true"
                   [filterDelay]="0"  dataKey="code" styleClass="p-datatable-striped"
                   [globalFilterFields]="[ 'code','title']">
                   <ng-template pTemplate="header" let-columns class="invoice-items">
                     <tr>
                       <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
                       [ngClass]="{'docid': i == 0,'title': i == 1}">  
                       {{ col.header }}<p-sortIcon [field]="col.field"></p-sortIcon>
                       </th>
                     </tr>
                     <tr id="trSearch">
                       <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                         <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" (input)="test($event.target.value,col.field,dt)" style="margin: -10px 0 -10px 0;width: 90%; height:25px;" />
                       </th>
                     </tr>
                   </ng-template>
                   <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
                     <tr [pSelectableRow]="rowData">
                       <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                        <td *ngIf="i == 0" style="text-align: center; width: 16%;">
                          <span><a href="javascript:;" (click)="DMSToken(rowData)" ptooltip="Click to View">{{rowData[col.field] }}</a> </span>
                      </td>
                      <td *ngIf="i == 1" style="text-align: left;padding-left:40px; width:64%;">
                          <a href="javascript:;" (click)="GetFile(rowData)"
                          ptooltip="Click to Download">{{
                              rowData[col.field] }}</a>
                      </td>
                       </ng-container>
                     </tr>
                   </ng-template>
         
                   <ng-template pTemplate="emptymessage">
                     <tr>
                       <td colspan="2" style="text-align: left;">No documents found.</td>
                     </tr>
                   </ng-template>
                 </p-table>
                  </div>
            </div>
        </div>
        </div>
           
            <div style="
                position: fixed;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: #000000;
                opacity: 0.5;
                z-index: 5500;
              " *ngIf="showLoading">
              <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
            </div>
            
            <p-dialog header="{{textlabelheader}}" [(visible)]="addDisplay" modal="modal" showEffect="fade"
                            [style]="{ width: '50%' }">
                            <hr />
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-2"></div>
                                <div class="p-field p-col-12 p-md-8">
                                    <label>Name<span style="color: red">*</span></label>
                                    <input [(ngModel)]="folderName" type="text" pInputText placeholder="Enter Name"/>
                                    <small class="p-invalid">{{ nameError }}</small>
                                    <br /><br />
                                    <label>Description</label>
                                    <textarea [(ngModel)]="description" type="text" rows="3" [maxLength]="300" pInputTextarea placeholder="Enter Description"></textarea>
                                    <br /><br />      
                                    <label>Active
                                    <p-checkbox disabled={{isdisabled}} [(ngModel)]="active" binary="active">
                                    </p-checkbox> </label>     
                                    <br /><br />                     
                                </div>
                                <div class="p-field p-col-12 p-md-2"></div>
                            </div>
                            <div class="p-fluid p-formgrid p-grid">
                                <div class="p-field p-col-12 p-md-8"></div>
                                <div class="p-field p-col-12 p-md-2">
                                    <br />
                                    <button pButton pRipple type="button" icon="pi pi-check" label="{{TextLabel}}"
                                     class="p-button-raised p-button-success p-mr-2 p-mb-2"
                                        style="float: right" (click)="CreateUpdateFolder()"></button>
                                </div>
                                <div class="p-field p-col-12 p-md-2"></div>
                                <small class="p-invalid" style="float: right">{{responseMessage}}</small>
                            </div>
                          </p-dialog>
                        </div>
                        <p-dialog header="Role Groups" [(visible)]="rolegroupsdisplay" modal="modal" showEffect="fade" >
                          <hr>
                          <div class="p-grid">
                              <div class="p-col-12 p-md-6 p-xl-12">
                                  <div class="card no-gutter widget-overview-box widget-overview-box-1" style="min-height:auto;min-width: 250px;">
                                      <ul class="widget-image-list" *ngIf="mulgrps.length>0">
                                      <li *ngFor="let k of mulgrps">
                                          <span>{{ k }}</span>
                                      </li>
                                      </ul>
                                      <ul class="widget-image-list" *ngIf="mulgrps.length==0">
                                        <span>no role groups found</span>
                                        </ul>
                                  </div>
                              </div>
                          </div>
                        </p-dialog>