import { Component, Inject, OnInit } from '@angular/core';
import { FacadeService} from '../../facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-obsoletedocument',
  templateUrl: './obsoletedocument.component.html',
  styleUrls: ['./obsoletedocument.component.scss']
})
export class ObsoletedocumentComponent implements OnInit {
  obsComments:any="";
  selectedECRN: any = "";
  ECRNDescription: any = "";
  ECRNReason: any = "";
  ECRNDate = new Date();
  NotproductRelated = false;
  productError: any = "";
  userId = parseInt(sessionStorage.getItem('userId'));
  UserGUID = sessionStorage.getItem('userGuid')
  docId = parseInt(sessionStorage.getItem('docId'));
  saveAndSendApprovalDisplay: boolean;
  AreYouSureText: string;
  apiResponse: any;
  responseError: any;
  docsDetailsData: any;
  workflowDefinitionId: string = "";
  docsTypesData: any = [];
  departmentsData: any = [];
  workFlowData: any;
  fileName: any = '';
  selectedFile: boolean;
  selectedProduct: any;
  filteredproduct: any;
  filesize: any;
  selectedDocType: any;
  selectedWorkflow: any;

  title: any;
  department: any = '';
  type: any = '';
  reviewDate: any = '';
  expirationDate: any = null;
  effectiveDate: any = '';
  Remarks: any = '';
  Description: any = '';

  workFlowError: any;
  todayDate: Date;
  filteredproductline: any;
  documentTypeId: any;
  revision: any;
  showLoading: boolean;
  minDate: Date;
  SelectedFileName: any;
  SelectedFileNameExist: boolean;
  doccode: any;
  alternatedDocId: any = "";
  ApprovedFile: any;
  productLineData = [];
  selectedProductLine: any;
  ECNLevelData = [];
  selectedECN: any;
  ShowECRN = false;
  productsData: any;
  productGuidURL: any;
  prodroles: boolean = false;
  prodrolesdisplay: boolean = false;
  prcols: any[];
  prodrolesdata: any[];

  ECRNDescriptionError = "";
  ECRNReasonError = "";
  userFeature:boolean;
  constructor(
    private _facadeService: FacadeService, private router: Router,private datePipe: DatePipe,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,) {
  }

  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="OBSOLETE_DOC");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    this.showLoading = true;
    this.ECNLevelData = [
      { name: 'Minor', value: 1, code: true },
      { name: 'Major', value: 2, code: false },
    ]
    this.selectedECN = this.ECNLevelData[1];
    this.prcols = [
      { field: 'profileDisplayName', header: 'Approval Level' },
      { field: 'profileName', header: 'Profile' },
      { field: 'department', header: 'Department' },
      { field: 'role', header: 'Role' }
    ];
    this.getDepartments();
    this.minDate = new Date();
  }
  getDocDetailsById() {
    this.showLoading = true;
    var userFeature = false;
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
        if(featureExist!=null)
          userFeature = true;
      }
    }
    var useId = userFeature?0:parseInt(sessionStorage.getItem('userId'));
    this._facadeService.getDocDetailsById(this.docId,useId).subscribe(
      (data) => {
        this.docsDetailsData = data.returnObject;
        this.title = data.returnObject.title;
        this.doccode = data.returnObject.code;
        this.alternatedDocId = data.returnObject.alternatedDocId;
        if (this.alternatedDocId === 'null')
          this.alternatedDocId = "";
        this.workflowDefinitionId = this.docsDetailsData.workflowDefinitionId;
        //this.title = "";
        //this.selectedECN = this.ECNLevelData.find(m => m.code == this.docsDetailsData.isMinor);
        this.Description = this.docsDetailsData.description;
        if (this.Description === "null") { this.Description = ''; }
        this.Remarks = this.docsDetailsData.remarks;
        if (this.Remarks === "null") { this.Remarks = ''; }
        
        if(this.docsDetailsData.effectiveDate !== null && this.docsDetailsData.effectiveDate !== "" && this.docsDetailsData.effectiveDate !== undefined)
          this.effectiveDate = new Date(this.docsDetailsData.effectiveDate);
        else
          this.effectiveDate = null;
        
        if(this.docsDetailsData.expirationDate !== null && this.docsDetailsData.expirationDate !== "" && this.docsDetailsData.expirationDate !== undefined)
          this.expirationDate = new Date(this.docsDetailsData.expirationDate);
        else
          this.expirationDate = null;

        if(this.docsDetailsData.reviewDate !== null && this.docsDetailsData.reviewDate !== 'null' && this.docsDetailsData.reviewDate !== undefined && this.docsDetailsData.reviewDate !== '')
          this.reviewDate = new Date(this.docsDetailsData.reviewDate);
        else
          this.reviewDate = null;

        this.effectiveDate = null;
        this.expirationDate = null;
        this.reviewDate = null;
          
        this.revision = this.docsDetailsData.revision;
        var dept = this.departmentsData.find(x => x.departmentId === this.docsDetailsData.departmentId)
        this.department = dept;
        var doctype = this.docsTypesData.find(x => x.documentTypeId === this.docsDetailsData.typeId)
        this.selectedDocType = doctype;
      
        if (this.selectedDocType.creationSource == "CMS")
        {
          this.ShowECRN = true;
          this.selectedECRN="";//this.docsDetailsData.ecrn;
          //this.ECRNDescription=this.docsDetailsData.changeDescription;
          //this.ECRNReason=this.docsDetailsData.changeReasons;
          this.ECRNDate=new Date();
        }
        else
          this.ShowECRN = false;
        if (this.docsDetailsData.userFileName != '' && this.docsDetailsData.userFileName != undefined) {
          this.SelectedFileNameExist = true;
          this.SelectedFileName = this.docsDetailsData.userFileName;
        }
        if (this.docsDetailsData.userFinalEditableFileName != '' && this.docsDetailsData.userFinalEditableFileName != undefined && this.docsDetailsData.userFinalEditableFileName != null) {
          if (this.docsDetailsData.finalEditableFileName != '' && this.docsDetailsData.finalEditableFileName != undefined && this.docsDetailsData.finalEditableFileName != null) {

            this.ApprovedFile = this.docsDetailsData.userFinalEditableFileName;
          }
          else if (this.docsDetailsData.prePublishedFileName != '' && this.docsDetailsData.prePublishedFileName != undefined && this.docsDetailsData.prePublishedFileName != null) {

            this.ApprovedFile = this.docsDetailsData.userFinalEditableFileName;
          }
        }
        else {
          this.ApprovedFile = this.docsDetailsData.userFileName;
        }
        if (this.selectedDocType != null && this.selectedDocType != "" && this.selectedDocType != undefined && this.selectedDocType.productRelated)
        {
          this.NotproductRelated = false;
          this.getProductsList();
        }
        else {
          this.NotproductRelated = true;
          this.GetProductLines(this.docsDetailsData.productLineId);
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  SelectPL()
  {
    this.productError = "";
    if(this.selectedProduct != null && this.selectedProduct != undefined)
    {
      this.GetProductLines(this.selectedProduct.productLineID);
    }
    else
      this.GetProductLines('');
  }

  GetProductLines(prodLine) {
    this.showLoading = true;
    this.productLineData = [];
    this._facadeService.GetProductLines().subscribe(
      (data) => {
        data.returnObject.forEach(element => {
          this.productLineData.push(element);
        });
        this.productLineData.push({ id: "-1", "name": "ALL/Non-Specific" });
        if (prodLine != "" && prodLine != null && prodLine != undefined) {
          this.selectedProductLine = this.productLineData.find(m => m.id.toUpperCase() == prodLine.toUpperCase());
        }
        this.getWorkFlowsList();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }
  filterProductLine(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.productLineData.length; i++) {
      let productline = this.productLineData[i];
      if (productline.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(productline);
      }
    }
    this.filteredproductline = filtered;
  }

  getProductsList() {
    this.showLoading = true;
    this._facadeService.getProductsList().subscribe(
      (data) => {
        this.productsData = data.returnObject;
        if (this.productGuidURL != "" && this.productGuidURL != null && this.productGuidURL != undefined) {
          var selProdct = this.productsData.find(x => x.productGuid === this.productGuidURL)
          this.selectedProduct = selProdct;
        }
        else {
          if (this.docsDetailsData != null && this.docsDetailsData != "" && this.docsDetailsData != undefined &&
            this.docsDetailsData.productGuid !== null && this.docsDetailsData.productGuid !== '' &&
            this.docsDetailsData.productGuid !== undefined && this.docsDetailsData.productGuid !== 'null') {
            var selProdct = this.productsData.find(x => x.productGuid === this.docsDetailsData.productGuid)
            this.selectedProduct = selProdct;
          }
        }
        if (this.selectedDocType != null && this.selectedDocType != "" && this.selectedDocType != undefined && this.selectedDocType.productRelated) {
          this.NotproductRelated = false;
        }
        else {
          this.NotproductRelated = true;
          this.selectedProduct = "";
        }
        this.SelectPL();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  filterProduct(event) {
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.productsData.length; i++) {
      let product = this.productsData[i];
      if (product.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(product);
      }
    }
    this.filteredproduct = filtered;
  }

  getDepartments() {
    this._facadeService.getAllDepartments().subscribe(
      (data) => {
        this.departmentsData = data.returnObject;
        this.getDocsTypes();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getDocsTypes() {
    this._facadeService.getDocsTypes().subscribe(
      (data) => {
        this.docsTypesData = data.returnObject;
        this.getDocDetailsById();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getWorkFlowsList() {
    this.showLoading = true;
    this.workFlowError = "";
    this.selectedWorkflow = null;
    var docTypeId = 0;
    if (this.selectedDocType != null && this.selectedDocType != "" && this.selectedDocType != undefined) {
      docTypeId = this.selectedDocType.documentTypeId;
    }
    // let minor = false;
    // if (this.selectedECN != null && this.selectedECN != "" && this.selectedECN != undefined) {
    //   minor = this.selectedECN.value == 1 ? true : false;
    // }
    let productlineId = "";
    if (this.selectedProductLine != null && this.selectedProductLine != "" && this.selectedProductLine != undefined) {
      if (this.selectedProductLine.id != "-1") {
        productlineId = this.selectedProductLine.id;
      }
    }
    var workFlowModel = {
      setupId: 0,
      documentTypeId: docTypeId,
      documentType: null,
      workflowDefinitionId: null,
      workflowDefinitionName: null,
      isDeleted: false,
      productLineIds: productlineId,
      isMinor: false
    };
    this._facadeService.workFlowsList(workFlowModel).subscribe(
      (data) => {
        this.workFlowData = data.returnObject;
        if (this.workFlowData.length > 0) {
          if(this.workflowDefinitionId != "")
          {
            var workflow = this.workFlowData.find(x => x.workflowDefinitionId === this.workflowDefinitionId)
            if(workflow != null && workflow != undefined)
              this.selectedWorkflow = workflow; 
            else
              this.selectedWorkflow = this.workFlowData[0];
            this.workflowDefinitionId = "";
          }
          else
          {
            if(this.selectedProductLine!=null&&this.selectedProductLine!=""&&this.selectedProductLine!=undefined&&this.selectedProductLine.id=="-1"){
            this.workFlowData=this.workFlowData.filter(m=>m.productLineIds==null);
            this.selectedWorkflow= this.workFlowData[0];
          }
          else{
            this.selectedWorkflow = this.workFlowData[0];
          }
            this.workflowDefinitionId = "";
          }
        }
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  BackClick() {
    location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"] + 'docdetails';  }

  saveAndSendApprovalClick() {
    this.saveAndSendApprovalDisplay = true;
    this.AreYouSureText = "Do you want to send this document for Obsolete approval?";
    this.showLoading = false;
  }
  createDoc(action,from) {
    this.showLoading = true;
    this.responseError = "";
    this.workFlowError = '';
    this.productError = "";
    this.ECRNDescriptionError = "";
    this.ECRNReasonError = "";
    if (this.selectedWorkflow == undefined || this.selectedWorkflow == null || this.selectedWorkflow == '') {
      this.workFlowError = 'Workflow not defined. Please contact administrator.'; this.showLoading = false;
    }
    else if (!this.NotproductRelated && (this.selectedProduct == "" || this.selectedProduct == null || this.selectedProduct == undefined)) {
      this.productError = "Please select product"; this.showLoading = false;
    }
    else if (this.obsComments == "" || this.obsComments == null || this.obsComments == undefined) {
      this.responseError = "Please enter reasons for change"; this.showLoading = false;
    }
    // else if (this.selectedDocType.creationSource == "CMS" && (this.ECRNDescription == null || this.ECRNDescription == "" || this.ECRNDescription == undefined)) {
    //   this.ECRNDescriptionError = "Please enter description of change"; this.showLoading = false;
    // }
    // else if (this.selectedDocType.creationSource == "CMS" && (this.ECRNReason == null || this.ECRNReason == "" || this.ECRNReason == undefined)) {
    //   this.ECRNReasonError = "Please enter reasons for change"; this.showLoading = false;
    // }
    else if (action === "sendforapproval") {
      this.showLoading = true;
      if (!this.NotproductRelated) {
        var model = {
          wfName: "",
          definitionId: this.selectedWorkflow.workflowDefinitionId,
          productGuid: this.selectedProduct.productGuid
        };
        this._facadeService.GetWorkflowApprovers(model).subscribe(
          (data) => {
            if (data.returnObject.length > 0) {
              this.prodrolesdata = data.returnObject.filter(x => x.productRelated == true && x.approversCount == 0);
              if (this.prodrolesdata != undefined && this.prodrolesdata != null) {
                if (this.prodrolesdata.length > 0) {
                  this.prodroles = true;
                  this.productError = "Product Team has missing approver(s).";
                  this.showLoading = false;
                }
                else
                  this.saveAndSendApprovalClick();
              }
              else
                this.saveAndSendApprovalClick();
            }
            else
              this.saveAndSendApprovalClick();
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
        this.saveAndSendApprovalClick();
    }
    else {
      this.showLoading = true;
      if (!this.NotproductRelated) {
        var model = {
          wfName: "",
          definitionId: this.selectedWorkflow.workflowDefinitionId,
          productGuid: this.selectedProduct.productGuid
        };
        this._facadeService.GetWorkflowApprovers(model).subscribe(
          (data) => {
            if (data.returnObject.length > 0) {
              this.prodrolesdata = data.returnObject.filter(x => x.productRelated == true && x.approversCount == 0);
              if (this.prodrolesdata != undefined && this.prodrolesdata != null) {
                if (this.prodrolesdata.length > 0) {
                  this.prodroles = true;
                  this.productError = "Product Team has missing approver(s).";
                  this.showLoading = false;
                }
                else
                  this.Save(action);
              }
              else
                this.Save(action);
            }
            else
              this.Save(action);
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else
        this.Save(action);
    }
  }
  Save(action) {
    this.showLoading = true;
    var obsoleteStatus="New";
    var operation="";
    if(action === 'sendforapproval' || action === 'confirm'){
      obsoleteStatus="In-Process";
      operation="sendforapproval";
    }
    if(this.selectedDocType.creationSource == "CMS")
    {
      this._facadeService.GetNewECN().subscribe(
        (data) => {
          this.selectedECRN=data.returnObject;
          var model ={
            "documentId": this.docsDetailsData.id,
            "obsoleteStatus": obsoleteStatus,
            "obsoleteComments": this.obsComments,
            "obsoleteBy": this.userId,
            "obsoleteByGuid": this.UserGUID,
            "operation":operation,
            "workflowDefinitionId": this.selectedWorkflow.workflowDefinitionId,
            "ecrn": this.selectedECRN,
            "changeDate": new Date(this.ECRNDate),
            //"changeDescription": this.ECRNDescription,
            "changeReasons": this.obsComments,
          }
          this._facadeService.ObsoleteDocument(model).subscribe(
            (data) => {
              this.saveAndSendApprovalDisplay=false;   
              this.showLoading = false; 
              if(this.userFeature)
                this.router.navigate(["/mydocsAdmin", { source: 'Admin', StateMode: 'Obsolete In-Process' }]);
              else
                location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs?TileStatus=Obsolete In-Process';
            },
            (error) => {
              this.showLoading = false;
              console.log(error);
            }
          );
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else
    {
      var model ={
        "documentId": this.docsDetailsData.id,
        "obsoleteStatus": obsoleteStatus,
        "obsoleteComments": this.obsComments,
        "obsoleteBy": this.userId,
        "obsoleteByGuid": this.UserGUID,
        "operation":operation,
        "workflowDefinitionId": this.selectedWorkflow.workflowDefinitionId
      }
      this._facadeService.ObsoleteDocument(model).subscribe(
        (data) => {
          this.saveAndSendApprovalDisplay=false;   
          this.showLoading = false; 
          if(this.userFeature)
            this.router.navigate(["/mydocsAdmin", { source: 'Admin', StateMode: 'Obsolete In-Process' }]);
          else
            location.href=this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'mydocs?TileStatus=Obsolete In-Process';
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  GetDownloadFile() {
    if (this.docsDetailsData.userFinalEditableFileName != '' && this.docsDetailsData.userFinalEditableFileName != undefined && this.docsDetailsData.userFinalEditableFileName != null) {
      if (this.docsDetailsData.finalEditableFileName != '' && this.docsDetailsData.finalEditableFileName != undefined && this.docsDetailsData.finalEditableFileName != null) {

        this.showLoading = true;
        var newname = this.docsDetailsData.userFinalEditableFileName.substr(0, this.docsDetailsData.userFinalEditableFileName.lastIndexOf('.'));
        this.Download(this.docsDetailsData.fileName, newname, this.docsDetailsData.userFinalEditableFileName);
      }
      else if (this.docsDetailsData.prePublishedFileName != '' && this.docsDetailsData.prePublishedFileName != undefined && this.docsDetailsData.prePublishedFileName != null) {

        this.showLoading = true;
        var newname = this.docsDetailsData.userFinalEditableFileName.substr(0, this.docsDetailsData.userFinalEditableFileName.lastIndexOf('.'));
        this.Download(this.docsDetailsData.fileName, newname, this.docsDetailsData.userFinalEditableFileName);
      }
    }
    else {
      this.showLoading = true;
      var newname = this.docsDetailsData.userFileName.substr(0, this.docsDetailsData.userFileName.lastIndexOf('.'));
      this.Download(this.docsDetailsData.fileName, newname, this.docsDetailsData.userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');

  }
}

