import { Component, Inject, OnInit } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: 'app-createclause',
  templateUrl: './createclause.component.html',
  styleUrls: ['./createclause.component.scss']
})
export class CreateclauseComponent implements OnInit {
  constructor(

    private fb: FormBuilder,
    private http: HttpClient,
    private _facadeService: FacadeService,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {

    {
      this.form = this.fb.group({
        name: [''],
        avatar: null,
      });
    }
  }
  userId = parseInt(sessionStorage.getItem('userId'));
  form: FormGroup;
  standardName: any;
  standardNameData: any;
  standardNameError: any;
  serialNo: any;
  serialNoError: any;
  shortDescription: any;
  shortDescriptionError: any;
  description: any;
  descriptionError: any;
  clauseGroup: any;
  clauseGroupData: any;
  clauseGroupError: any;
  checked: boolean = true;
  clauseModel: any;
  showLoading:boolean;

  ngOnInit(): void {
    this.getStandardName();
    this.getClauseGroup();
  }

  isNumberKey(evt)
       {
          var charCode = (evt.which) ? evt.which : evt.keyCode;
          if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57))
             return false;

          return true;
       }
  getStandardName() {
    this.showLoading = true;
    this._facadeService.getStandardName().subscribe(
      (data) => {
        this.showLoading = false;
        this.standardNameData = data;
        this.standardNameData = this.standardNameData.returnObject;
        console.log(this.standardNameData);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  getClauseGroup() {
    this.showLoading = true;
    this._facadeService.getClauseGroup().subscribe(
      (data) => {
        this.showLoading = false;
        this.clauseGroupData = data;
        this.clauseGroupData = this.clauseGroupData.returnObject;
        console.log(this.clauseGroupData);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  createClause() {
    this.standardNameError = '';
    this.serialNoError = '';
    this.shortDescriptionError = '';
    this.descriptionError = '';
    this.clauseGroupError = '';
    if (this.standardName == undefined || this.standardName == '') {
      this.standardNameError = 'Please select standard name.';
    }
    if (this.serialNo == undefined || this.serialNo == '') {
      this.serialNoError = 'Please enter clause.';
    }
    if (this.shortDescription == undefined || this.shortDescription == '') {
      this.shortDescriptionError = 'Please enter short description.';
    }
    if (this.description == undefined || this.description == '') {
      this.descriptionError = 'Please enter description.';
    }
    if (this.clauseGroup == undefined || this.clauseGroup == '') {
      this.clauseGroupError = 'Please select clause group.';
    }
    if (
      this.standardName != undefined &&
      this.standardName != "" &&
      this.serialNo != undefined &&
      this.serialNo != "" &&
      this.shortDescription != undefined &&
      this.shortDescription != "" &&
      this.description != undefined &&
      this.description != "" &&
      this.clauseGroup != undefined &&
      this.clauseGroup != ""
    ) {
      this.clauseModel = {
        clauseId:0,
        standardId: this.standardName.standardId,
        standardName: this.standardName.standardName,
        serialNo: this.serialNo,
        shortDescription: this.shortDescription,
        description: this.description,
        clauseGroup: this.clauseGroup.clauseGroup,
        //active: "True"
      };
      
      console.log("Input Clause Data ::" + JSON.stringify(this.clauseModel));
      this.showLoading = true;
        this._facadeService.AddClause(this.clauseModel)
        .subscribe(
          (data) => {
            this.showLoading = false;
            console.log(data);
            this.router.navigate(["/liststandard"]);
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
    }
  }

  resetCtrls() {
    this.standardName = '';
    this.serialNo = '';
    this.shortDescription = '';
    this.description = '';
    this.clauseGroup = ''; 
    this.standardNameError = '';
    this.serialNoError = '';
    this.shortDescriptionError = '';
    this.descriptionError = '';
    this.clauseGroupError = '';
    //this.checked:boolean = 'true';
    //this.ngOnInit();
  }

}

