<div class="p-grid p-fluid p-formgrid">
    <div class="p-col-12">
      <div class="card" style="min-height: 600px">
            <div class="p-grid p-fluid p-formgrid">
              <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="float: right">
                <a routerLink="/workflow/wfsetup"> <button pButton pRipple type="button" icon="pi pi-chevron-left"
                    iconPos="left" label="Back" class="p-button-raised p-button-primary p-mr-2 p-mb-2"
                    style="width:100px;"></button></a>
              </div>
              <div class="p-field p-col-12 p-lg-8 p-md-12 p-sm-12"></div>
              <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12">
                <button pButton type="button" label="New" (click)="createWorkflow()"
                  class="p-button-raised p-button-primary" icon="pi pi-plus" iconPos="left" style="width: 100px;float: right;"
                  [disabled]="userFeature"></button>
              </div>
        </div>
  
        <div class="card-header" style="background-color: #d5e8ef;border-radius: 5px; height: auto;width: 99.8%;">
          <h5 style="color: #495057; padding: 15px 0 0 15px;width: 90%;">Workflows - {{DocumentsCount}}</h5>
          <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
            <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
              (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
              style="float: right; height: 25px; width: 25px"></button>
            <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
              (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
              style="float: right; margin-left: 15px; height: 25px; width: 25px"></button>
          </span>
        </div>
        <div class="invoice invoice-header" id="invoice-content">
          <p-table #dt [value]="workflowsData" id="excel-table" [virtualScroll]="true" [scrollable]="true" scrollHeight="380px" [columns]="cols" dataKey="id"
            styleClass="p-datatable-striped" [rowHover]="true" [rows]="10" [filterDelay]="0">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <!-- <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" [ngClass]="{'width150': i == 2 || i == 3}"> -->
                  <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field" 
                  [ngClass]="{'name': i == 0,'description': i == 1,'version': i == 2,'count': i == 3,
                                        'action': i == 4}">
                  {{ col.header }}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th style="width: 150px">Actions</th>
              </tr>
              <tr ID="trSearch">
                <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                  <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" 
                  (input)="test($event.target.value,col.field,dt)"
                  style="margin: -10px 0 -10px 0; width: 60%; height: 25px" />
                </th>
                <th style="width: 150px"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                  <td *ngIf="i == 0" style="text-align: left; padding-left: 15px;width: 350px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 1" style="text-align: left; padding-left: 100px;width: 350px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 2" style="text-align: center;width: 150px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 3" style="text-align: center;width: 150px;">
                    {{ rowData[col.field] }}
                  </td>
                  <td *ngIf="i == 3" style="text-align: center;width: 150px;">
                      <button pButton type="button" icon="pi pi-pencil" pTooltip="Edit" tooltipPosition="right"
                      (click)="Edit(rowData.id)" class="p-button-raised p-button-primary"
                      style="height: 25px; width: 25px" [disabled]="userFeature"></button>
                      &nbsp;&nbsp;
                      <button pButton type="button" icon="pi pi-trash" pTooltip="Delete" tooltipPosition="right"
                      (click)="deleteDisplayClick(rowData)" class="p-button-raised p-button-danger"
                      style="height: 25px; width: 25px" [disabled]="userFeature"></button>
                  </td>
                </ng-container>
              </tr>
              <span>
                <p-dialog header="Delete Workflow" [(visible)]="deleteDisplay" modal="modal" showEffect="fade"
                  [style]="{ width: '60%' }">
                  <hr />
                  <div class="p-grid p-fluid p-formgrid">
                    <div class="p-field p-col-12 p-md-8" style="text-align: center; margin-top: 8px">
                      <h4 style="color: red">{{ AreYouSureText }}</h4>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                      <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                        <button pButton type="button" label="Delete" icon="pi pi-trash" iconPos="left"
                          (click)="deleteDoc()" class="p-button-raised p-button-danger"></button>
                      </span>
                    </div>
                    <div class="p-field p-col-12 p-md-2">
                      <span class="p-input-icon-left" style="margin-right: 10px; margin-top: 8px">
                        <button pButton type="button" label="Close" icon="pi pi-times" iconPos="left"
                          (click)="deleteDisplay = false" class="p-button-raised p-button-secondary"></button>
                      </span>
                    </div>
                  </div>
                </p-dialog>
              </span>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No workflows found.</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
  <div style="position: fixed; left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" 
  *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
  </div>