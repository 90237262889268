<div class="p-grid p-fluid p-formgrid">
  <div class="p-col-12">
    <div class="card" style="min-height: 600px">
      <!-- <div class="card-header" style="height: 40px">
        <h5 style="color: #495057; padding: 15px 0 0 15px">
        </h5>
        <span class="p-input-icon-left" style="float: right"> -->
          <div class="p-grid p-fluid p-formgrid">
            <div class="p-field p-col-12 p-lg-7 p-md-12 p-sm-12"></div>
            <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12">
              <button pButton type="button" label="Create Workflow-Mapping" (click)="createWorkflowSetup()"
                class="p-button-raised p-button-primary" icon="pi pi-plus" iconPos="left" style="width: 100%;margin-bottom:-20px;"
                [disabled]="userFeature"></button>
            </div>
          <!-- <button pButton type="button" label="Workflow Designer"(click)="openWorkflowDesign()" class="p-button-outlined p-button-primary"  style="margin-right:5px ; " ></button> -->
          <div class="p-field p-col-12 p-lg-2 p-md-12 p-sm-12" style="font-size: 18px;margin-top: 10px;">
          <a (click)="openWorkflowDesign()" *ngIf="userFeature1" style="cursor: pointer;">
            <!-- href="{{ elsaURL }}" target="blank" -->
            <!-- <span style="font-size: 18px; margin-right: 10px;"> -->
              <u>Workflow Designer </u>
            <!-- </span> -->
          </a>
        </div>
        <!-- </span> -->
      </div>

      <div class="card-header" style="background-color: #d5e8ef;border-radius: 5px; height: auto;width: 99.8%;">
        <h5 style="color: #495057; padding: 15px 0 0 15px;width: 90%;">Workflow Setup - {{DocumentsCount}}</h5>
        <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
          <!-- <button pButton type="button" label="Create Workflow-Mapping" (click)="openWorkflowDesign()" class="p-button-raised p-button-primary" icon="pi pi-plus" iconPos="left" style="margin-right:10px ; margin-top:-5px; " ></button>     
                    <button pButton type="button" label="Create Workflow-Setup" (click)="workflowsetupDisplay=true" class="p-button-raised p-button-primary" icon="pi pi-plus" iconPos="left" style="margin-right:10px ; margin-top:-5px; " ></button> -->
          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
            (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float: right; height: 25px; width: 25px"></button>
          <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download" tooltipPosition="top"
            (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float: right; margin-left: 15px; height: 25px; width: 25px"></button>
        </span>
      </div>

      <span>
        <p-dialog header="Create Workflow Mapping" [(visible)]="workflowsetupDisplay" modal="modal" showEffect="fade"
          [style]="{ width: '60%' }">
          <hr />
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-2"></div>
            <div class="p-field p-col-12 p-md-8">
              <br />
              <label for="Department">Document Type<span style="color: red">*</span></label>
              <!-- <p-dropdown inputId="documentType" [options]="docsTypesData" [(ngModel)]="selectedDocType"
                            placeholder="Select" optionLabel="documentType">
                        </p-dropdown> -->
              <p-autoComplete [(ngModel)]="selectedDocType" [suggestions]="filteredDocType"
                (completeMethod)="filterDocTypes($event)" field="documentType" [dropdown]="true">
                <ng-template>
                  <div>
                    <div>{{ filteredDocType.documentType }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
              <small class="p-invalid">{{ docTypeError }}</small>
              <br /><br />
              <label for="Department">Workflow<span style="color: red">*</span></label>
              <!-- <p-dropdown inputId="workflowDefinitionId" [options]="workflowdefinitions"
                            [(ngModel)]="selectedWorkflow" placeholder="Select"
                            optionLabel="name">
                        </p-dropdown> -->
              <p-autoComplete [(ngModel)]="selectedWorkflow" [suggestions]="filteredWFDef"
                (completeMethod)="filterWFDefinitions($event)" field="name" [dropdown]="true">
                <ng-template>
                  <div>
                    <div>{{ filteredWFDef.name }}</div>
                  </div>
                </ng-template>
              </p-autoComplete>
              <small class="p-invalid">{{ workFlowError }}</small>
              <br />
            </div>
            <div class="p-field p-col-12 p-md-2"></div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6"></div>

            <div class="p-field p-col-12 p-md-2">
              <br />
              <button pButton pRipple type="button" icon="pi pi-check" label="Submit" (click)="createWokrflowSetUp()"
                class="p-button-raised p-button-success p-mr-2 p-mb-2" style="float: right"></button>
            </div>
            <div class="p-field p-col-12 p-md-2">
              <br />
              <button pButton pRipple type="button" (click)="resetCtrls()" icon="pi pi-refresh" label="Reset"
                class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                style="float: right; margin-left: 10px"></button>
            </div>
            <div class="p-field p-col-12 p-md-2"></div>
            <small class="p-invalid" style="float: right">{{
              responseError
              }}</small>
          </div>
        </p-dialog>
      </span>

      <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
        <p-table #dt [value]="workflowsData" id="excel-table" [columns]="cols" dataKey="id"
          styleClass="p-datatable-striped" [virtualScroll]="false" [scrollable]="true" scrollHeight="400px" [rowHover]="true" [rows]="10" [filterDelay]="0"
          [globalFilterFields]="['documentType', 'workflowDefinitionName']">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns;let i = index" [pSortableColumn]="col.field"
              [ngClass]="{'documentType': i == 0,'workflowDefinitionName': i == 1}">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th style="width: 300px">Actions</th>
            </tr>
            <tr ID="trSearch">
              <th *ngFor="let col of columns" [ngSwitch]="col.field" style="text-align: center">
                <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder" 
                (input)="test($event.target.value,col.field,dt)"
                style="margin: -10px 0 -10px 0; width: 60%; height: 25px" />
              </th>
              <th style="width: 300px"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                <td *ngIf="i == 0" style="text-align: left; padding-left: 10%">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: left; padding-left: 10%">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: center">
                  <button pButton type="button" icon="pi pi-trash" pTooltip="Delete" tooltipPosition="right"
                    (click)="deleteDisplayClick(rowData)" class="p-button-raised p-button-danger"
                    style="height: 25px; width: 25px" [disabled]="userFeature"></button>
                </td>
              </ng-container>
            </tr>
            <span>
              <p-dialog header="Delete Workflow Setup" [(visible)]="deleteDisplay" modal="modal" showEffect="fade"
                [style]="{ width: '60%' }">
                <hr />
                <div class="p-grid p-fluid p-formgrid">
                  <div class="p-field p-col-12 p-md-8" style="text-align: center; margin-top: 8px">
                    <h4 style="color: red">{{ AreYouSureText }}</h4>
                  </div>
                  <div class="p-field p-col-12 p-md-2">
                    <span class="p-input-icon-left" style="float: right; margin-right: 10px; margin-top: 8px">
                      <button pButton type="button" label="Delete" icon="pi pi-trash" iconPos="left"
                        (click)="deleteDoc()" class="p-button-raised p-button-danger"></button>
                    </span>
                  </div>
                  <div class="p-field p-col-12 p-md-2">
                    <span class="p-input-icon-left" style="margin-right: 10px; margin-top: 8px">
                      <button pButton type="button" label="Close" icon="pi pi-times" iconPos="left"
                        (click)="deleteDisplay = false" class="p-button-raised p-button-secondary"></button>
                    </span>
                  </div>
                </div>
              </p-dialog>
            </span>
          </ng-template>
          <!-- <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">No documents found.</td>
                            </tr>
                        </ng-template> -->
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3">No documents found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<div style="position: fixed; left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" 
*ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>