<div class="p-grid table-demo">
    <div class="p-col-12">
      <div class="card" style="min-height:600px;">
        <div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; height:auto; ">
         
          <h5 style="color:#495057; padding:15px 0 0 15px;width: 90%;">Delegated Documents</h5> 
          <span class="p-input-icon-left" style="float:right; margin-right:10px;margin-top:8px;">        
          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print"tooltipPosition="top" (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2" style="float:right; height:25px; width:25px;"></button>
          <button pButton pRipple type="button" icon="pi pi-download"pTooltip="Download"tooltipPosition="top" (click)="exportExcel()" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"style="float:right; margin-left:15px ;width:25px; height:25px; "></button>
            </span>
        </div> 
      
        <div class="invoice invoice-header"id="invoice-content" style="overflow-x: auto;">
        <p-table #dt [value]="docsData" id="excel-table" [columns]="cols" sortMode="multiple" class="table-align"styleClass="p-datatable-striped"
        [filterDelay]="0" selectionMode="multiple" dataKey="id"
         [globalFilterFields]="['code','title','documentType','effectiveDate','reviewDate','status','owner']">
        <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                [ngClass]="{'code': i == 0,'title': i == 1,'documentType': i == 2,'effectiveDate': i == 3,'reviewDate': i == 4,
                'status': i == 5, 'owner': i == 6}">
                    {{col.header}}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th pSortableColumn="price">View</th>
            </tr>
            <tr id="trSearch">
                <th *ngFor="let col of columns" [ngSwitch]="col.field"
                    style="text-align: center;">
                    <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                        (input)="dt.filter($event.target.value, col.field, 'contains')"
                        style="margin: -10px 0 -10px 0; width:90%; height:25px;">
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-docsData>
            <tr [pSelectableRow]="rowData">
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
  
                    <td *ngIf="i == 0" style="text-align: left;">
                        {{ rowData[col.field] }}
                    </td>
                    <td *ngIf="i == 1" style="text-align: left;">
                        {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 2" style="text-align: left;">
                        {{rowData[col.field]}}
                    </td>
                    <td *ngIf="i == 3" style="text-align: center;">
                        {{rowData[col.field] | date: "MM/dd/yyyy"}}
                    </td>
                    <td *ngIf="i == 4" style="text-align: center;">
                        {{rowData[col.field] | date: "MM/dd/yyyy"}}
                    </td>
                    <td *ngIf="i == 5" style="text-align: left;"
                    [ngStyle]="{'color': rowData[col.field] === 'New' ? '#0288D1' : rowData[col.field] === 'In-Process' ? '#e8a704' : rowData[col.field] === 'Approved' ? '#537f2d' : rowData[col.field] === 'Hold' ? '#495057':rowData[col.field] === 'Obsoleted' ? '#4DB6AC':rowData[col.field] === 'Obsolete Rejected' ? '#FBC02D' :rowData[col.field] === 'Obsolete In-Process' ? '#7986CB':  rowData[col.field] === 'Obsolete Approved' ? '#537f2d': '#D32F2F'}">
                    {{rowData[col.field]}}
                  </td>
                  <td *ngIf="i == 6" style="text-align: left;">
                    {{rowData[col.field]}}
                  </td>
                </ng-container>
                <td style="text-align: center;">
                  <button
                    pButton
                    type="button"
                    (click)="viewDocDetails(docsData.id, docsData.wfDefinitionId)"
                    icon="pi pi-eye"
                    iconPos="left"
                    class="p-button-rasised p-button-seconday"
                    style="width: 25px; height: 25px;"
                  ></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="8" style="text-align: left;">No documents found.</td>
          </tr>
      </ng-template>
      </p-table>
      </div>
    </div>
  </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
</div>
  