import { Component, Inject, OnInit } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { viewClassName } from '@angular/compiler';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
interface Status {
  name: string,
  code: string
}
interface Types {
  standardType: string,
  code: string
}
@Component({
  selector: 'app-createstandard',
  templateUrl: './createstandard.component.html', 
  styleUrls: ['./createstandard.component.scss']
})
export class CreatestandardComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {
    this.statuses = [
      { name: 'Active', code: 'Active' },
      { name: 'InActive', code: 'InActive' },
    ];
    this.standardTypes = [
      { standardType: 'ACT', code: 'ACT' },
      { standardType: 'CHECK', code: 'CHECK' },
      { standardType: 'DO', code: 'DO' },
      { standardType: 'PLAN', code: 'PLAN' },
    ];
    {
      this.form = this.fb.group({
        name: [''],
        avatar: null,
      });
    }
  }
  userId = parseInt(sessionStorage.getItem('userId'));
  form: FormGroup;
  standardName: any;
  standardNameError: any;
  standardType: any;
  name: any;
  standardTypeError: any;
  StandardsData: any;
  startDate: any = '';
  startDateError: any;
  endDate: any = '';
  endDateError: any;
  statusError: any;
  statusData: any;
  statuses: Status[];
  standardTypes: Types[];
  selectedStatus: any;
  today: any;
  myDocsMode: any;
  liststandard: any;
  standardModel: any;
  showLoading:boolean;
  minDate:Date;
  apiResponse: any;
  responseError: any;

  ngOnInit(): void {
    this.getStandards();
    var status=this.statuses.find(s=> s.name="Active");
    this.selectedStatus = status;
    let today = new Date();
    this.minDate=new Date();
    this.startDate = new Date();
    this.endDate = new Date();
    this.endDate.setDate( this.endDate.getDate() + 3650 );
  }

  getStandards() {
    this.showLoading = true;
    this._facadeService.getStandards().subscribe(
      (data) => {
        this.showLoading = false;
        this.StandardsData = data;
        this.StandardsData = this.StandardsData.returnObject;
        console.log(this.StandardsData);
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  createStandard() {
    this.standardNameError = '';
    this.standardTypeError = '';
    this.startDateError = '';
    this.endDateError = '';
    this.statusError = '';
    if (this.standardName == undefined || this.standardName == '') {
      this.standardNameError = 'Please enter standard name.';
    }
     if (this.standardType == undefined || this.standardType == '') {
      this.standardTypeError = 'Please select standard type.';
    }
    if (this.startDate == undefined || this.startDate == '') {
      this.startDateError = 'Please select start date.';
    }
    if (this.endDate == undefined || this.endDate == '') {
      this.endDateError = 'Please select end date.';
    }
     if (this.selectedStatus == undefined || this.selectedStatus == '' || this.selectedStatus == "Select") {
      this.statusError = 'Please select status.';
    }
    if (this.startDate != undefined && this.startDate != '' && this.endDate != undefined && this.endDate != '') {
      if( new Date(this.startDate) > new Date(this.endDate))
      { this.startDateError = 'Start date should be less than end date.';
      return;
    }
    }
    if (
      this.standardName != undefined &&
      this.standardName != "" &&
      this.standardType != undefined &&
      this.standardType != "" &&          
      this.startDate != undefined &&
      this.startDate != "" &&
      this.endDate != undefined &&
      this.endDate != "" &&   
      this.selectedStatus != undefined &&
      this.selectedStatus != "" 
    ){ 
        this.standardModel = {
          standardId: 0,
          standardName: this.standardName,
          standardType: this.standardType.standardType,
          startDate: this.startDate, // new Date(),
          endDate: this.endDate,//new Date(),
          status: this.selectedStatus.name,
        };
        console.log("Input Standard Data ::" + JSON.stringify(this.standardModel));
        this.showLoading = true;
        this._facadeService.CreateStandard(this.standardModel)
        .subscribe(
          (data) => {
            this.showLoading = false;
            this.apiResponse = data;
            if(this.apiResponse.returnCode >= 0){
              this.router.navigate(["/liststandard"]);
            }
            else{
              this.responseError = this.apiResponse.returnMessage;
            }
            console.log(data);
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
    }
  }

  EndDateCheck(date){
    this.startDateError="";
    this.endDateError="";
    var GivenDate = date;
var CurrentDate = new Date(this.startDate);
GivenDate = new Date(GivenDate);
if(GivenDate < CurrentDate){
  this.endDateError='Given date is greater than the today date.';
}
  }
  StartDateCheck(date){
    this.endDateError="";
    this.startDateError="";
    var GivenDate = date;
var CurrentDate = new Date(this.endDate);
GivenDate = new Date(GivenDate);
if(GivenDate > CurrentDate){
  this.startDateError='Given date is greater than the end date.';
}
  }
  resetCtrls()
  {
    this.standardName = '';
    this.standardType = '';
    this.endDate = '';
    this.standardNameError = '';
    this.standardTypeError = '';
    this.startDateError = '';
    this.endDateError = '';
    this.statusError = '';
    this.selectedStatus = this.statuses.find(s=> s.name="Active");
    //this.ngOnInit();
  }
}

