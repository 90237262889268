import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { approvalNeededModel } from '../../models/approvalneeded_model';
import { FacadeService } from '../../facade/facade.service';
import * as _ from "lodash";
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";

@Component({
  selector: "app-pendingforapproval",
  templateUrl: "./pendingforapproval.component.html",
  styleUrls: ["./pendingforapproval.component.scss"],
})
export class PendingforapprovalComponent implements OnInit {
  constructor(
    private _facadeService: FacadeService,public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {}
  userId =
    sessionStorage.getItem("userId") != null
      ? parseInt(sessionStorage.getItem("userId"))
      : 0;
  userGuid = sessionStorage.getItem("userGuid");
  myApprovalModel: any;
  today: string;
  docsData: approvalNeededModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading: boolean;
  DocumentsCount=0;
  title: number = 5;
  EmailDisplay:boolean;
  isDisabled:boolean=true;
  userFeature:boolean;
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DSBL_SRCH_VIEW");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
        this.DocusPendingForApproval("R");

    this.cols = [
      {
        field: "ecrn",
        header: "ECRN",
        dynamicPlaceHolder: "Search by ECRN",
      },
      {
        field: "departmentName",
        header: "Department",
        dynamicPlaceHolder: " Search by Dept",
      },
      {
        field: "code",
        header: "Document Id",
        dynamicPlaceHolder: "Search by Doc Id",
      },
      { field: "revision", header: "Rev", dynamicPlaceHolder: "Search by Rev" },
      {
        field: "title",
        header: "Title",
        dynamicPlaceHolder: "Search by Title",
      },
      {
        field: "owner",
        header: "Originated By",
        dynamicPlaceHolder: "Search by Originator",
      },
      {
        field: "elapsedDays",
        header: "Elapsed Days Current Approver",
        dynamicPlaceHolder: "Search by Pending days",
      },
      {
        field: "totalElapsedDays",
        header: "Elapsed Days Total",
        dynamicPlaceHolder: "Search by Total Days",
      },
      {
        field: "approver",
        header: "Pending Approvers",
        dynamicPlaceHolder: "Search by Approvers",
      }
    ];
    sessionStorage.ecrn = "";
    sessionStorage.code ="";
    sessionStorage.revision = "";
    sessionStorage.approver = "";
    sessionStorage.title ="";
    sessionStorage.departmentName = "";
    sessionStorage.owner = "";
    sessionStorage.status = "";
    sessionStorage.totalElapsedDays = "";
    sessionStorage.elapsedDays = "";
  }

  DocusPendingForApproval(from) {
    var days = sessionStorage.getItem("daysCount");
    if(days!=null && days!=""&&days!=undefined &&from=="R"){
    this.title=parseInt(days);
    }
      this.showLoading = true; 
      this._facadeService.approvalNeededDataRPT(this.title,sessionStorage.getItem('timeZone'),this.userId).subscribe(
        (data) => {
          this.docsData = data.returnObject;
          console.log("this.docsData----", this.docsData);
          if(this.docsData !== null && this.docsData !== undefined && this.docsData.length>0){
          this.docsData.forEach(element => {
            if(element.ecrn === null || element.ecrn === undefined || element.ecrn === ""){
              element.ecrn = 'N/A';
            }
            else{
              element.ecrn = element.ecrn
            }
          });
        }
          
        if(this.docsData!=null && this.docsData.length>0){
          this.isDisabled=false;
        }
        else{
          this.isDisabled=true;
        }
        this.CountData();
        this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
  }

  // exportExcel() {
  //   /* table id is passed over here */   
  //   let element = document.getElementById('excel-table'); 
  //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element, {raw: true});
  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   this.delete_row(wb.Sheets.Sheet1, 1);
  //   ws['!cols'] = [{ wpx : 100 },{ wpx :  140},{ wpx : 80 },{ wpx : 80 },{ wpx : 550 },{ wpx : 150 },{ wpx : 200 },{ wpx : 120 },{ wpx : 310 }];
  //   /* save to file */
  //   XLSX.writeFile(wb, "Documents Pending for Approval.xlsx");
  // }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;

    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);

      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }

  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx : 100 },{ wpx :  140},{ wpx : 80 },{ wpx : 80 },{ wpx : 550 },{ wpx : 150 },{ wpx : 200 },{ wpx : 120 },{ wpx : 310 }];
  
    // Save to file
    XLSX.writeFile(wb, 'Documents Pending for Approval.xlsx');
  }
  print() {
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content, "Documents Pending for Approval", 'trSearch', true);
  }
  // print() {
  //   var Content = document.getElementById('invoice-content').innerHTML;
  //   this.printService.Print(Content,"Documents Pending for Approval",'trSearch',true);
  // }
  // delete_row (ws, row_index){
  //   let range = XLSX.utils.decode_range(ws["!ref"])
  //   for(var R = row_index; R < range.e.r; ++R){
  //       for(var C = range.s.c; C <= range.e.c; ++C){
  //         ws[this.ec(R, C)] = ws[this.ec(R+1, C)]

  //         if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
  //           ws[this.ec(R+1, C)].v  = "";
  //       }
  //   }
  //   range.e.r--
  //   delete (ws['J1'])
  //   ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  // }
  // ec(r, c) {
  //   return XLSX.utils.encode_cell({r:r,c:c})
  // }

  viewDocDetails(selectedDocId) {
    sessionStorage.docId = selectedDocId.documentId;
    sessionStorage.StatusDoc = selectedDocId.status;
    sessionStorage.isFrom = "pendingforapproval";
    sessionStorage.daysCount= this.title;
    this.router.navigate(["/docdetails"]);
  }
  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
      const activity = parseInt(value);

      if (!isNaN(activity)) {
        this.table.filter(activity, "activity", "gte");
      }
    }
  }
  SendEmail(){
    if(this.docsData!=null && this.docsData.length>0){
      this.EmailDisplay=true;
      this._facadeService.SendEmail(this.userId,"pendingforapproval",this.docsData).subscribe(
        (response: any) => {
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  test(val,field,dt)
  {
    if(field == "ecrn")
      sessionStorage.ecrn = val;
    else if(field == "code")
      sessionStorage.code = val;
    else if(field == "revision")
      sessionStorage.revision = val;
    else if(field == "title")
      sessionStorage.title = val;
    else if(field == "departmentName")
      sessionStorage.departmentName = val;
    else if(field == "elapsedDays")
      sessionStorage.elapsedDays = val;
    else if(field == "owner")
      sessionStorage.owner = val;
    else if(field == "status")
      sessionStorage.status = val;
    else if(field == "totalElapsedDays")
      sessionStorage.totalElapsedDays = val;
    else if(field == "approver")
      sessionStorage.approver = val;

    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
CountData(){
  if(this.docsData != undefined && this.docsData != null)
  {
    var temp = this.docsData;
    if(sessionStorage.ecrn != ""&&sessionStorage.ecrn != null)
      temp = temp.filter(x=> x.ecrn!=null&&x.ecrn!=""&&x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
    if(sessionStorage.code != "")
      //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != "")
      temp = temp.filter(x=> x.revision.toString().includes(sessionStorage.revision.toString()));
    if(sessionStorage.approver != ""&&sessionStorage.approver !=null)
      temp = temp.filter(x=> x.approver.toLowerCase().includes(sessionStorage.approver.toLowerCase()));
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
    if(sessionStorage.departmentName != "")
      temp = temp.filter(x=> x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
    if(sessionStorage.owner != "")
      temp = temp.filter(x=> x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
    if(sessionStorage.status != "")
      temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    if(sessionStorage.elapsedDays != "")
      temp = temp.filter(x=> x.elapsedDays.toString().includes(sessionStorage.elapsedDays));
    if(sessionStorage.totalElapsedDays != "")
      temp = temp.filter(x=> x.totalElapsedDays.toString().includes(sessionStorage.totalElapsedDays));
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
