import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { approvalNeededModel } from '../../models/approvalneeded_model';
import * as _ from "lodash";
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from "primeng/table";
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
import { FacadeService } from 'src/app/facade/facade.service';
import { PrintService } from "../../services/print.service";
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-byproject',
  templateUrl: './byproject.component.html',
  styleUrls: ['./byproject.component.scss'],
  styles: [`:host ::ng-deep .p-multiselect {
		min-width: 15rem;
	}
	:host ::ng-deep .multiselect-custom-virtual-scroll .p-multiselect {
		min-width: 20rem;
	}
	:host ::ng-deep .multiselect-custom .p-multiselect-label {
		padding-top: .25rem;
		padding-bottom: .25rem;

	}
	:host ::ng-deep .multiselect-custom .country-item.country-item-value {
		padding: .25rem .5rem;
		border-radius: 3px;
		display: inline-flex;
		margin-right: .5rem;
		background-color: var(--primary-color);
		color: var(--primary-color-text);
	}
	:host ::ng-deep .multiselect-custom .country-item.country-item-value img.flag {
		width: 17px;
	}
	:host ::ng-deep .multiselect-custom .country-item {
		display: flex;
		align-items: center;
	}
	:host ::ng-deep .multiselect-custom .country-item img.flag {
		width: 18px;
		margin-right: .5rem;
	}
	:host ::ng-deep .multiselect-custom .country-placeholder {
		padding: 0.25rem;
	}
	:host ::ng-deep .p-colorpicker {
		width: 2.5em
	}`]
})
export class ByprojectComponent implements OnInit {
  projects: any;
  selecteprojectArray: any[] = [];
  Prjarray: string;
  selectedStatusChecked: any[];
  docum: any[];
  isFrom: any;
  constructor(
    private _facadeService: FacadeService,
    private router: Router,    
    private route:ActivatedRoute,
    public printService: PrintService,
    private datePipe: DatePipe,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,private cdr: ChangeDetectorRef
  ) {}
  userId = sessionStorage.getItem('userId')!=null?parseInt(sessionStorage.getItem('userId')):0;
  userGuid = sessionStorage.getItem('userGuid');
  timeZone = sessionStorage.getItem('timeZone');
  projectError:string;
  docsData: approvalNeededModel[];
  cols: any[];
  @ViewChild("dt") table: Table;
  dynamicPlaceHolder: string;
  showLoading:boolean;
  source:any;
  index:any;
  selectedProjects:any;
  EmailDisplay:boolean;
  isDisabled:boolean=true;
  DocumentsCount=0;
  ngOnInit(): void {
    sessionStorage.ecrn = "";
    sessionStorage.code ="";
    sessionStorage.revision = "";
    sessionStorage.title ="";
    sessionStorage.alternateDocID = "";
    sessionStorage.project = "";
    sessionStorage.owner = "";
    sessionStorage.status = "";
    sessionStorage.date = "";
    this.source = '';  
    this.isFrom = this.route.snapshot.paramMap.get('isFrom');
    this.source = this.route.snapshot.paramMap.get('status');
    console.log('this.source-----', this.source); 
    this.Prjarray = "";
    if(this.isFrom === 'docdetails'){
      this.selectedProjects = JSON.parse(sessionStorage.selectedProjects);
      this.selecteprojectArray=[];
      this.selectedProjects.forEach((value, key) => {
      this.selecteprojectArray.push(value.productGuid);
      });
      this.searchProject();
      console.log('docdetails =====',this.selecteprojectArray);
    }
        if(this.baseUrl["API_ENDPOINTS"]["ISPDMFlagActive"]){
        this.getProjects();
        }
        this.cols = [
          { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'Search by ECRN' }, 
          { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Search by Doc Id' },
          { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Search by Rev'},
          { field: 'title', header: 'Title', dynamicPlaceHolder: 'Search by Title' },         
          { field: 'alternateDocID', header: 'Alt-Doc Id', dynamicPlaceHolder: 'Search by Alt-Doc Id' },          
          { field: 'project', header: 'Project', dynamicPlaceHolder: 'Search by Project'},        
          { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Search by Originator'},         
          { field: 'status', header: 'Status', dynamicPlaceHolder: 'Search by Status' },
          { field: 'date', header: 'Date', dynamicPlaceHolder: 'Date' },
        ];
  }

  selectedStatusChange(selectedStatus){
    this.selecteprojectArray=[];
    selectedStatus.value.forEach((value, key) => {
      this.selecteprojectArray.push(value.productGuid);
    });
  }

  searchProject(){
    this.docsData=[];
    this.DocumentsCount = 0; 
    this.projectError = '';
    if(this.selectedProjects!== null && this.selectedProjects !== undefined && this.selectedProjects.length > 0){
    this.showLoading = true;
    console.log('selectedProjects ======',this.selectedProjects);
    console.log('selectedProjects ======',this.selecteprojectArray);
    console.log('selectedProjects ======',this.timeZone);
    if(this.selectedProjects !== null && this.selectedProjects !== undefined){
    if (this.selectedProjects.length > 0) {
      sessionStorage.setItem('selectedProjects', JSON.stringify(this.selectedProjects));
    this._facadeService.documentByProject(this.selecteprojectArray,this.timeZone,this.userId).subscribe(
      (data) => {
        this.docsData = data.returnObject;
        if(this.docsData!=null && this.docsData.length>0){
          this.isDisabled = false;
          this.docsData.forEach(element => {
            if(element.status=='New'||element.status == 'In-Process' || element.status == 'Obsolete In-Process'){
              element.date ="--";
            }
            else{
              element.date = this.datePipe.transform(element.date, 'MM/dd/yyyy');
            }
            if(element.ecrn === null || element.ecrn === undefined || element.ecrn === ""){
              element.ecrn = 'N/A';
            }
            else{
              element.ecrn = element.ecrn
            }
            if(element.alternateDocID !== null && element.alternateDocID !== 'null' && element.alternateDocID !== undefined)
            {
            }
            else
              element.alternateDocID = '';
          });
        }
       else{
        this.isDisabled = true;
       }
        console.log('-----------',this.docsData);
        this.CountM();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    
  }
}
else{
  this.showLoading = false;
}
    }
    else{
        this.projectError ='select atleast one project';
    }
  }

  SendEmail(){
    if(this.docsData!=null && this.docsData.length>0){
      this.EmailDisplay=true;
      this._facadeService.SendEmail(this.userId,"byproject",this.docsData).subscribe(
        (response: any) => {
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  getProjects() {
    this.showLoading = true;
    var userDepartments = JSON.parse(sessionStorage.getItem("userdepts"));
    var salesexist = userDepartments.find(m=>m.name=="Sales");
    if(userDepartments.length==1&&salesexist!=null){
      this._facadeService.getProjects(this.userGuid).subscribe(
        (response: any) => {
          this.projects = response.returnObject;
          this.showLoading = false;
          console.log("Projects ::" + JSON.stringify(this.projects));
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      this._facadeService.getAllActiveProducts().subscribe(
        (response: any) => {
          this.projects = response.returnObject;
          this.showLoading = false;
          console.log("Projects ::" + JSON.stringify(this.projects));
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
  }
  print() {
   
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
    this.printService.Print(Content,"Documents By Project",'trSearch',false);
  }
//   exportExcel() {
//     /* table id is passed over here */   
//     let element = document.getElementById('excel-table'); 
//     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//     /* generate workbook and add the worksheet */
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     this.delete_row(wb.Sheets.Sheet1, 1);
//     ws['!cols'] = [{ wpx : 90 },{ wpx :  80},{ wpx : 80 },{ wpx : 530 },{ wpx : 80 },{ wpx : 200 },{ wpx : 140 },{ wpx : 100 },{ wpx : 100 }];
//     /* save to file */
//     XLSX.writeFile(wb, "Documents By Project.xlsx");
// }
exportExcel() {
  // Manually load all data
  this.table.first = 0;
  this.table.rows = this.table.totalRecords;

  // Wait for data to load (assuming it's loaded asynchronously)
  setTimeout(() => {
    const data = this.getDataFromTable(this.table);

    // Proceed with exporting data to Excel
    this.exportDataToExcel(data);
  }, 100); // Adjust the timeout as needed
}

// Helper function to get visible columns and data from the p-table
getDataFromTable(table: Table): { columns: any[], data: any[] } {
  const visibleColumns = table.columns.filter(column => column.field);
  const columns = visibleColumns.map(column => {
    return {
      header: typeof column.header === 'object' ? column.header['name'] : column.header,
      field: column.field
    };
  });
  // Use the 'filteredValue' property if available, otherwise fallback to 'value'
  const data = (table.filteredValue || table.value).map(item => {
    const rowData = {};
    visibleColumns.forEach(column => {
      const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
      rowData[columnHeader] = item[column.field];
    });
    return rowData;
  });

  return { columns, data };
}

// Example function to export data to Excel (you can customize this based on your needs)
exportDataToExcel(data: { columns: any[], data: any[] }) {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });

  // Add headers separately
  const headerRows = [data.columns.map(column => column.header)];
  XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Customize the column widths if needed
  ws['!cols'] = [{ wpx : 90 },{ wpx :  80},{ wpx : 80 },{ wpx : 530 },{ wpx : 80 },{ wpx : 200 },{ wpx : 140 },{ wpx : 100 },{ wpx : 100 }];

  // Save to file
  XLSX.writeFile(wb, "Documents By Project.xlsx");
}
CountM()
{
  if(this.docsData != undefined && this.docsData != null)
  {
    var temp = this.docsData;
    if(sessionStorage.ecrn != "")
      temp = temp.filter(x=> x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
    if(sessionStorage.code != "")
      //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != "")
      temp = temp.filter(x=> x.revision.toLowerCase().includes(sessionStorage.revision.toLowerCase()));
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
    if(sessionStorage.alternateDocID != "")
      temp = temp.filter(x=> x.alternateDocID.toLowerCase().includes(sessionStorage.alternateDocID.toLowerCase()));
    if(sessionStorage.project != "")
      temp = temp.filter(x=> x.project.toLowerCase().includes(sessionStorage.project.toLowerCase()));
    if(sessionStorage.owner != "")
      temp = temp.filter(x=> x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
    if(sessionStorage.status != "")
      temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    if(sessionStorage.date != "")
      temp = temp.filter(x=> x.date.toString().toLowerCase().includes(sessionStorage.date.toLowerCase()));
    this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
test(val,field,dt)
{
  if(field == "ecrn")
    sessionStorage.ecrn = val;
  else if(field == "code")
    sessionStorage.code = val;
  else if(field == "revision")
    sessionStorage.revision = val;
  else if(field == "title")
    sessionStorage.title = val;
  else if(field == "alternateDocID")
    sessionStorage.alternateDocID = val;
  else if(field == "project")
    sessionStorage.project = val;
  else if(field == "owner")
    sessionStorage.owner = val;
  else if(field == "status")
    sessionStorage.status = val;
  else if(field == "date")
    sessionStorage.date = val;
  dt = dt.filter(val,field, 'contains');
  this.CountM();
}
delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['J1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

  viewDocDetails(selectedDocId) {
    sessionStorage.docId = selectedDocId.documentId;
    sessionStorage.StatusDoc =selectedDocId.status;
    sessionStorage.isFrom= "byproject";
    this.router.navigate(['/docdetails']);
  }
  onActivityChange(event) {
    const value = event.target.value;
    if (value && value.trim().length) {
        const activity = parseInt(value);

        if (!isNaN(activity)) {
            this.table.filter(activity, 'activity', 'gte');
        }
    }
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'date') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '--' ? null : new Date(value1);
      const date2 = value2 === '--' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    if (e.status == 'New' || e.status == 'In-Process' || e.status == 'Obsolete In-Process') {
      e.date = "--";
    } else if (event.field !== 'date') {
      // Only format lastAccessedDate if it's not the field used for sorting
      e.date = this.datePipe.transform(e.date, 'MM/dd/yyyy');
    }
  });
  this.docsData = [...event.data];
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
