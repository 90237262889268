import { Component, OnInit,ViewChild,Inject } from '@angular/core';
import { FacadeService } from '../../facade/facade.service';
//import { DatePipe } from '@angular/common';
//import { Router } from '@angular/router';
import { Table } from "primeng/table";
import { FormBuilder, FormGroup } from "@angular/forms";
//import { HttpClient } from "@angular/common/http";
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';
@Component({
  selector: 'app-listdelegate',
  templateUrl: './listdelegate.component.html',
  styleUrls: ['./listdelegate.component.scss']
})
export class ListdelegateComponent implements OnInit {

  constructor(
    private fb: FormBuilder,public printService: PrintService,
    //private http: HttpClient,
    private _facadeService: FacadeService,
    private datePipe: DatePipe,
    private router: Router,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object
  ) {
    this.form = this.fb.group({
      name: [""],
      avatar: null,
    });
  }
  userId = parseInt(sessionStorage.getItem("userId"));

  // apiResponse: any;
  // responseError: any;
  cols: any[];
  form: FormGroup;
  DelegationExist:boolean;
  deleteDisplay:boolean;
  AlldeleteDisplay:boolean;
  selectedDelegates=[];
  AllAreYouSureText="";
  commentDisplay:boolean;
  commentText="";
  // delegationModel: any;
  // departmentsData: any;
  // TodepartmentsData: any;
  // fromRoleData: any;
  // ToRolesData: any;
  // fromUserData: any;  
  // ToUsersData: any;
  // selectedDept: any;
  // selectedUser: any;
  // fromDate: any;
  // RolesData: any;
  // ToselectedDept: any;
  // selectedRole: any;
  // UsersData: any;
  // ToselectedRole: any;
  // ToselectedUser: any;
  usersdelegateData=[];
  showLoading:boolean;
  delegateData: any;
  AreYouSureText:any="";
  delegationId:number=0;
  Fromuser:any;
  DocumentsCount =0;
  userscols=[];
  userFeature:boolean;
  @ViewChild("dt") table: Table;
  @ViewChild("dt1") table1: Table;

  delegateError="";
  ngOnInit(): void {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DELEGATE_ADMIN");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    this.GetAllDelegations();
    sessionStorage.department= "";
      sessionStorage.role= "";
      sessionStorage.user= "";
      sessionStorage.toDepartment= "";
      sessionStorage.toRole= "";
      sessionStorage.toUser= "";
      sessionStorage.fromDate= "";
      sessionStorage.toDate= "";
      sessionStorage.active= "";
      sessionStorage.code= "";
      sessionStorage.revision= "";
      sessionStorage.documentType= "";
    this.cols = [
      { field: 'user', header: 'User',dynamicPlaceHolder:'Search by user' },
      { field: 'role', header: 'Role' ,dynamicPlaceHolder:'Search by role' },
      { field: 'toUser', header: 'To User' ,dynamicPlaceHolder:'Search by toUser' },
      { field: 'toRole', header: 'To Role', dynamicPlaceHolder:'Search by toRole' },
      { field: 'documentType', header: 'Document Type' ,dynamicPlaceHolder:'Search byDocument type' },
      { field: 'code', header: 'Document Id' ,dynamicPlaceHolder:'Search by Document Id' },
      { field: 'revision', header: 'Rev' ,dynamicPlaceHolder:'Search by Rev' },
      { field: 'fromDate', header: 'From Date' ,dynamicPlaceHolder:'Search' },
      { field: 'toDate', header: 'To Date',dynamicPlaceHolder: 'Search' }
    ];
     this.userscols = [
      { field: 'user', header: 'User',dynamicPlaceHolder:'Search by user' },
      { field: 'role', header: 'Role' ,dynamicPlaceHolder:'Search by role' },
      { field: 'toUser', header: 'To User' ,dynamicPlaceHolder:'Search by toUser' },
      { field: 'toRole', header: 'To Role', dynamicPlaceHolder:'Search by toRole' },
      { field: 'documentType', header: 'Document Type' ,dynamicPlaceHolder:'Search byDocument type' },
      { field: 'code', header: 'Document Id',dynamicPlaceHolder:'Search Document Id' },
      { field: 'revision', header: 'Rev' ,dynamicPlaceHolder:'Search by Rev' },
      { field: 'fromDate', header: 'From Date' ,dynamicPlaceHolder:'Search by' },
      { field: 'toDate', header: 'To Date',dynamicPlaceHolder: 'Search by' }
    ];
  }

  GetAllDelegations() {
    this.showLoading = true;
    this._facadeService.ActiveDelegations().subscribe(
      (data) => {
        this.delegateData = data.returnObject;
        this.delegateData=this.delegateData.filter(m=>m.skipApproval==false);
        console.log(JSON.stringify(this.delegateData));
        this.delegateData.forEach(element => {
          // element.fromDate= this.datePipe.transform(element.fromDate, 'MM/dd/yyyy');
          // element.toDate= this.datePipe.transform(element.toDate, 'MM/dd/yyyy');
          if(element.documentType!=null&&element.documentType!=""&&element.documentType!=undefined){
            element.role="--";
          }
          if(element.code!=null&&element.code!=""&&element.code!=undefined){
            element.role="--";
          }
        });
        this.CountData();
        this.showLoading = false;
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  // RowCancel(data){
  //   if(data.userDelegations!=null&&data.userDelegations.length>0){
  //     this.AlldeleteDisplay=true;
  //     this.usersdelegateData=data.userDelegations;
  //     this.usersdelegateData.forEach(element => {
  //       element.fromDate= this.datePipe.transform(element.fromDate, 'MM/dd/yyyy');
  //       element.toDate= this.datePipe.transform(element.toDate, 'MM/dd/yyyy');
  //     });
  //     this.selectedDelegates=[];
  //     this.AllAreYouSureText="";
  //   }
  //   else{
  //     this.deleteDisplay=true;
  //     this.delegationId=data.delegationId;
  //     this.Fromuser = data.userId;
  //      this.AreYouSureText = "Are you sure want to cancel the delegation to " + data.toUser + " ?";
  //   }
  // }
  CancelDelegation(){
    this.showLoading = true;
    this._facadeService.cancelDelegation(this.delegationId,this.Fromuser).subscribe(
      (data) => {
        this.deleteDisplay=false;
        this.showLoading = false;
        location.reload();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
  }

  AllCancelDelegation(){
    this.AllAreYouSureText="";
    if(this.selectedDelegates==null||this.selectedDelegates.length==0){
      this.AllAreYouSureText="Please select atleast one delegation to cancel.";
    }
    else{
      var el = [];
      var userid = 0;
      this.selectedDelegates.forEach(element => {
        el.push(element.delegationId);
        userid = element.userId;
      });

      this.showLoading = true;
    this._facadeService.cancelDelegations(userid,el).subscribe(
      (data) => {
        this.AlldeleteDisplay=false;
        this.showLoading = false;
        location.reload();
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      }
    );
    }
  }

  exportExcel() {
     /* table id is passed over here */   
     let element = document.getElementById('excel-table'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
     /* generate workbook and add the worksheet */
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     this.delete_row(wb.Sheets.Sheet1, 1);
     ws['!cols'] = [{ wpx : 120},{ wpx : 140 },{ wpx : 120},{ wpx : 120 },{ wpx : 80},{ wpx : 80 },{ wpx : 90},{ wpx : 80 },{ wpx : 30}];
     /* save to file */
     XLSX.writeFile(wb, "Delegations List.xlsx");
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  delete (ws['J1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

print() {
  var Content = document.getElementById('invoice-content').innerHTML;
  this.printService.Print(Content,"Delegations List",'trSearch',true);
}
  test(val,field,dt)
{
 if(field == "user")
    sessionStorage.user = val;
  else if(field == "toUser")
    sessionStorage.toUser = val;
  else if(field == "fromDate")
    sessionStorage.fromDate = val;
  else if(field == "toDate")
    sessionStorage.toDate = val;
  else if(field == "active")
    sessionStorage.active = val;
    else if(field == "code")
    sessionStorage.code = val;
    else if(field == "revision")
    sessionStorage.revision = val;
    else if(field == "documentType")
    sessionStorage.documentType = val;
    else if(field == "role")
    sessionStorage.role = val;
    else if(field == "toRole")
    sessionStorage.toRole = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.delegateData != undefined && this.delegateData != null)
  {
    var temp = this.delegateData;
    if(sessionStorage.user != ""&&sessionStorage.user != null)
    temp = temp.filter(x=> x.user.toLowerCase().includes(sessionStorage.user.toLowerCase()));
    if(sessionStorage.role != ""&&sessionStorage.role != null)
    temp = temp.filter(x=> x.role.toLowerCase().includes(sessionStorage.role.toLowerCase()));
    if(sessionStorage.toUser != ""&&sessionStorage.toUser != null)
    temp = temp.filter(x=> x.toUser.toLowerCase().includes(sessionStorage.toUser.toLowerCase()));
    if(sessionStorage.toRole != ""&&sessionStorage.toRole != null)
    temp = temp.filter(x=> x.toRole.toLowerCase().includes(sessionStorage.toRole.toLowerCase()));
    if(sessionStorage.fromDate != ""&&sessionStorage.fromDate != null)
    temp = temp.filter(x=> x.fromDate.toLowerCase().includes(sessionStorage.fromDate.toLowerCase()));
    if(sessionStorage.toDate != ""&&sessionStorage.toDate != null)
    temp = temp.filter(x=> x.toDate!=null&&x.toDate!=""&&x.toDate.toLowerCase().includes(sessionStorage.toDate.toLowerCase()));
    if(sessionStorage.active != ""&&sessionStorage.active != null)
    temp = temp.filter(x=> x.active.toLowerCase().includes(sessionStorage.active.toLowerCase()));
    if(sessionStorage.code != ""&&sessionStorage.code != null)
    temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != ""&&sessionStorage.revision != null)
    temp = temp.filter(x=> x.revision!=null&&x.revision!=""&&x.revision.toLowerCase().includes(sessionStorage.revision.toLowerCase()));
    if(sessionStorage.documentType != ""&&sessionStorage.documentType != null)
    temp = temp.filter(x=> x.documentType!=null&&x.documentType!=""&&x.documentType.toLowerCase().includes(sessionStorage.documentType.toLowerCase()));
    this.DocumentsCount = temp.length;
  }
  else
  this.DocumentsCount = 0;
}
UserDelegation(){
  sessionStorage.FromAdmin=true;
  location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'delegatetouser?action=listdelegate';
}
GetDoc(rowData){
  this.showLoading=true;
  sessionStorage.docId = rowData.documentID;
  location.href = this.baseUrl["API_ENDPOINTS"]["DMSUI"]+'docdetails?action=listdelegate';
}
CancelDelegateClick(){
  this.delegateError="";
  if(this.selectedDelegates.length==0){
    this.delegateError="Please select at least one to cancel the delegation.";
  }
  else{
    this.usersdelegateData=this.selectedDelegates;
    this.AlldeleteDisplay=true;
  }
}
ViewComment(comment){
  this.commentDisplay=true;
  this.commentText=comment;
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
