<div class="p-grid">
  <div class="p-col-12">
    <div class="card" style="min-height: 600px">
      <div class="card-header" style="margin-bottom: 5px;border-radius: 5px;width: 99.8%;height: 40px;">
        <h5 style="color: #495057; padding: 15px 0 0 15px">ECN Report</h5>      
      </div>
      <hr>
      <div class="p-fluid p-formgrid p-grid p-jc-center">
        <div class="p-field p-col-12 p-md-4">
          <label for="city">From Date</label>
          <span style="color: red">*</span>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="fromDate" (change)="fromDateChange()"></p-calendar>
          <small class="p-invalid">{{ fromError }}</small>
        </div>
        <div class="p-field p-col-12 p-md-4">
          <label for="city">To Date</label>
          <span style="color: red">*</span>
          <p-calendar [showIcon]="true" inputId="icon" [(ngModel)]="toDate" (change)="toDateChange()"></p-calendar>
          <small class="p-invalid">{{ toError }}</small>
        </div>
        <div class="p-field p-col-12 p-md-2">
          <span class="p-input-icon-left" style="float: right; margin-right: 5px; margin-top: 24px;width: 100%;">
            <button pButton type="button" label="ECN Report" iconPos="left" class="p-button-raised p-button-primary"
              icon="pi pi-search" style="margin-left: 5px; height: 35px" (click)="GetECNreport()"></button>
          </span>
        </div>
        <div class="p-field p-col-12 p-md-2"></div>
      </div>
      <div class="card-header" style="background-color: #d5e8ef;border-radius: 5px; width: 99.8%;height: auto;">
        <h5 style="color: #495057; padding: 15px 0 0 15px;width: 90%;"></h5>
        <span class="p-input-icon-left" style="float: right; margin-right: 5px; margin-top: 8px">
          <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
            (click)="print()" class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float: right; height: 25px; width: 25px"></button>
        </span>
      </div>
      <div class="invoice invoice-header" id="invoice-content" style="overflow-x: auto;">
        <!-- <p-table
          #dt
          [value]="ecnData"
          [columns]="cols"
          sortMode="multiple"
          class="table-align"
          [filterDelay]="0"
          selectionMode="multiple"
          dataKey="id"
          styleClass="p-datatable-striped"
          [globalFilterFields]="[
            'code',
            'Rev',
            'isECOExists',
            'title',
            'documentType',
            'owner',
            'status'
          ]"
        >
        <ng-template pTemplate="header" let-columns class="invoice-items">
          <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                  {{col.header}}
                  <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
              <th pSortableColumn="price" style="width:100px;">View</th>
          </tr>
          <tr>
              <th *ngFor="let col of columns" [ngSwitch]="col.field"
                  style="text-align: center;">
                  <input pInputText type="text" [placeholder]="col.dynamicPlaceHolder"
                      (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)"
                      style="margin: -10px 0 -10px 0; width:95%; height:25px;">
              </th>
              <th></th>
          </tr>
      </ng-template>
          <ng-template
            pTemplate="body"
            let-rowData
            let-columns="columns"
            let-docsData
          >
            <tr [pSelectableRow]="rowData">
              <ng-container
                *ngFor="let col of columns; let i = index"
                [ngSwitch]="col.field"
              >
                <td *ngIf="i == 0" style="text-align: left; padding-left: 15px">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 1" style="text-align: center; width: 100px">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 2" style="text-align: center; width: 100px">
                  {{ rowData[col.field] }}
                </td>
                <td
                  *ngIf="i == 3"
                  style="text-align: left; padding-left: 15px; width: 300px"
                >
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 4" style="text-align: left; padding-left: 15px">
                  {{ rowData[col.field] }}
                </td>
                <td *ngIf="i == 5" style="text-align: left; padding-left: 15px">
                  {{ rowData[col.field] }}
                </td>               
              </ng-container>
              <td>
                <button
                  pButton
                  type="button"
                  icon="pi pi-eye"
                  pTooltip="View"
                  class="p-button-rasised p-button-primary"
                  style="height: 25px; width: 25px"
                ></button>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="8" style="text-align: left">No documents found.</td>
            </tr>
          </ng-template>
        </p-table> -->
        <!-- <p-table [value]="ecnData">  commented by vijaya for build errors-->
        <p-table [value]="">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="code" style="width: 150px;">ECN NUMBER<p-sortIcon field="code"></p-sortIcon>
              </th>
              <th pSortableColumn="name" style="width: 160px;">Elapsed Days - Total <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th pSortableColumn="category" style="width: 180px;">Elapsed Days - Current Approver <p-sortIcon field="category"></p-sortIcon>
              </th>
              <th pSortableColumn="quantity" style="width: 160px;">Document Number <p-sortIcon field="quantity"></p-sortIcon>
              </th>
              <th pSortableColumn="price" style="width: 160px;">CreatedBy <p-sortIcon field="price"></p-sortIcon>
              </th>
              <th pSortableColumn="price" style="width: 160px;">Current Approver Name <p-sortIcon field="price"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td>{{ product.code }}</td>
              <td>{{ product.name }}</td>
              <td>{{ product.category }}</td>
              <td>{{ product.quantity }}</td>
              <td>{{ product.price | currency: "USD" }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<div style="
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 1000;
  " *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>