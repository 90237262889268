import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { FacadeService } from 'src/app/facade/facade.service';
import { BASE_API_URL_TOKEN } from 'src/app/injectors';

@Component({
  selector: 'app-roleaccessfolders',
  templateUrl: './roleaccessfolders.component.html',
  styleUrls: ['./roleaccessfolders.component.scss']
})
export class RoleaccessfoldersComponent implements OnInit {
  files:any=[];
  showLoading:boolean;
  headerName="Limited Access Documents";
  roles = sessionStorage.getItem('roles') != null && sessionStorage.getItem('roles') != undefined && sessionStorage.getItem('roles') != "" ? JSON.parse(sessionStorage.getItem('roles')):null;
  userId = parseInt(sessionStorage.getItem("userId"));
  userGuid = sessionStorage.getItem('userGuid');
  source="NA";
  textlabelheader="";
  addDisplay=false;
  isdisabled=true;
  TextLabel="";
  name="";
  description="";
  active:boolean;
  nameError="";
  responseMessage="";
  Id=0;
  FolderStructureId=0;
  isDoc:boolean;
  constructor(private _facadeService: FacadeService,
    @Inject(BASE_API_URL_TOKEN) public baseUrl: Object,
    private route:ActivatedRoute) { }

  ngOnInit(): void {
    var source=this.route.snapshot.paramMap.get('source');
    if(source=="admin"||source=="Admin"){
      this.source="A";
      this.headerName="Admin / Limited Access Documents";
      this.GetSmartFolders('A');
    }
    else{
      this.source="NA";
      this.headerName="Limited Access Documents";
      this.GetSmartFolders('NA');
    }
  }
  expandAll(){
    this.files.forEach( node => {
        this.expandRecursive(node, true);
    } );
}

collapseAll(){
    this.files.forEach( node => {
        this.expandRecursive(node, false);
    } );
}
private expandRecursive(node:TreeNode, isExpand:boolean){
  node.expanded = isExpand;
  if (node.children){
      node.children.forEach( childNode => {
          this.expandRecursive(childNode, isExpand);
      } );
  }
}

GetSmartFolders(type){
  this.showLoading = true;
  var roles = [];
  if(type=="NA"){
    if(this.roles != null && this.roles != undefined)
    {
      this.roles.forEach(element => {
        var role = {roleGuid:element.id}
        roles.push(role);
      });
    }
  }
  this._facadeService.LimitedAccessGroups(roles).subscribe(
    (data) => {
      var folderData=data.returnObject;
      folderData.forEach(element => {
        let childs =[];
        if(element.childers!=null&& element.childers.length>0){
          element.childers.forEach(element1 => {
            let subChilds= [];
            if(element1.childers!=null&&element1.childers.length>0){
              element1.childers.forEach(element3 => {
                let subsubChilds= [];
                if(element3.childers!=null&&element3.childers.length>0){
                  element3.childers.forEach(element4 => {
                    let subsubsubChilds=[];
                    if(element4.documents!=null&&element4.documents.length>0){
                      element4.documents.forEach(element5 => {
                        var child = {
                          label: element5.code+" : "+element5.title,
                          icon: 'pi pi-file-o',
                          type: 'url',
                          data:this.baseUrl["API_ENDPOINTS"]["DMSUI"] +
                          "entrypoint?userguid=" +
                          this.userGuid +
                          "&userId=" +
                          this.userId +
                          '&destination=docdetails'+"&docId="+element5.id,
                          fileName:element5.fileName,
                          userFileName:element5.userFileName
                      }
                      subsubsubChilds.push(child);
                      });
                    }
                    var file = {
                      key: element4.id,
                      label: element4.folderName+" - "+element4.count,
                      roleGroups : element4.roleGroups,
                      collapsedIcon: 'pi pi-folder',
                      expandedIcon: 'pi pi-folder-open',
                      children: subsubsubChilds,
                      expanded: false,
                    }
                    subsubChilds.push(file);
                  });
                }
                if(element3.documents!=null&&element3.documents.length>0){
                  element3.documents.forEach(element2 => {
                    var child = {
                      label: element2.code+" : "+element2.title,
                      icon: 'pi pi-file-o',
                      type: 'url',
                      data:this.baseUrl["API_ENDPOINTS"]["DMSUI"] +
                      "entrypoint?userguid=" +
                      this.userGuid +
                      "&userId=" +
                      this.userId +
                      '&destination=docdetails'+"&docId="+element2.id,
                      fileName:element2.fileName,
                      userFileName:element2.userFileName
                  }
                  subsubChilds.push(child);
                  });
                }
                var file = {
                  key: element3.id,
                  label: element3.folderName+" - "+element3.count,
                  roleGroups : element3.roleGroups,
                  collapsedIcon: 'pi pi-folder',
                  expandedIcon: 'pi pi-folder-open',
                  children: subsubChilds,
                  expanded: false,
                  type:element3.subFol1==true?"subfol1":"",
                  isDocType:element1.isDocType,
                  mainFolderId:element1.mainFolderId
                }
                subChilds.push(file);
              });
            }
            if(element1.documents!=null&&element1.documents.length>0){
              element1.documents.forEach(element2 => {
                var child = {
                  label: element2.code+" : "+element2.title,
                  icon: 'pi pi-file-o',
                  type: 'url',
                  data:this.baseUrl["API_ENDPOINTS"]["DMSUI"] +
                  "entrypoint?userguid=" +
                  this.userGuid +
                  "&userId=" +
                  this.userId +
                  '&destination=docdetails'+"&docId="+element2.id,
                  fileName:element2.fileName,
                      userFileName:element2.userFileName
              }
              subChilds.push(child);
              });
            }
            var file = {
              key: element1.id,
              label: element1.folderName+" - "+element1.count,
              roleGroups : element1.roleGroups,
              collapsedIcon: 'pi pi-folder',
              expandedIcon: 'pi pi-folder-open',
              children: subChilds,
              expanded: false,
              type:element1.subFol==true?"subfol":"",
              isDocType:element1.isDocType,
              mainFolderId:element1.mainFolderId
            }
            childs.push(file);
          });
        }
        if(element.documents!=null&&element.documents.length>0){
          element.documents.forEach(element1 => {
            var child = {
              label: element1.code+" : "+element1.title,
              icon: 'pi pi-file-o',
              type: 'url',
              data:this.baseUrl["API_ENDPOINTS"]["DMSUI"] +
              "entrypoint?userguid=" +
              this.userGuid +
              "&userId=" +
              this.userId +
              '&destination=docdetails'+"&docId="+element1.id,
              fileName:element1.fileName,
                      userFileName:element1.userFileName
          }
          childs.push(child);
          });
        }
        var file = {
          key: element.id,
          label: element.folderName+" - "+element.count,
          roleGroups : element.roleGroups,
          collapsedIcon: 'pi pi-folder',
          expandedIcon: 'pi pi-folder-open',
          children: childs,
          expanded: false,
          type: 'urlplus',
          isDocType:element.isDocType
        }
        this.files.push(file);
        
      });
      this.showLoading = false;
    },
    (error) => {
      this.showLoading = false;
      console.log(error);
    }
  );
}

  DMSToken(urlfile){
    this.showLoading = true;
    this._facadeService.CreateSecurityToken().subscribe(
      (data) => {
        this.showLoading = false;
        if(data.returnObject!=null&&data.returnObject!=""&&data.returnObject!=undefined){
          var access_token = data.returnObject;
          urlfile = urlfile+'&access_token='+access_token;
          window.open(urlfile);
        }
        else{
          location.href = this.baseUrl["API_ENDPOINTS"]["UMSUI"];
        }
      },
      (error) => {
        this.showLoading = false;
        console.log(error);
      } 
    );
  }
  EditFolder(key,isDocType,type,mainFolderId,node){
    if(isDocType){
      //DMS
      if(type == "ME")
        this.textlabelheader="Edit DCC Folder";
      else
      {
        // var a = this.files.filter(x=>x.)
        this.textlabelheader="Edit " + node.parent.label + " Level 1 Folder";
      }
      this.isDoc=true;
      this.EditDocType(key,mainFolderId,type)
    }
    else{
      //Prod
      if(type == "ME")
        this.textlabelheader="Edit Product Folder";
      else
        this.textlabelheader="Edit " + node.parent.label + " Level 1 Folder";
      this.isDoc=false;
      this.EditProduct(key,mainFolderId,type);
    }
  }
  EditDocType(key,mainFolderId,type){
    this.TextLabel="Update";
    this.name="";
    this.description="";
    this.nameError="";
    this.active=true;
    this.isdisabled=false;
    this.Id=key;
    this.FolderStructureId=mainFolderId;
    this.responseMessage="";
    this.showLoading = true;
    if(type=="SSE"){
      this._facadeService.GetSubSubDocTypeFolderStructureDetailsById(this.Id).subscribe(
        (data) => {
          var prod=data.returnObject;
          this.name=prod.name;
          this.description=prod.description;
          this.active=prod.active;
          this.Id=prod.id;
          this.FolderStructureId=prod.docTypeFolderStructureId;
          this.addDisplay=true;
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      if(this.FolderStructureId>0){
        this._facadeService.GetSubDocTypeFolderStructureDetailsById(this.Id).subscribe(
          (data) => {
            var prod=data.returnObject;
            this.name=prod.name;
            this.description=prod.description;
            this.active=prod.active;
            this.Id=prod.id;
            this.FolderStructureId=prod.docTypeFolderStructureId;
            this.addDisplay=true;
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else{
        this._facadeService.GetDocTypeFolderStructureDetailsById(this.Id).subscribe(
          (data) => {
            var prod=data.returnObject;
            this.name=prod.name;
            this.description=prod.description;
            this.active=prod.active;
            this.Id=prod.id;
            this.FolderStructureId=prod.docTypeFolderStructureId;
            this.addDisplay=true;
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
    
   

  }
  EditProduct(key,mainFolderId,type){
    this.TextLabel="Update";
    this.name="";
    this.description="";
    this.nameError="";
    this.active=true;
    this.isdisabled=false;
    this.Id=key;
    this.FolderStructureId=mainFolderId;
    this.responseMessage="";
    this.showLoading = true;
    if(type=="SSE"){
      this._facadeService.GetSubSubProductFolderStructureDetailsById(this.Id).subscribe(
        (data) => {
          var prod=data.returnObject;
          this.name=prod.name;
          this.description=prod.description;
          this.active=prod.active;
          this.Id=prod.id;
          this.FolderStructureId=prod.productFolderStructureId;
          this.addDisplay=true;
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      if(this.FolderStructureId>0){
        this._facadeService.GetSubProductFolderStructureDetailsById(this.Id).subscribe(
          (data) => {
            var prod=data.returnObject;
            this.name=prod.name;
            this.description=prod.description;
            this.active=prod.active;
            this.Id=prod.id;
            this.FolderStructureId=prod.productFolderStructureId;
            this.addDisplay=true;
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else{
        this._facadeService.GetProductFolderStructureDetailsById(this.Id).subscribe(
          (data) => {
            var prod=data.returnObject;
            this.name=prod.name;
            this.description=prod.description;
            this.active=prod.active;
            this.Id=prod.id;
            this.FolderStructureId=prod.productFolderStructureId;
            this.addDisplay=true;
            this.showLoading = false;
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
   
  }
  CreateFolder(key,isDocType,name){
    this.addDisplay=true;
    this.TextLabel="Save";
    this.textlabelheader="Add";
    this.name="";
    this.description="";
    this.nameError="";
    this.active=true;
    this.isdisabled=true;
    this.Id=0;
    this.responseMessage="";
    this.FolderStructureId=key;
    this.addDisplay=true;
    this.textlabelheader="Add "+ name +" Level 1 Folder";
    if(isDocType)
      this.isDoc=true;
    else
      this.isDoc=false;
  }
  SaveUpdate(){
    if(this.isDoc)
      this.SaveUpdateDocType();
    else
      this.SaveUpdateProduct();
  }
  SaveUpdateDocType(){
    this.nameError="";
    this.responseMessage="";
    if(this.name==""||this.name==null||this.name==undefined)
      this.nameError="Please enter name";
    else{
      this.showLoading = true;
      var model={
        id:this.Id,
        Name:this.name,
        Description:this.description,
        active:this.active,
        DocTypeFolderStructureId:this.FolderStructureId
      }
      if(this.FolderStructureId>0){
        this._facadeService.CreateUpdateSubDocTypeFolderStructure(model).subscribe(
          (data) => {
            if(data.returnCode==0)
              location.reload();
            else {
              this.responseMessage = data.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else{
        this._facadeService.CreateUpdateDocTypeFolderStructure(model).subscribe(
          (data) => {
            if(data.returnCode==0)
              location.reload();
            else {
              this.responseMessage = data.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
   
  }
  SaveUpdateProduct(){
    this.nameError="";
    this.responseMessage="";
    if(this.name==""||this.name==null||this.name==undefined){
      this.nameError="Please enter name";
    }
    else{
      this.showLoading = true;
      var model={
        id:this.Id,
        Name:this.name,
        Description:this.description,
        active:this.active,
        productFolderStructureId:this.FolderStructureId
      }
      if(this.FolderStructureId>0){
        this._facadeService.CreateUpdateSubProductFolderStructure(model).subscribe(
          (data) => {
            if(data.returnCode==0)
              location.reload();
            else {
              this.responseMessage = data.returnMessage;
              this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
      else{
        this._facadeService.CreateUpdateProductFolderStructure(model).subscribe(
          (data) => {
            if(data.returnCode==0)
              location.reload();
            else {
             this.responseMessage = data.returnMessage;
             this.showLoading = false;
            }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
      }
    }
   
  }
  AddNewButton(type){
    this.addDisplay=true;
    this.name="";
    this.description="";
    this.nameError="";
    this.active=true;
    this.isdisabled=true;
    this.Id=0;
    this.responseMessage="";
    this.TextLabel="Save";
    this.FolderStructureId=0;
    if(type=='p'){
      this.textlabelheader="Add Product Folder";
      this.isDoc=false;
    }
    else{
      this.textlabelheader="Add DCC Folder";
      this.isDoc=true;
    }
  }

  GetFile(fileName,userFileName) {
    if (fileName != null && fileName != "" && fileName!= undefined) {
      this.showLoading = true;
      var newname = userFileName.substr(0, userFileName.lastIndexOf('.'));
      this.Download(fileName, newname, userFileName);
    }
  }
  Download(filename, newname, userfilename) {
    this._facadeService.downloadFile(filename, newname).subscribe(response => {
      response.filename = userfilename;
      let url = window.URL.createObjectURL(response);
      var anchor = document.createElement("a");
      anchor.download = userfilename;
      anchor.href = url;
      anchor.click();
      anchor.remove();
      // window.open(url, '_blank').focus();
      this.showLoading = false;
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');

  }
}

