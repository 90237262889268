<div class="p-grid p-fluid">
    <div class="p-col-12">
        <div class="card" style="min-height:600px;">
<div class="card-header" style="background-color: #d5e8ef; border-radius: 5px; width:99.8%;height:40px;">
    <h5 style="color:#495057; padding:15px 0 0 15px;width: 80%;">Access to Product Folders</h5>
    <span class="p-input-icon-left" style="float:right; margin-right:5px;margin-top:8px;">
        <button pButton pRipple type="button" icon="pi pi-print" pTooltip="Print" tooltipPosition="top"
            class="p-button-rounded p-button-warning p-mr-2 p-mb-2"
            style="float:right;height:25px; width:25px;" (click)="print()"></button>
        <button pButton pRipple type="button" icon="pi pi-download" pTooltip="Download"
            tooltipPosition="top" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2"
            style="float:right; height:25px; width:25px;" (click)="export()"></button>
    </span>
</div>
<div class="invoice invoice-header" id="invoice-content">
    <p-table #dt [value]="permissionData" id="excel-Departments" [columns]="cols" sortMode="multiple" [resizableColumns]="true" [autoLayout]="true"
        class="table-align" styleClass="p-datatable-striped" [filterDelay]="0" selectionMode="multiple"
        dataKey="taskGroupId">
        <ng-template pTemplate="header" let-columns class="invoice-items">
            <tr>
                <th style="width: 60px"></th>
                <th *ngFor="let col of columns;let i = index" class="rotate" style="height:230px;">
                    <div *ngIf="i == 0" style=" transform: translate3d(30px, 1px, 0) rotate(0deg);padding-bottom: 15px;"><span >{{ col.header }}</span></div>
                    <div *ngIf="i != 0"><span>{{ col.header }}</span></div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns"  let-expanded="expanded">
            <tr [pSelectableRow]="rowData">
                <td style="width:60px;">
                    <button *ngIf="rowData.subFolders!=null&&rowData.subFolders.length>0" type="button" pButton pRipple [pRowToggler]="rowData" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <ng-container *ngFor="let col of columns; let i = index" [ngSwitch]="col.field">
                    <td *ngIf="i == 0" style="text-align: left;padding-left:15px;width: 250px;">
                        {{ rowData[col.field] }} 
                    </td>
                </ng-container> 
                <ng-container *ngFor="let row of rowData.information">
                    <td style="text-align: left; padding-top: 8px;" [ngClass]="row.Edit ? 'red' : ''">
                        <p-inputSwitch [(ngModel)]="row.selectedRole"  (onChange)="toggleActivation(row,rowData)"></p-inputSwitch>
                    </td>
                </ng-container> 
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-rowData>
            <tr>
                <td style="padding-left: 20px;" [attr.colspan]="rowData.information.length + 2">
                    <p-table [value]="rowData.subFolders" dataKey="subFolderId">
                            <ng-template pTemplate="body" let-subfolder let-expanded="expanded">
                                <tr>
                                    <td style="width:40px;">
                                        <button *ngIf="subfolder.subSubFolders!=null&&subfolder.subSubFolders.length>0" type="button" pButton pRipple [pRowToggler]="subfolder" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                    </td>
                                    <td style="text-align: left;padding-left:15px;width: 250px;">{{subfolder.name}}</td>
                                    <ng-container *ngFor="let row of subfolder.information">
                                        <td style="text-align: left; padding-top: 8px;" [ngClass]="row.Edit ? 'red' : ''">
                                            <p-inputSwitch [(ngModel)]="row.selectedRole"  (onChange)="toggleActivation1(row,subfolder)"></p-inputSwitch>
                                        </td>
                                    </ng-container> 
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="rowexpansion" let-subfolder>
                                <tr>
                                    <td [attr.colspan]="rowData.information.length + 2" style="padding-left: 20px;" >
                                        <p-table [value]="subfolder.subSubFolders" dataKey="subSubFolderId">
                                            <ng-template pTemplate="body" let-subsubfolder>
                                                <tr>
                                                    <td style="text-align: left;padding-left:50px;width: 270px;white-space: pre-wrap;">{{subsubfolder.name}}</td>
                                                    <ng-container *ngFor="let row of subsubfolder.information">
                                                        <td style="text-align: left;padding-top: 8px;" [ngClass]="row.Edit ? 'red' : ''">
                                                            <p-inputSwitch [(ngModel)]="row.selectedRole"  (onChange)="toggleActivation2(row,subsubfolder)"></p-inputSwitch>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                                
                                            </ng-template>
                                        </p-table>
                                    </td>
                                </tr>
                            </ng-template>
                    </p-table>
                </td>
            </tr>
           
        </ng-template>
        
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="1" style="text-align: left;">No records found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div class="p-field p-col-12">
    <hr />
    <div class="p-grid">
        <div class="p-field p-col-12 p-lg-9 p-md-12 p-sm-12"></div>
        <div class="p-field p-col-12 p-lg-3 p-md-12 p-sm-12" style="text-align: right;">
        <br />
        <button pButton pRipple type="button" icon="pi pi-check" iconPos="left" label="Save"
            class="p-button-raised p-button-success p-mr-2 p-mb-2" style="width: 100px;" (click)="SavePermissions()"></button> &nbsp;&nbsp;
            <button pButton pRipple type="button" icon="pi pi-times" iconPos="left" label="Cancel"
            class="p-button-raised p-button-secondary p-mr-2 p-mb-2" style="width: 100px;" (click)="cancel()"></button>
        </div>
        <small class="p-invalid" style="float: right">{{responseError}}</small>
    </div>
  </div>
        </div>
       
    </div>
</div>

  <div style="
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.5;
    z-index: 1500;
  " *ngIf="showLoading">
  <i class="pi pi-spin pi-spinner" style="position: fixed; top: 50%; left: 50%; font-size: 5rem; color: #fff"></i>
</div>