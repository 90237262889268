import { Injectable } from '@angular/core';
import { HttputilityService } from "../services/httputility.service";

@Injectable({
  providedIn: 'root'
})
export class ArchivalService {

  constructor(private _httputilityService: HttputilityService) { }

  //baseUrl: any = "http://132.148.242.138/FPDMS/api/";

  archivalData(model) {
    return this._httputilityService.Add("MyDocuments", model);
  }

  GetExpiredDocsById(userId) {
    return this._httputilityService.Get('GetExpiredDocs?userId=' + userId);
  }

  GetArchivedDocsById(userId) {
    return this._httputilityService.Get('GetArchivedDocs?userId=' + userId);
  }

  ArchiveDocs(uId,model) {
    return this._httputilityService.Add('ArchiveDocs?userId=' + uId ,model);
  }
}
