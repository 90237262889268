<div class="p-grid">
    <div class="p-col-12">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-col-12">
                <div class="card" style="min-height:600px;">
                    <h4>Create Workflow Setup</h4>
                    <hr />
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3"></div>
                        <div class="p-field p-col-12 p-md-6">
                            <br>
                            <label for="Department">Document Type<span style="color: red">*</span></label>
                            <p-dropdown inputId="documentType" [options]="docsTypesData" [(ngModel)]="selectedDocType"
                                placeholder="Select" optionLabel="documentType">
                            </p-dropdown>
                            <small class="p-invalid">{{ docTypeError }}</small>
                            <br><br>
                            <label for="Department">Workflow<span style="color: red">*</span></label>
                            <p-dropdown inputId="workflowDefinitionId" [options]="workflowdefinitions"
                                [(ngModel)]="selectedWorkflow" placeholder="Select"
                                optionLabel="name">
                            </p-dropdown>
                            <small class="p-invalid">{{ workFlowError }}</small>
                            <br>
                        </div>
                        <div class="p-field p-col-12 p-md-3">
                        </div>
                    </div>
                    <div class="p-fluid p-formgrid p-grid">
                        <div class="p-field p-col-12 p-md-3"> </div>
                        <div class="p-field p-col-12 p-md-2"> </div>
                        <div class="p-field p-col-12 p-md-2">
                            <br>
                            <button pButton pRipple type="button" icon="pi pi-check" label="Submit"
                            (click)="createWokrflowSetUp()" class="p-button-raised p-button-success p-mr-2 p-mb-2" 
                            style="float:right; width: 100%"></button>
                        </div>
                        <div class="p-field p-col-12 p-md-2">
                            <br>
                            <button pButton pRipple type="button" (click)="resetCtrls()" icon="pi pi-refresh" label="Reset"
                                class="p-button-raised p-button-secondary p-mr-2 p-mb-2"
                                style="float:right; margin-left:10px ; width: 100%;"></button>
                        </div>
                        <div class="p-field p-col-12 p-md-3"> </div>
                        <small class="p-invalid" style="float:right;" >{{ responseError }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="position: fixed;left: 0;top: 0;width: 100%;height: 100%;background-color: #000000;opacity: 0.5;z-index: 1000;" *ngIf="showLoading">
    <i class="pi pi-spin pi-spinner" style="position: fixed;top:50%; left:50%; font-size: 5rem; color:#fff;"></i>
  </div>