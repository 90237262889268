import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppRightmenuComponent } from './app.rightmenu.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppSearchComponent } from './app.search.component';
import { AppFooterComponent } from './app.footer.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { MenuService } from './app.menu.service';
import { LoginComponent } from './components/login/login.component';
import { FacadeService } from './facade/facade.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ApprovalsneededComponent } from './components/approvalsneeded/approvalsneeded.component';
import { MydocsComponent } from './components/mydocs/mydocs.component';
import { HolddocsComponent } from './components/holddocs/holddocs.component';
import { WorkflowsComponent } from './components/workflows/workflows.component';
import { AdminComponent } from './components/admin/admin.component';
import { CreatenewdocComponent } from './components/createnewdoc/createnewdoc.component';
import { DocdetailsComponent } from './components/docdetails/docdetails.component';
import { DatePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateProfileComponent } from './components/workflows/create-profile/create-profile.component';
import { CreateWorkflowsetupComponent } from './components/workflows/create-workflowsetup/create-workflowsetup.component';
import { WfsetupComponent } from './components/workflows/wfsetup/wfsetup.component';
import { WfprofileComponent } from './components/workflows/wfprofile/wfprofile.component';
import { DocTypesComponent } from './components/admin/doc-types/doc-types.component';
import { PrintService } from './services/print.service';
import { CreateversionComponent } from './components/createversion/createversion.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { EcoComponent } from './components/eco/eco.component';
import { ListstandardComponent } from './components/liststandard/liststandard.component';
import { DelegationComponent } from './components/delegation/delegation.component';
import { CreatestandardComponent } from './components/createstandard/createstandard.component';
import { CreateclauseComponent } from './components/createclause/createclause.component';
import { NewProfileComponent } from './components/workflows/new-profile/new-profile.component';
import { TaskAssignmentComponent } from './components/reports/task-assignment/task-assignment.component';
import { DocumentSearchComponent } from './components/reports/document-search/document-search.component';
//import { SecurityComponent } from './components/security/security.component';
import { AddProfileComponent } from './components/workflows/add-profile/add-profile.component';
import { ArchiveComponent } from './components/archive/archive.component';
import { DocumenttypesComponent } from './components/documenttypes/documenttypes.component';
import { ListdelegateComponent } from './components/listdelegate/listdelegate.component';
import { ApprovalsDelegatedComponent } from './components/approvals-delegated/approvals-delegated.component';
import { EcnReportsComponent } from './components/reports/ecn-reports/ecn-reports.component';
import { ByprojectComponent } from './components/byproject/byproject.component';
import { OriginatedbystatusComponent } from './components/originatedbystatus/originatedbystatus.component';
import { PendingforapprovalComponent } from './components/pendingforapproval/pendingforapproval.component';
import { OriginatedreportComponent } from './components/originatedreport/originatedreport.component';
import { SignoffdocumentComponent } from './components/signoffdocument/signoffdocument.component';
import { PublishedneededComponent } from './components/publishedneeded/publishedneeded.component';
import { MyreviewneededComponent } from './components/reports/myreviewneeded/myreviewneeded.component';
import { AltDocidReportComponent } from './components/reports/alt-docid-report/alt-docid-report.component';
import { UserdelegationsComponent } from './components/userdelegations/userdelegations.component';
import { EntrypointComponent } from './components/entrypoint/entrypoint.component';
import { HelpguideComponent } from './components/helpguide/helpguide.component';
import { EmailnotificationComponent } from './components/emailnotification/emailnotification.component';
import { DelegatetouserComponent } from './components/delegatetouser/delegatetouser.component';
import { WorkflowlistComponent } from './components/workflows/workflowlist/workflowlist.component';
import { WorkflowdesignComponent } from './components/workflows/workflowdesign/workflowdesign.component';
import { TokenInterceptor } from "./token.interceptor";
import { SkipapprovallistComponent } from './components/skipapprovallist/skipapprovallist.component';
import { SkipapprovalComponent } from './components/skipapproval/skipapproval.component';
import { ObsoletedocumentComponent } from './components/obsoletedocument/obsoletedocument.component';
import { DccobsoletedocumentComponent } from './components/dccobsoletedocument/dccobsoletedocument.component';
import { DccfolderpermissionComponent } from './components/dccfolderpermission/dccfolderpermission.component';
import { DoctypedccfolderComponent } from './components/doctypedccfolder/doctypedccfolder.component';
import { DoctypefolderstructureComponent } from './components/doctypefolderstructure/doctypefolderstructure.component';
import { ProductfolderstructureComponent } from './components/productfolderstructure/productfolderstructure.component';
import { RoleaccessfoldersComponent } from './components/roleaccessfolders/roleaccessfolders.component';
import { RolegroupComponent } from './components/rolegroup/rolegroup.component';
import { TaskfolderpermissionComponent } from './components/taskfolderpermission/taskfolderpermission.component';
import { TaskproductfolderComponent } from './components/taskproductfolder/taskproductfolder.component';
import { DocumentoriginatorComponent } from './components/documentoriginator/documentoriginator.component';
import { PrintablereportComponent } from './components/printablereport/printablereport.component';
import { ProductfoldersComponent } from './components/productfolders/productfolders.component';
import { DccfoldersComponent } from './components/dccfolders/dccfolders.component';
import { BnNgIdleService } from './bn-ng-idle.service';
import { AlldccfoldersComponent } from './components/alldccfolders/alldccfolders.component';
import { AllproductfoldersComponent } from './components/allproductfolders/allproductfolders.component';
import { AllprodutsComponent } from './components/allproduts/allproduts.component';


@NgModule({
  imports: [
    BrowserModule /* or CommonModule */,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    SelectButtonModule,
    SidebarModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    AppCodeModule,
    ProgressSpinnerModule,
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppRightmenuComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppConfigComponent,
    AppTopBarComponent,
    AppSearchComponent,
    AppFooterComponent,
    LoginComponent,
    DashboardComponent,
    ApprovalsneededComponent,
    MydocsComponent,
    HolddocsComponent,
    WorkflowsComponent,
    AdminComponent,
    CreatenewdocComponent,
    DocdetailsComponent,
    CreateProfileComponent,
    CreateWorkflowsetupComponent,
    WfsetupComponent,
    WfprofileComponent,
    DocTypesComponent,
    CreateversionComponent,
    EcoComponent,
    ListstandardComponent,
    DelegationComponent,
    CreatestandardComponent,
    CreateclauseComponent,
    NewProfileComponent,
    TaskAssignmentComponent,
    DocumentSearchComponent,
    //SecurityComponent,
    AddProfileComponent,
    ArchiveComponent,
    DocumenttypesComponent,
    ListdelegateComponent,
    ApprovalsDelegatedComponent,
    EcnReportsComponent,
    ByprojectComponent,
    OriginatedbystatusComponent,
    PendingforapprovalComponent,
    OriginatedreportComponent,
    SignoffdocumentComponent,
    PublishedneededComponent,
    MyreviewneededComponent,
    AltDocidReportComponent,
    UserdelegationsComponent,
    EntrypointComponent,
    HelpguideComponent,
    EmailnotificationComponent,
    DelegatetouserComponent,
    WorkflowlistComponent,
    WorkflowdesignComponent,
    SkipapprovallistComponent,
    SkipapprovalComponent,
    ObsoletedocumentComponent,
    DccobsoletedocumentComponent,
    DccfolderpermissionComponent,
    DoctypedccfolderComponent,
    DoctypefolderstructureComponent,
    ProductfolderstructureComponent,
    RoleaccessfoldersComponent,
    RolegroupComponent,
    TaskfolderpermissionComponent,
    TaskproductfolderComponent,
    DocumentoriginatorComponent,
    PrintablereportComponent,
    ProductfoldersComponent,
    DccfoldersComponent,
    AlldccfoldersComponent,
    AllproductfoldersComponent,
    AllprodutsComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    MenuService,
    BreadcrumbService,
    FacadeService,
    DatePipe,
    PrintService,
    BnNgIdleService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
