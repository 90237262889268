import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MegaMenuItem, MenuItem, SortEvent } from 'primeng/api';
import { mydocumentsModel } from '../../models/mydocuments_model';
import { FacadeService } from '../../facade/facade.service';
import { holddocumentsModel } from 'src/app/models/holddocuments_model';
import { approvalNeededModel } from 'src/app/models/approvalneeded_model';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import {SelectItem} from 'primeng/api';
import * as _ from "lodash";
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../services/print.service";

@Component({
  selector: 'app-mydocs',
  templateUrl: './mydocs.component.html',
  styleUrls: ['./mydocs.component.scss'],
})
export class MydocsComponent implements OnInit {
  statusdocArray: any;
  StateMode: string;
  constructor(
    private _facadeService: FacadeService,public printService: PrintService,
    private datePipe: DatePipe,
    private router: Router,
    private route:ActivatedRoute,private cdr: ChangeDetectorRef
  ) {}
  source : any;
  userId = parseInt(sessionStorage.getItem('userId'));
  tileStatus= this.route.snapshot.queryParamMap.get('TileStatus')
  docsData: mydocumentsModel[];
  myDocsMode: any;
  inprocessDocsMode: any;
  hold: holddocumentsModel[];
  holdDocsMode: any;
  approvedDocsMode: any;
  archivedDocsMode: any;
  isArchivedstatus: any;
  //documentStatus: string = 'New';
  documentStatus: string;
  today: string;
  status: SelectItem[];
  selectedList: SelectItem;
DocumentsCount=0;
  selectedStatus=[];
  documentsName: string;
  cols: any[];
  @ViewChild('dt') table: Table;
  dynamicPlaceHolder: string;
  showLoading:boolean;
  CreateNewDocSH:boolean=true;
  Label:string="";
  sumstatus = sessionStorage.getItem('sumstatus')!=null&&sessionStorage.getItem('sumstatus')!=""&&sessionStorage.getItem('sumstatus')!=undefined?JSON.parse(sessionStorage.getItem('sumstatus')):[];
  ngOnInit(): void {
    sessionStorage.ecrn = "";
      sessionStorage.code ="";
      sessionStorage.revision = "";
      sessionStorage.eco = "";
      sessionStorage.title ="";
      sessionStorage.departmentName = "";
      sessionStorage.owner = "";
      sessionStorage.status = "";
      sessionStorage.lastAccessedDate = "";
    this.source=this.route.snapshot.paramMap.get('source');
    this.StateMode=this.route.snapshot.paramMap.get('StateMode');
    if(this.source=="Admin")
    this.Label = "All Documents";
    else 
    this.Label = "My Documents";
    if(this.source=="Admin"){
      this.CreateNewDocSH= false;
      this.status = [ 
        {label: 'New', value: {id: 1, name: 'New', code: 'New'}},
        {label: 'In-Process', value: {id: 2, name: 'In-Process', code: 'In-Process'}},
        {label: 'Hold', value: {id: 3, name: 'Hold', code: 'Hold'}},
        {label: 'Approved', value: {id: 4, name: 'Approved', code: 'Approved'}},
        {label: 'Rejected', value: {id: 5, name: 'Rejected', code: 'Rejected'}},
        {label: 'Withdrawn', value: {id: 6, name: 'Withdrawn', code: 'Withdrawn'}},
        {label: 'Expired', value: {id: 7, name: 'Expired', code: 'Expired'}},
        {label: 'Archived', value: {id: 8, name: 'Archived', code: 'Archived'}},
        {label: 'Obsoleted', value: {id: 9, name: 'Obsoleted', code: 'Obsoleted'}},
        {label: 'Obsolete In-Process', value: {id: 10, name: 'Obsolete In-Process', code: 'Obsolete In-Process'}},
        {label: 'Obsolete Rejected', value: {id: 11, name: 'Obsolete Rejected', code: 'Obsolete Rejected'}}
    ];
    if(this.sumstatus.length>0){
      this.selectedStatus=this.sumstatus;
    }
   else{
    this.selectedStatus.push(this.status[0].value,this.status[1].value,this.status[2].value,this.status[3].value,this.status[4].value,this.status[5].value);
   }
  this.documentsName="All Search Documents"; 
  }
    else{
      this.getDocsTypes();
      this.status = [ 
        {label: 'New', value: {id: 1, name: 'New', code: 'New'}},
        {label: 'In-Process', value: {id: 2, name: 'In-Process', code: 'In-Process'}},
        {label: 'Hold', value: {id: 3, name: 'Hold', code: 'Hold'}},
        {label: 'Approved', value: {id: 4, name: 'Approved', code: 'Approved'}},
        {label: 'Rejected', value: {id: 5, name: 'Rejected', code: 'Rejected'}},
        {label: 'Withdrawn', value: {id: 6, name: 'Withdrawn', code: 'Withdrawn'}},
        {label: 'Expired', value: {id: 7, name: 'Expired', code: 'Expired'}},
        {label: 'Archived', value: {id: 8, name: 'Archived', code: 'Archived'}},
        {label: 'Delegated', value: {id: 9, name: 'Delegated', code: 'Delegated'}},
        {label: 'Obsoleted', value: {id: 10, name: 'Obsoleted', code: 'Obsoleted'}},
        {label: 'Obsolete In-Process', value: {id: 11, name: 'Obsolete In-Process', code: 'Obsolete In-Process'}},
        {label: 'Obsolete Rejected', value: {id: 12, name: 'Obsolete Rejected', code: 'Obsolete Rejected'}}
    ];
   
    this.documentsName="My Documents";
    if(this.tileStatus!='' && this.tileStatus!=undefined){
      var filteredStatus = this.status.find(m=>m.label==this.tileStatus);
      this.selectedStatus.push(filteredStatus.value);
    }
    else if(this.sumstatus.length>0){
      this.selectedStatus=this.sumstatus;
    }
    else{
      this.selectedStatus.push(this.status[3].value);
    }
    }
    
    if(this.source !== null && this.source !== 'Admin')
    {
      var sourcecopy = this.status.find(m=>m.label===this.source);
      if(sourcecopy != null && sourcecopy != undefined)
        this.source = sourcecopy.value;
    }
    if(this.StateMode !== null && this.source === 'Admin')
    {
       var sourcecopy = this.status.find(m=>m.label===this.StateMode);
       if(sourcecopy != null && sourcecopy != undefined)
        this.StateMode = sourcecopy.value; 
    }
   
    
    console.log(' this.selectedStatus---', this.selectedStatus);
    this.mydocumentsData();

    this.cols = [
      { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Document Id' },
      { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Rev' },
      { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'ECO' },
      { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'ECRN' },
      { field: 'title', header: 'Title', dynamicPlaceHolder: 'Title' },
      { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
      { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Originator' },
      { field: 'status', header: 'Status', dynamicPlaceHolder: 'Status' },
      { field: 'lastAccessedDate', header: 'Date', dynamicPlaceHolder: 'Date' },
    ];
  }
  
  
  mydocumentsData() {
    this.isArchivedstatus = 'N';
    if(this.selectedStatus !=null && this.selectedStatus.length>0){
      var status = this.selectedStatus.find(m=>m.code=="New"||m.code=="In-Process"||m.code=='Obsolete In-Process');
      if(status!=null){
        this.cols = [
          { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Document Id' },
          { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Rev' },
          { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'ECO' },
          { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'ECRN' },
          { field: 'title', header: 'Title', dynamicPlaceHolder: 'Title' },
          { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
          { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Originator' },
          { field: 'status', header: 'Status', dynamicPlaceHolder: 'Status' },
        ];
      }
      else{
        this.cols = [
          { field: 'code', header: 'Document Id', dynamicPlaceHolder: 'Document Id' },
          { field: 'revision', header: 'Rev', dynamicPlaceHolder: 'Rev' },
          { field: 'eco', header: 'ECO', dynamicPlaceHolder: 'ECO' },
          { field: 'ecrn', header: 'ECRN', dynamicPlaceHolder: 'ECRN' },
          { field: 'title', header: 'Title', dynamicPlaceHolder: 'Title' },
          { field: 'departmentName', header: 'Department', dynamicPlaceHolder: 'Department' },
          { field: 'owner', header: 'Originator', dynamicPlaceHolder: 'Originator' },
          { field: 'status', header: 'Status', dynamicPlaceHolder: 'Status' },
          { field: 'lastAccessedDate', header: 'Date', dynamicPlaceHolder: 'Date' },
        ];
      }
      this.GetMyDocsData();
  }
  else{
    this.docsData = []; 
    this.CountData();
  }
  }

  GetMyDocsData(){
    if(this.selectedStatus!=null&&this.selectedStatus.length>0){
      var today = new Date();
      if(this.source=="Admin" ){
        this.userId = 0;
      }
      
      var stauses="";
      this.selectedStatus.forEach(element => {
        stauses+=element.code+",";
      });
      var userFeature = false;
      const userFeatures = sessionStorage.getItem('userFeatures');
      if (userFeatures) {
        var feature = JSON.parse(userFeatures);
        if(feature.length>0){
          var featureExist = feature.find(m=>m.code=="DMS_SF_Admin");
          if(featureExist!=null){
            userFeature = true;
          }
        }
      }
      //show only approved by default.
      this.myDocsMode = {
        documentId: 0,
        departmentId: 0,
        isArchived: this.isArchivedstatus,
        documentTypeId: 0,
        documentStatus: stauses,
        todayDate: today,
        userID: this.userId,
        accessUserID:userFeature?0:parseInt(sessionStorage.getItem('userId'))
      };
      this.showLoading = true;
      this._facadeService.mydocsData(this.myDocsMode).subscribe(
        (data) => {
          var dd =[];
          sessionStorage.ecrn = "";
          sessionStorage.code ="";
          sessionStorage.revision = "";
          sessionStorage.eco = "";
          sessionStorage.title ="";
          sessionStorage.departmentName = "";
          sessionStorage.owner = "";
          sessionStorage.status = "";
          sessionStorage.lastAccessedDate = "";
          if(data.returnObject.length>0){
            data.returnObject.forEach((value, key) => {
              if(value.status=='New'||value.status == 'In-Process' || value.status == 'Obsolete In-Process'){
                value.lastAccessedDate ="";
              }
              else{
                value.lastAccessedDate = this.datePipe.transform(value.lastAccessedDate, 'MM/dd/yyyy');
              }
              if(value.eco!=null &&value.eco!="" && value.eco!=undefined){
                value.eco= value.eco;
              }
              else{
                value.eco= "N/A";
              }
              dd.push(value);
            });
          }
          this.docsData = dd; 
          this.CountData();
          this.showLoading = false;
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    else{
      
    }
   
  }
  exportExcel() {
    // Manually load all data
    this.table.first = 0;
    this.table.rows = this.table.totalRecords;
  
    // Wait for data to load (assuming it's loaded asynchronously)
    setTimeout(() => {
      const data = this.getDataFromTable(this.table);
  
      // Proceed with exporting data to Excel
      this.exportDataToExcel(data);
    }, 100); // Adjust the timeout as needed
  }
  
  // Helper function to get visible columns and data from the p-table
  getDataFromTable(table: Table): { columns: any[], data: any[] } {
    const visibleColumns = table.columns.filter(column => column.field);
    const columns = visibleColumns.map(column => {
      return {
        header: typeof column.header === 'object' ? column.header['name'] : column.header,
        field: column.field
      };
    });
    // Use the 'filteredValue' property if available, otherwise fallback to 'value'
    const data = (table.filteredValue || table.value).map(item => {
      const rowData = {};
      visibleColumns.forEach(column => {
        const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
        rowData[columnHeader] = item[column.field];
      });
      return rowData;
    });
  
    return { columns, data };
  }
  
  // Example function to export data to Excel (you can customize this based on your needs)
  exportDataToExcel(data: { columns: any[], data: any[] }) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
  
    // Add headers separately
    const headerRows = [data.columns.map(column => column.header)];
    XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Customize the column widths if needed
    ws['!cols'] = [{ wpx : 80 },{ wpx : 50 },{ wpx : 50 },{ wpx : 90 },{ wpx : 530 },{ wpx : 140 },{ wpx : 120 },{ wpx : 100 },{ wpx : 100 }];
       /* save to file */
      XLSX.writeFile(wb, "Documents.xlsx");
  }
//   exportExcel() {
//        /* table id is passed over here */   
//        let element = document.getElementById('excel-table'); 
//        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//        /* generate workbook and add the worksheet */
//        const wb: XLSX.WorkBook = XLSX.utils.book_new();
//        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//        this.delete_row(wb.Sheets.Sheet1, 1);
//        ws['!cols'] = [{ wpx : 80 },{ wpx : 50 },{ wpx : 50 },{ wpx : 90 },{ wpx : 530 },{ wpx : 140 },{ wpx : 120 },{ wpx : 100 },{ wpx : 100 }];
//        /* save to file */
//       XLSX.writeFile(wb, "Documents.xlsx");
// }

print() {
  
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
  var title= "All Documents";
  this.printService.Print(Content,title,'trSearch',true);
}

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
        if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
          ws[this.ec(R+1, C)].v  = "";
      }
  }
  range.e.r--
  var status = this.selectedStatus.find(m=>m.code=="New"||m.code=="In-Process"||m.code=='Obsolete In-Process');
  if(status!=null)
    delete (ws['I1'])
  else
    delete (ws['J1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
}
ec(r, c) {
  return XLSX.utils.encode_cell({r:r,c:c})
}

getDocsTypes() {
  this.showLoading = true;
        this._facadeService.getDocTypes().subscribe(
          (data) => {
            // this.showLoading = false;
            console.log('this.docsTypesData---',data.returnObject);
              if(data.returnObject!=null && data.returnObject.length>0){
                var isExist = data.returnObject.find(m=>m.creationSource=="DMS" && m.documentTypeCode!="UCD" &&m.active=="Y");
               if(isExist && this.source!="Admin"){
                this.CreateNewDocSH=true;
               }
               else{
                this.CreateNewDocSH=false;
               }
              }
              else{
                this.CreateNewDocSH=false;
              }
          },
          (error) => {
            this.showLoading = false;
            console.log(error);
          }
        );
}
  viewDocDetails(selectedDocId, wfDefinitionId) {
    sessionStorage.sumstatus = JSON.stringify(this.selectedStatus);
    sessionStorage.docId = selectedDocId;
    sessionStorage.wfDefinitionId = wfDefinitionId;
    this.showLoading = true;
    if(this.source=="Admin"){
      var doc = this.docsData.find(m=>m.createdBy==parseInt(sessionStorage.getItem('userId'))&&m.id==selectedDocId);
      if(doc!=null){
        sessionStorage.isFrom='';
      }
      else{
        sessionStorage.isFrom='Admin';
      }
     
    }
    else{
      sessionStorage.isFrom='';
    }
      if(this.source=="Admin"){
        this.router.navigate(['/docdetails', this.source]);
      }
      else{
        this.router.navigate(['/docdetails']);
      }
  }

  ViewECO(selectedDocId, wfDefinitionId){
    sessionStorage.docId = selectedDocId;
    sessionStorage.wfDefinitionId = wfDefinitionId;
    this.showLoading = true;
    this.router.navigate(['/eco']);
  }
test(val,field,dt)
{
  if(field == "ecrn")
    sessionStorage.ecrn = val;
  else if(field == "code")
    sessionStorage.code = val;
  else if(field == "revision")
    sessionStorage.revision = val;
  else if(field == "title")
    sessionStorage.title = val;
  else if(field == "departmentName")
    sessionStorage.departmentName = val;
  else if(field == "eco")
    sessionStorage.eco = val;
  else if(field == "owner")
    sessionStorage.owner = val;
  else if(field == "status")
    sessionStorage.status = val;
    else if(field == "lastAccessedDate")
    sessionStorage.lastAccessedDate = val;
  dt = dt.filter(val,field, 'contains');
  this.CountData();
}
CountData(){
  if(this.docsData != undefined && this.docsData != null)
  {
    var temp = this.docsData;
    if(sessionStorage.ecrn != ""&&sessionStorage.ecrn != null)
      temp = temp.filter(x=> x.ecrn!=null&&x.ecrn!=""&&x.ecrn.toLowerCase().includes(sessionStorage.ecrn.toLowerCase()));
    if(sessionStorage.code != "")
      //temp = temp.filter(x=> x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
      temp = temp.filter(x=> x.code!=null&&x.code!=""&&x.code.toLowerCase().includes(sessionStorage.code.toLowerCase()));
    if(sessionStorage.revision != "")
      temp = temp.filter(x=> x.revision.toString().includes(sessionStorage.revision.toString()));
      if(sessionStorage.eco != "")
      temp = temp.filter(x=> x.eco.toLowerCase().includes(sessionStorage.eco.toLowerCase()));
    if(sessionStorage.title != "")
      temp = temp.filter(x=> x.title.toLowerCase().includes(sessionStorage.title.toLowerCase()));
    if(sessionStorage.departmentName != "")
      temp = temp.filter(x=> x.departmentName.toLowerCase().includes(sessionStorage.departmentName.toLowerCase()));
    if(sessionStorage.owner != "")
      temp = temp.filter(x=> x.owner.toLowerCase().includes(sessionStorage.owner.toLowerCase()));
    if(sessionStorage.status != "")
      temp = temp.filter(x=> x.status.toLowerCase().includes(sessionStorage.status.toLowerCase()));
    if(sessionStorage.lastAccessedDate != "")
      temp = temp.filter(x=> x.lastAccessedDate.toString().toLowerCase().includes(sessionStorage.lastAccessedDate.toLowerCase()));
     this.DocumentsCount = temp.length;
  }
  else
    this.DocumentsCount = 0;
}
customSort(event: SortEvent) {
  event.data.sort((data1, data2) => {
    let value1 = data1[event.field];
    let value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null)
      result = -1;
    else if (value1 != null && value2 == null)
      result = 1;
    else if (value1 == null && value2 == null)
      result = 0;
    else if (event.field === 'lastAccessedDate') {
      // Convert the string dates to Date objects for correct sorting
      const date1 = value1 === '' ? null : new Date(value1);
      const date2 = value2 === '' ? null : new Date(value2);

      // Handle special case: treat "--" as greater or smaller than any date
      if (date1 === null) {
        result = date2 === null ? 0 : -1;
      } else if (date2 === null) {
        result = 1;
      } else {
        result = date1.getTime() - date2.getTime();
      }
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return (event.order * result);
  });

  event.data.forEach(e => {
    if (e.status == 'New' || e.status == 'In-Process' || e.status == 'Obsolete In-Process') {
      e.lastAccessedDate = "";
    } else if (event.field !== 'lastAccessedDate') {
      // Only format lastAccessedDate if it's not the field used for sorting
      e.lastAccessedDate = this.datePipe.transform(e.lastAccessedDate, 'MM/dd/yyyy');
    }
  });
  this.docsData = [...event.data];
}
GetFile(rowData) {
  if (rowData.fileName != null && rowData.fileName != "" && rowData.fileName != undefined) {
    this.showLoading = true;
    var newname = rowData.userFileName.substr(0, rowData.userFileName.lastIndexOf('.'));
    this.Download(rowData.fileName, newname, rowData.userFileName);
  }
}
Download(filename, newname, userfilename) {
  this._facadeService.downloadFile(filename, newname).subscribe(response => {
    response.filename = userfilename;
    let url = window.URL.createObjectURL(response);
    var anchor = document.createElement("a");
    anchor.download = userfilename;
    anchor.href = url;
    anchor.click();
    anchor.remove();
    // window.open(url, '_blank').focus();
    this.showLoading = false;
  }),
    error => console.log('Error downloading the file'),
    () => console.info('File downloaded successfully');

}
}
