import { Injectable } from "@angular/core";
import { HttputilityService } from "../services/httputility.service";
@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private _httputilityService: HttputilityService) {}
  login(model) {
    return this._httputilityService.AddAuthh("user/Login", model);
  } 
}
