import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { workFlowModel } from '../../../models/workflowProfile_model';
import { FacadeService } from 'src/app/facade/facade.service';
import {WorkflowService} from '../../../services/workflow.service';
import { Table } from "primeng/table";
import * as _ from "lodash";
import * as XLSX from 'xlsx'; 
import { PrintService } from "../../../services/print.service";

@Component({
  selector: 'app-wfprofile',
  templateUrl: './wfprofile.component.html',
  styleUrls: ['./wfprofile.component.scss']
})
export class WfprofileComponent implements OnInit {
  DocumentsCount=0;
  profiles:any[];
  selectedProfile:any[];
  wfProfileData: workFlowModel[];
  cols: any[];
  dynamicPlaceHolder: string;
  profilesMode; any;
  showLoading:boolean;
  userFeature:boolean;

  @ViewChild("dt") table: Table;

   constructor(
              private facadeService:FacadeService, public printService: PrintService,
              private router:Router,private cdr: ChangeDetectorRef
              ) { }

  ngOnInit() {
    const userFeatures = sessionStorage.getItem('userFeatures');
    if (userFeatures) {
      var feature = JSON.parse(userFeatures);
      if(feature.length>0){
        var featureExist = feature.find(m=>m.code=="DSBL_NEW_VIEW_WF");
        if(featureExist!=null){
          this.userFeature = true;
        }
      }
    }
    sessionStorage.name ="";
    this.getwfProfileData();

    this.cols = [
      { field: 'name', header: 'Profile', dynamicPlaceHolder: 'Profile' },
      // { field: 'documentType', header: 'Document Type', dynamicPlaceHolder: 'Document Type' },
      { field: 'Action', header: 'Action', dynamicPlaceHolder: 'Action'}    
    ];
    }

    viewwfDetails(rowdata){
      sessionStorage.profileId = rowdata.id;
      this.router.navigateByUrl('/addprofile');
    }

    getwfProfileData() {
      this.facadeService.GetProfileNames().subscribe(
        (data) => {
          this.showLoading = false;
          this.wfProfileData = data.returnObject;
          //this.wfProfileData = _.sortBy(this.wfProfileData, "createdDate");
          console.log(this.wfProfileData);
          console.log("Profile Data ::" + JSON.stringify( this.wfProfileData));  
          this.CountData();  
        },
        (error) => {
          this.showLoading = false;
          console.log(error);
        }
      );
    }
    exportExcel() {
      // Manually load all data
      this.table.first = 0;
      this.table.rows = this.table.totalRecords;
    
      // Wait for data to load (assuming it's loaded asynchronously)
      setTimeout(() => {
        const data = this.getDataFromTable(this.table);
    
        // Proceed with exporting data to Excel
        this.exportDataToExcel(data);
      }, 100); // Adjust the timeout as needed
    }
    
    // Helper function to get visible columns and data from the p-table
    getDataFromTable(table: Table): { columns: any[], data: any[] } {
      const visibleColumns = table.columns.filter(column => column.field);
      const columns = visibleColumns.map(column => {
        return {
          header: typeof column.header === 'object' ? column.header['name'] : column.header,
          field: column.field
        };
      });
      // Use the 'filteredValue' property if available, otherwise fallback to 'value'
      const data = (table.filteredValue || table.value).map(item => {
        const rowData = {};
        visibleColumns.forEach(column => {
          const columnHeader = typeof column.header === 'object' ? column.header['name'] : column.header;
          rowData[columnHeader] = item[column.field];
        });
        return rowData;
      });
    
      return { columns, data };
    }
    
    // Example function to export data to Excel (you can customize this based on your needs)
    exportDataToExcel(data: { columns: any[], data: any[] }) {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.data, { header: data.columns.map(column => column.header) });
    
      // Add headers separately
      const headerRows = [data.columns.map(column => column.header)];
      XLSX.utils.sheet_add_aoa(ws, headerRows, { origin: 'A1' });
    
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
      ws['!cols'] = [{ wpx : 550 }];
  /* save to file */
  XLSX.writeFile(wb, "Document Approval Profiles.xlsx");
    }
// exportExcel() {
//   /* table id is passed over here */   
//   let element = document.getElementById('excel-table'); 
//   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw: true});
//   /* generate workbook and add the worksheet */
//   const wb: XLSX.WorkBook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//   this.delete_row(wb.Sheets.Sheet1, 1);
//   ws['!cols'] = [{ wpx : 550 }];
//   /* save to file */
//   XLSX.writeFile(wb, "Document Approval Profiles.xlsx");
// }

delete_row (ws, row_index){
  let range = XLSX.utils.decode_range(ws["!ref"])
  for(var R = row_index; R < range.e.r; ++R){
      for(var C = range.s.c; C <= range.e.c; ++C){
        if(ws[this.ec(R+1, C)] != undefined)
        {
          ws[this.ec(R, C)] = ws[this.ec(R+1, C)]
          if(ws[this.ec(R+1, C)].v == "&nbsp;" || ws[this.ec(R+1, C)].v == "" || ws[this.ec(R+1, C)].v == null)
            ws[this.ec(R+1, C)].v  = "";
        }
      }
  }
  range.e.r--
  delete (ws['B1'])
  ws['!ref'] = XLSX.utils.encode_range(range.s, range.e)
  }
ec(r, c) {
return XLSX.utils.encode_cell({r:r,c:c})
}
print() {
  
    // Disable virtual scrolling temporarily to ensure all rows are rendered
    const virtualScroll = this.table.virtualScroll;
    this.table.virtualScroll = false;
  
    // Refresh the data of the table
    //this.table.clear();
  
    // Detect changes immediately to ensure the table is rendered
    this.cdr.detectChanges();
  
    // Get the entire content of the 'invoice-content' element
    const Content = document.getElementById('invoice-content').innerHTML;
  
    // Revert virtual scrolling to its original state
    this.table.virtualScroll = virtualScroll;
  
    // Print the content
  this.printService.Print(Content,"Document Approval Profiles",'trSearch',true);
}

  createProfile(){
    this.showLoading = true;
    sessionStorage.profileId = 0;
    this.router.navigate(['/addprofile'])
  }
  test(val,field,dt)
  {
    if(field == "name")
      sessionStorage.name = val;
    dt = dt.filter(val,field, 'contains');
    this.CountData();
  }
  CountData(){
    if(this.wfProfileData != undefined && this.wfProfileData != null)
    {
      var temp = this.wfProfileData;
      if(sessionStorage.name != ""&&sessionStorage.name != null)
        temp = temp.filter(x=> x.name.toLowerCase().includes(sessionStorage.name.toLowerCase()));
      this.DocumentsCount = temp.length;
    }
    else
      this.DocumentsCount = 0;
  }
}

